import React, {Component} from 'react'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import CertificationInformation from '../../components/registrationsteps/presentation/certificateinformation'
import CertificationInformationLogic from '../../components/registrationsteps/logic/certificateinformationLogic'

class CertificateInformation extends Component {
    state = {
        CallingAPi: false,
    }
    render() {
        HelperMethods.setNavigationData(this)
        const {AuthenticationToken} = this.navigationData
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray, paddingBottom: 50}}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer
                        containerTitle={'CERTIFICATE INFORMATION'}
                        activityText={'Loading Certificate Information'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <CertificationInformation />
                        <ABATouchableHighlight
                            onPressEvent={() => {
                                CertificationInformationLogic.SaveLogic()
                            }}
                            text={'Continue'}
                        />
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default CertificateInformation
