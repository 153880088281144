import {Platform} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ProgressReportLogic = {
    context: null,

    GetUserRoleId: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('dashboard/getUserRoleId')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving User Role')
                    resolve(null)
                })
        })
    },

    getProfessionalStanding() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('medicallicense/getProfessionalStanding')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Professional Standing')
                    resolve(null)
                })
        })
    },

    getMocaCycle() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('cme/getMocaCycleByDocID')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Moca Cycle')
                    resolve(null)
                })
        })
    },

    getMocaMinuteDashboardInfo() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('mocaMinute/getMocaMinuteDashboardInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Dashboard Data')
                    resolve(null)
                })
        })
    },
}

export default ProgressReportLogic
