import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

const ChangePasswordLogic = {
    context: null,

    resetPassword: function (oldPassword, newPassword, newPasswordConfirm) {
        if (newPassword != newPasswordConfirm) {
            HelperMethods.alertAgnostic(
                'New Password and Confirm New Password do not match.',
                'New Password Does Not Match',
                [{text: 'Ok'}],
            )

            return
        }

        if (!ValidationHelper.isPasswordFormatValid(newPassword)) {
            HelperMethods.alertAgnostic(
                'The new password you specified does not meet the password requirements listed below.',
                'Invalid Password Format',
                [{text: 'Ok'}],
            )

            return
        }

        ChangePasswordLogic.context.setState({CallingApi: true})
        ABAPortalAPI.post('authentication/resetPassword', {
            NewPassword: newPassword,
            OldPassword: oldPassword,
        })
            .then(function (response) {
                ChangePasswordLogic.context.setState({CallingApi: false})

                HelperMethods.alertAgnostic(
                    'Your new password has been successfully reset.',
                    'Password Reset Successfully',
                    [{text: 'Ok'}],
                )

                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/dashboard')
            })
            .catch(function (error) {
                ChangePasswordLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Error Setting Password')
            })
    },
}

export default ChangePasswordLogic
