import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const MedLicenseLogic = {
    context: null,

    GetMedicalLicenseDisplayData: function () {
        return new Promise((resolve) => {
            MedLicenseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('medicallicense/getMedicalLicenseComponentDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Medical Licenses')
                    resolve(null)
                })
                .finally(function () {
                    MedLicenseLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default MedLicenseLogic
