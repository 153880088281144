import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep2 from '../presentation/nonStandardExamStep2'
import NonStandardExamStep3 from '../presentation/nonStandardExamStep3'

const NonStandardExamStep1Logic = {
    context: null,

    GetNSEBeginData: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.post('nserequestsinfo/GetNSEBegin', {
                Order: this.context.props.Order,
                CR2ID: this.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Information')
                    resolve(null)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    CreateNSE: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {ProductTypeCode, ProductId, ContactId, rs_statuscode, statuscode, exam_nserequestId} =
                this.context.state.NSEReleaseData
            ABAPortalAPI.post('nserequestsinfo/CreateNSEBegin', {
                CR2ID: this.context.props.CR2ID,
                Order: this.context.props.Order,
                exam_response: this.context.state.ReleaseOption,
                ProductTypeCode,
                ProductId,
                ContactId,
                rs_statuscode,
                statuscode,
                exam_nserequestId,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving NSE Information')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    populateStateVariables: function (examTypeList, data) {
        this.context.setState(
            {
                examTypeList: examTypeList || [],
            },
            () => {
                if (data) {
                    this.context.setState({
                        ReleaseOption: data.exam_Response,
                        NSEReleaseData: data,
                        productID: data.ProductId,
                    })
                }
            },
        )
    },

    ReleaseOptionYesSelected() {
        this.context.setState({
            ReleaseOption: 1,
            ReleaseOptionError: GeneralEnums.textInputStatus.default,
        })
        nonStandardExamContext.setState({ExamResponse: 1})
    },

    ReleaseOptionNoSelected() {
        this.context.setState({
            ReleaseOption: 2,
            ReleaseOptionError: GeneralEnums.textInputStatus.default,
        })
        nonStandardExamContext.setState({ExamResponse: 2})
    },

    NursingMotherOptionYesSelected() {
        this.context.setState({
            NursingMotherOption: 1,
            NursingMotherOptionError: GeneralEnums.textInputStatus.default,
        })
        nonStandardExamContext.setState({NursingMotherResponse: 1})
    },
    NursingMotherOptionNoSelected() {
        this.context.setState({
            NursingMotherOption: 2,
            NursingMotherOptionError: GeneralEnums.textInputStatus.default,
        })
        nonStandardExamContext.setState({NursingMotherResponse: 2})
    },

    ValidateForm: function () {
        var validated = true
        if (
            (this.context.state.ReleaseOption !== 1 && this.context.state.ReleaseOption !== 2) ||
            (this.context.props.IsFromDashboard && this.context.state.ReleaseOption === 2)
        ) {
            this.context.setState({ReleaseOptionError: GeneralEnums.textInputStatus.error})
            validated = false
        }
        if (
            this.context.props.IsFromDashboard &&
            this.context.state.NursingMotherOption !== 1 &&
            this.context.state.NursingMotherOption !== 2
        ) {
            this.context.setState({NursingMotherOptionError: GeneralEnums.textInputStatus.error})
            validated = false
        }
        return validated
    },

    SetExamInformation: function (productID, examList) {
        const exam = examList.find((exam) => exam.value === productID)
        if (exam) {
            this.context.setState({
                productID,
                ExamLabel: exam.label,
            })
        }
    },

    GetExamTypes: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getExamTypes')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Exam Types')
                    resolve(null)
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidateForm()) {
                resolve(false)
                return
            }

            if (!this.context.props.IsFromDashboard) {
                this.CreateNSE().then((response) => {
                    if (!response) {
                        resolve(false)
                        return
                    }

                    if (this.context.state.ReleaseOption === 1) {
                        nonStandardExamContext.setState({
                            NonStandardExamID: response,
                        })
                        if (this.context.props.IsFromDashboard) {
                            submitNseRequestContext.setState({
                                SubmittedSteps: 1,
                            })
                        }
                        if (this.context.props.CR2ID) {
                            nonStandardExamContext.setState({
                                CurrentComponentStep: NonStandardExamStep3,
                            })
                        } else {
                            nonStandardExamContext.setState({
                                CurrentComponentStep: NonStandardExamStep2,
                            })
                        }
                        resolve(false)
                    } else {
                        resolve(true)
                    }
                })
            } else {
                submitNseRequestContext.setState({
                    SubmittedSteps: 1,
                })
                nonStandardExamContext.setState({
                    CurrentComponentStep: NonStandardExamStep2,
                })
                resolve(false)
            }
        })
    },

    Previous() {
        return new Promise((resolve) => {
            resolve(true)
        })
    },
}

export default NonStandardExamStep1Logic
