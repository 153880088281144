import React, {Component} from 'react'
import {ActivityIndicator, Platform, Text, TouchableHighlight, View} from 'react-native'

import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ChangePasswordLogic from '../logic/changePasswordLogic'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import styleFunctions from '../../styles/styleFunctions'

const passwordRequirements = [
    'Be between 7 to 20 characters long',
    'Include at least one number',
    'Have at least one upper case letter',
    'Have at least one lower case letter or special character',
]

export default class ChangePasswordSetPassword extends Component {
    state = {
        newPassword: '',
        newPasswordConfirm: '',
        oldPassword: '',
        InputStatus: {
            NewPassword: GeneralEnums.textInputStatus.default,
            NewPasswordConfirm: GeneralEnums.textInputStatus.default,
            OldPassword: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {}

    render() {
        changePasswordSetPasswordContext = this
        navigationContext = this
        ChangePasswordLogic.context = changePasswordSetPasswordContext

        HelperMethods.setNavigationData(changePasswordSetPasswordContext)

        return (
            <NavigationShell context={changePasswordSetPasswordContext}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    {Platform.OS !== 'web' && (
                        <View>
                            <View style={{alignItems: 'center', paddingTop: 10, paddingBottom: 10}}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.danube,
                                            fontSize: 24,
                                        }}
                                    >
                                        {IconEnums['user-circle']}
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.danube,
                                            fontSize: 24,
                                        }}
                                    >
                                        {'  Account Settings'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}

                    <View style={[styleFunctions.loginContainer(), {width: '90%'}]}>
                        <Text style={[styleFunctions.loginHeader(), {paddingBottom: 10}]}>
                            Change Password
                        </Text>

                        <ABATextInput
                            placeholder="Old Password"
                            onChangeText={(text) => {
                                changePasswordSetPasswordContext.setState({oldPassword: text})
                            }}
                            onSubmitEditing={() => {
                                changePasswordSetPasswordContext.newPasswordInput.focus()
                            }}
                            reference={(oldPasswordInput) => {
                                oldPasswordInput &&
                                    oldPasswordInput.setNativeProps({style: {fontFamily: 'Lato'}})
                                changePasswordSetPasswordContext.oldPasswordInput = oldPasswordInput
                            }}
                            returnKeyType="next"
                            secureTextEntry
                            textInputStatus={
                                changePasswordSetPasswordContext.state.InputStatus.OldPassword
                            }
                            value={this.state.oldPassword}
                        />

                        <ABATextInput
                            placeholder="New Password"
                            onChangeText={(text) => {
                                changePasswordSetPasswordContext.setState({newPassword: text})
                            }}
                            onSubmitEditing={() => {
                                changePasswordSetPasswordContext.newPasswordConfirmInput.focus()
                            }}
                            reference={(newPasswordInput) => {
                                newPasswordInput &&
                                    newPasswordInput.setNativeProps({style: {fontFamily: 'Lato'}})
                                changePasswordSetPasswordContext.newPasswordInput = newPasswordInput
                            }}
                            returnKeyType="next"
                            secureTextEntry
                            textInputStatus={
                                changePasswordSetPasswordContext.state.InputStatus.NewPassword
                            }
                            value={this.state.newPassword}
                        />

                        <View
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '75%',
                                paddingBottom: 20,
                            }}
                        >
                            <Text
                                style={{fontFamily: 'Lato', color: ColorEnums.cloudBurst, fontSize: 14}}
                            >
                                Your password must:
                            </Text>
                            {passwordRequirements.map((requirement, i) => {
                                return (
                                    <Text
                                        key={i}
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 14,
                                        }}
                                    >{`\u2022 ${requirement}`}</Text>
                                )
                            })}
                        </View>

                        <ABATextInput
                            placeholder="Confirm Password"
                            onChangeText={(text) => {
                                changePasswordSetPasswordContext.setState({newPasswordConfirm: text})
                            }}
                            onSubmitEditing={() => {
                                ChangePasswordLogic.resetPassword(
                                    changePasswordSetPasswordContext.state.oldPassword,
                                    changePasswordSetPasswordContext.state.newPassword,
                                    changePasswordSetPasswordContext.state.newPasswordConfirm,
                                )
                            }}
                            reference={(newPasswordConfirmInput) => {
                                newPasswordConfirmInput &&
                                    newPasswordConfirmInput.setNativeProps({style: {fontFamily: 'Lato'}})
                                changePasswordSetPasswordContext.newPasswordConfirmInput =
                                    newPasswordConfirmInput
                            }}
                            secureTextEntry
                            textInputStatus={
                                changePasswordSetPasswordContext.state.InputStatus.NewPasswordConfirm
                            }
                            value={this.state.newPasswordConfirm}
                        />

                        <ABATouchableHighlight
                            icon={'angle-right'}
                            onPressEvent={() => {
                                ChangePasswordLogic.resetPassword(
                                    changePasswordSetPasswordContext.state.oldPassword,
                                    changePasswordSetPasswordContext.state.newPassword,
                                    changePasswordSetPasswordContext.state.newPasswordConfirm,
                                )
                            }}
                            text={'Submit'}
                        />

                        {changePasswordSetPasswordContext.state.CallingApi && (
                            <View style={styleFunctions.loadingOverlay()}>
                                <Text>Setting New Password</Text>
                                <ActivityIndicator color={ColorEnums.indigo} size="large" />
                            </View>
                        )}
                    </View>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
