import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import {GeneralEnums, ColorEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

export default class QuestionHistoryRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalVisible: false,
        }

        this.styles = StyleSheet.create({
            questionResponseText: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 12,
            },

            questionResponseIcon: {
                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                fontSize: 12,
                paddingRight: 5,
            },
        })
    }

    getResponseResult(responseResult) {
        switch (responseResult) {
            case 2:
                return (
                    <View style={{flexDirection: 'row'}}>
                        <Text style={[this.styles.questionResponseIcon, {color: ColorEnums.surfGreen}]}>
                            {IconEnums['check']}
                        </Text>
                        <Text style={this.styles.questionResponseText}>Correct</Text>
                    </View>
                )
            case 3:
                return (
                    <View style={{flexDirection: 'row'}}>
                        <Text
                            style={[this.styles.questionResponseIcon, {color: ColorEnums.pomegranate}]}
                        >
                            {IconEnums['times']}
                        </Text>
                        <Text style={this.styles.questionResponseText}>Incorrect</Text>
                    </View>
                )
            case 4:
                return (
                    <View style={{flexDirection: 'row'}}>
                        <Text style={[this.styles.questionResponseIcon, {color: ColorEnums.citrus}]}>
                            {IconEnums['clock']}
                        </Text>
                        <Text style={this.styles.questionResponseText}>Timed Out</Text>
                    </View>
                )
            default:
                return <Text style={this.styles.questionResponseText}>N/A</Text>
        }
    }

    render() {
        return (
            <View
                style={{
                    borderBottomColor: this.props.rowBorderColor,
                    borderBottomWidth: 1,
                    flexDirection: 'row',
                }}
            >
                <View style={{width: '75%'}}>
                    <View style={{paddingBottom: 5, paddingTop: 5}}>
                        <Text style={{fontFamily: 'Lato', color: ColorEnums.blueGray, fontSize: 10}}>
                            Question
                        </Text>
                        <Text style={{fontFamily: 'Lato', color: ColorEnums.cloudBurst, fontSize: 12}}>
                            {this.props.questionText}
                        </Text>

                        {/* q-tip content */}
                        {this.state.modalVisible && (
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    this.setState({modalVisible: !this.state.modalVisible})
                                }}
                                testID={'questionHistoryRowModal' + this.props.askId}
                            >
                                <View
                                    style={{
                                        borderWidth: 1,
                                        borderColor: ColorEnums.cloudBurst,
                                        position: 'absolute',
                                        right: 0,
                                        zIndex: 2,
                                        backgroundColor: ColorEnums.backgroundGray,
                                        padding: 10,
                                        borderRadius: 5,
                                        top: 3,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 10,
                                        }}
                                    >
                                        Shows percentage of your peers who answered this question
                                        correct.
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 10,
                                            right: 0,
                                            top: 0,
                                            position: 'absolute',
                                            paddingRight: 5,
                                            paddingTop: 2,
                                        }}
                                    >
                                        {IconEnums['times']}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        )}
                    </View>

                    <View style={{flexDirection: 'row', paddingBottom: 10}}>
                        <View style={{width: '50%'}}>
                            <Text style={{fontFamily: 'Lato', color: ColorEnums.blueGray, fontSize: 10}}>
                                Result
                            </Text>
                            {this.getResponseResult(this.props.responseStatus)}
                        </View>
                        <View style={{width: '50%'}}>
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    this.setState({modalVisible: !this.state.modalVisible})
                                }}
                                testID={'questionHistoryPeer' + this.props.askId}
                            >
                                <View style={{flexDirection: 'row', alignSelf: 'flex-start'}}>
                                    <Text
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.blueGray,
                                            fontSize: 10,
                                        }}
                                    >
                                        Peer Performance
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.blueGray,
                                            fontSize: 10,
                                            paddingLeft: 5,
                                        }}
                                    >
                                        {IconEnums['question-circle']}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <Text
                                style={{fontFamily: 'Lato', color: ColorEnums.cloudBurst, fontSize: 12}}
                            >{`${this.props.peerPerformance}%`}</Text>
                        </View>
                    </View>
                </View>

                <TouchableWithoutFeedback
                    onPress={() => {
                        NavigationHelper.GoToPage(
                            GeneralEnums.navigationType.push,
                            '/mocaMinuteFeedback',
                            {AskId: this.props.askId},
                        )
                    }}
                    testID={'questionHistoryRowView' + this.props.askId}
                >
                    <View style={{flexDirection: 'row', marginLeft: 'auto'}}>
                        <View style={{justifyContent: 'center', paddingRight: 5}}>
                            <Text style={{fontFamily: 'Lato', color: ColorEnums.danube, fontSize: 12}}>
                                View
                            </Text>
                        </View>
                        <View style={{justifyContent: 'center'}}>
                            <Text
                                style={{
                                    fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                    color: ColorEnums.danube,
                                    fontSize: 12,
                                }}
                            >
                                {IconEnums['angle-right']}
                            </Text>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }
}

QuestionHistoryRow.propTypes = {
    questionText: PropTypes.string,
    responseStatus: PropTypes.number,
    peerPerformance: PropTypes.string,
    askId: PropTypes.string,
    rowBorderColor: PropTypes.string,
}
