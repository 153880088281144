import {Platform, Text, View} from 'react-native'
import React from 'react'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'

const ViewExamResultsLogic = {
    context: null,

    getExamResults: function () {
        return new Promise((resolve) => {
            ViewExamResultsLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('examInformation/getExamResults')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
                .finally(function () {
                    ViewExamResultsLogic.context.setState({CallingApi: false})
                })
        })
    },

    getExamsViewed: function () {
        return new Promise((resolve) => {
            ViewExamResultsLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('examInformation/getExamsViewed')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
                .finally(function () {
                    ViewExamResultsLogic.context.setState({CallingApi: false})
                })
        })
    },

    fillInBoldText: function (ExamMessage, option) {
        var emessage = ExamMessage.toString()
        var regExSplitter = /%%%/
        var words = emessage.split(regExSplitter)
        var targetFontWeight
        if (words.length > 1) {
            return (
                <View>
                    <Text style={[styleFunctions.paragraphText(), {width: '100%', fontSize: 14}]}>
                        {words.map((word) => {
                            switch (word) {
                                case 'EventName':
                                    word = option.EventName
                                    targetFontWeight = 'bold'
                                    break
                                case 'ResultsMailDate':
                                    word = option.ResultsMailDate
                                    targetFontWeight = 'bold'
                                    break
                                case 'TelephoneDate':
                                    word = option.TelephoneDate
                                    targetFontWeight = 'bold'
                                    break
                                default:
                                    targetFontWeight = 'normal'
                                    break
                            }
                            return <Text style={{fontWeight: targetFontWeight}}>{word}</Text>
                        })}
                    </Text>
                </View>
            )
        } else {
            return (
                <View>
                    <Text style style={[styleFunctions.paragraphText(), {width: '100%', fontSize: 14}]}>
                        {ExamMessage}
                    </Text>
                </View>
            )
        }
    },
}

export default ViewExamResultsLogic
