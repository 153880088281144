import React from 'react'
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'

import ABABarChart from '../ABACustom/presentation/ABABarChart'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import stylefunctions from '../../styles/styleFunctions'
import DashboardLogic from '../../screens/logic/dashboardLogic'
import RegistrationRow from '../presentation/registrationRow'

const styles = StyleSheet.create({
    filledbarbgcolor: {
        height: 15,
        textAlign: 'center',
        justifyContent: 'center',
    },

    emptybarbgcolor: {
        height: 15,
        textAlign: 'center',
        justifyContent: 'center',
    },

    chartoutercontainer: {
        height: 30,
        alignItems: 'center',
        flexDirection: 'row',
        paddingBottom: 7,
    },

    progressPercent: {
        color: ColorEnums.danube,
        textAlign: 'left',
        width: '10%',
        fontSize: 12,
    },

    progressBarFill: {
        backgroundColor: ColorEnums.surfGreen,
    },

    progressBarEmpty: {
        backgroundColor: ColorEnums.aquaHaze,
    },
})

const RegistrationLogic = {
    context: null,
    dataColumnsWidth: 0.28,
    styleNoRegistrationButton: function (componentWidth) {
        return {
            alignItems: 'center',
            backgroundColor: '#3399cc',
            borderRadius: 15,
            flexDirection: 'row',
            height: 40,
            justifyContent: 'center',
            width: componentWidth * 0.6,
        }
    },

    styleButtonText: function (alignment) {
        if (alignment === undefined) {
            alignment = 'left'
        }
        return {
            textAlign: alignment,
            fontFamily: 'Lato',
            color: ColorEnums.white,
            fontSize: 18,
        }
    },

    GetNumberOfCertifications: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('registration/getNumberOfCertificates')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Number Of Certificates')
                    resolve(null)
                })
        })
    },

    GetRegistrationDisplayData: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('registration/getRegistrationDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Registration Components List')
                    resolve(null)
                })
        })
    },

    GetPaymentProcessingInfo() {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    timestamp: new Date().toUTCString(),
                },
            }
            RegistrationLogic.context.props.setPaymentOffline(false)
            ABAPortalAPI.get('payment/paymentStatusDashboard', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    if (error?.response?.status !== 503) {
                        HelperMethods.apiErrorHandler(error, 'Error Retrieving Payment Status')
                    } else {
                        RegistrationLogic.context.props.setPaymentOffline(true)
                    }
                    resolve(true)
                })
        })
    },

    GetRegistrationDisplay: function (showLoading = true) {
        return new Promise((resolve) => {
            RegistrationLogic.context.setState({CallingApi: showLoading})
            RegistrationLogic.context.props.setRegistrationLoading(true)
            Promise.all([
                RegistrationLogic.GetRegistrationDisplayData(),
                RegistrationLogic.GetPaymentProcessingInfo(),
            ])
                .then(function (values) {
                    const registrationComponents = values[0]
                    const paymentProcessing = values[1]
                    let RegistrationScreenComponents = []
                    RegistrationLogic.context.setState({
                        RegistrationScreenComponents: registrationComponents,
                        PaymentInfo: paymentProcessing,
                    })
                    const isProcessing =
                        paymentProcessing ||
                        registrationComponents.filter(
                            (registration) =>
                                registration.CR2_statuscode !==
                                    GeneralEnums.rulesetStatus_statusCodes.Eligible_to_Enroll &&
                                registration.CountFinished !== registration.CountTotal &&
                                registration.PaymentExists,
                        ).length > 0

                    RegistrationLogic.context.props.togglePendingPayment(isProcessing)

                    RegistrationScreenComponents = RegistrationLogic.PopulateRegistrationGrid(
                        registrationComponents,
                        paymentProcessing,
                    )
                    RegistrationLogic.SetMocaCR2(registrationComponents)
                    resolve(RegistrationScreenComponents)
                })
                .finally(() => {
                    RegistrationLogic.context.setState({CallingApi: false})
                    RegistrationLogic.context.props.setRegistrationLoading(false)
                })
        })
    },

    GetRegistrationAction: (registration, showProcessing) => {
        let Action = ''
        let navToComponent = '/'
        let percentComplete = registration.CountFinished / registration.CountTotal

        if (registration.CR2_statuscode === GeneralEnums.rulesetStatus_statusCodes.Eligible_to_Enroll) {
            percentComplete = 0
            if (registration.AreSessionsAvailable === true) {
                Action = 'Start'
                navToComponent = '/registrationStepsDisplay'
            } else {
                Action = 'Not Available'
                navToComponent = ''
            }
        } else if (registration.CountFinished === registration.CountTotal) {
            Action = 'View'
            navToComponent = '/viewRegistrationDetails'
        } else if (showProcessing || registration.PaymentExists) {
            Action = 'Processing'
        } else if (registration.re_IsEligible === false || registration.AreSessionsAvailable === false) {
            percentComplete = 0
            Action = registration.re_IsEligible === false ? 'Not Eligible' : 'Not Available'
            navToComponent = ''
        } else if (registration.CountFinished === 0) {
            Action = 'Start'
            navToComponent = '/registrationStepsDisplay'
        } else {
            Action = 'Finish'
            navToComponent = '/registrationStepsDisplay'
        }

        return {Action, navToComponent, percentComplete}
    },

    PopulateRegistrationGrid: function (registrationComponents, paymentInfo) {
        let RegistrationScreenComponents = []
        if (registrationComponents && registrationComponents.length > 0) {
            RegistrationScreenComponents.push(<RegistrationRow isHeader key="header" />)
            registrationComponents.forEach((registration, index) => {
                const {Action, navToComponent, percentComplete} =
                    RegistrationLogic.GetRegistrationAction(registration, paymentInfo)
                RegistrationScreenComponents.push(
                    <RegistrationRow
                        name={registration.DisplayName}
                        text={Action}
                        percentCompleted={percentComplete}
                        action={
                            Action === 'Processing'
                                ? () => RegistrationLogic.context.componentDidMount()
                                : () =>
                                      NavigationHelper.GoToPage(
                                          GeneralEnums.navigationType.push,
                                          navToComponent,
                                          {
                                              re_rulesetstatusId: registration.re_rulesetstatusId,
                                              re_RulegroupId: registration.re_RulegroupId,
                                              StatusCode: registration.CR2_statuscode,
                                          },
                                      )
                        }
                        key={`regRow${index}`}
                    />,
                )
            })
        } else if (RegistrationLogic.context.state.NumberofCerts === 0) {
            RegistrationScreenComponents.push(
                <RegistrationRow isHeader key="header" />,
                <RegistrationRow
                    key="regRow0"
                    name="MOCA 2.0 Program"
                    text="Start"
                    percentCompleted={0}
                    action={() =>
                        NavigationHelper.GoToPage(
                            GeneralEnums.navigationType.push,
                            '/registrationStepsDisplay',
                            {
                                re_rulesetstatusId: null,
                                isNTL: true,
                            },
                        )
                    }
                />,
            )
        } else if (RegistrationScreenComponents.length === 0 && registrationComponents !== null) {
            RegistrationScreenComponents.push(
                <View key={'1'} style={{alignItems: 'center'}}>
                    <View style={{paddingBottom: 10}}>
                        <Text
                            style={[
                                stylefunctions.columnWidth(
                                    RegistrationLogic.context.state.ComponentWidth,
                                    0.95,
                                ),
                                stylefunctions.containerHeader('center'),
                            ]}
                        >
                            Not currently eligible to register for an exam
                        </Text>
                    </View>
                    <View style={{paddingBottom: 10}}>
                        <Text
                            style={[
                                stylefunctions.columnWidth(
                                    RegistrationLogic.context.state.ComponentWidth,
                                    0.95,
                                ),
                                stylefunctions.containerText('center'),
                            ]}
                        >
                            Please check the exam schedule to view exam qualifications and exam dates.
                        </Text>
                    </View>
                    <View style={{paddingBottom: 10}}>
                        <ABATouchableHighlight
                            buttonStyle={RegistrationLogic.styleNoRegistrationButton(
                                RegistrationLogic.context.state.ComponentWidth,
                            )}
                            textStyle={RegistrationLogic.styleButtonText('center')}
                            text="View Exam Schedule >"
                            onPressEvent={() => {
                                HelperMethods.openURL('http://www.theaba.org')
                            }}
                        />
                    </View>
                </View>,
            )
        }
        return RegistrationScreenComponents
    },

    SetMocaCR2(registrationComponents) {
        const mocaRegistrationComponent = registrationComponents.filter(
            (component) => component.ProgramType === 500,
        )[0]
        if (mocaRegistrationComponent) {
            DashboardLogic.context.setState({mocaCR2: mocaRegistrationComponent.re_rulesetstatusId})
        }
    },
}

export default RegistrationLogic
