import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import {Platform} from 'react-native'
import ClinicalActivityStep4 from '../presentation/clinicalActivityStep4'
import ClinicalActivityStep3 from '../presentation/clinicalActivityStep3'
import TrainingPage from '../presentation/trainingPage'
import HelperMethods from '../../../../helpers/HelperMethods'

const ClinicalActivityLogicStep5 = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep5.context.setState({CallingApi: true})
            ABAPortalAPI.get(
                'clinicalactivity/getClinicalActivity?ClinicalActivityID=' +
                    clinicalActivityContext.state.ClinicalActivityID,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    ClinicalActivityLogicStep5.context.setState({CallingApi: false})
                })
        })
    },
    GetReferenceIDs() {
        return new Promise((resolve) => {
            const {ClinicalActivityID} = clinicalActivityContext.state
            let getParams = {
                params: {
                    ClinicalActivityID,
                    allReferences: 1,
                },
            }

            ClinicalActivityLogicStep5.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(function () {
                    ClinicalActivityLogicStep5.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables(confirmationPageInfo) {
        const [clinicalActivityData, referenceIDs] = confirmationPageInfo
        ClinicalActivityLogicStep5.context.setState({
            clinicalActivityData,
            referenceIDs,
        })
    },

    GoToComponentStep(componentStep) {
        registrationStepsDisplayContext.setState({ShowPrevious: true, ContinueText: 'Continue'})
        clinicalActivityContext.setState({CurrentComponentStep: componentStep}, () => {
            Platform.OS === 'web' && window.scrollTo(0, 0)
        })
    },

    UpdateClinicalActivityStep5() {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep5.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/UpdateClinicalActivityStep5', {
                cert_clinicalactivityid: clinicalActivityContext.state.ClinicalActivityID,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
                re_ProgramType: clinicalActivityContext.props.ProgramType,
                isRecertification: ClinicalActivityLogicStep5.context.props.IsRecertification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    ClinicalActivityLogicStep5.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            registrationStepsDisplayContext.setState({
                ShowPrevious: true,
                ContinueText: 'Continue',
            })
            ClinicalActivityLogicStep5.UpdateClinicalActivityStep5().then((response) => {
                response ? resolve(true) : resolve(false)
            })
        })
    },

    Previous() {
        const {CAstepCount: stepCount} = registrationStepsDisplayContext.state
        const {cert_acapath, ClinicalActivityStatus} = clinicalActivityContext.state
        const nextStep = stepCount === 3 ? ClinicalActivityStep3 : ClinicalActivityStep4

        return new Promise((resolve) => {
            registrationStepsDisplayContext.setState({ShowPrevious: true, ContinueText: 'Continue'})
            if (cert_acapath == 2) {
                clinicalActivityContext.setState({
                    CurrentComponentStep: TrainingPage,
                })
                resolve(false)
            } else if (ClinicalActivityStatus === 1) {
                clinicalActivityContext.setState({CurrentComponentStep: nextStep}, () => {
                    Platform.OS === 'web' && window.scrollTo(0, 0)
                })
                resolve(false)
            } else {
                resolve(true)
            }
        })
    },
}

export default ClinicalActivityLogicStep5
