import React, { Component } from 'react';
import { Text, View } from 'react-native';
export default class ABABarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() { 
        return (
            <View style={this.props.outerbarstyle}>
                <View style={this.props.filledbarstyle}></View>
                <View style={this.props.emptybarstyle}></View>
            </View>
        );
    }
}
