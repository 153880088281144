import React, {Component} from 'react'
import {Alert, BackHandler, Platform, ScrollView, Text, View} from 'react-native'

import ABAContainer from '../../../components/ABACustom/presentation/ABAContainer'
import ABACountdownCircle from '../../../components/ABACustom/presentation/ABACountdownCircle'
import ABAHeader from '../../../components/ABACustom/presentation/ABAHeader'
import ABAMMAnswerOptions from '../../../components/ABACustom/presentation/ABAMMAnswerOptions'
import ABATouchableHighlight from '../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAWebView from '../../../components/ABACustom/presentation/ABAWebView'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import MocaMinuteQuestionLogic from '../logic/mocaMinuteQuestionLogic'
import MocaMinuteImage from '../../presentation/mocaMinuteImage'
import NavigationHelper from '../../../helpers/NavigationHelper'
import {Prompt} from '../../../router'
import styleFunctions from '../../../styles/styleFunctions'
import mocaMinuteLogic from '../../../screens/logic/mocaMinuteLogic'
import MocaMinuteFeedback from './mocaMinuteFeedback'

export default class MocaMinuteQuestion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activityIndicatorText: 'Retrieving MOCA Minute Question',
            allowNavigation: false,
            answers: [],
            askId: null,
            callingApi: false,
            questionId: null,
            questionImage: null,
            questionImageType: null,
            questionText: null,
            questionTime: 60,
            renderCountdown: false,
            selectedAnswerOption: null,
            answerSubmitted: false,
        }
    }

    componentDidMount() {
        MocaMinuteQuestionLogic.getMocaMinuteQuestion().then((apiResponse) => {
            if (apiResponse) {
                this.setState({
                    answers: apiResponse.Answers,
                    askId: apiResponse.AskId,
                    questionId: apiResponse.QuestionID,
                    questionImage: apiResponse.QuestionImage,
                    questionImageType: apiResponse.QuestionImageType,
                    questionText: apiResponse.QuestionText,
                    questionTime: apiResponse.TimeSeconds,
                    renderCountdown: true,
                })
            }
        })

        if (Platform.OS === 'web') {
            window.onbeforeunload = function (event) {
                //Modern browsers don't allow custom messages, but we'll put one anyway in case it ever makes a comeback!
                return 'Leaving this page will result in the question being marked as incorrect. Do you wish to leave this page?'
            }
        } else if (Platform.OS === 'android') {
            BackHandler.addEventListener('hardwareBackPress', this.androidBackPress)
        }
    }

    componentWillUnmount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = null
        } else if (Platform.OS === 'android') {
            BackHandler.removeEventListener('hardwareBackPress', this.androidBackPress)
        }
    }

    androidBackPress() {
        Alert.alert(
            'Are you sure?',
            'Leaving this page will result in the question being marked as incorrect. Press OK to LEAVE the page. Press CANCEL to remain on the page.',
            [
                {
                    text: 'OK',
                    onPress: () => {
                        NavigationHelper.navigateBack()
                    },
                },
                {text: 'Cancel'},
            ],
            {cancelable: true},
        )

        return true
    }

    render() {
        MocaMinuteQuestionLogic.context = this

        return (
            <ScrollView style={{backgroundColor: ColorEnums.backgroundGray}} overScrollMode="never">
                {Platform.OS === 'web' && (
                    <Prompt
                        when={!this.state.allowNavigation}
                        message={
                            'Leaving this page will result in the question being marked as incorrect. Click OK to LEAVE the page. Click CANCEL to remain on the page.'
                        }
                    />
                )}
                <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCAMinute} />
                <ABAContainer
                    activityText={this.state.activityIndicatorText}
                    containerTitle={'ANSWER QUESTION'}
                    showActivityIndicator={this.state.callingApi}
                >
                    <View>
                        {this.state.renderCountdown === true && (
                            <View style={{alignItems: 'center', paddingBottom: 10}}>
                                <ABACountdownCircle
                                    ref={(countdownCircle) => {
                                        this.countdownCircle = countdownCircle
                                    }}
                                    expirationEvent={() => {
                                        if (!this.state.answerSubmitted) {
                                            this.setState({allowNavigation: true})
                                            mocaMinuteLogic.swapMocaComponent(MocaMinuteFeedback, {
                                                AskId: this.state.askId,
                                            })
                                        }
                                    }}
                                    seconds={this.state.questionTime}
                                />
                            </View>
                        )}

                        <MocaMinuteImage
                            image={this.state.questionImage}
                            imageType={this.state.questionImageType}
                        />
                        <ABAWebView htmlToRender={this.state.questionText || ''} />

                        <ABAMMAnswerOptions
                            onPress={(option) => {
                                this.setState({selectedAnswerOption: option})
                            }}
                            options={this.state.answers}
                        />

                        <ABATouchableHighlight
                            icon={'chevron-right'}
                            isDisabled={this.state.selectedAnswerOption === null}
                            onPressEvent={() => {
                                this.countdownCircle.stop()
                                this.setState({allowNavigation: true, answerSubmitted: true})
                                MocaMinuteQuestionLogic.upsertMocaMinuteResponse()
                            }}
                            text={'Submit'}
                        />

                        <Text
                            style={Object.assign({}, styleFunctions.placeholderText('center'), {
                                paddingTop: 20,
                            })}
                        >
                            {
                                'By clicking submit, I attest that I am the person assigned to this question and I am the one answering it.'
                            }
                        </Text>
                    </View>
                </ABAContainer>
            </ScrollView>
        )
    }
}
