import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Text, TouchableHighlight, View} from 'react-native'

import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'

export default class ABATouchableHighlight extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    getPadding = () => {
        if (this.props.text) return 30

        if (this.props.small) return 10

        return 15
    }

    render() {
        return (
            <View>
                <TouchableHighlight
                    disabled={this.props.isDisabled === true}
                    style={[
                        styleFunctions.buttonClick(),
                        {
                            display: 'flex',
                            alignSelf: 'flex-start',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                                this.props.isDisabled === true
                                    ? ColorEnums.blueGray
                                    : this.props.buttonStyle.backgroundColor,
                            paddingLeft: this.getPadding(),
                            paddingRight: this.getPadding(),
                            minHeight: this.props.small ? 30 : 40,
                            width: this.props.width,
                        },
                        this.props.buttonStyle,
                    ]}
                    onPress={this.props.onPressEvent}
                    underlayColor={this.props.underlayColor}
                    testID={'ABATouchableHighlight' + this.props.text}
                >
                    <View style={{alignItems: 'center', flexDirection: 'row'}}>
                        <Text
                            style={{
                                ...this.props.textStyle,
                                flexWrap: 'wrap',
                                textAlign: 'center',
                                color:
                                    this.props.isDisabled === true
                                        ? ColorEnums.white
                                        : this.props.textStyle.color,
                            }}
                        >
                            {this.props.text}
                        </Text>
                        {this.props.icon && (
                            <Text
                                style={{
                                    fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                    color:
                                        this.props.isDisabled === true
                                            ? ColorEnums.white
                                            : this.props.textStyle.color,
                                    paddingLeft: this.props.text ? 12 : 0,
                                }}
                            >
                                {IconEnums[this.props.icon]}
                            </Text>
                        )}
                        {this.props.children}
                    </View>
                </TouchableHighlight>
            </View>
        )
    }
}

ABATouchableHighlight.propTypes = {
    buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    onPressEvent: PropTypes.func.isRequired,
    text: PropTypes.string,
    textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    underlayColor: PropTypes.string,
    width: PropTypes.string,
    small: PropTypes.bool,
}

ABATouchableHighlight.defaultProps = {
    buttonStyle: styleFunctions.buttonClick(),
    small: false,
    isDisabled: false,
    text: null,
    textStyle: {color: 'white', fontFamily: 'Lato', fontSize: 16},
    underlayColor: ColorEnums.lightBlue,
}
