import React, { Component } from 'react';
import {
    View,
    Text
} from "react-native";
import HelperMethods from "../../helpers/HelperMethods";
import NavigationShell from "../../components/presentation/navigationShell";

export default class VolunteerCensus extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        volunteerCensusContext = this;
        navigationContext = this;
        HelperMethods.setNavigationData(volunteerCensusContext);
        const {isExaminer} = this.navigationData;
        return (
            <NavigationShell context={volunteerCensusContext}>
                <View style={{ flex: 1 }}>
                    {
                        isExaminer &&
                        <Text>I Am an Examiner</Text>
                    }
                    <Text>Volunteer Census Placeholder</Text>
                </View>
            </NavigationShell>
        )
    }
}