import {Platform} from 'react-native'

import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import SubstanceAbuseStep6 from '../presentation/substanceAbuseStep6'
import SubstanceAbuseStep8 from '../presentation/substanceAbuseStep8'

const SubstanceAbuseLogicStep7 = {
    context: null,

    GetSubstanceAbuseData: function () {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('substanceabuseinfo/getSAData', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetPrincipalFacilityReferences: function () {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        const {Principal_Facility_Reference} = GeneralEnums.cert_referenceType
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    Type: Principal_Facility_Reference,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('reference/getReferenceIDs', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving AfterCare Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables: function (SAData) {
        const [SubstanceAbuseData, principalFacilityReferenceIDList] = SAData
        if (SubstanceAbuseData) {
            this.context.setState({
                cert_otherinfo: SubstanceAbuseData.cert_otherinfo,
                principalFacilityReferenceIDList,
            })
        }
    },
    CancelAddReference() {
        SubstanceAbuseLogicStep7.context.setState({
            collapsedReference: null,
        })
    },
    UpdateSubstanceAbuseStep7: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {cert_otherinfo} = this.context.state

            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep7`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
                cert_otherinfo,
                rs_statuscode: substanceAbuseContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    UpdateGrid: function (componentIdx = null, validated = true) {
        if (!validated) {
            return
        }
        SubstanceAbuseLogicStep7.GetPrincipalFacilityReferences().then(
            (principalFacilityReferenceIDList) => {
                SubstanceAbuseLogicStep7.context.setState({
                    principalFacilityReferenceIDList,
                    addNewPrincipalFacilityReference: false,
                    referencesMissing: false,
                    collapsedReference: null,
                })
                registrationStepsDisplayContext.setState({ShowContinue: true})
            },
        )
    },

    SaveLogic() {
        if (this.context.state.principalFacilityReferenceIDList.length === 0) {
            return new Promise((resolve) => {
                this.context.setState({referencesMissing: true})
                resolve(false)
            })
        }
        return new Promise((resolve) => {
            this.UpdateSubstanceAbuseStep7().then(() => {
                substanceAbuseContext.setState({
                    CurrentComponentStep: SubstanceAbuseStep8,
                })
                resolve(false)
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep6,
            })
            resolve(false)
        })
    },
}

export default SubstanceAbuseLogicStep7
