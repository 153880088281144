import moment from 'moment'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods'
import SubstanceAbuseStep2 from '../presentation/substanceAbuseStep2'

const SubstanceAbuseLogicStep1 = {
    context: null,

    GetSubstanceAbuseBeginData: function () {
        return new Promise((resolve) => {
            SubstanceAbuseLogicStep1.context.setState({CallingApi: true})
            ABAPortalAPI.post('substanceabuseinfo/GetSABegin', {
                Order: SubstanceAbuseLogicStep1.context.props.Order,
                CR2ID: SubstanceAbuseLogicStep1.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Substance Abuse Information')
                    resolve(null)
                })
                .finally(function () {
                    SubstanceAbuseLogicStep1.context.setState({CallingApi: false})
                })
        })
    },

    CreateSubstanceAbuseBegin: function () {
        return new Promise((resolve) => {
            SubstanceAbuseLogicStep1.context.setState({CallingCreateApi: true})
            ABAPortalAPI.post('substanceabuseinfo/SABeginInsert', {
                Cert_AlcoholAbuse: SubstanceAbuseLogicStep1.context.state.AlcoholAbuse,
                Cert_DrugAbuse: SubstanceAbuseLogicStep1.context.state.DrugAbuse,
                ContactId: SubstanceAbuseLogicStep1.context.state.SAReponseData.ContactId,
                Crt_ProgramType: SubstanceAbuseLogicStep1.context.state.SAReponseData.Crt_ProgramType,
                Cert_substanceabuseId:
                    SubstanceAbuseLogicStep1.context.state.SAReponseData.Cert_substanceabuseId,
                LastName: SubstanceAbuseLogicStep1.context.state.SAReponseData.LastName,
                FirstName: SubstanceAbuseLogicStep1.context.state.SAReponseData.FirstName,
                rs_statuscode: SubstanceAbuseLogicStep1.context.state.SAReponseData.rs_statuscode,
                cert_relapse1: SubstanceAbuseLogicStep1.context.state.cert_relapse1,
                aba_substanceabuse1: SubstanceAbuseLogicStep1.context.state.aba_substanceabuse1,
                PreviousSAExists: SubstanceAbuseLogicStep1.context.state.PreviousSAExists,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Substance Abuse Information')
                    resolve(false)
                })
                .finally(function () {
                    SubstanceAbuseLogicStep1.context.setState({CallingCreateApi: false})
                })
        })
    },

    PopulateVariables: function (data) {
        if (data.Cert_AlcoholAbuse === null) {
            data.Cert_AlcoholAbuse = 'null'
        }

        if (data.Cert_DrugAbuse === null) {
            data.Cert_DrugAbuse = 'null'
        }
        const cert_lastepisode =
            data.cert_lastepisode && moment(new Date(data.cert_lastepisode)).format('MM/DD/YYYY')
        const {cert_relapse1} = data
        SubstanceAbuseLogicStep1.context.setState({
            AlcoholAbuse: data.Cert_AlcoholAbuse.toString(),
            DrugAbuse: data.Cert_DrugAbuse.toString(),
            SAReponseData: data,
            aba_substanceabuse1: data.aba_substanceabuse1,
            PreviousSAExists: data.PreviousSAExists,
            cert_relapse1: cert_relapse1 === null ? null : cert_relapse1.toString(),
            cert_lastepisode,
        })
    },

    CheckRequiredDropdown: function (field, value) {
        var status = GeneralEnums.textInputStatus.success
        if (value == 'null') {
            status = GeneralEnums.textInputStatus.error
        }
        SubstanceAbuseLogicStep1.context.setState({
            [field + 'Status']: status,
            [field]: value,
        })
        substanceAbuseContext.setState({
            [`Cert_${field}`]: +value,
        })
    },

    CertRelapse1NoSelected() {
        SubstanceAbuseLogicStep1.context.setState({cert_relapse1: '0'})
    },

    CertRelapse1YesSelected() {
        SubstanceAbuseLogicStep1.context.setState({cert_relapse1: '1'})
    },

    ValidateForm() {
        const {
            InputStatus,
            aba_substanceabuse1,
            PreviousSAExists,
            DrugAbuse,
            AlcoholAbuse,
            cert_relapse1,
        } = this.context.state
        const {error} = GeneralEnums.textInputStatus

        if (AlcoholAbuse == 'null') {
            InputStatus.AlcoholAbuseStatus = error
        } else {
            InputStatus.AlcoholAbuseStatus = GeneralEnums.textInputStatus.success
        }

        if (DrugAbuse == 'null') {
            InputStatus.DrugAbuseStatus = error
        } else {
            InputStatus.DrugAbuseStatus = GeneralEnums.textInputStatus.success
        }

        if (
            !!aba_substanceabuse1 &&
            !!PreviousSAExists &&
            DrugAbuse === '0' &&
            AlcoholAbuse === '0' &&
            cert_relapse1 === null
        ) {
            InputStatus.cert_relapse1 = error
        } else {
            InputStatus.cert_relapse1 = GeneralEnums.textInputStatus.success
        }

        SubstanceAbuseLogicStep1.context.setState({InputStatus})
        const validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        return validated
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (SubstanceAbuseLogicStep1.ValidateForm()) {
                this.context.setState({CallingApi: true})
                SubstanceAbuseLogicStep1.CreateSubstanceAbuseBegin().then((response) => {
                    if (!response) {
                        this.context.setState({CallingApi: false})
                        resolve(false)
                        return
                    }
                    const {
                        aba_substanceabuse1,
                        PreviousSAExists,
                        DrugAbuse,
                        AlcoholAbuse,
                        cert_relapse1,
                    } = this.context.state
                    if (
                        AlcoholAbuse === '1' ||
                        DrugAbuse === '1' ||
                        cert_relapse1 === '1' ||
                        (aba_substanceabuse1 == 1 && !PreviousSAExists)
                    ) {
                        substanceAbuseContext.setState({
                            CurrentComponentStep: SubstanceAbuseStep2,
                            SubstanceAbuseID: response,
                            ContactId: SubstanceAbuseLogicStep1.context.state.SAReponseData.ContactId,
                        })
                        resolve(false)
                    } else {
                        this.context.props.SetPreviousVisibility(true)
                        resolve(true)
                    }
                })
            } else {
                resolve(false)
            }
        })
    },

    Previous() {
        return new Promise((resolve) => {
            resolve(true)
        })
    },
}

export default SubstanceAbuseLogicStep1
