import React, {Component} from 'react'
import {Platform, StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import {connect} from 'react-redux'

import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABACheckbox from '../../components/ABACustom/presentation/ABACheckbox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ABAAffirmationRadio from '../../components/ABACustom/presentation/ABAAffirmationRadio'
import NavigationShell from '../../components/presentation/navigationShell'
import PersonalInfoLogic from '../logic/personalInfoLogic'
import styleFunctions from '../../styles/styleFunctions'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import LoginLogic from '../../screens/logic/loginLogic'
import ABAModal from '../../components/ABACustom/presentation/ABAModal'
import ABAAlert from '../../components/ABACustom/presentation/ABAAlert'
import StrikeIronForm from '../../components/presentation/strikeIronForm'
import {setBiometrics} from '../../redux/ActionCreators'

const styles = StyleSheet.create({
    dropdown: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        marginBottom: 30,
    },

    popover: {
        paddingTop: 8,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        backgroundColor: ColorEnums.aquaHaze,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 100,
        overflow: 'hidden',
        borderRadius: 4,
        borderWidth: 1,
        zIndex: 2,
        maxWidth: 560,
    },

    popoverText: {
        fontSize: 12,
        paddingBottom: 7,
    },

    confirmPIBox: {
        backgroundColor: ColorEnums.pomegranateLight,
        borderRadius: 4,
        flexDirection: 'row',
        marginBottom: 20,
        minHeight: 50,
        padding: 20,
    },

    confirmPIText: {
        color: ColorEnums.pomegranateDark,
        fontSize: 20,
    },
})

const mapStateToProps = (state) => ({
    settings: state.settings,
    biometrics: state.biometrics,
})

const mapDispatchToProps = {setBiometrics}

class PersonalInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            AddressId: '',
            originalAddress: {
                AddressLine1: '',
                AddressLine2: '',
                City: '',
                CountryId: 'null',
                PostalCode: '',
                StateId: 'null',
                StateProvinceForeign: '',
            },
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            CountryId: 'null',
            PostalCode: '',
            StateId: 'null',
            StateProvinceForeign: '',
            AddressType: '',
            BusinessPhone: '',
            DateOfBirth: '',
            Department: '',
            FaxNumber: '',
            FullName: '',
            Gender: 'null',
            HomePhone: '',
            MobilePhone: '',
            NPINumber: '',
            Organization: '',
            PrimaryEmail: '',
            ShowStrikeIronScreen: false,
            ShowEnableBiometrics: false,
            StackStateAndZip: false,
            InputStatus: {
                AddressId: GeneralEnums.textInputStatus.default,
                AddressLine1: GeneralEnums.textInputStatus.default,
                AddressLine2: GeneralEnums.textInputStatus.default,
                AddressType: GeneralEnums.textInputStatus.default,
                BusinessPhone: GeneralEnums.textInputStatus.default,
                City: GeneralEnums.textInputStatus.default,
                CountryId: GeneralEnums.textInputStatus.default,
                DateOfBirth: GeneralEnums.textInputStatus.disabled,
                Department: GeneralEnums.textInputStatus.default,
                Ethnicity: GeneralEnums.textInputStatus.default,
                FaxNumber: GeneralEnums.textInputStatus.default,
                FullName: GeneralEnums.textInputStatus.default,
                Gender: GeneralEnums.textInputStatus.default,
                HomePhone: GeneralEnums.textInputStatus.default,
                MobilePhone: GeneralEnums.textInputStatus.default,
                NPINumber: GeneralEnums.textInputStatus.default,
                Organization: GeneralEnums.textInputStatus.default,
                PostalCode: GeneralEnums.textInputStatus.default,
                PrimaryEmail: GeneralEnums.textInputStatus.default,
                RaceEthnicity: GeneralEnums.textInputStatus.default,
                RaceEthnicityOtherText: GeneralEnums.textInputStatus.default,
                StateId: GeneralEnums.textInputStatus.default,
                StateProvinceForeign: GeneralEnums.textInputStatus.default,
            },
            MedicalSchool: {
                MedicalSchoolDisplay: 'No Medical School on Record',
                ConferredDate: '',
            },
            raceEthnicityOptions: [],
            raceEthnicityOtherText: '',
            ShowPopup: false,
            states: [],
            CallingApi: false,
            activityText: 'Loading Personal Information',
            phoneErrorMessage: 'Please enter a valid number.',
            genderOptions: [],
            ethnicityOptions: [],
            addressTypeOptions: [],
            selectedEthnicityOption: null,
            racePreferNotToReport: false,
        }
    }
    async componentDidMount() {
        PersonalInfoLogic.getAllData(this.props.location.state.ConfirmRaceEthnicityInfo)

        PersonalInfoLogic.getMedicalSchool().then((school) => {
            this.setState({MedicalSchool: school})
        })
    }

    countryChange(countryId) {
        if (!countryId || countryId === 'null') {
            return
        }
        if (
            countryId === GeneralEnums.cert_CountryId.USA ||
            countryId === GeneralEnums.cert_CountryId.Canada
        ) {
            this.setState({StateProvinceForeign: null})
        } else {
            this.setState({StateId: null})
        }
        PersonalInfoLogic.getStatesProvinces(countryId).then((states) => {
            personalInfoContext.setState({
                states: states,
            })
        })
    }

    renderRaceEthnicityError(inputStatus) {
        if (inputStatus === GeneralEnums.textInputStatus.error) {
            return {borderColor: ColorEnums.pomegranate, borderWidth: 1}
        }
    }

    renderStateAndPostal() {
        if (Platform.OS !== 'web' || (Platform.OS === 'web' && this.state.StackStateAndZip)) {
            return (
                <View style={{marginLeft: 'auto', marginRight: 'auto', width: '80%'}}>
                    {this.state.states.length > 0 ? (
                        <View style={{paddingBottom: 20}}>
                            <ABADropdown
                                options={this.state.states || []}
                                label="State/Province *"
                                placeholder="State *"
                                onChange={(value) => this.setState({StateId: value})}
                                selectedValue={this.state.StateId}
                                validationStatus={this.state.InputStatus.StateId}
                            />
                        </View>
                    ) : (
                        <ABATextInput
                            onChangeText={(text) => this.setState({StateProvinceForeign: text})}
                            placeholder="State/Province *"
                            textInputStatus={this.state.InputStatus.StateProvinceForeign}
                            returnKeyType="next"
                            value={this.state.StateProvinceForeign || ''}
                            width={'100%'}
                            onBlur={() =>
                                this.setState({
                                    StateProvinceForeign: HelperMethods.properCase(
                                        this.state.StateProvinceForeign,
                                    ),
                                })
                            }
                        />
                    )}

                    <ABATextInput
                        onChangeText={(text) => this.setState({PostalCode: text})}
                        placeholder="Postal Code *"
                        textInputStatus={this.state.InputStatus.PostalCode}
                        returnKeyType="next"
                        value={this.state.PostalCode || ''}
                        width={'100%'}
                    />
                </View>
            )
        }

        return (
            <View style={{flexDirection: 'row', marginLeft: 'auto', marginRight: 'auto', width: '80%'}}>
                <View style={{width: '45%'}}>
                    {this.state.states.length > 0 ? (
                        <ABADropdown
                            options={this.state.states || []}
                            label="State/Province *"
                            placeholder="State *"
                            onChange={(value) => this.setState({StateId: value})}
                            selectedValue={this.state.StateId}
                            validationStatus={this.state.InputStatus.StateId}
                        />
                    ) : (
                        <ABATextInput
                            onChangeText={(text) => this.setState({StateProvinceForeign: text})}
                            placeholder="State/Province *"
                            textInputStatus={this.state.InputStatus.StateProvinceForeign}
                            returnKeyType="next"
                            value={this.state.StateProvinceForeign || ''}
                            width={'100%'}
                            onBlur={() =>
                                this.setState({
                                    StateProvinceForeign: HelperMethods.properCase(
                                        this.state.StateProvinceForeign,
                                    ),
                                })
                            }
                        />
                    )}
                </View>

                <View style={{width: '45%', marginLeft: 'auto'}}>
                    <ABATextInput
                        onChangeText={(text) => this.setState({PostalCode: text})}
                        placeholder="Postal Code *"
                        textInputStatus={this.state.InputStatus.PostalCode}
                        returnKeyType="next"
                        value={this.state.PostalCode || ''}
                        width={'100%'}
                    />
                </View>
            </View>
        )
    }

    preferNotToReport() {
        let raceEthnicityOptions = [...this.state.raceEthnicityOptions]
        raceEthnicityOptions = raceEthnicityOptions.map((option, index) => {
            if (option.selected && option.Name !== 'Prefer not to report') {
                option.selected = false
            }
            return option
        })

        this.setState({
            raceEthnicityOptions,
            raceEthnicityOtherText: '',
            racePreferNotToReport: true,
        })
    }

    togglePopup() {
        this.setState({ShowPopup: !this.state.ShowPopup})
    }
    closeBiometricPrompt() {
        LoginLogic.disableBiometricPopup()
    }

    inputLayoutOnResize(event) {
        let componentWidth = event.nativeEvent.layout.width

        //615 is referring to the view's width, not the entire screen size
        if (componentWidth <= 615 && !this.state.StackStateAndZip) {
            this.setState({StackStateAndZip: true})
            return
        }

        if (componentWidth > 615 && this.state.StackStateAndZip) {
            this.setState({StackStateAndZip: false})
            return
        }
    }

    renderAlert() {
        if (this.navigationData.ConfirmRaceEthnicityInfo) {
            return (
                <View
                    style={{
                        borderRadius: 4,
                        marginBottom: 20,
                        minHeight: 50,
                        padding: 20,
                        backgroundColor: ColorEnums.pomegranateLight,
                    }}
                >
                    <Text style={{color: ColorEnums.pomegranateDark, fontSize: 20}}>
                        Please indicate your race and ethnicity below as we work to improve our knowledge
                        of representation in the field of anesthesiology.
                    </Text>
                </View>
            )
        }

        if (this.navigationData.ConfirmPersonalInfo) {
            return (
                <ABAAlert
                    text={
                        'To ensure you receive correspondence from the ABA in a timely manner, ' +
                        'each time you log into your portal account please verify your email address and contact information.'
                    }
                    color="red"
                />
            )
        }
    }

    render() {
        personalInfoContext = this
        navigationContext = this
        PersonalInfoLogic.context = personalInfoContext
        const {biometrics} = this.props
        HelperMethods.setNavigationData(personalInfoContext)

        let homePhonePlaceholder = 'Home Phone'
        let businessPhonePlaceholder = 'Business Phone'

        //if address is personal, then Home Phone is required.
        if (this.state.AddressType == 1) {
            homePhonePlaceholder = 'Home Phone *'
        }

        //if address is business, Business Phone is required
        if (this.state.AddressType == 2) {
            businessPhonePlaceholder = 'Business Phone *'
        }

        return (
            <NavigationShell
                context={personalInfoContext}
                showNav={!this.navigationData.ConfirmPersonalInfo}
            >
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray, paddingBottom: 50}}
                    onLayout={(event) => this.inputLayoutOnResize(event)}
                    ref={(comp) => {
                        this.componentRef = comp
                    }}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer
                        containerTitle={'PERSONAL INFORMATION'}
                        activityText={this.state.activityText}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        {this.renderAlert()}
                        <View
                            style={{
                                borderBottomColor: ColorEnums.cloudBurst,
                                borderBottomWidth: 2,
                                width: '80%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: 30,
                                marginTop: 30,
                                height: 30,
                                flexDirection: 'row',
                            }}
                        >
                            <TouchableWithoutFeedback
                                onPress={() => this.togglePopup()}
                                testID={'personalInfoFullName'}
                            >
                                <View style={{flexDirection: 'row'}}>
                                    <Text
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 16,
                                        }}
                                    >
                                        {this.state.FullName}
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.danube,
                                            fontSize: 14,
                                            paddingLeft: 5,
                                        }}
                                    >
                                        {IconEnums['question-circle']}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>

                        {this.state.ShowPopup && (
                            <TouchableWithoutFeedback onPress={() => this.togglePopup()}>
                                <View style={styles.popover}>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            position: 'absolute',
                                            right: 5,
                                            top: 0,
                                        }}
                                    >
                                        {IconEnums['times']}
                                    </Text>
                                    <Text
                                        style={[styleFunctions.containerTextBold(), styles.popoverText]}
                                    >
                                        To change or correct the spelling of your name:
                                    </Text>
                                    <Text style={[styleFunctions.containerText(), styles.popoverText]}>
                                        1. Email coms@theABA.org or fax (866) 999-7503 official
                                        documentation, like your marriage license or driver's license,
                                        showing your name change/correct spelling.
                                    </Text>
                                    <Text style={[styleFunctions.containerText(), styles.popoverText]}>
                                        Please allow 24 hours for this update to be reflected in your
                                        portal.
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        )}

                        <View>
                            <View style={styles.dropdown}>
                                <ABADropdown
                                    options={this.state.genderOptions || []}
                                    label="Gender *"
                                    placeholder="Gender *"
                                    onChange={(value) => {
                                        this.setState({Gender: value})
                                    }}
                                    validationStatus={personalInfoContext.state.InputStatus.Gender}
                                    selectedValue={personalInfoContext.state.Gender}
                                />
                            </View>

                            <ABATextInput
                                placeholder="Date of Birth"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.DateOfBirth}
                                onChangeText={(text) => this.setState({DateOfBirth: text})}
                                value={this.state.DateOfBirth || ''}
                            />

                            {this.state.ethnicityOptions?.length > 0 && (
                                <View
                                    style={[
                                        {
                                            width: '90%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            marginBottom: 30,
                                        },
                                        this.renderRaceEthnicityError(this.state.InputStatus.Ethnicity),
                                    ]}
                                >
                                    <View
                                        style={{
                                            marginLeft: '5%',
                                            marginRight: '5%',
                                            borderBottomColor: ColorEnums.backgroundGray,
                                            borderBottomWidth: 1,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: ColorEnums.cloudBurst,
                                                fontWeight: 'bold',
                                                width: '105%',
                                                borderBottomColor: ColorEnums.backgroundGray,
                                                borderBottomWidth: 1,
                                                marginBottom: 15,
                                                paddingBottom: 20,
                                            }}
                                        >
                                            Are you of Hispanic, Latino or Spanish origin? *
                                        </Text>
                                        {this.state.ethnicityOptions.map((option) => {
                                            const {selectedEthnicityOption} = this.state
                                            return (
                                                <View style={{marginBottom: 30}}>
                                                    <ABACheckbox
                                                        isChecked={
                                                            selectedEthnicityOption === option.value
                                                        }
                                                        key={option.value}
                                                        label={option.label}
                                                        labelStyle={{
                                                            fontSize: 14,
                                                            color: ColorEnums.cloudBurst,
                                                            width: '100%',
                                                        }}
                                                        onChangeEvent={() => {
                                                            if (
                                                                selectedEthnicityOption === option.value
                                                            ) {
                                                                this.setState({
                                                                    selectedEthnicityOption: null,
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    selectedEthnicityOption:
                                                                        option.value,
                                                                })
                                                            }
                                                        }}
                                                        useValidation={false}
                                                        justifyContent="flex-start"
                                                        width="100%"
                                                    />
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            )}

                            {this.state.raceEthnicityOptions.length > 0 && (
                                <View
                                    style={[
                                        {
                                            width: '90%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            marginBottom: 30,
                                        },
                                        this.renderRaceEthnicityError(
                                            this.state.InputStatus.RaceEthnicity,
                                        ),
                                    ]}
                                >
                                    <View
                                        style={{
                                            marginLeft: '5%',
                                            marginRight: '5%',
                                            borderBottomColor: ColorEnums.backgroundGray,
                                            borderBottomWidth: 1,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: ColorEnums.cloudBurst,
                                                fontWeight: 'bold',
                                                width: '105%',
                                                borderBottomColor: ColorEnums.backgroundGray,
                                                borderBottomWidth: 1,
                                                marginBottom: 15,
                                                paddingBottom: 20,
                                            }}
                                        >
                                            What is your race? (select all that apply) *
                                        </Text>
                                        {this.state.raceEthnicityOptions.map((option) => {
                                            // we are hiding any option that is no longer active
                                            if (option.StateCode === 1) {
                                                return
                                            }
                                            if (option.Name === 'Other') {
                                                return (
                                                    <View style={{marginBottom: 30}}>
                                                        <ABACheckbox
                                                            isChecked={option.selected}
                                                            isDisabled={this.state.racePreferNotToReport}
                                                            key={option.OptionId}
                                                            label={option.Name}
                                                            labelStyle={{
                                                                fontSize: 14,
                                                                color: ColorEnums.cloudBurst,
                                                                width: '100%',
                                                            }}
                                                            onChangeEvent={() => {
                                                                this.setState((prevState) => ({
                                                                    raceEthnicityOtherText:
                                                                        !option.selected === false
                                                                            ? ''
                                                                            : this.state
                                                                                  .raceEthnicityOtherText,
                                                                    raceEthnicityOptions:
                                                                        prevState.raceEthnicityOptions.map(
                                                                            (prevRaceEthnicityOption) =>
                                                                                prevRaceEthnicityOption.Name ===
                                                                                option.Name
                                                                                    ? {
                                                                                          ...prevRaceEthnicityOption,
                                                                                          selected:
                                                                                              !option.selected,
                                                                                      }
                                                                                    : prevRaceEthnicityOption,
                                                                        ),
                                                                }))
                                                            }}
                                                            useValidation={false}
                                                            justifyContent="flex-start"
                                                            width="100%"
                                                        />
                                                        {option.selected && (
                                                            <View
                                                                style={
                                                                    Platform.OS !== 'web'
                                                                        ? {
                                                                              marginLeft: 10,
                                                                              marginTop: 10,
                                                                          }
                                                                        : {marginLeft: 25}
                                                                }
                                                            >
                                                                <ABATextInput
                                                                    placeholder="Other"
                                                                    value={
                                                                        this.state.raceEthnicityOtherText
                                                                    }
                                                                    onChangeText={(text) =>
                                                                        this.setState({
                                                                            raceEthnicityOtherText: text,
                                                                        })
                                                                    }
                                                                    textInputStatus={
                                                                        this.state.InputStatus
                                                                            .RaceEthnicityOtherText
                                                                    }
                                                                    width="100%"
                                                                    hideLabel={true}
                                                                />
                                                            </View>
                                                        )}
                                                    </View>
                                                )
                                            }
                                            return (
                                                <View style={{marginBottom: 30}}>
                                                    <ABACheckbox
                                                        isChecked={option.selected}
                                                        isDisabled={
                                                            this.state.racePreferNotToReport &&
                                                            option.Name !== 'Prefer not to report'
                                                        }
                                                        key={option.OptionId}
                                                        label={option.Name}
                                                        labelStyle={{
                                                            fontSize: 14,
                                                            color: ColorEnums.cloudBurst,
                                                            width: '100%',
                                                        }}
                                                        onChangeEvent={() => {
                                                            if (
                                                                option.Name === 'Prefer not to report' &&
                                                                !option.selected
                                                            ) {
                                                                this.preferNotToReport()
                                                            } else if (
                                                                option.Name === 'Prefer not to report' &&
                                                                option.selected
                                                            ) {
                                                                this.setState({
                                                                    racePreferNotToReport: false,
                                                                })
                                                            }

                                                            this.setState((prevState) => ({
                                                                raceEthnicityOptions:
                                                                    prevState.raceEthnicityOptions.map(
                                                                        (prevRaceEthnicityOption) =>
                                                                            prevRaceEthnicityOption.Name ===
                                                                            option.Name
                                                                                ? {
                                                                                      ...prevRaceEthnicityOption,
                                                                                      selected:
                                                                                          !option.selected,
                                                                                  }
                                                                                : prevRaceEthnicityOption,
                                                                    ),
                                                            }))
                                                        }}
                                                        useValidation={false}
                                                        justifyContent="flex-start"
                                                        width="100%"
                                                    />
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            )}

                            <ABATextInputMask
                                placeholder="NPI #"
                                keyboardType="number-pad"
                                maxLength={10}
                                options={{mask: '9999999999'}}
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.NPINumber}
                                onChangeText={(text) => this.setState({NPINumber: text})}
                                value={this.state.NPINumber || ''}
                            />

                            <ABATextInput
                                placeholder="Primary E-mail *"
                                returnKeyType="next"
                                maxLength={100}
                                textInputStatus={this.state.InputStatus.PrimaryEmail}
                                onChangeText={(text) => this.setState({PrimaryEmail: text})}
                                value={this.state.PrimaryEmail || ''}
                            />

                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={50}
                                options={{mask: '(999) 999-9999'}}
                                onChangeText={(text) => this.setState({HomePhone: text})}
                                placeholder={homePhonePlaceholder}
                                textInputStatus={this.state.InputStatus.HomePhone}
                                returnKeyType="next"
                                value={this.state.HomePhone || ''}
                                errorMessage={this.state.phoneErrorMessage}
                            />

                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={50}
                                options={{mask: '(999) 999-9999'}}
                                onChangeText={(text) => this.setState({MobilePhone: text})}
                                placeholder="Mobile Phone"
                                textInputStatus={this.state.InputStatus.MobilePhone}
                                returnKeyType="next"
                                value={this.state.MobilePhone || ''}
                                errorMessage={this.state.phoneErrorMessage}
                            />

                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={50}
                                options={{mask: '(999) 999-9999'}}
                                onChangeText={(text) => this.setState({BusinessPhone: text})}
                                placeholder={businessPhonePlaceholder}
                                textInputStatus={this.state.InputStatus.BusinessPhone}
                                returnKeyType="next"
                                value={this.state.BusinessPhone || ''}
                                errorMessage={this.state.phoneErrorMessage}
                            />

                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={50}
                                options={{mask: '(999) 999-9999'}}
                                onChangeText={(text) => this.setState({FaxNumber: text})}
                                placeholder="Fax Number"
                                textInputStatus={this.state.InputStatus.FaxNumber}
                                returnKeyType="next"
                                value={this.state.FaxNumber || ''}
                                errorMessage={this.state.phoneErrorMessage}
                            />

                            <View style={styles.dropdown}>
                                <ABADropdown
                                    options={this.state.addressTypeOptions}
                                    label="Address Type *"
                                    placeholder="Address Type *"
                                    validationStatus={this.state.InputStatus.AddressType}
                                    onChange={(value) =>
                                        this.setState({
                                            AddressType: value,
                                            Organization: null,
                                            Department: null,
                                        })
                                    }
                                    selectedValue={
                                        this.state.AddressType ? this.state.AddressType : 'null'
                                    }
                                />
                            </View>

                            {this.state.AddressType == 2 && (
                                <View>
                                    <ABATextInput
                                        placeholder="Organization *"
                                        returnKeyType="next"
                                        onChangeText={(text) => this.setState({Organization: text})}
                                        value={this.state.Organization || ''}
                                        textInputStatus={this.state.InputStatus.Organization}
                                        onBlur={() =>
                                            this.setState({
                                                Organization: HelperMethods.properCase(
                                                    this.state.Organization,
                                                ),
                                            })
                                        }
                                    />

                                    <ABATextInput
                                        placeholder="Department *"
                                        returnKeyType="next"
                                        onChangeText={(text) => this.setState({Department: text})}
                                        value={this.state.Department || ''}
                                        textInputStatus={this.state.InputStatus.Department}
                                        onBlur={() =>
                                            this.setState({
                                                Department: HelperMethods.properCase(
                                                    this.state.Department,
                                                ),
                                            })
                                        }
                                    />
                                </View>
                            )}

                            <ABATextInput
                                onChangeText={(text) => this.setState({AddressLine1: text})}
                                placeholder="Address Line 1 *"
                                textInputStatus={this.state.InputStatus.AddressLine1}
                                returnKeyType="next"
                                value={this.state.AddressLine1 || ''}
                                onBlur={() =>
                                    this.setState({
                                        AddressLine1: HelperMethods.properCase(this.state.AddressLine1),
                                    })
                                }
                            />

                            <ABATextInput
                                onChangeText={(text) => this.setState({AddressLine2: text})}
                                placeholder="Address Line 2"
                                textInputStatus={this.state.InputStatus.AddressLine2}
                                returnKeyType="next"
                                value={this.state.AddressLine2 || ''}
                                onBlur={() =>
                                    this.setState({
                                        AddressLine2: HelperMethods.properCase(this.state.AddressLine2),
                                    })
                                }
                            />

                            <View style={styles.dropdown}>
                                <ABADropdown
                                    options={this.state.Countries || []}
                                    label="Country *"
                                    placeholder="Country *"
                                    onChange={(value) => {
                                        this.setState({CountryId: value})
                                        this.countryChange(value)
                                    }}
                                    validationStatus={personalInfoContext.state.InputStatus.CountryId}
                                    selectedValue={personalInfoContext.state.CountryId}
                                />
                            </View>

                            <ABATextInput
                                onChangeText={(text) => this.setState({City: text})}
                                placeholder="City *"
                                textInputStatus={this.state.InputStatus.City}
                                returnKeyType="next"
                                value={this.state.City || ''}
                                onBlur={() =>
                                    this.setState({
                                        City: HelperMethods.properCase(this.state.City),
                                    })
                                }
                            />

                            {this.renderStateAndPostal()}

                            <ABATouchableHighlight
                                icon={'angle-right'}
                                onPressEvent={() => {
                                    PersonalInfoLogic.validateFormData(this.componentRef)
                                }}
                                text={'Submit'}
                            />
                        </View>
                    </ABAContainer>
                    {biometrics.available && (
                        <>
                            <View style={{marginBottom: 30}} />
                            <ABAContainer containerTitle={`Login with ${biometrics.friendlyType}`}>
                                <View style={{width: '50%', alignSelf: 'center'}}>
                                    <ABAAffirmationRadio
                                        leftOptionContent="Yes"
                                        leftOptionOnPress={() => LoginLogic.enableBiometrics()}
                                        rightOptionContent="No"
                                        rightOptionOnPress={() => LoginLogic.disableBiometrics()}
                                        optionValues={{leftValue: true, rightValue: false}}
                                        selectedValue={biometrics.enabled}
                                    />
                                </View>
                            </ABAContainer>
                        </>
                    )}
                    <View style={{marginBottom: 30}} />

                    <ABAContainer containerTitle={'MEDICAL SCHOOL'}>
                        <View>
                            <View style={{paddingBottom: 5}}>
                                <Text style={[styleFunctions.containerTextBold(), {fontSize: 14}]}>
                                    {this.state.MedicalSchool.MedicalSchoolDisplay
                                        ? this.state.MedicalSchool.MedicalSchoolDisplay
                                        : 'No Medical School on Record'}
                                </Text>
                            </View>

                            {this.state.MedicalSchool.ConferredDate ? (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        borderBottomColor: ColorEnums.cloudBurst,
                                        borderTopColor: ColorEnums.cloudBurst,
                                        borderBottomWidth: 2,
                                        borderTopWidth: 1,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                    }}
                                >
                                    <View style={{width: '40%'}}>
                                        <Text
                                            style={{
                                                fontFamily: 'Lato',
                                                color: ColorEnums.blueGray,
                                            }}
                                        >
                                            Conferred
                                        </Text>
                                    </View>
                                    <View style={{width: '60%'}}>
                                        <Text style={[styleFunctions.containerText(), {fontSize: 14}]}>
                                            {this.state.MedicalSchool.ConferredDate}
                                        </Text>
                                    </View>
                                </View>
                            ) : (
                                <View />
                            )}
                        </View>
                        <ABAHyperLink
                            label="Contact Us for Updates"
                            url="https://theaba.org/contact.html"
                            style={{
                                color: ColorEnums.danube,
                                fontFamily: 'Lato',
                                fontSize: 12,
                                marginTop: 10,
                            }}
                        />
                    </ABAContainer>

                    {this.state.ShowStrikeIronScreen && (
                        <ABAModal
                            isVisible={this.state.ShowStrikeIronScreen}
                            onDismiss={() => this.setState({ShowStrikeIronScreen: false})}
                            onBackdropPress={() => this.setState({ShowStrikeIronScreen: false})}
                        >
                            <StrikeIronForm
                                AddressLine1={this.state.AddressLine1}
                                AddressLine2={this.state.AddressLine2}
                                City={this.state.City}
                                StateSelected={this.state.StateId}
                                ZipCode={this.state.PostalCode}
                                onPressEvent={() => this.setState({ShowStrikeIronScreen: false})}
                                CountryName={
                                    this.state.Countries.find(
                                        (option) => option.value === this.state.CountryId,
                                    ).label
                                }
                                CountrySelected={this.state.CountryId}
                                StateName={
                                    this.state.states.find(
                                        (option) => option.value === this.state.StateId,
                                    ).label
                                }
                                saveAsFound={(data) => PersonalInfoLogic.saveAsFound(data)}
                                saveAsEntered={(data) => PersonalInfoLogic.saveAsEntered(data)}
                            />
                        </ABAModal>
                    )}

                    {biometrics.available && (
                        <ABAModal
                            isVisible={!biometrics.seenMessage}
                            onDismiss={() => this.closeBiometricPrompt()}
                            onBackdropPress={() => this.closeBiometricPrompt()}
                        >
                            <ABAConfirmationBox
                                messageText1={'Enable Biometrics:'}
                                messageText2={`Would you like to enable ${biometrics.friendlyType}?`}
                                buttonLeftText="Yes"
                                buttonRightText="No"
                                leftButtonFunction={() => {
                                    LoginLogic.enableBiometrics()
                                    this.closeBiometricPrompt()
                                }}
                                rightButtonFunction={() => {
                                    LoginLogic.disableBiometrics()
                                    this.closeBiometricPrompt()
                                }}
                            />
                        </ABAModal>
                    )}
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
