import {Platform} from 'react-native'
import ConfigurationProps from '../../../configuration'
import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import mocaMinuteLogic from '../../../screens/logic/mocaMinuteLogic'
import mocaMinuteConfRel from '../presentation/mocaMinuteConfRel'
import MocaMinuteLanding from '../presentation/mocaMinuteLanding'

const MocaMinuteQuestionLogic = {
    context: null,

    getMocaMinuteQuestion: function () {
        return new Promise((resolve) => {
            MocaMinuteQuestionLogic.context.setState({callingApi: true})

            ABAPortalAPI.get(
                'mocaMinute/getMocaMinuteQuestion?platform=' +
                    Platform.OS +
                    '&appVersion=' +
                    ConfigurationProps.APP_VERSION,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        MocaMinuteQuestionLogic.context.setState({allowNavigation: true})
                        mocaMinuteLogic.swapMocaComponent(MocaMinuteLanding)
                    }
                    if (error.response?.status === 503) {
                        MocaMinuteQuestionLogic.context.setState({allowNavigation: true})
                    }
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Question')
                    resolve(null)
                })
                .finally(function () {
                    MocaMinuteQuestionLogic.context.setState({callingApi: false})
                })
        })
    },

    upsertMocaMinuteResponse: function () {
        return new Promise((resolve) => {
            MocaMinuteQuestionLogic.context.setState({
                callingApi: true,
                activityIndicatorText: 'Scoring MOCA Minute Question',
            })
            const {
                selectedAnswerOption,
                askId,
                questionId,
                questionText,
                questionImage,
                questionImageType,
            } = MocaMinuteQuestionLogic.context.state
            ABAPortalAPI.post('mocaMinute/upsertMocaMinuteResponse', {
                AnswerId: selectedAnswerOption.AnswerId,
                AskId: askId,
                Platform: Platform.OS,
                QuestionId: questionId,
            })
                .then(function (response) {
                    resolve(response.data)
                    mocaMinuteLogic.swapMocaComponent(
                        mocaMinuteConfRel,
                        {
                            AskId: askId,
                            QuestionText: questionText,
                            SelectedAnswerOption: selectedAnswerOption,
                            QuestionImage: questionImage,
                            QuestionImageType: questionImageType,
                        },
                        false,
                    )
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Saving MOCA Minute Response',
                        MocaMinuteQuestionLogic.upsertMocaMinuteResponse,
                    )
                    MocaMinuteQuestionLogic.context.setState({answerSubmitted: false})
                })
        })
    },
}

export default MocaMinuteQuestionLogic
