import React, {Component} from 'react'
import {
    View,
    Text,
    StyleSheet,
    Platform,
    ActivityIndicator,
    findNodeHandle,
    UIManager,
} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import QIActivitiesLogic from '../logic/qiActivitiesLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import QI_IMP from '../../components/presentation/qi_imp'
import NavigationHelper from '../../helpers/NavigationHelper'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import PrintedFooter from '../../components/presentation/printedFooter'

export default class QIActivities extends Component {
    constructor(props) {
        super(props)
        var currentTime = new Date()
        this.currentYear = currentTime.getFullYear()
        this.YearList = [
            {label: 'Current Year', value: this.currentYear.toString()},
            {label: 'Current and Last Year', value: (this.currentYear - 1).toString()},
            {label: 'Last Year', value: 'lastyear'},
            {label: 'Last 5 Years', value: (this.currentYear - 4).toString()},
            {label: 'Last 10 Years', value: (this.currentYear - 9).toString()},
            {label: 'Show All', value: '1900'},
        ]
        this.state = {
            QIdata: [],
            QIdataToShow: [],
            CallingApi: false,
            CallingDeleteApi: false,
            nrRecsToShow: 10,
            selectedYear: '1900',
            validationStatus: GeneralEnums.textInputStatus.default,
            showDeleteConfirm: false,
            recordToDelete: '',
            recordToDeleteName: '',
            recordToDeleteCompleted: '1/1/1900',
            ComponentWidth: null,
        }
        this.styles = StyleSheet.create({
            activityTypeTitle: {
                textAlign: 'center',
                paddingLeft: 10,
                fontSize: 15,
                color: ColorEnums.white,
                fontWeight: 'bold',
            },
            activityTitle: {
                textAlign: 'left',
                paddingLeft: 10,
                fontSize: 15,
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
                width: '90%',
            },
            dataRowView: {
                width: '100%',
                flexDirection: 'row',
                marginBottom: 3,
                marginTop: 3,
                alignItems: 'flex-end',
            },
            dataLableStyle: {
                width: '50%',
                fontSize: 14,
                fontFamily: 'Lato',
                paddingLeft: 20,
            },
            dataRowStyle: {
                width: '50%',
                fontSize: 14,
                marginRight: 20,
                fontFamily: 'Lato',
                fontWeight: 'bold',
                textAlign: 'right',
            },
            linkStyle: {
                fontSize: 14,
                fontFamily: 'Lato',
                color: ColorEnums.danube,
                marginLeft: 10,
                textAlign: 'right',
            },
            popupContainer: {
                alignSelf: 'center',
                alignItems: 'center',
                backgroundColor: '#F5FCFF88',
                paddingBottom: 20,
                width: '95%',
                zIndex: 5,
            },
            accordionIconStyle: {
                color: 'white',
                fontSize: 12,
                paddingTop: 3,
            },
            accordionButtonWidth: {
                width: '97%',
            },
        })
    }

    updateDropdownSelectedValue(value) {
        if (value == 'null') {
            value = this.currentYear
        }
        this.setState({selectedYear: value})
        this.GetQIRecords(this.state.QIdata, value)
    }

    deleteQIRecord() {
        QIContext.setState({showDeleteConfirm: false})
        QIActivitiesLogic.deleteActivity().then((response) => {
            if (response) {
                QIActivitiesLogic.getQIActivities().then((responsedata) => {
                    QIContext.setState({QIdata: responsedata, QIdataToShow: responsedata})
                    QIContext.updateDropdownSelectedValue(QIContext.state.selectedYear.toString())
                })
            }
        })
    }

    GetQIRecords(data, value) {
        let YearData = []
        var YearDateCompleted = this.currentYear
        if (typeof value === 'undefined') {
            value = this.currentYear
        }
        for (var i = 0; i < data.length; i++) {
            YearDateCompleted = data[i].DateCompleted.substring(6, 11)
            if (value === 'lastyear') {
                if (YearDateCompleted === (this.currentYear - 1).toString()) {
                    YearData.push(data[i])
                    continue
                }
            }
            if (YearDateCompleted >= value) {
                YearData.push(data[i])
                continue
            }
        }
        this.setState({QIdataToShow: YearData})
    }

    resetStateVars() {
        QIContext.setState({showDeleteConfirm: false, recordToDelete: ''})
    }

    componentDidMount() {
        QIActivitiesLogic.getQIActivities().then((responsedata) => {
            this.setState({QIdata: responsedata, QIdataToShow: responsedata})
            this.updateDropdownSelectedValue(this.state.selectedYear.toString())
        })
    }

    render() {
        navigationContext = this
        QIContext = this
        QIActivitiesLogic.context = this
        HelperMethods.setNavigationData(this)

        return (
            <NavigationShell context={this} print={true}>
                <ABAKeyboardAwareScrollView
                    ref="_scrollView"
                    onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                    style={{backgroundColor: ColorEnums.backgroundGray}}
                >
                    {this.state.CallingDeleteApi && (
                        <View
                            style={[
                                styleFunctions.loadingOverlay(),
                                {
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 10,
                                },
                            ]}
                        >
                            <View style={{alignSelf: 'center'}}>
                                <Text>Deleting QI Record</Text>
                                <ActivityIndicator color={ColorEnums.indigo} size="large" />
                            </View>
                        </View>
                    )}
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                    {this.state.recordToDelete.length === 0 && (
                        <ABAContainer
                            activityText={'Loading QI Activities'}
                            containerTitle={'QI Activity Listing'}
                            showActivityIndicator={this.state.CallingApi}
                        >
                            <View>
                                <View style={{marginBottom: 20}}>
                                    <Text style={{fontFamily: 'Lato'}}>
                                        Click the button below, under QI-1 or QI-2, to attest to
                                        completing quality improvement (QI) activities. You must attest
                                        to completing 25 points of QI activity every five years. You will
                                        complete 25 points total if you are on a five-year certification
                                        cycle and 50 points total if you are on a 10-year certification
                                        cycle. For examples of QI activities,
                                        <ABAHyperLink
                                            url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_QI_Activities.pdf"
                                            label=" view our approved activity list."
                                        />
                                    </Text>
                                </View>
                                <Text
                                    style={{fontFamily: 'Lato', marginBottom: 20, fontStyle: 'italic'}}
                                >
                                    <Text>Please note: Your attestation is subject to audit.</Text> 
                                </Text>
                                <ABAAccordion
                                    title="QI Activity Progress"
                                    accordionContent={
                                        <QI_IMP dashboard={false} ShowActivitiesLink={false} />
                                    }
                                    accordionContainerStyle={[
                                        styleFunctions.Accordioncontainer(),
                                        {width: '97%'},
                                    ]}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionTitleStyle={this.styles.activityTitle}
                                    accordionExpanded={true}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    accordionButtonStyle={this.styles.accordionButtonWidth}
                                />
                                <View style={{alignSelf: 'center', width: '70%', marginBottom: 20}}>
                                    <ABADropdown
                                        ref="optYears"
                                        label="Display QI for:"
                                        placeholder=""
                                        options={this.YearList}
                                        width="100%"
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {fontSize: 12, width: '60%'},
                                        ]}
                                        selectedValue={this.state.selectedYear}
                                        validationStatus={this.state.validationStatus}
                                        onChange={(value) => this.updateDropdownSelectedValue(value)}
                                    />
                                </View>

                                {!this.state.CallingApi && (
                                    <ABAAccordion
                                        title="QI Activity Details"
                                        accordionContent={this.state.QIdataToShow.map((item, index) => {
                                            return (
                                                index <= this.state.nrRecsToShow - 1 && (
                                                    <View key={index}>
                                                        <ABAAccordion
                                                            key={item.IMPID}
                                                            title={item.IMPName}
                                                            title2={
                                                                item.IMPID.length > 0 && (
                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: 'Lato',
                                                                                fontSize: 14,
                                                                                marginLeft: 22,
                                                                            }}
                                                                        />
                                                                        <Text
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: 'Lato',
                                                                                fontSize: 14,
                                                                                marginRight: 7,
                                                                            }}
                                                                        >
                                                                            {item.DateCompleted}
                                                                        </Text>
                                                                        <Text
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: 'Lato',
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            Points:{' '}
                                                                        </Text>
                                                                        <Text
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: 'Lato',
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            {parseFloat(
                                                                                Math.round(
                                                                                    item.PointsGranted *
                                                                                        100,
                                                                                ) / 100,
                                                                            ).toFixed(2)}
                                                                        </Text>
                                                                    </View>
                                                                )
                                                            }
                                                            accordionContent={
                                                                <View
                                                                    style={{
                                                                        borderBottomWidth: 1,
                                                                        borderBottomColor:
                                                                            ColorEnums.lightGray,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <View
                                                                        style={[this.styles.dataRowView]}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles
                                                                                    .dataLableStyle,
                                                                            ]}
                                                                        >
                                                                            Audit Status
                                                                        </Text>
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles.dataRowStyle,
                                                                            ]}
                                                                        >
                                                                            {item.RecordStatusName}
                                                                        </Text>
                                                                    </View>
                                                                    <View
                                                                        style={[this.styles.dataRowView]}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles
                                                                                    .dataLableStyle,
                                                                            ]}
                                                                        >
                                                                            Audit Date
                                                                        </Text>
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles.dataRowStyle,
                                                                            ]}
                                                                        >
                                                                            {item.ReviewedOn}
                                                                        </Text>
                                                                    </View>
                                                                    <View
                                                                        style={[this.styles.dataRowView]}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles
                                                                                    .dataLableStyle,
                                                                            ]}
                                                                        >
                                                                            Audit Result
                                                                        </Text>
                                                                        <Text
                                                                            style={[
                                                                                styleFunctions.paragraphText(),
                                                                                this.styles.dataRowStyle,
                                                                            ]}
                                                                        >
                                                                            {item.ReviewResultName}
                                                                        </Text>
                                                                    </View>
                                                                </View>
                                                            }
                                                            accordionContainerStyle={[
                                                                styleFunctions.Accordioncontainer(),
                                                                {
                                                                    width: '97%',
                                                                    marginTop: 20,
                                                                },
                                                            ]}
                                                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                                            accordionTitleStyle={
                                                                this.styles.activityTitle
                                                            }
                                                            accordionExpanded={false}
                                                            accordionUnderlayColor={ColorEnums.white}
                                                            accordionIconLeft={{
                                                                collapsed: 'chevron-down',
                                                                expanded: 'chevron-up',
                                                            }}
                                                            accordionButtonStyle={{
                                                                width: '97%',
                                                            }}
                                                        />
                                                        {index === this.state.nrRecsToShow - 1 && (
                                                            <View>
                                                                <Text
                                                                    onPress={() => {
                                                                        this.setState({
                                                                            nrRecsToShow:
                                                                                this.state.nrRecsToShow +
                                                                                10,
                                                                        })
                                                                    }}
                                                                    style={{
                                                                        paddingLeft: 25,
                                                                        fontFamily: 'Lato',
                                                                        color: ColorEnums.danube,
                                                                        fontSize: 18,
                                                                    }}
                                                                >
                                                                    Show More...
                                                                </Text>
                                                            </View>
                                                        )}
                                                    </View>
                                                )
                                            )
                                        })}
                                        accordionExpanded={true}
                                        accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                        accordionTitleContainerStyle={[
                                            styleFunctions.AccordiontitleContainer(),
                                            {
                                                width: '100%',
                                                backgroundColor: ColorEnums.indigo,
                                                paddingLeft: 20,
                                            },
                                        ]}
                                        accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                        accordionTitleStyle={this.styles.activityTypeTitle}
                                        accordionIconStyle={this.styles.accordionIconStyle}
                                        accordionButtonStyle={this.styles.accordionButtonWidth}
                                    />
                                )}
                            </View>
                        </ABAContainer>
                    )}

                    {this.state.showDeleteConfirm && (
                        <View style={[this.styles.popupContainer]}>
                            <ABAConfirmationBox
                                messageText1="Are you sure you want to delete the following record?"
                                messageText2={
                                    `${QIContext.state.recordToDeleteName}` +
                                    ' completed on ' +
                                    `${QIContext.state.recordToDeleteCompleted}`
                                }
                                buttonLeftText="  Yes  "
                                buttonRightText=" No "
                                leftButtonFunction={QIContext.deleteQIRecord}
                                rightButtonFunction={QIContext.resetStateVars}
                            />
                        </View>
                    )}
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
                {this.state.nrRecsToShow > 10 && (
                    <Text
                        style={{
                            paddingLeft: 60,
                            fontFamily: 'Lato',
                            color: ColorEnums.danube,
                            fontSize: 14,
                            marginBottom: 20,
                        }}
                        onPress={() => this.refs._scrollView.scrollTo(0)}
                    >
                        Return To Top
                    </Text>
                )}
            </NavigationShell>
        )
    }
}
