import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'

const ExaminerAvailabilityLogic = {
    context: null,

    GetExaminerAvailability() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('examiners/getExaminerAvailability')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Examiner Availability Information',
                    )
                    resolve(null)
                })
        })
    },

    GetExaminerAvailabilityOptions() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getExaminerAvailabilityOptions')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Examiner Availability Options',
                    )
                    resolve(null)
                })
        })
    },

    SaveAvailability() {
        return new Promise((resolve) => {
            const {examinerAvailabilityList} = this.context.state

            examinerAvailabilityList.forEach((exam) => {
                const {exam_name, title} = exam
                exam.exam_name = `${exam_name}: ${title}`
            })
            ABAPortalAPI.post(`examiners/updateExaminerAvailability`, examinerAvailabilityList)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Availability Records')
                    resolve(false)
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})

            if (!this.ValidateLogic()) {
                resolve(false)
                this.context.setState({CallingApi: false})
                return
            }

            this.SaveAvailability().then((response) => {
                if (response) {
                    HelperMethods.alertAgnostic(
                        'Your availability has been updated.',
                        'Examiner Availability Updated Successfully',
                        [{text: 'Ok'}],
                    )

                    NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                }
            })
        })
    },

    PopulateVariables(availabilityInfo) {
        const examinerAvailabilityList = availabilityInfo[0]
        const examinerAvailabilityOptionList = availabilityInfo[1]
        if (examinerAvailabilityList && examinerAvailabilityOptionList) {
            this.context.setState({
                examinerAvailabilityList,
                examinerAvailabilityOptionList,
            })
        }
    },

    ValidateLogic() {
        const {examinerAvailabilityList} = this.context.state
        let isValid = true

        examinerAvailabilityList.forEach((examAvailability) => {
            const {exam_availability, exam_remoteexaminer} = examAvailability
            if (exam_availability === null || exam_availability === 'null') {
                examAvailability.exam_availability_status = GeneralEnums.textInputStatus.error
                isValid = false
            } else {
                examAvailability.exam_availability_status = GeneralEnums.textInputStatus.success
            }
            if (exam_remoteexaminer !== true && exam_remoteexaminer !== false) {
                examAvailability.exam_availability_remote_status = GeneralEnums.textInputStatus.error
                isValid = false
            } else {
                examAvailability.exam_availability_remote_status = GeneralEnums.textInputStatus.success
            }
        })

        this.context.setState({examinerAvailabilityList})
        return isValid
    },
}

export default ExaminerAvailabilityLogic
