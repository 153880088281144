import React, {Component} from 'react'
import {Text, View} from 'react-native'

import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'
import MocaMinuteInformation from '../../components/presentation/mocaMinuteInformation'
import HelperMethods from '../../helpers/HelperMethods'
import StyleFunctions from '../../styles/styleFunctions'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABALineChart, {ABALineChartColumn} from '../../components/ABACustom/presentation/ABALineChart'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import MocaMinutePerformanceChartLogic from '../logic/mocaMinutePerformanceChartLogic.js'

export default class MocaMinutePerformance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            YearSelected: 'null',
            Years: [],
            PerformanceValues: [],
            LineChartToHTML: '',
            ComponentWidth: 0,
            RenderChart: false,
            CallingApi: false,
            MMDashboardInfo: {
                AnnualMaxQuestions: null,
                AnnualQuestionsRemaining: null,
                CurrentQuarter: null,
                DailyQuestionsRemaining: null,
                HasReachedAnnualLimit: false,
                HasReachedDailyLimit: false,
                HasReachedQuarteryLimit: false,
                IsEligibleCurrently: false,
                MDTValue: null,
                MMStatusScenario: null,
                MocaRegistrationStatus: null,
                QuarterlyQuestionsRemaining: null,
                Q1Answered: 0,
                Q2Answered: 0,
                Q3Answered: 0,
                Q4Answered: 0,
            },
            ChartDimensions: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
        }
        mocaMinutePerformanceContext = this
        MocaMinutePerformanceChartLogic.context = mocaMinutePerformanceContext
        HelperMethods.setNavigationData(mocaMinutePerformanceContext)
    }
    componentDidMount() {
        this.setState({CallingApi: true})
        Promise.all([
            MocaMinutePerformanceChartLogic.GetMocaMinuteYears(),
            MocaMinutePerformanceChartLogic.GetMocaMinuteDashboardInfo(),
        ]).then((values) => {
            const mocaMinuteYears = values[0]
            const MMDashboardInfo = values[1]
            var currentYear = mocaMinuteYears[0].value
            this.setState({
                CallingApi: false,
                Years: mocaMinuteYears,
                MMDashboardInfo,
            })
            this.getData(currentYear)
        })
        MocaMinutePerformanceChartLogic.GetMocaMinuteYears().then((data) => {
            var currentYear = data[0].value
            this.setState({
                Years: data,
            })
            this.getData(currentYear)
        })
    }
    getData(year) {
        MocaMinutePerformanceChartLogic.GetMocaMinutePerformance(year).then((data) => {
            let new_data = data.map((d) => {
                return [
                    d.QuestionNumber,
                    d.ResponseStatus == GeneralEnums.PerformanceLogicQuestionResponseCodes.FilledIn
                        ? null
                        : d.MDTValue,
                    d.ResponseStatus != GeneralEnums.PerformanceLogicQuestionResponseCodes.FilledIn
                        ? null
                        : d.MDTValue,
                    d.StandardValue,
                ]
            })
            if (new_data.length === 0) {
                new_data = [[0, 0, 0, 0.1]]
            }
            this.setState({
                YearSelected: year,
                PerformanceValues: new_data,
            })
        })
    }
    renderPerformanceChart(hide) {
        return (
            <View
                onLayout={(event) => {
                    this.setState({ChartDimensions: event.nativeEvent.layout})
                }}
            >
                {this.state.PerformanceValues.length > 0 && (
                    <ABALineChart
                        dataset={this.state.PerformanceValues}
                        maxFillValue={[null, null, 0.1, null]}
                        vAxisTooltipLabel="Question"
                        options={{enableInteractivity: true}}
                        curveType="function"
                        vAxis={{
                            ticks: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                            title: 'MDT Probability Value',
                            titleTextStyle: {
                                bold: true,
                                italic: false,
                                fontName: 'lato',
                            },
                            format: '#.00',
                        }}
                        hAxis={{
                            ticks: [0, 30, 60, 90, 120],
                            title: 'Question Sequence',
                            titleTextStyle: {
                                bold: true,
                                italic: false,
                                fontName: 'lato',
                            },
                            viewWindow: {
                                min: 0,
                                max: 120,
                            },
                        }}
                        fontFamily="Lato"
                        customLegend
                        legend={{textStyle: {fontSize: 14}}}
                        Width={this.state.ComponentWidth}
                        formatMdt
                    >
                        <ABALineChartColumn DataSetIndex={0}>Question Number</ABALineChartColumn>
                        <ABALineChartColumn color={ColorEnums.surfGreen} lineWidth={3} DataSetIndex={1}>
                            MDT Value
                        </ABALineChartColumn>
                        <ABALineChartColumn
                            lineDashStyle={[13, 3]}
                            lineWidth={3}
                            color={ColorEnums.citrus}
                            DataSetIndex={2}
                        >
                            Unanswered
                        </ABALineChartColumn>
                        <ABALineChartColumn color={ColorEnums.danube} lineWidth={5} DataSetIndex={3}>
                            Standard Value
                        </ABALineChartColumn>
                    </ABALineChart>
                )}
                {hide && (
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'white',
                            opacity: 0.8,
                        }}
                    />
                )}
            </View>
        )
    }

    renderDashboardComponent() {
        const {x, y, width, height} = this.state.ChartDimensions
        return (
            <View style={{position: 'absolute', top: y, left: x, height, width}}>
                <View style={{paddingTop: 80}}>
                    <MocaMinuteInformation
                        MMData={this.state.MMDashboardInfo}
                        HeaderStyle={[
                            StyleFunctions.containerTextBold(),
                            {textAlign: 'center', paddingBottom: 10},
                        ]}
                        RenderProgressReportLink
                    />
                </View>
            </View>
        )
    }

    render() {
        const {MocaRegistrationStatus, Q1Answered, Q2Answered, Q3Answered, Q4Answered} =
            this.state.MMDashboardInfo
        let hideChart =
            !this.state.CallingApi &&
            this.state.MMDashboardInfo &&
            this.state.YearSelected === new Date().getFullYear().toString() &&
            (MocaRegistrationStatus !== GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA ||
                Q1Answered + Q2Answered + Q3Answered + Q4Answered === 0)
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.aquaHaze}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.Performance} />
                    <ABAContainer
                        activityText={'Loading MOCA Minute Performance Data'}
                        containerTitle={'MOCA Minute MDT Chart'}
                        showActivityIndicator={this.state.CallingApi}
                        sidePadding={5}
                    >
                        <View
                            onLayout={(event) =>
                                HelperMethods.setComponentWidth(mocaMinutePerformanceContext, event)
                            }
                        >
                            <View style={{paddingBottom: 10}}>
                                <Text style={StyleFunctions.containerTextBold()}>
                                    MDT Probability Value Chart
                                </Text>
                            </View>
                            <View style={{paddingBottom: 10}}>
                                <Text style={{flexWrap: 'wrap', fontFamily: 'lato', fontSize: 14}}>
                                    We are using Measurement Decision Theory (MDT) to estimate the
                                    probability that you are continuing to demonstrate your mastery of
                                    core topics in anesthesiology.
                                </Text>
                            </View>
                            <View style={{paddingBottom: 10}}>
                                <Text style={{flexWrap: 'wrap', fontFamily: 'lato', fontSize: 14}}>
                                    If you answer 120 questions per calendar year and maintain an MDT
                                    Probability Value of 0.10 or greater, you are meeting the standard
                                    for MOCA Minute.
                                </Text>
                            </View>

                            <View style={{paddingTop: 50}}>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={[
                                            StyleFunctions.containerTextBold(),
                                            {paddingTop: 15, paddingRight: 5},
                                        ]}
                                    >
                                        View Performance for:
                                    </Text>
                                    <ABADropdown
                                        options={this.state.Years || this.state.Years}
                                        placeholder=""
                                        label={''}
                                        onChange={(value) => {
                                            if (!value || value === 'null') {
                                                return
                                            }
                                            this.getData(value)
                                        }}
                                        selectedValue={this.state.YearSelected}
                                        validationStatus={GeneralEnums.textInputStatus.default}
                                        width={120}
                                    />
                                </View>
                                {this.renderPerformanceChart(hideChart)}
                                {hideChart && this.renderDashboardComponent()}
                            </View>

                            <View style={{width: '75%', alignSelf: 'center', paddingTop: 10}}>
                                {!hideChart && (
                                    <ABATouchableHighlight
                                        buttonStyle={StyleFunctions.buttonClick()}
                                        onPressEvent={() => {
                                            NavigationHelper.navigateBack()
                                        }}
                                        text={'Return to My Progress Report'}
                                        textStyle={{color: ColorEnums.white, fontSize: 16}}
                                        underlayColor={ColorEnums.white}
                                    />
                                )}
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
