import React, {Component} from 'react'
import {Text, Platform, View} from 'react-native'

import {GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import {Link} from '../../router'

export default class LoginReturnLink extends Component {
    render() {
        let linkStyle = {}

        if (Platform.OS === 'web') {
            linkStyle = {textDecoration: 'none'}
        }

        return (
            <View
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 50,
                    marginTop: 10,
                    alignItems: 'center',
                }}
            >
                <Link to="/login" style={linkStyle}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName, color: 'white'}}>
                            {IconEnums['angle-left'] + ' '}
                        </Text>
                        <Text style={{fontFamily: 'Lato', color: 'white'}}>Return to Log In</Text>
                    </View>
                </Link>
            </View>
        )
    }
}
