import React from 'react'
import App from './App'
import {Platform, SafeAreaView, StyleSheet} from 'react-native'
import {Router} from './router'
import configuration from './configuration'
import {createBrowserHistory} from 'history'
import {Provider} from 'react-redux'
import {ConfigureStore} from './redux/configureStore'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserTracing} from '@sentry/tracing'

const isWeb = Platform.OS === 'web'
const Sentry = isWeb ? require('@sentry/react') : require('@sentry/react-native')
const history = isWeb ? createBrowserHistory() : undefined

const isDev = isWeb ? window.location.hostname.toString() === 'localhost' : __DEV__
// We don't want to track errors if the errors come from the developer
Sentry.init({
    dsn: isDev ? '' : 'https://e8dc0535f0bc4fcea96abe31b4ea72f8@o1114836.ingest.sentry.io/6488540',
    environment: configuration.ENVIRONMENT,
    integrations: isWeb
        ? [
              new BrowserTracing({
                  routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
              }),
          ]
        : undefined,
    tracesSampleRate: 1.0,
})

const {store, persistor} = ConfigureStore()

function ProviderWrapper() {
    if (configuration.ENVIRONMENT === 'PROD') {
        console.log = function () {}
    } else {
        if (isWeb) document.title = `${document.title} - ${configuration.ENVIRONMENT}`
    }
    return (
        <SafeAreaView style={styles.container}>
            <Router history={history}>
                <Provider store={store}>
                    {isWeb ? (
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    ) : (
                        <App />
                    )}
                </Provider>
            </Router>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
})

export default ProviderWrapper
