import React from 'react'
import {Text} from 'react-native'
import {ColorEnums, UnicodeEnums} from '../../../helpers/generalEnums'

const ABARequiredAsterisk = props => {
    const style = [{color: ColorEnums.pomegranate}]
    props.style && style.push(props.style)
    return (
        <Text {...props} style={style}>
            {`${UnicodeEnums.nbsp}*`}
        </Text>
    )
}

export default ABARequiredAsterisk
