import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet, Platform} from 'react-native'

import clinicalActivityLogicStep4 from '../logic/clinicalActivityLogicStep4'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABADropdown from '../../../ABACustom/presentation/ABADropdown'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABAAccordion from '../../../ABACustom/presentation/ABAAccordion'
import Reference from '../../../presentation/reference'

const styles = StyleSheet.create({
    certificationComponentTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    certificationComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
        width: '70%',
    },

    statusTextIncomplete: {
        textAlign: 'right',
        fontFamily: 'lato',
        fontSize: 15,
        color: ColorEnums.pomegranate,
    },

    statusTextComplete: {
        fontFamily: 'lato',
        fontSize: 15,
        color: ColorEnums.surfGreen,
    },
})

const {
    Clinical_Activity,
    Clinical_Activity_Chief_of_Anesthesiology,
    Clinical_Activity_Chief_of_Staff,
    Clinical_Activity_Clinical_Competence_Committee_Chair,
} = GeneralEnums.cert_referenceType

export default class ClinicalActivityStep4 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            ReleaseOption: null,
            ComponentWidth: null,
            ReferenceIDs: [],
        }

        this.references = [
            {
                AccordianTitle: 'Chief of Anesthesiology or equivalent',
                ReferenceType: Clinical_Activity_Chief_of_Anesthesiology,
                ref: null,
            },
            {
                AccordianTitle: 'Chief of Staff or equivalent',
                ReferenceType: Clinical_Activity_Chief_of_Staff,
                ref: null,
            },
            {
                AccordianTitle: 'Clinical Competence Committee Chair or equivalent',
                ReferenceType: Clinical_Activity_Clinical_Competence_Committee_Chair,
                ref: null,
            },
        ]
    }

    componentDidMount() {
        clinicalActivityLogicStep4.GetReferenceIDs().then((referenceIDs) => {
            clinicalActivityLogicStep4.PopulateVariables(referenceIDs)
        })
    }

    Save() {
        return clinicalActivityLogicStep4.SaveLogic()
    }

    Previous() {
        return clinicalActivityLogicStep4.Previous()
    }

    render() {
        clinicalActivityStep4Context = this
        clinicalActivityLogicStep4.context = clinicalActivityStep4Context
        const isIndepedentPractice = clinicalActivityContext.state.ClinicalActivityWorkPrivilege === 3

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) =>
                    HelperMethods.setComponentWidth(clinicalActivityStep4Context, event)
                }
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 3 of 4</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep4Context.state.ComponentWidth,
                                    1,
                                ),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep4Context.state.ComponentWidth,
                                    0.75,
                                ),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep4Context.state.ComponentWidth,
                                    0.25,
                                ),
                            ]}
                        />
                    </View>
                )}
                {this.references.map((referenceInfo, index) => {
                    return (
                        <View key={`RefViewComponent${index}`}>
                            {!this.state.CallingApi && (
                                <View
                                    style={{
                                        zIndex: 100,
                                        textAlign: 'right',
                                        position: 'absolute',
                                        top: 0,
                                        right: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    {((this.refs[`reference${index}`] &&
                                        this.refs[`reference${index}`].state.cert_referenceId &&
                                        this.refs[`reference${index}`].state.cert_referenceId !==
                                            'null') ||
                                        this.state.ReferenceIDs[index]) &&
                                    !this.refs[`reference${index}`].state.hasErrors ? (
                                        <Text style={styles.statusTextComplete}>Complete</Text>
                                    ) : (
                                        <Text style={styles.statusTextIncomplete}>Incomplete</Text>
                                    )}
                                </View>
                            )}

                            <ABAAccordion
                                ref={(comp) => (referenceInfo.ref = comp)}
                                accordianID={index}
                                title={
                                    isIndepedentPractice
                                        ? `Reference #${index + 1}`
                                        : referenceInfo.AccordianTitle
                                }
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={index === 0 ? true : this.props.isConfirmationPage}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                toggleCallback={clinicalActivityLogicStep4.AccordianToggled}
                                scrollOnToggle={true}
                                scrollviewRef={
                                    !this.props.isConfirmationPage &&
                                    registrationStepsDisplayContext.refs.RegStepScrollView
                                }
                                accordionContent={
                                    <Reference
                                        referenceID={
                                            clinicalActivityStep4Context.state.ReferenceIDs[index]
                                                ? clinicalActivityStep4Context.state.ReferenceIDs[index]
                                                      .cert_referenceId
                                                : '-1'
                                        }
                                        isConfirmationPage={this.props.isConfirmationPage}
                                        ProgramType={clinicalActivityContext.props.ProgramType}
                                        ReferenceType={
                                            isIndepedentPractice
                                                ? Clinical_Activity
                                                : referenceInfo.ReferenceType
                                        }
                                        componentIndex={index}
                                        saveCallback={clinicalActivityLogicStep4.ReferenceSaved}
                                        ref={`reference${index}`}
                                        RenderSaveReferenceButton={true}
                                        scrollviewRef={
                                            !this.props.isConfirmationPage &&
                                            registrationStepsDisplayContext.refs.RegStepScrollView
                                        }
                                    />
                                }
                            />
                        </View>
                    )
                })}
            </View>
        )
    }
}
