import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep8 from '../logic/substanceAbuseLogicStep8'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import HelperMethods from '../../../../helpers/HelperMethods'
import SubstanceAbuseStep1 from '../presentation/substanceAbuseStep1'
import SubstanceAbuseStep2 from '../presentation/substanceAbuseStep2'
import SubstanceAbuseStep3 from '../presentation/substanceAbuseStep3'
import SubstanceAbuseStep4 from '../presentation/substanceAbuseStep4'
import SubstanceAbuseStep5 from '../presentation/substanceAbuseStep5'
import SubstanceAbuseStep6 from '../presentation/substanceAbuseStep6'
import SubstanceAbuseStep7 from '../presentation/substanceAbuseStep7'

const styles = StyleSheet.create({
    certificationComponentTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    certificationComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    editButtonStyle: {
        backgroundColor: ColorEnums.white,
        borderColor: ColorEnums.danube,
        borderRadius: 50,
        borderWidth: 1,
        flexDirection: 'row',
        minHeight: 40,
        marginTop: 15,
    },

    editButtonContainer: {
        paddingTop: 20,
        zIndex: 100,
        position: 'absolute',
        right: 0,
        top: 0,
    },

    stepContainer: {
        marginTop: 15,
    },
})

export default class SubstanceAbuseStep8 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
    }

    componentDidMount() {
        if (this.props.SubmittedSteps == 1) {
            this.props.SetPreviousVisibility(false)
        }
    }

    Save() {
        return SubstanceAbuseLogicStep8.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep8.Previous()
    }

    render() {
        substanceAbuseLogicStep8context = this
        SubstanceAbuseLogicStep8.context = substanceAbuseLogicStep8context
        const {Cert_DrugAbuse, Cert_AlcoholAbuse, ReviewResult} = substanceAbuseContext.state
        const approvedResult = 1
        isEditable = !this.props.IsViewRegistration && ReviewResult !== approvedResult

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.IsViewRegistration && (
                    <View>
                        <Text> Page 7 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0),
                            ]}
                        />
                    </View>
                )}
                {ReviewResult === approvedResult && (
                    <View
                        style={{
                            backgroundColor: ColorEnums.pomegranateLight,
                            borderRadius: 4,
                            minHeight: 50,
                            padding: 20,
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{color: ColorEnums.pomegranateDark, fontSize: 20}}>
                            Request has been approved by the ABA. No further edits are allowed.
                        </Text>
                    </View>
                )}
                <View style={{paddingTop: 25, justifyContent: 'center'}}>
                    {isEditable && (
                        <View style={styles.editButtonContainer}>
                            <ABATouchableHighlight
                                buttonStyle={styles.editButtonStyle}
                                onPressEvent={() =>
                                    SubstanceAbuseLogicStep8.GoToComponentStep(SubstanceAbuseStep1)
                                }
                                textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                                text={'Edit'}
                            />
                        </View>
                    )}
                    <ABAAccordion
                        title="Statement"
                        accordionContainerStyle={styleFunctions.Accordioncontainer()}
                        accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                        accordionTitleStyle={styles.certificationComponentTitle}
                        accordionButtonStyle={styles.certificationComponentTitlebutton}
                        accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                        accordionExpanded={true}
                        accordionUnderlayColor={ColorEnums.white}
                        accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                        accordionContent={
                            <View>
                                <View>
                                    <SubstanceAbuseStep1
                                        CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                        Order={substanceAbuseLogicStep8context.props.Order}
                                        ProgramType={substanceAbuseLogicStep8context.props.ProgramType}
                                        isConfirmationPage={true}
                                        SetPreviousVisibility={this.props.SetPreviousVisibility}
                                        IsViewRegistration={this.props.IsViewRegistration}
                                    />
                                    <View style={styleFunctions.confirmationOverlay()} />
                                </View>
                            </View>
                        }
                    />
                </View>
                {(Cert_DrugAbuse === 1 || Cert_AlcoholAbuse === 1) && (
                    <View style={{paddingTop: 25, justifyContent: 'center'}}>
                        {isEditable && (
                            <View style={styles.editButtonContainer}>
                                <ABATouchableHighlight
                                    buttonStyle={styles.editButtonStyle}
                                    onPressEvent={() =>
                                        SubstanceAbuseLogicStep8.GoToComponentStep(SubstanceAbuseStep2)
                                    }
                                    textStyle={{
                                        color: ColorEnums.danube,
                                        fontFamily: 'Lato',
                                        fontSize: 14,
                                    }}
                                    text={'Edit'}
                                />
                            </View>
                        )}
                        <ABAAccordion
                            title="Substances"
                            accordionContainerStyle={styleFunctions.Accordioncontainer()}
                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                            accordionTitleStyle={styles.certificationComponentTitle}
                            accordionButtonStyle={styles.certificationComponentTitlebutton}
                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                            accordionExpanded={true}
                            accordionUnderlayColor={ColorEnums.white}
                            accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                            accordionContent={
                                <View>
                                    <View style={styles.stepContainer}>
                                        <SubstanceAbuseStep2
                                            CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                            Order={substanceAbuseLogicStep8context.props.Order}
                                            ProgramType={
                                                substanceAbuseLogicStep8context.props.ProgramType
                                            }
                                            isConfirmationPage={true}
                                            SetPreviousVisibility={this.props.SetPreviousVisibility}
                                            IsViewRegistration={this.props.IsViewRegistration}
                                        />
                                        <View style={styleFunctions.confirmationOverlay()} />
                                    </View>
                                </View>
                            }
                        />

                        <View style={{paddingTop: 25, justifyContent: 'center'}}>
                            {isEditable && (
                                <View style={styles.editButtonContainer}>
                                    <ABATouchableHighlight
                                        buttonStyle={styles.editButtonStyle}
                                        onPressEvent={() =>
                                            SubstanceAbuseLogicStep8.GoToComponentStep(
                                                SubstanceAbuseStep3,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title="Initial Treatment"
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <View style={styles.stepContainer}>
                                            <SubstanceAbuseStep3
                                                CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                                Order={substanceAbuseLogicStep8context.props.Order}
                                                ProgramType={
                                                    substanceAbuseLogicStep8context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                                SetPreviousVisibility={this.props.SetPreviousVisibility}
                                                IsViewRegistration={this.props.IsViewRegistration}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    </View>
                                }
                            />
                        </View>

                        <View style={{paddingTop: 25, justifyContent: 'center'}}>
                            {isEditable && (
                                <View style={styles.editButtonContainer}>
                                    <ABATouchableHighlight
                                        buttonStyle={styles.editButtonStyle}
                                        onPressEvent={() =>
                                            SubstanceAbuseLogicStep8.GoToComponentStep(
                                                SubstanceAbuseStep4,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title="Relapse"
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <View style={styles.stepContainer}>
                                            <SubstanceAbuseStep4
                                                CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                                Order={substanceAbuseLogicStep8context.props.Order}
                                                ProgramType={
                                                    substanceAbuseLogicStep8context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                                SetPreviousVisibility={this.props.SetPreviousVisibility}
                                                IsViewRegistration={this.props.IsViewRegistration}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    </View>
                                }
                            />
                        </View>

                        <View style={{paddingTop: 25, justifyContent: 'center'}}>
                            {isEditable && (
                                <View style={styles.editButtonContainer}>
                                    <ABATouchableHighlight
                                        buttonStyle={styles.editButtonStyle}
                                        onPressEvent={() =>
                                            SubstanceAbuseLogicStep8.GoToComponentStep(
                                                SubstanceAbuseStep5,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title="Recovery"
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <View style={styles.stepContainer}>
                                            <SubstanceAbuseStep5
                                                CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                                Order={substanceAbuseLogicStep8context.props.Order}
                                                ProgramType={
                                                    substanceAbuseLogicStep8context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                                SetPreviousVisibility={this.props.SetPreviousVisibility}
                                                IsViewRegistration={this.props.IsViewRegistration}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    </View>
                                }
                            />
                        </View>

                        <View style={{paddingTop: 25, justifyContent: 'center'}}>
                            {isEditable && (
                                <View style={styles.editButtonContainer}>
                                    <ABATouchableHighlight
                                        buttonStyle={styles.editButtonStyle}
                                        onPressEvent={() =>
                                            SubstanceAbuseLogicStep8.GoToComponentStep(
                                                SubstanceAbuseStep6,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title="Aftercare"
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <View style={styles.stepContainer}>
                                            <SubstanceAbuseStep6
                                                CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                                Order={substanceAbuseLogicStep8context.props.Order}
                                                ProgramType={
                                                    substanceAbuseLogicStep8context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                                SetPreviousVisibility={this.props.SetPreviousVisibility}
                                                IsViewRegistration={this.props.IsViewRegistration}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    </View>
                                }
                            />
                        </View>

                        <View style={{paddingTop: 25, justifyContent: 'center'}}>
                            {isEditable && (
                                <View style={styles.editButtonContainer}>
                                    <ABATouchableHighlight
                                        buttonStyle={styles.editButtonStyle}
                                        onPressEvent={() =>
                                            SubstanceAbuseLogicStep8.GoToComponentStep(
                                                SubstanceAbuseStep7,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title="Current Practice"
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <View style={styles.stepContainer}>
                                            <SubstanceAbuseStep7
                                                CR2ID={substanceAbuseLogicStep8context.props.CR2ID}
                                                Order={substanceAbuseLogicStep8context.props.Order}
                                                ProgramType={
                                                    substanceAbuseLogicStep8context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                                SetPreviousVisibility={this.props.SetPreviousVisibility}
                                                IsViewRegistration={this.props.IsViewRegistration}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    </View>
                                }
                            />
                        </View>
                    </View>
                )}

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 7</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
