import React, {Component} from 'react'
import {ActivityIndicator, StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import QuestionHistoryLogic from '../logic/questionHistoryLogic'
import QuestionHistoryRow from './questionHistoryRow'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import {GeneralEnums, ColorEnums, IconEnums} from '../../helpers/generalEnums'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import styleFunctions from '../../styles/styleFunctions'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'

export default class QuestionHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            callingApi: false,
            questionCategories: [
                {
                    categoryName: 'Fundamental Topics in Anesthesiology',
                    showDetails: false,
                },
                {
                    categoryName: 'Pharmacology',
                    showDetails: false,
                },
                {
                    categoryName: 'Clinical Sciences: Anesthesia Procedures, Methods, and Techniques',
                    showDetails: false,
                },
                {
                    categoryName: 'Organ-Based Basic and Clinical Sciences',
                    showDetails: false,
                },
                {
                    categoryName: 'Clinical Subspecialties',
                    showDetails: false,
                },
                {
                    categoryName: 'Special Problems or Issues in Anesthesiology',
                    showDetails: false,
                },
                {
                    categoryName: 'Quality Improvement',
                    showDetails: false,
                },
                {
                    categoryName: 'Overview of Anesthesiology Topics',
                    showDetails: false,
                },
                {
                    categoryName: 'Critical Care Medicine',
                    showDetails: false,
                },
                {
                    categoryName: 'Pain Medicine',
                    showDetails: false,
                },
                {
                    categoryName: 'Pediatric Anesthesiology',
                    showDetails: false,
                },
            ],
        }

        this.styles = StyleSheet.create({
            rowPadding: {
                paddingBottom: 15,
                paddingLeft: 15,
                paddingRight: 15,
            },
        })
    }

    componentDidMount() {
        QuestionHistoryLogic.getQuestionHistory().then((questions) => {
            this.setState({questionCategories: questions})
        })
    }

    renderCategoryDetails(category) {
        if (category.quarters && category.quarters.length > 0) {
            return category.quarters.map((quarter, quarterKey) => {
                let quarterlyQuestions = category.questions.filter(
                    (question) => question.Answered === quarter,
                )

                return (
                    <View key={quarterKey} style={this.styles.rowPadding}>
                        <View style={{paddingTop: 10, paddingBottom: 10}}>
                            <Text
                                style={{
                                    fontFamily: 'Lato',
                                    fontWeight: 'bold',
                                    color: ColorEnums.cloudBurst,
                                }}
                            >
                                {quarter}
                            </Text>
                        </View>

                        {quarterlyQuestions.map((question, questionKey) => {
                            let rowBorderColor = ColorEnums.backgroundGray
                            //if it is the last row for a quarter, we need a darker border (as seen in the mockup)
                            if (quarterlyQuestions.length === questionKey + 1) {
                                rowBorderColor = ColorEnums.cloudBurst
                            }

                            return (
                                <QuestionHistoryRow
                                    questionText={question.QuestionText}
                                    responseStatus={question.ResponseStatus}
                                    peerPerformance={question.PeerPerformance}
                                    askId={question.AskID}
                                    rowBorderColor={rowBorderColor}
                                    key={questionKey}
                                />
                            )
                        })}
                    </View>
                )
            })
        }

        return (
            <View style={this.styles.rowPadding}>
                <Text style={{fontFamily: 'Lato', color: ColorEnums.cloudBurst}}>
                    None of your previous 120 questions have covered this topic.
                </Text>
            </View>
        )
    }

    toggleQuestionTopic(categoryKey) {
        let questionCategories = [...this.state.questionCategories]
        questionCategories[categoryKey].showDetails = !questionCategories[categoryKey].showDetails

        this.setState({questionCategories})
    }

    render() {
        navigationContext = this
        QuestionHistoryLogic.context = this

        HelperMethods.setNavigationData(this)
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray, paddingBottom: 20}}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.QuestionHistory} />
                    <Text
                        style={{
                            fontFamily: 'Lato',
                            color: ColorEnums.blueGray,
                            textAlign: 'center',
                            marginTop: -10,
                            zIndex: 2,
                        }}
                    >
                        Only the last 120 questions will be displayed
                    </Text>

                    {this.state.questionCategories.map((category, categoryKey) => {
                        return (
                            <View
                                style={{
                                    maxWidth: 700,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '100%',
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: 'white',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '95%',
                                        marginTop: 15,
                                        borderRadius: 5,
                                    }}
                                    key={categoryKey}
                                >
                                    <TouchableWithoutFeedback
                                        onPress={() => this.toggleQuestionTopic(categoryKey)}
                                        testID={'questionCategory' + category.categoryName}
                                    >
                                        <View style={{flexDirection: 'row', padding: 15}}>
                                            <View style={{justifyContent: 'center'}}>
                                                <Text
                                                    style={{
                                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                        color: ColorEnums.cloudBurst,
                                                        paddingRight: 10,
                                                    }}
                                                >
                                                    {category.showDetails
                                                        ? IconEnums['chevron-up']
                                                        : IconEnums['chevron-down']}
                                                </Text>
                                            </View>

                                            <View style={{justifyContent: 'center', flex: 1}}>
                                                <Text
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        color: ColorEnums.cloudBurst,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {category.categoryName}
                                                </Text>
                                            </View>
                                        </View>
                                    </TouchableWithoutFeedback>

                                    {category.showDetails && this.renderCategoryDetails(category)}
                                </View>
                            </View>
                        )
                    })}

                    {this.state.callingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Loading Question History</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
