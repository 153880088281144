import React, {Component} from 'react'
import {View} from 'react-native'
import ABAPortalAPI, {setHeaderABAID} from '../../helpers/AxiosInstance'
import {GeneralEnums, PearsonVueErrors} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

class RedirectErrors extends Component {
    async componentDidMount() {
        navigationContext = this
        const {eventId, token} = this.props.match.params

        const errorCode = new URLSearchParams(this.props.location.search).get('s')
        const ABAID = new URLSearchParams(this.props.location.search).get('ABAID')

        if (token == null) {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/')
        }
        const body = {AuthenticationToken: token}
        setHeaderABAID(ABAID)
        setHeaderAuthToken(token)
        if (errorCode === PearsonVueErrors.ERROR_SESSION_TIMEOUT) {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, `/login`, {
                error: GeneralEnums.AlertTypes.SessiontTimeout,
            })
        }
        const errorBody = {
            errorCode,
            eventId,
        }
        const errorEmail = await ABAPortalAPI.post('registration/postPVRedirectError', errorBody)
        const reauthenticate = await ABAPortalAPI.post('authentication/reauthenticate', body)
        this.props.setAuthToken(reauthenticate.data.AuthenticationToken)
        NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, `/schedulingDetails/${errorCode}`)
    }

    render() {
        return <View />
    }
}

export default RedirectErrors
