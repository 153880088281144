import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep4 from '../logic/substanceAbuseLogicStep4'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABADropdown from '../../../../components/ABACustom/presentation/ABADropdown'
import ABATextInput from '../../../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import RelapseSubstanceAbuseSubstance from '../../../../components/presentation/relapseSubstanceAbuseSubstance'
import ReferenceList from '../../../../components/presentation/referenceList'

const styles = StyleSheet.create({
    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },
})

export default class SubstanceAbuseStep4 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        cert_relapse1: -1,
        relapse1Options: [],
        cert_SubstanceBeganDate: '',
        cert_name: '',
        relapseSubstanceIDList: [],
        substancesMissing: false,
        showReferenceList: false,
        referenceIDList: [],
        addNewReferenceContext: null,
        InputStatus: {
            cert_relapse1: GeneralEnums.textInputStatus.default,
            cert_SubstanceBeganDate: GeneralEnums.textInputStatus.default,
            cert_name: GeneralEnums.textInputStatus.default,
        },
    }

    relapseSubstanceRefs = []

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        Promise.all([
            SubstanceAbuseLogicStep4.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep4.GetRelapse1Options(),
            SubstanceAbuseLogicStep4.GetSubstanceAbuseSubstancesData(),
            SubstanceAbuseLogicStep4.GetSAReferences(),
        ]).then((SAData) => {
            SubstanceAbuseLogicStep4.PopulateVariables(SAData)
        })
    }

    renderSaveButton() {
        const {ComponentWidth} = this.state
        return (
            <View
                style={{
                    flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 10,
                }}
            >
                <ABATouchableHighlight
                    onPressEvent={() => SubstanceAbuseLogicStep4.AddSubstanceToGrid()}
                    text="Save"
                />
            </View>
        )
    }

    renderSubstance(substance) {
        const {cert_SAsubstanceId} = substance
        const {referenceIDList} = this.state
        return (
            <RelapseSubstanceAbuseSubstance
                ref={(comp) => (this.relapseSubstanceRefs[cert_SAsubstanceId] = comp)}
                key={cert_SAsubstanceId}
                SubstanceAbuseSubstanceID={cert_SAsubstanceId}
                UpdateGrid={SubstanceAbuseLogicStep4.UpdateGrid}
                ReferenceIDList={referenceIDList}
                ProgramType={this.props.ProgramType}
                ShowReferenceList={SubstanceAbuseLogicStep4.ShowReferenceList}
            />
        )
    }

    Save() {
        return SubstanceAbuseLogicStep4.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep4.Previous()
    }

    hideContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: false})
    }

    showContinue = () => {
        !this.props.IsViewRegistration && registrationStepsDisplayContext.setState({ShowContinue: true})
    }

    render() {
        SubstanceAbuseLogicStep4.context = this
        const {
            ComponentWidth,
            InputStatus,
            cert_relapse1,
            relapseSubstanceIDList,
            referenceIDList,
            showReferenceList,
            addNewReferenceContext,
        } = this.state

        let relapseSubstanceAbuseSubstances
        if (relapseSubstanceIDList.length > 0) {
            relapseSubstanceAbuseSubstances = relapseSubstanceIDList.map((substance) => {
                return this.renderSubstance(substance)
            })
        }

        return (
            <View style={{width: '100%'}}>
                <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                    {!this.props.isConfirmationPage && (
                        <View>
                            <Text> Page 3 of 7</Text>
                            <ABABarChart
                                outerbarstyle={[
                                    styleFunctions.chartoutercontainer(),
                                    styleFunctions.columnWidth(ComponentWidth, 1),
                                ]}
                                filledbarstyle={[
                                    styleFunctions.filledbarbgcolor(),
                                    styleFunctions.progressBarFill(),
                                    styleFunctions.columnWidth(ComponentWidth, 0.43),
                                ]}
                                emptybarstyle={[
                                    styleFunctions.emptybarbgcolor(),
                                    styleFunctions.progressBarEmpty(),
                                    styleFunctions.columnWidth(ComponentWidth, 0.57),
                                ]}
                            />
                        </View>
                    )}
                </View>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        paddingBottom: 15,
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? '70%' : '100%'}}>
                        <Text style={[styleFunctions.paragraphText(), {fontSize: 14, width: '90%'}]}>
                            Have you had a <Text style={{fontWeight: 'bold'}}>relapse</Text> since the
                            date of your initial treatment?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: ComponentWidth > 600 ? '30%' : '100%'}}>
                        <ABADropdown
                            options={this.state.relapse1Options}
                            placeholder="Select Type"
                            placeholderItemValue={'-1'}
                            onChange={(cert_relapse1) => {
                                SubstanceAbuseLogicStep4.SetRelapse1Option(cert_relapse1)
                            }}
                            selectedValue={cert_relapse1}
                            validationStatus={InputStatus.cert_relapse1}
                            width="100%"
                        />
                    </View>
                </View>

                {cert_relapse1 == 1 && showReferenceList && (
                    <View>
                        <ReferenceList
                            ReferenceList={referenceIDList}
                            AddReference={SubstanceAbuseLogicStep4.AddExistingReference}
                            ReferenceContext={addNewReferenceContext}
                        />
                        <View style={{flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={SubstanceAbuseLogicStep4.AddNewReference}
                            >
                                ADD NEW
                            </Text>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={() => {
                                    this.showContinue()
                                    this.setState({showReferenceList: false})
                                }}
                            >
                                CANCEL
                            </Text>
                        </View>
                    </View>
                )}

                {cert_relapse1 == 1 && !showReferenceList && (
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingBottom: 15, width: '100%'},
                            ]}
                        >
                            Please indicate the date of each relapse and the substance(s) involved:
                        </Text>
                        {this.state.substancesMissing && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    fontSize: 16,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                At least one substance must be added
                            </Text>
                        )}
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <Text>
                                    Relapse Date:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <ABATextInputMask
                                    errorMessage="Please enter a valid date"
                                    keyboardType="number-pad"
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={10}
                                    onChangeText={(cert_SubstanceBeganDate) =>
                                        this.setState({cert_SubstanceBeganDate})
                                    }
                                    options={{mask: '99/99/9999'}}
                                    placeholder="mm/dd/yyyy"
                                    returnKeyType="next"
                                    style={styleFunctions.textInput()}
                                    textInputStatus={InputStatus.cert_SubstanceBeganDate}
                                    value={this.state.cert_SubstanceBeganDate}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <Text>
                                    Substance(s) Abused:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <ABATextInput
                                    marginLeft={0}
                                    marginRight={0}
                                    placeholder="Substance"
                                    onChangeText={(cert_name) => this.setState({cert_name})}
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.cert_name}
                                    value={this.state.cert_name}
                                    width="100%"
                                />
                            </View>
                        </View>
                        {this.renderSaveButton()}
                    </View>
                )}

                <View style={{display: showReferenceList || cert_relapse1 != 1 ? 'none' : 'flex'}}>
                    {relapseSubstanceAbuseSubstances}
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 4</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
