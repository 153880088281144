import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep1 from '../presentation/nonStandardExamStep1'
import NonStandardExamStep2 from '../presentation/nonStandardExamStep2'
import NonStandardExamStep4 from '../presentation/nonStandardExamStep4'
import ValidationHelper from '../../../../helpers/ValidationHelper'

const NonStandardExamLogicStep3 = {
    context: null,

    GetNSEData: function () {
        return new Promise((resolve) => {
            const {NonStandardExamID} = nonStandardExamContext.state

            let getParams = {
                params: {
                    NonStandardExamID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`nserequestsinfo/getNSEData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing NSE Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateNSEStep3().then((response) => {
                if (response) {
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep4,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            if (this.context.props.CR2ID) {
                nonStandardExamContext.setState({
                    CurrentComponentStep: NonStandardExamStep1,
                })
            } else {
                nonStandardExamContext.setState({
                    CurrentComponentStep: NonStandardExamStep2,
                })
            }
            resolve(false)
        })
    },

    ValidatePage: function () {
        const {
            exam_specificDiagnosis,
            exam_yearFirstDiagnosed,
            exam_specificLimitations,
            exam_lastEvaluationDate,
            InputStatus,
        } = this.context.state

        var startValidationDate = new Date('January 1, 1900 00:00:00')
        var endValidationDate = new Date()
        const {error, success} = GeneralEnums.textInputStatus

        if (exam_specificDiagnosis.length === 0) {
            InputStatus.exam_specificDiagnosis = error
        } else {
            InputStatus.exam_specificDiagnosis = success
        }

        if (
            exam_yearFirstDiagnosed > startValidationDate.getFullYear() &&
            exam_yearFirstDiagnosed <= endValidationDate.getFullYear()
        ) {
            InputStatus.exam_yearFirstDiagnosed = success
        } else {
            InputStatus.exam_yearFirstDiagnosed = error
        }

        if (exam_specificLimitations.length === 0) {
            InputStatus.exam_specificLimitations = error
        } else {
            InputStatus.exam_specificLimitations = success
        }

        if (
            ValidationHelper.isDateFormatValid(
                exam_lastEvaluationDate,
                GeneralEnums.DateCheckType.TodayAndPastOnly,
            )
        ) {
            InputStatus.exam_lastEvaluationDate = success
        } else {
            InputStatus.exam_lastEvaluationDate = error
        }

        var Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)

        if (!Validated) {
            this.context.setState({
                InputStatus: InputStatus,
            })
        }
        return Validated
    },

    UpdateNSEStep3: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {
                exam_specificDiagnosis,
                exam_yearFirstDiagnosed,
                exam_specificLimitations,
                exam_lastEvaluationDate,
            } = this.context.state

            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep3`, {
                NSEID: nonStandardExamContext.state.NonStandardExamID,
                exam_specificDiagnosis,
                exam_yearFirstDiagnosed,
                exam_specificLimitations,
                exam_lastEvaluationDate,
                rs_statuscode: nonStandardExamContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateStateVariables: function (NSEData) {
        if (NSEData) {
            const exam_lastEvaluationDate =
                NSEData.exam_lastEvaluationDate &&
                moment(new Date(NSEData.exam_lastEvaluationDate)).format('MM/DD/YYYY')
            this.context.setState({
                exam_specificDiagnosis: NSEData.exam_specificDiagnosis || '',
                exam_yearFirstDiagnosed: NSEData.exam_yearFirstDiagnosed || '',
                exam_specificLimitations: NSEData.exam_specificLimitations || '',
                exam_lastEvaluationDate: exam_lastEvaluationDate || '',
                exam_specificLimitationsFile: NSEData.exam_specificLimitationsFile || null,
                pmt_examtypename: NSEData.pmt_examtypename,
            })
        }
    },
}

export default NonStandardExamLogicStep3
