import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import {connect} from 'react-redux'
import ABATabControl from '../ABACustom/presentation/ABATabControl'
import HelperMethods from '../../helpers/HelperMethods'
import MyProgressLogic from '../logic/myprogressLogic'
import styleFunctions from '../../styles/styleFunctions'
import {ColorEnums} from '../../helpers/generalEnums'
import MocaMinute from '../presentation/mocaMinute'
import MyProgressCME from '../presentation/myprogressCme'
import QI from '../presentation/qi_imp'
import {setCurrentProgressTab} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    pageSettings: state.pageSettings,
})

const mapDispatchToProps = {setCurrentProgressTab}

class MyProgress extends Component {
    state = {
        CallingApi: false,
        ComponentWidth: null,
        Tabs: [],
    }

    renderMyProgressTabs() {
        MyProgressLogic.GetUserRoleId().then((roleId) => {
            let allTabs = []
            let mocaMinuteTab = {
                key: 'mm',
                text: 'MOCA MINUTE\u00AE',
                componentDisplay: <MocaMinute MocaCR2={myProgressContext.props.MocaCR2} />,
            }

            let cmeTab = {
                key: 'cme',
                text: 'CME',
                componentDisplay: <MyProgressCME RoleId={roleId} />,
            }
            let qiTab = {
                key: 'qi',
                text: 'QUALITY IMPROVEMENT',
                componentDisplay: <QI />,
            }
            switch (roleId) {
                case 1: //Diplomate
                    //this role has MOCA Minute, CME, and Quality Improvement
                    allTabs.push(mocaMinuteTab)
                    allTabs.push(cmeTab)
                    allTabs.push(qiTab)
                    break
                case 3: //Diplomate (No MOCA)
                    //this role has CME and Quality Improvement
                    allTabs.push(mocaMinuteTab)
                    allTabs.push(cmeTab)
                    break
                case 2: //Non-Diplomate
                case 4: //Retired/Revoked
                default:
                    //these roles do not have My Progress component so ensure the array is empty for safety
                    allTabs = []
                    break
            }
            myProgressContext.setState({Tabs: allTabs})
        })
    }

    componentDidMount() {
        this.renderMyProgressTabs()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.MocaCR2 !== this.props.MocaCR2) {
            this.renderMyProgressTabs()
        }
    }

    render() {
        myProgressContext = this
        MyProgressLogic.context = myProgressContext

        if (myProgressContext.state.ComponentWidth && myProgressContext.state.Tabs.length > 0) {
            let qiTab = myProgressContext.state.Tabs.find((tab) => tab.key == 'qi')
            if (qiTab !== undefined) {
                qiTab.text = MyProgressLogic.UpdateQiText(qiTab)
            }
        }
        return (
            <View onLayout={(event) => HelperMethods.setComponentWidth(myProgressContext, event)}>
                {myProgressContext.state.Tabs && myProgressContext.state.Tabs.length > 0 && (
                    <ABATabControl
                        tabs={myProgressContext.state.Tabs}
                        tabHeight={60}
                        parentContainerWidth={myProgressContext.state.ComponentWidth}
                        defaultTabKey={this.props.pageSettings.CurrentProgressTab}
                        onTabChange={(tabIndex) => this.props.setCurrentProgressTab(tabIndex)}
                    />
                )}

                {myProgressContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading My Progress</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProgress)
