import React, {Component} from 'react'
import {Linking, Text} from 'react-native'
import PropTypes from 'prop-types'
import HelperMethods from '../../../helpers/HelperMethods'
import {GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import SideNavigationLogic from '../../logic/sidenavigationLogic'
import StyleFunctions from '../../../styles/styleFunctions'

export default class ABAHyperLink extends Component {
    render() {
        return (
            <Text
                style={this.props.style ? this.props.style : StyleFunctions.actionText()}
                onPress={() => {
                    this.goToURL(this.props.url)
                }}
            >
                {this.props.icon && (
                    <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>
                        {IconEnums[this.props.icon]}{' '}
                    </Text>
                )}
                {this.props.label}
            </Text>
        )
    }

    goToURL(url) {
        Linking.canOpenURL(url).then((supported) => {
            if (supported) {
                {
                    this.props.addAuth
                        ? SideNavigationLogic.getTemporaryAuthToken().then((authToken) => {
                              HelperMethods.openURL(`${url}/${authToken}`)
                          })
                        : HelperMethods.openURL(url)
                }
            } else {
                HelperMethods.alertAgnostic('Cannot open URL: ' + url, 'Hyperlink Error', [{text: 'Ok'}])
            }
        })
    }
}

ABAHyperLink.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    addAuth: PropTypes.bool,
}

ABAHyperLink.defaultProps = {
    addAuth: false,
    icon: null,
}
