import moment from 'moment-timezone'
import React, {Component} from 'react'
import {Linking, Platform, StyleSheet, Text, View} from 'react-native'
import {connect} from 'react-redux'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABACountdownCircle from '../../components/ABACustom/presentation/ABACountdownCircle'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {ColorEnums, ExamProviders, GeneralEnums, PearsonVueErrors} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import {Link} from '../../router'
import Loading from '../../screens/presentation/loading'
import styleFunctions from '../../styles/styleFunctions'
import DashboardLogic from '../logic/dashboardLogic'

const HoldPendingExam = ({Product, style}) => (
    <View style={{margin: 30}}>
        <Text style={[styleFunctions.paragraphText(), ...style]}>
            Your registration for{'\n'}
            <Text style={{fontWeight: 'bold'}}>{Product}</Text>
            {'\n'}is currently under review. We cannot process and accept your registration until all
            requirements are met.
        </Text>
    </View>
)
const UnknownErrorMessage = ({Product, style}) => (
    <View style={{margin: 30}}>
        <Text style={[styleFunctions.paragraphText(), ...style]}>
            Your registration for{'\n'}
            <Text style={{fontWeight: 'bold'}}>{Product}</Text>
            {'\n'}has been accepted. You are now eligible to schedule your assessment.
        </Text>
        <Text style={[styleFunctions.paragraphText(), ...style]}>
            We are currently experiencing difficulties processing your request. Please check back
            periodically to schedule your assessment. Thank you for your patience.
        </Text>
    </View>
)

const ExamMessage = ({title, center}) => (
    <View style={{padding: 15}}>
        <Text
            style={[
                styleFunctions.paragraphText(),
                styles.textStyle,
                {textAlign: center ? 'center' : 'auto'},
            ]}
        >
            {title}
        </Text>
    </View>
)

const NSEMessage = ({reschedule}) => (
    <View style={{margin: 30}}>
        <Text style={[styleFunctions.paragraphText(), styles.textStyle, {textAlign: 'center'}]}>
            You have been approved for special accommodations. You must call the{'\n'}
            <Text style={{fontWeight: 'bold'}}>Special Accommodations Center at (800) 466-0450</Text>
            {'\n'}
            to {reschedule ? 'reschedule' : 'schedule'} your exam appointment.
        </Text>
    </View>
)

const GenericErrorMessage = () => (
    <Text>
        It looks like something went wrong. Please try again using the{' '}
        <Link to="/login" style={{textDecoration: 'none'}}>
            <Text style={[styles.LinkStyle]}>portal link</Text>
        </Link>{' '}
        and only this browser tab.
    </Text>
)

const getErrorMessage = (error) => {
    switch (error) {
        case PearsonVueErrors.ERROR_CANDIDATE_NOT_FOUND:
        case PearsonVueErrors.ERROR_CLIENT_NOT_SUPPORTED:
        case PearsonVueErrors.ERROR_INVALID_PAYLOAD:
        case PearsonVueErrors.ERROR_MISSING_REQUIRED_PARAMETERS:
        case PearsonVueErrors.ERROR_REGISTRATION_NOT_FOUND:
        case PearsonVueErrors.ERROR:
            return <GenericErrorMessage />
        case PearsonVueErrors.BAD_AUTH:
            return 'An error has occurred. Please contact the ABA at exams@theaba.org.'
        case PearsonVueErrors.ERROR_TEMPORARY:
            return 'Pearson VUE is not currently available. Please return at a later date.'
        case PearsonVueErrors.ERROR_VUE_WIDE_BLOCK:
            return 'Your access to the Pearson VUE website has been restricted. Please contact the Pearson VUE Call Center (800) 466-0450 for assistance.'
        default:
            return null
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
})

class SchedulingDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messageDigest: null,
            abaId: null,
            timeStamp: null,
            evt_ProductIDName: null,
            evt_ScheduledBy: null,
            statuscode: '',
            PV_AuthId: '',
            callingApi: false,
            redirectUrl: null,
            showCountdown: true,
            nccMonth: '',
            importantMessage: '',
            appointmentInformation: {
                evt_address1: null,
                evt_address2: null,
                evt_address3: null,
                evt_appointmentstartdate: null,
                evt_city: null,
                evt_dtCancelled: null,
                evt_eventtype: null,
                evt_postalcode: null,
                evt_state: null,
                evt_timezonename: null,
                evt_appointmentdisplaytimezoneutcoffset: null,
                evt_vuetestcenterid: null,
                evt_testcentername: null,
            },
        }
    }

    async componentDidMount() {
        const {error, event_id} = this.props.match.params
        let regAlerts = null
        await DashboardLogic.GetRegistrationAlerts().then((data) => {
            if (data) {
                data.map((regData) => {
                    if (regData.evt_eventId == event_id) {
                        regAlerts = regData
                    }
                })
            }
        })

        const {evt_ScheduledBy, statuscode, PV_AuthId, productNumber, evt_eventId} = regAlerts

        if (error) {
            const tmp = getErrorMessage(error)
            this.setState({error: tmp})
            return
        }

        this.setState({statuscode, PV_AuthId})
        const importantMessage = await ABAPortalAPI.get('registration/getImportantMessage')
        if (importantMessage.data !== '') {
            this.setState({importantMessage: importantMessage.data})
        }
        const pendingExam = statuscode.toLowerCase() === 'hold pending'
        let isPearsonVue = evt_ScheduledBy === ExamProviders.PEARSON_VUE && !pendingExam

        if (productNumber === 'Exam-NCC') {
            month = await ABAPortalAPI.get(`registration/getNCCRegistrationMonth/`)
            this.setState({nccMonth: month.data})
        }
        if (isPearsonVue) {
            this.setState({callingApi: true})
            try {
                const serverDetails = await ABAPortalAPI.get(`registration/getRegistrationDetails/`)
                const appointmentInformation = await ABAPortalAPI.get(
                    `registration/GetAppointmentInformation/${evt_eventId}/`,
                )
                const redirectUrl = `${serverDetails.data.baseUrl}/routeToPearsonVue/${this.props.settings.authToken}/${evt_eventId}/${Platform.OS}/${serverDetails.data.authInfo.ABAId}`

                this.setState({
                    ...serverDetails.data,
                    callingApi: false,
                    redirectUrl,
                    appointmentInformation: appointmentInformation.data
                        ? appointmentInformation.data
                        : this.state.appointmentInformation,
                })
            } catch (e) {
                // Future Error Handling
            }
        }
    }

    getHeaderMessage(examProvider, rankedEvent) {
        switch (examProvider) {
            case 1:
                return 'We’ve received your registration for the'
            default:
                return (rankedEvent = 1
                    ? 'You have successfully registered for the'
                    : 'Your registration has been received for')
        }
    }

    ImportantMessageCheck() {
        if (this.state.importantMessage !== '') {
            return (
                <Text style={{color: ColorEnums.cloudBurst, margin: 10, textAlign: 'center'}}>
                    {this.state.importantMessage}
                </Text>
            )
        }
        return null
    }

    renderPearsonVue = () => {
        const {
            callingApi,
            showCountdown,
            redirectTimer,
            appointmentInformation,
            importantMessage,
            statuscode,
            PV_AuthId,
        } = this.state

        const {nseId, evt_ProductIDName} = this.navigationData
        const pendingExam = statuscode.toLowerCase() === 'hold pending'
        const appointment = appointmentInformation?.evt_appointmentstartdate
        const {
            evt_testcentername,
            evt_postalcode,
            evt_state,
            evt_city,
            evt_address1,
            evt_address2,
            evt_address3,
            evt_timezonename,
        } = appointmentInformation
        if (callingApi || (!redirectTimer && !pendingExam)) {
            return (
                <View style={{margin: 30}}>
                    <Loading />
                </View>
            )
        }

        if (!PV_AuthId && statuscode === 'Hold Pending') {
            return (
                <View style={{textAlign: 'center'}}>
                    <HoldPendingExam
                        Product={evt_ProductIDName}
                        style={[styles.textStyle, {textAlign: 'center'}]}
                    />
                    {this.ImportantMessageCheck()}
                </View>
            )
        }

        if (!PV_AuthId && statuscode !== 'Hold Pending') {
            return (
                <View style={{textAlign: 'center'}}>
                    <UnknownErrorMessage
                        Product={evt_ProductIDName}
                        style={[styles.textStyle, {textAlign: 'center'}]}
                    />
                    {this.ImportantMessageCheck()}
                </View>
            )
        }

        if (nseId && !appointment) {
            return (
                <View style={{textAlign: 'center'}}>
                    <NSEMessage />
                    {this.ImportantMessageCheck()}
                </View>
            )
        }

        if (!appointment) {
            return (
                <>
                    {showCountdown && (
                        <View
                            style={{
                                paddingVertical: 20,
                                alignItems: 'center',
                            }}
                        >
                            <ExamMessage title="You are being redirected to the exam appointment scheduling site." />
                            <View style={{margin: 10}}>
                                {
                                    <ABACountdownCircle
                                        ref={(countdownCircle) => {
                                            this.countdownCircle = countdownCircle
                                        }}
                                        expirationEvent={() => this.redirectToPearsonVue()}
                                        caption={'TIME REMAINING'}
                                        seconds={redirectTimer}
                                    />
                                }
                            </View>
                        </View>
                    )}
                    {this.ImportantMessageCheck()}
                    {this.PvRedirectButton()}
                </>
            )
        }

        const examTime = moment(appointment + 'Z')
            .tz(evt_timezonename)
            .format('MM/D/YY @ h:mm A z')
        return (
            <>
                <ExamMessage
                    title={`You have scheduled your ${evt_ProductIDName} with Pearson VUE:`}
                    center
                />
                <Text style={styles.testCenterName}>{evt_testcentername}</Text>
                <View style={{alignSelf: 'center', maxWidth: '100%'}}>
                    <View>
                        <View style={{maxWidth: 550}}>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontWeight: '700',
                                    maxWidth: '100%',
                                    alignSelf: 'center',
                                    paddingVertical: 5,
                                }}
                            >
                                {this.isExamPassed()
                                    ? `Exams Complete: We will release results within 6 weeks to candidates who meet our policy requirements.`
                                    : `Please click the “Get Directions” button below for the most up-to-date
                                location information about your appointment.`}
                            </Text>
                            {evt_address1 && <Text style={{textAlign: 'center'}}>{evt_address1}</Text>}
                            {evt_address2 && <Text style={{textAlign: 'center'}}>{evt_address2}</Text>}
                            {evt_address3 && <Text style={{textAlign: 'center'}}>{evt_address3}</Text>}
                            <Text
                                style={{textAlign: 'center'}}
                            >{`${evt_city}, ${evt_state} ${evt_postalcode}`}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styleFunctions.paragraphText(), {fontWeight: 'bold'}]}>
                            Exam Time:{' '}
                        </Text>
                        <Text>{examTime}</Text>
                    </View>
                </View>
                {nseId && <NSEMessage reschedule />}
                <View style={[styles.row]}>
                    {!this.isExamPassed() && (
                        <View style={{paddingBottom: 10, paddingHorizontal: 10}}>
                            <ABATouchableHighlight
                                buttonStyle={[styleFunctions.buttonClick(), {width: 250}]}
                                onPressEvent={() => this.pearsonVueDirections()}
                                text={'Get Directions'}
                                textStyle={{color: ColorEnums.white, fontSize: 16}}
                                underlayColor={ColorEnums.white}
                            />
                        </View>
                    )}
                    {!nseId && (
                        <>
                            {!this.isExamPassed() && this.PvRedirectButton('Modify Appointment')}
                            {this.PvRedirectButton()}
                        </>
                    )}
                </View>
            </>
        )
    }

    PvRedirectButton = (title = 'Go to Pearson VUE') => (
        <View style={{paddingBottom: 10, paddingHorizontal: 10}}>
            <ABATouchableHighlight
                buttonStyle={[styleFunctions.buttonClick(), {width: 250}]}
                onPressEvent={() => this.redirectToPearsonVue()}
                text={title}
                textStyle={{color: ColorEnums.white, fontSize: 16}}
                underlayColor={ColorEnums.white}
            />
        </View>
    )

    redirectToPearsonVue = () => {
        const {redirectUrl} = this.state

        Linking.openURL(redirectUrl)
        this.setState({showCountdown: false})
    }
    pearsonVueDirections = () => {
        const {evt_vuetestcenterid} = this.state.appointmentInformation
        let directionsUrl = `https://wsr.pearsonvue.com/testtaker/registration/TestCenterDirections.htm?testCenterId=${evt_vuetestcenterid}&clientCode=ABA`

        if (Platform.OS == 'web') {
            window.open(directionsUrl, '_blank')
        } else {
            Linking.openURL(directionsUrl)
        }
    }

    isExamPassed = () => {
        const {appointmentInformation} = this.state

        const isExamPassed =
            Date.parse(appointmentInformation?.evt_appointmentstartdate + 'Z') < Date.now()

        return isExamPassed
    }

    renderExamRegistered = () => {
        const {evt_ProductIDName, evt_ScheduledBy, eventName, rankedEvent} = this.navigationData

        if (this.state.error) {
            return (
                <View>
                    <Text
                        style={[styleFunctions.paragraphText(), styles.textStyle, {textAlign: 'center'}]}
                    >
                        {this.state.error}
                    </Text>
                </View>
            )
        }

        return (
            <Text style={[styleFunctions.paragraphText(), {fontSize: 16, textAlign: 'center'}]}>
                {!this.isExamPassed() && this.getHeaderMessage(evt_ScheduledBy, rankedEvent)}{' '}
                <Text style={{fontWeight: 'bold'}}>
                    {rankedEvent === 1 ? eventName : evt_ProductIDName}
                </Text>
                .
            </Text>
        )
    }
    render() {
        navigationContext = this
        HelperMethods.setNavigationData(this)
        const {evt_ProductIDName, evt_ScheduledBy, pendingExam, productNumber, rankedEvent} =
            this.navigationData

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.ExamInformation} />
                    <ABAContainer
                        activityText={'Loading Details'}
                        containerTitle={'Exam Scheduling Instructions'}
                        useMaxWidth={'true'}
                    >
                        <View
                            style={{width: '100%'}}
                            onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                        >
                            {this.renderExamRegistered()}
                            {/* PearsonVue Instructions */}
                            {evt_ScheduledBy === ExamProviders.PEARSON_VUE && !pendingExam && (
                                <View
                                    style={{
                                        alignSelf: 'center',
                                        justifyContent: 'center',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {this.renderPearsonVue()}
                                </View>
                            )}

                            {/* ABA Instructions */}
                            {evt_ScheduledBy === ExamProviders.ABA && !pendingExam && (
                                <View>
                                    {/* 0 mean is not ranked */}
                                    {rankedEvent === 0 ? (
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                styles.textStyle,
                                                {textAlign: 'center'},
                                            ]}
                                        >
                                            You will receive an email notification of your exact date,
                                            time and hotel information approximately two months prior to
                                            your exam week.
                                        </Text>
                                    ) : (
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                styles.textStyle,
                                                {textAlign: 'center'},
                                            ]}
                                        >
                                            You will receive your exam week assignment via email in the
                                            upcoming weeks.
                                        </Text>
                                    )}
                                </View>
                            )}

                            {/* Pending Instructions */}
                            {pendingExam && (
                                <HoldPendingExam
                                    Product={evt_ProductIDName}
                                    style={[styles.textStyle, {textAlign: 'center'}]}
                                />
                            )}

                            {/* NCC Specific Instructions */}
                            {productNumber === 'Exam-NCC' && (
                                <View>
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            styles.textStyle,
                                            {
                                                maxWidth: 500,
                                                textAlign: 'center',
                                                alignSelf: 'center',
                                            },
                                        ]}
                                    >
                                        Once it’s been verified, we will send it to ABPN. In{'\n'}
                                        {this.state.nccMonth}, ABPN will email you instructions for
                                        scheduling your exam appointment with Pearson VUE.
                                    </Text>
                                </View>
                            )}

                            {/* Other Instructions */}
                            {(evt_ScheduledBy === 100 || evt_ScheduledBy === 4) &&
                                !pendingExam &&
                                productNumber !== 'Exam-NCC' && (
                                    <View>
                                        <Text style={[styleFunctions.paragraphText(), styles.textStyle]}>
                                            Once your registration information has been verified and
                                            accepted, you will receive an email notification within 10
                                            business days instructing you to contact the testing vendor
                                            to schedule your examination appointment.
                                        </Text>
                                    </View>
                                )}

                            {evt_ScheduledBy !== ExamProviders.PEARSON_VUE &&
                                !pendingExam &&
                                this.ImportantMessageCheck()}
                            <View
                                style={{
                                    alignSelf: 'center',
                                    paddingTop: 15,
                                }}
                            >
                                <ABATouchableHighlight
                                    buttonStyle={styleFunctions.buttonClick()}
                                    onPressEvent={() => {
                                        NavigationHelper.GoToPage(
                                            GeneralEnums.navigationType.push,
                                            '/dashboard',
                                            {
                                                AuthenticationToken:
                                                    this.navigationData.AuthenticationToken,
                                            },
                                        )
                                    }}
                                    text={'Return to Dashboard'}
                                    textStyle={{color: ColorEnums.white, fontSize: 16}}
                                    underlayColor={ColorEnums.white}
                                />
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

const styles = StyleSheet.create({
    textStyle: {
        paddingTop: 20,
        paddingBottom: 10,
        fontSize: 16,
    },

    bulletText: {
        paddingTop: 5,
        fontSize: 16,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    testCenterName: {
        textAlign: 'center',
        fontWeight: '900',
        fontFamily: 'Lato',
        fontSize: 24,
        color: ColorEnums.danube,
    },
    LinkStyle: {
        color: 'blue',
        fontFamily: 'Lato',
        letterSpacing: 1,
    },
})

export default connect(mapStateToProps)(SchedulingDetails)
