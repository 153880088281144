import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import clinicalActivityLogicStep1 from '../logic/clinicalActivityLogicStep1'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABAAffirmationRadio from '../../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'

export default class ClinicalActivityStep1 extends Component {
    state = {
        CallingApi: false,
        CAOption: null,
        CAOptionStatus: GeneralEnums.textInputStatus.default,
        re_ProgramType: null,
        PDIR: null,
        PreviousCA: null,
        CAResponseData: null,
        cert_graduation_date: '',
        clinicalActivityCheckInterval: 0,
        shouldCaptureGraduation: false,
    }

    componentDidMount() {
        clinicalActivityLogicStep1.GetClinicalActivityData().then((CAData) => {
            clinicalActivityLogicStep1.PopulateVariables(CAData)
        })
    }

    Save() {
        return clinicalActivityLogicStep1.SaveLogic()
    }

    Previous() {
        return clinicalActivityLogicStep1.Previous()
    }

    render() {
        clinicalActivityStep1Context = this
        clinicalActivityLogicStep1.context = clinicalActivityStep1Context

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                        ]}
                    >
                        Practice Requirements
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, width: '90%'},
                        ]}
                    >
                        Physicians must have on file with the Board documentation attesting to the
                        current privileges and evaluations of various aspects of his or her current
                        practice of anesthesiology. Such evaluations will include verification that the
                        physician meets the Board's clinical activity requirement by spending, on
                        average, at least one day per week during 12 consecutive months over the previous
                        three years in the clinical practice of anesthesiology and/or related
                        subspecialties.
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                        ]}
                    >
                        Have you spent on average, at least one day per week during 12 consecutive months
                        over the previous three years in the clinical practice of anesthesiology and/or
                        related subspecialties?
                        <ABARequiredAsterisk />
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 20,
                    }}
                >
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={clinicalActivityLogicStep1.CAOptionYesSelected}
                            rightOptionContent="No"
                            rightOptionOnPress={clinicalActivityLogicStep1.CAOptionNoSelected}
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={clinicalActivityStep1Context.state.CAOption}
                            validationStatus={clinicalActivityStep1Context.state.CAOptionStatus}
                            errorMessage="You must select Yes to continue."
                        />
                    </View>
                </View>
                {!clinicalActivityStep1Context.state.CAOption &&
                    clinicalActivityStep1Context.state.CAOption != null && (
                        <View>
                            <Text
                                style={{
                                    width: '90%',
                                    fontFamily: 'Lato',
                                    fontSize: 14,
                                    color: ColorEnums.pomegranate,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                }}
                            >
                                You have indicated that you have not averaged at least one day per week
                                during 12 consecutive months over the previous three years in the
                                clinical practice of anesthesiology and/or related subspecialties.
                                Therefore, you cannot apply to the ABA at the current time. If you have
                                any questions, please call the ABA at (866) 999-7501 and ask to speak to
                                a member of our Communications Team.
                            </Text>
                        </View>
                    )}

                {clinicalActivityStep1Context.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Clinical Activity</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
