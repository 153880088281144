import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    logoutNoticeTime: null,
    logoutTime: null,
}

export const timeoutSettings = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetLogoutNoticeTime:
            return {...state, logoutNoticeTime: action.payload, logoutTime: action.payload + 60000}
        case ActionTypes.ClearSettings:
            return initialState
        default:
            return state
    }
}

export default timeoutSettings
