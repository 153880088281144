import React, {Component} from 'react'
import {View, Text, StyleSheet, TouchableWithoutFeedback} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import ReceiptDetailsLogic from '../logic/receiptDetailsLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import NavigationHelper from '../../helpers/NavigationHelper'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import PrintedFooter from '../../components/presentation/printedFooter'

const styles = StyleSheet.create({
    amountColumn: {
        width: '25%',
        minWidth: 90,
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 14,
        marginBottom: 5,
        marginTop: 5,
        fontWeight: 'bold',
    },

    descriptionColumn: {
        width: '75%',
        minWidth: 90,
        color: ColorEnums.mdDarkGray,
        fontFamily: 'Lato',
        fontSize: 14,
        marginBottom: 5,
        marginTop: 5,
        fontWeight: 'bold',
        paddingLeft: 10,
    },
})

export default class ReceiptDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            ShowNote: [],
            LineItems: [],
            DescriptionData: [],
            returnRoute: 'receipts',
        }
    }

    async componentDidMount() {
        const {details} = this.props.location.state
        let returnRoute = this.state.returnRoute
        let responseData

        if (details) {
            responseData = details
            returnRoute = 'dashboard'
        } else {
            responseData = await ReceiptDetailsLogic.getReceiptDetails()
        }

        var LineItems =
            responseData.Description.indexOf('MOCA 2.0') > -1
                ? responseData.LineItems.filter((item) => item.SumByProd !== 0)
                : [{ItemDescription: responseData.Description, Amount: responseData.TotalAmount}]

        var DescriptionData = [
            {
                Label: 'Organization',
                Value: 'The American Board of Anesthesiology, Inc.',
            },
            {
                Label: responseData.TransactionID ? 'Transaction ID' : 'Payment ID',
                Value: responseData.TransactionID ? responseData.TransactionID : responseData.PaymentID,
            },
            {
                Label: 'Date',
                Value: responseData.PayDate,
            },
            {
                Label: 'Description',
                Value: responseData.Description,
            },
            {
                Label: 'Amount',
                Value: `$${responseData.TotalAmount.toFixed(2)}`,
            },
            {
                Label: 'Payment Type',
                Value: responseData.PayType,
            },
            {
                Label: 'Approval Code',
                Value: responseData.ApprovalCode,
            },
        ]
        this.setState({LineItems, DescriptionData, returnRoute})
    }

    renderLineItems() {
        return this.state.LineItems.map((lineItem, index) => (
            <View
                key={`lineItem${index}`}
                style={{flexDirection: 'row', width: '100%', paddingTop: 10, paddingBottom: 10}}
            >
                <Text style={styles.amountColumn}>${lineItem.Amount.toFixed(2)}</Text>
                <Text style={styles.descriptionColumn}>{lineItem.ItemDescription}</Text>
            </View>
        ))
    }

    renderDescription() {
        return this.state.DescriptionData.map((item, index) => (
            <View
                key={`descItem${index}`}
                style={{
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    borderBottomColor: ColorEnums.backgroundGray,
                    paddingTop: 10,
                }}
            >
                <Text style={styles.amountColumn}>{item.Label}</Text>
                <Text style={styles.descriptionColumn}>{item.Value}</Text>
            </View>
        ))
    }

    render() {
        receiptDetailsSummaryContext = this
        navigationContext = this
        HelperMethods.setNavigationData(receiptDetailsSummaryContext)
        ReceiptDetailsLogic.context = receiptDetailsSummaryContext

        return (
            <NavigationShell context={receiptDetailsSummaryContext} print={true}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer
                        activityText={'Loading Receipts Summary'}
                        containerTitle={'RECEIPT DETAILS'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 10,
                                paddingTop: 30,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        color: ColorEnums.cloudBurst,
                                    },
                                ]}
                            >
                                LINE ITEMS
                            </Text>
                        </View>
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                paddingBottom: 10,
                                paddingTop: 30,
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.lightGray,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '25%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Amount
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '75%', fontFamily: 'Lato', fontSize: 14, paddingLeft: 13},
                                ]}
                            >
                                Description
                            </Text>
                        </View>

                        {this.renderLineItems()}
                        <View
                            style={{
                                borderTopWidth: 2,
                                borderTopColor: ColorEnums.cloudBurst,
                                paddingTop: 10,
                            }}
                        />
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 10,
                                paddingTop: 30,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        color: ColorEnums.cloudBurst,
                                    },
                                ]}
                            >
                                DETAILS
                            </Text>
                        </View>
                        {this.renderDescription()}
                        <View style={{borderTopWidth: 2, borderTopColor: ColorEnums.cloudBurst}} />

                        <View style={{flexDirection: 'row', minWidth: 85, paddingTop: 10}}>
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        `/${this.state.returnRoute}`,
                                    )
                                }}
                                testID="returnToReceiptsSummary"
                            >
                                <View style={{flexDirection: 'row'}}>
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {
                                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                color: ColorEnums.danube,
                                                paddingRight: 5,
                                            },
                                        ]}
                                    >
                                        {IconEnums['angle-left']}
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.danube,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {this.state.returnRoute === 'dashboard'
                                            ? 'Go to Dashboard'
                                            : 'Return to Receipts Summary'}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                    </ABAContainer>
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
