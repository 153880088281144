import React, {Component} from 'react'
import {StyleSheet, View, Text, ActivityIndicator, styles} from 'react-native'
import PropTypes from 'prop-types'
import {ColorEnums} from '../../helpers/generalEnums'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import styleFunctions from '../../styles/styleFunctions'
import strikeIronFormLogic from '../../components/logic/strikeIronFormLogic'

export default class StrikeIronForm extends Component {
    constructor(props) {
        super(props)
        this.styles = {
            column1: {width: '16%'},
            column2: {width: '42%', marginLeft: 12},
            column3: {width: '42%', marginLeft: 12},
            strikeIronText: {
                color: ColorEnums.cloudBurst,
                fontFamily: 'Lato',
                fontSize: 14,
                paddingLeft: 5,
            },
        }

        this.state = {
            CallingApi: false,
            si_AddressLine1: '',
            si_AddressLine2: '',
            si_City: '',
            si_ZipCode: '',
            si_StateSelected: 'null',
            si_StateName: '',
            si_StatusNbr: 0,
            componentWidth: 100,
        }
    }

    componentDidMount() {
        strikeIronFormLogic.ValidateAddress().then((responseData) => {
            responseData &&
                strikeIronFormContext.setState({
                    si_AddressLine1: responseData.AddressLine1,
                    si_AddressLine2: responseData.AddressLine2,
                    si_City: responseData.City,
                    si_StateSelected: responseData.StateID,
                    si_StateName: responseData.StateIDName,
                    si_ZipCode: responseData.ZipCode,
                    si_StatusNbr: responseData.Status,
                })
        })
    }

    render() {
        strikeIronFormContext = this
        strikeIronFormLogic.context = strikeIronFormContext
        const displayValidationTable =
            strikeIronFormContext.state.si_StatusNbr === 'verified' ||
            strikeIronFormContext.state.si_StatusNbr === 'corrected'
        let {column1, column2, column3} = {...this.styles}
        const colStyle1 = {...column1, width: this.state.componentWidth * 0.2}
        const colStyle2 = {...column2, width: this.state.componentWidth * 0.4 - 13}
        const colStyle3 = {...column3, width: this.state.componentWidth * 0.4 - 13}

        return (
            <View
                style={{
                    zIndex: 100,
                    backgroundColor: ColorEnums.aquaHaze,
                    shadowColor: '#000000',
                    shadowOffset: {width: 4, height: 5},
                    shadowOpacity: 0.3,
                    shadowRadius: 4,
                    maxWidth: '90%',
                }}
            >
                {displayValidationTable && (
                    <View>
                        <View
                            style={{
                                marginTop: 20,
                                backgroundColor: ColorEnums.aquaHaze,
                                flexDirection: 'row',
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.containerTextBold(),
                                    {padding: 5, fontWeight: 'bold', fontSize: 14},
                                ]}
                            >
                                Select Address Format
                            </Text>
                            <Text
                                onPress={strikeIronFormContext.props.onPressEvent}
                                style={[
                                    styleFunctions.containerHeader(),
                                    {
                                        padding: 5,
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        marginLeft: 'auto',
                                    },
                                ]}
                            >
                                X Close and Edit 
                            </Text>
                        </View>
                        <View
                            style={{
                                paddingTop: 20,
                                backgroundColor: ColorEnums.aquaHaze,
                                borderBottomColor: ColorEnums.cloudBurst,
                                borderBottomWidth: 2,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {padding: 5, fontWeight: 'bold', fontSize: 14},
                                ]}
                            >
                                Please click “Save as Entered” to save the address as it was entered,
                                “Save as Found” to save the address as it was found, or click “Close and
                                Edit” to change the information.
                            </Text>
                            {strikeIronFormContext.props.NameOnCertificate && (
                                <Text
                                    style={[
                                        styleFunctions.containerTextBold(),
                                        {
                                            paddingLeft: 5,
                                            paddingTop: 20,
                                            width: '100%',
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                        },
                                    ]}
                                >
                                    Name: {strikeIronFormContext.props.NameOnCertificate}
                                </Text>
                            )}
                        </View>
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 10,
                                paddingTop: 30,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                            onLayout={(event) =>
                                this.setState({componentWidth: event.nativeEvent.layout.width})
                            }
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.columnHeader(),
                                        {
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                            paddingLeft: 5,
                                        },
                                    ]}
                                >
                                    Address
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.columnHeader(),
                                        {
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                            paddingLeft: 5,
                                        },
                                    ]}
                                >
                                    Entered
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.columnHeader(),
                                        {
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        },
                                    ]}
                                >
                                    Found
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 5,
                                paddingTop: 5,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontWeight: 'bold'},
                                        this.styles.strikeIronText,
                                    ]}
                                >
                                    Line 1
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.props.AddressLine1}
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.state.si_AddressLine1}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 5,
                                paddingTop: 5,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontWeight: 'bold'},
                                        this.styles.strikeIronText,
                                    ]}
                                >
                                    Line 2
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.props.AddressLine2}
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.state.si_AddressLine2}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 5,
                                paddingTop: 5,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontWeight: 'bold'},
                                        this.styles.strikeIronText,
                                    ]}
                                >
                                    City
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.props.City}
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.state.si_City}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 5,
                                paddingTop: 5,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontWeight: 'bold'},
                                        this.styles.strikeIronText,
                                    ]}
                                >
                                    State
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.props.StateName}
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.state.si_StateName}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingTop: 5,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            <View
                                style={[
                                    {borderBottomWidth: 2, borderBottomColor: ColorEnums.cloudBurst},
                                    colStyle1,
                                ]}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontWeight: 'bold'},
                                        this.styles.strikeIronText,
                                    ]}
                                >
                                    Postal Code
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 2,
                                        borderBottomColor: ColorEnums.cloudBurst,
                                    },
                                    colStyle2,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.props.ZipCode}
                                </Text>
                            </View>
                            <View
                                style={[
                                    {
                                        borderBottomWidth: 2,
                                        borderBottomColor: ColorEnums.cloudBurst,
                                    },
                                    colStyle3,
                                ]}
                            >
                                <Text
                                    style={[styleFunctions.paragraphText(), this.styles.strikeIronText]}
                                >
                                    {strikeIronFormContext.state.si_ZipCode}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 20,
                                paddingTop: 15,
                                backgroundColor: ColorEnums.aquaHaze,
                            }}
                        >
                            {this.state.componentWidth > 400 && <View style={{width: '20%'}} />}

                            <View
                                style={{
                                    paddingHorizontal: 6,
                                    width: this.state.componentWidth > 400 ? '40%' : '50%',
                                }}
                            >
                                <ABATouchableHighlight
                                    text="Save as Entered"
                                    onPressEvent={() =>
                                        strikeIronFormContext.props.saveAsEntered(strikeIronFormContext)
                                    }
                                />
                            </View>
                            <View
                                style={{
                                    paddingHorizontal: 6,
                                    width: this.state.componentWidth > 400 ? '40%' : '50%',
                                }}
                            >
                                <ABATouchableHighlight
                                    text="Save as Found"
                                    onPressEvent={() =>
                                        strikeIronFormContext.props.saveAsFound(strikeIronFormContext)
                                    }
                                />
                            </View>
                        </View>
                    </View>
                )}
                {!displayValidationTable && (
                    <View style={{alignSelf: 'center', height: 150}}>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.containerTextBold(),
                                    {
                                        marginTop: 20,
                                        textAlign: 'center',
                                        width: '100%',
                                        marginBottom: 20,
                                    },
                                ]}
                            >
                                Address not found
                            </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '50%', padding: 5}}>
                                <ABATouchableHighlight
                                    text="Save as Entered"
                                    onPressEvent={() =>
                                        strikeIronFormContext.props.saveAsEntered(strikeIronFormContext)
                                    }
                                />
                            </View>
                            <View style={{width: '50%', padding: 5}}>
                                <ABATouchableHighlight
                                    text="Close and Edit"
                                    onPressEvent={() =>
                                        strikeIronFormContext.props.onPressEvent(strikeIronFormContext)
                                    }
                                />
                            </View>
                        </View>
                    </View>
                )}
                {strikeIronFormContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Validating Address</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
StrikeIronForm.propTypes = {
    AddressLine1: PropTypes.string.isRequired,
    AddressLine2: PropTypes.string.isRequired,
    City: PropTypes.string.isRequired,
    StateSelected: PropTypes.string.isRequired,
    StateName: PropTypes.string.isRequired,
    CountrySelected: PropTypes.string.isRequired,
    CountryName: PropTypes.string.isRequired,
    ZipCode: PropTypes.string.isRequired,
    onPressEvent: PropTypes.func.isRequired,
    marginTop: PropTypes.number,

    NameOnCertificate: PropTypes.string,
    saveAsFound: PropTypes.func.isRequired,
    saveAsEntered: PropTypes.func.isRequired,
}
