import React, {Component} from 'react'
import {Text, View, StyleSheet} from 'react-native'
import PropTypes from 'prop-types'

import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'

export default class ABAAlert extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            alertView: {
                borderRadius: 4,
                flexDirection: 'row',
                marginBottom: 20,
                minHeight: 50,
                padding: 20,
            },

            alertViewred: {
                backgroundColor: ColorEnums.pomegranateLight,
            },

            alertTextred: {
                color: ColorEnums.pomegranateDark,
                fontSize: 20,
            },

            alertViewgreen: {
                backgroundColor: ColorEnums.surfGreenLight,
            },

            alertTextgreen: {
                color: ColorEnums.surfGreenDark,
                fontSize: 20,
            },

            alertViewyellow: {
                backgroundColor: ColorEnums.citrusLight,
            },

            alertTextyellow: {
                color: ColorEnums.citrusDark,
                fontSize: 20,
            },

            alertIcon: {
                fontFamily: GeneralEnums.fontAwesomeFamilyName,
            },
        })
    }

    render() {
        return (
            <View
                style={[this.styles.alertView, this.styles[`alertView${this.props.color}`]]}
                testID={`ABAAlert${this.props.text}`}
            >
                {this.props.icon && (
                    <Text style={[this.styles.alertIcon, this.styles[`alertText${this.props.color}`]]}>
                        {this.props.icon}
                    </Text>
                )}
                <Text style={this.styles[`alertText${this.props.color}`]}>{this.props.text}</Text>
            </View>
        )
    }
}

ABAAlert.defaultProps = {
    alertColor: 'red',
}

ABAAlert.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['red', 'yellow', 'green']),
    icon: PropTypes.string,
}
