import React, {Component} from 'react'
import {View} from 'react-native'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import ABATextInput from '../ABACustom/presentation/ABATextInput'
import ABADropdown from '../ABACustom/presentation/ABADropdown'

export default class Address extends Component {
    constructor(props) {
        super(props)
        const {textInputStatus} = GeneralEnums
        const defaultStatus = textInputStatus.default
        this.state = {
            organization: '',
            department: '',
            address1: '',
            address2: '',
            country: null,
            state: null,
            stateOrProvince: '',
            postal: '',
            city: '',
            countryList: null,
            stateList: null,
            componentWidth: 700,
            status: {
                address1: defaultStatus,
                country: defaultStatus,
                state: defaultStatus,
                stateOrProvince: defaultStatus,
                postal: defaultStatus,
                city: defaultStatus,
            },
        }
    }

    async componentDidMount() {
        const {initialAddress} = this.props
        const countries = await this.getCountries()
        if (!initialAddress) {
            this.setState(countries)
            return
        }
        this.setAddress(initialAddress, countries)
    }

    componentDidUpdate(previousProps) {
        if (this.props.initialAddress != previousProps.initialAddress) {
            this.setAddress(this.props.initialAddress)
        }
    }

    async setAddress(addressToSet, countries) {
        let newState = countries || {}
        if (addressToSet.country) {
            const stateList = await this.getStates(addressToSet.country)
            newState = {...newState, ...stateList}
            this.setState({
                ...newState,
                organization: addressToSet.organization || '',
                department: addressToSet.department || '',
                address1: addressToSet.address1 || '',
                address2: addressToSet.address2 || '',
                country: addressToSet.country || null,
                state: addressToSet.state || null,
                stateOrProvince: addressToSet.stateOrProvince || '',
                postal: addressToSet.postal || '',
                city: addressToSet.city || '',
            })
        }
    }

    getCountries = async () => {
        try {
            const respose = await ABAPortalAPI.get('lookups/getCountries')
            return {countryList: respose.data}
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Retrieving Country List')
            return {countryList: null}
        }
    }

    countryChange = async (countryId) => {
        const newState = {country: countryId, state: null, stateOrProvince: ''}
        const states = await this.getStates(countryId)
        this.setState({...newState, ...states})
    }

    getStates = async (countryId) => {
        if (!HelperMethods.isCountryDomestic(countryId)) {
            return {stateList: null}
        }
        try {
            const respose = await ABAPortalAPI.get('lookups/getStates?countryId=' + countryId)
            return {stateList: respose.data}
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Retrieving State List')
            return {stateList: null}
        }
    }

    validate = () => {
        const {textInputStatus} = GeneralEnums
        let {status} = this.state
        const {address1, country, state, stateOrProvince, postal, city, stateList} = this.state
        for (let key in status) {
            status[key] = textInputStatus.default
        }
        var validated = true
        if (!country || country === 'null') {
            status.country = textInputStatus.error
            validated = false
        }
        if (!address1) {
            status.address1 = textInputStatus.error
            validated = false
        }
        if (!city) {
            status.city = textInputStatus.error
            validated = false
        }
        if (!postal) {
            status.postal = textInputStatus.error
            validated = false
        }
        if (stateList && (!state || state === 'null')) {
            status.state = textInputStatus.error
            validated = false
        } else if (!stateList && !stateOrProvince) {
            status.stateOrProvince = textInputStatus.error
            validated = false
        }
        this.setState({
            status,
        })
        return validated
    }
    render() {
        const {
            organization,
            department,
            address1,
            address2,
            country,
            state,
            stateOrProvince,
            postal,
            city,
            countryList,
            stateList,
            componentWidth,
            status,
        } = this.state

        return (
            <View onLayout={(event) => this.setState({componentWidth: event.nativeEvent.layout.width})}>
                <ABATextInput
                    style={styleFunctions.paragraphText()}
                    placeholder="Organization"
                    onChangeText={(text) => this.setState({organization: text})}
                    width="100%"
                    value={organization}
                    maxLength={150}
                    onBlur={() => this.setState({organization: HelperMethods.properCase(organization)})}
                />
                <ABATextInput
                    style={styleFunctions.paragraphText()}
                    placeholder="Department"
                    onChangeText={(text) => this.setState({department: text})}
                    width="100%"
                    value={department}
                    maxLength={150}
                    onBlur={() => this.setState({department: HelperMethods.properCase(department)})}
                />
                <ABATextInput
                    style={styleFunctions.paragraphText()}
                    placeholder="Address Line 1 *"
                    onChangeText={(text) => this.setState({address1: text})}
                    width="100%"
                    value={address1}
                    textInputStatus={status.address1}
                    maxLength={150}
                    onBlur={() => this.setState({address1: HelperMethods.properCase(address1)})}
                />

                <ABATextInput
                    style={styleFunctions.paragraphText()}
                    placeholder="Address Line 2"
                    onChangeText={(text) => this.setState({address2: text})}
                    width="100%"
                    value={address2}
                    maxLength={150}
                    onBlur={() => this.setState({address2: HelperMethods.properCase(address2)})}
                />
                <View style={{marginBottom: 30}}>
                    <ABADropdown
                        label="Country *"
                        placeholder="Country *"
                        options={countryList || []}
                        width={componentWidth - 20}
                        selectedValue={country}
                        validationStatus={status.country}
                        onChange={this.countryChange}
                    />
                </View>
                <ABATextInput
                    style={styleFunctions.paragraphText()}
                    placeholder="City *"
                    onChangeText={(text) => this.setState({city: text})}
                    width="100%"
                    value={city}
                    textInputStatus={status.city}
                    maxLength={75}
                    onBlur={() => this.setState({city: HelperMethods.properCase(city)})}
                />
                <View
                    style={{
                        flexDirection: componentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                    }}
                >
                    <View
                        style={{
                            width: componentWidth > 600 ? '50%' : '100%',
                        }}
                    >
                        {stateList ? (
                            <View style={{marginBottom: 26, marginTop: 4}}>
                                <ABADropdown
                                    options={stateList || []}
                                    label={`State *`}
                                    placeholder={`State *`}
                                    onChange={(value) => this.setState({state: value})}
                                    selectedValue={state}
                                    validationStatus={status.state}
                                    width={componentWidth > 600 ? '90%' : '100%'}
                                />
                            </View>
                        ) : (
                            <ABATextInput
                                onChangeText={(text) => this.setState({stateOrProvince: text})}
                                placeholder="State or Province *"
                                textInputStatus={status.stateOrProvince}
                                returnKeyType="next"
                                width={componentWidth > 600 ? '90%' : '100%'}
                                marginLeft={0}
                                value={stateOrProvince}
                                maxLength={100}
                                onBlur={() =>
                                    this.setState({
                                        stateOrProvince: HelperMethods.properCase(stateOrProvince),
                                    })
                                }
                            />
                        )}
                    </View>
                    <View
                        style={{
                            width: componentWidth > 600 ? '50%' : '100%',
                        }}
                    >
                        <ABATextInput
                            onChangeText={(text) => this.setState({postal: text})}
                            placeholder="Postal Code *"
                            textInputStatus={status.postal}
                            returnKeyType="next"
                            value={postal}
                            width="100%"
                            maxLength={50}
                        />
                    </View>
                </View>
            </View>
        )
    }
}
