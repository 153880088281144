import React, { Component } from "react";
import { View } from "react-native";
import PropTypes from "prop-types";
import { ColorEnums, GeneralEnums } from "../../../helpers/generalEnums";

export default class ABAMMBarChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        filledHeight = this.props.questionsAnswered > 0 ? this.props.size.height : 0;
        return (
            <View style={{ flexDirection: "column", backgroundColor: this.props.colorEmpty, height: 180, width: this.props.size.width }}>
                {(this.props.registrationStatus === GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA) &&
                    <View style={{ backgroundColor: this.props.colorFilled, height: filledHeight, width: "100%", position: "absolute", bottom: 0 }}></View>
                }
            </View>
        );
    }
}

ABAMMBarChart.defaultProps = {
    colorFilled: ColorEnums.surfGreen,
    colorEmpty: ColorEnums.lightGray,
    questionsAnswered: 0,
};

ABAMMBarChart.propTypes = {
    annualMaxQuestions: PropTypes.number,
    colorFilled: PropTypes.string,
    colorEmpty: PropTypes.string,
    questionsAnswered: PropTypes.number,
    registrationStatus: PropTypes.number,
    size: PropTypes.object.isRequired
};