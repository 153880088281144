import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Text, TextInput, View} from 'react-native'

import styleFunctions from '../../../styles/styleFunctions'
import HelperMethods from '../../../helpers/HelperMethods'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'

export default class ABATextInputMultiline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            borderColor: ColorEnums.blueGray,
            borderWidth: 1,
            RemainingChars: this.props.maxLength,
        }

        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
    }

    getTextInputStatusColor() {
        switch (this.props.textInputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                return ColorEnums.blueGray
            case GeneralEnums.textInputStatus.error:
                return ColorEnums.pomegranate
            case GeneralEnums.textInputStatus.success:
                return ColorEnums.surfGreen
            case GeneralEnums.textInputStatus.default:
            default:
                return ColorEnums.blueGray
        }
    }

    setBorderStyle(targetBorderWidth) {
        this.setState({
            borderColor: this.getTextInputStatusColor(),
            borderWidth: targetBorderWidth,
        })
    }

    componentDidMount() {
        this.updateCharRemaining(this.props.value)
    }

    componentDidUpdate(previousProps) {
        if (this.props.textInputStatus !== previousProps.textInputStatus) {
            this.setBorderStyle(1)
        }
        if (this.props.value !== previousProps.value) {
            this.updateCharRemaining(this.props.value)
        }
    }

    onBlur() {
        this.setBorderStyle(1)
    }

    onFocus() {
        this.setBorderStyle(2)
    }

    renderIcon() {
        let targetIcon = null

        switch (this.props.textInputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetIcon = IconEnums['lock']
                break
            case GeneralEnums.textInputStatus.error:
                targetIcon = IconEnums['times']
                break
            case GeneralEnums.textInputStatus.success:
                targetIcon = IconEnums['check']
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetIcon = null
                break
        }

        return (
            <View style={{marginBottom: 30, width: '10%'}}>
                <Text
                    style={{
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        color: this.getTextInputStatusColor(),
                        fontSize: 14,
                        paddingLeft: 7,
                        paddingTop: 10,
                        textAlign: 'right',
                    }}
                >
                    {targetIcon}
                </Text>
            </View>
        )
    }

    updateCharRemaining(currentText) {
        if (currentText) {
            this.setState({RemainingChars: this.props.maxLength - currentText.length})
        } else {
            this.setState({RemainingChars: this.props.maxLength})
        }
    }

    render() {
        return (
            <View
                style={[
                    {marginLeft: 'auto', marginRight: 'auto', width: this.props.width},
                    this.props.style,
                ]}
            >
                {this.props.label && (
                    <Text
                        style={
                            this.props.labelStyle
                                ? this.props.labelStyle
                                : styleFunctions.containerText()
                        }
                    >
                        {this.props.label}
                    </Text>
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        borderRadius: 5,
                        borderColor: this.state.borderColor,
                        borderWidth: this.state.borderWidth,
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}
                >
                    <TextInput
                        autoCorrect={false}
                        editable={this.props.textInputStatus != GeneralEnums.textInputStatus.disabled}
                        keyboardType={this.props.keyboardType}
                        maxLength={this.props.maxLength}
                        multiline={true}
                        numberOfLines={this.props.numberOfLines}
                        onBlur={this.onBlur}
                        onChangeText={(text) => {
                            text = HelperMethods.replaceApostrophe(text)
                            this.updateCharRemaining(text)
                            this.props.onChangeText(text)
                        }}
                        onFocus={this.onFocus}
                        onKeyPress={this.props.onKeyPress}
                        onSubmitEditing={this.props.onSubmitEditing}
                        placeholder={this.props.placeholder}
                        placeholderTextColor={ColorEnums.blueGray}
                        ref={this.props.reference}
                        returnKeyType={this.props.returnKeyType}
                        style={Object.assign({}, this.props.style ? this.props.style : {}, {
                            width: '90%',
                            maxHeight: this.props.maxHeight ? this.props.maxHeight : 100,
                        })}
                        textAlignVertical="top"
                        underlineColorAndroid="transparent"
                        value={this.props.value}
                        testID={`ABAMultiInput${this.props.label || this.props.placeholder}`}
                    />
                    {this.renderIcon()}
                </View>
                {this.props.showCharactersRemaining && (
                    <View style={{alignItems: 'flex-end'}}>
                        <Text>
                            {this.state.RemainingChars.toLocaleString('en-us')} characters remaining
                        </Text>
                    </View>
                )}
                {this.props.textInputStatus === GeneralEnums.textInputStatus.error && (
                    <Text style={{color: ColorEnums.pomegranate}}>{this.props.errorMessage}</Text>
                )}
            </View>
        )
    }
}

ABATextInputMultiline.defaultProps = {
    errorMessage: 'Please enter a value.',
    returnKeyType: 'done',
    width: '100%',
    showCharactersRemaining: true,
}

ABATextInputMultiline.propTypes = {
    errorMessage: PropTypes.string,
    keyboardType: PropTypes.string,
    label: PropTypes.string,
    maxHeight: PropTypes.number,
    maxLength: PropTypes.number.isRequired,
    numberOfLines: PropTypes.number.isRequired,
    onChangeText: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    placeholder: PropTypes.string,
    reference: PropTypes.func,
    returnKeyType: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    textInputStatus: PropTypes.number,
    value: PropTypes.string,
    width: PropTypes.string,
    showCharactersRemaining: PropTypes.bool,
}
