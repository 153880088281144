import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import releaseLogic from '../logic/releaseLogic'
import styleFunctions from '../../../styles/styleFunctions'
import ABACheckbox from '../../../components/ABACustom/presentation/ABACheckbox'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'

export default class Release extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: false,
        NoteText: '',
        ReleaseData: null,
        TextDisplay: null,
        LabelText: '',
        InputStatus: {
            ReleaseOption: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        releaseLogic.GetReleaseData().then((Data) => {
            releaseLogic.PopulateRelease(Data)
            releaseLogic.GetText(Data).then((Text) => {
                releaseContext.setState({TextDisplay: Text})
            })
        })
    }

    Save() {
        return releaseLogic.SaveLogic()
    }

    render() {
        releaseContext = this
        releaseLogic.context = releaseContext

        return (
            <View>
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 10,
                                paddingBottom: 20,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            },
                        ]}
                    >
                        Before proceeding to the next section, please read and acknowledge the following
                        statement:
                    </Text>
                </View>
                <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                paddingTop: 10,
                                width: '90%',
                                paddingBottom: 10,
                                textAlign: 'justify',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            },
                        ]}
                    >
                        {releaseContext.state.TextDisplay}
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 25,
                    }}
                >
                    <View style={{alignSelf: 'center', paddingTop: 25, width: '85%'}}>
                        <ABACheckbox
                            isChecked={releaseContext.state.ReleaseOption}
                            isRequired={true}
                            onChangeEvent={releaseLogic.ReleaseOptionSelected}
                            label="I have read and understand the Registration Release statement as it pertains to my registration for ABA exam."
                            labelStyle={{
                                fontFamily: 'Lato',
                                color: ColorEnums.cloudBurst,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                            validationStatus={this.state.InputStatus.ReleaseOption}
                        />
                    </View>
                    <View
                        style={{
                            borderTopColor: ColorEnums.backgroundGray,
                            borderTopWidth: 1,
                            width: '90%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 15,
                        }}
                    />
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 12, paddingTop: 15, textAlign: 'center'},
                        ]}
                    >
                        By checking the above box, you are affixing your legal electronic signature to
                        this document.
                    </Text>
                </View>
                {releaseContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Release</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
