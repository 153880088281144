import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const HeaderLogic = {
    context: null,

    GetHeaderInfo: function () {
        return new Promise((resolve) => {
            HeaderLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('doctorInformation/getDoctorHeaderInfo')
                .then(function (response) {
                    HeaderLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    HeaderLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
        })
    },

    GetBellNotificationsCount: function () {
        return new Promise((resolve) => {
            HeaderLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('notifications/getBellNotificationsCount')
                .then(function (response) {
                    HeaderLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    HeaderLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Bell Notifications')
                    resolve(null)
                })
        })
    },
}

export default HeaderLogic
