import React, {Component} from 'react'
import {View} from 'react-native'
import {connect} from 'react-redux'
import MocaMinuteLogic from '../logic/mocaMinuteLogic'
import MocaMinuteInformation from '../presentation/mocaMinuteInformation'
import MyProgressMM from '../presentation/myProgressMM'
import {ColorEnums} from '../../helpers/generalEnums'

const mapStateToProps = (state) => ({
    registration: state.registration,
})

class MocaMinute extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            MMDataContainer: {
                AnnualMaxQuestions: null,
                AnnualQuestionsRemaining: null,
                CurrentQuarter: null,
                ConsentToCME: false,
                DailyQuestionsRemaining: null,
                HasReachedAnnualLimit: false,
                HasReachedDailyLimit: false,
                HasReachedQuarteryLimit: false,
                IsEligibleCurrently: false,
                MDTValue: null,
                MMStatusScenario: null,
                MocaRegistrationStatus: null,
                QuarterlyQuestionsRemaining: null,
                Q1Answered: null,
                Q2Answered: null,
                Q3Answered: null,
                Q4Answered: null,
                MocaCR2: null,
            },
            columnWidthDynamic: '50%',
            flexDirectionDynamic: 'row',
            height: 100,
        }
    }

    componentDidMount() {
        MocaMinuteLogic.GetMocaMinuteDashboardInfo().then((response) => {
            this.setState({MMDataContainer: response})
        })
    }

    async componentDidUpdate(prevProps) {
        if (this.props.registration.pendingPayment != prevProps.registration.pendingPayment) {
            mmData = await MocaMinuteLogic.GetMocaMinuteDashboardInfo()
            this.setState({MMDataContainer: mmData})
        }
    }

    changeLayoutBasedOnWidth(componentWidth) {
        if (componentWidth <= 1100) {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: null,
                    borderTopWidth: 2,
                    borderLeftColor: null,
                    borderTopColor: ColorEnums.aquaHaze,
                },
                columnWidthDynamic: '100%',
                flexDirectionDynamic: 'column',
            })
        } else {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: 2,
                    borderTopWidth: null,
                    borderLeftColor: ColorEnums.aquaHaze,
                    borderTopColor: null,
                },
                columnWidthDynamic: '50%',
                flexDirectionDynamic: 'row',
            })
        }
    }

    render() {
        MocaMinuteLogic.context = this
        return (
            <View
                style={{flexDirection: this.state.flexDirectionDynamic}}
                onLayout={(event) => {
                    this.changeLayoutBasedOnWidth(event.nativeEvent.layout.width)
                }}
            >
                <View
                    style={{width: this.state.columnWidthDynamic}}
                    onLayout={(event) => {
                        this.setState({height: event.nativeEvent.layout.height})
                    }}
                >
                    <MocaMinuteInformation
                        MMData={this.state.MMDataContainer}
                        MocaCR2={this.props.MocaCR2}
                        PaymentOffline={this.props.registration.paymentOffline}
                        Height={this.state.height}
                        Stacked={this.state.flexDirectionDynamic === 'column'}
                    />
                </View>

                <View style={[{width: this.state.columnWidthDynamic}, this.state.borderDynamic]}>
                    <MyProgressMM MMData={this.state.MMDataContainer} />
                </View>
            </View>
        )
    }
}
export default connect(mapStateToProps)(MocaMinute)
