import React, {Component} from 'react'
import {Text, TouchableOpacity, View, StyleSheet} from 'react-native'
import NavigationHelper from '../../helpers/NavigationHelper'
import {GeneralEnums, IconEnums, ColorEnums, ExamProviders} from '../../helpers/generalEnums'
import PropTypes from 'prop-types'

export default class Alert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            height: 20,
        }

        this.styles = StyleSheet.create({
            AlertContainer: {
                paddingTop: this.props.FirstAlert ? 0 : 20,
                paddingBottom: this.props.LastAlert ? 0 : 20,
            },
            AlertText: {
                fontSize: 14,
                alignSelf: 'flex-start',
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
                flex: 1,
                flexDirection: 'column',
            },
            AlertIcon: {
                fontSize: 14,
                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                fontWeight: 'normal',
            },
            AlertBorder: {
                borderBottomColor: ColorEnums.aquaHaze,
                borderBottomWidth: 1,
            },
            ChevronText: {
                fontWeight: 'normal',
                color: ColorEnums.danube,
                fontSize: 14,
                fontFamily: GeneralEnums.fontAwesomeFamilyName,
            },
            ChevronContainer: {
                paddingLeft: 10,
                alignItems: 'center',
                justifyContent: 'center',
            },
            IconContainer: {
                paddingRight: 10,
                alignItems: 'center',
                justifyContent: 'center',
            },
        })
    }

    render() {
        let AlertBorder = !this.props.LastAlert ? this.styles.AlertBorder : {}
        const {evt_ScheduledBy} = this.props.NavigationData
        return (
            <View style={[this.styles.AlertContainer, AlertBorder]}>
                <TouchableOpacity
                    style={{flexDirection: 'row'}}
                    onPress={() => {
                        NavigationHelper.GoToPage(
                            GeneralEnums.navigationType.push,
                            this.props.Route,
                            this.props.NavigationData,
                        )
                    }}
                    testID={`alert${this.props.AlertText?.substring?.(10)}`}
                >
                    <View style={[this.styles.IconContainer, {height: this.state.height}]}>
                        <Text style={[this.styles.AlertIcon, {color: this.props.IconColor}]}>
                            {IconEnums['exclamation_triangle']}
                        </Text>
                    </View>

                    <Text
                        style={this.styles.AlertText}
                        onLayout={(event) => {
                            this.setState({height: event.nativeEvent.layout.height})
                        }}
                    >
                        {this.props.AlertText} {'\n'}
                        {evt_ScheduledBy === ExamProviders.PEARSON_VUE && (
                            <Text style={[this.styles.AlertText, {fontSize: 10, alignSelf: 'center'}]}>
                                Click to schedule, reschedule or view your appointment.
                            </Text>
                        )}
                    </Text>

                    <View style={[this.styles.ChevronContainer, {height: this.state.height, width: 20}]}>
                        <Text style={this.styles.ChevronText}>{IconEnums['chevron-right']}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}

Alert.defaultProps = {
    FirstAlert: true,
    LastAlert: true,
    AlertText: '',
    IconColor: ColorEnums.pomegranate,
    Route: '/dashboard',
    NavigationData: {},
}

Alert.propTypes = {
    FirstAlert: PropTypes.bool,
    LastAlert: PropTypes.bool,
    AlertText: PropTypes.string,
    IconColor: PropTypes.string,
    Route: PropTypes.string,
    NavigationData: PropTypes.object,
}
