import React, { Component } from "react";
import {
    View,
    StyleSheet,
    Text 
} from "react-native";
import ABAHyperLink from "../../components/ABACustom/presentation/ABAHyperLink";
import { ColorEnums, GeneralEnums, IconEnums } from "../../helpers/generalEnums";

const styles = StyleSheet.create({
    examInfoLinksTitle: {
        marginTop: 15, 
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 16, 
        fontWeight: 'bold', 
        marginLeft: 20, 
        marginRight: 20
    },   

    examInfoLinksDescription: {
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 16, 
        fontWeight: 'normal', 
        marginLeft: 20, 
        marginRight: 20                 
    },

    hyperLink: {
        color: ColorEnums.danube, 
        fontFamily: 'Lato',
        fontSize: 16, 
        marginLeft: 20, 
        marginRight: 20        
    } 
});

const ExamInfoLinksLogic = {
    context: null, 

    PopulateExamInfoLinks: function (examInfoLinksArray) {
        let ExamInfoLinksScreenComponents = [];

        if (!examInfoLinksArray || examInfoLinksArray.length <= 0) {
            return ExamInfoLinksScreenComponents;
        } 

        for (let index = 0; index < examInfoLinksArray.length; index++) {
            ExamInfoLinksScreenComponents.push(  
                <View key={index}>  
                    <Text style={styles.examInfoLinksTitle}>
                        {examInfoLinksArray[index][0]}
                    </Text>
                    <Text style={styles.examInfoLinksDescription}>
                        {examInfoLinksArray[index][1]}
                    </Text>
                    <ABAHyperLink
                        label='View Here >' 
                        url = {examInfoLinksArray[index][2]}
                        style={styles.hyperLink}
                    />                    
                </View>
            )
        }                  
        return ExamInfoLinksScreenComponents;
    }     
}

export default ExamInfoLinksLogic;