import {GeneralEnums} from '../../helpers/generalEnums'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

const CreateAccountLogic = {
    context: null,

    goToConfirmIdentity() {
        let validationPassed = CreateAccountLogic.validateFieldData()

        if (!validationPassed) {
            return
        }

        CreateAccountLogic.getContactInfoForAccountValidation()
    },

    goToSetPassword(contactId) {
        if (!CreateAccountLogic.verifyLastNameMatches()) {
            return
        }

        NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/createAccountSetPassword', {
            ContactId: contactId,
        })
    },

    goToValidateIdentity() {
        navigationContext.props.history.replace('/createAccountValidate')
    },

    getContactInfoForAccountValidation: function () {
        CreateAccountLogic.context.setState({CallingApi: true})

        ABAPortalAPI.post('authentication/getContactInfoForAccountValidation', {
            AbaId: CreateAccountLogic.context.state.abaId,
            DateOfBirth: CreateAccountLogic.context.state.dateOfBirth,
            LastName: CreateAccountLogic.context.state.lastName,
            Ssn: CreateAccountLogic.context.state.ssnLastFour,
        })
            .then(function (response) {
                CreateAccountLogic.context.setState({CallingApi: false})

                if (!response.data) {
                    HelperMethods.alertAgnostic(
                        'The information you provided does not identify you as an ABA Diplomate, Candidate or Applicant with a current ABA account, or an anesthesiology resident who may create an ABA account. Please re-enter your information.\n\nIf you have any questions, please contact the ABA Customer Service Center at (866) 999-7501.',
                        'Account Not Found',
                        [{text: 'Ok'}],
                    )
                } else if (response.data.LoginExists) {
                    HelperMethods.alertAgnostic(
                        "This account already exists. If you don't remember your password, click Forgot Password from the login screen.",
                        'Account Already Exists',
                        [{text: 'Ok'}],
                    )

                    NavigationHelper.goToHomePage()
                } else {
                    NavigationHelper.GoToPage(
                        GeneralEnums.navigationType.replace,
                        '/createAccountVerify',
                        {
                            ContactFullName: response.data.ContactFullName,
                            ContactLastName: response.data.ContactLastName,
                            ContactId: response.data.ContactId,
                        },
                    )
                }
            })
            .catch(function (error) {
                CreateAccountLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Error Verifying Information')
            })
    },

    createAccount: function (newPassword, newPasswordConfirm) {
        if (newPassword != newPasswordConfirm) {
            HelperMethods.alertAgnostic('Your passwords do not match.', 'Your passwords do not match.', [
                {text: 'Ok'},
            ])

            return
        }

        if (!ValidationHelper.isPasswordFormatValid(newPassword)) {
            HelperMethods.alertAgnostic(
                'Your password does not meet the specified requirements.',
                'Your password does not meet the specified requirements.',
                [{text: 'Ok'}],
            )

            return
        }

        CreateAccountLogic.context.setState({CallingApi: true})

        ABAPortalAPI.post('authentication/createAccount', {
            ContactId: CreateAccountLogic.context.state.ContactId,
            NewPassword: newPassword,
        })
            .then(function (response) {
                CreateAccountLogic.context.setState({CallingApi: false})

                HelperMethods.alertAgnostic(
                    'Your account has been successfully created. Please log in to continue.',
                    'Account Created Successfully',
                    [{text: 'Ok'}],
                )

                navigationContext.props.history.replace('/')
            })
            .catch(function (error) {
                CreateAccountLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Error Creating Account')
            })
    },

    validateFieldData: function () {
        const FIELDS_REQUIRED_COUNT = 3

        //var names for our state variables
        let abaId = CreateAccountLogic.context.state.abaId
        let dateOfBirth = CreateAccountLogic.context.state.dateOfBirth
        let lastName = CreateAccountLogic.context.state.lastName
        let ssnLastFour = CreateAccountLogic.context.state.ssnLastFour

        //Since err messages for SSN and DOB can vary, we're just setting them to an empty string here
        let errMsgSsn = ''
        let errMsgDateOfBirth = ''

        //creating default input statuses before we check each of our inputs
        let inputStatus = {
            ssn: GeneralEnums.textInputStatus.default,
            abaId: GeneralEnums.textInputStatus.default,
            dateOfBirth: GeneralEnums.textInputStatus.default,
            lastName: GeneralEnums.textInputStatus.default,
        }

        let validationPassed = true
        let alertMessage = ''
        let allFieldsExceptSsn = [abaId, dateOfBirth, lastName]
        let populatedFieldsCount = 0

        if (ssnLastFour && ssnLastFour.length > 0) {
            populatedFieldsCount++
        }

        for (let counter = 0; counter < allFieldsExceptSsn.length; counter++) {
            if (allFieldsExceptSsn[counter] && allFieldsExceptSsn[counter].length > 0) {
                populatedFieldsCount++
            }
        }

        if (populatedFieldsCount < FIELDS_REQUIRED_COUNT) {
            alertMessage +=
                'Please enter the last four digits of your social security number and complete two additional fields.\n'
        }

        if (!ssnLastFour) {
            inputStatus.ssn = GeneralEnums.textInputStatus.error
            errMsgSsn = 'Last 4 of your social security number is required'
        }

        if (abaId && !ValidationHelper.isAbaIdFormatValid(abaId)) {
            inputStatus.abaId = GeneralEnums.textInputStatus.error
        }

        if (dateOfBirth && dateOfBirth.length < 10) {
            inputStatus.dateOfBirth = GeneralEnums.textInputStatus.error
            errMsgDateOfBirth = 'Invalid date format'
        } else if (dateOfBirth && !ValidationHelper.isDateOfBirthFormatValid(dateOfBirth)) {
            inputStatus.dateOfBirth = GeneralEnums.textInputStatus.error
            errMsgDateOfBirth = 'Invalid date'
        }

        if (ssnLastFour && !ValidationHelper.isSSNFormatValid(ssnLastFour)) {
            inputStatus.ssn = GeneralEnums.textInputStatus.error
            errMsgSsn = 'Invalid SSN format'
        }

        for (let key in inputStatus) {
            if (inputStatus[key] === GeneralEnums.textInputStatus.error) {
                validationPassed = false
                break
            }
        }

        if (alertMessage) {
            HelperMethods.alertAgnostic(alertMessage, 'Invalid Format', [{text: 'Ok'}])

            validationPassed = false
        }

        CreateAccountLogic.context.setState({
            errMsgSsn: errMsgSsn,
            errMsgDateOfBirth,
            errMsgDateOfBirth,
            inputStatusAbaId: inputStatus.abaId,
            inputStatusSsn: inputStatus.ssn,
            inputStatusDateOfBirth: inputStatus.dateOfBirth,
            inputStatusLastName: inputStatus.lastName,
        })

        return validationPassed
    },

    verifyLastNameMatches: function () {
        if (
            !CreateAccountLogic.context.state.LastNameConfirmationEntry ||
            !CreateAccountLogic.context.navigationData.ContactLastName ||
            CreateAccountLogic.context.state.LastNameConfirmationEntry.toLowerCase().trim() !==
                CreateAccountLogic.context.navigationData.ContactLastName.toLowerCase().trim()
        ) {
            HelperMethods.alertAgnostic(
                'The last name you entered does not match the last name displayed above.',
                'Last Name Mismatch',
                [{text: 'Ok'}],
            )
            return false
        }

        return true
    },
}

export default CreateAccountLogic
