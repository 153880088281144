import React, { Component } from 'react';
import { View, Image } from 'react-native';
import styleFunctions from '../../../styles/styleFunctions';
import logo from '../../../assets/images/aba_logo.png';

export default class ABALogo extends Component {
    render(){
        return (
            <View style={styleFunctions.loginLogoContainer()}>
                <Image
                    source={logo}
                    style={styleFunctions.loginImage()}
                    resizeMode='contain'
                />
            </View>
        );
    }
}