import React, {Component} from 'react'
import {Alert, BackHandler, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native'

import ABAContainer from '../../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../../components/ABACustom/presentation/ABAHeader'
import ABAMMAnswerOptions from '../../../components/ABACustom/presentation/ABAMMAnswerOptions'
import ABATextInputMultiline from '../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABATouchableHighlight from '../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAKeyboardAwareScrollView from '../../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAWebView from '../../../components/ABACustom/presentation/ABAWebView'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import MocaMinuteConfRelLogic from '../logic/mocaMinuteConfRelLogic'
import MocaMinuteImage from '../../presentation/mocaMinuteImage'
import NavigationHelper from '../../../helpers/NavigationHelper'
import {Prompt} from '../../../router'
import styleFunctions from '../../../styles/styleFunctions'
import mocaMinuteLogic from '../../../screens/logic/mocaMinuteLogic'
import mocaMinuteFeedback from './mocaMinuteFeedback'

export default class MocaMinuteConfRel extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            option: {
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 50,
            },
            optionContainer: {
                marginTop: 20,
                width: '50%',
            },
            optionSelectionUnselected: {
                backgroundColor: ColorEnums.backgroundGray,
                borderRadius: 4,
                width: '100%',
            },
            optionSelectionSelected: {
                backgroundColor: ColorEnums.danube,
                borderRadius: 4,
                width: '100%',
            },
            optionSelectionUnselectedText: {
                color: ColorEnums.cloudBurst,
            },
            optionSelectionSelectedText: {
                color: ColorEnums.white,
            },
        })

        this.ConfidenceOptions = {
            Unsure: 0,
            SomewhatConfident: 1,
            VeryConfident: 2,
        }

        this.RelevanceOptions = {
            NotRelevant: 0,
            SomewhatRelevant: 1,
            VeryRelevant: 2,
        }

        this.state = {
            allowNavigation: false,
            callingApi: false,
            confidenceSelection: null,
            htmlToRender: '',
            relevanceFeedback: '',
            relevanceSelection: null,
            questionImage: null,
            questionImageType: null,
        }
    }

    componentDidMount() {
        const {QuestionText, QuestionImage, QuestionImageType} = this.props.NavData
        this.setState({
            htmlToRender: QuestionText,
            questionImage: QuestionImage,
            questionImageType: QuestionImageType,
        })

        if (Platform.OS === 'web') {
            window.onbeforeunload = function (event) {
                //Modern browsers don't allow custom messages, but we'll put one anyway in case it ever makes a comeback!
                return 'You have not your submitted your response. Do you wish to leave this page?'
            }
        } else if (Platform.OS === 'android') {
            BackHandler.addEventListener('hardwareBackPress', this.androidBackPress)
        }
    }

    componentWillUnmount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = null
        } else if (Platform.OS === 'android') {
            BackHandler.removeEventListener('hardwareBackPress', this.androidBackPress)
        }
    }

    androidBackPress() {
        Alert.alert(
            'Are you sure?',
            'You have not submitted your response. Press OK to LEAVE the page. Press CANCEL to remain on the page.',
            [
                {
                    text: 'OK',
                    onPress: () => {
                        NavigationHelper.navigateBack()
                    },
                },
                {text: 'Cancel'},
            ],
            {cancelable: true},
        )

        return true
    }

    render() {
        MocaMinuteConfRelLogic.context = this

        return (
            <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                {Platform.OS === 'web' && (
                    <Prompt
                        when={!this.state.allowNavigation}
                        message={
                            'You have not submitted your response. Click OK to LEAVE the page. Click CANCEL to remain on the page.'
                        }
                    />
                )}
                <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCAMinute} />
                <ABAContainer
                    activityText={'Saving Confidence And Relevance'}
                    containerTitle={'QUESTION CONFIDENCE AND RELEVANCE'}
                    showActivityIndicator={this.state.callingApi}
                >
                    <View>
                        <MocaMinuteImage
                            image={this.state.questionImage}
                            imageType={this.state.questionImageType}
                        />
                        <ABAWebView htmlToRender={this.state.htmlToRender} />

                        <View style={{alignItems: 'center'}}>
                            <View
                                style={{
                                    marginTop: 20,
                                    width: '99%',
                                }}
                            >
                                <Text style={styleFunctions.containerTextBold()}>You answered:</Text>
                                <ABAMMAnswerOptions
                                    onPress={(option) => {}}
                                    options={[
                                        Object.assign({}, this.props.NavData.SelectedAnswerOption, {
                                            isSelected: true,
                                        }),
                                    ]}
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                borderBottomColor: ColorEnums.lightGray,
                                borderBottomWidth: 1,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        />

                        <View style={{alignItems: 'center'}}>
                            <Text style={styleFunctions.containerTextBold()}>
                                1. How confident are you in the answer you selected?{' '}
                                <Text style={{color: ColorEnums.pomegranate}}>*</Text>
                            </Text>
                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.confidenceSelection ===
                                        this.ConfidenceOptions.VeryConfident
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            confidenceSelection: this.ConfidenceOptions.VeryConfident,
                                        })
                                    }}
                                    testID="VeryConfidentButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.confidenceSelection ===
                                                this.ConfidenceOptions.VeryConfident
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Very Confident
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.confidenceSelection ===
                                        this.ConfidenceOptions.SomewhatConfident
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            confidenceSelection:
                                                this.ConfidenceOptions.SomewhatConfident,
                                        })
                                    }}
                                    testID="SomewhatConfidentButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.confidenceSelection ===
                                                this.ConfidenceOptions.SomewhatConfident
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Somewhat Confident
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.confidenceSelection === this.ConfidenceOptions.Unsure
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            confidenceSelection: this.ConfidenceOptions.Unsure,
                                        })
                                    }}
                                    testID="UnsureButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.confidenceSelection ===
                                                this.ConfidenceOptions.Unsure
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Unsure
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View
                            style={{
                                borderBottomColor: ColorEnums.lightGray,
                                borderBottomWidth: 1,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        />

                        <View style={{alignItems: 'center'}}>
                            <Text style={styleFunctions.containerTextBold()}>
                                2. How relevant is this question to your practice?
                            </Text>
                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.relevanceSelection ===
                                        this.RelevanceOptions.VeryRelevant
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            relevanceSelection: this.RelevanceOptions.VeryRelevant,
                                        })
                                    }}
                                    testID="VeryRelevantButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.relevanceSelection ===
                                                this.RelevanceOptions.VeryRelevant
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Very Relevant
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.relevanceSelection ===
                                        this.RelevanceOptions.SomewhatRelevant
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            relevanceSelection: this.RelevanceOptions.SomewhatRelevant,
                                        })
                                    }}
                                    testID="SomewhatRelevantButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.relevanceSelection ===
                                                this.RelevanceOptions.SomewhatRelevant
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Somewhat Relevant
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={this.styles.optionContainer}>
                                <TouchableOpacity
                                    style={
                                        this.state.relevanceSelection ===
                                        this.RelevanceOptions.NotRelevant
                                            ? this.styles.optionSelectionSelected
                                            : this.styles.optionSelectionUnselected
                                    }
                                    activeOpacity={1}
                                    onPress={() => {
                                        this.setState({
                                            relevanceSelection: this.RelevanceOptions.NotRelevant,
                                        })
                                    }}
                                    testID="NotRelevantButton"
                                >
                                    <View style={this.styles.option}>
                                        <Text
                                            style={
                                                this.state.relevanceSelection ===
                                                this.RelevanceOptions.NotRelevant
                                                    ? this.styles.optionSelectionSelectedText
                                                    : this.styles.optionSelectionUnselectedText
                                            }
                                        >
                                            Not Relevant
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                        {this.state.relevanceSelection === this.RelevanceOptions.NotRelevant && (
                            <View style={{marginTop: 20}}>
                                <Text style={styleFunctions.containerTextBold()}>
                                    3. Why is this not relevant to your practice?
                                </Text>
                                <ABATextInputMultiline
                                    maxLength={2500}
                                    numberOfLines={10}
                                    onChangeText={(value) => {
                                        this.setState({relevanceFeedback: value})
                                    }}
                                    value={this.state.relevanceFeedback}
                                />
                            </View>
                        )}

                        <View
                            style={{
                                borderBottomColor: ColorEnums.lightGray,
                                borderBottomWidth: 1,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        />

                        <ABATouchableHighlight
                            icon={'chevron-right'}
                            isDisabled={this.state.confidenceSelection === null}
                            onPressEvent={() => {
                                this.setState({allowNavigation: true})
                                MocaMinuteConfRelLogic.updateMocaMinuteConfidenceRelevance().then(
                                    (resolvedData) => {
                                        if (resolvedData === null) {
                                            return
                                        }
                                        mocaMinuteLogic.swapMocaComponent(mocaMinuteFeedback, {
                                            AskId: this.props.NavData.AskId,
                                        })
                                    },
                                )
                            }}
                            text={'Submit'}
                        />

                        <Text style={{color: ColorEnums.pomegranate}}>* Required Information</Text>
                    </View>
                </ABAContainer>
            </ABAKeyboardAwareScrollView>
        )
    }
}
