import HelperMethods from '../../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import NavigationHelper from '../../../../helpers/NavigationHelper'
import {GeneralEnums} from '../../../../helpers/generalEnums'
const EventSelectionLogic = {
    context: null,

    getAvailableSessions: function () {
        EventSelectionLogic.context.setState({CallingApi: true})
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    CR2ID: EventSelectionLogic.context.props.CR2ID,
                    order: EventSelectionLogic.context.props.Order,
                },
            }

            ABAPortalAPI.get('registration/getAvailableExamSessions', getParams)
                .then(function (response) {
                    let sessions = []
                    const currentYear = new Date().getFullYear()

                    response.data.forEach((session) => {
                        const yearIdx =
                            new Date(Date.parse(session.evt_datetimestart)).getFullYear() - currentYear
                        while (yearIdx >= sessions.length) {
                            sessions.push([])
                        }
                        sessions[yearIdx] = sessions[yearIdx].concat([session])
                    })

                    resolve(sessions)
                })
                .catch(function (error) {
                    if (error?.response?.status === 400) {
                        HelperMethods.alertAgnostic(
                            'There was an error retrieving the available sessions.',
                            'Error',
                        )
                        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                    } else {
                        HelperMethods.apiErrorHandler(error, 'Error Retrieving Open Exam Sessions')
                    }
                    resolve([])
                })
                .finally(function () {
                    EventSelectionLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default EventSelectionLogic
