import {ColorEnums} from '../helpers/generalEnums'

const styleFunctions = {
    buttonClick: function () {
        return {
            alignItems: 'center',
            backgroundColor: ColorEnums.danube,
            borderRadius: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 0,
            minHeight: 40,
        }
    },

    buttonClickInverted: function () {
        return {
            alignItems: 'center',
            backgroundColor: ColorEnums.white,
            borderColor: ColorEnums.danube,
            borderRadius: 50,
            borderWidth: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            marginRight: 'auto',
            marginLeft: 'auto',
            height: 0,
            minHeight: 40,
        }
    },

    controlLabel: function () {
        return {
            fontFamily: 'Lato',
            fontSize: 10,
            color: ColorEnums.blueGray,
        }
    },

    textInput: function (borderBottomColor, borderBottomWidth) {
        return {
            borderBottomColor: borderBottomColor,
            borderBottomWidth: borderBottomWidth,
            outline: 'none',
            height: 35,
            marginBottom: 30,
            width: '90%',
            fontSize: 16,
            fontFamily: 'Lato',
            color: ColorEnums.cloudBurst,
        }
    },

    loginImage: function () {
        return {
            paddingBottom: 100,
            // height and width are 70% of actual image size (206 * 91)
            height: 65,
            width: 144,
            resizeMode: 'contain',
        }
    },

    loginBackground: function () {
        return {
            backgroundColor: ColorEnums.cloudBurst,
        }
    },

    containerTitle: function () {
        return {
            backgroundColor: ColorEnums.indigo,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            flex: 1,
            paddingBottom: 7,
            paddingLeft: 20,
            paddingTop: 7,
        }
    },

    containerHeader: function (alignment) {
        if (alignment == undefined) {
            alignment = 'left'
        }
        return {
            textAlign: alignment,
            color: ColorEnums.danube,
            fontSize: 36,
        }
    },

    containerText: function (alignment) {
        if (alignment == undefined) {
            alignment = 'left'
        }
        return {
            textAlign: alignment,
            fontFamily: 'Lato',
            color: ColorEnums.cloudBurst,
            fontSize: 18,
        }
    },

    containerTextBold: function (alignment) {
        if (alignment == undefined) {
            alignment = 'left'
        }
        return {
            textAlign: alignment,
            fontFamily: 'Lato',
            color: ColorEnums.cloudBurst,
            fontSize: 18,
            fontWeight: 'bold',
        }
    },

    placeholderText: function () {
        return {
            fontFamily: 'Lato',
            color: ColorEnums.lightGray,
        }
    },

    columnHeader: function () {
        return {
            color: ColorEnums.blueGray,
        }
    },

    paragraphText: function (alignment) {
        if (alignment == undefined) {
            alignment = 'left'
        }
        return {
            textAlign: alignment,
            fontFamily: 'Lato',
            color: ColorEnums.cloudBurst,
        }
    },

    progressBarFill: function () {
        return {
            backgroundColor: ColorEnums.surfGreen,
        }
    },

    progressBarEmpty: function () {
        return {
            backgroundColor: ColorEnums.aquaHaze,
        }
    },

    actionText: function () {
        return {
            color: ColorEnums.danube,
        }
    },

    columnWidth: function (componentWidth, percent) {
        return {
            width: componentWidth * percent,
        }
    },

    filledbarbgcolor: function () {
        return {
            height: 15,
            justifyContent: 'center',
        }
    },

    emptybarbgcolor: function () {
        return {
            height: 15,
        }
    },

    chartoutercontainer: function () {
        return {
            height: 30,
            alignItems: 'center',
            flexDirection: 'row',
            paddingBottom: 7,
        }
    },

    Accordioncontainer: function () {
        return {
            margin: 10,
            overflow: 'hidden',
            zIndex: 10,
        }
    },

    AccordionIconStyle: function () {
        return {
            alignSelf: 'center',
        }
    },

    AccordiontitleContainer: function () {
        return {
            flexDirection: 'row',
        }
    },

    Accordiontitle: function () {
        return {
            flex: 1,
            color: ColorEnums.white,
            fontFamily: 'Lato',
            fontSize: 16,
            fontWeight: 'bold',
        }
    },

    AccordionBottomViewStyle: function () {
        return {
            backgroundColor: ColorEnums.white,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        }
    },

    loadingOverlay: function () {
        return {
            alignItems: 'center',
            backgroundColor: '#F5FCFF88',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
        }
    },

    loginNavLinks: function () {
        return {
            color: ColorEnums.white,
            fontFamily: 'Lato',
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
        }
    },

    loginHeader: function () {
        return {
            color: '#3399cc',
            fontSize: 32,
            marginTop: 20,
            textAlign: 'center',
            fontFamily: 'Lato',
        }
    },

    loginFooter: function () {
        return {
            color: ColorEnums.white,
            fontSize: 12,
            fontFamily: 'Lato',
            textAlign: 'center',
        }
    },

    loginContainer: function () {
        return {
            backgroundColor: ColorEnums.white,
            borderRadius: 4,
            justifyContent: 'center',
            width: 400,
            paddingBottom: 50,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },

    loginFormContainer: function () {
        return {
            marginTop: 20,
        }
    },

    loginLogoContainer: function () {
        return {
            marginTop: 40,
            marginBottom: 20,
            alignItems: 'center',
        }
    },

    confirmationOverlay: function () {
        return {
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,.4)',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
        }
    },
}

export default styleFunctions
