import {Platform} from 'react-native'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import ClinicalActivityStep3 from '../presentation/clinicalActivityStep3'
import ClinicalActivityStep5 from '../presentation/clinicalActivityStep5'
import {GeneralEnums} from '../../../../helpers/generalEnums'

const ClinicalActivityLogicStep4 = {
    context: null,

    GetReferenceIDs() {
        return new Promise((resolve) => {
            const {ClinicalActivityID} = clinicalActivityContext.state
            let getParams = {
                params: {
                    ClinicalActivityID,
                    allReferences: ClinicalActivityLogicStep4.context.props.isConfirmationPage ? 1 : 0,
                },
            }

            ClinicalActivityLogicStep4.context.setState({CallingApi: true})
            ABAPortalAPI.get('reference/getReferenceIDs', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(function () {
                    ClinicalActivityLogicStep4.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables(ReferenceIDs) {
        if (clinicalActivityContext.state.ClinicalActivityWorkPrivilege === 3) {
            //Independent practice
            ClinicalActivityLogicStep4.context.setState({ReferenceIDs})
        } else {
            let SortedReferenceIDs = []
            const {
                Clinical_Activity_Chief_of_Anesthesiology,
                Clinical_Activity_Chief_of_Staff,
                Clinical_Activity_Clinical_Competence_Committee_Chair,
            } = GeneralEnums.cert_referenceType
            ReferenceIDs.map((reference, index) => {
                switch (reference.cert_ReferenceType) {
                    case Clinical_Activity_Chief_of_Anesthesiology:
                        SortedReferenceIDs[0] = ReferenceIDs[index]
                        break
                    case Clinical_Activity_Chief_of_Staff:
                        SortedReferenceIDs[1] = ReferenceIDs[index]
                        break
                    case Clinical_Activity_Clinical_Competence_Committee_Chair:
                        SortedReferenceIDs[2] = ReferenceIDs[index]
                        break
                }
            })
            ClinicalActivityLogicStep4.context.setState({ReferenceIDs: SortedReferenceIDs})
        }
    },

    SaveLogic() {
        return new Promise((resolve) => {
            const referenceRefs = [
                ClinicalActivityLogicStep4.context.refs.reference0.state.cert_referenceId,
                ClinicalActivityLogicStep4.context.refs.reference1.state.cert_referenceId,
                ClinicalActivityLogicStep4.context.refs.reference2.state.cert_referenceId,
            ]
            if (
                referenceRefs[0] &&
                referenceRefs[0] !== '-1' &&
                referenceRefs[1] &&
                referenceRefs[1] !== '-1' &&
                referenceRefs[2] &&
                referenceRefs[2] !== '-1'
            ) {
                registrationStepsDisplayContext.setState({ShowPrevious: true, ContinueText: 'Confirm'})
                clinicalActivityContext.setState(
                    {
                        CurrentComponentStep: ClinicalActivityStep5,
                    },
                    () => {
                        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                            x: 0,
                            y: 0,
                            animated: false,
                        })
                    },
                )
            } else {
                HelperMethods.alertAgnostic('Please include three references.', 'An Error Occured')
            }
            resolve(false)
        })
    },

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({CurrentComponentStep: ClinicalActivityStep3}, () => {
                registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                    x: 0,
                    y: 0,
                    animated: false,
                })
            })
            resolve(false)
        })
    },

    AccordianToggled(accordianID, expanded) {
        if (!expanded) {
            return
        }

        ClinicalActivityLogicStep4.context.references.forEach((referenceInfo, index) => {
            if (index != accordianID && referenceInfo.ref.state.expanded) {
                referenceInfo.ref.toggle(false)
            }
        })
    },

    ReferenceSaved(index, expandNext = true) {
        if (expandNext) {
            ClinicalActivityLogicStep4.context.references[index].ref.toggle(false)
            registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                x: 0,
                y: 0,
                animated: false,
            })
            if (index < ClinicalActivityLogicStep4.context.references.length - 1) {
                ClinicalActivityLogicStep4.context.references[index + 1].ref.toggle(false)
            }
        }
        ClinicalActivityLogicStep4.context.forceUpdate()
    },
}

export default ClinicalActivityLogicStep4
