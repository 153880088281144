import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Text, TouchableHighlight, View} from 'react-native'

import {ColorEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'

export default class ABATabControl extends Component {
    constructor(props) {
        super(props)
        const firstTabDisplay = props.tabs[0].componentDisplay
        this.state = {
            CurrentTabComponent: props.defaultTabKey
                ? props.tabs.filter((tab) => tab.key === props.defaultTabKey)[0].componentDisplay
                : firstTabDisplay,
            SelectedTabKey: props.defaultTabKey || props.tabs[0].key,
        }
    }

    componentDidUpdate(prevProps) {
        const prevMocaTab = prevProps.tabs.filter((tabs) => tabs.key === 'mm')[0]
        const currentMocaTab = this.props.tabs.filter((tabs) => tabs.key === 'mm')[0]
        const prevMocaTabCr2 = prevMocaTab.componentDisplay.props.MocaCR2
        const currentMocaTabCr2 = currentMocaTab.componentDisplay.props.MocaCR2
        if (prevMocaTabCr2 !== currentMocaTabCr2 && this.state.SelectedTabKey === 'mm') {
            this.setState({CurrentTabComponent: currentMocaTab.componentDisplay})
        }
    }

    render() {
        return (
            <View>
                <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    {this.props.tabs.map((tab, index) => {
                        let tabStyleCommon = Object.assign({
                            alignItems: 'center',
                            height: this.props.tabHeight,
                            justifyContent: 'center',
                        })
                        let textStyleInactive = Object.assign(
                            styleFunctions.containerText('center'),
                            styleFunctions.columnHeader(),
                            {color: '#6D93A9'},
                        )
                        let tabStyleInactive = Object.assign(
                            {backgroundColor: index % 2 == 0 ? '#EAECED' : '#DCE0E1'},
                            tabStyleCommon,
                        )
                        let textStyleActive = Object.assign(styleFunctions.containerText('center'))
                        let tabStyleActive = Object.assign(
                            {backgroundColor: ColorEnums.white},
                            tabStyleCommon,
                        )

                        return (
                            <View
                                key={tab.key + 'view'}
                                style={{width: 100 / this.props.tabs.length + '%'}}
                            >
                                <TouchableHighlight
                                    key={tab.key + 'tab'}
                                    onPress={() => {
                                        this.setState({
                                            CurrentTabComponent: tab.componentDisplay,
                                            SelectedTabKey: tab.key,
                                        })
                                        this.props.onTabChange && this.props.onTabChange(tab.key)
                                    }}
                                    style={
                                        this.state.SelectedTabKey == tab.key
                                            ? tabStyleActive
                                            : tabStyleInactive
                                    }
                                    underlayColor={ColorEnums.white}
                                    testID={`ABATab${tab.key}`}
                                >
                                    <View>
                                        <Text
                                            style={
                                                this.state.SelectedTabKey == tab.key
                                                    ? textStyleActive
                                                    : textStyleInactive
                                            }
                                        >
                                            {tab.text}
                                        </Text>
                                    </View>
                                </TouchableHighlight>
                            </View>
                        )
                    })}
                </View>
                <View>{this.state.CurrentTabComponent}</View>
            </View>
        )
    }
}

ABATabControl.defaultProps = {
    tabHeight: 50,
}

ABATabControl.propTypes = {
    tabs: PropTypes.array.isRequired,
    tabHeight: PropTypes.number,
    onTabChange: PropTypes.func,
    defaultTabKey: PropTypes.string,
}
