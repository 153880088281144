import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'

export default CMEExplorerLogic = {
    context: null,

    getCertTypeFiltersForAPI: function(certTypes) {
        let certTypeParams = {
            AmbulatoryOutpatient: null,
            CardiacAnesthesia: null,
            CriticalCareMedicine: null,
            GeneralOperativeAnesthesia: null,
            HospiceAndPalliativeMedicine: null,
            NeuroAnesthesia: null,
            NeurocriticalCare: null,
            ObstetricAnesthesia: null,
            PainMedicine: null,
            PediatricAnesthesia: null,
            RegionalAnesthesiaAcutePain: null,
            SleepMedicine: null,
            ThoracicAnesthesia: null,
            Trauma: null,
        }

        if(certTypes.length < 1) {
            return certTypeParams
        }

        if(certTypes.includes('Ambulatory/Outpatient')) { 
            certTypeParams.AmbulatoryOutpatient = true
        }

        if(certTypes.includes('Cardiac Anesthesia')) { 
            certTypeParams.CardiacAnesthesia = true
        }

        if(certTypes.includes('Critical Care Medicine')) { 
            certTypeParams.CriticalCareMedicine = true
        }

        if(certTypes.includes('General Operative Anesthesia')) { 
            certTypeParams.GeneralOperativeAnesthesia = true
        }

        if(certTypes.includes('Hospice and Paliative Medicine')) { 
            certTypeParams.HospiceAndPalliativeMedicine = true
        }

        if(certTypes.includes('Neuro Anesthesia')) { 
            certTypeParams.NeuroAnesthesia = true
        }

        if(certTypes.includes('Neurocritical Care')) { 
            certTypeParams.NeurocriticalCare = true
        }

        if(certTypes.includes('Obstetric Anesthesia')) { 
            certTypeParams.ObstetricAnesthesia = true
        }

        if(certTypes.includes('Pain Medicine')) { 
            certTypeParams.PainMedicine = true
        }

        if(certTypes.includes('Pediatric Anesthesia')) { 
            certTypeParams.PediatricAnesthesia = true
        }

        if(certTypes.includes('Regional Anesthesia/Acute Pain')) { 
            certTypeParams.RegionalAnesthesiaAcutePain = true
        }

        if(certTypes.includes('Sleep Medicine')) { 
            certTypeParams.SleepMedicine = true
        }

        if(certTypes.includes('Thoracic Anesthesia')) { 
            certTypeParams.ThoracicAnesthesia = true
        }

        if(certTypes.includes('Trauma')) { 
            certTypeParams.Trauma = true
        }

        return certTypeParams
    },

    getCMEActivities: function () {
        const {searchbarText, certTypeFilters, activityFormatFilters, feeFilters, activityType} = CMEExplorerLogic.context.state
        let searchParams = this.getCertTypeFiltersForAPI(certTypeFilters)
        searchParams.searchString = searchbarText
        searchParams.Fee = null
        searchParams.ActivityFormat = !activityFormatFilters || activityFormatFilters.length < 1 ? null : activityFormatFilters.join()

        if(feeFilters.length > 0 && !feeFilters.includes(2) && feeFilters.length === 1) {
            searchParams.Fee = Number(feeFilters[0])
        }

        searchParams.ActivityType = !activityType || activityType == 'null' ? null : Number(activityType)

        return new Promise((resolve) => {
            let getParams = {
                params: searchParams
            }

            CMEExplorerLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cmeExplorer/getActivities', getParams)
                .then(function (response) {
                    //removing any results that don't have an ActivityUrl
                    let responseData = response.data.filter((rd) => {
                        return rd.ActivityUrl && rd.ActivityUrl.trim() != ''
                    })
                    resolve(responseData)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activities')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },

    getCMEActivitiesTier3: function (tier3value) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    contentOutlineId: tier3value,
                },
            }

            CMEExplorerLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cmeExplorer/getActivitiesTier3', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activities')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },

    getCMEActivitiesTier4: function (tier4_options) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    contentOutlineId: tier4_options,
                },
            }

            CMEExplorerLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cmeExplorer/getActivitiesTier4', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activities')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },

    getContentOutline: function () {
        return new Promise((resolve) => {
            CMEExplorerLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cmeExplorer/getContentOutline')
                .then(function (response) {
                    let tier1 = response.data
                        .filter((r) => r.Tier === 1)
                        .map((r) => {
                            return {label: r.Description, value: r.ID}
                        })
                    let tier2 = response.data.filter((r) => r.Tier === 2)
                    let tier3 = response.data.filter((r) => r.Tier === 3)
                    let tier4 = response.data.filter((r) => r.Tier === 4)
                    let finalResponse = {tier1, tier2, tier3, tier4}

                    resolve(finalResponse)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Content Outline')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },
    
    getCMECertificationTypes: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('cmeExplorer/getCertificationTypes')
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler.apply(error, 'Error Retrieving CME Certification Types')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },

    getCMEActivityFormats: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('cmeExplorer/getActivityFormats')
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler.apply(error, 'Error Retrieving CME Activity Formats')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    },

    getEventTypes: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('cmeExplorer/getEventTypes')
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler.apply(error, 'Error Retrieving CME Event Types')
                    resolve(null)
                })
                .finally(function () {
                    CMEExplorerLogic.context.setState({CallingApi: false})
                })
        })
    }
}
