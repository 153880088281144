import {GeneralEnums} from '../../helpers/generalEnums'

const ReferenceLogic = {
    context: null,

    DetermineReferenceType: function (refType) {
        const {
            Inpatient_Treating_Physicians,
            AfterCare_Monitoring,
            Outpatient_Treating_Physicians,
            Relapse_Inpatient,
            Relapse_Outpatient,
        } = GeneralEnums.cert_referenceType

        switch (refType) {
            case Inpatient_Treating_Physicians:
                return `Inpatient Reference`
            case Outpatient_Treating_Physicians:
                return `Outpatient Reference`
            case Relapse_Inpatient:
                return `Relapse Inpatient Reference`
            case Relapse_Outpatient:
                return `Relapse Outpatient Reference`
            case AfterCare_Monitoring:
                return `Treatment Reference`
        }
    },
}

export default ReferenceLogic
