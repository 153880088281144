import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Platform, Text, TouchableOpacity, View, PixelRatio} from 'react-native'
import WebView from 'react-native-webview'
import {ColorEnums} from '../../../helpers/generalEnums'

export default class ABAMMAnswerOptions extends Component {
    state = {
        selectedOptionIndex: null,
    }

    isElementSelected(option, index) {
        return option.isSelected === true || index === this.state.selectedOptionIndex
    }

    onPressOption(option, index) {
        this.props.onPress(option)
        this.setState({selectedOptionIndex: index})
    }

    render() {
        let headHtml =
            '<head>' +
            "   <meta name='viewport' content='width=device-width, initial-scale=1'>" +
            "   <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'>" +
            '   <style>' +
            '       body.mmOptSelected {' +
            '           background-color: #4A8BC9' +
            '       }' +
            '       body.mmOptUnselected {' +
            '          background-color: #F4F7F8' +
            '       }' +
            '       div.mmOptSelected > p {' +
            '           color: #ffffff;' +
            '           margin-bottom: 0;' +
            '       }' +
            '       div.mmOptUnselected > p {' +
            '           color: #4A8BC9;' +
            '           margin-bottom: 0;' +
            '       }' +
            '       p {' +
            "           font-family: 'Lato' !important;" +
            '       }' +
            '       p > span {' +
            "           font-family: 'Lato' !important;" +
            '       }' +
            '   </style>' +
            '</head>'

        const longestAnswerLength = Math.max(
            ...this.props.options.map((option) => option.AnswerText.length - 7),
            0,
        )

        const fontScale = PixelRatio.getFontScale()

        let minHeight = Math.max(50, 27 * Math.ceil(longestAnswerLength / 32))
        if (fontScale > 1) {
            minHeight = minHeight * fontScale + 15
        }
        return this.props.options.map((option, index) => {
            let className = this.isElementSelected(option, index) ? 'mmOptSelected' : 'mmOptUnselected'
            let optionHtml =
                headHtml +
                "<body class='" +
                className +
                "'><div class='" +
                className +
                "'>" +
                option.AnswerText +
                '</div></body>'
            return (
                <View key={option.AnswerId} style={{alignItems: 'center', width: this.props.width}}>
                    <View
                        style={{
                            flexDirection: 'row',
                            marginBottom: 10,
                            minHeight: 50,
                            width: this.props.width,
                        }}
                    >
                        <TouchableOpacity
                            activeOpacity={1}
                            disabled={option.disabled === true}
                            key={'label' + index}
                            onPress={() => {
                                this.onPressOption(option, index)
                            }}
                            style={Object.assign(
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    marginRight: 3,
                                    width: '10%',
                                },
                                this.isElementSelected(option, index)
                                    ? this.props.selectedButtonStyle
                                    : this.props.unselectedButtonStyle,
                            )}
                            testID={`ABAMMAnswerOption${option.DisplayLabel}`}
                        >
                            <Text
                                style={
                                    this.isElementSelected(option, index)
                                        ? this.props.selectedLabelTextStyle
                                        : this.props.unselectedLabelTextStyle
                                }
                            >
                                {option.DisplayLabel}
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            activeOpacity={1}
                            disabled={option.disabled === true}
                            onPress={() => {
                                this.onPressOption(option, index)
                            }}
                            style={Object.assign(
                                {
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    justifyContent: 'center',
                                    paddingLeft: Platform.OS === 'web' ? 5 : 0,
                                    width: '90%',
                                    padding: 5,
                                },
                                this.isElementSelected(option, index)
                                    ? this.props.selectedButtonStyle
                                    : this.props.unselectedButtonStyle,
                            )}
                        >
                            {Platform.OS === 'web' && (
                                <div
                                    className={
                                        this.isElementSelected(option, index)
                                            ? 'mmOptSelected'
                                            : 'mmOptUnselected'
                                    }
                                    key={'optionChild ' + index}
                                    dangerouslySetInnerHTML={{
                                        __html: option.AnswerText,
                                    }}
                                />
                            )}
                            {Platform.OS !== 'web' && (
                                <WebView
                                    key={index}
                                    scrollEnabled={false}
                                    source={{html: optionHtml}}
                                    style={{
                                        backgroundColor: 'transparent',
                                        minHeight: minHeight,
                                        width: '99%',
                                    }}
                                />
                            )}
                        </TouchableOpacity>
                    </View>
                </View>
            )
        })
    }
}

ABAMMAnswerOptions.propTypes = {
    onPress: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selectedButtonStyle: PropTypes.object,
    selectedLabelTextStyle: PropTypes.object,
    unselectedButtonStyle: PropTypes.object,
    unselectedLabelTextStyle: PropTypes.object,
    width: PropTypes.string,
}

ABAMMAnswerOptions.defaultProps = {
    selectedButtonStyle: {backgroundColor: ColorEnums.danube},
    selectedLabelTextStyle: {color: ColorEnums.white},
    unselectedButtonStyle: {backgroundColor: ColorEnums.backgroundGray},
    unselectedLabelTextStyle: {color: ColorEnums.danube},
    width: '100%',
}
