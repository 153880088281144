import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep3 from '../presentation/nonStandardExamStep3'
import NonStandardExamStep5 from '../presentation/nonStandardExamStep5'

const NonStandardExamLogicStep4 = {
    context: null,

    GetNSEData: function () {
        return new Promise((resolve) => {
            const {NonStandardExamID} = nonStandardExamContext.state

            let getParams = {
                params: {
                    NonStandardExamID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('nserequestsinfo/getNSEData', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing NSE Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateStateVariables: function (NSEData) {
        if (NSEData) {
            this.context.setState({
                exam_assistiveDevicesAvailable:
                    NSEData.exam_assistiveDevicesAvailable == null
                        ? null
                        : NSEData.exam_assistiveDevicesAvailable
                        ? true
                        : false,
                exam_currentlyUseAssistiveDevices:
                    NSEData.exam_currentlyUseAssistiveDevices == null
                        ? null
                        : NSEData.exam_currentlyUseAssistiveDevices
                        ? true
                        : false,
                exam_currentyUseAssistveDevicesDescription:
                    NSEData.exam_currentyUseAssistveDevicesDescription || '',
                exam_howLongAssistiveDevicesUsed: NSEData.exam_howLongAssistiveDevicesUsed || '',
                exam_symptomsAndLimitationsBefore: NSEData.exam_symptomsAndLimitationsBefore || '',
                exam_symptomsAndLimitationsAfter: NSEData.exam_symptomsAndLimitationsAfter || '',
                exam_symptomsAndLimitationsUnaffected:
                    NSEData.exam_symptomsAndLimitationsUnaffected || '',
                exam_unaffectedExplanationForMajorLifeActivity:
                    NSEData.exam_unaffectedExplanationForMajorLifeActivity || '',
                exam_mitigatingMeasuresOverTime:
                    NSEData.exam_mitigatingMeasuresOverTime == null
                        ? null
                        : NSEData.exam_mitigatingMeasuresOverTime
                        ? true
                        : false,
                exam_mitigatingMeasuresOverTimeDesc: NSEData.exam_mitigatingMeasuresOverTimeDesc || '',
                exam_changeMitigatingMeasures: NSEData.exam_changeMitigatingMeasures || '',
                exam_mitigatingMeasuresSideEffects: NSEData.exam_mitigatingMeasuresSideEffects || '',
                exam_explainMitigatingMeasuresEffectLimitation:
                    NSEData.exam_explainMitigatingMeasuresEffectLimitation || '',
                exam_mitigatingMeasuresForExam:
                    NSEData.exam_mitigatingMeasuresForExam == null
                        ? null
                        : NSEData.exam_mitigatingMeasuresForExam
                        ? true
                        : false,
                exam_mitigatingMeasuresForExamDesc: NSEData.exam_mitigatingMeasuresForExamDesc || '',
                exam_mitigatingMeasureAtEval:
                    NSEData.exam_mitigatingMeasureAtEval == null
                        ? null
                        : NSEData.exam_mitigatingMeasureAtEval
                        ? true
                        : false,
                exam_mitigatingMeasureAtEvalDesc: NSEData.exam_mitigatingMeasureAtEvalDesc || '',
            })
        }
    },

    DevicesAvailableNoSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_assistiveDevicesAvailable: false,
            exam_currentlyUseAssistiveDevices: null,
        })
    },

    DevicesAvailableYesSelected() {
        NonStandardExamLogicStep4.context.setState({exam_assistiveDevicesAvailable: true})
    },

    CurrentlyUsingDevicesNoSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_currentlyUseAssistiveDevices: false,
        })
    },

    CurrentlyUsingDevicesYesSelected() {
        NonStandardExamLogicStep4.context.setState({exam_currentlyUseAssistiveDevices: true})
    },

    MeasuresOverTimeNoSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_mitigatingMeasuresOverTime: false,
        })
    },

    MeasuresOverTimeYesSelected() {
        NonStandardExamLogicStep4.context.setState({exam_mitigatingMeasuresOverTime: true})
    },

    MeasuresForExamNoSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_mitigatingMeasuresForExam: false,
        })
    },

    MeasuresForExamYesSelected() {
        NonStandardExamLogicStep4.context.setState({exam_mitigatingMeasuresForExam: true})
    },

    MeasuresAtEvalNoSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_mitigatingMeasureAtEval: false,
        })
    },

    MeasuresAtEvalYesSelected() {
        NonStandardExamLogicStep4.context.setState({
            exam_mitigatingMeasureAtEval: true,
        })
    },

    UpdateNSEStep4: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {
                exam_assistiveDevicesAvailable,
                exam_currentlyUseAssistiveDevices,
                exam_currentyUseAssistveDevicesDescription,
                exam_howLongAssistiveDevicesUsed,
                exam_symptomsAndLimitationsBefore,
                exam_symptomsAndLimitationsAfter,
                exam_symptomsAndLimitationsUnaffected,
                exam_unaffectedExplanationForMajorLifeActivity,
                exam_mitigatingMeasuresOverTime,
                exam_mitigatingMeasuresOverTimeDesc,
                exam_changeMitigatingMeasures,
                exam_mitigatingMeasuresSideEffects,
                exam_explainMitigatingMeasuresEffectLimitation,
                exam_mitigatingMeasuresForExam,
                exam_mitigatingMeasuresForExamDesc,
                exam_mitigatingMeasureAtEval,
                exam_mitigatingMeasureAtEvalDesc,
            } = this.context.state
            const nseStep4Information = {
                NSEID: nonStandardExamContext.state.NonStandardExamID,
                exam_assistiveDevicesAvailable,
                exam_currentlyUseAssistiveDevices,
                exam_currentyUseAssistveDevicesDescription,
                exam_howLongAssistiveDevicesUsed,
                exam_symptomsAndLimitationsBefore,
                exam_symptomsAndLimitationsAfter,
                exam_symptomsAndLimitationsUnaffected,
                exam_unaffectedExplanationForMajorLifeActivity,
                exam_mitigatingMeasuresOverTime,
                exam_mitigatingMeasuresOverTimeDesc,
                exam_changeMitigatingMeasures,
                exam_mitigatingMeasuresSideEffects,
                exam_explainMitigatingMeasuresEffectLimitation,
                exam_mitigatingMeasuresForExam,
                exam_mitigatingMeasuresForExamDesc,
                exam_mitigatingMeasureAtEval,
                exam_mitigatingMeasureAtEvalDesc,
                rs_statuscode: nonStandardExamContext.state.RuleStatus,
            }
            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep4`, nseStep4Information)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }
            this.UpdateNSEStep4().then((response) => {
                if (response) {
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep5,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            nonStandardExamContext.setState({
                CurrentComponentStep: NonStandardExamStep3,
            })
            resolve(false)
        })
    },

    InputStateAccordianToggle(...InputStatus) {
        return (
            InputStatus.some((status) => this.context.state.InputStatus[status] === 3) ||
            this.context.props.isConfirmationPage
        )
    },

    ValidatePage: function () {
        const {
            exam_assistiveDevicesAvailable,
            exam_currentlyUseAssistiveDevices,
            exam_mitigatingMeasuresOverTime,
            exam_mitigatingMeasuresForExam,
            exam_mitigatingMeasureAtEval,
            InputStatus,
        } = this.context.state
        const {error, success} = GeneralEnums.textInputStatus
        var multiLineTextFields = [
            'exam_currentyUseAssistveDevicesDescription',
            'exam_howLongAssistiveDevicesUsed',
            'exam_symptomsAndLimitationsBefore',
            'exam_symptomsAndLimitationsAfter',
            'exam_symptomsAndLimitationsUnaffected',
            'exam_unaffectedExplanationForMajorLifeActivity',
            'exam_mitigatingMeasuresOverTimeDesc',
            'exam_changeMitigatingMeasures',
            'exam_mitigatingMeasuresSideEffects',
            'exam_explainMitigatingMeasuresEffectLimitation',
            'exam_mitigatingMeasuresForExamDesc',
            'exam_mitigatingMeasureAtEvalDesc',
        ]

        if (exam_assistiveDevicesAvailable === null) {
            InputStatus.exam_assistiveDevicesAvailable = error
        } else {
            InputStatus.exam_assistiveDevicesAvailable = success
        }
        if (exam_currentlyUseAssistiveDevices === null && exam_assistiveDevicesAvailable === true) {
            InputStatus.exam_currentlyUseAssistiveDevices = error
        } else {
            InputStatus.exam_currentlyUseAssistiveDevices = success
        }

        const checkMitigationMeasures =
            exam_assistiveDevicesAvailable === true && exam_currentlyUseAssistiveDevices === true

        if (checkMitigationMeasures) {
            if (exam_mitigatingMeasureAtEval == null) {
                InputStatus.exam_mitigatingMeasureAtEval = error
            } else {
                InputStatus.exam_mitigatingMeasureAtEval = success
            }
            if (exam_mitigatingMeasuresForExam == null) {
                InputStatus.exam_mitigatingMeasuresForExam = error
            } else {
                InputStatus.exam_mitigatingMeasuresForExam = success
            }
            if (exam_mitigatingMeasuresOverTime == null) {
                InputStatus.exam_mitigatingMeasuresOverTime = error
            } else {
                InputStatus.exam_mitigatingMeasuresOverTime = success
            }
            multiLineTextFields.forEach((field) => {
                switch (field) {
                    case 'exam_mitigatingMeasuresOverTimeDesc':
                        if (
                            this.context.state[field].length === 0 &&
                            exam_mitigatingMeasuresOverTime === true
                        ) {
                            InputStatus[field] = error
                        } else {
                            InputStatus[field] = success
                        }
                        break
                    case 'exam_mitigatingMeasuresForExamDesc':
                        if (
                            this.context.state[field].length === 0 &&
                            exam_mitigatingMeasuresForExam === true
                        ) {
                            InputStatus[field] = error
                        } else {
                            InputStatus[field] = success
                        }
                        break
                    case 'exam_mitigatingMeasureAtEvalDesc':
                        if (
                            this.context.state[field].length === 0 &&
                            exam_mitigatingMeasureAtEval !== null
                        ) {
                            InputStatus[field] = error
                        } else {
                            InputStatus[field] = success
                        }
                        break
                    default:
                        if (this.context.state[field].length === 0) {
                            InputStatus[field] = error
                        } else {
                            InputStatus[field] = success
                        }
                }
            })
        }
        var Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        if (!Validated)
            this.context.setState({
                InputStatus: InputStatus,
            })
        return Validated
    },
}

export default NonStandardExamLogicStep4
