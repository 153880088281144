import React from 'react'
import {Platform} from 'react-native'
import {ColorEnums} from '../../helpers/generalEnums'

export default function PrintedFooter() {
    if (Platform.OS !== 'web') {
        return null
    }

    return (
        <div
            style={{
                paddingTop: 30,
                paddingBottom: 10,
                marginLeft: 'auto',
                marginRight: 'auto',
                bottom: 0,
            }}
            className="printed-footer"
        >
            <div style={{fontFamily: 'Lato', textAlign: 'center', color: ColorEnums.cloudBurst}}>
                The American Board of Anesthesiology is a member of the American Board of Medical
                Specialties.
                <br />
                Copyright 2016© The American Board of Anesthesiology - All Rights Reserved.
            </div>
        </div>
    )
}
