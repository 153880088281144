import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'

const MocaMinuteConfRelLogic = {
    context: null,

    updateMocaMinuteConfidenceRelevance: function () {
        return new Promise((resolve) => {
            MocaMinuteConfRelLogic.context.setState({callingApi: true})
            ABAPortalAPI.post('mocaMinute/updateMocaMinuteConfidenceRelevance', {
                AskId: MocaMinuteConfRelLogic.context.props.NavData.AskId,
                Confidence: MocaMinuteConfRelLogic.context.state.confidenceSelection,
                Relevance: MocaMinuteConfRelLogic.context.state.relevanceSelection,
                RelevanceFeedback: MocaMinuteConfRelLogic.context.state.relevanceFeedback,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Confidence and Relevance')
                    MocaMinuteConfRelLogic.context.setState({callingApi: false})
                    resolve(null)
                })
        })
    },
}

export default MocaMinuteConfRelLogic
