import React, {useEffect, useRef, useState} from 'react'
import {AppState, Platform, StyleSheet, Text, View} from 'react-native'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {store} from '../../redux/configureStore'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import {toggleActivityReminder, setLogoutNoticeTime} from '../../redux/ActionCreators'
import loginLogic from '../../screens/logic/loginLogic'
import styleFunctions from '../../styles/styleFunctions'
import ABACountdownCircle from '../ABACustom/presentation/ABACountdownCircle'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'

const InctivityAlert = () => {
    const dispatch = useDispatch()
    const {userData, settings, timeoutSettings} = useSelector((state) => state)
    const [countdownCircle, setCountdownCircle] = useState(null)
    const appState = useRef(AppState.currentState)

    const enableResponseInterceptors = () => {
        const minuteTimeout = 15
        const duration = minuteTimeout * 60000

        const interceptor = ABAPortalAPI.interceptors.response.use((res) => {
            const {status} = res.request

            const activateInactivityTimer =
                status === 200 && settings.allowUpdateToken && settings.inactivityTimer

            if (activateInactivityTimer) {
                cancelTimer()

                dispatch(toggleActivityReminder(false))

                setTimeout(() => {
                    settings.allowUpdateToken = true
                    settings.inactivityTimer = true
                }, 5000)

                const newTimer = setTimeout(() => {
                    if (userData.hasBackDoorAccess || !settings.inactivityTimer) {
                        cancelTimer()
                        return res
                    } else {
                        dispatch(toggleActivityReminder(true))
                    }
                }, duration)
                dispatch(setLogoutNoticeTime(Date.now() + duration))
                settings.logoutTimer = newTimer
                allowUpdateToken = false
            }
            return res
        })
        return interceptor
    }

    const cancelTimer = () => {
        clearTimeout(settings.logoutTimer)
    }

    useEffect(() => {
        settings.inactivityTimer = true
        settings.allowUpdateToken = true
        if (!settings.interceptor) {
            settings.interceptor = enableResponseInterceptors()
        }
        if (Platform.OS === 'web') {
            return () => {
                ABAPortalAPI.interceptors.request.eject(settings.interceptor)
            }
        }
        const subscription = AppState.addEventListener('change', (nextAppState) => {
            const {settings, timeoutSettings, userData} = store.getState()
            if (
                appState.current.match(/inactive|background/) &&
                nextAppState === 'active' &&
                !userData.hasBackDoorAccess &&
                settings.inactivityTimer &&
                timeoutSettings.logoutTime
            ) {
                const currentTime = Date.now()
                if (currentTime > timeoutSettings.logoutTime) {
                    cancelTimer()
                    dispatch(loginLogic.logout(GeneralEnums.AlertTypes.SessiontTimeout))
                } else if (currentTime > timeoutSettings.logoutNoticeTime) {
                    cancelTimer()
                    dispatch(toggleActivityReminder(true))
                }
            }
            appState.current = nextAppState
        })
        return () => {
            subscription.remove()
            ABAPortalAPI.interceptors.response.eject(settings.interceptor)
        }
    }, [])

    if (!userData.inactivityAlert || useLocation().pathname.includes('/login')) {
        return <></>
    }
    return (
        <View style={styles.logoutTimerConfirmation}>
            <View style={[styles.popUpStyle, {marginLeft: 20, marginRight: 20, paddingBottom: 10}]}>
                <Text
                    style={[styleFunctions.containerTextBold('center'), {padding: 20, paddingBottom: 0}]}
                >
                    Your session is about to expire!
                </Text>
                <Text
                    style={[
                        styleFunctions.containerTextBold('center'),
                        {paddingLeft: 10, paddingRight: 10, paddingBottom: 20},
                    ]}
                >
                    You will be logged out in 60 seconds.
                </Text>
                <View style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 30}}>
                    <ABACountdownCircle
                        ref={(countdownCircle) => {
                            setCountdownCircle(countdownCircle)
                        }}
                        expirationEvent={() => {
                            dispatch(toggleActivityReminder(false))
                            dispatch(loginLogic.logout(GeneralEnums.AlertTypes.SessiontTimeout))
                        }}
                        caption={'LOGGING OUT IN'}
                        seconds={Math.max(
                            Math.ceil((timeoutSettings.logoutTime - Date.now()) / 1000),
                            1,
                        )}
                    />
                </View>
                <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                    <ABATouchableHighlight
                        isDisabled={false}
                        onPressEvent={() => dispatch(loginLogic.logout())}
                        text={'Logout'}
                    />
                    <View style={{paddingLeft: 10}} />
                    <ABATouchableHighlight
                        isDisabled={false}
                        onPressEvent={() => {
                            countdownCircle.stop()
                            dispatch(loginLogic.stayLoggedin())
                        }}
                        text={'Stay Connected!'}
                    />
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    logoutTimerConfirmation: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
    popUpStyle: {
        backgroundColor: ColorEnums.aquaHaze,
        shadowColor: '#dddddd',
        shadowOffset: {width: 4, height: 5},
        zIndex: 10,
        opacity: 1,
        borderWidth: 1,
        borderColor: ColorEnums.lightBlue,
        borderRadius: 10,
        width: '95%',
        maxWidth: 500,
        alignContent: 'center',
    },
})

export default InctivityAlert
