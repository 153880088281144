import React, {Component} from 'react'
import {Text, View} from 'react-native'
import {Link} from '../../router'
import styleFunctions from '../../styles/styleFunctions'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'

export default class LoginHeader extends Component {
    render() {
        return (
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingTop: 25,
                    paddingRight: 10,
                }}
            >
                <Link to="/" style={{marginRight: 35, textDecoration: 'none'}}>
                    <Text style={[styleFunctions.loginNavLinks()]}>Home</Text>
                </Link>
                <ABAHyperLink
                    label="ABA Website"
                    url="http://www.theaba.org"
                    style={[styleFunctions.loginNavLinks(), {marginRight: 35}]}
                />
                <ABAHyperLink
                    label="Contact Us"
                    url="https://theaba.org/contact.html"
                    style={[styleFunctions.loginNavLinks(), {marginRight: 35}]}
                />
            </View>
        )
    }
}
