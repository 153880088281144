import React from 'react'
import {Text, View, TouchableOpacity, StyleSheet, ActivityIndicator} from 'react-native'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import RegistrationLogic from '../logic/registrationLogic'
import stylefunctions from '../../styles/styleFunctions'
import ABABarChart from '../ABACustom/presentation/ABABarChart'

const styles = StyleSheet.create({
    filledbarbgcolor: {
        height: 15,
        textAlign: 'center',
        justifyContent: 'center',
    },

    emptybarbgcolor: {
        height: 15,
        textAlign: 'center',
        justifyContent: 'center',
    },

    chartoutercontainer: {
        height: 30,
        flexDirection: 'row',
        paddingBottom: 7,
    },

    progressPercent: {
        color: ColorEnums.danube,
        textAlign: 'left',
        fontSize: 12,
        width: 45,
        paddingRight: 5,
        paddingLeft: 3,
        justifyContent: 'center',
    },

    progressBarFill: {
        backgroundColor: ColorEnums.surfGreen,
    },

    progressBarEmpty: {
        backgroundColor: ColorEnums.aquaHaze,
    },
})

const columnWidthLeft = '25%'
const columnWidthRight = '30%'
const paddingThreshold = 500
const paddingScale = 0.15
const getChartRightMargin = () => {
    const {ComponentWidth} = RegistrationLogic.context.state
    return Math.max((ComponentWidth - paddingThreshold) * paddingScale, 0)
}

export default RegistrationRow = ({
    name = '',
    text = '',
    percentCompleted = 0,
    action = null,
    isHeader = false,
}) => {
    if (isHeader) {
        return (
            <View
                style={{
                    borderBottomWidth: 1,
                    borderBottomColor: ColorEnums.blueGray,
                    flexDirection: 'row',
                }}
            >
                <Text style={[stylefunctions.columnHeader(), {width: columnWidthLeft, minWidth: 100}]}>
                    Name
                </Text>
                <Text style={[stylefunctions.columnHeader(), {flex: 1}]}>Progress</Text>
                <Text style={[stylefunctions.columnHeader(), {width: columnWidthRight, minWidth: 90}]}>
                    Action
                </Text>
            </View>
        )
    }

    const filledBarWidth = `${percentCompleted * 100}%`
    const emptyBarWidth = `${100 - percentCompleted * 100}%`

    return (
        <View style={{flexDirection: 'row', paddingBottom: 5, paddingTop: 5}}>
            <Text
                style={[
                    stylefunctions.paragraphText(),
                    ,
                    {fontWeight: 'bold', width: columnWidthLeft, paddingRight: 2, minWidth: 100},
                ]}
            >
                {name}
            </Text>
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    paddingRight: getChartRightMargin(),
                    justifyContent: 'center',
                }}
            >
                <ABABarChart
                    outerbarstyle={[styles.chartoutercontainer, {flex: 1, maxWidth: 500}]}
                    filledbarstyle={[
                        styles.filledbarbgcolor,
                        styles.progressBarFill,
                        {width: filledBarWidth},
                    ]}
                    emptybarstyle={[
                        styles.emptybarbgcolor,
                        styles.progressBarEmpty,
                        {width: emptyBarWidth},
                    ]}
                />
                <Text style={styles.progressPercent}> {Math.round(percentCompleted * 100)}%</Text>
            </View>
            <View style={{width: columnWidthRight, minWidth: 90}}>
                <TouchableOpacity
                    style={[styles.chartoutercontainer, {width: '100%'}]}
                    textStyle={stylefunctions.actionText()}
                    onPress={action}
                    testID={`regRow${name}${text}`}
                >
                    <View style={{flexDirection: 'row', width: '100%'}}>
                        <Text style={stylefunctions.actionText()}>{text} </Text>
                        {text === 'Processing' && (
                            <ActivityIndicator color={ColorEnums.indigo} size="small" />
                        )}
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}
