import React, {Component} from 'react'
import {View, Text, Platform} from 'react-native'
import PropTypes from 'prop-types'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import NavigationHelper from '../../helpers/NavigationHelper'
import StyleFunctions from '../../styles/styleFunctions'
export default class CMEActivitiesLinks extends Component {
    render() {
        const selfReportLink = (
            <ABAHyperLink
                label="How to Self-Report CME"
                url="https://theaba.org/pdfs/MOCA_Report_CME_Instructions.pdf"
                style={[
                    StyleFunctions.actionText(),
                    {
                        textAlign: 'left',
                    },
                ]}
                icon="list-ul"
            />
        )

        return (
            <View
                style={{
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <View style={{width: '50%', alignItems: 'flex-start'}}>
                    <Text
                        style={{
                            color: ColorEnums.danube,
                            paddingRight: Platform.OS === 'web' ? 2 : 20,
                            flexDirection: 'row',
                            textAlign: 'left',
                        }}
                        onPress={() => {
                            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/reportNewCME')
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                            }}
                        >
                            {IconEnums['file-alt']}
                        </Text>{' '}
                        Report New CME
                    </Text>
                    <View
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: 10,
                            paddingRight: Platform.OS === 'web' ? 2 : 20,
                        }}
                    >
                        {selfReportLink}
                    </View>
                </View>
                <View
                    style={{borderLeftWidth: 2, borderLeftColor: ColorEnums.aquaHaze, marginRight: 9}}
                />
                <View
                    style={{
                        width: '50%',
                    }}
                >
                    <View
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        {Platform.OS === 'web' && (
                            <ABAHyperLink
                                label="Print My CME Listing"
                                url={`/cmeActivitiesTable/${this.props.SelectedYearForTable}`}
                                icon="print"
                                style={{marginBottom: 10, color: ColorEnums.danube}}
                                addAuth={true}
                            />
                        )}
                        <ABAHyperLink
                            label="ABA-Approved Patient Safety CME"
                            url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                            style={{color: ColorEnums.danube}}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

CMEActivitiesLinks.propTypes = {
    SelectedYearForTable: PropTypes.string,
}

CMEActivitiesLinks.defaultProps = {
    SelectedYearForTable: '1900',
}
