import React, {Component} from 'react'
import {Platform, Text, View} from 'react-native'
import WebView from 'react-native-webview'
import PropTypes from 'prop-types'
import {GeneralEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
export default class ABALineChart extends Component {
    constructor(props) {
        super(props)
        this.context = this
        this.iframe_ref = null
        this.writeHTML = this.writeHTML.bind(this)
    }
    state = {
        headers: [],
        dataSet: [],
        formattedDataSet: [],
        shouldRender: false,
        maxValue: 0,
        frameHeight: 450,
        width: 500,
        reRender: 0,
    }

    componentDidMount() {
        const legend = {
            textStyle: {fontSize: 14},
            ...this.props.legend,
            position: this.props.customLegend
                ? GeneralEnums.LegendPosition.None
                : this.props.legend.position,
        }
        this.setState({legend: legend})
        var headers = []
        this.props.children.forEach((child) => {
            switch (child.type) {
                case ABALineChartColumn:
                    headers.push({
                        text: `data.addColumn('${child.props.dataType}', '${child.props.children}')`,
                        arrayIndex: child.props.DataSetIndex,
                        ...child.props,
                    })
                    break
            }
        })
        this.setState({headers})
    }

    validateRender(formattedDataSet) {
        var validated = []
        if (formattedDataSet.length === 0) validated.push('Formatted Data Set is 0')
        return validated.length === 0
    }

    formatDataSet() {
        const {maxFillValue} = this.props
        if (this.props.dataset.length != this.props.maxRange) {
            if (maxFillValue.length != this.props.dataset[0].length) {
                maxFillValue.length = this.props.dataset[0].length
                maxFillValue.fill(null)
            }
            this.props.dataset.push([this.props.maxRange, ...maxFillValue])
        }
        var fullSet = this.props.dataset.map((row) => {
            if (this.props.vAxisTooltipLabel) {
                row[0] = `{v:${row[0]},f:'${this.props.vAxisTooltipLabel}: ${row[0]}'}`
            }
            return `[${[
                this.state.headers.map((header) =>
                    row[header.arrayIndex] === null ? 'null' : row[header.arrayIndex],
                ),
            ].join(',')}]`
        })
        return fullSet.join(',\n')
    }

    verifyMaxValue(propMax) {
        return this.props.maxValue < this.props.dataset.length ? this.props.dataset.length : propMax
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataset != this.state.dataSet) {
            var formattedDataSet = this.formatDataSet(this.props.dataset)
            var validated = this.validateRender(formattedDataSet)
            this.setState((prevState) => ({
                formattedDataSet: formattedDataSet,
                dataSet: this.props.dataset,
                shouldRender: validated,
                newMax: this.verifyMaxValue(this.props.maxValue),
                reRender: ++prevState.reRender,
            }))
        }
        if (prevProps.Width != this.state.width) {
            this.setState((prevState) => ({
                width: this.props.Width,
                reRender: ++prevState.reRender,
            }))
        }
    }

    getBlobURL = (code, type) => {
        const blob = new Blob([code], {type})
        return URL.createObjectURL(blob)
    }

    renderSeries() {
        var series = {}
        this.state.headers
            .filter((item, index) => index != 0)
            .forEach((item, index) => {
                series[index] = {
                    lineWidth: item.lineWidth,
                    color: item.color,
                    lineDashStyle: item.lineDashStyle,
                }
            })
        return series
    }

    renderTitles() {
        var titles = []
        this.state.headers
            .filter((item, index) => index != 0)
            .forEach((item) => {
                var str = HelperMethods.objToRenderableString({
                    title: item.children,
                    style: {
                        strokeWidth: item.lineWidth,
                        stroke: item.color,
                        strokeDasharray: item.lineDashStyle,
                    },
                })
                titles.push(str)
            })
        var data = titles
        return `[${data.join(',')}]`
    }
    getFontFamily() {
        var font = this.props.fontFamily
        return font[0].toUpperCase() + font.slice(1).toLowerCase()
    }
    renderOptions() {
        var options = {
            height: 400,
            width: '100%',
            series: this.renderSeries(),
            vAxis: this.props.vAxis,
            hAxis: this.props.hAxis,
            chartArea: {width: '70%', height: '84%'},
            legend: this.state.legend,
            lineWidth: this.props.lineWidth,
            colors: this.props.colors,
            curveType: this.props.curveType,
            focusTarget: 'category',
            ...this.props.options,
        }
        var str = `var options = ${HelperMethods.objToRenderableString(options)} `
        return str
    }

    formatMdt() {
        if (this.props.formatMdt) {
            return `
                var formatter = new google.visualization.NumberFormat({pattern: '0.00'});
                formatter.format(data, 3); // Apply formatter to MDT column
                formatter.format(data, 2); // Apply formatter to MDT column
                formatter.format(data, 1); // Apply formatter to MDT column
            `
        }
        return ''
    }

    htmlToRender() {
        var html = `<!DOCTYPE html>
      <head>
      <meta name="viewport" content="width=${this.state.width}, initial-scale=1">
      <link href="https://fonts.googleapis.com/css?family=${this.getFontFamily()}&display=swap" rel="stylesheet">
      <style>
      body {
        font-family: 'lato', sans-serif;
      }
      </style>
        
        <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
         <script type="text/javascript">
         var lineWidth = 40
         var x = 0
         var y = 0
         var lineNumber = 0;
         var count = 0
         var svgWidth = ${this.state.width - 8};
         google.charts.load('current', {'packages':['corechart', 'line']});
            google.charts.setOnLoadCallback(drawChart);
            function drawChart() {
            var data = new google.visualization.DataTable();
            ${this.state.headers.map((header) => header.text).join(';\n')}
            data.addRows([${this.state.formattedDataSet}]);
            ${this.renderOptions()}
            ${this.formatMdt()}
            var linearChart = new google.visualization.LineChart(document.getElementById('googleChart'));
            google.visualization.events.addListener(linearChart, 'ready', onGoogleChartsLoaded);
            linearChart.draw(data, google.charts.Line.convertOptions(options));
          }

          function createLegend(id) {
            var legend = document.getElementById(id)
            var titles = ${this.renderTitles()}
            titles.forEach(function(element) {
                var dims = getTextDimensions(element.title)     
                if(y<dims.height)
                y = dims.height           
                var newLine = document.createElementNS("http://www.w3.org/2000/svg", "line");
                if(x+dims.width+lineWidth>svgWidth){
                    y += dims.height
                    x=0
                    lineNumber++
                }
                attribs = {
                    x1:x,
                    x2:x + lineWidth,
                    y1:(y / 2) + ((lineNumber) * 10)+5,
                    y2:(y / 2) + ((lineNumber) * 10)+5
                }
                setAttributes(newLine,attribs)
                Object.keys(element.style).forEach( function(attrib) {newLine.style[attrib] = element.style[attrib]})
                x += lineWidth + 10
                count++
                legend.appendChild(newLine)
                var txt = document.createElementNS("http://www.w3.org/2000/svg", "text");
                txt.setAttribute("x", x)
                txt.setAttribute("y", y)
                txt.setAttribute("font-size","${this.state.legend.textStyle.fontSize}")
                x += dims.width
                var textNode = document.createTextNode(element.title);
                txt.appendChild(textNode)
                legend.appendChild(txt)
            });
            reRenderSVG(legend)
        }

        function setAttributes(element, attributes) {
            Object.keys(attributes).forEach(function(name){element.setAttribute(name, attributes[name])})
        }

        function reRenderSVG(svgElement) {
            var svgClone = svgElement.cloneNode(true)
            svgElement.style.paddingLeft = 0 //(svgWidth - x) / 2
            svgElement.setAttribute("width", svgWidth)
            svgElement.innerHTML = ''
            svgElement.innerHTML = svgClone.innerHTML
            svgElement.style.height = ((lineNumber+1)*25)
        }

        function onGoogleChartsLoaded(){
          var movePosition = "-25"
          var chart = document.getElementById('googleChart').getElementsByTagName('div')[0];
          chart.style.top = movePosition
        }

        function getTextDimensions(val) {
            var fontSize = ${this.state.legend.textStyle.fontSize};
            var newElement = document.createElement("div")
            newElement.style.position="absolute"
            newElement.style.visibility= "hidden";
            newElement.style.height= "auto";
            newElement.style.width= "auto";
            newElement.style.whiteSpace= "nowrap";
            newElement.innerText = val
            newElement.style.fontSize = fontSize +"px";
            document.body.appendChild(newElement)
            var height = (newElement.clientHeight + 1);
            var width = (newElement.clientWidth + 40)
            var dims = { height: height, width: width }
            return (dims)
        }
        </script>
      </head>
      <body>
        <div id="googleChart"></div>
        <div id="legendMain" style="position:relative;top:-15;left:-10">
        <svg id="legend" height="400" width="${
            this.state.width
        }" version="1.1" xmlns="http://www.w3.org/2000/svg">
        </svg>
        </div>
        <script>
        ${this.props.customLegend ? "createLegend('legend')" : ''}
        </script>
        </body>
      </html>`
        return html
    }

    writeHTML(frame) {
        if (!frame) {
            return
        }
        this.iframe_ref = frame
        let doc = frame.contentDocument
        doc.open()
        doc.write(this.htmlToRender())
        doc.close()
        frame.style.width = '100%'
    }

    setIframeHeight(height) {
        this.setState({frameHeight: height})
    }

    render() {
        if (this.state.shouldRender) {
            if (Platform.OS === 'web')
                return (
                    <View>
                        <iframe
                            style={{minHeight: 430}}
                            onLoad={() =>
                                this.setIframeHeight(this.iframe_ref.contentDocument.body.scrollHeight)
                            }
                            key={this.state.reRender}
                            ref={this.writeHTML}
                            frameBorder="0"
                            scrolling="no"
                            width={this.state.Width}
                            height={this.state.frameHeight}
                        />
                    </View>
                )
            return (
                <WebView
                    scrollEnabled={false}
                    javaScriptEnabled={true}
                    injectedJavaScript={webViewScript}
                    onMessage={(event) => this.setIframeHeight(parseInt(event.nativeEvent.data))}
                    source={{html: this.htmlToRender()}}
                    style={{
                        flex: 0,
                        backgroundColor: 'transparent',
                        height: this.state.frameHeight,
                        width: '100%',
                    }}
                />
            )
        }
        return (
            <View>
                <Text>Data Not Found</Text>
            </View>
        )
    }
}

const webViewScript = `
            setTimeout(function() { 
                window.postMessage(Math.max(
                    document.documentElement.clientHeight, 
                    document.documentElement.scrollHeight, 
                    document.body.clientHeight, 
                    document.body.scrollHeight
                )); 
            }, 500);
            true;
        `

ABALineChart.defaultProps = {
    maxValue: '0',
    maxFillValue: [],
    fontFamily: 'sans-serif',
}

ABALineChart.propTypes = {
    dataset: PropTypes.array.isRequired,
    legend: PropTypes.object,
    hAxis: PropTypes.object,
    vAxis: PropTypes.object,
    lineWidth: PropTypes.number,
    colors: PropTypes.array,
    children: PropTypes.array.isRequired,
    curveType: PropTypes.string,
    vAxisTooltipLabel: PropTypes.string,
    maxFillValue: PropTypes.array,
    maxRange: PropTypes.number,
    maxValue: PropTypes.string,
    Width: PropTypes.number,
    fontFamily: PropTypes.string,
    customLegend: PropTypes.bool,
    options: PropTypes.object,
}

export const ABALineChartColumn = () => {}

ABALineChartColumn.defaultProps = {
    dataType: 'number',
    lineWidth: 2,
    lineDashStyle: [],
}
