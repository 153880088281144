import React from 'react'
import {Text, View} from 'react-native'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import Acknowledgement from '../../components/registrationsteps/presentation/acknowledgement'
import CertificationInformation from '../../components/registrationsteps/presentation/certificateinformation'
import ClinicalActivity from '../../components/registrationsteps/presentation/clinicalActivity'
import CMECreditRelease from '../../components/registrationsteps/presentation/cmeCreditRelease'
import CMERelease from '../../components/registrationsteps/presentation/cmeReleaseForm'
import Confidentiality from '../../components/registrationsteps/presentation/confidentialityPolicy'
import GeneralIPR from '../../components/registrationsteps/presentation/generalIPR'
import MedLicenseRelease from '../../components/registrationsteps/presentation/medicalLicenseRelease'
import MOCAAnnualPayment from '../../components/registrationsteps/presentation/mocaAnnualPayment'
import MOCACopyRight from '../../components/registrationsteps/presentation/mocaCopyrightRelease'
import NSE from '../../components/registrationsteps/presentation/nonStandardExam'
import PaymentandRegistration from '../../components/registrationsteps/presentation/paymentAndRegistration'
import PracticeArea from '../../components/registrationsteps/presentation/practiceAreaAndSetting'
import Release from '../../components/registrationsteps/presentation/release'
import SubstanceAbuse from '../../components/registrationsteps/presentation/substanceAbuse'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'

const componentsListing = {
    'MOCA Independent Practice Requirement': GeneralIPR,
    Acknowledgement: Acknowledgement,
    'General Independent Practice Requirement': GeneralIPR,
    'Medical License Release': MedLicenseRelease,
    'MOCA Acknowledgement and Release Form': Acknowledgement,
    'CME Release Form': CMERelease,
    'Practice Area & Setting': PracticeArea,
    'MOCA Copyright Release': MOCACopyRight,
    Release: Release,
    'Confidentiality Policy': Confidentiality,
    'Clinical Activity': ClinicalActivity,
    'Prerequisite Check': PaymentandRegistration,
    'Substance Abuse': SubstanceAbuse,
    'MOCA Annual Payment': MOCAAnnualPayment,
    'Certificate Information': CertificationInformation,
    'Payment and Registration': PaymentandRegistration,
    NSE: NSE,
    'CME Credit Release': CMECreditRelease,
}

const registrationStepsDisplayLogic = {
    context: null,

    SetContinueButtonVisibility: function (ShowContinue) {
        registrationStepsDisplayLogic.context.setState({ShowContinue})
    },

    SetPreviousButtonVisibility: function (ShowPrevious) {
        registrationStepsDisplayLogic.context.setState({ShowPrevious})
    },

    GetRegistrationStepsDisplayData: function (Order, StepDirection, rulesetStatusId) {
        return new Promise((resolve) => {
            const CR2ID =
                registrationStepsDisplayLogic.context.navigationData.re_rulesetstatusId ||
                registrationStepsDisplayLogic.context.state.annualCr2 ||
                rulesetStatusId
            registrationStepsDisplayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRegistrationStepsDisplay', {
                Order,
                StepDirection,
                CR2ID,
                StatusCode: registrationStepsDisplayLogic.context.navigationData.StatusCode,
            })
                .then(function (response) {
                    registrationStepsDisplayLogic.context.setState({
                        CallingApi: false,
                        ShowPrevious: response.data.SubmittedSteps > 1,
                    })
                    resolve(response.data)
                })
                .catch(function (error) {
                    registrationStepsDisplayLogic.context.setState({error: true})
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Registration Information')
                })
                .finally(function () {
                    registrationStepsDisplayLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetPreviousStep: function () {
        if (registrationStepsDisplayLogic.context.child.state.CheckPrevious) {
            registrationStepsDisplayLogic.context.child.Previous().then((success) => {
                if (success) {
                    registrationStepsDisplayLogic.GotoPrevious()
                }
            })
        } else {
            registrationStepsDisplayLogic.GotoPrevious()
        }
    },

    GotoPrevious: function () {
        const cr2Id = registrationStepsDisplayLogic.context.state.annualCr2
        registrationStepsDisplayLogic
            .GetRegistrationStepsDisplayData(
                registrationStepsDisplayLogic.context.state.registrationStepsData.re_Order,
                1,
                cr2Id,
            )
            .then((responseData) => {
                registrationStepsDisplayLogic.context.setState({registrationStepsData: responseData})
            })
    },

    GetNextStep: function () {
        registrationStepsDisplayLogic.context.setState({
            disableButton: true,
        })

        registrationStepsDisplayLogic.context.child
            .Save()
            .then((success) => {
                if (success) {
                    const cr2Id = registrationStepsDisplayLogic.context.state.annualCr2
                    registrationStepsDisplayLogic
                        .GetRegistrationStepsDisplayData(
                            registrationStepsDisplayLogic.context.state.registrationStepsData.re_Order,
                            2,
                            cr2Id,
                        )
                        .then((responseData) => {
                            registrationStepsDisplayLogic.context.setState({
                                registrationStepsData: responseData,
                            })
                        })
                }
            })
            .finally(() => {
                registrationStepsDisplayLogic.context.setState({disableButton: false})
            })
    },

    GetRegistrationStepsView: function (registrationStepsData) {
        if (registrationStepsData != null) {
            let MyComp = componentsListing[registrationStepsData.ActivityTypeName]
            const {annualCr2, ContinueText, disableButton} = registrationStepsDisplayLogic.context.state
            const CR2ID =
                registrationStepsDisplayLogic.context.navigationData.re_rulesetstatusId || annualCr2
            const isRecertification = registrationStepsData.re_RulegroupIdName
                .toLowerCase()
                .includes('recertification')
            return (
                <View style={{backgroundColor: ColorEnums.backgroundGray, flex: 1}}>
                    <View style={{width: '95%', alignSelf: 'center'}}>
                        <Text
                            style={{
                                textAlign: 'center',
                                alignSelf: 'center',
                                color: ColorEnums.danube,
                                fontSize: 24,
                                fontFamily: 'Lato',
                                paddingTop: 25,
                                paddingBottom: 25,
                            }}
                        >
                            Registration for {registrationStepsData.re_RulegroupIdName}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: '95%',
                            alignSelf: 'center',
                            backgroundColor: ColorEnums.white,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: ColorEnums.indigo,
                                flexDirection: 'row',
                            }}
                        >
                            <Text
                                style={{
                                    color: ColorEnums.white,
                                    width: '70%',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    padding: 5,
                                    paddingLeft: 10,
                                    fontFamily: 'Lato',
                                }}
                            >
                                {registrationStepsData.re_name.toUpperCase()}
                            </Text>
                            {MyComp != CertificationInformation && (
                                <Text
                                    style={{
                                        color: ColorEnums.white,
                                        width: '30%',
                                        fontSize: 15,
                                        textAlign: 'right',
                                        padding: 5,
                                        paddingRight: 10,
                                        fontFamily: 'Lato',
                                    }}
                                >
                                    STEP {registrationStepsData.SubmittedSteps} OF{' '}
                                    {registrationStepsData.TotalSteps}
                                </Text>
                            )}
                        </View>
                        <View
                            style={{width: '95%', alignSelf: 'center', maxWidth: 700}}
                            onLayout={(event) =>
                                HelperMethods.setComponentWidth(
                                    registrationStepsDisplayLogic.context,
                                    event,
                                )
                            }
                        >
                            {
                                <MyComp
                                    ref={(child) => {
                                        registrationStepsDisplayLogic.context.child = child
                                    }}
                                    ProgramType={registrationStepsData.re_ProgramType}
                                    Order={registrationStepsData.re_Order}
                                    CR2ID={CR2ID}
                                    SubmittedSteps={registrationStepsData.SubmittedSteps}
                                    DoNotRenderNav={true}
                                    RegistrationContext={registrationStepsDisplayLogic}
                                    SetContinueVisibility={
                                        registrationStepsDisplayLogic.SetContinueButtonVisibility
                                    }
                                    SetPreviousVisibility={
                                        registrationStepsDisplayLogic.SetPreviousButtonVisibility
                                    }
                                    IsRecertification={isRecertification}
                                    NavigationData={registrationStepsDisplayLogic.context.navigationData}
                                    ContainerTitle={registrationStepsData.re_name.toUpperCase()}
                                />
                            }
                            <Text style={{color: ColorEnums.pomegranate, paddingTop: 10}}>
                                * Required field
                            </Text>
                            <View
                                style={{
                                    width: '90%',
                                    alignSelf: 'center',
                                    paddingTop: 5,
                                    paddingBottom: 20,
                                }}
                            >
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{width: '50%'}}>
                                        {registrationStepsDisplayLogic.context.state.ShowPrevious && (
                                            <ABATouchableHighlight
                                                buttonStyle={styleFunctions.buttonClickInverted()}
                                                onPressEvent={
                                                    registrationStepsDisplayLogic.GetPreviousStep
                                                }
                                                textStyle={{
                                                    color: ColorEnums.danube,
                                                    fontFamily: 'Lato',
                                                    fontSize: 16,
                                                }}
                                                text={'Previous'}
                                                isDisabled={disableButton}
                                            />
                                        )}
                                    </View>
                                    <View style={{width: '50%'}}>
                                        {registrationStepsDisplayLogic.context.state.ShowContinue && (
                                            <ABATouchableHighlight
                                                icon={'angle-right'}
                                                onPressEvent={registrationStepsDisplayLogic.GetNextStep}
                                                text={ContinueText || 'Continue'}
                                                isDisabled={disableButton}
                                            />
                                        )}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            )
        }
    },

    UpdateNTLCertificates() {
        return new Promise((resolve) => {
            registrationStepsDisplayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`registration/updateNTLCertificates`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    registrationStepsDisplayLogic.context.setState({error: true})
                    HelperMethods.apiErrorHandler(error, 'Error Updating NTL Certificates')
                })
        })
    },

    Create10YearMocaCR2(ntlCertificate) {
        return new Promise((resolve) => {
            registrationStepsDisplayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/create10YearMocaCR2', ntlCertificate)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    registrationStepsDisplayLogic.context.setState({error: true})
                    HelperMethods.apiErrorHandler(error, 'Error Creating 10 Year Moca CR2s')
                })
        })
    },
}

export default registrationStepsDisplayLogic
