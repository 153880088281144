import React, { Component } from "react";
import ABAContainer from "../ABACustom/presentation/ABAContainer";
import AlertsLogic from "../logic/alertsLogic";
import Alert from "./alert";

export default class Alerts extends Component {

    render() {
        AlertsLogic.context = this;
        const {Alerts} = this.props;
        return (
            <ABAContainer
                activityText={"Loading Alerts"}
                showContainerTitleBar={false}
                useMaxWidth={false}
            >
                {Alerts.map((alert, index) => {
                    var {alertText, iconColor, route, navigationData} = alert;
                    return (
                        <Alert
                            FirstAlert={index === 0}
                            LastAlert={index === Alerts.length - 1}
                            AlertText={alertText}
                            IconColor={iconColor}
                            Route={route}
                            NavigationData={navigationData}
                            key={`Alert${index}`}
                        />
                    );
                })}
            </ABAContainer>
        );
    }
}