import React, {Component} from 'react'
import {Text, StyleSheet, View} from 'react-native'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import Header from '../../components/presentation/header'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import PrintedFooter from '../../components/presentation/printedFooter'
import CMEActivitiesTableLogic from '../logic/cmeActivitiesTableLogic'

export default class CMEActivitiesTable extends Component {
    constructor(props) {
        super(props)

        const currentTime = new Date()
        this.currentYear = currentTime.getFullYear()

        this.state = {
            selectedYear: this.currentYear.toString(),
            Category1CME: [],
            OtherDocumentedActivities: [],
            OtherUndocumentedActivities: [],
            Category1CreditsRequested: 0,
            Category1CreditsGranted: 0,
            OtherDocumentedCreditsRequested: 0,
            OtherDocumentedCreditsGranted: 0,
            OtherUndocumentedCreditsRequested: 0,
            OtherUndocumentedCreditsGranted: 0,
        }

        this.styles = StyleSheet.create({
            bold: {
                fontWeight: 'bold',
            },
            accordionTitleContainer: {
                backgroundColor: ColorEnums.indigo,
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: 3,
                paddingBottom: 3,
                marginTop: 20,
            },
            accordionTitle: {
                color: 'white',
                fontSize: 15,
                fontWeight: 'bold',
            },
            accordionIcon: {
                color: 'white',
                fontSize: 12,
                paddingTop: 5,
                paddingRight: 5,
                paddingLeft: 5,
            },
        })
    }

    componentDidMount() {
        CMEActivitiesTableLogic.getCMEActivities().then((responsedata) => {
            this.setState({CMEdata: responsedata})
            this.GetCMEByCategory(responsedata, this.props.match.params.selectedYear)
        })
    }

    GetCMEByCategory(cmeActivities, selectedYear) {
        let YearDateCompleted = this.currentYear
        const cmeActivityList = {
            Category1CME: [],
            OtherDocumentedActivities: [],
            OtherUndocumentedActivities: [],
        }

        if (typeof selectedYear === 'undefined') {
            selectedYear = this.currentYear
        }

        cmeActivities.forEach((cmeActivity) => {
            YearDateCompleted = cmeActivity.DateCompleted.substring(6, 11)
            if (selectedYear === 'lastyear') {
                if (YearDateCompleted === (this.currentYear - 1).toString()) {
                    this.addActivityToCategory(cmeActivity, cmeActivityList)
                }
            }
            if (YearDateCompleted >= selectedYear) {
                this.addActivityToCategory(cmeActivity, cmeActivityList)
            }
            if (selectedYear === 'yes') {
                if (cmeActivity.PatientSafety.toLowerCase() === 'yes') {
                    this.addActivityToCategory(cmeActivity, cmeActivityList)
                }
            }
        })

        const {Category1CME, OtherDocumentedActivities, OtherUndocumentedActivities} = cmeActivityList

        const Category1CreditsRequested = this.getTotalCreditsRequested(Category1CME)
        const Category1CreditsGranted = this.getTotalCreditsGranted(Category1CME)
        const OtherDocumentedCreditsRequested = this.getTotalCreditsRequested(OtherDocumentedActivities)
        const OtherDocumentedCreditsGranted = this.getTotalCreditsGranted(OtherDocumentedActivities)
        const OtherUndocumentedCreditsRequested = this.getTotalCreditsRequested(
            OtherUndocumentedActivities,
        )
        const OtherUndocumentedCreditsGranted = this.getTotalCreditsGranted(OtherUndocumentedActivities)

        this.setState({
            Category1CME,
            OtherDocumentedActivities,
            OtherUndocumentedActivities,
            Category1CreditsRequested,
            Category1CreditsGranted,
            OtherDocumentedCreditsRequested,
            OtherDocumentedCreditsGranted,
            OtherUndocumentedCreditsRequested,
            OtherUndocumentedCreditsGranted,
        })
    }

    addActivityToCategory(cmeActivity, cmeActivityList) {
        const {Category1CME, OtherDocumentedActivities, OtherUndocumentedActivities} = cmeActivityList

        switch (cmeActivity.Category) {
            case 1:
                Category1CME.push(cmeActivity)
                break
            case 2:
                OtherDocumentedActivities.push(cmeActivity)
                break
            case 3:
                OtherUndocumentedActivities.push(cmeActivity)
                break
            default:
                null
        }
    }

    getTotalCreditsRequested(cmeArray) {
        if (cmeArray.length < 1) {
            return 0
        }

        let sum = cmeArray.reduce((a, b) => ({
            CreditsRequested: a.CreditsRequested + b.CreditsRequested,
        }))
        return sum.CreditsRequested
    }

    getTotalCreditsGranted(cmeArray) {
        if (cmeArray.length < 1) {
            return 0
        }
        let sum = cmeArray.reduce((a, b) => ({CreditsGranted: a.CreditsGranted + b.CreditsGranted}))
        return sum.CreditsGranted
    }

    renderTable(tableData, totalCreditsRequested, totalCreditsGranted) {
        if (tableData.length < 1) {
            return (
                <div
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: 'white',
                        width: '95%',
                        fontFamily: 'lato',
                        color: ColorEnums.cloudBurst,
                        textAlign: 'center',
                    }}
                >
                    No CME data to display
                </div>
            )
        }
        return (
            <div
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: 'white',
                    width: '95%',
                }}
            >
                <div style={{paddingTop: '5px', paddingBottom: '5px'}}>
                    <div className="cmeTableCategory" style={{width: '45%'}}>
                        Activity
                    </div>
                    <div className="cmeTableCategory" style={{width: '16%'}}>
                        Credits
                    </div>
                    <div className="cmeTableCategory" style={{width: '10%'}} />
                    <div className="cmeTableCategory" style={{width: '29%'}}>
                        ABA Review
                    </div>
                </div>
                <table
                    style={{
                        width: '100%',
                        fontFamily: 'lato',
                        fontSize: 14,
                    }}
                >
                    <thead>
                        <tr className="cmeTableRow">
                            <th style={{width: '11%'}}>Completed</th>
                            <th style={{width: '14%'}}>Sponsor</th>
                            <th style={{width: '20%'}}>Activity Description</th>
                            <th style={{width: '8%'}}>Requested</th>
                            <th style={{width: '8%'}}>Creditable</th>
                            <th style={{width: '10%'}}>Patient Safety</th>
                            <th style={{width: '8%'}}>Status</th>
                            <th style={{width: '11%'}}>Date</th>
                            <th style={{width: '10%'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data) => {
                            return (
                                <tr className="cmeTableRow">
                                    <td style={{textAlign: 'center'}}>{data.DateCompleted}</td>
                                    <td>{data.Provider}</td>
                                    <td>{data.SessionDescription}</td>
                                    <td style={{textAlign: 'center'}}>{data.CreditsRequested}</td>
                                    <td style={{textAlign: 'center'}}>{data.CreditsGranted}</td>
                                    <td style={{textAlign: 'center'}}>{data.PatientSafety}</td>
                                    <td>{data.AuditStatus}</td>
                                    <td style={{textAlign: 'center'}}>{data.AuditDate}</td>
                                    <td style={{textAlign: 'center'}}>{data.AuditResult}</td>
                                </tr>
                            )
                        })}
                        <tr
                            style={{
                                fontWeight: 'bold',
                                fontSize: 18,
                                fontFamily: 'lato',
                                color: ColorEnums.cloudBurst,
                            }}
                        >
                            <td />
                            <td />
                            <td style={{textAlign: 'right', paddingTop: 15, paddingBottom: 15}}>
                                Total Credits
                            </td>
                            <td style={{textAlign: 'center', paddingTop: 15, paddingBottom: 15}}>
                                {totalCreditsRequested.toFixed(2)}
                            </td>
                            <td style={{textAlign: 'center', paddingTop: 15, paddingBottom: 15}}>
                                {totalCreditsGranted.toFixed(2)}
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        CMEActivitiesTableLogic.context = this
        return (
            <div
                style={{
                    backgroundColor: ColorEnums.backgroundGray,
                    height: '100%',
                    minWidth: 800,
                }}
            >
                <Header showNav={true} print={true} shellContext={this} hideBellIcon={true} />
                <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                <ABAContainer containerTitle="CME Activities Listing">
                    <Text
                        style={{
                            fontFamily: 'lato',
                            fontSize: 14,
                        }}
                    >
                        <Text style={this.styles.bold}>Beginning in 2013</Text>, a maximum of
                        <Text style={this.styles.bold}> 60</Text> CME credits will be accepted per
                        calendar year.
                    </Text>
                    <View style={{paddingTop: 20, paddingBottom: 20}}>
                        <Text style={{paddingRight: 3}}>
                            If you complete an activity through one of our
                            <ABAHyperLink
                                url="https://theaba.org/pdfs/MOCA_CME_Providers.pdf"
                                label=" registered CME providers"
                            />
                            , please give them your ABA ID number and they will report your activities to
                            us on your behalf. This includes
                            <ABAHyperLink
                                url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                                label=" ABA-approved Patient Safety CME"
                            />
                            . These activities have the provider’s name in{' '}
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    color: ColorEnums.surfGreen,
                                }}
                            >
                                green
                            </Text>{' '}
                            font in your below activity listing.
                        </Text>
                    </View>
                    <View style={{paddingBottom: 15}}>
                        <Text>
                            Self-reported CME activities are subject to audit for a period of three years
                            from the date you report them. Please retain your CME documentation for your
                            self-reported activities. If you are scheduled for an audit, we will contact
                            you about the activities for which you must submit documentation. Do not mail
                            or fax documentation to us unless it's requested.
                        </Text>
                    </View>
                </ABAContainer>
                <ABAAccordion
                    title="Category 1 Activities"
                    accordionExpanded={this.state.Category1CME.length > 0 ? true : false}
                    accordionTitleContainerStyle={this.styles.accordionTitleContainer}
                    accordionTitleStyle={this.styles.accordionTitle}
                    accordionIconStyle={this.styles.accordionIcon}
                    accordionContent={this.renderTable(
                        this.state.Category1CME,
                        this.state.Category1CreditsRequested,
                        this.state.Category1CreditsGranted,
                    )}
                />

                <ABAAccordion
                    title="Other: Documented Activities"
                    accordionExpanded={this.state.OtherDocumentedActivities.length > 0 ? true : false}
                    accordionTitleContainerStyle={this.styles.accordionTitleContainer}
                    accordionTitleStyle={this.styles.accordionTitle}
                    accordionIconStyle={this.styles.accordionIcon}
                    accordionContent={this.renderTable(
                        this.state.OtherDocumentedActivities,
                        this.state.OtherDocumentedCreditsRequested,
                        this.state.OtherDocumentedCreditsGranted,
                    )}
                />

                <ABAAccordion
                    title="Other: Undocumented Activities"
                    accordionExpanded={this.state.OtherUndocumentedActivities.length > 0 ? true : false}
                    accordionTitleContainerStyle={this.styles.accordionTitleContainer}
                    accordionTitleStyle={this.styles.accordionTitle}
                    accordionIconStyle={this.styles.accordionIcon}
                    accordionContent={this.renderTable(
                        this.state.OtherUndocumentedActivities,
                        this.state.OtherUndocumentedCreditsRequested,
                        this.state.OtherUndocumentedCreditsGranted,
                    )}
                />
                <PrintedFooter />
            </div>
        )
    }
}
