import React, {Component} from "react";
import {View, Text} from "react-native";
import NavigationShell from "../../components/presentation/navigationShell";
import HelperMethods from "../../helpers/HelperMethods";
import styleFunctions from "../../styles/styleFunctions";
import ViewCompletedSubstanceAbuseFormsLogic from "../logic/viewCompletedSubstanceAbuseFormsLogic";
import ABAHeader from "../../components/ABACustom/presentation/ABAHeader";
import {ColorEnums, GeneralEnums, IconEnums} from "../../helpers/generalEnums";
import ABAContainer from "../../components/ABACustom/presentation/ABAContainer";
import ABAKeyboardAwareScrollView from "../../components/ABACustom/presentation/ABAKeyboardAwareScrollView";

export default class ViewCompletedSubstanceAbuseForms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            SUDdata: [],
            CallingApi: false
        }
    }
    componentDidMount() {
        ViewCompletedSubstanceAbuseFormsLogic.getCompletedSUDForms().then(responsedata => {
            ViewCompletedSubstanceAbuseFormsLogic.context.setState({SUDdata: responsedata});
        });
    }

    render() {
        viewCompletedSubstanceAbuseFormsContext = this;
        navigationContext = this;
        ViewCompletedSubstanceAbuseFormsLogic.context = this;
        HelperMethods.setNavigationData(viewCompletedSubstanceAbuseFormsContext);
        return (
            <NavigationShell context={viewCompletedSubstanceAbuseFormsContext}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader
                        group={GeneralEnums.ABAHeaderGroups.ExamInformation}
                    />
                    <ABAContainer
                        activityText={"Loading Completed Substance Abuse Forms"}
                        containerTitle={"COMPLETED SUBSTANCE ABUSE FORMS"}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View style={{width: "100%", flexDirection: "row", alignSelf: "center", paddingBottom: 10, paddingTop: 30, borderBottomWidth: 2, borderBottomColor: ColorEnums.lightGray}}>
                            <Text style={[styleFunctions.columnHeader(), {width: "25%", fontFamily: "Lato", fontSize: 14}]}>Date</Text>
                            <Text style={[styleFunctions.columnHeader(), {width: "50%", fontFamily: "Lato", fontSize: 14}]}>Description</Text>
                            <Text style={[styleFunctions.columnHeader(), {width: "20%", fontFamily: "Lato", fontSize: 14}]}>View PDF</Text>
                        </View>
                        {viewCompletedSubstanceAbuseFormsContext.state.SUDdata.map((item, index) => {
                            return (
                                <View key={index} style={{width: "100%", flexDirection: "row", alignSelf: "center", paddingBottom: 10, paddingTop: 10, borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray}}>
                                    <Text style={[styleFunctions.paragraphText(), {width: "25%", fontSize: 14}]}>{item.DateSigned}</Text>
                                    <Text style={[styleFunctions.paragraphText(), {width: "50%", fontSize: 14}]}>{item.ExamName}</Text>
                                    {
                                        !item.PDFName &&
                                        <Text style={[styleFunctions.columnHeader(), {width: "20%", fontFamily: "Lato", fontSize: 14}]}>
                                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>{IconEnums["file-alt"]}</Text> Not Available
                                        </Text>
                                    }
                                    {
                                        item.PDFName &&
                                        <Text style={[styleFunctions.actionText(), {width: "25%", fontFamily: "Lato", fontSize: 14}]}
                                            onPress={() => ViewCompletedSubstanceAbuseFormsLogic.openPDF(item.PDFName, item.SAID)}>
                                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>{IconEnums["file-alt"]}</Text> Download
                                        </Text>
                                    }
                                </View>
                            )
                        })}
                        <View style={{borderTopWidth: 2, borderTopColor: ColorEnums.cloudBurst, width: "100%", alignSelf: "center", maxWidth: 700}}></View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}