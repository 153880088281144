import React, {Component} from 'react'
import {ActivityIndicator, Platform, Text, View} from 'react-native'

import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABALogo from '../../components/ABACustom/presentation/ABALogo'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ForgotPasswordLogic from '../logic/forgotPasswordLogic'
import HelperMethods from '../../helpers/HelperMethods'
import LoginFooter from '../../components/presentation/loginFooter'
import LoginHeader from '../../components/presentation/loginHeader'
import LoginReturnLink from '../../components/presentation/loginReturnLink'
import styleFunctions from '../../styles/styleFunctions'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'

export default class ForgotPasswordValidation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            abaId: null,
            CallingApi: false,
            dateOfBirth: null,
            lastName: null,
            ssnLastFour: null,
            inputStatusAbaId: GeneralEnums.textInputStatus.default,
            inputStatusSsn: GeneralEnums.textInputStatus.default,
            inputStatusDateOfBirth: GeneralEnums.textInputStatus.default,
            inputStatusLastName: GeneralEnums.textInputStatus.default,
            errMsgAbaId: 'Invalid ABA ID format',
            errMsgSsn: 'Last 4 of your social security number is required',
            errMsgDateOfBirth: 'Invalid date of birth format',
        }
    }

    render() {
        ForgotPasswordLogic.context = this

        HelperMethods.setNavigationData(this)

        return (
            <ABAKeyboardAwareScrollView
                contentContainerStyle={[
                    {flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between'},
                    styleFunctions.loginBackground(),
                ]}
            >
                <View>
                    {Platform.OS === 'web' && <LoginHeader />}
                    <ABALogo />

                    <View style={styleFunctions.loginContainer()}>
                        <Text style={styleFunctions.loginHeader()}>Forgot Password</Text>
                        {this.props.location.state?.error && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    marginTop: 20,
                                    marginBottom: 20,
                                    padding: 5,
                                    backgroundColor: ColorEnums.pomegranateLight,
                                    borderColor: ColorEnums.pomegranate,
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    width: '85%',
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        color: ColorEnums.pomegranateDark,
                                        alignSelf: 'stretch',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    Your session has timed-out and your information was not saved. Please
                                    complete the fields below to change your password.
                                </Text>
                            </View>
                        )}
                        <Text
                            style={[
                                styleFunctions.containerText('center'),
                                {fontSize: 14, paddingLeft: 40, paddingRight: 40, paddingBottom: 20},
                            ]}
                        >
                            To reset your password, please enter the last four digits of your social
                            security number and complete two additional fields.
                        </Text>

                        <ABATextInputMask
                            errorMessage={this.state.errMsgSsn}
                            keyboardType="number-pad"
                            maxLength={4}
                            onChangeText={(text) => {
                                this.setState({ssnLastFour: text})
                            }}
                            onSubmitEditing={() => {
                                this.abaIdInput.focus()
                            }}
                            options={{mask: '9999'}}
                            placeholder="Last 4 of SSN *"
                            refInput={(input) => {
                                this.ssnInput = input
                            }}
                            returnKeyType="next"
                            style={styleFunctions.textInput()}
                            textInputStatus={this.state.inputStatusSsn}
                            type={'custom'}
                            value={this.state.ssnLastFour}
                        />

                        <ABATextInputMask
                            errorMessage={this.state.errMsgAbaId}
                            keyboardType="number-pad"
                            maxLength={9}
                            onChangeText={(text) => {
                                this.setState({abaId: text})
                            }}
                            onSubmitEditing={() => {
                                this.dateOfBirthInput.focus()
                            }}
                            options={{mask: '9999-9999'}}
                            placeholder="ABA ID"
                            refInput={(input) => {
                                this.abaIdInput = input
                            }}
                            returnKeyType="next"
                            style={styleFunctions.textInput()}
                            textInputStatus={this.state.inputStatusAbaId}
                            type={'custom'}
                            value={this.state.abaId}
                        />

                        <ABATextInputMask
                            errorMessage={this.state.errMsgDateOfBirth}
                            keyboardType="number-pad"
                            maxLength={10}
                            onChangeText={(text) => {
                                this.setState({dateOfBirth: text})
                            }}
                            onSubmitEditing={() => {
                                this.lastNameInput.focus()
                            }}
                            options={{mask: '99/99/9999'}}
                            placeholder="Date of Birth (mm/dd/yyyy)"
                            refInput={(input) => {
                                this.dateOfBirthInput = input
                            }}
                            returnKeyType="next"
                            style={styleFunctions.textInput()}
                            textInputStatus={this.state.inputStatusDateOfBirth}
                            type={'custom'}
                            value={this.state.dateOfBirth}
                        />

                        <ABATextInput
                            maxLength={50}
                            onChangeText={(text) => {
                                this.setState({lastName: text})
                            }}
                            onSubmitEditing={() => {
                                ForgotPasswordLogic.goToConfirmIdentity()
                            }}
                            textInputStatus={this.state.inputStatusLastName}
                            placeholder="Last Name"
                            reference={(input) => {
                                this.lastNameInput = input
                            }}
                        />

                        <ABATouchableHighlight
                            buttonStyle={styleFunctions.buttonClick()}
                            icon={'angle-right'}
                            onPressEvent={() => {
                                ForgotPasswordLogic.goToConfirmIdentity()
                            }}
                            text={'Continue'}
                            textStyle={{color: 'white', fontSize: 16}}
                            underlayColor="#66ccff"
                        />
                    </View>
                    <LoginReturnLink />
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Verifying Data</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
                <LoginFooter />
            </ABAKeyboardAwareScrollView>
        )
    }
}
