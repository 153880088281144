import React, {Component} from 'react'
import {Text, View, ActivityIndicator} from 'react-native'
import styleFunctions from '../../styles/styleFunctions'
import {ColorEnums} from '../../helpers/generalEnums'

export default class Loading extends Component {
    render() {
        return (
            <View style={styleFunctions.loadingOverlay()}>
                <Text>{'Please Wait'}</Text>
                <ActivityIndicator color={ColorEnums.indigo} size="large" />
            </View>
        )
    }
}
