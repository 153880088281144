import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep5 from '../logic/substanceAbuseLogicStep5'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import Reference from '../../../presentation/reference'
import ReferenceList from '../../../presentation/referenceList'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },
})

export default class SubstanceAbuseStep5 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        addNewTreatmentReference: false,
        showReferenceList: false,
        addNewReferenceID: '-1',
        ReferenceIDList: [],
        TreatmentReferenceIDList: [],
        collapsedReference: null,
    }

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        Promise.all([
            SubstanceAbuseLogicStep5.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep5.GetSAReferences(),
            SubstanceAbuseLogicStep5.GetTreatmentReferences(),
        ]).then((SAData) => {
            SubstanceAbuseLogicStep5.PopulateVariables(SAData)
        })
    }

    Save() {
        return SubstanceAbuseLogicStep5.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep5.Previous()
    }

    hideContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: false})
    }

    showContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: true})
    }

    renderAddTreatmentButton() {
        const {addNewTreatmentReference, ReferenceIDList, ComponentWidth} = this.state
        const buttonStyle = {
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 5,
        }
        let addFunction = () => {
            this.hideContinue()
            this.setState({addNewTreatmentReference: true})
        }
        if (ReferenceIDList.length > 0) {
            addFunction = () => {
                this.hideContinue()
                this.setState({showReferenceList: true})
            }
        }

        return (
            <View style={buttonStyle}>
                <View style={{marginBottom: ComponentWidth > 600 ? 0 : 10}}>
                    <ABATouchableHighlight
                        onPressEvent={addFunction}
                        text="Add Treatment/Recovery Reference"
                    />
                </View>
                {addNewTreatmentReference && (
                    <ABATouchableHighlight
                        buttonStyle={[
                            styleFunctions.buttonClickInverted(),
                            {marginLeft: ComponentWidth > 600 ? 10 : 0},
                        ]}
                        onPressEvent={() => {
                            this.showContinue()
                            this.setState({addNewTreatmentReference: false})
                        }}
                        textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                        text={'Cancel'}
                    />
                )}
            </View>
        )
    }

    renderReference(reference) {
        const {cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName} = reference
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`

        return (
            <ABAAccordion
                key={cert_referenceId}
                title={referenceName}
                accordianID={cert_referenceId}
                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                accordionTitleStyle={styles.accordionComponentTitle}
                accordionButtonStyle={styles.accordionTitlebutton}
                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                accordionExpanded={this.state.collapsedReference === cert_referenceId}
                toggleCallback={(refId, expanded) =>
                    expanded && this.setState({collapsedReference: refId})
                }
                accordionUnderlayColor={ColorEnums.white}
                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                accordionContent={
                    <Reference
                        referenceID={cert_referenceId}
                        isConfirmationPage={this.props.isConfirmationPage}
                        ProgramType={this.props.ProgramType}
                        IsSubstanceAbuseRef={true}
                        RenderSaveReferenceButton={false}
                        RenderEditButtons={true}
                        saveCallback={SubstanceAbuseLogicStep5.UpdateGrid}
                        cancelCallback={SubstanceAbuseLogicStep5.CancelAddReference}
                    />
                }
            />
        )
    }

    render() {
        SubstanceAbuseLogicStep5.context = this
        const {ComponentWidth, TreatmentReferenceIDList} = this.state
        let treatmentReferences
        if (TreatmentReferenceIDList.length > 0) {
            treatmentReferences = TreatmentReferenceIDList.map((reference) => {
                return this.renderReference(reference)
            })
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 4 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.57),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.43),
                            ]}
                        />
                    </View>
                )}
                <Text
                    style={[styleFunctions.paragraphText(), {fontSize: 14, width: '90%', padding: 10}]}
                >
                    Please provide the names and addresses of the physicians and other healthcare
                    professionals who have been involved in your treatment/recovery and monitoring your
                    participation in aftercare programs.
                    <ABARequiredAsterisk />
                </Text>
                {!this.state.showReferenceList && (
                    <View>
                        {this.renderAddTreatmentButton()}
                        {treatmentReferences}
                    </View>
                )}
                {this.state.showReferenceList && (
                    <View>
                        <ReferenceList
                            ReferenceList={this.state.ReferenceIDList}
                            AddReference={SubstanceAbuseLogicStep5.AddExistingReference}
                        />
                        <View style={{flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={SubstanceAbuseLogicStep5.AddNewReference}
                            >
                                ADD NEW
                            </Text>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={() => {
                                    this.showContinue()
                                    this.setState({showReferenceList: false})
                                }}
                            >
                                CANCEL
                            </Text>
                        </View>
                    </View>
                )}
                {this.state.addNewTreatmentReference && (
                    <Reference
                        referenceID={this.state.addNewReferenceID}
                        isConfirmationPage={this.props.isConfirmationPage}
                        ProgramType={this.props.ProgramType}
                        NewReference={true}
                        ReferenceType={GeneralEnums.cert_referenceType.AfterCare_Monitoring}
                        IsSubstanceAbuseRef={true}
                        RenderEditButtons={true}
                        saveCallback={SubstanceAbuseLogicStep5.UpdateGrid}
                    />
                )}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 4</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
