import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import ABATextInput from '../../../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods'
import styleFunctions from '../../../../styles/styleFunctions'
import {ABAFileUpload} from '../../../ABACustom/presentation/ABAFileUpload'
import NonStandardExamLogicStep3 from '../logic/nonStandardExamStep3Logic'

export default class nonStandardExamStep3 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        exam_specificDiagnosis: '',
        exam_yearFirstDiagnosed: '',
        exam_specificLimitations: '',
        exam_lastEvaluationDate: '',
        pmt_examtypename: '',
        InputStatus: {
            exam_specificDiagnosis: GeneralEnums.textInputStatus.default,
            exam_yearFirstDiagnosed: GeneralEnums.textInputStatus.default,
            exam_specificLimitations: GeneralEnums.textInputStatus.default,
            exam_lastEvaluationDate: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        NonStandardExamLogicStep3.GetNSEData().then((NSEData) => {
            NonStandardExamLogicStep3.PopulateStateVariables(NSEData)
        })
    }

    Save() {
        return NonStandardExamLogicStep3.SaveLogic()
    }

    Previous() {
        return NonStandardExamLogicStep3.Previous()
    }

    render() {
        NonStandardExamLogicStep3.context = this
        const ExamLabel = nonStandardExamContext.state.ExamLabel || this.state.pmt_examtypename
        const {ComponentWidth} = this.state
        const isDesktop = ComponentWidth > 600

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 1 of 5</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.2),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.8),
                            ]}
                        />
                    </View>
                )}
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, paddingBottom: isDesktop ? 0 : 5},
                        ]}
                    >
                        Please provide a specific diagnosis of the impairment to be accommodated.
                        <ABARequiredAsterisk />
                    </Text>
                    <Text style={{fontStyle: 'italic'}}>
                        Note: General descriptions such as 'learning problems' or 'processing deficit'
                        are <Text style={{fontWeight: 'bold'}}>not</Text> adequate.
                    </Text>
                    <ABATextInputMultiline
                        maxLength={2000}
                        numberOfLines={8}
                        onChangeText={(exam_specificDiagnosis) =>
                            this.setState({exam_specificDiagnosis})
                        }
                        value={this.state.exam_specificDiagnosis}
                        textInputStatus={this.state.InputStatus.exam_specificDiagnosis}
                    />
                </View>

                <View
                    style={{
                        flexDirection: isDesktop ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: isDesktop ? '60%' : '100%'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 15,
                                    width: '100%',
                                    marginRight: 10,
                                    paddingBottom: isDesktop ? 0 : 15,
                                },
                            ]}
                        >
                            What is the year the condition was first diagnosed?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: isDesktop ? '40%' : '100%'}}>
                        <ABATextInput
                            onChangeText={(exam_yearFirstDiagnosed) =>
                                this.setState({exam_yearFirstDiagnosed})
                            }
                            placeholder="(yyyy)"
                            textInputStatus={this.state.InputStatus.exam_yearFirstDiagnosed}
                            returnKeyType="next"
                            value={this.state.exam_yearFirstDiagnosed.toString()}
                            width="50%"
                            marginRight={0}
                            marginLeft={0}
                            keyboardType="number-pad"
                        />
                    </View>
                </View>

                <View
                    style={{
                        flexDirection: isDesktop ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: isDesktop ? '60%' : '100%'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 15,
                                    width: '100%',
                                    marginRight: 10,
                                    paddingBottom: isDesktop ? 0 : 15,
                                },
                            ]}
                        >
                            What is the most recent date the condition was evaluated?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: isDesktop ? '40%' : '100%'}}>
                        <ABATextInputMask
                            keyboardType="number-pad"
                            maxLength={10}
                            onChangeText={(exam_lastEvaluationDate) =>
                                this.setState({exam_lastEvaluationDate})
                            }
                            options={{mask: '99/99/9999'}}
                            placeholder="(mm/dd/yyyy)"
                            returnKeyType="next"
                            style={styleFunctions.textInput()}
                            textInputStatus={this.state.InputStatus.exam_lastEvaluationDate}
                            value={this.state.exam_lastEvaluationDate}
                            marginLeft={0}
                            marginRight={0}
                            width="50%"
                            errorMessage="Please enter a valid date."
                        />
                    </View>
                </View>

                <View>
                    <Text style={[styleFunctions.paragraphText(), {fontSize: 14, paddingTop: 15}]}>
                        Please provide a personal statement that explains specifically how the diagnosed
                        impairment limits your ability to take the {ExamLabel} exam under standard
                        testing conditions.
                        <ABARequiredAsterisk />
                    </Text>
                    <ABATextInputMultiline
                        maxLength={2000}
                        numberOfLines={8}
                        onChangeText={(exam_specificLimitations) =>
                            this.setState({exam_specificLimitations})
                        }
                        value={this.state.exam_specificLimitations}
                        textInputStatus={this.state.InputStatus.exam_specificLimitations}
                    />
                    <Text style={{color: ColorEnums.pomegranate}}>
                        Please upload any supporting documents.
                    </Text>
                    <ABAFileUpload
                        filename={this.state.exam_specificLimitationsFile}
                        CRMField={'exam_specificLimitations'}
                        NSEID={nonStandardExamContext.state.NonStandardExamID}
                        deleteurl={'nserequestsinfo/removefile'}
                        uploadurl={'nserequestsinfo/uploadfile'}
                    />
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Non-Standard Exam Page 1</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
