import React, { Component } from "react";
import { View, Text } from "react-native";
import ABAKeyboardAwareScrollView from "../../components/ABACustom/presentation/ABAKeyboardAwareScrollView";
import NavigationShell from "../../components/presentation/navigationShell";
import HelperMethods from "../../helpers/HelperMethods";
import styleFunctions from "../../styles/styleFunctions";
import ViewNSERequestsLogic from "../logic/viewNSERequestsLogic";
import ABAHeader from "../../components/ABACustom/presentation/ABAHeader";
import { ColorEnums, GeneralEnums, IconEnums } from "../../helpers/generalEnums";
import ABAContainer from "../../components/ABACustom/presentation/ABAContainer";
export default class ViewNSERequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            NSEdata: [],
            CallingApi: false
        }
    }
    componentDidMount(){
        ViewNSERequestsLogic.getCompletedNSERequests().then(responsedata => {
            ViewNSERequestsLogic.context.setState({ NSEdata: responsedata });
        });    
    }

    render() {
        viewNSERequestsContext = this;
        navigationContext = this;
        ViewNSERequestsLogic.context = this;
        HelperMethods.setNavigationData(viewNSERequestsContext);
        return (
            <NavigationShell context={viewNSERequestsContext}>
                <ABAKeyboardAwareScrollView style={{ backgroundColor: ColorEnums.backgroundGray }}>
                    <ABAHeader
                        group={GeneralEnums.ABAHeaderGroups.ExamInformation}
                    />
                    <ABAContainer
                        activityText={"Loading Nonstandard Exam Requests"}
                        containerTitle={"COMPLETED NSE REQUEST FORMS"}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View style={{width:"100%", flexDirection: "row", alignSelf:"center", paddingBottom:10, paddingTop:30, borderBottomWidth: 2, borderBottomColor: ColorEnums.lightGray}}>
                            <Text style={[styleFunctions.columnHeader(),{width:"25%", fontFamily:"Lato", fontSize:14}]}>Date</Text>
                            <Text style={[styleFunctions.columnHeader(),{width:"47%", fontFamily:"Lato", fontSize:14}]}>Description</Text>
                            <Text style={[styleFunctions.columnHeader(),{width:"28%", fontFamily:"Lato", fontSize:14}]}>View PDF</Text>
                        </View>
                        {viewNSERequestsContext.state.NSEdata.map((item, index) => {  
                            return (
                                <View key={index} style={{width:"100%", flexDirection: "row", alignSelf:"center", paddingBottom:10, paddingTop:10, borderBottomWidth: 1, borderBottomColor: ColorEnums.lightGray}}>
                                    <Text style={[styleFunctions.paragraphText(),{width:"25%", fontSize:14}]}>{item.DateSigned}</Text>
                                    <Text style={[styleFunctions.paragraphText(),{width:"47%", fontSize:14}]}>{item.ExamName}</Text>                                        
                                    {
                                        !item.nsePDFName &&
                                        <Text style={[styleFunctions.columnHeader(),{width:"28%", fontFamily:"Lato", fontSize:14}]}>
                                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>{IconEnums["file-alt"]}</Text> Not Available
                                        </Text>
                                    }
                                    {
                                        item.nsePDFName &&
                                        <Text style={[styleFunctions.actionText(),{width:"28%", fontFamily:"Lato", fontSize:14}]}
                                            onPress={() => ViewNSERequestsLogic.openPDF(item.nsePDFName, item.NSEID)}>
                                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>{IconEnums["file-alt"]}</Text> Download
                                        </Text>
                                    }
                                </View> 
                            )
                        })}
                        <View style={{borderTopWidth: 2, borderTopColor: ColorEnums.cloudBurst, width:"100%", alignSelf:"center", maxWidth:700 }}></View>        
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}