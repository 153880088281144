import React, {Component} from 'react'
import {View, Text, StyleSheet, Platform, TouchableHighlight} from 'react-native'

import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABADisplayCard from '../../components/ABACustom/presentation/ABADisplayCard'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAPagination from '../../components/ABACustom/presentation/ABAPagination'
import ExaminerConflictLogic from '../logic/examinerConflictsLogic'
import {GeneralEnums, ColorEnums, IconEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'

const isInternetExplorer = Platform.OS === 'web' && /*@cc_on!@*/ (false || !!document.documentMode)

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    dropdownWidth: {
        ...Platform.select({
            android: {width:200},
            default: {width:170},
        })
    },
    selectExam: {
        display: 'flex',
        padding: 5,
        width: '95%',
        marginRight: 'auto',
        marginLeft: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        flex: 1,
        backgroundColor: 'white',
        minHeight: isInternetExplorer ? 50 : null,
    },
    utilityRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        ...Platform.select({
            ios: {justifyContent: 'center'},
            android: {justifyContent: 'center'},
            default: {
                justifyContent: 'space-between',
            },
        }),
    },
    filterRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexGrow: 1,
        flexShrink: 1,
        width: '100%',
        flexWrap: 'wrap',
        padding: 10,
        alignItems: 'stretch',
        minHeight: isInternetExplorer ? 50 : null,
    },
    buttons: {
        ...styleFunctions.buttonClick(),
        margin: 5,
        ...Platform.select({
            ios: {marginLeft: 0},
            android: {marginLeft: 0},
            default: {
                marginLeft: 35,
            },
        }),
    },
    invisibleBox: {
        ...Platform.select({
            ios: {width: 130},
            android: {width: 130},
            default: {
                width: 170,
            },
        }),
    },
})

export default class ExaminerConflicts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gettingCandidates: false,
            gettingConflicts: false,
            candidateInitials: [],
            candidateStates: [],
            selectedInitial: 'null',
            selectedState: 'null',
            selectedCandidateSort: 1,
            selectedConflictSort: 1,
            selectedExamId: null,
            filterText: '',
            conflictCandidates: [],
            candidates: [],
            currentCandidateIndex: 0,
            currentConflictIndex: 0,
            showCandidateRecordCount: 25,
            showConflictRecordCount: 15,
            expandConflicts: false,
            expandCandidates: true,
            ExaminerExamList: [],
            ExamStatus: {
                ExaminerId: null,
                ConflictStatus: null,
                ExamId: null,
                InvitedExamId: null,
                ExamName: null,
            },
        }
    }

    componentDidMount() {
        ExaminerConflictLogic.SetExam(this.props.location.state.ExamId)
    }

    render() {
        ExaminerConflictLogic.context = this
        const {context} = ExaminerConflictLogic
        HelperMethods.setNavigationData(context)

        const CandidateView = candidate => {
            return (
                <View
                    style={{
                        display: 'flex',
                    }}
                >
                    <Text style={{fontWeight: 'bold', paddingBottom: 5}}>{candidate.ProgramName}</Text>
                    <View>
                        <View style={styles.row}>
                            <Text style={{fontWeight: 'bold', paddingBottom: 5}}>Program ID: </Text>
                            <Text style={{flexGrow: 1}}>{candidate.ProgramId}</Text>
                        </View>
                        {candidate.conflictType && (
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingBottom: 5,
                                }}
                            >
                                <Text style={{fontWeight: 'bold'}}>Conflict Type: </Text>
                                <Text style={{flexGrow: 1}}>{candidate.conflictType}</Text>
                            </View>
                        )}
                    </View>
                    <Text style={{textAlign: 'center'}}>
                        {candidate.Address1_City}, {candidate.Address_StateOrProvince}
                    </Text>
                </View>
            )
        }

        let addButton = candidate => (
            <View style={{alignSelf: 'center', paddingRight: 5}}>
                <ABATouchableHighlight
                    buttonStyle={{
                        backgroundColor: 'green',
                        alignSelf: 'center',
                    }}
                    icon={'plus'}
                    onPressEvent={() => ExaminerConflictLogic.AddConflict(candidate)}
                    small
                />
            </View>
        )

        let removeButton = candidate => (
            <View style={{alignSelf: 'center', paddingRight: 5}}>
                <ABATouchableHighlight
                    buttonStyle={{backgroundColor: 'red', alignSelf: 'center'}}
                    icon={'minus'}
                    onPressEvent={() => ExaminerConflictLogic.RemoveConflict(candidate)}
                    small
                />
            </View>
        )

        return (
            <NavigationShell context={context}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.Conflicts} />
                    {this.state.selectedExamId && (
                        <View style={styles.selectExam}>
                            <ABADropdown
                                options={this.state.ExaminerExamList}
                                label={`Selected Exam`}
                                placeholder={'Select Exam'}
                                onChange={selectedExamId =>
                                    ExaminerConflictLogic.SetExam(selectedExamId)
                                }
                                selectedValue={this.state.selectedExamId}
                                validationStatus={GeneralEnums.textInputStatus.default}
                                width={300}
                            />
                        </View>
                    )}
                    {this.state.conflictCandidates.length > 0 && (
                        <ABAContainer
                            activityText={'Loading Candidate Conflicts'}
                            containerTitle={'Current Conflicts'}
                            status={`${
                                this.state.ExamStatus.ExamName
                            } - ${ExaminerConflictLogic.examConflictStatus()}`}
                            showActivityIndicator={this.state.gettingConflicts}
                            useMaxWidth={false}
                        >
                            <View style={styles.utilityRow}>
                                <View style={[{paddingBottom: 5},styles.dropdownWidth]}>
                                    <ABADropdown
                                        options={ExaminerConflictLogic.sortTypes}
                                        label={`Sort`}
                                        placeholder={`Sort Fields`}
                                        onChange={selectedConflictSort =>
                                            this.setState({
                                                selectedConflictSort,
                                                currentCandidateIndex: 0,
                                            })
                                        }
                                        selectedValue={this.state.selectedConflictSort}
                                        validationStatus={GeneralEnums.textInputStatus.default}
                                        width={styles.dropdownWidth.width}
                                    />
                                </View>
                                <ABAPagination
                                    currentIndex={this.state.currentConflictIndex}
                                    totalRecordCount={this.state.conflictCandidates.length || 0}
                                    displayedArrayLength={
                                        ExaminerConflictLogic.getDisplayConflicts().length
                                    }
                                    previousPage={() => ExaminerConflictLogic.changeConflictsPage(false)}
                                    nextPage={() => ExaminerConflictLogic.changeConflictsPage(true)}
                                />
                                <View style={styles.invisibleBox} />
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <View
                                    style={{
                                        display: 'flex',
                                        flexShrink: 1,
                                        flexGrow: 1,
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {ExaminerConflictLogic.getDisplayConflicts().map(candidate => {
                                        ConflictType = null
                                        if (
                                            candidate.exam_legacyid_string &&
                                            candidate.exam_legacyid_string !== 'Added By Doctor'
                                        ) {
                                            ConflictType = 'Auto'
                                        }
                                        candidate.conflictType = ConflictType
                                        return (
                                            <ABADisplayCard
                                                title={candidate.FullName}
                                                item={candidate}
                                                isExpanded={this.state.expandConflicts}
                                                expandOnClick={false}
                                                titleActionButton={
                                                    ConflictType !== 'Auto' && removeButton(candidate)
                                                }
                                            >
                                                {CandidateView(candidate)}
                                            </ABADisplayCard>
                                        )
                                    })}
                                </View>
                                <TouchableHighlight
                                    style={{alignSelf: 'flex-start', padding: 10}}
                                    onPress={() =>
                                        this.setState({
                                            expandConflicts: !this.state.expandConflicts,
                                        })
                                    }
                                >
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.danube,
                                        }}
                                    >
                                        {this.state.expandConflicts
                                            ? IconEnums['chevron-up']
                                            : IconEnums['chevron-down']}{' '}
                                    </Text>
                                </TouchableHighlight>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    flexGrow: 1,
                                }}
                            >
                                <ABATouchableHighlight
                                    text={'Submit'}
                                    onPressEvent={() => ExaminerConflictLogic.SubmitExamStatus()}
                                    buttonStyle={styles.buttons}
                                />
                            </View>
                        </ABAContainer>
                    )}
                    <ABAContainer
                        activityText={`Loading Candidates`}
                        containerTitle={'Current Candidates'}
                        useMaxWidth={false}
                        showActivityIndicator={this.state.gettingCandidates}
                    >
                        <View style={{display: 'flex', flexDirection: 'column'}}>
                            <View style={styles.filterRow}>
                                <ABADropdown
                                    options={this.state.candidateInitials}
                                    label={`Last Initial${
                                        this.state.candidateInitials.length === 0 ? '' : ' *'
                                    }`}
                                    placeholder={`Last Initial${
                                        this.state.candidateInitials.length === 0 ? '' : ' *'
                                    }`}
                                    onChange={selectedInitial =>
                                        this.setState({
                                            selectedInitial,
                                            currentCandidateIndex: 0,
                                        })
                                    }
                                    selectedValue={this.state.selectedInitial}
                                    validationStatus={GeneralEnums.textInputStatus.default}
                                    width={170}
                                />
                                <ABADropdown
                                    options={this.state.candidateStates || []}
                                    label="State/Province *"
                                    placeholder="State *"
                                    onChange={selectedState =>
                                        this.setState({
                                            selectedState,
                                            currentCandidateIndex: 0,
                                        })
                                    }
                                    selectedValue={this.state.selectedState}
                                    validationStatus={GeneralEnums.textInputStatus.default}
                                    width={131}
                                />
                                <ABATextInput
                                    onChangeText={filterText =>
                                        this.setState({
                                            filterText,
                                            currentCandidateIndex: 0,
                                        })
                                    }
                                    placeholder="Filter Text"
                                    textInputStatus={GeneralEnums.textInputStatus.default}
                                    returnKeyType="next"
                                    width={200}
                                    marginRight={0}
                                    marginLeft={0}
                                    value={this.state.filterText}
                                />
                                <View style={{paddingTop:10}}>
                                <ABATouchableHighlight
                                    text={'Reset Filters'}
                                    onPressEvent={() => ExaminerConflictLogic.resetFilter()}
                                />
                                </View>
                            </View>
                            <View style={styles.utilityRow}>
                                <View style={styles.dropdownWidth}>
                                    <ABADropdown
                                        options={ExaminerConflictLogic.sortTypes}
                                        label={`Sort`}
                                        placeholder={`Please select a sorting method`}
                                        onChange={selectedCandidateSort =>
                                            this.setState({
                                                selectedCandidateSort,
                                                currentCandidateIndex: 0,
                                            })
                                        }
                                        selectedValue={this.state.selectedCandidateSort}
                                        validationStatus={GeneralEnums.textInputStatus.default}
                                        width={styles.dropdownWidth.width}
                                    />
                                </View>

                                <ABAPagination
                                    currentIndex={this.state.currentCandidateIndex}
                                    totalRecordCount={
                                        ExaminerConflictLogic.getFilteredCandidates().length || 0
                                    }
                                    displayedArrayLength={
                                        ExaminerConflictLogic.getDisplayCandidates().length
                                    }
                                    previousPage={() =>
                                        ExaminerConflictLogic.changeCandidatesPage(false)
                                    }
                                    nextPage={() => ExaminerConflictLogic.changeCandidatesPage(true)}
                                    style={{alignSelf: 'center'}}
                                />
                                <View style={styles.invisibleBox} />
                            </View>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexShrink: 1,
                                    flexGrow: 1,
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}
                            >
                                {ExaminerConflictLogic.getDisplayCandidates().map(candidate => {
                                    return (
                                        <ABADisplayCard
                                            title={candidate.FullName}
                                            item={candidate}
                                            isExpanded={this.state.expandCandidates}
                                            expandOnClick={false}
                                            titleActionButton={addButton(candidate)}
                                        >
                                            {CandidateView(candidate)}
                                        </ABADisplayCard>
                                    )
                                })}
                            </View>
                            <TouchableHighlight
                                style={{alignSelf: 'flex-start', padding: 10}}
                                onPress={() =>
                                    this.setState({
                                        expandCandidates: !this.state.expandCandidates,
                                    })
                                }
                            >
                                <Text
                                    style={{
                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                        color: ColorEnums.danube,
                                    }}
                                >
                                    {this.state.expandCandidates
                                        ? IconEnums['chevron-up']
                                        : IconEnums['chevron-down']}{' '}
                                </Text>
                            </TouchableHighlight>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
