import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {Platform, View, TouchableWithoutFeedback, StyleSheet} from 'react-native'
import Modal from 'react-native-modal'
export default class ABAModal extends Component {
    closeModal() {
        this.props.onDismiss()
    }

    onBackdropPress() {
        this.props.onBackdropPress()
    }

    render() {
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
            return (
                <View style={styles.container}>
                    <Modal
                        visible={this.props.isVisible}
                        onRequestClose={() => this.closeModal()}
                        transparent={true}
                        backdropColor={styles.modalContainer.backgroundColor}
                        {...this.props}
                    >
                        <View style={styles.innerContainer}>{this.props.children}</View>
                    </Modal>
                </View>
            )
        } else {
            return ReactDOM.createPortal(
                <View style={styles.container}>
                    <TouchableWithoutFeedback onPress={() => this.onBackdropPress()}>
                        <View style={styles.modalContainer}>
                            <TouchableWithoutFeedback style={{cursor: 'default'}}>
                                <View style={styles.innerContainer}>{this.props.children}</View>
                            </TouchableWithoutFeedback>
                        </View>
                    </TouchableWithoutFeedback>
                </View>,
                document.getElementById('modal'),
            )
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                zIndex: 100,
                overflow: 'visible',
                position: 'absolute',
            },
        }),
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
        }),
    },
    innerContainer: {
        alignItems: 'center',
        ...Platform.select({
            web: {
                cursor: 'default',
            },
        }),
    },
})
