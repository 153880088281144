import React, {Component} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import QIAttestationLogic from '../logic/qiAttestationLogic'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import {GeneralEnums, ColorEnums, IconEnums} from '../../helpers/generalEnums'
import ABARequiredAsterisk from '../../components/ABACustom/presentation/ABARequiredAsterisk'
import ABACheckBox from '../../components/ABACustom/presentation/ABACheckbox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import StyleFunctions from '../../styles/styleFunctions'

export default class QIAttestation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            AttestChecked: false,
            ComponentWidth: null,
            InputStatus: {
                AttestChecked: GeneralEnums.textInputStatus.default,
            },
        }
    }

    render() {
        QIAttestationLogic.context = this
        let qiAttestationContext = QIAttestationLogic.context
        HelperMethods.setNavigationData(qiAttestationContext)

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                    <ABAContainer
                        activityText={'Submitting QI Attestation'}
                        containerTitle={'QI Attestation'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View style={{marginTop: 20, paddingBottom: 20, justifyContent: 'center'}}>
                            <Text style={{fontFamily: 'Lato', fontSize: 14}}>
                                Check the box below to attest to completing 25 points worth of quality
                                improvement (QI) and then hit “Submit.” You will attest every five years;
                                but will not be required to report individual activities.
                            </Text>
                            <Text
                                style={{
                                    fontFamily: 'Lato',
                                    fontStyle: 'italic',
                                    marginTop: 15,
                                    fontSize: 14,
                                }}
                            >
                                Please note: Your attestation is subject to audit.
                            </Text>

                            <View style={{marginTop: 40}}>
                                <ABACheckBox
                                    checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                    isChecked={this.state.AttestChecked}
                                    labelStyle={StyleFunctions.containerText()}
                                    label={
                                        'I attest that I have completed 25 points of ABA-approved QI activities.'
                                    }
                                    onChangeEvent={() =>
                                        qiAttestationContext.setState({
                                            AttestChecked: !qiAttestationContext.state.AttestChecked,
                                        })
                                    }
                                    isRequired={true}
                                    useValidation={true}
                                    validationStatus={this.state.InputStatus.AttestChecked}
                                    errorMessage={'Please check the Attest check box'}
                                />
                            </View>
                            <Text style={{color: ColorEnums.pomegranate, marginTop: 10}}>
                                <ABARequiredAsterisk /> Required Field
                            </Text>
                            <View style={{paddingTop: 30}}>
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{width: '50%', alignSelf: 'flex-start'}}>
                                        <ABATouchableHighlight
                                            buttonStyle={StyleFunctions.buttonClickInverted()}
                                            onPressEvent={() => {
                                                NavigationHelper.navigateBack()
                                            }}
                                            text={'Cancel'}
                                            textStyle={{
                                                color: ColorEnums.danube,
                                                fontSize: 16,
                                            }}
                                            underlayColor={ColorEnums.white}
                                        />
                                    </View>
                                    <View style={{width: '50%', alignSelf: 'flex-end'}}>
                                        <ABATouchableHighlight
                                            buttonStyle={StyleFunctions.buttonClick()}
                                            icon={'angle-right'}
                                            onPressEvent={() => {
                                                QIAttestationLogic.CreateQIAttestationIMP()
                                            }}
                                            text={'Submit'}
                                            textStyle={{
                                                color: ColorEnums.white,
                                                fontSize: 16,
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
