import React, {Component} from 'react'
import moment from 'moment'
import {View, Text, StyleSheet, ActivityIndicator} from 'react-native'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ExamInfoSubspecialtyLogic from '../logic/examInfoSubspecialtyLogic'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import styleFunctions from '../../styles/styleFunctions'

const styles = StyleSheet.create({
    linkComponents: {
        flexDirection: 'column',
        alignSelf: 'center',
        marginBottom: 20,
    },
    examInformation: {
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 16,
        fontWeight: 'normal',
    },
    confirmationBox: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
})

export default class ExamInfoSubspecialty extends Component {
    constructor(props) {
        super(props)
        this.state = {
            adultCardiacRegistration: null,
            hospiceInitialRegistration: null,
            hospiceRecertRegistration: null,
            sleepInitialRegistration: null,
            sleepRecertRegistration: null,
            neurocriticalRegistration: null,
            showConfirmation: false,
            confirmationBox: {
                show: false,
                examName: null,
            },
            showCreateCRModal: false,
            selectedExam: {
                programType: null,
                isRecert: false,
            },
            loadingSubspecialties: true,
        }
    }

    componentDidMount() {
        ExamInfoSubspecialtyLogic.getOpenSubspecialtyExams().then((openExams) => {
            ExamInfoSubspecialtyLogic.getCertifications().then((certs) => {
                let checkIfRegistrationIsOpen = (productId) => {
                    return openExams.some((exam) => exam.ProductId === productId)
                }
                let adultCardiacRegistration,
                    hospiceInitialRegistration,
                    hospiceRecertRegistration,
                    sleepInitialRegistration,
                    sleepRecertRegistration,
                    neurocriticalRegistration = null
                const anesthesiologyCert = certs.find((cert) => cert.SpecialtyName === 'Anesthesiology')
                const adultCardiacAnesthesiologyCert = certs.find((cert) => cert.SpecialtyName === 'Adult Cardiac Anesthesiology')
                const hospiceCert = certs.find(
                    (cert) => cert.SpecialtyName === 'Hospice and Palliative Medicine',
                )
                const sleepCert = certs.find((cert) => cert.SpecialtyName === 'Sleep Medicine')

                const certifiedInAnesthesiology =
                    anesthesiologyCert &&
                    anesthesiologyCert.CertificationStatus === 'Certified' &&
                    anesthesiologyCert.IncludedInMOC === 1

                const threeYearsFromToday = moment().add(3, 'years').calendar()

                // Hospice and Palliative Medicine
                if (
                    hospiceCert &&
                    hospiceCert.CertificationStatus === 'Certified' &&
                    hospiceCert.IncludedInMOC === 1 &&
                    moment(threeYearsFromToday).isAfter(hospiceCert.ExpirationDate, 'day') &&
                    checkIfRegistrationIsOpen(GeneralEnums.product.HPMRecert)
                ) {
                    hospiceRecertRegistration = true
                } else if (
                    !hospiceCert &&
                    certifiedInAnesthesiology &&
                    checkIfRegistrationIsOpen(GeneralEnums.product.HPM)
                ) {
                    hospiceInitialRegistration = true
                }

                // Sleep Medicine
                if (
                    sleepCert &&
                    sleepCert.CertificationStatus === 'Certified' &&
                    sleepCert.IncludedInMOC === 1 &&
                    moment(threeYearsFromToday).isAfter(sleepCert.ExpirationDate, 'day') &&
                    checkIfRegistrationIsOpen(GeneralEnums.product.SMRecert)
                ) {
                    sleepRecertRegistration = true
                } else if (
                    !sleepCert &&
                    certifiedInAnesthesiology &&
                    checkIfRegistrationIsOpen(GeneralEnums.product.SM)
                ) {
                    sleepInitialRegistration = true
                }

                //Neurocritical Care
                if (certifiedInAnesthesiology && checkIfRegistrationIsOpen(GeneralEnums.product.NCC)) {
                    neurocriticalRegistration = true
                }

                //Adult Cardiac
                if (certifiedInAnesthesiology && !adultCardiacAnesthesiologyCert && checkIfRegistrationIsOpen(GeneralEnums.product.ACA)) {
                    adultCardiacRegistration = true
                }

                this.setState({
                    hospiceInitialRegistration,
                    hospiceRecertRegistration,
                    sleepInitialRegistration,
                    sleepRecertRegistration,
                    neurocriticalRegistration,
                    adultCardiacRegistration,
                    loadingSubspecialties: false,
                })
            })
        })
    }

    render() {
        ExamInfoSubspecialtyLogic.context = this
        HelperMethods.setNavigationData(this)
        
        let ExamInfoLinksArray = [
            {
                name: 'Adult Cardiac Anesthesiology - Initial Certification',
                text: 'Learn more about the Adult Cardiac Anesthesiology initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: this.state.adultCardiacRegistration,
                productId: GeneralEnums.product.ACA,
                programType: GeneralEnums.program_type.Adult_Cardiac_Anesthesiology,
                isRecert: false,
            },
            {
                name: 'Critical Care Medicine - Initial Certification',
                text: 'Learn more about the Critical Care Medicine initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: null,
                productId: null,
                programType: GeneralEnums.program_type.Critical_Care_Medicine,
                isRecert: false,
            },
            {
                name: 'Hospice & Palliative Medicine - Initial Certification',
                text: 'Learn more about the Hospice and Palliative Medicine initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: this.state.hospiceInitialRegistration,
                productId: GeneralEnums.product.HPM,
                programType: GeneralEnums.program_type.Hospice_and_Palliative_Medicine,
                isRecert: false,
            },
            {
                name: 'Hospice & Palliative Medicine - Recertification',
                text: 'Learn more about the Hospice and Palliative Medicine recertification exam:',
                infoLink: 'https://theaba.org/recertification.html',
                registrationLink: this.state.hospiceRecertRegistration,
                productId: GeneralEnums.product.HPMRecert,
                programType: GeneralEnums.program_type.Hospice_and_Palliative_Medicine,
                isRecert: true,
            },
            {
                name: 'Neurocritical Care - Initial Certification',
                text: 'Learn more about the Neurocritical Care initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: this.state.neurocriticalRegistration,
                productId: GeneralEnums.product.NCC,
                programType: GeneralEnums.program_type.Neurocritical_Care_Exam,
                isRecert: false,
            },
            {
                name: 'Pain Medicine - Initial Certification',
                text: 'Learn more about the Pain Medicine initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: null,
                productId: null,
                programType: GeneralEnums.program_type.Pain_Medicine,
                isRecert: false,
            },
            {
                name: 'Pediatric Anesthesiology - Initial Certification',
                text: 'Learn more about the Pediatric Anesthesiology initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: null,
                productId: null,
                programType: GeneralEnums.program_type.Pediatric_Anesthesiology,
                isRecert: false,
            },
            {
                name: 'Sleep Medicine - Initial Certification',
                text: 'Learn more about the Sleep Medicine initial certification exam:',
                infoLink: 'https://theaba.org/subspecialty%20certification%20exams.html',
                registrationLink: this.state.sleepInitialRegistration,
                productId: GeneralEnums.product.SM,
                programType: GeneralEnums.program_type.Sleep_Medicine,
                isRecert: false,
            },
            {
                name: 'Sleep Medicine - Recertification',
                text: 'Learn more about the Sleep Medicine recertification exam:',
                infoLink: 'https://theaba.org/recertification.html',
                registrationLink: this.state.sleepRecertRegistration,
                productId: GeneralEnums.product.SMRecert,
                programType: GeneralEnums.program_type.Sleep_Medicine,
                isRecert: true,
            },
        ]

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader heading="Exam Information Page" />
                    <ABAContainer
                        activityText={'Loading Subspecialties'}
                        containerTitle={'SUBSPECIALTY EXAM INFORMATION'}
                        showActivityIndicator={this.state.loadingSubspecialties}
                        topPadding={0}
                    >
                        <View style={styles.linkComponents}>
                            {ExamInfoLinksArray.map((examInfoLink) => {
                                return (
                                    <View key={examInfoLink.name}>
                                        <Text
                                            style={[
                                                styles.examInformation,
                                                {marginTop: 15, fontWeight: 'bold'},
                                            ]}
                                        >
                                            {examInfoLink.name}
                                        </Text>
                                        <Text style={styles.examInformation}>{examInfoLink.text}</Text>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <ABAHyperLink
                                                label="View Here >"
                                                url={examInfoLink.infoLink}
                                                style={[
                                                    styles.examInformation,
                                                    {color: ColorEnums.danube},
                                                ]}
                                            />
                                            {examInfoLink.registrationLink && (
                                                <Text
                                                    onPress={() => {
                                                        this.setState({
                                                            confirmationBox: {
                                                                show: true,
                                                                examName: examInfoLink.name,
                                                            },
                                                            selectedExam: {
                                                                programType: examInfoLink.programType,
                                                                isRecert: examInfoLink.isRecert,
                                                            },
                                                        })
                                                    }}
                                                    style={[
                                                        styles.examInformation,
                                                        {
                                                            marginLeft: 120,
                                                            color: ColorEnums.danube,
                                                        },
                                                    ]}
                                                >
                                                    {'Register Here >'}
                                                </Text>
                                            )}
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </ABAContainer>

                    {this.state.confirmationBox.show && (
                        <View style={styles.confirmationBox}>
                            <ABAConfirmationBox
                                messageText1={`Please confirm you want to take the ${this.state.confirmationBox.examName} Exam`}
                                buttonLeftText="Cancel"
                                buttonRightText="Confirm"
                                leftButtonFunction={() =>
                                    this.setState({
                                        confirmationBox: {show: false, examName: null},
                                        selectedExam: {programType: null, isRecert: false},
                                    })
                                }
                                rightButtonFunction={() => {
                                    this.setState(
                                        {
                                            confirmationBox: {show: false, examName: null},
                                            showCreateCRModal: true,
                                        },
                                        () => {
                                            ExamInfoSubspecialtyLogic.createSubspecialtyCR2(
                                                this.state.selectedExam.programType,
                                                this.state.selectedExam.isRecert,
                                            ).then((cr2) => {
                                                NavigationHelper.GoToPage(
                                                    GeneralEnums.navigationType.push,
                                                    '/registrationStepsDisplay',
                                                    {
                                                        re_rulesetstatusId: cr2,
                                                        StatusCode:
                                                            GeneralEnums.rulesetStatus_statusCodes
                                                                .Eligible_to_Enroll,
                                                    },
                                                )
                                            })
                                        },
                                    )
                                }}
                            />
                        </View>
                    )}
                    {this.state.showCreateCRModal && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Loading Registration</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
