import React, {Component} from 'react'
import configurationProps from '../../configuration'
import PropTypes from 'prop-types'
import ABAVideoPlayer from '../ABACustom/presentation/ABAVideoPlayer'
import {Image, Platform} from 'react-native'

export default class MocaMinuteImage extends Component {
    constructor(props) {
        super(props)
    }

    constructImageURL(image) {
        if (configurationProps.ENVIRONMENT === 'DEV') {
            return `https://mmimageshare-dev01.office.abanes.org/Files/${image}`
        }
        if (configurationProps.ENVIRONMENT === 'QA') {
            return `https://mmimageshare-qa01.office.abanes.org/Files/${image}`
        }
        if (configurationProps.ENVIRONMENT === 'UAT') {
            return `https://mmimageshare-uat01.office.abanes.org/Files/${image}`
        }
        if (configurationProps.ENVIRONMENT === 'PROD') {
            return `https://mmimageshare.theaba.org/Files/${image}`
        }
    }

    renderMMImage(image, imageType) {
        if (!image) {
            return
        }

        const url = this.constructImageURL(image)
        if (imageType === '.mp4') {
            return <ABAVideoPlayer url={url} />
        }
        // assuming all other types are .jpg, .jpeg, .png, or .gif
        if (Platform.OS === 'web') {
            return (
                <div style={{textAlign: 'center'}}>
                    <img src={url} style={{maxHeight: 300}} />
                </div>
            )
        } else {
            return (
                <Image
                    style={{height: 300, width: 300, alignSelf: 'center'}}
                    source={{
                        uri: url,
                    }}
                    resizeMode="contain"
                />
            )
        }
    }

    render() {
        return <>{this.renderMMImage(this.props.image, this.props.imageType)}</>
    }
}

MocaMinuteImage.propTypes = {
    image: PropTypes.string.isRequired,
    imageType: PropTypes.string.isRequired,
}
