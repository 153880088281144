import {GeneralEnums} from '../helpers/generalEnums'
import {Platform} from 'react-native'
import HelperMethods from './HelperMethods'

const NavigationHelper = {
    goToHomePage: function () {
        NavigationHelper.GoToPage(
            GeneralEnums.navigationType.push,
            Platform.OS === 'ios' ? '/' : '/login',
        )
    },

    navigateBack: function () {
        navigationContext.props.history.goBack()
    },

    GoToPage: function (pushOrReplace, route, data = {}) {
        if (!pushOrReplace || !route) {
            return
        }
        if (Platform.OS === 'web') {
            sessionStorage.setItem(route + 'Data', HelperMethods.encryptData(JSON.stringify(data)))
        }
        data && HelperMethods.trackPageTransition(route)

        switch (pushOrReplace) {
            case GeneralEnums.navigationType.push:
                navigationContext.props.history.push(route, data)
                break
            case GeneralEnums.navigationType.replace:
                navigationContext.props.history.replace(route, data)
                break
        }
    },
}

export default NavigationHelper
