import {Platform} from 'react-native'

import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import NavigationHelper from '../../../../helpers/NavigationHelper'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep6 from '../presentation/nonStandardExamStep6'
import NonStandardExamStep1 from '../presentation/nonStandardExamStep1'

const NonStandardExamLogicStep7 = {
    context: null,

    GoToComponentStep(componentStep) {
        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({x: 0, y: 0, animated: false})
        nonStandardExamContext.setState({CurrentComponentStep: componentStep})
    },

    UpdateNSEStep7() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep7`, {
                NSEID: nonStandardExamContext.state.NonStandardExamID,
                statuscode: nonStandardExamContext.state.NSEStatus,
                rs_statuscode: nonStandardExamContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            this.UpdateNSEStep7().then((response) => {
                if (response) {
                    const {IsFromDashboard} = this.context.props
                    if (IsFromDashboard) {
                        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/viewNseRequests')
                    } else {
                        resolve(true)
                    }
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            if (nonStandardExamContext.state.RuleStatus === 1) {
                var goToStep = NonStandardExamStep6
                if (nonStandardExamContext.state.ExamResponse !== 1) {
                    goToStep = NonStandardExamStep1
                }
                nonStandardExamContext.setState({
                    CurrentComponentStep: goToStep,
                })
                resolve(false)
            } else {
                resolve(true)
            }
        })
    },
}

export default NonStandardExamLogicStep7
