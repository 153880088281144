import ABAPortalAPI from '../../helpers/AxiosInstance'

const QILogic = {
    context: null,

    GetDiplomateQIInformation: function () {
        return new Promise((resolve) => {
            QILogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('qualityimprovement/getDiplomateQualityImprovementInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Quality Improvement Data')
                    resolve(null)
                })
                .finally(function () {
                    QILogic.context.setState({CallingApi: false})
                })
        })
    },

    GetQIEntries: function (qiComponent) {
        let qi1Temp, qi2Temp, qi3Temp

        qiComponent.map((qi) => {
            {
                qi.RuleType == 1 ? (qi1Temp = qi) : null
            }
            {
                qi.RuleType == 2 ? (qi2Temp = qi) : null
            }
            {
                qi.RuleType == 3 ? (qi3Temp = qi) : null
            }
        })

        QILogic.context.setState({qi1: qi1Temp, qi2: qi2Temp, qi3: qi3Temp})
    },
}

export default QILogic
