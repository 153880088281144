import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import RegistrationStepsDisplayLogic from '../logic/registrationStepsDisplayLogic'
import styleFunctions from '../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

export default class RegistrationStepsDisplay extends Component {
    state = {
        CallingApi: false,
        registrationStepsData: null,
        ComponentWidth: null,
        Order: null,
        StepDirection: null,
        ShowContinue: true,
        ShowPrevious: true,
        ContinueText: 'Continue',
        overlayText: null,
        error: false,
        annualCr2: null,
        disableButton: false,
    }

    styleSetWidth(Componentwidth) {
        var width = 1 - Componentwidth * 0.000171
        if (width < 0.5) width = 0.5
        else if (width > 0.95) width = 0.95
        return {
            width: width + '%',
        }
    }

    componentDidMount() {
        const {isNTL} = this.navigationData
        if (isNTL) {
            RegistrationStepsDisplayLogic.UpdateNTLCertificates().then((ntlCertificate) => {
                RegistrationStepsDisplayLogic.Create10YearMocaCR2(ntlCertificate).then((cr2Id) => {
                    RegistrationStepsDisplayLogic.GetRegistrationStepsDisplayData(
                        null,
                        null,
                        cr2Id,
                    ).then((responseData) => {
                        registrationStepsDisplayContext.setState({
                            registrationStepsData: responseData,
                            annualCr2: cr2Id,
                        })
                    })
                })
            })
        } else {
            RegistrationStepsDisplayLogic.GetRegistrationStepsDisplayData().then((responseData) => {
                registrationStepsDisplayContext.setState({
                    registrationStepsData: responseData,
                    annualCr2: this.navigationData.re_rulesetstatusId,
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.error !== this.state.error) {
            this.showAlert()
        }
    }

    showAlert = () => {
        HelperMethods.alertAgnostic(null, 'Error retrieving records. Please return to Dashboard', [
            {text: 'Ok'},
        ])
        NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/dashboard')
    }

    render() {
        registrationStepsDisplayContext = this
        navigationContext = this
        RegistrationStepsDisplayLogic.context = registrationStepsDisplayContext
        HelperMethods.setNavigationData(registrationStepsDisplayContext)

        return (
            <NavigationShell context={registrationStepsDisplayContext}>
                <ABAKeyboardAwareScrollView
                    style={{
                        flex: 1,
                        backgroundColor: ColorEnums.backgroundGray,
                    }}
                    ref="RegStepScrollView"
                >
                    {registrationStepsDisplayContext.state.registrationStepsData &&
                        RegistrationStepsDisplayLogic.GetRegistrationStepsView(
                            registrationStepsDisplayContext.state.registrationStepsData,
                        )}
                </ABAKeyboardAwareScrollView>
                {registrationStepsDisplayContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>
                            {this.state.overlayText
                                ? this.state.overlayText
                                : 'Loading Registration Steps'}
                        </Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </NavigationShell>
        )
    }
}
