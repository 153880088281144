import React, { Component } from "react";
import {
    Text,
    View,
    StyleSheet
} from "react-native";

import PropTypes from 'prop-types';
import { ColorEnums } from "../../helpers/generalEnums";
import HelperMethods from "../../helpers/HelperMethods";
import ReferenceListLogic from "../logic/referenceListLogic";

const styles = StyleSheet.create({
    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: "Lato",
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: "right",
    },

    confirmationBox: {
        alignItems: 'center',
        justifyContent: "center",
        backgroundColor: "#F5FCFF88",
        height: "100%",
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: "absolute",
        zIndex: 65
    }
});

export default class ReferenceList extends Component {

    state = {
        ComponentWidth: null
    }

    renderReference(reference) {
        const { cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName, cert_ReferenceType } = reference;
        const referenceName = cert_MiddleName ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}` : `${cert_FirstName} ${cert_LastName}`;
        const referenceLabel = ReferenceListLogic.DetermineReferenceType(cert_ReferenceType)
        const { AddReference } = this.props;

        return (
            <View
                style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center", padding: 10, borderBottomWidth: 1, borderBottomColor: ColorEnums.blueGray }}
                key={cert_referenceId}
            >
                <Text style={{ width: "45%" }}>
                    { referenceName }
                </Text>
                <Text style={{ width: "45%" }}>
                    { referenceLabel }
                </Text>
                <Text
                    style={styles.accordionTextLinkStyle}
                    onPress={() => AddReference(cert_ReferenceType, cert_referenceId, this.props.ReferenceContext) }
                >
                    ADD
                </Text>
            </View>
        )
    }

    render() {
        const { ReferenceList } = this.props;
        let references;
        if (ReferenceList.length > 0) {
            references = ReferenceList.map(reference => {
                return this.renderReference(reference);
            });
        }

        return(
            <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                <Text style={{textAlign: "center", paddingBottom: 15, fontWeight: "bold", fontSize: 16}}>
                    Use Existing Reference
                </Text>
                { references }
            </View>
        )
    }
}

ReferenceList.propTypes = {
    ReferenceList: PropTypes.array,
    AddReference: PropTypes.func
};