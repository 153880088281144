import React, {createContext, useReducer} from 'react'
import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    links: null,
    openMenuGroups: [],
}

export const navigationMenu = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetNavigation:
            return {...state, links: action.payload}
        case ActionTypes.SetOpenMenuGroups:
            return {...state, openMenuGroups: action.payload}
        case ActionTypes.ClearUserData:
                return {...state, ...initialState}
        default:
            return state
    }
}

export default navigationMenu
