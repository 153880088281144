import ABAPortalAPI, {setHeaderABAID, setHeaderAuthToken} from '../helpers/AxiosInstance'
import {ActionTypes} from '../helpers/generalEnums'
import HelperMethods from '../helpers/HelperMethods'

export const setAuthToken = (newAuthToken) => (dispatch, store) => {
    const {authToken} = store().settings
    const action = {
        type: ActionTypes.SetAuthToken,
        payload: newAuthToken,
    }
    if (authToken !== newAuthToken || authToken === null) {
        setHeaderAuthToken(newAuthToken)
        dispatch(action)
    }
}

export const setABAID = (newABAID) => (dispatch, store) => {
    const {ABAID} = store().settings
    const action = {
        type: ActionTypes.SetABAID,
        payload: newABAID,
    }
    if (ABAID !== newABAID && newABAID !== null) {
        setHeaderABAID(newABAID)
        dispatch(action)
    }
}

export const setKARYear = (KARYear) => (dispatch) => {
    const action = {
        type: ActionTypes.SetKARYear,
        payload: KARYear,
    }
    dispatch(action)
}

export const setUserRole = (userRole) => (dispatch) => {
    const action = {
        type: ActionTypes.SetUserRole,
        payload: userRole,
    }

    dispatch(action)
}

export const setUserData = (userData) => (dispatch, store) => {
    const {hasBackDoorAccess} = store().userData
    const action = {
        type: ActionTypes.SetUserData,
        payload: userData,
    }
    if (hasBackDoorAccess !== userData.hasBackDoorAccess) {
        dispatch(action)
    }
}

export const clearUserData = () => (dispatch) => {
    const action = {
        type: ActionTypes.ClearUserData,
    }
    dispatch(action)
}

export const clearSettings = () => (dispatch) => {
    const action = {
        type: ActionTypes.ClearSettings,
    }
    setHeaderABAID(undefined)
    dispatch(action)
}

export const setCurrentProgressTab = (currentTab) => (dispatch) => {
    const action = {
        type: ActionTypes.SetCurrentProgressTab,
        payload: currentTab,
    }
    dispatch(action)
}

export const setBiometricsAvailable = (available, type) => (dispatch) => {
    const action = {
        type: ActionTypes.SetBiometricsAvailable,
        payload: {available, type, friendlyType: HelperMethods.AddSpaceBefore(type, 'ID')},
    }
    dispatch(action)
}
export const setBiometricsEnabled = (enabled) => (dispatch) => {
    const action = {
        type: ActionTypes.SetBiometricsEnabled,
        payload: enabled,
    }
    dispatch(action)
}

export const setBiometricsSeen = (seen) => (dispatch) => {
    const action = {
        type: ActionTypes.SetBiometricsSeen,
        payload: seen,
    }
    dispatch(action)
}

export const togglePendingPayment = (isProcessing) => (dispatch, store) => {
    const {pendingPayment} = store().registration
    if (pendingPayment === isProcessing) return
    const action = {
        type: ActionTypes.TogglePendingPayment,
        payload: isProcessing,
    }
    dispatch(action)
}

export const setRegistrationLoading = (isLoading) =>({
        type: ActionTypes.RegistrationLoading,
        payload: isLoading,
})

export const setPaymentOffline = (isPaymentOffline) => (dispatch) => {
    const action = {
        type: ActionTypes.SetPaymentOffline,
        payload: isPaymentOffline,
    }
    dispatch(action)
}

export const setNavigation = (Navigation) => (dispatch) => {
    const action = {
        type: ActionTypes.SetNavigation,
        payload: Navigation,
    }
    dispatch(action)
}

export const setOpenMenuGroups = (openMenuGroups) => (dispatch) => {
    const action = {
        type: ActionTypes.SetOpenMenuGroups,
        payload: openMenuGroups,
    }
    dispatch(action)
}

export const toggleActivityReminder = (value) => (dispatch, store) => {
    const {inactivityAlert} = store().userData
    if (inactivityAlert === value) return
    const action = {
        type: ActionTypes.ToggleActivityReminder,
        payload: value,
    }
    dispatch(action)
}

export const setLogoutNoticeTime = (time) => (dispatch) => {
    const action = {
        type: ActionTypes.SetLogoutNoticeTime,
        payload: time,
    }
    dispatch(action)
}
