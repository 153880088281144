import axios from 'axios'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

const PersonalInfoLogic = {
    context: null,

    getAddressTypes: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getAddressTypes')
                .then(function (response) {
                    let addressTypes = response.data
                    addressTypes = addressTypes.filter(
                        (addressType) =>
                            addressType.label === 'Personal' || addressType.label === 'Professional',
                    )
                    resolve(addressTypes)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Address Types')
                    resolve(null)
                })
        })
    },

    getCountries: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getCountries')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Countries')
                    resolve(null)
                })
        })
    },

    getGenders: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getGenders')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Genders')
                    resolve(null)
                })
        })
    },

    getEthnicities: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getEthnicities')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Ethnicities')
                    resolve(null)
                })
        })
    },

    getMedicalSchool: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('doctorInformation/getMedicalSchoolInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Medical School')
                    resolve(null)
                })
        })
    },

    getPersonalInfo: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('doctorInformation/getPersonalInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Personal Information')
                    resolve(null)
                })
        })
    },

    getRaceEthnicityOptions: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('doctorInformation/getRaceEthnicityOptions')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Race/Ethnicity Options')
                    resolve(null)
                })
        })
    },

    getRaceInfo: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('doctorInformation/getRaceInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Race/Ethnicity Options')
                    resolve(null)
                })
        })
    },

    saveAsEntered: function () {
        PersonalInfoLogic.context.setState({ShowStrikeIronScreen: false})
        PersonalInfoLogic.submitPersonalInfo()
    },

    saveAsFound: function (context) {
        PersonalInfoLogic.context.setState(
            {
                AddressLine1: context.state.si_AddressLine1,
                AddressLine2: context.state.si_AddressLine2,
                City: context.state.si_City,
                PostalCode: context.state.si_ZipCode,
                StateId: context.state.si_StateSelected,
            },
            () => {
                PersonalInfoLogic.submitPersonalInfo()
            },
        )
    },

    getStatesProvinces: function (country = GeneralEnums.cert_CountryId['USA']) {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getStates?countryId=' + country)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving States')
                    resolve(null)
                })
        })
    },

    getAllData: function (RaceEthnicityConfirmation) {
        PersonalInfoLogic.context.setState({
            CallingApi: true,
            activityText: 'Loading Personal Information',
        })

        PersonalInfoLogic.getPersonalInfo().then((info) => {
            PersonalInfoLogic.getStatesProvinces(info.CountryId).then((states) => {
                PersonalInfoLogic.getCountries().then((countries) => {
                    PersonalInfoLogic.getGenders().then((genderOptions) => {
                        PersonalInfoLogic.getEthnicities().then((ethnicityOptions) => {
                            PersonalInfoLogic.getRaceEthnicityOptions().then((raceEthnicityOptions) => {
                                PersonalInfoLogic.getAddressTypes().then((addressTypeOptions) => {
                                    PersonalInfoLogic.getRaceInfo().then((raceInfo) => {
                                        let raceEthnicityOtherText = raceInfo.find(
                                            (reInfo) => reInfo.OptionName === 'Other',
                                        )
                                        if (raceInfo && raceEthnicityOtherText) {
                                            raceEthnicityOtherText =
                                                raceEthnicityOtherText.OtherRaceEthnicityText
                                        } else {
                                            raceEthnicityOtherText = ''
                                        }

                                        raceEthnicityOptions.map((options) => {
                                            // if user has race/ethnicity data, we want to mark their selection and keep their RaceId in case the user edits their info
                                            if (
                                                raceInfo &&
                                                raceInfo.some(
                                                    (reInfo) => reInfo.OptionId === options.OptionId,
                                                )
                                            ) {
                                                options.selected = true
                                                options.RaceId = raceInfo.find(
                                                    (reInfo) => reInfo.OptionId === options.OptionId,
                                                ).RaceId
                                            } else {
                                                options.selected = false
                                                options.RaceId = null
                                            }
                                        })

                                        PersonalInfoLogic.context.setState(
                                            {
                                                addressTypeOptions,
                                                Countries: countries,
                                                states: states,
                                                genderOptions,
                                                ethnicityOptions,
                                                raceEthnicityOptions,
                                                raceEthnicityOtherText,
                                                originalRaceEthnicityOtherText: raceEthnicityOtherText,
                                            },
                                            () => {
                                                PersonalInfoLogic.context.setState(
                                                    {
                                                        originalAddress: {
                                                            AddressLine1: info.AddressLine1,
                                                            AddressLine2: info.AddressLine2 || '',
                                                            City: info.City,
                                                            CountryId: info.CountryId,
                                                            PostalCode: info.PostalCode,
                                                            StateId: info.StateId,
                                                            StateProvinceForeign:
                                                                info.StateProvinceForeign,
                                                        },
                                                        AddressLine1: info.AddressLine1,
                                                        AddressLine2: info.AddressLine2 || '',
                                                        City: info.City,
                                                        CountryId:
                                                            info.CountryId ||
                                                            GeneralEnums.cert_CountryId['USA'],
                                                        PostalCode: info.PostalCode,
                                                        StateId: info.StateId,
                                                        StateProvinceForeign: info.StateProvinceForeign,
                                                        AddressId: info.AddressId,
                                                        AddressType: info.AddressType,
                                                        BusinessPhone: info.BusinessPhone,
                                                        DateOfBirth: info.DateOfBirth,
                                                        Department: info.Department,
                                                        FaxNumber: info.FaxNumber,
                                                        FullName: info.FullName,
                                                        // Need to handle if users have 'Unspecified' selected for Gender, which we are no longer showing
                                                        Gender:
                                                            info.Gender === 200000 ? null : info.Gender,
                                                        HomePhone: info.HomePhone,
                                                        MobilePhone: info.MobilePhone,
                                                        NPINumber: info.NPINumber,
                                                        Organization: info.Organization,
                                                        PrimaryEmail: info.PrimaryEmail,
                                                        StateId: info.StateId,
                                                        StateProvinceForeign: info.StateProvinceForeign,
                                                        CallingApi: false,
                                                        OriginalEthnicitySelection: {
                                                            EthnicityId: info.EthnicityId,
                                                            EthnicityValue: info.EthnicityValue,
                                                        },
                                                        selectedEthnicityOption: info.EthnicityValue,
                                                    },
                                                    () => {
                                                        //Determine if NPI Text Input should be enabled or disabled
                                                        if (PersonalInfoLogic.context.state.NPINumber) {
                                                            const inputStatus =
                                                                PersonalInfoLogic.context.state
                                                                    .InputStatus
                                                            inputStatus.NPINumber =
                                                                GeneralEnums.textInputStatus.disabled
                                                            PersonalInfoLogic.context.setState({
                                                                InputStatus: inputStatus,
                                                            })
                                                        }
                                                    },
                                                )
                                            },
                                        )
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    },

    verifyAddress: () => {
        const {AddressLine1, AddressLine2, City, CountryId, PostalCode, StateId, StateProvinceForeign} =
            PersonalInfoLogic.context.state
        const enteredAddress = {
            AddressLine1,
            AddressLine2,
            City,
            CountryId,
            PostalCode,
            StateId,
            StateProvinceForeign,
        }

        if (
            CountryId === GeneralEnums.cert_CountryId.USA ||
            CountryId === GeneralEnums.cert_CountryId.Canada
        ) {
            if (
                JSON.stringify(enteredAddress) !==
                JSON.stringify(PersonalInfoLogic.context.state.originalAddress)
            ) {
                PersonalInfoLogic.context.setState({ShowStrikeIronScreen: true})
            } else {
                PersonalInfoLogic.submitPersonalInfo()
            }
        } else {
            PersonalInfoLogic.submitPersonalInfo()
        }
    },

    submitPersonalInfo: function () {
        PersonalInfoLogic.context.setState({ShowStrikeIronScreen: false})
        const {
            raceEthnicityOptions,
            raceEthnicityOtherText,
            originalRaceEthnicityOtherText,
            OriginalEthnicitySelection,
            selectedEthnicityOption,
            ethnicityOptions,
        } = PersonalInfoLogic.context.state

        let postData = {
            AddressId: PersonalInfoLogic.context.state.AddressId,
            AddressLine1: PersonalInfoLogic.context.state.AddressLine1,
            AddressLine2: PersonalInfoLogic.context.state.AddressLine2,
            AddressType: PersonalInfoLogic.context.state.AddressType,
            BusinessPhone: PersonalInfoLogic.context.state.BusinessPhone,
            City: PersonalInfoLogic.context.state.City,
            CountryId: PersonalInfoLogic.context.state.CountryId,
            DateOfBirth: PersonalInfoLogic.context.state.DateOfBirth,
            Department: PersonalInfoLogic.context.state.Department,
            EthnicityValue: selectedEthnicityOption,
            FaxNumber: PersonalInfoLogic.context.state.FaxNumber,
            FullName: PersonalInfoLogic.context.state.FullName,
            Gender: PersonalInfoLogic.context.state.Gender,
            HomePhone: PersonalInfoLogic.context.state.HomePhone,
            MobilePhone: PersonalInfoLogic.context.state.MobilePhone,
            NPINumber: PersonalInfoLogic.context.state.NPINumber,
            Organization: PersonalInfoLogic.context.state.Organization,
            PostalCode: PersonalInfoLogic.context.state.PostalCode,
            PrimaryEmail: PersonalInfoLogic.context.state.PrimaryEmail,
            StateId: PersonalInfoLogic.context.state.StateId,
            StateProvinceForeign: PersonalInfoLogic.context.state.StateProvinceForeign,
        }

        let racePostData = []
        let ethnicityPostData = []
        const findEthnicityOptionName = (value) => {
            const matchingOption = ethnicityOptions.find((option) => option.value == value)
            return matchingOption.label
        }

        raceEthnicityOptions.map((option) => {
            // new race/ethnicity checked
            if (option.selected && !option.RaceId) {
                racePostData.push({
                    OptionId: option.OptionId,
                    RaceId: null,
                    OtherRaceEthnicityText: option.Name === 'Other' ? raceEthnicityOtherText : null,
                })
            }

            // user previously selected race/ethnicity but it is no longer checked OR option is no longer active
            if ((!option.selected && option.RaceId) || (option.selected && option.StateCode === 1)) {
                racePostData.push({
                    OptionId: option.OptionId,
                    RaceId: option.RaceId,
                    OtherRaceEthnicityText:
                        option.Name === 'Other' ? originalRaceEthnicityOtherText : null,
                })
            }

            // user previously selected 'Other', 'Other' is still checked but there is new text in the input field
            if (
                option.selected &&
                option.RaceId &&
                option.Name === 'Other' &&
                raceEthnicityOtherText !== originalRaceEthnicityOtherText
            ) {
                racePostData.push({
                    OptionId: option.OptionId,
                    RaceId: option.RaceId,
                    OtherRaceEthnicityText: originalRaceEthnicityOtherText,
                })

                racePostData.push({
                    OptionId: option.OptionId,
                    RaceId: null,
                    OtherRaceEthnicityText: raceEthnicityOtherText,
                })
            }
        })

        if (
            OriginalEthnicitySelection.EthnicityId &&
            OriginalEthnicitySelection.EthnicityValue != selectedEthnicityOption
        ) {
            ethnicityPostData.push({
                EthnicityId: OriginalEthnicitySelection.EthnicityId,
                EthnicityValue: OriginalEthnicitySelection.EthnicityValue,
                EthnicityLabel: findEthnicityOptionName(OriginalEthnicitySelection.EthnicityValue),
            })
            ethnicityPostData.push({
                EthnicityId: null,
                EthnicityValue: selectedEthnicityOption,
                EthnicityLabel: findEthnicityOptionName(selectedEthnicityOption),
            })
        } else if (!OriginalEthnicitySelection.EthnicityId) {
            ethnicityPostData.push({
                EthnicityId: null,
                EthnicityValue: selectedEthnicityOption,
                EthnicityLabel: findEthnicityOptionName(selectedEthnicityOption),
            })
        }

        //If user's country has a selectable dropdown of states, then they should not have anything submitted for StateProvinceForeign.  Vice versa for non-North American and StateId
        if (PersonalInfoLogic.context.state.states.length > 0) {
            postData.StateProvinceForeign = null
        } else {
            postData.StateId = null
        }

        PersonalInfoLogic.context.setState({
            CallingApi: true,
            activityText: 'Saving Personal Information',
        })
        axios
            .all([
                ABAPortalAPI.post('doctorInformation/savePersonalInfo', postData),
                ABAPortalAPI.post('doctorInformation/saveRaceInfo', racePostData),
                ABAPortalAPI.post('doctorInformation/saveEthnicityInfo', ethnicityPostData),
            ])
            .then(function (response) {
                HelperMethods.alertAgnostic(
                    'Your Personal Information has been updated.',
                    'Personal Info Updated Successfully',
                    [{text: 'Ok'}],
                )

                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/dashboard')
            })
            .catch(function (error) {
                HelperMethods.apiErrorHandler(error, 'Error Updating Personal Info')
            })
            .finally(function () {
                PersonalInfoLogic.context.setState({CallingApi: false})
            })
    },

    validateFormData: function (componentRef) {
        let inputStatus = PersonalInfoLogic.context.state.InputStatus
        let error = GeneralEnums.textInputStatus.error

        // We need to change back any text inputs that had an error to default.  This is because  on re-submittal,
        // some required fields could have been filled out but others were missed.  We need those former errors
        // set back to default.  This is essentially giving us a clean pallete to work with each submittal.
        for (let key in inputStatus) {
            if (inputStatus[key] === GeneralEnums.textInputStatus.error) {
                inputStatus[key] = GeneralEnums.textInputStatus.default
            }
        }

        // Each if statement below is validating that all required fields have been filled
        if (
            !PersonalInfoLogic.context.state.PrimaryEmail ||
            !ValidationHelper.isEmailValid(PersonalInfoLogic.context.state.PrimaryEmail)
        ) {
            inputStatus.PrimaryEmail = error
        }

        if (
            !PersonalInfoLogic.context.state.Gender ||
            PersonalInfoLogic.context.state.Gender == 'null'
        ) {
            inputStatus.Gender = error
        }

        if (
            (!PersonalInfoLogic.context.state.HomePhone &&
                PersonalInfoLogic.context.state.AddressType == 1) ||
            (PersonalInfoLogic.context.state.HomePhone &&
                !ValidationHelper.isPhoneNumberValid(PersonalInfoLogic.context.state.HomePhone))
        ) {
            inputStatus.HomePhone = error
        }

        if (!PersonalInfoLogic.context.state.selectedEthnicityOption) {
            inputStatus.Ethnicity = error
        }

        // if no race/ethnicty checkboxes are selected, we change the status to error
        if (
            !PersonalInfoLogic.context.state.raceEthnicityOptions.some(
                (raceEthnicity) => raceEthnicity.selected === true,
            )
        ) {
            inputStatus.RaceEthnicity = error
        }

        // if 'Other' is selected but the user didn't fill out the text input, then we change the status to error
        if (
            PersonalInfoLogic.context.state.raceEthnicityOptions.some(
                (raceEthnicity) => raceEthnicity.Name === 'Other' && raceEthnicity.selected === true,
            ) &&
            !PersonalInfoLogic.context.state.raceEthnicityOtherText
        ) {
            inputStatus.RaceEthnicity = error
            inputStatus.RaceEthnicityOtherText = error
        }

        if (
            (!PersonalInfoLogic.context.state.BusinessPhone &&
                PersonalInfoLogic.context.state.AddressType == 2) ||
            (PersonalInfoLogic.context.state.BusinessPhone &&
                !ValidationHelper.isPhoneNumberValid(PersonalInfoLogic.context.state.BusinessPhone))
        ) {
            inputStatus.BusinessPhone = error
        }

        if (
            PersonalInfoLogic.context.state.MobilePhone &&
            !ValidationHelper.isPhoneNumberValid(PersonalInfoLogic.context.state.MobilePhone)
        ) {
            inputStatus.MobilePhone = error
        }

        if (
            PersonalInfoLogic.context.state.FaxNumber &&
            !ValidationHelper.isPhoneNumberValid(PersonalInfoLogic.context.state.FaxNumber)
        ) {
            inputStatus.FaxNumber = error
        }

        if (
            !PersonalInfoLogic.context.state.AddressType ||
            PersonalInfoLogic.context.state.AddressType === 'null'
        ) {
            inputStatus.AddressType = error
        }

        if (
            PersonalInfoLogic.context.state.AddressType == 2 &&
            (!PersonalInfoLogic.context.state.Organization ||
                !PersonalInfoLogic.context.state.Department)
        ) {
            if (!PersonalInfoLogic.context.state.Organization) {
                inputStatus.Organization = error
            }
            if (!PersonalInfoLogic.context.state.Department) {
                inputStatus.Department = error
            }
        }

        if (!PersonalInfoLogic.context.state.AddressLine1) {
            inputStatus.AddressLine1 = error
        }

        if (!PersonalInfoLogic.context.state.City) {
            inputStatus.City = error
        }

        //if user has selected a country that has a dropdown of states, we're checking to make sure they have submitted a state/province as well.
        if (
            PersonalInfoLogic.context.state.states.length > 0 &&
            (!PersonalInfoLogic.context.state.StateId ||
                PersonalInfoLogic.context.state.StateId === 'null')
        ) {
            inputStatus.StateId = error
        }

        //if user has selected a country and it does not have a dropdown of states, we're checking to make sure they have filled out the field for StateProvinceForeign.
        if (
            PersonalInfoLogic.context.state.CountryId &&
            PersonalInfoLogic.context.state.states.length < 1 &&
            !PersonalInfoLogic.context.state.StateProvinceForeign
        ) {
            inputStatus.StateProvinceForeign = error
        }

        if (!PersonalInfoLogic.context.state.PostalCode) {
            inputStatus.PostalCode = error
        }

        if (
            !PersonalInfoLogic.context.state.CountryId ||
            PersonalInfoLogic.context.state.CountryId === 'null'
        ) {
            inputStatus.CountryId = error
        }

        //if our inputStatuses contain an error, then we will return false which will alert the user of form errors.
        for (let key in inputStatus) {
            if (inputStatus[key] === GeneralEnums.textInputStatus.error) {
                PersonalInfoLogic.context.setState({InputStatus: inputStatus}, () => {
                    componentRef.scrollTo({
                        x: 0,
                        y: 0,
                        animated: false,
                    })

                    HelperMethods.alertAgnostic(
                        'Please correctly fill out all required fields',
                        'Form Validation Error',
                        [{text: 'Ok'}],
                    )
                })
                return
            }
        }

        // once we've made it this far in form validation, all fields have passed and we can verify our address
        this.verifyAddress()
    },
}

export default PersonalInfoLogic
