import moment from 'moment'
import {GeneralEnums} from './generalEnums'

const ValidationHelper = {
    isAbaIdFormatValid(abaId) {
        if (typeof abaId === 'string') {
            abaId = abaId.trim()
        }

        let regExAbaId = /^(\d{4}-\d{4})$|^(\d{8})$/

        if (!regExAbaId.test(abaId)) {
            return false
        }

        return true
    },

    isDateOfBirthFormatValid: function (dateOfBirth) {
        dateOfBirth = dateOfBirth.trim()

        //https://regex101.com/r/DUMAZA/2
        let regExDateOfBirth =
            /^((([1-9]|1[012]|0[1-9])[\/-]([1-9]|1\d|2[0-8]|0[1-9])|(0[13456789]|[13456789]|1[012])[\/-](29|30)|(0[13578]|[13578]|1[02])[\/-]31)[\/-][1-9]\d{3}|(02|2)[\/-]29[\/-](([1-9]\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$/
        let today = moment(new Date()).format('MM/DD/YYYY')

        if (!regExDateOfBirth.test(dateOfBirth)) {
            return false
        }

        if (new Date(dateOfBirth) > new Date() || dateOfBirth === today) {
            return false
        }

        return true
    },

    isEmailValid: function (email) {
        email = email.trim()

        let regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/

        if (!regExEmail.test(email)) {
            return false
        }

        return true
    },

    isLastNameFormatValid: function (lastName) {
        lastName = lastName.trim()

        //https://regex101.com/r/Oaqy0F/4
        let regExLastName =
            /^([a-z]+-[a-z]+)$|^([a-z]+'[a-z]+)$|^([a-z]+ [a-z]+)$|^([a-z]+\. *[a-z]+)$|^([a-z]+)$/i

        if (!regExLastName.test(lastName)) {
            return false
        }

        return true
    },

    isPhoneNumberValid: function (phoneNumber) {
        phoneNumber = phoneNumber.replace(/\D/g, '')

        if (phoneNumber.length < 10) {
            return false
        }

        return true
    },

    isPasswordFormatValid: function (password) {
        let regExHasAtLeastOneNumber = /\d+/
        let regExHasAtLeastOneUppercaseLetter = /[A-Z]+/
        let regExHasAtLeastOneLowercaseLetterOrSpecialCharacter =
            /[a-z]|[`~!@#$%^&*\(\)\-_=+{}\[\]\\\|;:'",<\.>\/\?]+/

        return !(
            password.length < 7 ||
            password.length > 20 ||
            !regExHasAtLeastOneNumber.test(password) ||
            !regExHasAtLeastOneUppercaseLetter.test(password) ||
            !regExHasAtLeastOneLowercaseLetterOrSpecialCharacter.test(password)
        )
    },

    isSSNFormatValid: function (ssn) {
        ssn = ssn.trim()

        let regExSsn = /^(\d{4})$/

        if (!regExSsn.test(ssn)) {
            return false
        }

        return true
    },
    isDate: function (date, dateFormat = moment.ISO_8601) {
        var dateEntered = moment(date, dateFormat, true)
        return dateEntered.isValid()
    },

    isDateRangeValid: function (beginDate, endDate, canEqual = true) {
        const begin = moment(beginDate, 'MM/DD/YYY', true)
        const end = moment(endDate, 'MM/DD/YYY', true)
        if (canEqual) {
            return end.isSameOrAfter(begin)
        }

        return end.isAfter(begin)
    },

    isDateFormatValid: function (date, dateCheckType = GeneralEnums.DateCheckType.NoCheck) {
        if (!date || date.length !== 10) return false

        const today = moment(new Date(), 'MM/DD/YYY', true)
        var dateEntered = moment(date, 'MM/DD/YYYY', true)
        var minDateAllowed = moment('01/01/1900', 'MM/DD/YYYY', true)
        if (!dateEntered.isValid() || dateEntered.isBefore(minDateAllowed)) {
            return false
        }

        var dateChecksSettings = ValidationHelper.CreateDateCheckSettings(dateCheckType)
        var dateCheckPassed = {
            Today: true,
            Future: true,
            Past: true,
        }

        if (dateEntered.isBefore(today, 'day') && !dateChecksSettings.allowPast) {
            dateCheckPassed.Past = false
        }

        if (dateEntered.isAfter(today, 'day') && !dateChecksSettings.allowFuture) {
            dateCheckPassed.Future = false
        }

        if (dateEntered.isSame(today, 'day') && !dateChecksSettings.allowToday) {
            dateCheckPassed.Today = false
        }

        var allChecksPassed = Object.keys(dateCheckPassed).every(
            (check) => dateCheckPassed[check] === true,
        )
        return allChecksPassed
    },

    CreateDateCheckSettings: function (checkType) {
        var check = {
            allowFuture: true,
            allowToday: true,
            allowPast: true,
        }
        switch (checkType) {
            case GeneralEnums.DateCheckType.NoCheck:
                return check
            case GeneralEnums.DateCheckType.TodayAndFutureAllowed:
                check.allowToday = true
                check.allowFuture = true
                check.allowPast = false
                return check
            case GeneralEnums.DateCheckType.TodayOnly:
                check.allowToday = true
                check.allowFuture = false
                check.allowPast = false
                return check
            case GeneralEnums.DateCheckType.TodayAndPastOnly:
                check.allowToday = true
                check.allowFuture = false
                check.allowPast = true
                return check
            case GeneralEnums.DateCheckType.PastOnly:
                check.allowToday = false
                check.allowFuture = false
                check.allowPast = true
                return check
            case GeneralEnums.DateCheckType.FutureOnly:
                check.allowToday = false
                check.allowFuture = true
                check.allowPast = false
                return check
            default:
                return check
        }
    },
}

export default ValidationHelper
