import React, {Component} from 'react'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import CMEExplorer from './cmeExplorer'

export default class CMEExplorerContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        HelperMethods.setNavigationData(this)
        let navData = {
            showKeywordSearchTab: this.navigationData.showKeywordSearchTab === false ? false : true,
            tier1Id: this.navigationData.tier1Id,
            tier3Id: this.navigationData.tier3Id,
        }

        let knowledgeAssessmentState = this.navigationData.knowledgeAssessmentState

        return (
            <NavigationShell context={this}>
                <CMEExplorer navData={navData} knowledgeAssessmentState={knowledgeAssessmentState} />
            </NavigationShell>
        )
    }
}
