import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Text, View} from 'react-native';
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums';

export default class ABAValidationView extends Component {

    getBorderProperties() {
        let borderWidth = 0
        let borderColor = ""

        switch (this.props.inputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                borderColor = ColorEnums.blueGray;
                break;
            case GeneralEnums.textInputStatus.error:
                borderWidth = 1
                borderColor = ColorEnums.pomegranate;
                break;
            case GeneralEnums.textInputStatus.success:
                borderWidth = 1
                borderColor = ColorEnums.surfGreen;
                break;
            case GeneralEnums.textInputStatus.default:
                borderColor = ""
                break;
        }

        const border = {borderWidth: this.props.showBorder ? borderWidth : 0, borderColor: borderColor}
        return border
    }

    renderIcon() {
        let targetIcon = null;

        switch (this.props.inputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetIcon = IconEnums["lock"];
                break;
            case GeneralEnums.textInputStatus.error:
                targetIcon = IconEnums["times"];
                break;
            case GeneralEnums.textInputStatus.success:
                targetIcon = IconEnums["check"];
                break;
            case GeneralEnums.textInputStatus.default:
            default:
                targetIcon = null;
                break;
        }

        return (
            <View >
                <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName, color: this.getBorderProperties().borderColor, fontSize: 14, paddingLeft: 4, paddingTop: 2}}>{targetIcon}</Text>
            </View>
        );
    }


    render() {
        const {style, children, inputStatus, errorMessage} = this.props
        return (<View style={{...this.getBorderProperties(), padding: 10, borderRadius: 5}}>
            <View style={{paddingBottom: 10, ...style}}>
                {children && [...children]}
            </View>
            <View style={{alignSelf: "center", flexDirection: "row", width: "auto"}}>
                {inputStatus === GeneralEnums.textInputStatus.error &&
                    <Text style={{color: ColorEnums.pomegranate, }}>
                        {errorMessage}
                    </Text>
                }
                {this.renderIcon()}
            </View>
        </View>)
    }
}

ABAValidationView.propTypes = {
    errorMessage: PropTypes.string,
    inputStatus: PropTypes.number,
    showBorder: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    children: PropTypes.any
};