import React, {Component} from 'react'
import {Text, TouchableWithoutFeedback, View} from 'react-native'

import ABAMMBarChart from '../ABACustom/presentation/ABAMMBarChart'
import ABAContainer from '../ABACustom/presentation/ABAContainer'
import ABAPerformanceStandard from '../ABACustom/presentation/ABAPerformanceStandard'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import StyleFunctions from '../../styles/styleFunctions'
import MyProgressLinks from './myProgressLinks'

export default class MyProgressMM extends Component {
    state = {
        CallingApi: false,
        questionsAnswered: 0,   
    }

    componentDidUpdate(previousProps) {
        if (this.props.MMData !== previousProps.MMData) {
            const {AnnualMaxQuestions, AnnualQuestionsRemaining} = this.props.MMData
            this.setState({questionsAnswered: AnnualMaxQuestions - AnnualQuestionsRemaining})  
        }
    }

    setFilledHeight(quarterlyQuestion) { 
        return `${(quarterlyQuestion / 30) * 100}%`  
    }

    setTextColor(mocaRegistrationStatus, currentQuarter, quarter) {
        if (
            mocaRegistrationStatus == GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA &&
            currentQuarter >= quarter
        ) {
            return ColorEnums.cloudBurst
        } else if (
            mocaRegistrationStatus == GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA &&
            currentQuarter < quarter
        ) {
            return ColorEnums.blueGray
        }

        return ColorEnums.lightGray
    }

    setLinkTextColor(registrationStatus) {
        if (registrationStatus != GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA) {
            return ColorEnums.lightGray
        } else {
            return ColorEnums.danube
        }
    }

showQuarterlyQuestions(QuarterlyQuestionsRemaining){
    if(this.props.location && this.props.location.pathname === '/myProgressReport' && QuarterlyQuestionsRemaining ) {
        return (
            <Text 
                style={{
                    textAlign: 'center', 
                    fontFamily: 'Lato', 
                    color: ColorEnums.cloudBurst,
                    marginBottom: 30,
                }}
            >
                {`You have ${QuarterlyQuestionsRemaining} ${QuarterlyQuestionsRemaining === 1 ? 'question' : 'questions'} left this quarter.`}
            </Text>
        )
    }
}

    render() {
        const {
            AnnualMaxQuestions,
            CurrentQuarter,
            MocaRegistrationStatus,
            QuarterlyQuestionsRemaining,
            Q1Answered,
            Q2Answered,
            Q3Answered,
            Q4Answered,
        } = this.props.MMData

        return (
            <ABAContainer
                activityText={'Loading MOCA Minute Data'}
                showActivityIndicator={this.state.CallingApi}
                showContainerTitleBar={false}
                useMaxWidth={false}
                overrideWidth={!this.props.dashboard}
            >
                {this.showQuarterlyQuestions(QuarterlyQuestionsRemaining)}
                <View
                    style={{
                        flexDirection: 'row',
                        alignContent: 'center',
                        paddingHorizontal: 25,
                    }}
                >
                    <View
                        style={{
                            width: '25%',
                            maxWidth: '25%',
                            alignItems: 'center',
                            paddingHorizontal: 10,
                        }}
                    >
                        <Text
                            style={[
                                StyleFunctions.containerTextBold(),
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingBottom: 3,
                                    fontSize: 14,
                                    color: this.setTextColor(MocaRegistrationStatus, CurrentQuarter, 1),
                                },
                            ]}
                        >
                            30
                        </Text>
                        <ABAMMBarChart
                            annualMaxQuestions={AnnualMaxQuestions}
                            questionsAnswered={Q1Answered}
                            registrationStatus={MocaRegistrationStatus}
                            size={{height: this.setFilledHeight(Q1Answered), width: '75%'}}
                        />

                        <View style={{paddingTop: 3}}>
                            <Text
                                style={[
                                    StyleFunctions.containerTextBold(),
                                    {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: ColorEnums.lightGray,
                                        fontSize: 12,
                                    },
                                ]}
                            >
                                Q1
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            width: '25%',
                            maxWidth: '25%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingHorizontal: 10,
                        }}
                    >
                        <Text
                            style={[
                                StyleFunctions.containerTextBold(),
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingBottom: 3,
                                    fontSize: 14,
                                    color: this.setTextColor(MocaRegistrationStatus, CurrentQuarter, 2),
                                },
                            ]}
                        >
                            60
                        </Text>
                        <ABAMMBarChart
                            annualMaxQuestions={AnnualMaxQuestions}
                            questionsAnswered={Q2Answered}
                            registrationStatus={MocaRegistrationStatus}
                            size={{height: this.setFilledHeight(Q2Answered), width: '75%'}}
                        />
                        <View style={{paddingTop: 3}}>
                            <Text
                                style={[
                                    StyleFunctions.containerTextBold(),
                                    {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: ColorEnums.lightGray,
                                        fontSize: 12,
                                    },
                                ]}
                            >
                                Q2
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            width: '25%',
                            maxWidth: '25%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingHorizontal: 10,
                        }}
                    >
                        <Text
                            style={[
                                StyleFunctions.containerTextBold(),
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingBottom: 3,
                                    fontSize: 14,
                                    color: this.setTextColor(MocaRegistrationStatus, CurrentQuarter, 3),
                                },
                            ]}
                        >
                            90
                        </Text>
                        <ABAMMBarChart
                            annualMaxQuestions={AnnualMaxQuestions}
                            questionsAnswered={Q3Answered}
                            registrationStatus={MocaRegistrationStatus}
                            size={{height: this.setFilledHeight(Q3Answered), width: '75%'}}
                        />
                        <View style={{paddingTop: 3}}>
                            <Text
                                style={[
                                    StyleFunctions.containerTextBold(),
                                    {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: ColorEnums.lightGray,
                                        fontSize: 12,
                                    },
                                ]}
                            >
                                Q3
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            width: '25%',
                            maxWidth: '25%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingHorizontal: 10,
                        }}
                    >
                        <Text
                            style={[
                                StyleFunctions.containerTextBold(),
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingBottom: 3,
                                    fontSize: 14,
                                    color: this.setTextColor(MocaRegistrationStatus, CurrentQuarter, 4),
                                },
                            ]}
                        >
                            120
                        </Text>
                        <ABAMMBarChart
                            annualMaxQuestions={AnnualMaxQuestions}
                            questionsAnswered={Q4Answered}
                            registrationStatus={MocaRegistrationStatus}
                            size={{height: this.setFilledHeight(Q4Answered), width: '75%'}}
                        />
                        <View style={{paddingTop: 3}}>
                            <Text
                                style={[
                                    StyleFunctions.containerTextBold(),
                                    {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: ColorEnums.lightGray,
                                        fontSize: 12,
                                    },
                                ]}
                            >
                                Q4
                            </Text>
                        </View>
                    </View>
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        width: 300,
                        justifyContent: 'center',
                        alignSelf: 'center',
                        paddingTop: 5,
                    }}
                >
                    <View style={{width: '50%'}}>
                        <Text
                            style={{
                                color: ColorEnums.surfGreen,
                                fontSize: 20,
                                width: '100%',
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                textAlign: 'center',
                            }}
                        >
                            {IconEnums['circle']}
                            <Text style={[StyleFunctions.containerTextBold(), {fontSize: 12}]}>
                                {' '}
                                Answered
                            </Text>
                        </Text>
                    </View>

                    <View style={{width: '50%'}}>
                        <Text
                            style={{
                                color: ColorEnums.lightGray,
                                fontSize: 20,
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            {IconEnums['circle']}
                            <Text style={[StyleFunctions.containerTextBold(), {fontSize: 12}]}>
                                {' '}
                                Remaining
                            </Text>
                        </Text>
                    </View>
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        paddingVertical: 20,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {MocaRegistrationStatus == GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA && (
                        <ABAPerformanceStandard />
                    )}
                </View>
                {this.props.dashboard && (
                    <MyProgressLinks
                        LeftLink={{
                            Icon: 'tasks',
                            Text: 'My Progress Report',
                            Link: '/myProgressReport',
                            Enabled:
                                MocaRegistrationStatus ==
                                GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA,
                        }}
                        RightLink={{
                            Icon: 'history',
                            Text: 'My Question History',
                            Link: '/questionHistory',
                            Enabled:
                                MocaRegistrationStatus ==
                                GeneralEnums.MocaRegistrationStatus.RegisteredForMOCA,
                        }}
                    />
                )}
            </ABAContainer>
        )
    }
}

MyProgressMM.defaultProps = {
    dashboard: true,
}
