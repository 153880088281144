import ABAPortalAPI from '../../helpers/AxiosInstance'
import {Platform} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import ValidationHelper from '../../helpers/ValidationHelper'
import {GeneralEnums} from '../../helpers/generalEnums'

const ReferenceLogic = {
    context: null,

    GetCountryListData() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getCountries')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    this.setState({CallingApi: false})
                    resolve(null)
                })
        })
    },

    GetStateListData(country) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    countryId: country,
                },
            }

            ABAPortalAPI.get('lookups/getStates', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    this.setState({CallingApi: false})
                    resolve(null)
                })
        })
    },

    GetReferenceDropdownData(programType) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    Specialty: programType,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('reference/getReferenceDropdownData', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference Dropdowns')
                    this.context.setState({CallingApi: false})
                    resolve(null)
                })
        })
    },

    GetReference(ReferenceID) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    ReferenceID,
                },
            }

            ABAPortalAPI.get('reference/getReference', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference Information')
                    this.context.setState({CallingApi: false})
                    resolve(null)
                })
        })
    },

    Validate() {
        const {InputStatus} = this.context.state
        const {IsRelapseRef} = this.context.props
        let {error, success} = GeneralEnums.textInputStatus
        const {Relapse_Inpatient, Relapse_Outpatient} = GeneralEnums.cert_referenceType
        let emailFormatInvalid = false

        const {
            referenceType,
            cert_ZipCode,
            stateList,
            cert_StateId,
            cert_City,
            cert_CountryId,
            cert_AddressLine1,
            cert_InstitutionorOrganization,
            cert_Fax,
            cert_OfficePhone,
            cert_Email,
            cert_MedicalDegree,
            cert_LastName,
            cert_FirstName,
            cert_referencepostype,
            aba_referenceclaim,
        } = this.context.state
        HelperMethods.clearValidation(InputStatus)

        if (aba_referenceclaim === 2 && (!cert_referencepostype || cert_referencepostype === 'null')) {
            InputStatus.cert_referencepostype = error
        }

        if (!cert_FirstName) {
            InputStatus.cert_FirstName = error
        }

        if (!cert_LastName) {
            InputStatus.cert_LastName = error
        }

        if (!cert_MedicalDegree || cert_MedicalDegree === 'null') {
            InputStatus.cert_MedicalDegree = error
        }

        if (!cert_Email || !ValidationHelper.isEmailValid(cert_Email)) {
            InputStatus.cert_Email = error
        }

        if (cert_Email && !ValidationHelper.isEmailValid(cert_Email)) {
            emailFormatInvalid = true
        }

        if (!cert_OfficePhone) {
            this.context.setState({officePhoneFormatError: false})
            InputStatus.cert_OfficePhone = error
        } else if (cert_OfficePhone.length < 14) {
            this.context.setState({officePhoneFormatError: true})
            InputStatus.cert_OfficePhone = error
        }

        if (!cert_Fax) {
            this.context.setState({faxFormatError: false})
            InputStatus.cert_Fax = error
        } else if (cert_Fax.length < 14) {
            this.context.setState({faxFormatError: true})
            InputStatus.cert_Fax = error
        }

        if (!cert_InstitutionorOrganization) {
            InputStatus.cert_InstitutionorOrganization = error
        }

        if (!cert_AddressLine1) {
            InputStatus.cert_AddressLine1 = error
        }

        if (!cert_CountryId || cert_CountryId === 'null') {
            InputStatus.cert_CountryId = error
        }

        if (!cert_City) {
            InputStatus.cert_City = error
        }

        if (
            (stateList.length > 0 || InputStatus.cert_CountryId === error) &&
            (!cert_StateId || cert_StateId === 'null')
        ) {
            InputStatus.cert_StateId = error
        }

        if (!cert_ZipCode) {
            InputStatus.cert_ZipCode = error
        }

        if (IsRelapseRef && referenceType != Relapse_Inpatient && referenceType != Relapse_Outpatient) {
            InputStatus.referenceType = error
        }

        let hasErrors = false
        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                hasErrors = true
            } else {
                InputStatus[key] = success
            }
        }

        if (hasErrors) {
            this.context.setState({InputStatus, emailFormatInvalid, hasErrors}, () => {
                this.context.props.saveCallback &&
                    this.context.props.saveCallback(this.context.props.componentIndex, false)
            })
            return false
        }

        this.context.setState({hasErrors})
        return true
    },

    PostReferenceData() {
        return new Promise((resolve) => {
            const {
                aba_referenceclaim,
                cert_AddressLine1,
                cert_AddressLine2,
                cert_City,
                cert_CountryId,
                cert_DeptBox,
                cert_Email,
                cert_referenceId,
                cert_Fax,
                cert_FirstName,
                cert_InstitutionorOrganization,
                cert_LastName,
                cert_MedicalDegree,
                cert_MiddleName,
                cert_OfficePhone,
                cert_Salutation,
                cert_StateId,
                cert_Suffix,
                cert_ZipCode,
                positionList,
                referenceType,
                cert_StateorProvince,
            } = this.context.state

            const cert_Specialty = this.context.props.ProgramType
            const cert_ReferenceType = this.context.props.ReferenceType || referenceType
            const cert_referencepostype = this.checkReferencePositionType(
                this.context.state.cert_referencepostype,
                cert_ReferenceType,
                positionList,
            )
            const referenceUpdateObject = {
                aba_referenceclaim,
                cert_AddressLine1,
                cert_AddressLine2,
                cert_City,
                cert_CountryId: cert_CountryId === 'null' ? null : cert_CountryId,
                cert_DeptBox,
                cert_Email,
                cert_Fax,
                cert_FirstName,
                cert_InstitutionorOrganization,
                cert_LastName,
                cert_MedicalDegree: cert_MedicalDegree === 'null' ? -1 : cert_MedicalDegree,
                cert_MiddleName,
                cert_OfficePhone,
                cert_referencepostype: cert_referencepostype === 'null' ? null : cert_referencepostype,
                cert_ReferenceType,
                cert_Salutation: cert_Salutation === 'null' ? -1 : cert_Salutation,
                cert_Specialty,
                cert_StateId: cert_StateId === 'null' ? null : cert_StateId,
                cert_StateorProvince,
                cert_Suffix: cert_Suffix === 'null' ? -1 : cert_Suffix,
                cert_ZipCode,
            }

            const {IsSubstanceAbuseRef, NewReference, IsRelapseRef} = this.context.props
            if (IsSubstanceAbuseRef) {
                referenceUpdateObject.cert_SubstanceAbuseId =
                    substanceAbuseContext.state.SubstanceAbuseID
                referenceUpdateObject.cert_contactid = substanceAbuseContext.state.ContactID
            }

            if (!IsSubstanceAbuseRef) {
                referenceUpdateObject.cert_clinicalactivityid =
                    clinicalActivityContext.state.ClinicalActivityID
                referenceUpdateObject.cert_contactid = clinicalActivityContext.state.ContactID
            }

            if (IsRelapseRef) {
                referenceUpdateObject.cert_relapseId = this.context.props.SubstanceAbuseSubstanceID
            }

            if (!NewReference) {
                referenceUpdateObject.cert_referenceId = cert_referenceId
            }
            ABAPortalAPI.post('reference/updateReference', referenceUpdateObject)
                .then((response) => {
                    const {InputStatus} = this.context.state
                    for (let key in InputStatus) {
                        InputStatus[key] = GeneralEnums.textInputStatus.success
                    }

                    this.context.setState(
                        {
                            cert_referenceId: response.data,
                            InputStatus,
                            originalReferenceData: {
                                ...referenceUpdateObject,
                                stateList: this.context.state.stateList,
                            },
                        },
                        () => {
                            this.context.props.saveCallback &&
                                this.context.props.saveCallback(this.context.props.componentIndex)
                        },
                    )
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Reference Record')
                    this.setState({CallingApi: false})
                    resolve(false)
                })
        })
    },

    saveAsEntered() {
        this.context.setState({ShowStrikeIronScreen: false})
        this.PostReferenceData()
    },

    saveAsFound(context) {
        this.context.setState(
            {
                cert_AddressLine1: context.state.si_AddressLine1,
                cert_AddressLine2: context.state.si_AddressLine2,
                cert_City: context.state.si_City,
                cert_ZipCode: context.state.si_ZipCode,
                ShowStrikeIronScreen: false,
            },
            () => {
                this.PostReferenceData()
            },
        )
    },

    UpdateReference() {
        if (!this.Validate()) {
            return
        }

        if (
            this.context.state.cert_CountryId === GeneralEnums.cert_CountryId.USA ||
            this.context.state.cert_CountryId === GeneralEnums.cert_CountryId.Canada
        ) {
            this.context.setState({ShowStrikeIronScreen: true})
            return
        } else {
            this.context.setState({
                CallingApi: true,
                saving: true,
            })

            if (Platform.OS !== 'web') {
                registrationStepsDisplayContext.setState({
                    CallingApi: true,
                    overlayText: 'Saving Reference Data',
                })
            }
            this.context.setState({ShowStrikeIronScreen: false})
            this.PostReferenceData().then(() => {
                this.context.setState({CallingApi: false})
                if (Platform.OS !== 'web') {
                    registrationStepsDisplayContext.setState({
                        CallingApi: false,
                        overlayText: null,
                    })
                }
                return
            })
        }
    },

    getStatesProvinces(country) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    countryId: country,
                },
            }

            ABAPortalAPI.get(`lookups/getStates`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving States')
                    this.setState({CallingApi: false})
                    resolve(null)
                })
        })
    },

    SetAba_referenceclaim() {
        let {aba_referenceclaim} = this.context.state
        const cert_referencepostype = 'null'

        if (aba_referenceclaim === 2) {
            this.context.setState({
                aba_referenceclaim: 1,
                cert_referencepostype,
            })
        } else {
            this.context.setState({
                aba_referenceclaim: 2,
                cert_referencepostype,
            })
        }
    },

    checkReferencePositionType(positionType, referenceType, positionList) {
        if (positionType === '' || positionType === 'null') {
            let position
            switch (referenceType) {
                //Chief of Anesthesiology
                case 9:
                    position = positionList.find(
                        (position) => position.label === 'Chief of Anesthesiology',
                    )
                    break
                //Chief of Staff
                case 10:
                    position = positionList.find((position) => position.label === 'Chief of Staff')
                    break
                //Clinical Competence Committee Chair
                case 11:
                    position = positionList.find(
                        (position) => position.label === 'Clinical Competence Committee Chair',
                    )
                    break
                //Other
                case 5:
                    position = positionList.find((position) => position.label === 'Other Reference')
                    break
            }
            if (position) {
                positionType = position.value
            }
        }
        return positionType
    },

    CancelReferenceChanges() {
        const {originalReferenceData} = this.context.state
        this.context.setState(
            {
                stateList: originalReferenceData.stateList,
            },
            () =>
                this.context.setState(
                    {
                        cert_AddressLine1: originalReferenceData.cert_AddressLine1 || '',
                        cert_AddressLine2: originalReferenceData.cert_AddressLine2 || '',
                        cert_City: originalReferenceData.cert_City || '',
                        cert_CountryId: originalReferenceData.cert_CountryId || 'null',
                        cert_DeptBox: originalReferenceData.cert_DeptBox || '',
                        cert_Email: originalReferenceData.cert_Email || '',
                        cert_Fax: originalReferenceData.cert_Fax || '',
                        cert_FirstName: originalReferenceData.cert_FirstName || '',
                        cert_InstitutionorOrganization:
                            originalReferenceData.cert_InstitutionorOrganization || '',
                        cert_LastName: originalReferenceData.cert_LastName || '',
                        cert_MedicalDegree: originalReferenceData.cert_MedicalDegree || 'null',
                        cert_MiddleName: originalReferenceData.cert_MiddleName || '',
                        cert_OfficePhone: originalReferenceData.cert_OfficePhone || '',
                        cert_Salutation: originalReferenceData.cert_Salutation || 'null',
                        cert_referenceId: originalReferenceData.cert_referenceId,
                        cert_referencepostype: originalReferenceData.cert_referencepostype || 'null',
                        cert_StateId: originalReferenceData.cert_StateId || 'null',
                        cert_StateorProvince: originalReferenceData.cert_StateorProvince || '',
                        cert_Suffix: originalReferenceData.cert_Suffix || 'null',
                        cert_ZipCode: originalReferenceData.cert_ZipCode || '',
                    },
                    () =>
                        this.context.props.cancelCallback &&
                        this.context.props.cancelCallback(originalReferenceData.cert_referenceId),
                ),
        )
    },

    DeleteReferenceFromCRM() {
        return new Promise((resolve) => {
            const {referenceID} = this.context.props
            let getParams = {
                params: {
                    referenceID,
                },
            }

            ABAPortalAPI.post('reference/deleteReference', {}, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting Reference')
                    resolve(false)
                })
        })
    },

    DeleteReference() {
        this.context.setState({showDeleteConfirm: false})
        this.DeleteReferenceFromCRM().then((response) => {
            if (response) {
                this.context.props.saveCallback()
            }
        })
    },

    PopulateVariables(dropdownData, countryData, referenceData, stateData) {
        const {positions, suffixes, salutations, medicalDegrees, referenceTypes} = dropdownData
        this.context.setState(
            {
                positionList: positions || [],
                suffixList: suffixes || [],
                salutationList: salutations || [],
                medicalDegreeList: medicalDegrees || [],
                countryList: countryData || [],
                stateList: stateData || [],
                referenceTypeList: referenceTypes || [],
            },
            () => {
                if (referenceData) {
                    this.context.setState(
                        {
                            originalReferenceData: referenceData,
                            aba_referenceclaim: referenceData.aba_referenceclaim,
                            cert_AddressLine1: referenceData.cert_AddressLine1 || '',
                            cert_AddressLine2: referenceData.cert_AddressLine2 || '',
                            cert_City: referenceData.cert_City || '',
                            cert_CountryId: referenceData.cert_CountryId || 'null',
                            cert_DeptBox: referenceData.cert_DeptBox || '',
                            cert_Email: referenceData.cert_Email || '',
                            cert_Fax: referenceData.cert_Fax || '',
                            cert_FirstName: referenceData.cert_FirstName || '',
                            cert_InstitutionorOrganization:
                                referenceData.cert_InstitutionorOrganization || '',
                            cert_LastName: referenceData.cert_LastName || '',
                            cert_MedicalDegree: referenceData.cert_MedicalDegree || 'null',
                            cert_MiddleName: referenceData.cert_MiddleName || '',
                            cert_OfficePhone: referenceData.cert_OfficePhone || '',
                            cert_Salutation: referenceData.cert_Salutation || 'null',
                            cert_StateorProvince: referenceData.cert_StateorProvince || '',
                            cert_referenceId: referenceData.cert_referenceId,
                            cert_referencepostype: referenceData.cert_referencepostype || 'null',
                            cert_StateId: referenceData.cert_StateId || 'null',
                            cert_Suffix: referenceData.cert_Suffix || 'null',
                            cert_ZipCode: referenceData.cert_ZipCode || '',
                            referenceType: referenceData.cert_ReferenceType || '',
                        },
                        () => {
                            const {originalReferenceData, stateList} = this.context.state
                            originalReferenceData.stateList = stateList
                            this.context.setState({originalReferenceData})
                        },
                    )
                }
                this.context.setState({CallingApi: false})
            },
        )
    },
}

export default ReferenceLogic
