import {Platform} from 'react-native'

import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

const ExaminerConflictLogic = {
    context: null,
    getCandidates: () => {
        const {selectedExamId} = ExaminerConflictLogic.context.state
        return new Promise((resolve) => {
            ExaminerConflictLogic.context.setState({
                gettingCandidates: true,
            })

            ABAPortalAPI.post('examiners/getExaminerNoConflicts', {
                eventId: selectedExamId,
            })
                .then((response) => {
                    var initials = []
                    var states = []
                    ExaminerConflictLogic.context.setState({
                        candidates: response.data.map((candidate) => ({
                            ...candidate,
                            ProgramName: candidate.ProgramName.replace(':Primary', ''),
                        })),
                        gettingCandidates: false,
                    })

                    response.data.forEach((candidate) => {
                        var initial = candidate.LastName[0]
                        var state = candidate.Address_StateOrProvince
                        if (initials.findIndex((i) => i.value === initial) <= -1) {
                            initials.push({label: initial, value: initial})
                        }
                        if (states.findIndex((s) => s.value === state) <= -1) {
                            states.push({label: state, value: state})
                        }
                    })
                    ExaminerConflictLogic.context.setState({
                        candidateInitials: initials,
                        expandConflicts: false,
                        candidateStates: states.sort((a, b) => (a.value < b.value ? -1 : 1)),
                    })

                    resolve(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Exam Candidates')
                    resolve(null)
                })
        })
    },

    getCandidateConflicts: () => {
        const {selectedExamId} = ExaminerConflictLogic.context.state
        return new Promise((resolve) => {
            ExaminerConflictLogic.context.setState({
                gettingConflicts: true,
            })

            ABAPortalAPI.post('examiners/getExaminerConflicts', {
                eventId: selectedExamId,
            })
                .then((response) => {
                    ExaminerConflictLogic.context.setState({
                        conflictCandidates: response.data.map((candidate) => ({
                            ...candidate,
                            ProgramName: candidate.ProgramName.replace(':Primary', ''),
                        })),
                        gettingConflicts: false,
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving examiner conflict candidates')
                    resolve(null)
                })
        })
    },

    SubmitExamStatus: () => {
        ExaminerConflictLogic.context.setState({
            gettingCandidates: true,
            gettingConflicts: true,
        })

        const {selectedExamId} = ExaminerConflictLogic.context.state

        return new Promise((resolve) => {
            ABAPortalAPI.post('examiners/submitConflicts', {
                invitedExamId: ExaminerConflictLogic.context.state.ExamStatus.InvitedExamId,
                eventId: selectedExamId,
            })
                .then((response) => {
                    ExaminerConflictLogic.context.setState({ExamStatus: {...response.data}})
                    resolve(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving examiner conflict candidates')
                    resolve(null)
                })
                .finally(() =>
                    ExaminerConflictLogic.context.setState({
                        gettingCandidates: false,
                        gettingConflicts: false,
                    }),
                )
        })
    },

    examConflictStatus: () => {
        const {ConflictStatus} = ExaminerConflictLogic.context.state.ExamStatus
        switch (ConflictStatus) {
            case 970230001:
                return 'Viewed'
            case 970230003:
                return 'Edited'
            case 970230002:
                return 'Submitted'
            default:
                return 'Viewed'
        }
    },

    SetExam: (selectedExamId) => {
        if (selectedExamId !== 'null')
            ExaminerConflictLogic.GetExamInformation(selectedExamId).then(() => {
                ExaminerConflictLogic.getCandidates()
                ExaminerConflictLogic.getCandidateConflicts()
            })
    },

    GetExamInformation: (examId) => {
        return new Promise((resolve) => {
            ABAPortalAPI.get('examiners/GetExamConflictData')
                .then((response) => {
                    resolve(response.data)

                    var selectedExam = response.data.filter((exam) => {
                        return exam.ExamId === examId
                    })[0]
                    var examList = response.data.map((exam) => ({
                        label: exam.ExamName,
                        value: exam.ExamId,
                    }))

                    ExaminerConflictLogic.context.setState({
                        ExaminerExamList: examList,
                        selectedExamId: examId,
                        currentCandidateIndex: 0,
                        currentConflictIndex: 0,
                    })

                    ExaminerConflictLogic.SetExamStatus(selectedExam)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving examiner conflict candidates')
                    resolve(null)
                })
        })
    },

    SetExamStatus: (status) => {
        ExaminerConflictLogic.context.setState((currentState) => ({
            ExamStatus: status,
        }))
    },

    AddConflict: (candidate) => {
        ExaminerConflictLogic.context.setState({
            gettingCandidates: true,
            gettingConflicts: true,
        })
        const {selectedExamId} = ExaminerConflictLogic.context.state
        return new Promise((resolve) => {
            ABAPortalAPI.post('examiners/addConflict', {
                eventId: selectedExamId,
                candidateId: candidate.ContactId,
                conflictName: candidate.ExamName,
                invitedExamId: ExaminerConflictLogic.context.state.ExamStatus.InvitedExamId,
            })
                .then((response) => {
                    resolve(response.data)
                    ExaminerConflictLogic.SetExamStatus(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving examiner conflict candidates')
                    resolve(null)
                })
                .finally(() => {
                    ExaminerConflictLogic.getCandidates()
                    ExaminerConflictLogic.getCandidateConflicts()
                })
        })
    },

    RemoveConflict: (candidate) => {
        ExaminerConflictLogic.context.setState({
            gettingCandidates: true,
            gettingConflicts: true,
        })
        const {selectedExamId} = ExaminerConflictLogic.context.state
        return new Promise((resolve) => {
            ABAPortalAPI.post('examiners/removeConflict', {
                eventId: selectedExamId,
                conflictId: candidate.exam_conflictId,
                invitedExamId: ExaminerConflictLogic.context.state.ExamStatus.InvitedExamId,
            })
                .then((response) => {
                    resolve(response.data)
                    ExaminerConflictLogic.SetExamStatus(response.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving examiner conflict candidates')
                    resolve(null)
                })
                .finally(() => {
                    ExaminerConflictLogic.getCandidates()
                    ExaminerConflictLogic.getCandidateConflicts()
                })
        })
    },

    getFilteredCandidates: () =>
        ExaminerConflictLogic.context.state.candidates
            .filter((c) => ExaminerConflictLogic.stateFilter(c))
            .filter((c) => ExaminerConflictLogic.initialFilter(c))
            .filter((c) => ExaminerConflictLogic.allFilter(c)),

    getDisplayCandidates: () => {
        const {currentCandidateIndex, showCandidateRecordCount} = ExaminerConflictLogic.context.state

        return ExaminerConflictLogic.getFilteredCandidates()
            .sort((a, b) =>
                ExaminerConflictLogic.sortCandidates(
                    a,
                    b,
                    ExaminerConflictLogic.context.state.selectedCandidateSort,
                ),
            )
            .slice(currentCandidateIndex, currentCandidateIndex + showCandidateRecordCount)
    },

    getDisplayConflicts: () => {
        const {currentConflictIndex, showConflictRecordCount} = ExaminerConflictLogic.context.state
        return ExaminerConflictLogic.context.state.conflictCandidates
            .sort((a, b) =>
                ExaminerConflictLogic.sortCandidates(
                    a,
                    b,
                    ExaminerConflictLogic.context.state.selectedConflictSort,
                ),
            )
            .slice(currentConflictIndex, currentConflictIndex + showConflictRecordCount)
    },

    resetFilter: () => {
        ExaminerConflictLogic.context.setState({
            selectedInitial: 'null',
            selectedState: 'null',
            filterText: '',
        })
    },

    changeCandidatesPage: (nextPage = true) => {
        const {currentCandidateIndex, showCandidateRecordCount} = ExaminerConflictLogic.context.state
        var startIndex = currentCandidateIndex - showCandidateRecordCount
        if (nextPage) {
            startIndex = currentCandidateIndex + showCandidateRecordCount
        }

        ExaminerConflictLogic.context.setState({
            currentCandidateIndex: startIndex,
        })
    },

    changeConflictsPage: (nextPage = true) => {
        const {currentConflictIndex, showConflictRecordCount} = ExaminerConflictLogic.context.state
        var startIndex = currentConflictIndex - showConflictRecordCount
        if (nextPage) {
            startIndex = currentConflictIndex + showConflictRecordCount
        }

        ExaminerConflictLogic.context.setState({
            currentConflictIndex: startIndex,
        })
    },

    stateFilter: (candidate) =>
        candidate.Address_StateOrProvince === ExaminerConflictLogic.context.state.selectedState ||
        ExaminerConflictLogic.context.state.selectedState === 'null',

    initialFilter: (candidate) =>
        candidate.LastName[0] === ExaminerConflictLogic.context.state.selectedInitial ||
        ExaminerConflictLogic.context.state.selectedInitial === 'null',

    allFilter: (candidate) => {
        inFullName =
            candidate.FullName.toLowerCase().indexOf(
                ExaminerConflictLogic.context.state.filterText.toLowerCase(),
            ) > -1
        inProgramId = candidate.ProgramId.indexOf(ExaminerConflictLogic.context.state.filterText) === 0
        inProgramName =
            candidate.ProgramName.toLowerCase().indexOf(
                ExaminerConflictLogic.context.state.filterText.toLowerCase(),
            ) > -1
        inState =
            candidate.Address_StateOrProvince.toLowerCase().indexOf(
                ExaminerConflictLogic.context.state.filterText.toLowerCase(),
            ) === 0
        inCity =
            candidate.Address1_City.toLowerCase().indexOf(
                ExaminerConflictLogic.context.state.filterText.toLowerCase(),
            ) === 0
        if (
            inFullName ||
            inProgramId ||
            inProgramName ||
            inCity ||
            inState ||
            ExaminerConflictLogic.context.state.filterText === ''
        )
            return true
    },
    sortCandidates: (candidateA, candidateB, sortType) => {
        switch (+sortType) {
            case 2:
                return candidateA.LastName > candidateB.LastName ? -1 : 1
            case 3:
                return candidateA.ProgramName < candidateB.ProgramName ? -1 : 1
            case 4:
                return candidateA.ProgramName > candidateB.ProgramName ? -1 : 1
            case 5:
                return candidateA.Address_StateOrProvince < candidateB.Address_StateOrProvince ? -1 : 1
            case 6:
                return candidateA.Address_StateOrProvince > candidateB.Address_StateOrProvince ? -1 : 1
            default:
                return candidateA.LastName < candidateB.LastName ? -1 : 1
        }
    },
    sortTypes: [
        {label: 'Name (ASC)', value: 1},
        {label: 'Name (DESC)', value: 2},
        {label: 'Program (ASC)', value: 3},
        {label: 'Program (DESC)', value: 4},
        {label: 'State (ASC)', value: 5},
        {label: 'State (DESC)', value: 6},
    ],
}

export default ExaminerConflictLogic
