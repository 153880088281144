import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'

const MedicalLicenseLogic = {
    context: null,

    styleContainerWidth: function () {
        return {
            flex: 1,
        }
    },

    GetMedicalLicenseReleaseData: async () => {
        try {
            const response = await ABAPortalAPI.post('registration/GetRelease', {
                Order: MedicalLicenseLogic.context.props.Order,
                CR2ID: MedicalLicenseLogic.context.props.CR2ID,
            })

            return MedicalLicenseLogic.PopulateRelease(response.data)
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Release')
            MedicalLicenseLogic.context.setState({CallingApi: false})
        }
    },

    getMedicalLicenseURL: function (states) {
        const getParams = {
            params: {
                states,
            },
        }
        return new Promise((resolve) => {
            ABAPortalAPI.get('medicalLicense/getMedicalLicenseURL', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving States')
                    resolve(null)
                })
        })
    },

    PopulateRelease: (data) => {
        var releaseOption
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOption = true
        } else if (data.Crt_RecordStatus == 2) {
            releaseOption = false
        }
        return {
            ReleaseOption: releaseOption,
            NoteText: data.Crt_Note || '',
            MLReleaseData: data,
        }
    },

    CreateMedicalLicenseRelease: function () {
        return new Promise((resolve) => {
            MedicalLicenseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: MedicalLicenseLogic.context.props.CR2ID,
                Order: MedicalLicenseLogic.context.props.Order,
                Crt_RecordStatus: MedicalLicenseLogic.context.state.ReleaseOption ? 1 : 2,
                Crt_Note: MedicalLicenseLogic.context.state.NoteText,
                ContactId: MedicalLicenseLogic.context.state.MLReleaseData.ContactId,
                Crt_ReleaseType: MedicalLicenseLogic.context.state.MLReleaseData.Crt_ReleaseType,
                Crt_ProgramType: MedicalLicenseLogic.context.state.MLReleaseData.Crt_ProgramType,
                Crt_ReleaseId: MedicalLicenseLogic.context.state.MLReleaseData.Crt_ReleaseId,
                LastName: MedicalLicenseLogic.context.state.MLReleaseData.LastName,
                FirstName: MedicalLicenseLogic.context.state.MLReleaseData.FirstName,
                rs_statuscode: MedicalLicenseLogic.context.state.MLReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    MedicalLicenseLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    GetMedicalLicenseDetailDisplayData: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('medicallicense/getMedicalLicenseComponentDetailDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Detail')
                })
        })
    },

    ReleaseOptionYesSelected: function () {
        MedicalLicenseLogic.context.setState({ReleaseOption: true, ABAAffirmationRadioError: false})
    },

    ReleaseOptionNoSelected: function () {
        MedicalLicenseLogic.context.setState({ReleaseOption: false, ABAAffirmationRadioError: false})
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (
                medicalLicenseContext.state.ReleaseOption === true &&
                medicalLicenseContext.state.NoteText === ''
            ) {
                MedicalLicenseLogic.context.setState({NoteError: 3})

                resolve(false)
            } else if (
                medicalLicenseContext.state.ReleaseOption === false ||
                medicalLicenseContext.state.ReleaseOption === true
            ) {
                MedicalLicenseLogic.CreateMedicalLicenseRelease().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            } else {
                MedicalLicenseLogic.context.setState({ABAAffirmationRadioError: true})
                resolve(false)
            }
        })
    },
}

export default MedicalLicenseLogic
