import React, {Component} from 'react'
import {View, Text, ActivityIndicator, Platform} from 'react-native'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import acaPathwayLogic from '../../clinicalactivity/logic/acaPathwayPageLogic'
import HelperMethods from '../../../../helpers/HelperMethods'
import {ColorEnums} from '../../../../helpers/generalEnums'
import styleFunctions from '../../../../styles/styleFunctions'
import ACAExamPath4Logic from '../logic/acaExamPath4Logic'
import ACAPathwayPage from '../../clinicalactivity/presentation/acaPathwayPage'

export default class ACAExamPath4 extends Component {
    state = {
        CallingApi: false,
        primaryError: false,
        secondaryError: false,
        nbeCertOptions: [],
        nbeCert: null,
        clinicalActivityId: null,
        acaPathway: null,
        postTrainingOptions: [],
        postTraining: null,
    }

    componentDidMount() {
        const {GetACANBECerts, GetClinicalActivityData, GetACAPostTrainingOptions} = acaPathwayLogic
        Promise.all([GetACANBECerts(), GetClinicalActivityData(), GetACAPostTrainingOptions()]).then(
            (data) => {
                this.setState({
                    nbeCertOptions: data[0],
                    clinicalActivityId: data[1].cert_clinicalactivityid,
                    acaPathway: data[1].cert_acapath,
                    nbeCert: data[1].cert_acanbecert,
                    postTrainingOptions: data[2],
                    postTraining: data[1].cert_acaposttraining,
                })
            },
        )
    }

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({
                CurrentComponentStep: ACAPathwayPage,
            })
            resolve(false)
        })
    }

    Save() {
        return ACAExamPath4Logic.SaveLogic()
    }

    renderNBECertOptions() {
        const {nbeCert} = this.state

        return this.state.nbeCertOptions.map((option, index) => {
            const {value, label} = option
            if (value != 1 && value != 2) {
                return
            }

            return (
                <View
                    style={{
                        marginBottom: 15,
                        marginRight: Platform.OS === 'android' && index === 0 ? 25 : 0,
                    }}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={nbeCert === value}
                        label={label}
                        onChangeEvent={() => {
                            this.setState({
                                nbeCert: nbeCert === value ? null : value,
                                primaryError: null,
                                secondaryError: null,
                                postTraining: nbeCert === value ? null : this.state.postTraining,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    renderPostTrainingOptions() {
        const {postTraining} = this.state
        if (!this.state.nbeCert) {
            return
        }

        return this.state.postTrainingOptions.map((option) => {
            const {value, label} = option

            return (
                <View
                    style={{marginBottom: 15, paddingLeft: HelperMethods.acaCheckboxPadding(value)}}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={postTraining === value}
                        label={label}
                        labelStyle={{
                            fontSize: 14,
                            color: ColorEnums.cloudBurst,
                        }}
                        onChangeEvent={() => {
                            this.setState({
                                postTraining: postTraining === value ? null : value,
                                primaryError: null,
                                secondaryError: null,
                            })
                        }}
                        width="100%"
                    />
                    {value == 1 && (
                        <Text
                            style={{
                                paddingLeft: Platform.OS === 'web' ? 70 : 50,
                                fontSize: 14,
                                color: ColorEnums.cloudBurst,
                                paddingTop: 20,
                            }}
                        >
                            I have spent the following amount of clinical time dedicated to the
                            anesthetic management of patients undergoing cardiac procedures, and at least
                            20 cases per year required cardiopulmonary bypass or other mechanical
                            circulatory support:
                        </Text>
                    )}
                </View>
            )
        })
    }

    render() {
        acaPathwayLogic.context = this
        ACAExamPath4Logic.context = this
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 20,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        Adult Cardiac Anesthesiology Pathway
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 16,
                                paddingTop: 15,
                                width: '100%',
                                marginBottom: 15,
                            },
                        ]}
                    >
                        Please select your Adult Cardiac Anesthesiology entrance pathway:
                        <ABARequiredAsterisk />
                    </Text>
                    {this.state.primaryError && (
                        <Text style={{color: ColorEnums.pomegranate, paddingBottom: 5}}>
                            Please select a pathway
                        </Text>
                    )}
                    <View>{this.renderNBECertOptions()}</View>
                    <View>
                        {this.state.secondaryError && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    paddingBottom: 5,
                                    paddingLeft: 120,
                                }}
                            >
                                Please select one of the options below
                            </Text>
                        )}
                        {this.renderPostTrainingOptions()}
                    </View>
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
