import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Picker, Platform, StyleSheet, Text, View, ActionSheetIOS, TouchableHighlight} from 'react-native'

import ABATextInput from './ABATextInput'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'

const areArraysSame = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false
    }
    //Do object comparison because we know that the array contents are objects.
    for (let i = array1.length; i--; ) {
        let array1Props = Object.getOwnPropertyNames(array1[i])
        let array2Props = Object.getOwnPropertyNames(array2[i])

        if (array1Props.length != array2Props.length) {
            return false
        }
        for (let j = array1Props.length; j--; ) {
            let propName = array1Props[j]

            if (array1[i][propName] !== array2[i][propName]) {
                return false
            }
        }
    }
    return true
}

export default class ABADropdown extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            dropdown: {
                borderWidth: 0,
                borderBottomWidth: 1,
                height: 26,
                width: '100%',
            },
            dropdownIos: {
                height: 100,
            },
        })

        this.state = {
            borderBottomColor: ColorEnums.blueGray,
            selectedItemColor: ColorEnums.blueGray,
            showLabel: false,
            width: 100,
            options: [],
        }
    }

    componentDidMount() {
        this.setBorderBottomColor()
        this.selectionChanged(this.props.selectedValue)
    }

    componentDidUpdate(previousProps) {
        if (previousProps.validationStatus !== this.props.validationStatus) {
            this.setBorderBottomColor()
        }
        if (!areArraysSame(previousProps.options, this.props.options)) {
            if (
                previousProps.selectedValue === this.props.selectedValue &&
                !this.props.keepValueOnOptionsChange
            ) {
                this.selectionChanged(this.props.placeholderItemValue)
                this.props.onChange(this.props.placeholderItemValue)
            }
        }
        if (previousProps.selectedValue !== this.props.selectedValue) {
            this.selectionChanged(this.props.selectedValue)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!areArraysSame(state.options, props.options)) {
            const item = props.options.find((items) => items.value == props.placeholderItemValue)
            if (!item) {
                props.options.unshift({
                    label: props.placeholder,
                    value: props.placeholderItemValue,
                })
            }
            return {
                options: props.options,
            }
        }
        return null
    }

    getStyleForValidationStatus() {
        var targetIcon = null
        var targetColor = ColorEnums.blueGray
        switch (this.props.validationStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetIcon = IconEnums['lock']
                targetColor = ColorEnums.blueGray
                break
            case GeneralEnums.textInputStatus.error:
                targetIcon = IconEnums['times']
                targetColor = ColorEnums.pomegranate
                break
            case GeneralEnums.textInputStatus.success:
                targetIcon = IconEnums['check']
                targetColor = ColorEnums.surfGreen
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetIcon = null
                targetColor = ColorEnums.blueGray
                break
        }

        return {
            color: targetColor,
            icon: targetIcon,
        }
    }

    selectionChanged(selectedValue) {
        if (selectedValue || selectedValue == 0) {
            if (selectedValue === this.props.placeholderItemValue) {
                this.setState({
                    selectedItemColor: ColorEnums.blueGray,
                    showLabel: false,
                })
            }
            if (selectedValue !== this.props.placeholderItemValue) {
                this.setState({
                    selectedItemColor: ColorEnums.cloudBurst,
                    showLabel: true,
                })
            }
        }
    }
    showActionSheet() {
        var options = this.state.options.map((option) => option.label)
        var optionValues = this.state.options.map((option) => option.value)
        var selectedIndex = optionValues.indexOf(this.props.selectedValue)
        options.push('Cancel')
        var destructiveButtonIndex = options.length - 1
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: options,
                destructiveButtonIndex: selectedIndex,
                cancelButtonIndex: destructiveButtonIndex,
                title: this.props.placeholder || this.props.label,
                tintColor: ColorEnums.cloudBurst,
            },
            (buttonIndex) => {
                if (buttonIndex === destructiveButtonIndex) return

                var selectedOption = this.state.options.find(
                    (option) => option.value === this.state.options[buttonIndex].value,
                )

                this.selectionChanged(selectedOption.value)
                this.props.onChange(selectedOption.value)
            },
        )
    }
    setBorderBottomColor() {
        this.setState({
            borderBottomColor: this.getStyleForValidationStatus().color,
        })
    }

    renderPicker() {
        var fontColor = ColorEnums.cloudBurst
        const dynamicOptions = this.state.options.map((option, index) => {
            if (index === 0) {
                return (
                    <Picker.Item
                        color={ColorEnums.blueGray}
                        key={index}
                        label={option.label}
                        style={{fontFamily: 'Lato'}}
                        value={option.value}
                        testID={'ABADropdownItem' + option.label}
                    />
                )
            }
            return (
                <Picker.Item
                    color={ColorEnums.cloudBurst}
                    key={index}
                    label={option.label}
                    style={{fontFamily: 'Lato'}}
                    value={option.value}
                    testID={'ABADropdownItem' + option.label}
                />
            )
        })

        if (Platform.OS === 'ios') {
            if (this.state.options.length === 0) return

            var selectedItemValue = this.state.options.find(
                (item) => item.value === this.props.selectedValue,
            )

            if (!selectedItemValue) {
                selectedItemValue = {
                    label: this.props.placeholder,
                    value: this.props.placeholderItemValue,
                }
            }

            if (selectedItemValue.value === this.state.options[0].value) {
                fontColor = ColorEnums.blueGray
            }

            return (
                <TouchableHighlight onPress={() => this.showActionSheet()}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 10,
                            borderBottomColor: this.state.borderBottomColor,
                            borderBottomWidth: 1,
                            width: '100%',
                        }}
                    >
                        <Text style={{color: fontColor, fontFamily: 'lato', alignSelf: 'center'}}>
                            {selectedItemValue.label}
                        </Text>
                        <Text
                            style={{
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                color: ColorEnums.danube,
                                fontSize: 16,
                            }}
                        >
                            {IconEnums['chevron-down']}
                        </Text>
                    </View>
                </TouchableHighlight>
            )
        }

        return (
            <Picker
                enabled={this.props.validationStatus !== GeneralEnums.textInputStatus.disabled}
                itemStyle={[this.styles.dropdownIos, {fontSize: 16, textAlign: 'left'}]}
                onValueChange={(itemValue) => {
                    this.selectionChanged(itemValue)
                    this.props.onChange(itemValue)
                }}
                selectedValue={this.props.selectedValue}
                style={[
                    this.styles.dropdown,
                    {borderBottomColor: this.state.borderBottomColor},
                    Platform.OS === 'ios'
                        ? this.styles.dropdownIos
                        : {color: this.state.selectedItemColor},
                ]}
                testID={'ABADropdown' + this.props.label}
            >
                {dynamicOptions}
            </Picker>
        )
    }

    renderIcon() {
        return (
            <View
                style={{
                    alignItems: 'center',
                    borderBottomColor: this.state.borderBottomColor,
                    borderBottomWidth: Platform.OS === 'android' ? 0 : 1,
                    justifyContent: 'center',
                    width: 20,
                }}
            >
                <Text
                    style={{
                        color: this.state.borderBottomColor,
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        fontSize: 14,
                    }}
                >
                    {this.getStyleForValidationStatus().icon}
                </Text>
            </View>
        )
    }

    render() {
        if (this.props.validationStatus === GeneralEnums.textInputStatus.disabled) {
            let selectedText
            let selectedValue = this.props.selectedValue
            let selectedOption = this.props.options.find(function (option) {
                return option.value === selectedValue
            })

            if (selectedOption !== undefined) {
                selectedText = selectedOption.label
            }

            return (
                <ABATextInput
                    onChangeText={(value) => {}}
                    textInputStatus={this.props.validationStatus}
                    value={selectedText}
                    width="100%"
                />
            )
        }
        return (
            <View style={{width: this.props.width}}>
                <View style={{height: 15}}>
                    {this.state.showLabel === true && (
                        <Text style={styleFunctions.controlLabel()}>{this.props.label}</Text>
                    )}
                </View>
                <View style={{flexDirection: 'row', width: '100%'}}>
                    <View style={{width: this.props.width}}>{this.renderPicker()}</View>
                    {this.renderIcon()}
                </View>
                {this.props.validationStatus === GeneralEnums.textInputStatus.error && (
                    <Text style={{color: ColorEnums.pomegranate}}>{this.props.errorMessage}</Text>
                )}
            </View>
        )
    }
}

ABADropdown.propTypes = {
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    placeholderItemValue: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    validationStatus: PropTypes.number.isRequired,
    width: PropTypes.string,
    keepValueOnOptionsChange: PropTypes.bool,
}

ABADropdown.defaultProps = {
    errorMessage: 'Please select a value.',
    placeholderItemValue: 'null',
    width: '100%',
    keepValueOnOptionsChange: false,
}
