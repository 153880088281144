import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import NonStandardExamLogicStep2 from '../logic/nonStandardExamStep2Logic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABADropdown from '../../../../components/ABACustom/presentation/ABADropdown'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABAModal from '../../../ABACustom/presentation/ABAModal'
import ABAConfirmationBox from '../../../ABACustom/presentation/ABAConfirmationBox'
import ABAHyperLink from '../../../ABACustom/presentation/ABAHyperLink'
import ABATextInput from '../../../ABACustom/presentation/ABATextInput'
import ABATouchableHighlight from '../../../ABACustom/presentation/ABATouchableHighlight'
export default class NonStandardExamStep2 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        NSEReleaseData: {},
        examTypeList: [],
        productID: '',
        exam_examYearRequested: '',
        ExamLabel: '',
        InputStatus: {
            productID: GeneralEnums.textInputStatus.default,
            exam_examYearRequested: GeneralEnums.textInputStatus.default,
        },
        nursingMotherResults: {},
        showNursingMotherModal: false,
    }

    componentDidMount() {
        NonStandardExamLogicStep2.GetExamTypes().then((examTypeList) => {
            if (this.state.NonStandardExamID) {
                NonStandardExamLogicStep2.GetNSEData().then((NSEData) => {
                    NonStandardExamLogicStep2.PopulateStateVariables(examTypeList, NSEData)
                })
            } else {
                NonStandardExamLogicStep2.PopulateStateVariables(examTypeList, null)
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.productID !== this.state.productID) {
            const {productID, examTypeList} = this.state
            if (productID) {
                NonStandardExamLogicStep2.SetExamInformation(productID, examTypeList)
            }
        }
    }

    Save() {
        return NonStandardExamLogicStep2.SaveLogic(this.state.productID)
    }

    Previous() {
        return NonStandardExamLogicStep2.Previous()
    }

    render() {
        NonStandardExamLogicStep2.context = this
        const {ComponentWidth} = this.state

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                        marginBottom: 15,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '95%',
                            },
                        ]}
                    >
                        Online Form Instructions
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText('center'),
                            {
                                fontSize: 15,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                color: ColorEnums.pomegranate,
                                padding: 20,
                            },
                        ]}
                    >
                        Please read the following instructions before completing this online form.
                    </Text>
                    <Text style={[styleFunctions.paragraphText(), {fontSize: 15, marginBottom: 15}]}>
                        Individuals who are seeking exam/assessment accommodation must complete and
                        submit the following form no later than the nonstandard request deadline
                        published on our website. Our process for considering nonstandard accommodation
                        requests due to a disability is defined in the "Requesting Accommodation" section
                        in our Policy Book.
                    </Text>
                    <Text style={[styleFunctions.paragraphText(), {fontSize: 15, marginBottom: 15}]}>
                        We only review and respond to one accommodation request at a time; therefore,
                        individuals seeking accommodation on more than one assessment or exam must submit
                        a form for each request. We will not consider requests for a Part 2 Exam, APPLIED
                        Exam or subspecialty certification exam if the individual has not first satisfied
                        the preceding exam requirement.
                    </Text>
                    <Text style={[styleFunctions.paragraphText(), {fontSize: 15, marginBottom: 15}]}>
                        We must receive documentation of the nature and severity of your disability and
                        its impact on your ability to take the exam under standard conditions. Please
                        review and share the Guidelines for Requesting Accommodations with your
                        evaluator, therapist, or treating physician, so that the appropriate
                        documentation can be submitted to support your request.
                    </Text>
                </View>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? '60%' : '100%'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 15,
                                    width: '100%',
                                    marginRight: 10,
                                    paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                },
                            ]}
                        >
                            Exam year for which accommodation is being requested:
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: ComponentWidth > 600 ? '40%' : '100%'}}>
                        <ABATextInputMask
                            onChangeText={(exam_examYearRequested) =>
                                this.setState({exam_examYearRequested})
                            }
                            placeholder="(yyyy)"
                            textInputStatus={this.state.InputStatus.exam_examYearRequested}
                            returnKeyType="next"
                            value={
                                this.state.exam_examYearRequested &&
                                this.state.exam_examYearRequested.toString()
                            }
                            width="60%"
                            marginRight={0}
                            marginLeft={0}
                            maxLength={4}
                            options={{mask: '9999'}}
                            errorMessage={'Please enter a valid Exam year.'}
                        />
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? '60%' : '100%'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 15,
                                    width: '100%',
                                    marginRight: 10,
                                    paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                },
                            ]}
                        >
                            Exam type:
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: ComponentWidth > 600 ? '40%' : '100%'}}>
                        <ABADropdown
                            options={this.state.examTypeList}
                            label="Exam Types *"
                            placeholder="Exam Types *"
                            onChange={(productID) =>
                                NonStandardExamLogicStep2.SetExamInformation(
                                    productID,
                                    this.state.examTypeList,
                                )
                            }
                            selectedValue={this.state.productID}
                            validationStatus={this.state.InputStatus.productID}
                            width="100%"
                            placeholderItemValue=""
                        />
                    </View>
                </View>
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 13,
                                marginTop: 10,
                                marginBottom: 15,
                                fontStyle: 'italic',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            },
                        ]}
                    >
                        Note: If you wish to request accommodation on more than one exam, you must submit
                        a new form for each exam.
                    </Text>
                </View>

                {this.state.showNursingMotherModal &&
                    this.state.nursingMotherResults.ShowMultipleEventMessage && (
                        <ABAModal
                            isVisible={this.state.showNursingMotherModal}
                            onDismiss={() => this.setState({showNursingMotherModal: false})}
                        >
                            <View
                                style={{
                                    backgroundColor: ColorEnums.aquaHaze,
                                    shadowColor: '#dddddd',
                                    shadowOffset: {width: 4, height: 5},
                                    zIndex: 10,
                                    opacity: 1,
                                    borderWidth: 1,
                                    borderColor: ColorEnums.lightBlue,
                                    borderRadius: 10,
                                    width: '95%',
                                    maxWidth: 500,
                                    padding: 15,
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {margin: 10, fontSize: 18, fontWeight: 'bold'},
                                    ]}
                                >
                                    This request falls outside the request window for{' '}
                                    {this.state.nursingMotherResults.IneligibleExamName}.
                                </Text>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {margin: 10, fontSize: 18, fontWeight: 'bold'},
                                    ]}
                                >
                                    This request is eligible for{' '}
                                    {this.state.nursingMotherResults.EligibleExamName}.{'  '}
                                    To proceed with this request, please click Continue. To change your
                                    selection, please click Cancel. If you believe this is a mistake,
                                    email accommodations@theaba.org.
                                </Text>
                                <View style={{flexDirection: 'row', marginTop: 15, alignSelf: 'center'}}>
                                    <ABATouchableHighlight
                                        text="Continue"
                                        onPressEvent={() => {
                                            this.setState({showNursingMotherModal: false})
                                            NonStandardExamLogicStep2.SaveLogicOnModalContinue()
                                        }}
                                    />
                                    <View style={{paddingLeft: 10}} />
                                    <ABATouchableHighlight
                                        text="Cancel"
                                        onPressEvent={() =>
                                            this.setState({showNursingMotherModal: false})
                                        }
                                    />
                                </View>
                            </View>
                        </ABAModal>
                    )}
                {this.state.showNursingMotherModal &&
                    !this.state.nursingMotherResults.ShowMultipleEventMessage && (
                        <ABAModal
                            isVisible={this.state.showNursingMotherModal}
                            onDismiss={() => this.setState({showNursingMotherModal: false})}
                            onBackdropPress={() => this.setState({showNursingMotherModal: false})}
                        >
                            <View
                                style={{
                                    backgroundColor: ColorEnums.aquaHaze,
                                    shadowColor: '#dddddd',
                                    shadowOffset: {width: 4, height: 5},
                                    zIndex: 10,
                                    opacity: 1,
                                    borderWidth: 1,
                                    borderColor: ColorEnums.lightBlue,
                                    borderRadius: 10,
                                    width: '95%',
                                    maxWidth: 500,
                                    padding: 15,
                                }}
                            >
                                {this.state.nursingMotherResults.ShowNMMessage && (
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {margin: 10, fontSize: 18, fontWeight: 'bold'},
                                        ]}
                                    >
                                        This request falls outside of the Nursing Mother request window.
                                        {'  '}
                                        All Requests must fall within 60 and 120 days prior to the exam
                                        date.
                                    </Text>
                                )}
                                {this.state.nursingMotherResults.ShowNSEMessage && (
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {margin: 10, fontSize: 18, fontWeight: 'bold'},
                                        ]}
                                    >
                                        This request falls outside of the NSE request window. Please
                                        review the deadlines for ADA Assessment Accommodation Requests
                                        <ABAHyperLink
                                            url="https://theaba.org/certifications%20and%20exms.html"
                                            label=" here"
                                            style={[styleFunctions.actionText(), {padding: 0}]}
                                        />
                                        .{'  '} If you believe this is a mistake, email
                                        accommodations@theaba.org.
                                    </Text>
                                )}
                                <ABATouchableHighlight
                                    text="OK"
                                    onPressEvent={() => this.setState({showNursingMotherModal: false})}
                                />
                            </View>
                        </ABAModal>
                    )}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading NSE</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
