import React, {Component} from 'react'
import {
    Image,
    TouchableHighlight,
    TouchableWithoutFeedback,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import {connect} from 'react-redux'
import ABAAccordion from '../ABACustom/presentation/ABAAccordion'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import ABALogo from '../ABACustom/presentation/ABALogo'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import HeaderLogic from '../logic/headerLogic'
import LoginLogic from '../../screens/logic/loginLogic'
import NavigationHelper from '../../helpers/NavigationHelper'
import ProfileIcon from '../../assets/images/bottomNav/user-circle.png'
import SideNavigationLogic from '../logic/sidenavigationLogic'
import SidebarSublinks from '../presentation/sidebarSublinks'
import {
    setAuthToken,
    setCurrentProgressTab,
    clearUserData,
    setNavigation,
    setOpenMenuGroups,
} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    settings: state.settings,
    navigationMenu: state.navigationMenu,
})

const mapDispatchToProps = {
    setAuthToken,
    setCurrentProgressTab,
    clearUserData,
    setNavigation,
    setOpenMenuGroups,
    logout: LoginLogic.logout,
}

const styles = StyleSheet.create({
    navLinkText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'Lato',
    },

    navLinkContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: ColorEnums.cloudBurst,
    },
    userInfoText: {
        fontFamily: 'lato',
        color: 'white',
        fontSize: 12,
    },
})

class MobileMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            userInfo: {
                FirstName: null,
                LastName: null,
                ABAId: null,
                Email: null,
                PrincipalCertNumber: null,
            },
            links: this.props.navigationMenu.links,
        }
        this.navigateToPage = this.navigateToPage.bind(this)
    }

    navigateToPage(linkUrl) {
        //if our current url is the same as the link, then we just want to close our navigation menu instead of re-loading the page.
        if (this.props.currentUrl === linkUrl) {
            return bottomNavContext.setState({showMenu: false})
        }

        return NavigationHelper.GoToPage(GeneralEnums.navigationType.push, linkUrl)
    }

    componentDidMount() {
        //whatever logic needs to be done (if any) when this component loads
        if (!this.props.navigationMenu.links) {
            SideNavigationLogic.getNavigationLinks().then((links) => {
                this.props.setNavigation(links)
                this.setState({links})
            })
        }

        HeaderLogic.GetHeaderInfo().then((userInfo) => {
            sideNavigationContext.setState({userInfo: userInfo})
        })
    }

    render() {
        sideNavigationContext = this
        SideNavigationLogic.context = sideNavigationContext
        HeaderLogic.context = sideNavigationContext

        return (
            <ScrollView
                style={{
                    backgroundColor: ColorEnums.cloudBurst,
                    minHeight: '100%',
                    width: '100%',
                }}
            >
                <ABALogo />

                <View
                    style={{
                        borderTopColor: ColorEnums.danube,
                        borderBottomColor: ColorEnums.danube,
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 10,
                        marginBottom: 10,
                        paddingTop: 15,
                        paddingBottom: 15,
                        width: '80%',
                    }}
                >
                    <View style={{marginLeft: 'auto', marginRight: 'auto', flexDirection: 'row'}}>
                        <View style={{marginRight: 5, marginTop: 'auto', marginBottom: 'auto'}}>
                            <TouchableHighlight
                                onPress={() => sideNavigationContext.navigateToPage('/personalInfo')}
                            >
                                <Image
                                    style={{
                                        marginRight: 10,
                                        width: 46,
                                        height: 46,
                                    }}
                                    source={ProfileIcon}
                                    resizeMode={'contain'}
                                />
                            </TouchableHighlight>
                        </View>
                        <View>
                            <Text style={[styles.userInfoText, {fontSize: 18, maxWidth: 230}]}>
                                Dr. {this.state.userInfo.FirstName} {this.state.userInfo.LastName}
                            </Text>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'white',
                                    paddingBottom: 5,
                                    maxWidth: 200,
                                }}
                            >
                                <Text style={styles.userInfoText}>
                                    ABA ID: {this.state.userInfo.ABAId}
                                </Text>
                                {this.state.userInfo.PrincipalCertNumber && (
                                    <Text style={styles.userInfoText}>
                                        {' '}
                                        | CERT #: {this.state.userInfo.PrincipalCertNumber}
                                    </Text>
                                )}
                            </View>
                            <Text
                                style={[
                                    styles.userInfoText,
                                    {
                                        paddingTop: 3,
                                        maxWidth: 240,
                                    },
                                ]}
                                numberOfLines={1}
                            >
                                {this.state.userInfo.Email}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={{marginLeft: 'auto', marginRight: 'auto', width: '80%'}}>
                    {this.state.links &&
                        this.state.links.map((link, index) => {
                            if (link.MenuItems) {
                                return (
                                    <View key={index}>
                                        <ABAAccordion
                                            title={link.MenuGroupText.toUpperCase()}
                                            accordionContent={
                                                <SidebarSublinks
                                                    links={link.MenuItems}
                                                    bottomNavContext={bottomNavContext}
                                                />
                                            }
                                            accordionContainerStyle={{
                                                backgroundColor: ColorEnums.cloudBurst,
                                            }}
                                            accordionTitleContainerStyle={styles.navLinkContainer}
                                            accordionTitleStyle={styles.navLinkText}
                                            accordionIconRight={{
                                                expanded: 'chevron-down',
                                                collapsed: 'chevron-right',
                                            }}
                                            accordionIconStyle={{
                                                color: 'white',
                                                fontSize: 12,
                                                marginLeft: 'auto',
                                                paddingTop: 3,
                                            }}
                                        />
                                    </View>
                                )
                            } else if (link.MenuGroupText.toUpperCase() === 'LOG OUT') {
                                return (
                                    <View key={index} style={styles.navLinkContainer}>
                                        <TouchableHighlight
                                            onPress={() => {
                                                this.props.logout()
                                                this.props.clearUserData()
                                            }}
                                        >
                                            <Text style={styles.navLinkText}>{link.MenuGroupText}</Text>
                                        </TouchableHighlight>
                                    </View>
                                )
                            } else if (
                                link.MenuGroupText.toUpperCase() === 'CONTACT US' ||
                                link.MenuGroupText.toUpperCase() === 'PUBLIC WEBSITE' ||
                                link.MenuGroupText.toUpperCase() === 'POLICY BOOK' ||
                                link.MenuGroupText.toUpperCase() === 'FAQ'
                            ) {
                                return (
                                    <View key={index} style={styles.navLinkContainer}>
                                        <ABAHyperLink
                                            key={index}
                                            label={link.MenuGroupText}
                                            url={link.MenuGroupUrl}
                                            style={styles.navLinkText}
                                        />
                                    </View>
                                )
                            } else {
                                return (
                                    <View key={index} style={styles.navLinkContainer}>
                                        <TouchableWithoutFeedback
                                            onPress={() =>
                                                sideNavigationContext.navigateToPage(link.MenuGroupUrl)
                                            }
                                        >
                                            <View>
                                                <Text style={styles.navLinkText}>
                                                    {link.MenuGroupText.toUpperCase()}
                                                </Text>
                                            </View>
                                        </TouchableWithoutFeedback>
                                    </View>
                                )
                            }
                        })}
                </View>
            </ScrollView>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
