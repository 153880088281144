import {GeneralEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'

const ReleaseLogic = {
    context: null,

    GetText: function (Data) {
        return new Promise((resolve) => {
            ReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: Data.Crt_ReleaseType,
                ProgramType: ReleaseLogic.context.props.ProgramType,
                re_AdditionalSpecification: Data.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Release')
                })
                .finally(function () {
                    ReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetReleaseData: function () {
        return new Promise((resolve) => {
            ReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: ReleaseLogic.context.props.Order,
                CR2ID: ReleaseLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Acknowledgement Release')
                })
                .finally(function () {
                    ReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease: function (data) {
        var releaseOption
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOption = true
        } else if (data.Crt_RecordStatus == 2) {
            releaseOption = false
        }
        ReleaseLogic.context.setState({
            ReleaseOption: releaseOption,
            NoteText: data.Crt_Note || '',
            ReleaseData: data,
        })
    },

    CreateRelease: function () {
        return new Promise((resolve) => {
            ReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: ReleaseLogic.context.props.CR2ID,
                Order: ReleaseLogic.context.props.Order,
                Crt_RecordStatus: ReleaseLogic.context.state.ReleaseOption ? 1 : 2,
                Crt_Note: ReleaseLogic.context.state.NoteText,
                ContactId: ReleaseLogic.context.state.ReleaseData.ContactId,
                Crt_ReleaseType: ReleaseLogic.context.state.ReleaseData.Crt_ReleaseType,
                Crt_ProgramType: ReleaseLogic.context.state.ReleaseData.Crt_ProgramType,
                Crt_ReleaseId: ReleaseLogic.context.state.ReleaseData.Crt_ReleaseId,
                LastName: ReleaseLogic.context.state.ReleaseData.LastName,
                FirstName: ReleaseLogic.context.state.ReleaseData.FirstName,
                rs_statuscode: ReleaseLogic.context.state.ReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    ReleaseLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    ReleaseOptionSelected: function () {
        let stateObj = {
            ReleaseOption: !ReleaseLogic.context.state.ReleaseOption,
        }

        if (
            ReleaseLogic.context.state.InputStatus.ReleaseOption === GeneralEnums.textInputStatus.error
        ) {
            stateObj['InputStatus'] = {
                ReleaseOption: GeneralEnums.textInputStatus.default,
            }
        }

        ReleaseLogic.context.setState(stateObj)
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!ReleaseLogic.context.state.ReleaseOption) {
                ReleaseLogic.context.setState({
                    InputStatus: {ReleaseOption: GeneralEnums.textInputStatus.error},
                })
                resolve(false)
            } else {
                ReleaseLogic.CreateRelease().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default ReleaseLogic
