import React, {Component} from 'react'
import axios from 'axios'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAModal from '../../components/ABACustom/presentation/ABAModal'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import NavigationShell from '../../components/presentation/navigationShell'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import {View, Text, TouchableOpacity, Platform, ActivityIndicator} from 'react-native'
import NavigationHelper from '../../helpers/NavigationHelper'
import styleFunctions from '../../styles/styleFunctions'
import HelperMethods from '../../helpers/HelperMethods'

const feeContainerStyle = {paddingTop: 10, paddingBottom: 10}
const feeButtonStyle = {
    paddingTop: 10,
    paddingBottom: 10,
    flex: 1,
    flexDirection: 'row',
}
const isIE = Platform.OS === 'web' && /Trident/.test(navigator.userAgent)
const feeNameStyle = {
    width: '50%',
    fontFamily: 'lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: ColorEnums.danube,
}
const feePriceStyle = {
    width: '50%',
    fontFamily: 'lato',
    fontSize: 14,
    color: ColorEnums.cloudBurst,
    textAlign: 'right',
}
const feeDescriptionStyle = {
    fontFamily: 'lato',
    fontSize: 14,
    color: ColorEnums.cloudBurst,
    paddingTop: 5,
}
const chevronStyle = {
    fontFamily: GeneralEnums.fontAwesomeFamilyName,
    width: 30,
    paddingLeft: 15,
    margin: 'auto',
    fontSize: 18,
    color: ColorEnums.cloudBurst,
}
class AddOnServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eligibleRequests: [],
            pendingRequests: [],
            openRequests: [],
            selectedFee: null,
            displayOverwriteBox: false,
            displayDeleteBox: false,
            loading: true,
            paymentTimeout: undefined,
            isMounted: undefined,
        }
    }

    startPaymentProccessingLoop() {
        const paymentTimeout = setTimeout(async () => {
            const {data} = await this.getPaymentStatus()
            if (!data) {
                this.fetchFees()
            } else if (this.state.isMounted) {
                this.startPaymentProccessingLoop()
            }
        }, 2000)
        this.setState({paymentTimeout})
    }

    componentDidMount() {
        this.setState({isMounted: true})
        this.fetchFees()
    }

    componentWillUnmount() {
        this.setState({isMounted: false})
        clearTimeout(this.state.paymentTimeout)
    }

    getPaymentStatus() {
        return ABAPortalAPI.get('payment/paymentStatusDashboard', {
            params: {
                timestamp: new Date().toUTCString(),
            },
        })
    }

    async fetchFees() {
        try {
            const [feeResponse, paymentResonse] = await Promise.all([
                ABAPortalAPI.get('fee/fees'),
                this.getPaymentStatus(),
            ])
            const {data} = feeResponse
            const paymentProcessing = paymentResonse.data
            if (paymentProcessing) {
                this.startPaymentProccessingLoop()
            } else {
                this.setState({
                    eligibleRequests: data.EligibleRequests,
                    pendingRequests: data.PendingRequests,
                    openRequests: data.OpenRequests,
                    loading: false,
                })
            }
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Retrieving Services List')
            this.setState({loading: false})
        }
    }

    async deleteFeeDetail(feeDetailId) {
        this.setState({loading: true})
        try {
            await ABAPortalAPI.delete(`fee/detail/${feeDetailId}`)
            this.fetchFees()
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Retrieving Services List')
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <NavigationShell context={this} print>
                <ABAKeyboardAwareScrollView
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        paddingBottom: 50,
                    }}
                    ref={(comp) => {
                        this.componentRef = comp
                    }}
                >
                    <ABAHeader heading="Add-On Services" icon={IconEnums['user-circle']} />
                    {!this.state.eligibleRequests?.length &&
                        !this.state.openRequests?.length &&
                        !this.state.pendingRequests?.length &&
                        !this.state.loading && (
                            <Text style={{textAlign: 'center', paddingTop: 100}}>
                                No services to display
                            </Text>
                        )}
                    {!!this.state.openRequests?.length && (
                        <ABAContainer
                            containerTitle={'Open Requests'}
                            containerViewStyle={{marginBottom: 30}}
                        >
                            {this.state.openRequests.map((fee, index) => {
                                const TrashComponent = fee.Deletable ? TouchableOpacity : View
                                const trashPress = fee.Deletable
                                    ? () => {
                                          this.setState({selectedFee: fee, displayDeleteBox: true})
                                      }
                                    : undefined
                                return (
                                    <View
                                        key={fee.Name + fee.FeeDetailID}
                                        style={
                                            !isIE
                                                ? undefined
                                                : {
                                                      height: 100,
                                                  }
                                        }
                                    >
                                        {!!index && (
                                            <View
                                                style={{
                                                    borderTopColor: ColorEnums.blueGray,
                                                    borderTopWidth: 1,
                                                }}
                                            />
                                        )}
                                        <View style={[feeContainerStyle, {flexDirection: 'row'}]}>
                                            <TrashComponent
                                                style={{
                                                    width: 40,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                onPress={trashPress}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                        color: fee.Deletable
                                                            ? ColorEnums.pomegranateDark
                                                            : ColorEnums.blueGray,
                                                    }}
                                                >
                                                    {IconEnums['trash']}
                                                </Text>
                                            </TrashComponent>
                                            <View style={{width: 10}} />
                                            <TouchableOpacity
                                                style={feeButtonStyle}
                                                onPress={() =>
                                                    NavigationHelper.GoToPage(
                                                        GeneralEnums.navigationType.push,
                                                        '/addOnDetails',
                                                        fee,
                                                    )
                                                }
                                                testID={`openRequest${fee.Name}`}
                                            >
                                                <View style={{flex: 1}}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text style={feeNameStyle}>{fee.Name}</Text>
                                                        <Text style={feePriceStyle}>{fee.Pricing}</Text>
                                                    </View>
                                                    <Text style={feeDescriptionStyle}>
                                                        {fee.Description}
                                                    </Text>
                                                </View>
                                                <Text style={chevronStyle}>
                                                    {IconEnums['chevron-right']}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            })}
                            <View
                                style={{borderBottomColor: ColorEnums.cloudBurst, borderBottomWidth: 3}}
                            />
                        </ABAContainer>
                    )}
                    {!!this.state.pendingRequests?.length && (
                        <ABAContainer
                            containerTitle={'Pending Requests'}
                            containerViewStyle={{marginBottom: 30}}
                        >
                            {this.state.pendingRequests.map((fee, index) => {
                                return (
                                    <View
                                        key={fee.Name + fee.FeeDetailID}
                                        style={
                                            !isIE
                                                ? undefined
                                                : {
                                                      height: 100,
                                                  }
                                        }
                                    >
                                        {!!index && (
                                            <View
                                                style={{
                                                    borderTopColor: ColorEnums.blueGray,
                                                    borderTopWidth: 1,
                                                }}
                                            />
                                        )}
                                        <View style={feeContainerStyle}>
                                            <TouchableOpacity
                                                style={feeButtonStyle}
                                                onPress={() =>
                                                    NavigationHelper.GoToPage(
                                                        GeneralEnums.navigationType.push,
                                                        '/addOnDetails',
                                                        {...fee, disabled: true},
                                                    )
                                                }
                                                testID={`pendingRequest${fee.Name}`}
                                            >
                                                <View style={{flex: 1}}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text style={feeNameStyle}>{fee.Name}</Text>
                                                        <Text style={feePriceStyle}>{fee.Pricing}</Text>
                                                    </View>
                                                    <Text style={feeDescriptionStyle}>
                                                        {fee.Description}
                                                    </Text>
                                                </View>
                                                <Text style={chevronStyle}>
                                                    {IconEnums['chevron-right']}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            })}
                            <View
                                style={{borderBottomColor: ColorEnums.cloudBurst, borderBottomWidth: 3}}
                            />
                        </ABAContainer>
                    )}
                    {!!this.state.eligibleRequests?.length && (
                        <ABAContainer containerTitle={'Add-On Services'}>
                            {this.state.eligibleRequests.map((fee, index) => {
                                return (
                                    <View
                                        key={fee.Name}
                                        style={
                                            !isIE
                                                ? undefined
                                                : {
                                                      height: 100,
                                                  }
                                        }
                                    >
                                        {!!index && (
                                            <View
                                                style={{
                                                    borderTopColor: ColorEnums.blueGray,
                                                    borderTopWidth: 1,
                                                }}
                                            />
                                        )}
                                        <View style={feeContainerStyle}>
                                            <TouchableOpacity
                                                style={feeButtonStyle}
                                                onPress={() => {
                                                    if (
                                                        this.state.openRequests.find((openFee) => {
                                                            return (
                                                                openFee.Deletable &&
                                                                fee.Name == openFee.Name
                                                            )
                                                        })
                                                    ) {
                                                        this.setState({
                                                            displayOverwriteBox: true,
                                                            selectedFee: fee,
                                                        })
                                                    } else {
                                                        NavigationHelper.GoToPage(
                                                            GeneralEnums.navigationType.push,
                                                            '/addOnDetails',
                                                            fee,
                                                        )
                                                    }
                                                }}
                                                testID={`eligibleRequest${fee.Name}`}
                                            >
                                                <View style={{flex: 1}}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text style={feeNameStyle}>{fee.Name}</Text>
                                                        <Text style={feePriceStyle}>{fee.Pricing}</Text>
                                                    </View>
                                                    <Text style={feeDescriptionStyle}>
                                                        {fee.Description}
                                                    </Text>
                                                </View>
                                                <Text style={chevronStyle}>
                                                    {IconEnums['chevron-right']}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            })}
                            <View
                                style={{borderBottomColor: ColorEnums.cloudBurst, borderBottomWidth: 3}}
                            />
                            <Text style={{color: 'red', fontWeight: 'bold'}}>
                                *Once your payment is submitted all charges are non-refundable.
                            </Text>
                        </ABAContainer>
                    )}
                </ABAKeyboardAwareScrollView>
                {this.state.displayOverwriteBox && (
                    <ABAModal
                        isVisible={this.state.displayOverwriteBox}
                        onDismiss={() => this.setState({displayOverwriteBox: false})}
                        onBackdropPress={() => this.setState({displayOverwriteBox: false})}
                    >
                        <ABAConfirmationBox
                            messageText1="An open request for this add-on service already exists."
                            messageText2="Select 'Cancel' to return to the Add-On Services page and access your Open Requests. Select 'Ok' to open a new form and replace your existing request."
                            buttonLeftText="Cancel"
                            buttonRightText="Ok"
                            leftButtonFunction={() => this.setState({displayOverwriteBox: false})}
                            rightButtonFunction={() =>
                                NavigationHelper.GoToPage(
                                    GeneralEnums.navigationType.push,
                                    '/addOnDetails',
                                    this.state.selectedFee,
                                )
                            }
                        />
                    </ABAModal>
                )}
                {this.state.displayDeleteBox && (
                    <ABAModal
                        isVisible={this.state.displayDeleteBox}
                        onDismiss={() => this.setState({displayDeleteBox: false})}
                        onBackdropPress={() => this.setState({displayDeleteBox: false})}
                    >
                        <ABAConfirmationBox
                            messageText1="Selecting 'Ok' will permanently delete the request."
                            messageText2="Do you wish to Continue?"
                            buttonLeftText="Cancel"
                            buttonRightText="Ok"
                            leftButtonFunction={() => this.setState({displayDeleteBox: false})}
                            rightButtonFunction={() => {
                                this.deleteFeeDetail(this.state.selectedFee.FeeDetailID)
                                this.setState({displayDeleteBox: false})
                            }}
                        />
                    </ABAModal>
                )}
                {this.state.loading && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Add-On Services</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </NavigationShell>
        )
    }
}
export default AddOnServices
