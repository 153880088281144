import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const CMEDiplomateInMOCALogic = {
    context: null,

    GetDiplomateInMOCADisplay() {
        return new Promise((resolve) => {
            CMEDiplomateInMOCALogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getDiplomateInMOCACmeCredits')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Data')
                    resolve(null)
                })
                .finally(function () {
                    CMEDiplomateInMOCALogic.context.setState({CallingApi: false})
                })
        })
    },

    GetPatientSafetyRuleStatus() {
        return new Promise((resolve) => {
            CMEDiplomateInMOCALogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getPatientSafetyRuleStatus')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Patient Safety Rule Status')
                    resolve(null)
                })
                .finally(function () {
                    CMEDiplomateInMOCALogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default CMEDiplomateInMOCALogic
