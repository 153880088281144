import React, {Component} from 'react'
import {Platform} from 'react-native'
import {
    ActivityIndicator,
    StyleSheet,
    Text,
    TouchableHighlight,
    TouchableOpacity,
    View,
} from 'react-native'
import PropTypes from 'prop-types'

import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import HeaderLogic from '../logic/headerLogic'
import styleFunctions from '../../styles/styleFunctions'
import SideNavigation from './sidenavigation'
import {connect} from 'react-redux'
const isWeb = Platform.OS === 'web'
const Sentry = isWeb ? require('@sentry/react') : require('@sentry/react-native')

const styles = StyleSheet.create({
    iconSymbol: {
        fontFamily: GeneralEnums.fontAwesomeFamilyName,
        color: ColorEnums.white,
        fontSize: 32,
    },
    headerInfoText: {
        color: ColorEnums.white,
        fontFamily: 'Lato',
        fontSize: 14,
    },
})
const mapStateToProps = (state) => ({
    settings: state.settings,
    hasBackDoorAccess: state.userData.hasBackDoorAccess,
})
class Header extends Component {
    constructor(props) {
        super(props)

        let showPrintIcon = true
        // checking if broswer is IE.  If so, we're hiding the print icon
        if (/Trident/.test(navigator.userAgent)) {
            showPrintIcon = false
        }

        this.state = {
            CallingApi: false,
            currentABAID: null,
            HeaderInfo: {
                FirstName: null,
                LastName: null,
                ABAId: null,
                Email: null,
                PrincipalCertNumber: null,
            },
            showResponsiveNav: false,
            showPrintIcon,
            notificationCount: null,
        }

        this.toggleNav = this.toggleNav.bind(this)
    }

    GENERAL_PADDING = 10

    getUserInfo() {
        HeaderLogic.GetHeaderInfo().then((info) => {
            Sentry.setUser({...info, impersonated: this.props.hasBackDoorAccess})
            HeaderContext.setState({
                HeaderInfo: info,
                currentABAID: Number(info.ABAId.replace('-', '')),
            })
        })

        HeaderLogic.GetBellNotificationsCount().then((info) => {
            this.setState({notificationCount: info})
        })
    }

    componentDidMount() {
        this.getUserInfo()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.HeaderInfo.ABAId === this.state.HeaderInfo.ABAId) {
            return
        }

        if (Number(this.props.settings.ABAID) !== this.state.currentABAID) {
            this.getUserInfo()
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showNav && prevState.showResponsiveNav) {
            return {
                showResponsiveNav: false,
            }
        }
        return null
    }

    toggleNav() {
        this.setState({
            showResponsiveNav: !this.state.showResponsiveNav,
        })
    }

    goToNewRoute(route) {
        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, route)
    }

    renderBellIcon() {
        if (this.props.hideBellIcon) {
            return
        }

        if (
            window.location.pathname !== '/notifications' &&
            this.state.notificationCount &&
            this.state.notificationCount > 0 &&
            this.props.navEnabled
        ) {
            return (
                <View style={{flexDirection: 'row'}}>
                    <Text
                        style={[
                            styles.iconSymbol,
                            {
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                paddingRight: 15,
                                textAlign: 'right',
                            },
                        ]}
                        onPress={() => this.goToNewRoute('/notifications')}
                    >
                        {IconEnums['bell']}
                    </Text>
                    <View
                        style={{
                            backgroundColor: ColorEnums.pomegranateDark,
                            height: 17,
                            width: 17,
                            borderRadius: '50%',
                            position: 'absolute',
                            right: 12,
                            top: 4,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onPress={() => this.goToNewRoute('/notifications')}
                    >
                        <Text
                            style={{fontFamily: 'Lato', color: 'white', fontSize: 9}}
                            onPress={() => this.goToNewRoute('/notifications')}
                        >
                            {this.state.notificationCount}
                        </Text>
                    </View>
                </View>
            )
        } else {
            return (
                <View>
                    <Text
                        style={[
                            styles.iconSymbol,
                            {
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                paddingRight: 15,
                                textAlign: 'right',
                            },
                        ]}
                        onPress={() => this.goToNewRoute('/notifications')}
                    >
                        {IconEnums['bell']}
                    </Text>
                    {!this.props.navEnabled && (
                        <View
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(65,143,222,.5)',
                            }}
                        />
                    )}
                </View>
            )
        }
    }

    render() {
        HeaderContext = this
        HeaderLogic.context = HeaderContext
        return (
            <View
                style={{
                    backgroundColor: ColorEnums.danube,
                    flexDirection: 'row',
                    paddingLeft: 15,
                    height: 70,
                    zIndex: 99,
                }}
            >
                <View style={{flexDirection: 'row', width: '80%'}}>
                    {this.props.showNav ? (
                        <View style={{flexDirection: 'row'}}>
                            <View style={{justifyContent: 'center'}}>
                                <Text
                                    style={[styles.iconSymbol, {fontSize: 54, marginRight: 15}]}
                                    onPress={
                                        this.props.navEnabled
                                            ? () => this.goToNewRoute('/personalInfo')
                                            : undefined
                                    }
                                >
                                    {IconEnums['user-circle']}
                                </Text>
                            </View>
                            {this.state.HeaderInfo.FirstName && (
                                <View style={{justifyContent: 'center'}}>
                                    <View>
                                        <Text
                                            style={[
                                                styleFunctions.containerText(),
                                                {color: ColorEnums.white, padding: 0},
                                            ]}
                                        >
                                            Dr. {HeaderContext.state.HeaderInfo.FirstName}{' '}
                                            {HeaderContext.state.HeaderInfo.LastName}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: 'white',
                                            paddingBottom: 2,
                                            paddingRight: 10,
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text style={styles.headerInfoText}>
                                            ABA ID: {HeaderContext.state.HeaderInfo.ABAId}
                                        </Text>
                                        {this.state.HeaderInfo.PrincipalCertNumber && (
                                            <Text style={styles.headerInfoText}>
                                                {' '}
                                                | CERT #:{' '}
                                                {HeaderContext.state.HeaderInfo.PrincipalCertNumber}
                                            </Text>
                                        )}
                                    </View>
                                    <Text style={[styles.headerInfoText, {paddingTop: 1}]}>
                                        {this.state.HeaderInfo.Email}
                                    </Text>
                                </View>
                            )}
                        </View>
                    ) : (
                        <View style={{padding: HeaderContext.GENERAL_PADDING}}>
                            <TouchableHighlight onPress={this.toggleNav} testID="sideNavToggle">
                                <Text style={styles.iconSymbol}>{IconEnums['menu']}</Text>
                            </TouchableHighlight>
                        </View>
                    )}
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        padding: HeaderContext.GENERAL_PADDING,
                        width: '20%',
                        justifyContent: 'flex-end',
                    }}
                >
                    {this.props.print && this.state.showPrintIcon && (
                        <Text
                            style={[
                                styles.iconSymbol,
                                {
                                    fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                    paddingRight: 15,
                                    textAlign: 'right',
                                },
                            ]}
                            onPress={() => {
                                {
                                    this.props.beforePrint && this.props.beforePrint()
                                }

                                this.props.shellContext.setState({displayPrintInfo: 'flex'}, () => {
                                    window.document.close()
                                    window.print()
                                    this.props.shellContext.setState({displayPrintInfo: 'none'}, () => {
                                        if (this.props.afterPrint) {
                                            this.props.afterPrint()
                                        }
                                    })
                                })
                            }}
                        >
                            {IconEnums['print']}
                        </Text>
                    )}

                    {this.renderBellIcon()}
                </View>

                {this.state.showResponsiveNav && (
                    <View
                        style={{
                            position: 'fixed',
                            height: '100vh',
                            width: '100vw',
                            zIndex: 98,
                            flexDirection: 'row',
                            flex: 2,
                            left: 0,
                            overflow: 'auto',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        <TouchableHighlight
                            onPress={this.toggleNav}
                            style={{
                                position: 'absolute',
                                zIndex: 99,
                                marginTop: 10,
                                marginLeft: 25,
                            }}
                            testID="sideNavClose"
                        >
                            <Text style={[styles.iconSymbol, {fontSize: 18}]}>{IconEnums['times']}</Text>
                        </TouchableHighlight>
                        <View style={{width: 275, opacity: 1}}>
                            <SideNavigation
                                showNav={this.props.navEnabled}
                                headerInfo={HeaderContext.state.HeaderInfo}
                            />
                        </View>
                        <TouchableOpacity
                            style={{flexGrow: 2, cursor: 'pointer'}}
                            onPress={this.toggleNav}
                            testID="toggleNavHeader"
                        />
                    </View>
                )}

                {HeaderContext.state.CallingApi && (
                    <View style={styles.loadingOverlay}>
                        <Text>Loading Header Information</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

Header.defaultProps = {
    hideBellIcon: false,
    navEnabled: true,
}

Header.propTypes = {
    hideBellIcon: PropTypes.bool,
    navEnabled: PropTypes.bool,
}
export default connect(mapStateToProps)(Header)
