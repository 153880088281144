import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {StyleSheet, Text, TextInput, TouchableHighlight, View} from 'react-native'

import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        flexDirection: 'row',
        height: 40,
        justifyContent: 'center',
    },
    textInput: {
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 16,
        height: 40,
        textAlignVertical: 'center',
    },
})

export default class ABAIncrementDecrement extends Component {
    maxValue
    minValue
    stepValue
    width

    constructor(props) {
        super(props)
        let borderStyle = this.getStyleForStatus(props.status)

        this.state = {
            borderColor: borderStyle.borderColor,
            borderWidth: borderStyle.borderWidth,
            decrementDisabled:
                props.status === GeneralEnums.textInputStatus.disabled ||
                props.decrementDisabled ||
                parseInt(props.inputValue) <= props.minValue,
            incrementDisabled:
                props.status === GeneralEnums.textInputStatus.disabled ||
                props.incrementDisabled ||
                parseInt(props.inputValue) >= props.maxValue,
            inputValue: props.inputValue,
            status: props.status,
        }

        this.maxValue = props.maxValue != undefined ? props.maxValue : 999
        this.minValue = props.minValue != undefined ? props.minValue : -999
        this.stepValue = props.stepValue || 1
        this.width = props.width || '100%'

        if (this.state.incrementDisabled === true) {
            this.maxValue = this.state.inputValue
        }
        if (this.state.decrementDisabled === true) {
            this.minValue = this.state.inputValue
        }
    }

    componentDidUpdate(previousProps) {
        if (this.props.inputValue !== previousProps.inputValue) {
            let decrementDisabled = false,
                incrementDisabled = false
            if (parseInt(this.props.inputValue) >= this.maxValue) {
                incrementDisabled = true
            }
            if (parseInt(this.props.inputValue) <= this.minValue) {
                decrementDisabled = true
            }
            this.setState({
                decrementDisabled: decrementDisabled,
                incrementDisabled: incrementDisabled,
                inputValue: this.props.inputValue,
            })
        }
        if (this.props.decrementDisabled !== previousProps.decrementDisabled) {
            this.setState({decrementDisabled: this.props.decrementDisabled})
        }
        if (this.props.incrementDisabled !== previousProps.incrementDisabled) {
            this.setState({incrementDisabled: this.props.incrementDisabled})
        }
        if (this.props.status !== previousProps.status) {
            let borderStyle = this.getStyleForStatus(this.props.status)
            this.setState({
                borderColor: borderStyle.borderColor,
                borderWidth: borderStyle.borderWidth,
                decrementDisabled:
                    this.props.decrementDisabled ||
                    parseInt(this.props.inputValue) <= this.minValue ||
                    this.props.status === GeneralEnums.textInputStatus.disabled,
                incrementDisabled:
                    this.props.incrementDisabled ||
                    parseInt(this.props.inputValue) >= this.maxValue ||
                    this.props.status === GeneralEnums.textInputStatus.disabled,
            })
        }
    }

    renderIcon() {
        let targetColor,
            targetIcon = null

        switch (this.props.status) {
            case GeneralEnums.textInputStatus.disabled:
                targetColor = ColorEnums.blueGray
                targetIcon = IconEnums['lock']
                break
            case GeneralEnums.textInputStatus.error:
                targetColor = ColorEnums.pomegranate
                targetIcon = IconEnums['times']
                break
            case GeneralEnums.textInputStatus.success:
                targetColor = ColorEnums.surfGreen
                targetIcon = IconEnums['check']
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetColor = ColorEnums.blueGray
                targetIcon = null
                break
        }

        return (
            <View style={{alignItems: 'center', justifyContent: 'center', width: '15%'}}>
                <Text
                    style={{
                        color: targetColor,
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        fontSize: 14,
                        paddingLeft: 5,
                    }}
                >
                    {targetIcon}
                </Text>
            </View>
        )
    }

    getStyleForStatus(status) {
        switch (status) {
            case GeneralEnums.textInputStatus.disabled:
                return {borderColor: ColorEnums.blueGray, borderWidth: 2}
            case GeneralEnums.textInputStatus.error:
                return {borderColor: ColorEnums.pomegranate, borderWidth: 2}
            case GeneralEnums.textInputStatus.success:
                return {borderColor: ColorEnums.surfGreen, borderWidth: 2}
            case GeneralEnums.textInputStatus.default:
            default:
                return {borderColor: null, borderWidth: 0}
        }
    }

    updateValue(text) {
        let decrementDisabled, incrementDisabled
        if (parseInt(text) >= this.maxValue) {
            decrementDisabled = false
            incrementDisabled = true
            text = this.maxValue.toString()
        } else if (parseInt(text) <= this.minValue) {
            decrementDisabled = true
            incrementDisabled = false
            text = this.minValue.toString()
        }
        this.setState({
            decrementDisabled: decrementDisabled,
            incrementDisabled: incrementDisabled,
            inputValue: text,
        })
        this.props.onChangeText(text)
    }

    render() {
        return (
            <View style={{flexDirection: 'row', width: this.width}}>
                <View style={{flexDirection: 'row', justifyContent: 'center', width: '85%'}}>
                    <TouchableHighlight
                        disabled={this.state.decrementDisabled === true}
                        onPress={() => {
                            let targetValue = parseInt(this.state.inputValue || '0') - this.stepValue
                            this.updateValue(targetValue)
                        }}
                        style={[
                            styles.button,
                            {
                                backgroundColor:
                                    this.state.decrementDisabled === true
                                        ? ColorEnums.blueGray
                                        : ColorEnums.danube,
                                borderBottomLeftRadius: 5,
                                borderTopLeftRadius: 5,
                                borderBottomColor: this.state.borderColor,
                                borderBottomWidth: this.state.borderWidth,
                                borderLeftColor: this.state.borderColor,
                                borderLeftWidth: this.state.borderWidth,
                                borderTopColor: this.state.borderColor,
                                borderTopWidth: this.state.borderWidth,
                                width: '30%',
                            },
                        ]}
                        underlayColor={ColorEnums.lightBlue}
                        testID="ABADecrement"
                    >
                        <Text
                            style={{
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                color: ColorEnums.white,
                            }}
                        >
                            {IconEnums['minus']}
                        </Text>
                    </TouchableHighlight>

                    {(this.props.allowManualEntry === false ||
                        this.props.status === GeneralEnums.textInputStatus.disabled) && (
                        <View
                            style={[
                                {
                                    alignItems: 'center',
                                    borderBottomColor: this.state.borderColor,
                                    borderBottomWidth: this.state.borderWidth,
                                    borderTopColor: this.state.borderColor,
                                    borderTopWidth: this.state.borderWidth,
                                    justifyContent: 'center',
                                    width: '40%',
                                },
                            ]}
                        >
                            <Text>{this.state.inputValue}</Text>
                        </View>
                    )}
                    {(this.props.allowManualEntry === undefined ||
                        this.props.allowManualEntry === true) &&
                        this.props.status !== GeneralEnums.textInputStatus.disabled && (
                            <TextInput
                                onBlur={() => {
                                    if (
                                        this.state.inputValue.length === 0 ||
                                        isNaN(this.state.inputValue)
                                    ) {
                                        this.updateValue(0)
                                    } else if (!Number.isInteger(this.state.inputValue)) {
                                        this.updateValue(Math.floor(this.state.inputValue))
                                    }
                                }}
                                onChangeText={(text) => {
                                    this.updateValue(text)
                                }}
                                style={[
                                    styles.textInput,
                                    {
                                        backgroundColor: ColorEnums.backgroundGray,
                                        borderBottomColor: this.state.borderColor,
                                        borderBottomWidth: this.state.borderWidth,
                                        borderTopColor: this.state.borderColor,
                                        borderTopWidth: this.state.borderWidth,
                                        textAlign: 'center',
                                        width: '40%',
                                    },
                                ]}
                                underlineColorAndroid="transparent"
                                value={
                                    this.state.inputValue != null ? this.state.inputValue.toString() : ''
                                }
                                testID="ABAIncrementDecrementInput"
                            />
                        )}

                    <TouchableHighlight
                        disabled={this.state.incrementDisabled === true}
                        onPress={() => {
                            let targetValue = parseInt(this.state.inputValue || '0') + this.stepValue
                            this.updateValue(targetValue)
                        }}
                        style={[
                            styles.button,
                            {
                                backgroundColor:
                                    this.state.incrementDisabled === true
                                        ? ColorEnums.blueGray
                                        : ColorEnums.danube,
                                borderBottomRightRadius: 5,
                                borderTopRightRadius: 5,
                                borderBottomColor: this.state.borderColor,
                                borderBottomWidth: this.state.borderWidth,
                                borderRightColor: this.state.borderColor,
                                borderRightWidth: this.state.borderWidth,
                                borderTopColor: this.state.borderColor,
                                borderTopWidth: this.state.borderWidth,
                                width: '30%',
                            },
                        ]}
                        underlayColor={ColorEnums.lightBlue}
                        testID="ABAIncrement"
                    >
                        <Text
                            style={{
                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                color: ColorEnums.white,
                            }}
                        >
                            {IconEnums['plus']}
                        </Text>
                    </TouchableHighlight>
                </View>
                {this.renderIcon()}
            </View>
        )
    }
}

ABAIncrementDecrement.propTypes = {
    allowManualEntry: PropTypes.bool,
    decrementDisabled: PropTypes.bool,
    incrementDisabled: PropTypes.bool,
    inputValue: PropTypes.string.isRequired,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    onChangeText: PropTypes.func.isRequired,
    status: PropTypes.number,
    stepValue: PropTypes.number,
    width: PropTypes.string,
}
