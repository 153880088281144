import React, {Component} from 'react'
import {
    Dimensions,
    Image,
    Keyboard,
    Platform,
    Text,
    TouchableHighlight,
    StyleSheet,
    View,
} from 'react-native'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import BottomNavigationLogic from '../logic/bottomnavigationLogic'
import MobileMenu from './mobileMenu'
import NavigationHelper from '../../helpers/NavigationHelper'
import MOCAActive from '../../assets/images/MOCA-Active.png'
import MOCAInactive from '../../assets/images/MOCA-Inactive.png'
import DashboardIcon from '../../assets/images/bottomNav/tachometer-alt.png'
import DashboardActiveIcon from '../../assets/images/bottomNav/tachometer-alt-active.png'
import ProgressReportIcon from '../../assets/images/bottomNav/clipboard.png'
import ProgressReportActiveIcon from '../../assets/images/bottomNav/clipboard-active.png'
import BellIcon from '../../assets/images/bottomNav/bell.png'
import BellActiveIcon from '../../assets/images/bottomNav/bell-active.png'
import MenuIcon from '../../assets/images/bottomNav/bars.png'
import MenuActiveIcon from '../../assets/images/bottomNav/bars-active.png'

export default class BottomNavigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showMenu: false,
            showBottomNav: true,
            bottomNavOptions: [],
            notificationCount: null,
        }

        this.styles = StyleSheet.create({
            iconSymbol: {
                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                color: ColorEnums.lightGray,
                fontSize: 32,
                marginLeft: 'auto',
                marginRight: 'auto',
            },

            iconContainer: {
                backgroundColor: ColorEnums.aquaHaze,
                width: '20%',
                height: Dimensions.get('window').height * 0.1,
            },

            iconSubtitle: {
                fontSize: 10,
                fontFamily: 'Lato',
                color: ColorEnums.lightGray,
                fontWeight: 'bold',
                textAlign: 'center',
                paddingTop: 5,
            },

            iconCentered: {
                marginTop: 'auto',
                marginBottom: 'auto',
            },
        })

        this.iconColor = this.iconColor.bind(this)
        this.iconAction = this.iconAction.bind(this)
    }

    componentDidMount() {
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.keyboardDidShow)
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.keyboardDidHide)
        BottomNavigationLogic.GetUserRoleId().then((roleId) => {
            let bottomNavOptions = [
                {
                    name: 'Dashboard',
                    icon: DashboardIcon,
                    url: '/dashboard',
                    activeIcon: DashboardActiveIcon,
                },
                {
                    name: 'Progress Report',
                    icon: ProgressReportIcon,
                    url: '/myProgressReport',
                    activeIcon: ProgressReportActiveIcon,
                },
                {
                    name: 'Notifications',
                    icon: BellIcon,
                    url: '/notifications',
                    activeIcon: BellActiveIcon,
                },
                {
                    name: 'Menu',
                    icon: MenuIcon,
                    url: null,
                    activeIcon: MenuActiveIcon,
                },
            ]
            if (roleId === 1) {
                bottomNavOptions.splice(2, 0, {
                    name: 'MOCA Minute',
                    icon: MOCAInactive,
                    url: '/mocaMinuteLanding',
                    activeIcon: MOCAActive,
                })
            }

            this.setState({bottomNavOptions})
        })

        BottomNavigationLogic.GetBellNotificationsCount().then((notificationCount) => {
            this.setState({notificationCount})
        })
    }

    componentWillUnmount() {
        this.keyboardDidShowListener.remove()
        this.keyboardDidHideListener.remove()
    }

    keyboardDidShow() {
        bottomNavContext.setState({showBottomNav: false})
    }

    keyboardDidHide() {
        bottomNavContext.setState({showBottomNav: true})
    }

    iconColor(iconUrl) {
        if (
            (this.props.url === iconUrl && !this.state.showMenu) ||
            (iconUrl === null && this.state.showMenu === true)
        ) {
            return {color: ColorEnums.danube}
        }
    }

    iconAction(iconUrl) {
        //If the icon is for our current page but the menu is currently open, then the onPress function will just be set
        //to close the menu if clicked on.  This prevents us from having to reload our page.  Otherwise, the icon's onPress
        //function will just be set to navigate to it's associated page.
        if (this.props.url === iconUrl && this.state.showMenu === true) {
            return this.setState({showMenu: false})
        }

        return NavigationHelper.GoToPage(GeneralEnums.navigationType.push, iconUrl)
    }

    render() {
        if (Platform.OS === 'web') {
            return null
        }

        bottomNavContext = this
        BottomNavigationLogic.context = bottomNavContext

        return (
            <View
                style={
                    this.state.showMenu && {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}
                }
            >
                {this.state.showMenu && (
                    <View style={{flex: 1}}>
                        <MobileMenu currentUrl={this.props.url} bottomNavContext={bottomNavContext} />
                    </View>
                )}
                {this.state.showBottomNav && (
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderTopColor: ColorEnums.lightGray,
                            borderTopWidth: 1,
                            backgroundColor: ColorEnums.aquaHaze,
                        }}
                    >
                        {this.state.bottomNavOptions.map((navOption, index) => {
                            if (navOption.name === 'Menu') {
                                return (
                                    <TouchableHighlight
                                        style={this.styles.iconContainer}
                                        key={navOption}
                                        onPress={() => this.setState({showMenu: !this.state.showMenu})}
                                        testID="bottomNevMenuButton"
                                    >
                                        <View style={this.styles.iconCentered}>
                                            <Image
                                                style={{
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginBottom: '-4%',
                                                    width: 38,
                                                    height: 38,
                                                }}
                                                source={
                                                    this.state.showMenu
                                                        ? navOption.activeIcon
                                                        : navOption.icon
                                                }
                                                resizeMode={'contain'}
                                            />
                                            <Text
                                                style={[
                                                    this.styles.iconSubtitle,
                                                    this.iconColor(navOption.url),
                                                ]}
                                            >
                                                {navOption.name}
                                            </Text>
                                        </View>
                                    </TouchableHighlight>
                                )
                            }
                            if (navOption.name === 'Notifications') {
                                return (
                                    <TouchableHighlight
                                        style={this.styles.iconContainer}
                                        key={navOption}
                                        onPress={() => {
                                            this.iconAction(navOption.url)
                                        }}
                                        testID="bottomNavNotificationsButton"
                                    >
                                        <View style={this.styles.iconCentered}>
                                            <View>
                                                <Image
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        marginBottom: '-4%',
                                                        width: 38,
                                                        height: 38,
                                                    }}
                                                    source={
                                                        this.props.url === navOption.url &&
                                                        !this.state.showMenu
                                                            ? navOption.activeIcon
                                                            : navOption.icon
                                                    }
                                                    resizeMode={'contain'}
                                                />
                                                <Text
                                                    style={[
                                                        this.styles.iconSubtitle,
                                                        this.iconColor(navOption.url),
                                                    ]}
                                                >
                                                    {navOption.name}
                                                </Text>
                                            </View>
                                            {this.state.notificationCount > 0 && (
                                                <View
                                                    style={{
                                                        backgroundColor: ColorEnums.pomegranateDark,
                                                        height: 17,
                                                        width: 17,
                                                        borderRadius: 17 / 2,
                                                        position: 'absolute',
                                                        right: 20,
                                                        top: 4,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontFamily: 'Lato',
                                                            color: 'white',
                                                            fontSize: 9,
                                                        }}
                                                    >
                                                        {this.state.notificationCount}
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    </TouchableHighlight>
                                )
                            }
                            return (
                                <TouchableHighlight
                                    style={this.styles.iconContainer}
                                    key={navOption}
                                    onPress={() => {
                                        this.iconAction(navOption.url)
                                    }}
                                    testID={'bottomNavButton' + navOption.name}
                                >
                                    <View style={this.styles.iconCentered}>
                                        <Image
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                marginBottom: '-4%',
                                                width: 38,
                                                height: 38,
                                            }}
                                            source={
                                                this.props.url === navOption.url && !this.state.showMenu
                                                    ? navOption.activeIcon
                                                    : navOption.icon
                                            }
                                            resizeMode={'contain'}
                                        />
                                        <Text
                                            style={[
                                                this.styles.iconSubtitle,
                                                this.iconColor(navOption.url),
                                            ]}
                                        >
                                            {navOption.name}
                                        </Text>
                                    </View>
                                </TouchableHighlight>
                            )
                        })}
                    </View>
                )}
            </View>
        )
    }
}
