import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'
import clinicalActivityLogicStep2 from '../logic/clinicalActivityLogicStep2'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABADropdown from '../../../ABACustom/presentation/ABADropdown'
import ABAIncrementDecrement from '../../../ABACustom/presentation/ABAIncrementDecrement'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'

export default class ClinicalActivityStep2 extends Component {
    state = {
        CallingApi: false,
        ComponentWidth: null,
        PracticeTypeList: [],
        RequiredIncrementor: '',
        cert_practicetype: 'null',
        cert_anesthesiologydays: 0,
        cert_painmedicinedays: 0,
        cert_criticalcaremedicinedays: 0,
        cert_PediatricAnesthesiologyDays: 0,
        cert_HospiceandPalliativeMedicineDays: 0,
        cert_sleepmedicinedays: 0,
        cert_otheranesthesiology: 0,
        cert_acadays: 0,
        cert_nccdays: 0,
        cert_otheractivitydescription: '',
        incrementDisabled: false,
        InputStatus: {
            cert_practicetype: GeneralEnums.textInputStatus.default,
            cert_otheractivitydescription: GeneralEnums.textInputStatus.default,
            cert_anesthesiologydays: GeneralEnums.textInputStatus.default,
            cert_painmedicinedays: GeneralEnums.textInputStatus.default,
            cert_criticalcaremedicinedays: GeneralEnums.textInputStatus.default,
            cert_PediatricAnesthesiologyDays: GeneralEnums.textInputStatus.default,
            cert_HospiceandPalliativeMedicineDays: GeneralEnums.textInputStatus.default,
            cert_sleepmedicinedays: GeneralEnums.textInputStatus.default,
            cert_otheranesthesiology: GeneralEnums.textInputStatus.default,
            cert_nccdays: GeneralEnums.textInputStatus.default,
            cert_acadays: GeneralEnums.textInputStatus.default,
        },
    }

    styles = StyleSheet.create({
        IncrementView: {
            flexDirection: 'row',
            marginBottom: 10,
            paddingBottom: 2,
            paddingRight: 5,
            alignItems: 'center',
            borderBottomWidth: 1,
        },

        IncrementLabel: {
            fontSize: 14,
            width: '65%',
        },
    })

    componentDidMount() {
        clinicalActivityLogicStep2.GetLookupClinicalActivityPracticeTypes().then((PracticeTypeList) => {
            clinicalActivityLogicStep2.context.setState({PracticeTypeList}, () => {
                clinicalActivityLogicStep2.GetClinicalActivityData().then((CAData) => {
                    clinicalActivityLogicStep2.PopulateVariables(CAData)
                })
            })
        })
    }

    Save() {
        return clinicalActivityLogicStep2.SaveLogic()
    }

    Previous() {
        return clinicalActivityLogicStep2.Previous()
    }

    render() {
        clinicalActivityStep2Context = this
        clinicalActivityLogicStep2.context = clinicalActivityStep2Context
        let currentStep = 1
        let completionPercentage
        const {ProgramType, isConfirmationPage} = this.props
        const {
            Primary,
            Pain_Medicine,
            Critical_Care_Medicine,
            Pediatric_Anesthesiology,
            Hospice_and_Palliative_Medicine,
            Sleep_Medicine,
            Neurocritical_Care_Exam,
            Adult_Cardiac_Anesthesiology,
        } = GeneralEnums.program_type
        const {RequiredIncrementor} = clinicalActivityStep2Context.state

        if (!isConfirmationPage) {
            completionPercentage = (1 / registrationStepsDisplayContext.state.CAstepCount) * currentStep
        }
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) =>
                    HelperMethods.setComponentWidth(clinicalActivityLogicStep2.context, event)
                }
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text>
                            Page {currentStep} of {registrationStepsDisplayContext.state.CAstepCount}
                        </Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(
                                    clinicalActivityLogicStep2.context.state.ComponentWidth,
                                    1,
                                ),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(
                                    clinicalActivityLogicStep2.context.state.ComponentWidth,
                                    completionPercentage,
                                ),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(
                                    clinicalActivityLogicStep2.context.state.ComponentWidth,
                                    0.65,
                                ),
                            ]}
                        />
                    </View>
                )}
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '95%',
                            },
                        ]}
                    >
                        Practice Type Information
                    </Text>
                    <View style={{flexDirection: 'row', width: '100%'}}>
                        <View style={{flex: 1}}>
                            <ABADropdown
                                style={{marginBottom: 5}}
                                label="What is your primary practice? *"
                                placeholder="What is your primary practice? *"
                                options={clinicalActivityStep2Context.state.PracticeTypeList}
                                width="100%"
                                selectedValue={clinicalActivityStep2Context.state.cert_practicetype}
                                validationStatus={
                                    clinicalActivityStep2Context.state.InputStatus.cert_practicetype
                                }
                                onChange={(practiceType) =>
                                    clinicalActivityLogicStep2.practiceTypeChange(practiceType)
                                }
                            />
                        </View>
                    </View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        On average, how many days a week do you practice?
                        <ABARequiredAsterisk />
                    </Text>
                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Adult Cardiac Anesthesiology
                            {(RequiredIncrementor == 'cert_acadays' ||
                                ProgramType === Adult_Cardiac_Anesthesiology) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_acadays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange('cert_acadays', value)
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={clinicalActivityStep2Context.state.InputStatus.cert_acadays}
                            />
                        </View>
                    </View>
                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Anesthesiology
                            {(RequiredIncrementor == 'cert_anesthesiologydays' ||
                                ProgramType === Primary) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_anesthesiologydays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_anesthesiologydays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_anesthesiologydays
                                }
                            />
                        </View>
                    </View>
                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Critical Care Medicine
                            {(RequiredIncrementor == 'cert_criticalcaremedicinedays' ||
                                ProgramType === Critical_Care_Medicine) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_criticalcaremedicinedays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_criticalcaremedicinedays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_criticalcaremedicinedays
                                }
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Hospice and Palliative Medicine
                            {(RequiredIncrementor == 'cert_HospiceandPalliativeMedicineDays' ||
                                ProgramType === Hospice_and_Palliative_Medicine) && (
                                <ABARequiredAsterisk />
                            )}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_HospiceandPalliativeMedicineDays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_HospiceandPalliativeMedicineDays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_HospiceandPalliativeMedicineDays
                                }
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Neurocritical Care
                            {(RequiredIncrementor == 'cert_nccdays' ||
                                ProgramType === Neurocritical_Care_Exam) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_nccdays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange('cert_nccdays', value)
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={clinicalActivityStep2Context.state.InputStatus.cert_nccdays}
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Pain Medicine
                            {(RequiredIncrementor == 'cert_painmedicinedays' ||
                                ProgramType === Pain_Medicine) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_painmedicinedays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_painmedicinedays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus.cert_painmedicinedays
                                }
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Pediatric Anesthesiology
                            {(RequiredIncrementor == 'cert_PediatricAnesthesiologyDays' ||
                                ProgramType === Pediatric_Anesthesiology) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_PediatricAnesthesiologyDays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_PediatricAnesthesiologyDays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_PediatricAnesthesiologyDays
                                }
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Sleep Medicine
                            {(RequiredIncrementor == 'cert_sleepmedicinedays' ||
                                ProgramType === Sleep_Medicine) && <ABARequiredAsterisk />}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_sleepmedicinedays.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.PracticeDaysChange(
                                        'cert_sleepmedicinedays',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus.cert_sleepmedicinedays
                                }
                            />
                        </View>
                    </View>
                    <View
                        style={[
                            this.styles.IncrementView,
                            {width: this.state.ComponentWidth > 600 ? '60%' : '100%'},
                        ]}
                    >
                        <Text style={[styleFunctions.paragraphText(), this.styles.IncrementLabel]}>
                            Other Anesthesiology
                            {RequiredIncrementor == 'cert_otheranesthesiology' && (
                                <ABARequiredAsterisk />
                            )}
                        </Text>
                        <View style={{width: '35%'}}>
                            <ABAIncrementDecrement
                                inputValue={clinicalActivityStep2Context.state.cert_otheranesthesiology.toString()}
                                onChangeText={(value) =>
                                    clinicalActivityLogicStep2.OtherAnesthesiologyChange(
                                        'cert_otheranesthesiology',
                                        value,
                                    )
                                }
                                width="100%"
                                allowManualEntry={false}
                                minValue={0}
                                maxValue={7}
                                incrementDisabled={clinicalActivityStep2Context.state.incrementDisabled}
                                status={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_otheranesthesiology
                                }
                            />
                        </View>
                    </View>
                </View>

                {clinicalActivityStep2Context.state.cert_otheranesthesiology > 0 && (
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 15,
                                        paddingBottom: 15,
                                    },
                                ]}
                            >
                                You indicated that your clinical activity is related to 'Other
                                anesthesiology.' Please input the specific subdiscipline of
                                anesthesiology in which you are clinically active.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                    },
                                ]}
                            >
                                Details
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View>
                            <ABATextInputMultiline
                                maxLength={5000}
                                numberOfLines={8}
                                onChangeText={(text) =>
                                    clinicalActivityStep2Context.setState({
                                        cert_otheractivitydescription: text,
                                    })
                                }
                                value={clinicalActivityStep2Context.state.cert_otheractivitydescription}
                                textInputStatus={
                                    clinicalActivityStep2Context.state.InputStatus
                                        .cert_otheractivitydescription
                                }
                            />
                        </View>
                    </View>
                )}

                {clinicalActivityStep2Context.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Clinical Activity Page 1</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
