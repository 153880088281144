import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const VolunteerTrainingLogic = {
    context: null,

    SaveTrainingData() {
        return new Promise((resolve) => {
            const {volunteerTraining} = this.context.navigationData

            ABAPortalAPI.post('volunteerTraining/saveVolunteerTraining', volunteerTraining)
                .then((volunteerTrainingData) => {
                    resolve(volunteerTrainingData.data)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Volunteer Training Information')
                    resolve(null)
                })
        })
    },
}

export default VolunteerTrainingLogic
