import React, {Component} from 'react'
import {View, Text, Platform} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import WebView from 'react-native-webview'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import HeaderLogic from '../../components/logic/headerLogic'
import VolunteerTrainingLogic from '../logic/volunteerTrainingLogic'

function CompletedTraining(props) {
    const {FirstName, LastName} = props.DoctorName
    const {ExamName} = props
    return (
        <View>
            <View style={{justifyContent: 'center', alignItems: 'center', marginBottom: 15}}>
                <Text style={[styleFunctions.paragraphText(), {fontSize: 20, paddingBottom: 5}]}>
                    Congratulations {`${FirstName} ${LastName}`} for completing the {ExamName}!
                </Text>
            </View>
            <ABATouchableHighlight
                buttonStyle={styleFunctions.buttonClick()}
                onPressEvent={() =>
                    NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                }
                text="Return to Dashboard"
            />
        </View>
    )
}
class VolunteerTraining extends Component {
    state = {
        ComponentWidth: null,
        showIframe: true,
        callingApi: false,
        doctorName: {},
        height: 100,
    }

    async onMessage() {
        const {callingApi} = this.state
        if (callingApi) {
            const trainingInformation = await VolunteerTrainingLogic.SaveTrainingData()
            trainingInformation && this.setState({showIframe: false, callingApi: false})
        }
    }

    messageListener = (event) => {
        if (event.data.func === 'courseCompleted') {
            this.setState({callingApi: true}, () => {
                this.onMessage()
            })
        }
    }

    async componentDidMount() {
        const {AuthenticationToken} = this.navigationData
        const doctorInfo = await HeaderLogic.GetHeaderInfo(AuthenticationToken)
        if (doctorInfo) {
            const {FirstName, LastName} = doctorInfo
            this.setState({doctorName: {FirstName, LastName}})
        }

        if (Platform.OS === 'web') {
            window.addEventListener('message', this.messageListener, false)
        }
    }

    componentWillUnmount() {
        Platform.OS === 'web' && window.removeEventListener('message', this.messageListener)
    }

    render() {
        VolunteerTrainingLogic.context = this
        HelperMethods.setNavigationData(this)
        const {AuthenticationToken, volunteerTraining} = this.navigationData
        const {doctorName} = this.state
        const {volunteerTrainingModuleName, volunteerTrainingExamUrl} = volunteerTraining
        const renderCourse =
            Platform.OS === 'web' ? (
                <div>
                    {
                        <iframe
                            style={{
                                width: '100%',
                                minHeight: '65vh',
                            }}
                            src={volunteerTrainingExamUrl}
                        />
                    }
                </div>
            ) : (
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 18, fontWeight: 'bold', width: '90%'},
                    ]}
                >
                    Please log into the ABA Go portal using Google Chrome to complete this training
                    module.
                </Text>
            )

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        paddingBottom: 50,
                    }}
                >
                    <ABAHeader heading="Examiner Training" />
                    <ABAContainer
                        containerTitle={volunteerTrainingModuleName}
                        useMaxWidth={false}
                        activityText={'Saving Training Data...'}
                        showActivityIndicator={this.state.callingApi}
                    >
                        <View
                            style={{
                                width: '90%',
                                alignSelf: 'center',
                            }}
                            onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                        >
                            {this.state.showIframe ? (
                                renderCourse
                            ) : (
                                <CompletedTraining
                                    DoctorName={doctorName}
                                    ExamName={volunteerTrainingModuleName}
                                />
                            )}
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default VolunteerTraining
