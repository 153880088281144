import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const SubmitNseRequestLogic = {
    context: null,

    GetNSEBeginData: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.post('nserequestsinfo/GetNSEBegin', {})
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Information')
                    resolve(null)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetPreviousStep: function () {
        SubmitNseRequestLogic.context.child.Previous()
    },

    GetNextStep: function () {
        SubmitNseRequestLogic.context.child.Save()
    },
}

export default SubmitNseRequestLogic
