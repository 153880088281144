import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const CMEActivitiesTableLogic = {
    context: null,

    getCMEActivities: function () {
        return new Promise((resolve) => {
            CMEActivitiesTableLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getcmeactivities')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activities Information')
                    resolve(null)
                })
                .finally(function () {
                    CMEActivitiesTableLogic.context.setState({CallingApi: false})
                })
        })
    },
}
export default CMEActivitiesTableLogic
