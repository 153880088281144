import moment from 'moment'
import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import ValidationHelper from '../../helpers/ValidationHelper'

const RelapseSubstanceAbuseSubstanceLogic = {
    context: null,

    GetSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            const {SubstanceAbuseSubstanceID} = this.context.props
            let getParams = {
                params: {
                    SubstanceAbuseSubstanceID,
                },
            }

            ABAPortalAPI.get(`substanceabuseinfo/getSubstanceAbuseSubstance`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Accessing Substance Abuse Substances Record',
                    )
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            const {
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
                cert_SAsubstanceId,
            } = this.context.state
            ABAPortalAPI.post('substanceabuseinfo/updateSubstanceAbuseSubstance', {
                cert_substanceabuseid: substanceAbuseContext.state.SubstanceAbuseID,
                cert_Type: GeneralEnums.SASubstanceTypes.Relapse,
                cert_SAsubstanceId,
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Adding Substance Abused')
                    resolve(false)
                })
        })
    },

    DeleteSubstanceFromGrid() {
        return new Promise((resolve) => {
            const {SubstanceAbuseSubstanceID} = this.context.props
            let getParams = {
                params: {
                    SubstanceAbuseSubstanceID,
                },
            }

            ABAPortalAPI.post('substanceabuseinfo/deleteSubstanceAbuseSubstance', {}, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting Substance Abused')
                    resolve(false)
                })
        })
    },

    DeleteReferences() {
        return new Promise((resolve) => {
            const {relapseReferenceIDList} = this.context.state
            this.context.setState({CallingApi: true})
            ABAPortalAPI.post(`reference/deleteAllReferences`, relapseReferenceIDList)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting References')
                })
        })
    },

    DeleteSubstance() {
        this.context.setState({showDeleteConfirm: false})
        Promise.all([this.DeleteReferences(), this.DeleteSubstanceFromGrid()]).then(() =>
            this.context.props.UpdateGrid(),
        )
    },

    UpdateSubstanceAbuseSubstance() {
        if (!this.ValidateSubstance()) {
            return
        }

        this.AddSubstanceAbuseSubstance().then(() => {
            this.GetSubstanceAbuseSubstance().then((SASData) => {
                this.PopulateVariables(SASData)
                const {canEdit} = this.context.state
                this.context.setState({
                    canEdit: !canEdit,
                })
            })
        })
    },

    CancelSubstanceAbuseSubstanceChanges() {
        const {cert_SubstanceBeganDate, cert_name, cert_LengthofTime, cert_AvenueofAdministration} =
            this.context.state.originalSubstanceData
        this.context.setState({
            cert_SubstanceBeganDate,
            cert_name,
            cert_LengthofTime,
            cert_AvenueofAdministration,
            canEdit: false,
        })
    },
    CancelAddReference(relapseComponent) {
        relapseComponent.context.setState({
            collapsedReference: null,
        })
    },
    GetRelapseReferenceIDs() {
        return new Promise((resolve) => {
            const {ContactID} = substanceAbuseContext.state
            const {SubstanceAbuseSubstanceID} = this.context.props
            let getParams = {
                params: {
                    ContactID,
                    SubstanceAbuseSubstanceID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getRelapseReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    ValidateSubstance() {
        const {InputStatus, cert_SubstanceBeganDate} = this.context.state
        const {error} = GeneralEnums.textInputStatus

        if (
            !ValidationHelper.isDateFormatValid(
                cert_SubstanceBeganDate,
                GeneralEnums.DateCheckType.TodayAndPastOnly,
            )
        ) {
            InputStatus.cert_SubstanceBeganDate = error
        } else {
            InputStatus.cert_SubstanceBeganDate = GeneralEnums.textInputStatus.default
        }

        const Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        this.context.setState({InputStatus: InputStatus})
        return Validated
    },

    UpdateGrid(relapseComponent, validated = true) {
        if (!validated) {
            return
        }

        relapseComponent.GetRelapseReferenceIDs().then((relapseReferenceIDList) => {
            relapseComponent.context.setState({
                relapseReferenceIDList,
                addNewTreatmentFacility: false,
                referenceMissing: false,
                collapsedReference: null,
            })
        })

        relapseComponent.context.showContinue()
        relapseComponent.context.props.UpdateGrid()
    },

    PopulateVariables(substanceAbuseData, RelapseReferenceIDs) {
        const substanceBeganDate =
            substanceAbuseData.cert_SubstanceBeganDate &&
            moment(new Date(substanceAbuseData.cert_SubstanceBeganDate)).format('MM/DD/YYYY')
        this.context.setState({
            originalSubstanceData: substanceAbuseData,
            cert_SubstanceBeganDate: substanceBeganDate,
            cert_name: substanceAbuseData.cert_name,
            cert_SAsubstanceId: substanceAbuseData.cert_SAsubstanceId,
            relapseReferenceIDList: RelapseReferenceIDs || [],
        })
    },
}

export default RelapseSubstanceAbuseSubstanceLogic
