import {Platform} from 'react-native'

import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const SideNavigationLogic = {
    context: null,

    getNavigationLinks: function () {
        return new Promise((resolve) => {
            SideNavigationLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('dashboard/getNavigationMenu')
                .then(function (response) {
                    SideNavigationLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    SideNavigationLogic.context.setState({CallingApi: false})
                    //HelperMethods.apiErrorHandler(error,"Error Retrieving Navigation Links");
                    resolve(null)
                })
        })
    },

    getTemporaryAuthToken: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('authentication/getTemporaryAuthToken')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Token for Status Letter')
                    resolve(null)
                })
        })
    },
}

export default SideNavigationLogic
