import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import ACAPathwayPage from '../presentation/acaPathwayPage'
import ACAExamPath1 from '../../ACASteps/presentation/acaExamPath1'
import ACAExamPath2 from '../../ACASteps/presentation/acaExamPath2'
import ACAExamPath3 from '../../ACASteps/presentation/acaExamPath3'
import ACAExamPath4 from '../../ACASteps/presentation/acaExamPath4'

const acaPathwayLogic = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            acaPathwayLogic.context.setState({CallingApi: true})

            ABAPortalAPI.post('clinicalactivity/GetClinicalActivityBegin', {
                Order: acaPathwayLogic.context.props.Order,
                CR2ID: acaPathwayLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    acaPathwayLogic.context.setState({CallingApi: false})
                })
        })
    },

    ValidatePage() {
        const {acaPathway} = this.context.state

        if (!acaPathway) {
            this.context.setState({
                acaError: true,
            })
            return false
        }

        return true
    },

    GetACANBECerts() {
        return new Promise((resolve) => {
            acaPathwayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getACANBECerts`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving ACA NBE Certification Options',
                    )
                })
                .finally(() => {
                    acaPathwayLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetACAPostTrainingOptions() {
        return new Promise((resolve) => {
            acaPathwayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/acaPostTraining`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving ACA Post Training Options')
                })
                .finally(() => {
                    acaPathwayLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetACAPathwayOptions() {
        return new Promise((resolve) => {
            acaPathwayLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getACAPathways`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving ACA Pathway Options')
                })
                .finally(() => {
                    acaPathwayLogic.context.setState({CallingApi: false})
                })
        })
    },

    UpdateACAPathwayPage() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {acaPathway, clinicalActivityId, originalACAPathway, nbeCert, postTraining} =
                this.context.state
            const {ProgramType: re_ProgramType} = this.context.props
            const {ContactId} = clinicalActivityContext.state

            // if user is changing their original ACA Pathway choice, we need to reset the
            // NBE Cert and Post Training options.  If not wrong data may load on the next
            // page since each of the next potential pages pulls from the same option set (cert_acanbecert and cert_acaposttraining)
            const resetCertAndPostTraining = acaPathway === originalACAPathway

            ABAPortalAPI.post(`clinicalactivity/updateACAPathwayPage`, {
                re_ProgramType,
                ContactId,
                cert_clinicalactivityid: clinicalActivityId,
                cert_acapath: acaPathway,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
                cert_acanbecert: resetCertAndPostTraining ? nbeCert : null,
                cert_acaposttraining: resetCertAndPostTraining ? postTraining : null,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    acaPathwayLogic.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            clinicalActivityContext.setState({
                cert_acapath: this.context.state.acaPathway,
            })

            this.UpdateACAPathwayPage().then((response) => {
                if (response) {
                    const {acaPathway} = acaPathwayLogic.context.state
                    let nextComponentStep

                    switch (acaPathway) {
                        case 1:
                            nextComponentStep = ACAExamPath1
                            break
                        case 2:
                            nextComponentStep = ACAExamPath2
                            break
                        case 3:
                            nextComponentStep = ACAExamPath3
                            break
                        case 4:
                            nextComponentStep = ACAExamPath4
                            break
                    }

                    clinicalActivityContext.setState({
                        CurrentComponentStep: nextComponentStep,
                        PreviousComponentStep: ACAPathwayPage,
                    })

                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },
}

export default acaPathwayLogic
