import moment from 'moment'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

export default ReportQIActivitiesLogic = {
    context: null,

    GetIMP: function (IMPID) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    IMPID,
                },
            }
            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`imp/getIMP`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving IMP Data')
                    resolve(null)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetReportingActivityTypesFromCrm() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`qualityimprovement/getReportingActivityTypesFromCrm`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reporting Activity Types')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SetReportingActivityData(reportingActivityID, reportingActivityTypes) {
        const reportingActivity = reportingActivityTypes.find(
            (reportingActivity) => reportingActivity.value === reportingActivityID,
        )
        if (reportingActivity) {
            this.context.setState({
                reportingActivity: reportingActivityID,
                reportingActivityName: reportingActivity.value,
                crt_maxpoints: reportingActivity.crt_maxpoints,
                crt_pointsperhour: reportingActivity.crt_pointsperhour,
            })
        }
    },

    SetHoursReported(crt_HoursReported) {
        const regex = /^[0-9]{0,4}$/
        if (crt_HoursReported.match(regex))
            this.context.setState({
                crt_HoursReported,
            })
    },

    ValidateFormData: function () {
        const {InputStatus} = this.context.state
        const {error} = GeneralEnums.textInputStatus
        let defaultStatus = GeneralEnums.textInputStatus.default
        const {crt_datecompleted, reportingActivity, crt_HoursReported} = this.context.state

        // We need to change back any text inputs that had an error to default.  This is because  on re-submittal,
        // some required fields could have been filled out but others were missed.  We need those former errors
        // set back to default.  This is essentially giving us a clean pallete to work with each submittal.
        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                InputStatus[key] = defaultStatus
            }
        }
        let crt_datecompletedError = ''
        let currentDate = moment(new Date(), 'MM/DD/YYYY', true)
        const dateCompleted = moment(crt_datecompleted, 'MM/DD/YYYY', true)
        let pointOfCareRange = new Date()
        pointOfCareRange.setDate(pointOfCareRange.getDate() - 32)
        pointOfCareRangeDate = moment(pointOfCareRange, 'MM/DD/YYYY', true)

        if (
            !crt_datecompleted ||
            crt_datecompleted.length < 10 ||
            !ValidationHelper.isDateFormatValid(crt_datecompleted)
        ) {
            InputStatus.crt_datecompleted = error
            crt_datecompletedError = 'Please enter a valid date.'
        } else if (dateCompleted > currentDate) {
            InputStatus.crt_datecompleted = error
            crt_datecompletedError = "Please enter a date on or before today's date."
        }

        if (!reportingActivity || reportingActivity == 'null') {
            InputStatus.reportingActivity = error
        }

        //Point of care learning GUID
        if (
            reportingActivity === '42b55603-4473-e511-9cdf-005056ab0010' &&
            dateCompleted &&
            dateCompleted < pointOfCareRangeDate
        ) {
            InputStatus.crt_datecompleted = error
            crt_datecompletedError =
                'Point-of-care learning activities must be reported within 31 days of the case. '
        }

        if (!crt_HoursReported) {
            InputStatus.crt_HoursReported = error
        }

        //return false in the event our InputStatuses contains an error
        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                ReportQIActivitiesLogic.context.setState({InputStatus, crt_datecompletedError})
                return false
            }
        }
        return true
    },

    SaveReportingActivity: function () {
        ReportQIActivitiesLogic.context.setState({CallingApi: true})
        const {reportingActivity, reportingActivityName, crt_datecompleted, crt_HoursReported, IMPID} =
            ReportQIActivitiesLogic.context.state

        if (!ReportQIActivitiesLogic.ValidateFormData()) {
            ReportQIActivitiesLogic.context.setState({CallingApi: false})
            return
        }

        return new Promise((resolve) => {
            ABAPortalAPI.post('imp/saveIMP', {
                IMPID,
                IMPName: reportingActivityName,
                IMPType: reportingActivity,
                DateCompleted: crt_datecompleted,
                HoursReported: crt_HoursReported,
            })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Saving IMP Record')
                    resolve(false)
                })
                .finally(() => {
                    NavigationHelper.navigateBack()
                })
        })
    },

    PopulateVariables(activityData, impData) {
        if (activityData) {
            this.context.setState(
                {
                    reportingActivityTypes: activityData,
                },
                () => {
                    if (impData) {
                        const reportingActivityType = activityData.find(
                            (reportingActivity) => reportingActivity.value === impData.IMPType,
                        )
                        if (reportingActivityType) {
                            this.context.setState({
                                reportingActivity: reportingActivityType.value,
                                crt_maxpoints: reportingActivityType.crt_maxpoints,
                                crt_pointsperhour: reportingActivityType.crt_pointsperhour,
                                crt_HoursReported: impData.HoursReported.toString(),
                                crt_datecompleted: impData.DateCompleted,
                                IMPID: impData.IMPID,
                                reportingActivityName: reportingActivityType.label,
                            })
                        }
                    }
                },
            )
        }
    },
}
