import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import styleFunctions from '../../../styles/styleFunctions'
import clinicalActivityLogic from '../logic/clinicalActivityLogic'
import {ColorEnums} from '../../../helpers/generalEnums'

export default class ClinicalActivity extends Component {
    state = {
        CallingApi: false,
        CheckPrevious: true,
        CurrentComponentStep: null,
        PreviousComponentStep: null,
        ClinicalActivityID: null,
        ContactID: null,
        ClinicalActivityWorkPrivilege: null,
        shouldCaptureGraduation: false,
        showNCCPathway: false,
        cert_nccpath: null,
        cert_acapath: null,
        graduationDate: null,
    }

    componentDidMount() {
        clinicalActivityLogic.GetClinicalActivityData().then((CAData) => {
            clinicalActivityLogic.PopulateVariables(CAData)
        })
    }

    Save() {
        return clinicalActivityContext.child.Save()
    }

    Previous() {
        return clinicalActivityContext.child.Previous()
    }

    render() {
        clinicalActivityContext = this
        clinicalActivityLogic.context = clinicalActivityContext
        return (
            <View>
                {clinicalActivityContext.state.CurrentComponentStep && (
                    <clinicalActivityContext.state.CurrentComponentStep
                        ref={(child) => {
                            clinicalActivityContext.child = child
                        }}
                        ProgramType={clinicalActivityContext.props.ProgramType}
                        Order={clinicalActivityContext.props.Order}
                        CR2ID={clinicalActivityContext.props.CR2ID}
                        SubmittedSteps={clinicalActivityContext.props.SubmittedSteps}
                        IsViewRegistration={clinicalActivityContext.props.IsViewRegistration}
                        IsRecertification={clinicalActivityContext.props.IsRecertification}
                    />
                )}

                {clinicalActivityContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Clinical Activity</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
