import React, {Component, useState, useEffect} from 'react'
import {View, Text, StyleSheet, Platform} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import {ColorEnums, GeneralEnums, RemoteExam} from '../../helpers/generalEnums'
import ExaminerAvailabilityLogic from '../logic/examinerAvailabilityLogic'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABADisplayCard from '../../components/ABACustom/presentation/ABADisplayCard'
import ABATextInputMultiline from '../../components/ABACustom/presentation/ABATextInputMultiline'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ABARadio, ABARadioGroup} from '../../components/ABACustom/presentation/ABARadio'
import styleFunctions from '../../styles/styleFunctions'

const ExamView = ({exam, examinerAvailabilityOptionList}) => {
    const {
        evt_eventId,
        evt_name_display,
        exam_comment,
        exam_availability,
        exam_availability_status,
        exam_remoteexaminer,
        exam_availability_remote_status,
    } = exam
    const [comment, setComment] = useState(exam_comment)
    const [examAvailabilityOption, setExamAvailabilityOption] = useState(exam_availability)
    const [Remoteexam, setRemoteexam] = useState(exam_remoteexaminer)

    useEffect(() => {
        exam.exam_comment = comment
    }, [comment])

    useEffect(() => {
        exam.exam_availability = examAvailabilityOption
    }, [examAvailabilityOption])

    useEffect(() => {
        exam.exam_remoteexaminer = Remoteexam
    }, [Remoteexam])

    const indexOfSplit = evt_name_display.indexOf('-', 20)
    const title = evt_name_display.substring(0, indexOfSplit)
    const examDates = evt_name_display.substring(indexOfSplit + 1, evt_name_display.length)

    //Need to set title to save exam name correctly
    exam.title = title.trim()

    return (
        <ABADisplayCard
            key={evt_eventId}
            title={title}
            isExpanded={true}
            expandOnClick={false}
            style={{marginBottom: 50}}
        >
            <View>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {textAlign: 'center', fontWeight: 'bold', marginBottom: 10, paddingTop: 5},
                    ]}
                >
                    {examDates}
                </Text>
                <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 15, width: '100%', textAlign: 'center', marginBottom: 5},
                        ]}
                    >
                        Comments
                    </Text>
                    <View style={{width: '100%'}}>
                        <ABATextInputMultiline
                            maxLength={2000}
                            numberOfLines={4}
                            showCharactersRemaining={false}
                            onChangeText={(comment) => setComment(comment)}
                            value={comment}
                        />
                    </View>
                </View>
            </View>

            <View style={{paddingBottom: 10, paddingLeft: 10, paddingRight: 10}}>
                <ABADropdown
                    options={examinerAvailabilityOptionList}
                    onChange={(examinerAvailabilityOption) => {
                        setExamAvailabilityOption(examinerAvailabilityOption)
                    }}
                    selectedValue={examAvailabilityOption}
                    placeholder={'In Person'}
                    validationStatus={exam_availability_status}
                />
            </View>

            <View style={{flexDirection: 'column', paddingTop: 5}}>
                <Text style={styleFunctions.paragraphText()}>Available for Remote OSCE Scoring?</Text>

                <ABARadioGroup
                    onChange={(Remoteexam) => {
                        setRemoteexam(Remoteexam)
                    }}
                    defaultValue={Remoteexam}
                    itemsUnderlined={false}
                >
                    <ABARadio
                        value={RemoteExam.yes}
                        style={{paddingTop: 5, paddingBottom: 5}}
                    >
                        <Text style={styleFunctions.paragraphText()}>YES</Text>
                    </ABARadio>
                    <ABARadio
                        value={RemoteExam.no}
                        style={{paddingTop: 5, paddingBottom: 5}}
                    >
                        <Text style={styleFunctions.paragraphText()}>NO</Text>
                    </ABARadio>
                </ABARadioGroup>
            </View>
            <Text style={{color: ColorEnums.pomegranate}}>
                {exam_availability_remote_status === GeneralEnums.textInputStatus.error
                    ? 'Please select your availability.'
                    : undefined}
            </Text>
        </ABADisplayCard>
    )
}

export default class ExaminerAvailability extends Component {
    styles = StyleSheet.create({
        examAvailabilityContainer: {
            flex: 1,
            flexDirection: 'column',
            marginBottom: 20,
            width: '100%',
        },

        titleText: {
            fontFamily: 'Lato',
            color: ColorEnums.cloudBurst,
            fontSize: 18,
            fontWeight: 'bold',
            borderBottomColor: '#000',
            borderBottomWidth: 4,
            width: '40%',
            marginTop: 8,
            marginBottom: 8,
        },
    })

    state = {
        ComponentWidth: null,
        CallingApi: false,
        examinerAvailabilityList: [],
        examinerAvailabilityOptionList: [],
        examinerAvailabilityOption: 0,
        InputStatus: {
            examinerAvailabilityOption: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        Promise.all([
            ExaminerAvailabilityLogic.GetExaminerAvailability(),
            ExaminerAvailabilityLogic.GetExaminerAvailabilityOptions(),
        ]).then((examinerAvailability) =>
            ExaminerAvailabilityLogic.PopulateVariables(examinerAvailability),
        )
    }

    renderExamWeekCards = () => {
        return this.state.examinerAvailabilityList.map((examAvailability) => {
            return (
                <ExamView
                    key={examAvailability.evt_eventId}
                    exam={examAvailability}
                    examinerAvailabilityOptionList={this.state.examinerAvailabilityOptionList}
                />
            )
        })
    }

    render() {
        const {ComponentWidth} = this.state
        ExaminerAvailabilityLogic.context = this
        const {context} = ExaminerAvailabilityLogic
        HelperMethods.setNavigationData(context)
        const examWeekCards = this.renderExamWeekCards()

        return (
            <NavigationShell context={context}>
                <ABAKeyboardAwareScrollView
                    style={{width: '100%'}}
                    onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.ExaminerAvailability} />
                    <View style={{width: ComponentWidth > 600 ? '45%' : '75%', alignSelf: 'center'}}>
                        <View style={this.styles.examAvailabilityContainer}>
                            <View>
                                <Text
                                    style={[styleFunctions.paragraphText(), {fontSize: 16, padding: 15}]}
                                >
                                    We greatly appreciate your support as APPLIED Examiners. In planning
                                    for next year's administrations, we ask that you provide your
                                    availability and week preferences. Examiners should be prepared to
                                    administer exams at the Raleigh AIME Center at least one week per
                                    year and to score OSCEs remotely another week per year.
                                </Text>
                            </View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 16, padding: 15, fontWeight: 'bold'},
                                    ]}
                                >
                                    For each exam week below, please indicate your availability to
                                    administer exams in Raleigh by choosing 1st Choice, 2nd Choice, 3rd
                                    Choice, Not Available, or If Needed. Please use the comment section
                                    if you're available to come to Raleigh more than once next year.
                                    Additionally, for each exam week, please use the radio buttons to
                                    indicate when you are available for remote OSCE scoring.
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    padding: 15,
                                    justifyContent: 'space-around',
                                }}
                            >
                                {examWeekCards}
                            </View>

                            <View style={{paddingBottom: 15}}>
                                <ABATouchableHighlight
                                    onPressEvent={() => ExaminerAvailabilityLogic.SaveLogic()}
                                    isDisabled={this.state.CallingApi}
                                    text={'Submit'}
                                />
                            </View>
                        </View>
                    </View>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
