import {Platform} from 'react-native'

import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ViewReceiptsSummaryLogic = {
    context: null,

    getReceiptsSummary: function () {
        return new Promise((resolve) => {
            ViewReceiptsSummaryLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('receipts/getReceiptsSummary')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
                .finally(function () {
                    ViewReceiptsSummaryLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default ViewReceiptsSummaryLogic
