import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ExamInfoSubspecialtyLogic = {
    context: null,

    getCertifications: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('certification/getCertificationComponentDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    ExamInfoSubspecialtyLogic.context.setState({loadingSubspecialties: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certifications')
                    resolve(null)
                })
        })
    },
    getOpenSubspecialtyExams: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('registration/getOpenSubspecialtyExams')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    ExamInfoSubspecialtyLogic.context.setState({loadingSubspecialties: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certifications')
                    resolve(null)
                })
        })
    },
    createSubspecialtyCR2: function (ProgramType, IsRecert) {
        return new Promise((resolve) => {
            ABAPortalAPI.post('registration/createSubspecialtyCR2', {
                ProgramType,
                IsRecert,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Creating Registration')
                    resolve(null)
                })
        })
    },
}

export default ExamInfoSubspecialtyLogic
