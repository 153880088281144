import {GeneralEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import ValidationHelper from '../../../../helpers/ValidationHelper'
import SubstanceAbuseStep3 from '../presentation/substanceAbuseStep3'
import SubstanceAbuseStep5 from '../presentation/substanceAbuseStep5'

const SubstanceAbuseLogicStep4 = {
    context: null,

    GetSubstanceAbuseData() {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`substanceabuseinfo/getSAData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetRelapse1Options() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getRelapse1Options`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Relapse 1 Options')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetSubstanceAbuseSubstancesData() {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    SubType: GeneralEnums.SASubstanceTypes.Relapse,
                },
            }

            SubstanceAbuseLogicStep4.context.setState({CallingApi: true})
            ABAPortalAPI.get(`substanceabuseinfo/getSubstanceAbuseSubstancesData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Accessing Substance Abuse Substances Record',
                    )
                })
                .finally(() => {
                    SubstanceAbuseLogicStep4.context.setState({CallingApi: false})
                })
        })
    },

    SetRelapse1Option(cert_relapse1) {
        const {InputStatus} = this.context.state
        InputStatus.cert_relapse1 = GeneralEnums.textInputStatus.default

        this.context.setState({
            cert_relapse1,
            InputStatus,
        })
        this.context.showContinue()
    },

    AddSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {cert_SubstanceBeganDate, cert_name} = this.context.state
            ABAPortalAPI.post('substanceabuseinfo/updateSubstanceAbuseSubstance', {
                cert_substanceabuseid: substanceAbuseContext.state.SubstanceAbuseID,
                cert_Type: GeneralEnums.SASubstanceTypes.Relapse,
                cert_SubstanceBeganDate,
                cert_name,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Adding Relapse Substance')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddSubstanceToGrid() {
        if (!this.ValidateSubstance()) {
            return
        }

        this.AddSubstanceAbuseSubstance().then(() => {
            this.GetSubstanceAbuseSubstancesData().then((relapseSubstanceIDList) => {
                this.context.setState({
                    relapseSubstanceIDList,
                    cert_SubstanceBeganDate: '',
                    cert_name: '',
                    substancesMissing: false,
                })
            })
        })
    },

    ValidateSubstance() {
        const {InputStatus, cert_SubstanceBeganDate} = this.context.state
        const {error} = GeneralEnums.textInputStatus

        if (
            !ValidationHelper.isDateFormatValid(
                cert_SubstanceBeganDate,
                GeneralEnums.DateCheckType.TodayAndPastOnly,
            )
        ) {
            InputStatus.cert_SubstanceBeganDate = error
        } else {
            InputStatus.cert_SubstanceBeganDate = GeneralEnums.textInputStatus.default
        }

        const Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        this.context.setState({InputStatus: InputStatus})
        return Validated
    },

    UpdateGrid() {
        SubstanceAbuseLogicStep4.GetSubstanceAbuseSubstancesData().then((relapseSubstanceIDList) => {
            SubstanceAbuseLogicStep4.GetSAReferences().then((referenceIDList) => {
                SubstanceAbuseLogicStep4.context.setState({relapseSubstanceIDList, referenceIDList})
            })
        })
    },

    GetSAReferences() {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddExistingReference(referenceType, referenceID, context) {
        SubstanceAbuseLogicStep4.context.setState({
            showReferenceList: false,
        })

        context.setState({
            addNewReferenceID: referenceID,
            addNewTreatmentFacility: true,
        })
    },

    ShowReferenceList(context) {
        SubstanceAbuseLogicStep4.context.setState({
            showReferenceList: true,
            addNewReferenceContext: context,
        })
    },

    AddNewReference() {
        SubstanceAbuseLogicStep4.context.setState({
            showReferenceList: false,
        })

        const {addNewReferenceContext} = SubstanceAbuseLogicStep4.context.state
        addNewReferenceContext.setState({
            showReferenceList: false,
            addNewTreatmentFacility: true,
            addNewReferenceID: '-1',
        })
    },
    CancelAddReference() {
        SubstanceAbuseLogicStep4.context.setState({
            collapsedReference: null,
        })
    },
    PopulateVariables(SAData) {
        const [SubstanceAbuseData, Relapse1Options, relapseSubstanceIDList, referenceIDList] = SAData
        if (SubstanceAbuseData && Relapse1Options) {
            const {cert_relapse1} = SubstanceAbuseData
            this.context.setState(
                {
                    relapse1Options: Relapse1Options || [],
                },
                () => {
                    this.context.setState({
                        cert_relapse1: cert_relapse1 === null ? '-1' : cert_relapse1,
                        relapseSubstanceIDList: relapseSubstanceIDList || [],
                        referenceIDList: referenceIDList || [],
                    })
                },
            )
        }
    },

    UpdateSubstanceAbuseStep4() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {cert_relapse1} = this.context.state

            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep4`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
                cert_relapse1,
                rs_statuscode: substanceAbuseContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    ValidatePage() {
        let validated
        const {InputStatus, cert_relapse1, relapseSubstanceIDList} = this.context.state
        const {relapseSubstanceRefs} = this.context
        const {error, success} = GeneralEnums.textInputStatus
        InputStatus.cert_relapse1 = cert_relapse1 == -1 ? error : success
        validated = !Object.keys(InputStatus).some((input) => InputStatus[input] === 3)
        this.context.setState({InputStatus})

        if (cert_relapse1 == '1') {
            //If they have relapsed, check that at least one substance has been added
            if (relapseSubstanceIDList.length < 1) {
                this.context.setState({
                    substancesMissing: true,
                })
                validated = false
            }
            //Check if all relapse substances have a reference
            for (const ref in relapseSubstanceRefs) {
                const relapseSubstanceComp = relapseSubstanceRefs[ref]
                if (
                    relapseSubstanceComp &&
                    relapseSubstanceComp.state.relapseReferenceIDList.length === 0
                ) {
                    relapseSubstanceComp.setState({referenceMissing: true})
                    validated = false
                }
            }
        }
        return validated
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateSubstanceAbuseStep4().then(() => {
                substanceAbuseContext.setState({
                    CurrentComponentStep: SubstanceAbuseStep5,
                })
                resolve(false)
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            registrationStepsDisplayContext.setState({ShowContinue: true})

            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep3,
            })
            resolve(false)
        })
    },
}

export default SubstanceAbuseLogicStep4
