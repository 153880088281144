import {GeneralEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'

const CMEReleaseFormLogic = {
    context: null,

    GetText: function (Data) {
        return new Promise((resolve) => {
            CMEReleaseFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: Data.Crt_ReleaseType,
                ProgramType: CMEReleaseFormLogic.context.props.ProgramType,
                re_AdditionalSpecification: Data.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Release')
                })
                .finally(function () {
                    CMEReleaseFormLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetReleaseData: function () {
        return new Promise((resolve) => {
            CMEReleaseFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: CMEReleaseFormLogic.context.props.Order,
                CR2ID: CMEReleaseFormLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Acknowledgement Release')
                })
                .finally(function () {
                    CMEReleaseFormLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease: function (data) {
        var releaseOptionYes
        var releaseOptionNo
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOptionYes = true
            releaseOptionNo = false
        } else if (data.Crt_RecordStatus == 2) {
            releaseOptionYes = false
            releaseOptionNo = true
        }
        CMEReleaseFormLogic.context.setState({
            ReleaseOptionYes: releaseOptionYes,
            ReleaseOptionNo: releaseOptionNo,
            NoteText: data.Crt_Note || '',
            ReleaseData: data,
        })
    },

    CreateRelease: function () {
        return new Promise((resolve) => {
            CMEReleaseFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: CMEReleaseFormLogic.context.props.CR2ID,
                Order: CMEReleaseFormLogic.context.props.Order,
                Crt_RecordStatus: CMEReleaseFormLogic.context.state.ReleaseOptionYes ? 1 : 2,
                Crt_Note: CMEReleaseFormLogic.context.state.NoteText,
                ContactId: CMEReleaseFormLogic.context.state.ReleaseData.ContactId,
                Crt_ReleaseType: CMEReleaseFormLogic.context.state.ReleaseData.Crt_ReleaseType,
                Crt_ProgramType: CMEReleaseFormLogic.context.state.ReleaseData.Crt_ProgramType,
                Crt_ReleaseId: CMEReleaseFormLogic.context.state.ReleaseData.Crt_ReleaseId,
                LastName: CMEReleaseFormLogic.context.state.ReleaseData.LastName,
                FirstName: CMEReleaseFormLogic.context.state.ReleaseData.FirstName,
                rs_statuscode: CMEReleaseFormLogic.context.state.ReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    CMEReleaseFormLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    ReleaseOptionYesSelected: function () {
        CMEReleaseFormLogic.context.setState({
            ReleaseOptionYes: true,
            ReleaseOptionNo: false,
            cmeReleaseConfirmationError: GeneralEnums.textInputStatus.default,
        })
    },

    ReleaseOptionNoSelected: function () {
        CMEReleaseFormLogic.context.setState({
            ReleaseOptionNo: true,
            ReleaseOptionYes: false,
            cmeReleaseConfirmationError: GeneralEnums.textInputStatus.default,
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!CMEReleaseFormLogic.ValidatePage()) {
                resolve(false)
                return
            }

            CMEReleaseFormLogic.CreateRelease().then((response) => {
                if (response) {
                    resolve(true)
                }
                resolve(false)
            })
        })
    },

    ValidatePage() {
        const {ReleaseOptionYes, ReleaseOptionNo} = CMEReleaseFormLogic.context.state
        if (ReleaseOptionYes === undefined && ReleaseOptionNo === undefined) {
            CMEReleaseFormLogic.context.setState({
                cmeReleaseConfirmationError: GeneralEnums.textInputStatus.error,
            })
            return false
        }
        return true
    },
}

export default CMEReleaseFormLogic
