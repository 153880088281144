import CryptoJs from 'crypto-js'
import * as LocalAuthentication from 'expo-local-authentication'
import {Keyboard, Platform} from 'react-native'
import {appConfiguration} from '../../configuration.json'
import ABAPortalAPI, {setHeaderAuthToken} from '../../helpers/AxiosInstance'
import {BiometryTypes, BrowserTypes, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'
import {clearSettings, clearUserData, setUserData} from '../../redux/ActionCreators'

const isWeb = Platform.OS === 'web'
const Sentry = isWeb ? require('@sentry/react') : require('@sentry/react-native')

const aesSaltUsername = '5syfVyfGkPcjED4lNVAx*t5w'
const aesSaltPassword = 'gTq%S*XHx2XraUBtkFme8t1c'

if (Platform.OS !== 'web') {
    var AsyncStorage = require('@react-native-community/async-storage').default
}

const LoginLogic = {
    context: null,
    clearStoredCredentials() {
        if (Platform.OS === 'web') {
            return
        }

        AsyncStorage.multiRemove(['abausername', 'abapassword'])
    },
    enableBiometrics: async () => {
        await AsyncStorage.setItem('biometricsActive', 'true')
        LoginLogic.checkBiometrics()
    },
    disableBiometrics: async () => {
        await AsyncStorage.removeItem('biometricsActive')
        LoginLogic.checkBiometrics()
    },
    disableBiometricPopup: async () => {
        await AsyncStorage.setItem('seenBiometricMessage', 'true')
        LoginLogic.checkBiometrics()
    },
    checkBiometrics: async () => {
        if (Platform.OS === 'web') {
            return
        }

        const getBiometryType = (biometryType) => {
            if (biometryType.includes(BiometryTypes.FaceRecognition)) {
                if (Platform.OS === 'ios') return BiometryTypes.FaceIOS
                else return BiometryTypes.Biometrics
            }
            if (Platform.OS === 'ios') return BiometryTypes.FingerprintIOS
            return BiometryTypes.Biometrics
        }

        const seenBiometricMessage = (await AsyncStorage.getItem('seenBiometricMessage')) === 'true'
        const hasStoredCredentials = await LoginLogic.getStoredCredentials()
        const hasHardware = await LocalAuthentication.hasHardwareAsync()
        const isEnrolled = await LocalAuthentication.isEnrolledAsync()
        const available = hasHardware && isEnrolled
        const biometryType = await LocalAuthentication.supportedAuthenticationTypesAsync()

        if (!available || !hasStoredCredentials) {
            LoginLogic.context.props.setBiometricsAvailable(false, null)
            LoginLogic.context.props.SetBiometricsEnabled(false)
            return
        }
        const biometricsEnabled = (await AsyncStorage.getItem('biometricsActive')) === 'true'
        LoginLogic.context.props.setBiometricsAvailable(available, getBiometryType(biometryType))
        LoginLogic.context.props.setBiometricsEnabled(biometricsEnabled)
        LoginLogic.context.props.setBiometricsSeen(seenBiometricMessage)
    },
    performFPLogin: async function () {
        const biometricOptions = {
            promptMessage: 'Login to ABA',
        }
        const {success} = await LocalAuthentication.authenticateAsync(biometricOptions)

        if (success) {
            LoginLogic.context.setState({password: LoginLogic.context.state.hiddenPassword}, () =>
                LoginLogic.performLogin(),
            )
        }
    },
    getStoredCredentials: async function () {
        if (Platform.OS === 'web') {
            return
        }
        return new Promise(async (resolve) => {
            let encryptedUsername = await AsyncStorage.getItem('abausername')
            let encryptedPassword = await AsyncStorage.getItem('abapassword')
            if (encryptedUsername !== null && encryptedPassword !== null) {
                let decryptedUsername = CryptoJs.AES.decrypt(
                    encryptedUsername,
                    aesSaltUsername,
                ).toString(CryptoJs.enc.Utf8)
                let decryptedPassword = CryptoJs.AES.decrypt(
                    encryptedPassword,
                    aesSaltPassword,
                ).toString(CryptoJs.enc.Utf8)
                LoginLogic.context.setState({
                    username: decryptedUsername,
                    hiddenPassword: decryptedPassword,
                })
                resolve(true)
            }
            resolve(false)
        })
    },

    stayLoggedin: () => async () => {
        const data = await ABAPortalAPI.get('dashboard/getComponentsDisplay')
    },

    getUserData: () => async (dispatch) => {
        const {data} = await ABAPortalAPI.get('authentication/getUserData')
        const formattedUserData = {
            hasBackDoorAccess: data.HasBackDoorAccess,
        }
        dispatch(setUserData(formattedUserData))
    },

    logout: (error) => (dispatch, store) => {
        if (Platform.OS !== 'web') {
            const analytics = require('@react-native-firebase/analytics').default
            analytics().setUserId(null)
        }
        NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/login', {
            inactivityAlert: false,
            error,
        })
        const {settings} = store()
        Sentry.setUser({})
        ABAPortalAPI.get('authentication/logout')
            .then(function (response) {})
            .catch(function (error) {
                HelperMethods.apiErrorHandler(error, 'Error Logging Out')
            })
            .finally(() => {
                clearTimeout(settings.logoutTimer)
                dispatch(clearUserData())
                dispatch(clearSettings())
            })
    },

    async saveStoredCredentials(username, password) {
        if (Platform.OS === 'web') {
            return
        }
        let encryptedUsername = CryptoJs.AES.encrypt(username, aesSaltUsername)
        let encryptedPassword = CryptoJs.AES.encrypt(password, aesSaltPassword)
        await AsyncStorage.multiSet([
            ['abausername', encryptedUsername.toString()],
            ['abapassword', encryptedPassword.toString()],
        ])
        LoginLogic.checkBiometrics()
    },

    isCredentialsFormatValid(username, password) {
        username = username.trim()
        password = password.trim()
        let isValid = true

        if (username === '' && password === '') {
            navigationContext.props.history.push('/login', {
                error: GeneralEnums.AlertTypes.ImproperUsernameAndPassword,
            })
            isValid = false
        } else if (username === '') {
            navigationContext.props.history.push('/login', {
                error: GeneralEnums.AlertTypes.ImproperUsername,
            })
            isValid = false
        } else if (password === '') {
            navigationContext.props.history.push('/login', {
                error: GeneralEnums.AlertTypes.ImproperPassword,
            })
            isValid = false
        }

        let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let regExAbaId = /^(\d{4}-\d{4})$|^(\d{8})$/

        if (!regExAbaId.test(username) && !emailRegEx.test(username)) {
            navigationContext.props.history.push('/login', {
                error: GeneralEnums.AlertTypes.InvalidUsername,
            })
            isValid = false
        }

        if (!isValid) {
            LoginLogic.context.setState({password: ''})
        }
        return isValid
    },

    getCookie(cname) {
        var name = cname + '='
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return null
    },

    downForMaintenanceCheck: function (propState) {
        ABAPortalAPI.get('authentication/maintenanceCheck')
            .then(() => {
                // if there was an already existing maintenance error in prop.location.state.error,
                // the following logic will remove that to prevent the maintenance error still showing
                if (propState?.error && propState.error !== 401) {
                    navigationContext.props.history.replace({
                        ...navigationContext.props.history.location,
                        state: undefined,
                    })
                }
                return
            })
            .catch((error) => {
                HelperMethods.apiErrorHandler(error)
            })
    },

    versionCheck: function () {
        const platform = Platform.OS
        const buildNumber = appConfiguration.BuildNumber
        let getParams = {
            params: {
                platform,
                buildNumber,
            },
        }
        ABAPortalAPI.get('authentication/versionCheck', getParams).catch((error) => {
            HelperMethods.apiErrorHandler(error)
        })
    },

    clientBrowser: () => {
        ABAPortalAPI.get('authentication/clientbrowser')
            .then(function (response) {
                LoginLogic.context.setState({
                    IsSafari:
                        response.data.browser.name === BrowserTypes.Safari ||
                        response.data.browser.name === BrowserTypes.Safari_Mobile,
                    UserAgentData: response.data,
                })
                return response.data
            })
            .catch((error) => {
                HelperMethods.apiErrorHandler(error)
            })
    },

    authenticateToken: function (dispatchProps) {
        const {BuildNumber} = appConfiguration

        ABAPortalAPI.post('authentication/authenticate', {
            UserName: 'token',
            Platform: Platform.OS,
            BuildNumber,
        })
            .then(function (response) {
                if (response.data?.AuthenticationToken) {
                    dispatchProps.setAuthToken(response.data.AuthenticationToken)
                    dispatchProps.setUserData({
                        hasBackDoorAccess: response.data.HasBackDoorAccess,
                    })
                } else {
                    dispatchProps.setUserData({
                        hasBackDoorAccess: false,
                    })
                }
            })
            .catch((e) => {
                //
            })
    },

    authenticateWithApi: async function (username, password) {
        const {BuildNumber, Environment} = appConfiguration
        let apiConfig = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }

        if (Platform.OS !== 'web') {
            apiConfig.headers[
                'User-Agent'
            ] = `${Platform.OS}; ${Platform.Version};${Platform.constants.Fingerprint};${Platform.constants.Model}`
        }
        let promptedBiometrics = true
        if (Platform.OS !== 'web') {
            promptedBiometrics = await AsyncStorage.getItem('promptedBiometrics')
        }

        LoginLogic.context.setState({CallingApi: true})
        ABAPortalAPI.post(
            'authentication/authenticate',
            {
                UserName: username,
                Password: password,
                Platform: Platform.OS,
                BuildNumber,
            },
            apiConfig,
        )
            .then(function (response) {
                if (Platform.OS === 'web') {
                    sessionStorage.clear()
                }
                LoginLogic.context.props.setAuthToken(response.data.AuthenticationToken)
                LoginLogic.context.props.setUserData({
                    hasBackDoorAccess: response.data.HasBackDoorAccess,
                })
                LoginLogic.context.props.setABAID(response.data.abaId)
                LoginLogic.upsertFCMToken(response.data.AuthenticationToken)
                LoginLogic.saveStoredCredentials(username, password)
                var origPath = LoginLogic.context.props.location.origPath
                    ? LoginLogic.context.props.location.origPath
                    : '/dashboard'

                setHeaderAuthToken(response.data.AuthenticationToken)

                const navState = {
                    ConfirmPersonalInfo: false,
                    ConfirmRaceEthnicityInfo: response.data.ConfirmRaceEthnicityInfo,
                    promptedBiometrics,
                    abaid: response.data.abaId,
                }
                if (response.data.HasBackDoorAccess === true) {
                    switch (origPath) {
                        case '/':
                            origPath = '/backdoor'
                            break
                        case '/backdoor':
                            origPath = '/dashboard'
                    }
                    const isABAID = ValidationHelper.isAbaIdFormatValid(origPath.split('/').pop())
                    if (!ABAPortalAPI.defaults.headers.common.ABAID && !isABAID) {
                        origPath = '/backdoor'
                    }
                    NavigationHelper.GoToPage(GeneralEnums.navigationType.push, origPath, navState)
                    return
                }
                const {abaId} = response.data
                if (Platform.OS === 'web') {
                    gtag('set', 'user_properties', {
                        user_id: `${Environment}${CryptoJs.MD5(String(abaId)).toString()}`,
                        aba_user_environment: Environment,
                    })
                } else {
                    const analytics = require('@react-native-firebase/analytics').default
                    analytics().setUserId(`${Environment}${CryptoJs.MD5(String(abaId)).toString()}`)
                    analytics().setUserProperties({aba_user_environment: Environment})
                }

                if (response.data.ConfirmPersonalInfo || response.data.ConfirmRaceEthnicityInfo) {
                    navState.ConfirmPersonalInfo = true
                    NavigationHelper.GoToPage(
                        GeneralEnums.navigationType.push,
                        '/personalInfo',
                        navState,
                    )
                } else {
                    if (origPath === '/backdoor') origPath = '/'

                    if (response.data.HasBackDoorAccess === true) {
                        switch (origPath) {
                            case '/':
                                origPath = '/backdoor'
                                break
                            case '/backdoor':
                                origPath = '/dashboard'
                        }
                    }
                    NavigationHelper.GoToPage(GeneralEnums.navigationType.push, origPath, navState)
                }
            })
            .catch(function (error) {
                LoginLogic.context.setState({
                    CallingApi: false,
                    password: '',
                })
                LoginLogic.clearStoredCredentials()
                HelperMethods.apiErrorHandler(error, 'Login Error')
            })
            .finally(() => LoginLogic.context.setState({CallingApi: false}))
    },

    performLogin() {
        const {username, password} = LoginLogic.context.state
        Keyboard.dismiss()
        if (!LoginLogic.isCredentialsFormatValid(username, password)) {
            return
        }
        LoginLogic.authenticateWithApi(username, password)
    },

    upsertFCMToken(authenticationToken) {
        if (Platform.OS === 'web') {
            return
        }

        const messaging = require('@react-native-firebase/messaging').default
        messaging()
            .hasPermission()
            .then((hasPermission) => {
                if (!hasPermission) {
                    return
                }
                messaging()
                    .getToken()
                    .then((FCMToken) => {
                        const DevicePlatform = Platform.OS
                        const body = {FCMToken, DevicePlatform}
                        ABAPortalAPI.post('/fcm/upsertFCMToken', body).catch((error) => {
                            HelperMethods.apiErrorHandler(error, 'Firebase Login Error')
                        })
                    })
            })
    },
}

export default LoginLogic
