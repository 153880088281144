import React, {Component} from 'react'
import {Prompt} from '../../router'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAModal from '../../components/ABACustom/presentation/ABAModal'
import Address from '../../components/presentation/address'
import {ColorEnums, GeneralEnums, IconEnums, UnicodeEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import {Text, View, Platform} from 'react-native'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMultiline from '../../components/ABACustom/presentation/ABATextInputMultiline'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import {ABAFileUpload} from '../../components/ABACustom/presentation/ABAFileUpload'
import styleFunctions from '../../styles/styleFunctions'
import StrikeIronForm from '../../components/presentation/strikeIronForm'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const getDetailsText = (feeName) => {
    switch (feeName) {
        case 'Exam Cancellation':
            return 'In the field below, please provide the details regarding your cancellation.'
        case 'Rescore Request':
            return 'In the field below, please indicate why you would like your exam rescored.'
        case 'APPLIED Exam Change':
            return 'In the field below, please provide the details regarding your change request.'
        default:
            return ''
    }
}
const getDisplayFields = (feeName) => {
    switch (feeName) {
        case 'Status Letter':
            return {
                address: true,
                contactInfo: true,
                certificateName: false,
                specialty: false,
                exam: false,
                details: false,
                fileUpload: false,
            }
        case 'Printed Certificate':
            return {
                address: true,
                contactInfo: false,
                certificateName: true,
                specialty: true,
                exam: false,
                details: false,
                fileUpload: false,
            }
        case 'Exam Cancellation':
            return {
                address: false,
                contactInfo: false,
                certificateName: false,
                specialty: false,
                exam: true,
                details: true,
                fileUpload: true,
            }
        case 'Rescore Request':
            return {
                address: false,
                contactInfo: false,
                certificateName: false,
                specialty: false,
                exam: true,
                details: true,
                fileUpload: false,
            }
        case 'APPLIED Exam Change':
            return {
                address: false,
                contactInfo: false,
                certificateName: false,
                specialty: false,
                exam: false,
                details: true,
                fileUpload: false,
            }
        default:
            return {
                address: false,
                contactInfo: false,
                certificateName: false,
                specialty: false,
                exam: false,
                details: false,
                fileUpload: false,
            }
    }
}

const PaymentMessage = () => {
    return (
        <Text style={boldRedDescription}>
            *Once your payment is submitted all charges are non-refundable.
        </Text>
    )
}

const feeNameStyle = {
    width: '50%',
    fontFamily: 'lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: ColorEnums.danube,
}
const feePriceStyle = {
    width: '50%',
    fontFamily: 'lato',
    fontSize: 14,
    color: ColorEnums.cloudBurst,
    textAlign: 'right',
}

const descriptionStyle = [styleFunctions.paragraphText(), {paddingBottom: 8, fontSize: 14}]
const boldRedDescription = {color: 'red', fontWeight: 'bold'}
class AddOnDetails extends Component {
    constructor(props) {
        super(props)
        const defaultStatus = GeneralEnums.textInputStatus.default
        this.state = {
            mailToName: null,
            email: null,
            phone: null,
            address: null,
            nameOnCert: null,
            suffix: null,
            specialty: null,
            certEvent: null,
            exam: null,
            details: null,
            displayFields: {
                address: false,
                contactInfo: false,
                certificateName: false,
                specialty: false,
                exam: false,
                details: false,
                fileUpload: false,
            },
            fieldStatus: {
                mailToName: defaultStatus,
                email: defaultStatus,
                phone: defaultStatus,
                certFirstName: defaultStatus,
                certMiddleName: defaultStatus,
                certLastName: defaultStatus,
                suffix: defaultStatus,
                specialty: defaultStatus,
                exam: defaultStatus,
                details: defaultStatus,
                certEvent: defaultStatus,
            },
            specialties: [],
            certEvents: [],
            certEventsFiltered: [],
            suffixes: [],
            exams: [],
            showAddressValidation: false,
            componentWidth: 700,
            formWidth: 700,
            loading: false,
            mounted: false,
            preventNavigation: true,
            nameText: '',
            nameTextFormatted: '',
        }
        this.addressRef = React.createRef()
        this.uploadRef = React.createRef()
    }

    componentDidMount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = (event) => {
                return 'You have not your submitted your response. Do you wish to leave this page?'
            }
        }
        this.setState(
            {
                displayFields: getDisplayFields(this.navigationData.Name),
                loading: 'Loading Add-On Service Details',
            },
            async () => {
                try {
                    let specialties, suffixes, exams, retrievedFee, certEvents
                    const {displayFields} = this.state
                    if (displayFields.certificateName) {
                        const {data} = await ABAPortalAPI.get('fee/certEvents')
                        specialties = []
                        data.forEach((certEvent) => {
                            if (!specialties.find((item) => item.value === certEvent.SpecialtyID)) {
                                specialties.push({
                                    label: certEvent.SpecialtyLabel,
                                    value: certEvent.SpecialtyID,
                                })
                            }
                            certEvents = data
                        })
                    }
                    if (displayFields.specialty) {
                        const {data} = await ABAPortalAPI.get('lookups/suffixes')
                        suffixes = data
                    }
                    if (displayFields.exam) {
                        const axiosConfig =
                            this.navigationData.Deletable === false || this.navigationData.disabled
                                ? {params: {feeDetailID: this.navigationData.FeeDetailID}}
                                : undefined
                        let {data} =
                            this.navigationData.Name === 'Exam Cancellation'
                                ? await ABAPortalAPI.get('fee/cancellationExams', axiosConfig)
                                : await ABAPortalAPI.get('fee/rescoreExams', axiosConfig)
                        if (this.navigationData.Name === 'Rescore Request') {
                            data.push({label: 'MOCA Minute', value: 20})
                        }
                        exams = data
                    }
                    if (this.navigationData.FeeDetailID) {
                        let {data} = await ABAPortalAPI.get(
                            'fee/detail/' + this.navigationData.FeeDetailID,
                        )
                        data = HelperMethods.toCamel(data)
                        const {
                            organization,
                            department,
                            address1,
                            address2,
                            country,
                            state,
                            stateOrProvince,
                            postal,
                            city,
                            examType,
                            examTypeLabel,
                        } = data
                        if (
                            exams &&
                            examTypeLabel &&
                            examType &&
                            (this.navigationData.Deletable === false || this.navigationData.disabled)
                        ) {
                            exams = [
                                {
                                    label: data.examTypeLabel,
                                    value: data.examType,
                                },
                            ]
                        }
                        retrievedFee = {
                            ...data,
                            exam: examType,
                            address: {
                                organization,
                                department,
                                address1,
                                address2,
                                country,
                                state,
                                stateOrProvince,
                                postal,
                                city,
                            },
                        }
                    }
                    const certEventsFiltered = certEvents
                        ?.filter((certEvent) => certEvent.SpecialtyID === retrievedFee?.specialty)
                        ?.map((certEvent) => {
                            return {label: certEvent.CertEventLabel, value: certEvent.CertEventID}
                        })
                    this.setState({
                        ...retrievedFee,
                        exams,
                        specialties,
                        certEvents,
                        suffixes,
                        certEventsFiltered,
                    })
                } catch (error) {
                    HelperMethods.apiErrorHandler(error, 'Error loading add-on service details')
                } finally {
                    this.setState({loading: false, mounted: true})
                }
            },
        )
    }

    componentWillUnmount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = null
        }
    }

    saveAsFound(context) {
        const addressState = this.addressRef.current?.state
        this.setState(
            {
                address: {
                    organization: addressState.organization,
                    department: addressState.department,
                    country: addressState.country,
                    address1: context.state.si_AddressLine1,
                    address2: context.state.si_AddressLine2,
                    city: context.state.si_City,
                    postal: context.state.si_ZipCode,
                    state: context.state.si_StateSelected,
                    stateOrProvince: addressState.stateOrProvince,
                },
                showAddressValidation: false,
            },
            this.submit,
        )
    }

    saveAsEntered(_) {
        this.setState(
            {
                showAddressValidation: false,
            },
            this.submit,
        )
    }

    getDescription() {
        switch (this.navigationData.Name) {
            case 'Status Letter':
                return (
                    <>
                        <Text style={descriptionStyle}>
                            This is a request to have a closed envelope status letter mailed from the ABA
                            to the address of choice when the standard status letter does not meet the
                            needs of the requesting party. Requests can take up to 7 business days to
                            process.
                        </Text>
                        <Text style={descriptionStyle}>
                            Digital status letters are always on demand
                            {Platform.OS === 'web' ? (
                                <ABAHyperLink
                                    label=" here "
                                    url="/statusLetter"
                                    style={{color: ColorEnums.danube}}
                                    addAuth={true}
                                />
                            ) : (
                                ' in your portal via Chrome or IE '
                            )}
                            at no cost.
                        </Text>
                        <Text style={descriptionStyle}>
                            Please complete the fields below. The address you provide is the address to
                            which the status letter will be mailed.
                        </Text>
                        <PaymentMessage />
                    </>
                )
            case 'Printed Certificate':
                return (
                    <>
                        <Text style={descriptionStyle}>
                            Request a one-time complimentary printed MOCA certificate – $0 per
                            certificate
                        </Text>
                        <Text style={[descriptionStyle, boldRedDescription]}>
                            (valid for certifications on or after Jan. 1, 2024)
                        </Text>
                        <Text style={descriptionStyle}>
                            Request an additional printed initial, subspecialty, or MOCA certificate –
                            $150 per certificate
                        </Text>
                        <Text style={descriptionStyle}>
                            Requests can take up to 6-8 weeks to fulfill.
                        </Text>
                        <Text style={descriptionStyle}>
                            Please complete the fields below. The address you provide is the address to
                            which the certificate will be mailed. Your certificate will be sent directly
                            to you from our third-party printer.
                        </Text>
                        <PaymentMessage />
                    </>
                )
            case 'Exam Cancellation':
                return this.navigationData.Deletable ? (
                    <>
                        <Text style={descriptionStyle}>
                            This is to cancel your upcoming examination. Cancellations are only eligible
                            up to one week prior to the event start date.
                        </Text>
                        <PaymentMessage />
                    </>
                ) : (
                    <>
                        <Text style={descriptionStyle}>
                            This request is to process a late cancellation. Late cancellation requests
                            must be completed within one week of your exam start date and up to three
                            days after the event end date.
                        </Text>
                        <PaymentMessage />
                    </>
                )

            case 'Rescore Request':
                return (
                    <>
                        <Text style={descriptionStyle}>
                            We employ extensive and rigorous quality control procedures to ensure the
                            accuracy of exam results. While the possibility of an exam being incorrectly
                            scored is extremely remote, we recognize that you may wish to have your exam
                            rescored. In such cases, use this form to request the rescore service and
                            detail the specific reason why a scoring error is suspected.
                        </Text>
                        <Text style={descriptionStyle}>
                            Requests must be submitted within six weeks of the official release date of
                            the exam results.
                        </Text>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[descriptionStyle, {paddingLeft: 5, paddingRight: 20}]}>
                                {UnicodeEnums['bullet']}
                            </Text>
                            <Text style={descriptionStyle}>
                                For written exams, we will verify the physician’s response to each
                                question, compare those responses to the answer keys and recalculate the
                                scaled score.
                            </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[descriptionStyle, {paddingLeft: 5, paddingRight: 20}]}>
                                {UnicodeEnums['bullet']}
                            </Text>
                            <Text style={descriptionStyle}>
                                For the APPLIED Exam, we will confirm whether the numerical ratings from
                                examiners were correctly converted to the exam results; no additional
                                examiners will review your exam.
                            </Text>
                        </View>
                        <Text style={descriptionStyle}>
                            We will communicate results of the rescore to you via email within six weeks
                            of receiving the request.
                        </Text>
                        <Text style={descriptionStyle}>
                            Using the dropdown menu below, please select the exam that you wish to have
                            rescored. To request a rescore for more than one examination, please submit
                            one request per exam ($250 per exam). Please note that the SOE and the OSCE
                            count as two separate exams and therefore each requires a paid request.
                        </Text>
                        <PaymentMessage />
                    </>
                )
            case 'APPLIED Exam Change':
                return (
                    <>
                        <Text style={descriptionStyle}>
                            This is a request to change your exam week. This request can only be made if
                            you have not yet been assigned an exam date or time. The new exam week must
                            take place during the same calendar year as the current exam event year.
                        </Text>
                        <PaymentMessage />
                    </>
                )
            case 'MOCA Reinstatement':
                return (
                    <>
                        <Text style={descriptionStyle}>
                            Please click Submit below to complete payment for your MOCA reinstatement
                            fee. If you have questions, you may contact our Physician Engagement Team at
                            (866) 999-7501.
                        </Text>
                        <PaymentMessage />
                    </>
                )
            default:
                return <></>
        }
    }

    specialtyChange(specialty) {
        const certEventsFiltered = this.state.certEvents
            ?.filter((certEvent) => certEvent.SpecialtyID === specialty)
            ?.map((certEvent) => {
                return {label: certEvent.CertEventLabel, value: certEvent.CertEventID}
            })
        this.setState({specialty, certEventsFiltered})
    }

    validate() {
        const {textInputStatus} = GeneralEnums
        let {fieldStatus, displayFields} = this.state
        const {mailToName, email, phone, nameOnCert, specialty, exam, details, certEvent} = this.state
        for (let key in fieldStatus) {
            fieldStatus[key] = textInputStatus.default
        }
        let validated = true
        if (displayFields.specialty && (!specialty || specialty === 'null')) {
            fieldStatus.specialty = textInputStatus.error
        }
        if (displayFields.specialty && (!certEvent || certEvent === 'null')) {
            fieldStatus.certEvent = textInputStatus.error
        }
        if (displayFields.certificateName && !nameOnCert) {
            fieldStatus.nameOnCert = textInputStatus.error
        }
        if (displayFields.address && !mailToName) {
            fieldStatus.mailToName = textInputStatus.error
        }
        if (displayFields.contactInfo && (!email || !ValidationHelper.isEmailValid(email))) {
            fieldStatus.email = textInputStatus.error
        }
        if (displayFields.contactInfo && (!phone || !ValidationHelper.isPhoneNumberValid(phone))) {
            fieldStatus.phone = textInputStatus.error
        }
        if (displayFields.exam && (!exam || exam === 'null' || exam == 20)) {
            fieldStatus.exam = textInputStatus.error
        }
        if (displayFields.details && !details) {
            fieldStatus.details = textInputStatus.error
        }
        for (let key in fieldStatus) {
            if (fieldStatus[key] === textInputStatus.error) {
                validated = false
                break
            }
        }
        if (displayFields.address) {
            if (!this.addressRef.current.validate()) {
                validated = false
            }
            if (validated && HelperMethods.isCountryDomestic(this.addressRef.current.state.country)) {
                this.setState({showAddressValidation: true, fieldStatus})
                return
            }
        }
        this.setState({fieldStatus})
        if (validated) {
            this.submit()
        }
    }

    setAddressFields(callback) {
        if (!this.state.displayFields.address) {
            return callback()
        }
        const addressState = this.addressRef.current?.state
        this.setState(
            {
                address: {
                    organization: addressState.organization,
                    department: addressState.department,
                    country: addressState.country,
                    address1: addressState.address1,
                    address2: addressState.address2,
                    city: addressState.city,
                    postal: addressState.postal,
                    state: addressState.state,
                    stateOrProvince: addressState.stateOrProvince,
                },
            },
            callback,
        )
    }
    async submit() {
        const {
            mailToName,
            email,
            phone,
            suffix,
            nameOnCert,
            specialty,
            displayFields,
            details,
            exam,
            certEvent,
        } = this.state
        const {FeeTypeID, FeeDetailID} = this.navigationData
        const camelData = {
            ...this.state.address,
            mailToName,
            email,
            phone,
            suffix,
            details,
            certEvent,
            examType: exam,
            nameOnCert,
            specialty,
            FeeType: FeeTypeID,
            FeeDetailID,
        }
        this.setState({loading: 'Saving Add-On Service Details'})
        const {uploaded, selected} = this.uploadRef?.current?.state || {}
        try {
            if (displayFields.fileUpload && selected && !uploaded) {
                const {data} = await this.uploadRef.current.UploadFiles()
                camelData.filename = data
                this.setState({filename: data})
            }
            const requestData = HelperMethods.toPascal(camelData)
            const response = await ABAPortalAPI.post('fee/detail', requestData)
            this.setState({preventNavigation: false}, () => {
                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/addOnInvoice', {
                    ...this.navigationData,
                    FeeDetailID: response.data,
                })
            })
        } catch (error) {
            if (error?.response?.status === 400) {
                HelperMethods.alertAgnostic(
                    `It looks like you've already clicked "Continue" so we're redirecting you to your home page.`,
                    'Alert',
                    [{text: 'Ok'}],
                )
                this.setState({preventNavigation: false}, () => {
                    NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                })
                return
            }
            HelperMethods.apiErrorHandler(error, 'Error submitting add-on service details')
            this.setState({loading: false})
        }
    }

    render() {
        const addressState = this.state.address
        const {state} = this
        const {displayFields, fieldStatus, componentWidth, formWidth} = state
        HelperMethods.setNavigationData(this)
        return (
            <NavigationShell context={this} print>
                <ABAKeyboardAwareScrollView
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        paddingBottom: 50,
                    }}
                    ref={(comp) => {
                        this.componentRef = comp
                    }}
                >
                    <ABAHeader heading="Add-On Services" icon={IconEnums['user-circle']} />
                    <ABAContainer
                        containerTitle="Add-On Service Details"
                        activityText={state.loading}
                        showActivityIndicator={!!state.loading}
                        containerViewStyle={{minHeight: 200}}
                        onLayout={(event) =>
                            this.setState({componentWidth: event.nativeEvent.layout.width})
                        }
                    >
                        <View style={{flexDirection: 'row', paddingBottom: 10}}>
                            <Text style={feeNameStyle}>{this.navigationData.Name}</Text>
                            <Text style={feePriceStyle}>{this.navigationData.Pricing}</Text>
                        </View>
                        {this.getDescription()}
                        <View
                            style={{width: '100%', height: 30}}
                            onLayout={(event) =>
                                this.setState({formWidth: event.nativeEvent.layout.width})
                            }
                        />

                        <View>
                            {displayFields.specialty && (
                                <>
                                    <View style={{width: '100%', marginBottom: 30}}>
                                        <ABADropdown
                                            label="Specialty/Subspecialty *"
                                            placeholder="Specialty/Subspecialty *"
                                            options={state.specialties}
                                            selectedValue={state.specialty}
                                            validationStatus={fieldStatus.specialty}
                                            onChange={(value) => {
                                                this.specialtyChange(value)
                                            }}
                                            width={formWidth - 20}
                                        />
                                    </View>
                                    {state.specialty && state.specialty !== 'null' && (
                                        <View style={{width: '100%', marginBottom: 30}}>
                                            <ABADropdown
                                                label="Certificate *"
                                                placeholder="Certificate *"
                                                options={state.certEventsFiltered}
                                                selectedValue={state.certEvent}
                                                validationStatus={fieldStatus.certEvent}
                                                onChange={(value) => {
                                                    this.setState({certEvent: value})
                                                }}
                                                width={formWidth - 20}
                                            />
                                        </View>
                                    )}
                                </>
                            )}
                            {displayFields.exam && (
                                <View style={{width: '100%', marginBottom: 30}}>
                                    <ABADropdown
                                        label="Exam *"
                                        placeholder="Exam *"
                                        options={state.exams}
                                        selectedValue={this.state.exam}
                                        validationStatus={
                                            this.navigationData.Deletable === false &&
                                            !!this.navigationData.FeeDetailID
                                                ? GeneralEnums.textInputStatus.disabled
                                                : state.exam == 20
                                                ? GeneralEnums.textInputStatus.error
                                                : fieldStatus.exam
                                        }
                                        onChange={(value) => {
                                            let {fieldStatus} = state
                                            if (value && value != 'null' && value != 20) {
                                                fieldStatus.exam = GeneralEnums.textInputStatus.default
                                            }
                                            this.setState({exam: value})
                                        }}
                                        width={formWidth - 20}
                                        errorMessage={
                                            state.exam == 20
                                                ? 'To request a rescore of MOCA Minute, please email credentialing@theaba.org.'
                                                : 'Please select a value.'
                                        }
                                    />
                                </View>
                            )}
                            {displayFields.details && (
                                <>
                                    <Text style={descriptionStyle}>
                                        <Text style={{fontWeight: 'bold'}}>Details: </Text>
                                        {getDetailsText(this.navigationData.Name)}
                                    </Text>
                                    <ABATextInputMultiline
                                        maxLength={2000}
                                        onChangeText={(value) => {
                                            this.setState({details: value})
                                        }}
                                        value={state.details || ''}
                                        textInputStatus={fieldStatus.details}
                                        placeholder="Details *"
                                        numberOfLines={5}
                                        style={{paddingBottom: 20}}
                                    />
                                </>
                            )}
                            {displayFields.certificateName && (
                                <>
                                    <Text style={descriptionStyle}>
                                        <Text style={{fontWeight: 'bold'}}>Name on Certificate:</Text>{' '}
                                        Enter your name as you want it to appear on the certificate. Your
                                        principal medical degree will not be printed on your
                                        certificate(s).
                                    </Text>
                                    <View
                                        style={{
                                            flexDirection: componentWidth > 600 ? 'row' : 'column',
                                            width: '100%',
                                        }}
                                    >
                                        <View style={{width: componentWidth > 600 ? '80%' : '100%'}}>
                                            <ABATextInput
                                                placeholder="Full Name On Certificate *"
                                                returnKeyType="next"
                                                textInputStatus={fieldStatus.nameOnCert}
                                                onChangeText={(text) => {
                                                    this.setState({nameOnCert: text})
                                                }}
                                                value={state.nameOnCert}
                                                width={componentWidth > 600 ? '95%' : '100%'}
                                                marginLeft={0}
                                                marginRight={0}
                                                maxLength={150}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                width: formWidth * (componentWidth > 600 ? 0.2 : 1) - 20,
                                                marginBottom: 26,
                                                marginTop: 4,
                                            }}
                                        >
                                            <ABADropdown
                                                label="Suffix"
                                                placeholder="Suffix"
                                                options={state.suffixes}
                                                selectedValue={state.suffix}
                                                validationStatus={fieldStatus.suffix}
                                                onChange={(value) => {
                                                    this.setState({suffix: value})
                                                }}
                                                width="100%"
                                            />
                                        </View>
                                    </View>
                                </>
                            )}
                            {displayFields.contactInfo && (
                                <>
                                    <Text style={descriptionStyle}>
                                        <Text style={{fontWeight: 'bold'}}>Contact information:</Text>
                                    </Text>
                                    <ABATextInput
                                        style={styleFunctions.paragraphText()}
                                        placeholder="Email *"
                                        onChangeText={(text) => this.setState({email: text})}
                                        width="100%"
                                        value={state.email || ''}
                                        textInputStatus={fieldStatus.email}
                                        errorMessage={
                                            state.email
                                                ? 'Please enter a valid email address.'
                                                : 'Please enter a value.'
                                        }
                                        maxLength={125}
                                    />
                                    <ABATextInputMask
                                        keyboardType="number-pad"
                                        maxLength={25}
                                        options={{mask: '(999) 999-9999'}}
                                        onChangeText={(text) => this.setState({phone: text})}
                                        placeholder="Phone *"
                                        textInputStatus={fieldStatus.phone}
                                        returnKeyType="next"
                                        width="100%"
                                        errorMessage={
                                            state.phone
                                                ? 'Please enter a valid phone number.'
                                                : 'Please enter a value.'
                                        }
                                        value={state.phone || ''}
                                    />
                                </>
                            )}
                            {displayFields.address && state.mounted && (
                                <>
                                    <Text style={descriptionStyle}>
                                        <Text style={{fontWeight: 'bold'}}>Mailing address:</Text>
                                    </Text>
                                    <ABATextInput
                                        style={styleFunctions.paragraphText()}
                                        placeholder="Mail To Name *"
                                        onChangeText={(text) => this.setState({mailToName: text})}
                                        width="100%"
                                        value={state.mailToName || ''}
                                        textInputStatus={fieldStatus.mailToName}
                                        maxLength={150}
                                    />
                                    <Address ref={this.addressRef} initialAddress={state.address} />
                                </>
                            )}
                            {displayFields.fileUpload && (
                                <>
                                    <Text style={descriptionStyle}>
                                        <Text style={{fontWeight: 'bold'}}>File Upload:</Text> Please
                                        attach any required documentation related to your cancellation
                                        request, as applicable.{' '}
                                        {Platform.OS !== 'web' &&
                                            'In order to upload a supporting document, please access the web version of your portal. '}
                                        Only one document upload is supported per request.
                                    </Text>
                                    {Platform.OS === 'web' && (
                                        <ABAFileUpload
                                            filename={this.state.filename}
                                            deleteurl={`fee/deleteDetailFile`}
                                            uploadurl={`fee/detailFile`}
                                            uploadOnChange={false}
                                            noReplace={true}
                                            ref={this.uploadRef}
                                            feeType={this.navigationData.Name}
                                        />
                                    )}
                                </>
                            )}
                            {this.navigationData.disabled && (
                                <View style={styleFunctions.loadingOverlay()} />
                            )}
                        </View>
                        <View
                            style={{
                                marginTop: 50,
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <View style={{width: '50%', alignItems: 'center'}}>
                                <ABATouchableHighlight
                                    onPressEvent={() => {
                                        this.navigationData.disabled
                                            ? NavigationHelper.navigateBack()
                                            : this.setState({showBackModal: true})
                                    }}
                                    buttonStyle={styleFunctions.buttonClickInverted()}
                                    textStyle={{
                                        color: ColorEnums.danube,
                                        fontFamily: 'Lato',
                                        fontSize: 16,
                                    }}
                                    text={'Back'}
                                />
                            </View>
                            {!this.navigationData.disabled &&
                                !(
                                    this.navigationData.Name === 'Rescore Request' &&
                                    this.state.exam == 20
                                ) && (
                                    <View style={{width: '50%', alignItems: 'center'}}>
                                        <ABATouchableHighlight
                                            icon={'chevron-right'}
                                            onPressEvent={() => {
                                                this.setAddressFields(this.validate.bind(this))
                                            }}
                                            text={'Submit'}
                                        />
                                    </View>
                                )}
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
                {state.showBackModal && (
                    <ABAModal
                        isVisible={this.state.showBackModal}
                        onDismiss={() => this.setState({showBackModal: false})}
                        onBackdropPress={() => this.setState({showBackModal: false})}
                    >
                        <ABAConfirmationBox
                            messageText1="Returning to the previous screen will delete any changes."
                            messageText2="Do you wish to Continue?"
                            buttonLeftText="Cancel"
                            buttonRightText="Ok"
                            leftButtonFunction={() => this.setState({showBackModal: false})}
                            rightButtonFunction={() =>
                                this.setState({preventNavigation: false}, () => {
                                    NavigationHelper.navigateBack()
                                })
                            }
                        />
                    </ABAModal>
                )}
                {Platform.OS === 'web' && (
                    <Prompt
                        when={state.preventNavigation && !this.navigationData.disabled}
                        message={
                            'Returning to the previous screen will delete any changes. Do you wish to Continue?'
                        }
                    />
                )}
                {state.showAddressValidation && (
                    <ABAModal
                        isVisible={state.showAddressValidation}
                        onDismiss={() => this.setState({showAddressValidation: false})}
                        onBackdropPress={() => this.setState({showAddressValidation: false})}
                    >
                        <StrikeIronForm
                            AddressLine1={addressState.address1}
                            AddressLine2={addressState.address2}
                            City={addressState.city}
                            StateSelected={addressState.state}
                            ZipCode={addressState.postal}
                            onPressEvent={() => this.setState({showAddressValidation: false})}
                            CountryName={
                                this.addressRef.current.state.countryList.find(
                                    (option) => option.value === addressState.country,
                                )?.label
                            }
                            CountrySelected={addressState.country}
                            StateName={
                                this.addressRef.current.state.stateList.find(
                                    (option) => option.value === addressState.state,
                                )?.label
                            }
                            saveAsFound={this.saveAsFound.bind(this)}
                            saveAsEntered={this.saveAsEntered.bind(this)}
                        />
                    </ABAModal>
                )}
            </NavigationShell>
        )
    }
}
export default AddOnDetails
