import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import NonStandardExamStep5 from '../presentation/nonStandardExamStep5'
import NonStandardExamStep7 from '../presentation/nonStandardExamStep7'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import ValidationHelper from '../../../../helpers/ValidationHelper'

const NonStandardExamLogicStep6 = {
    context: null,

    GetNSEData: function () {
        return new Promise((resolve) => {
            const {NonStandardExamID} = nonStandardExamContext.state

            let getParams = {
                params: {
                    NonStandardExamID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('nserequestsinfo/getNSEData', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing NSE Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateStateVariables: function (NSEData) {
        if (NSEData) {
            this.context.setState({
                exam_authDate: NSEData.exam_authDate,
                exam_certDate: NSEData.exam_certDate,
                saveOnContinue: !ValidationHelper.isDate(NSEData.exam_authDate),
                InputStatus: {
                    exam_authDate: NSEData.exam_authDate
                        ? GeneralEnums.textInputStatus.disabled
                        : GeneralEnums.textInputStatus.default,
                    exam_certDate: NSEData.exam_certDate
                        ? GeneralEnums.textInputStatus.disabled
                        : GeneralEnums.textInputStatus.default,
                },
            })
        }
    },

    SetCertificationDate: function () {
        currentDate = new Date()
        const {exam_certDate} = this.context.state
        exam_certDate
            ? this.context.setState({exam_certDate: null})
            : this.context.setState({exam_certDate: currentDate.toISOString()})
    },

    SetAuthorizationDate: function () {
        currentDate = new Date()
        const {exam_authDate} = this.context.state
        exam_authDate
            ? this.context.setState({exam_authDate: null})
            : this.context.setState({exam_authDate: currentDate.toISOString()})
    },

    UpdateNSEStep6: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {exam_authDate, exam_certDate} = this.context.state
            var nseData = {
                NSEID: nonStandardExamContext.state.NonStandardExamID,
                exam_authDate,
                exam_certDate,
                rs_statuscode: nonStandardExamContext.state.RuleStatus,
            }
            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep6`, nseData)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }
            if (!this.context.state.saveOnContinue) {
                nonStandardExamContext.setState({
                    CurrentComponentStep: NonStandardExamStep7,
                })
                resolve(false)
                return
            }
            this.UpdateNSEStep6().then((response) => {
                if (response) {
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep7,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            nonStandardExamContext.setState({
                CurrentComponentStep: NonStandardExamStep5,
            })
            resolve(false)
        })
    },
    ValidatePage() {
        const {InputStatus} = this.context.state
        if (this.context.state.exam_authDate !== null) {
            InputStatus.exam_authDate = GeneralEnums.textInputStatus.success
        } else {
            InputStatus.exam_authDate = GeneralEnums.textInputStatus.error
        }
        if (this.context.state.exam_certDate !== null) {
            InputStatus.exam_certDate = GeneralEnums.textInputStatus.success
        } else {
            InputStatus.exam_certDate = GeneralEnums.textInputStatus.error
        }
        var Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)

        this.context.setState({
            InputStatus: InputStatus,
        })
        return Validated
    },
}

export default NonStandardExamLogicStep6
