import React, {Component, Fragment} from 'react'
import {
    Platform,
    ScrollView,
    TextInput,
    UIManager,
    Keyboard,
    findNodeHandle,
    Dimensions,
    View,
} from 'react-native'

export default class ABAKeyboardAwareScrollView extends Component {
    state = {height: null}
    scrollTo(options = {x: 0, y: 0, animated: false}) {
        this.refs.InnerRef.scrollTo(options)
        Platform.OS === 'web' && options.y === 0 && window.scrollTo(0, 0)
    }
    componentDidMount() {
        if (Platform.OS !== 'web') {
            this.keyboardDidShowListener = Keyboard.addListener(
                'keyboardDidShow',
                this.keyboardDidShow.bind(this),
            )
            this.keyboardDidHideListener = Keyboard.addListener(
                'keyboardDidHide',
                this.keyboardDidHide.bind(this),
            )
        }
    }
    componentWillUnmount() {
        if (Platform.OS !== 'web') {
            this.keyboardDidShowListener.remove()
            this.keyboardDidHideListener.remove()
        }
    }
    scrollIfFieldHidden(event) {
        let currentlyFocused = TextInput.State.currentlyFocusedField()
        //0 is a valid node so we need to check for it explicitly
        if (currentlyFocused !== 0 && !currentlyFocused) {
            return
        }
        const keyboardHeightThreshold =
            Dimensions.get('window').height - event.endCoordinates.height - 75
        UIManager.measure(currentlyFocused, (x, y, width, height, pageX, pageY) => {
            if (pageY >= keyboardHeightThreshold) {
                UIManager.measureLayout(
                    currentlyFocused,
                    findNodeHandle(this.refs.InnerRef),
                    () => {},
                    (x, y, width, height) => {
                        this.scrollTo({x, y: y - keyboardHeightThreshold + height, animated: true})
                    },
                )
            }
        })
    }
    keyboardDidShow(event) {
        if (Platform.OS === 'ios') {
            this.setState({height: event.endCoordinates.height}, this.scrollIfFieldHidden(event))
        } else {
            this.scrollIfFieldHidden(event)
        }
    }
    keyboardDidHide() {
        this.setState({height: null})
    }

    render() {
        return (
            <>
                <ScrollView ref="InnerRef" {...this.props} {...this.props.scrollviewProps} />
                <View style={{height: this.state.height || 0}} />
            </>
        )
    }
}

ABAKeyboardAwareScrollView.defaultProps = {
    scrollviewProps: {
        overScrollMode: 'never',
    },
}
