import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Platform, Text, View} from 'react-native'
import {TextInputMask} from 'react-native-masked-text'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
import styleFunctions from '../../../styles/styleFunctions'

export default class ABATextInputMask extends Component {
    constructor(props) {
        super(props)

        let showLabel = false

        if (this.props.value && this.props.value.length > 0) {
            showLabel = true
        }

        this.state = {
            borderBottomColor: ColorEnums.blueGray,
            borderBottomWidth: 1,
            showLabel: showLabel,
            isInternetExplorer: HelperMethods.checkIsIE(),
        }

        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
    }

    getTextInputStatusColor() {
        switch (this.props.textInputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                return ColorEnums.blueGray
            case GeneralEnums.textInputStatus.error:
                return ColorEnums.pomegranate
            case GeneralEnums.textInputStatus.success:
                return ColorEnums.surfGreen
            case GeneralEnums.textInputStatus.default:
            default:
                return ColorEnums.blueGray
        }
    }

    setBorderBottomStyle(targetBorderWidth) {
        this.setState({
            borderBottomColor: this.getTextInputStatusColor(),
            borderBottomWidth: targetBorderWidth,
        })
    }

    componentDidUpdate(previousProps) {
        if (this.props.textInputStatus !== previousProps.textInputStatus) {
            this.setBorderBottomStyle(1)
        }

        if (this.props.value !== previousProps.value) {
            this.inputCheck(this.props.value)
        }
    }

    inputCheck(text) {
        if (text.length > 0 && this.state.showLabel === false) {
            this.setState({showLabel: true})
            return
        }

        if (text.length < 1 && this.state.showLabel === true && !this.state.isInternetExplorer) {
            this.setState({showLabel: false})
            return
        }
    }

    onBlur() {
        if (this.state.isInternetExplorer && !this.props.value) {
            this.setState({showLabel: false})
        }
        this.setBorderBottomStyle(1)
    }

    onFocus() {
        if (this.state.isInternetExplorer) {
            this.setState({showLabel: true})
        }
        this.setBorderBottomStyle(2)
    }

    renderIcon() {
        let targetIcon = null

        switch (this.props.textInputStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetIcon = IconEnums['lock']
                break
            case GeneralEnums.textInputStatus.error:
                targetIcon = IconEnums['times']
                break
            case GeneralEnums.textInputStatus.success:
                targetIcon = IconEnums['check']
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetIcon = null
                break
        }

        return (
            <View
                style={{
                    borderBottomColor: this.state.borderBottomColor,
                    borderBottomWidth: this.state.borderBottomWidth,
                    marginBottom: 30,
                    width: '10%',
                }}
            >
                <Text
                    style={{
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        color: this.getTextInputStatusColor(),
                        fontSize: 14,
                        paddingLeft: 7,
                        paddingTop: 10,
                        textAlign: 'right',
                    }}
                >
                    {targetIcon}
                </Text>
            </View>
        )
    }

    render() {
        let keyboardTypeToUse = null

        if (Platform.OS === 'web' || this.props.keyboardType == undefined) {
            keyboardTypeToUse = 'default'
        } else if (Platform.OS === 'android' && this.props.keyboardType == 'number-pad') {
            keyboardTypeToUse = 'numeric'
        } else {
            keyboardTypeToUse = this.props.keyboardType
        }

        return (
            <View
                style={{
                    marginLeft: this.props.marginLeft,
                    marginRight: this.props.marginRight,
                    width: this.props.width,
                }}
            >
                <View style={{height: 10, width: '100%'}}>
                    {this.state.showLabel && (
                        <Text style={{fontFamily: 'Lato', fontSize: 10, color: ColorEnums.blueGray}}>
                            {this.props.placeholder}
                        </Text>
                    )}
                </View>
                <View style={{flexDirection: 'row'}}>
                    <TextInputMask
                        editable={this.props.textInputStatus != GeneralEnums.textInputStatus.disabled}
                        keyboardType={keyboardTypeToUse}
                        maxLength={this.props.maxLength}
                        onBlur={this.onBlur}
                        onChangeText={(text) => {
                            this.props.onChangeText(text)
                            if (!this.props.value) {
                                this.inputCheck(text)
                            }
                        }}
                        onFocus={this.onFocus}
                        onSubmitEditing={this.props.onSubmitEditing}
                        options={this.props.options}
                        placeholder={this.props.placeholder}
                        placeholderTextColor={ColorEnums.blueGray}
                        refInput={this.props.refInput}
                        returnKeyType={this.props.returnKeyType}
                        style={[
                            styleFunctions.textInput(
                                this.state.borderBottomColor,
                                this.state.borderBottomWidth,
                            ),
                            {padding: 0},
                        ]}
                        type={'custom'}
                        underlineColorAndroid="transparent"
                        value={this.props.value}
                        testID={`ABAMaskInput${this.props.placeholder}`}
                    />
                    {this.renderIcon()}
                </View>
                {this.props.textInputStatus === GeneralEnums.textInputStatus.error && (
                    <Text style={{color: ColorEnums.pomegranate, marginTop: -30}}>
                        {this.props.errorMessage}
                    </Text>
                )}
            </View>
        )
    }
}

ABATextInputMask.defaultProps = {
    errorMessage: 'Please enter a value.',
    returnKeyType: 'done',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
}

ABATextInputMask.propTypes = {
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    keyboardType: PropTypes.string,
    maxLength: PropTypes.number,
    onChangeText: PropTypes.func.isRequired,
    onSubmitEditing: PropTypes.func,
    options: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    refInput: PropTypes.func,
    returnKeyType: PropTypes.string,
    textInputStatus: PropTypes.number,
    value: PropTypes.string,
    width: PropTypes.string,
    marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
