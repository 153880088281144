import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const QIActivitiesLogic = {
    context: null,
    getQIActivities: function () {
        return new Promise((resolve) => {
            QIActivitiesLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('imp/getIMPs')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving QI Activities Information')
                    resolve(null)
                })
                .finally(function () {
                    QIActivitiesLogic.context.setState({CallingApi: false})
                })
        })
    },
    deleteActivity: function () {
        return new Promise((resolve) => {
            QIActivitiesLogic.context.setState({CallingDeleteApi: true})

            ABAPortalAPI.get('imp/DeleteIMP?IMPID=' + QIActivitiesLogic.context.state.recordToDelete)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting QI Record')
                    resolve(null)
                })
                .finally(function () {
                    QIActivitiesLogic.context.setState({CallingDeleteApi: false, recordToDelete: ''})
                })
        })
    },
}
export default QIActivitiesLogic
