import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import HelperMethods from '../../helpers/HelperMethods'

export default QIAttestationLogic = {
    context: null,

    ValidateForm: function () {
        const error = GeneralEnums.textInputStatus.error
        const {InputStatus, AttestChecked} = QIAttestationLogic.context.state
        let defaultStatus = GeneralEnums.textInputStatus.default

        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                InputStatus[key] = defaultStatus
            }
        }

        if (!AttestChecked) {
            InputStatus.AttestChecked = error
        }

        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                QIAttestationLogic.context.setState({InputStatus})
                return false
            }
        }
        return true
    },

    CreateQIAttestationIMP: function () {
        if (this.ValidateForm() === false) {
            return
        }
        QIAttestationLogic.context.setState({CallingApi: true})

        return new Promise((resolve) => {
            ABAPortalAPI.post('imp/saveQIAttestationIMP', {})
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    HelperMethods.apiErrorHandler(error, 'Error Saving QI Attestation IMP Record')
                    resolve(false)
                })
                .finally(() => {
                    NavigationHelper.navigateBack()
                })
        })
    },
}
