import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import HelperMethods from '../../../../helpers/HelperMethods'
import ClinicalActivityStep2 from '../presentation/clinicalActivityStep2'
import {GeneralEnums} from '../../../../helpers/generalEnums'

const ClinicalActivityLogicStep1 = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep1.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/GetClinicalActivityBegin', {
                Order: ClinicalActivityLogicStep1.context.props.Order,
                CR2ID: ClinicalActivityLogicStep1.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    ClinicalActivityLogicStep1.context.setState({CallingApi: false})
                })
        })
    },

    UpdateClinicalActivity() {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep1.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/InsertUpdateClinicalActivityStep1', {
                Order: ClinicalActivityLogicStep1.context.props.Order,
                CR2ID: ClinicalActivityLogicStep1.context.props.CR2ID,
                ContactId: ClinicalActivityLogicStep1.context.state.CAResponseData.ContactId,
                re_ProgramType: ClinicalActivityLogicStep1.context.state.re_ProgramType,
                cert_clinicalactivityid:
                    ClinicalActivityLogicStep1.context.state.CAResponseData.cert_clinicalactivityid,
                PDIR: ClinicalActivityLogicStep1.context.state.PDIR,
                PreviousCA: ClinicalActivityLogicStep1.context.state.PreviousCA,
                rs_statuscode: ClinicalActivityLogicStep1.context.state.CAResponseData.rs_statuscode,
                cert_graduation_date: ClinicalActivityLogicStep1.context.state.cert_graduation_date,
                isRecertification: ClinicalActivityLogicStep1.context.props.IsRecertification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    ClinicalActivityLogicStep1.context.setState({CallingApi: false})
                })
        })
    },

    determineStepCount(clinicalActivityData) {
        let stepCount = 4
        const {MOCA2, Sleep_Medicine, Hospice_and_Palliative_Medicine} = GeneralEnums.program_type
        const {
            re_ProgramType: programType,
            cert_nccpath: nccPathway,
            cert_graduation_date: graduationDate,
            cert_acapath,
        } = clinicalActivityData

        const isMocaRegistration = programType === MOCA2
        const isSubspecialtyRegistration =
            programType === Sleep_Medicine || programType === Hospice_and_Palliative_Medicine
        let skipReferences = false

        const today = moment(new Date(), 'MM/DD/YYYY', true)
        const referenceCutoffDate = today.subtract(7, 'years').format('MM/DD/YYYY')
        const graduationDateExceeded =
            graduationDate &&
            moment(new Date(graduationDate), 'MM/DD/YYYY').isBefore(referenceCutoffDate)

        if (nccPathway) {
            const {Currently_Training_In_Fellowship, Completed_Fellowship_In_NCC} =
                GeneralEnums.nccTrainingPathway

            const collectReferencePathways = [
                Currently_Training_In_Fellowship,
                Completed_Fellowship_In_NCC,
            ]
            skipReferences = collectReferencePathways.includes(nccPathway) && !graduationDateExceeded
        }

        if ((cert_acapath == 1 || cert_acapath == 3) && !graduationDateExceeded) {
            skipReferences = true
        }

        if (isSubspecialtyRegistration) {
            skipReferences = true
        }

        if (isMocaRegistration || skipReferences) {
            stepCount = 3
        }

        return stepCount
    },

    PopulateVariables(CAData) {
        const cert_graduation_date =
            CAData.cert_graduation_date &&
            moment(new Date(CAData.cert_graduation_date)).format('MM/DD/YYYY')
        const {IsViewRegistration} = this.context.props
        if (!IsViewRegistration) {
            registrationStepsDisplayContext.setState({
                CAstepCount: this.determineStepCount(CAData),
            })
        }

        ClinicalActivityLogicStep1.context.setState({
            CAOption: CAData.cert_clinicalactivityid ? true : null,
            re_ProgramType: CAData.re_ProgramType,
            PDIR: CAData.PDIR,
            PreviousCA: CAData.PreviousCA,
            CAResponseData: CAData,
            cert_graduation_date: cert_graduation_date || '',
            clinicalActivityCheckInterval: CAData.clinicalActivityCheckInterval || 0,
            shouldCaptureGraduation: CAData.shouldCaptureGraduation,
        })
    },

    CAOptionYesSelected() {
        ClinicalActivityLogicStep1.context.setState({
            CAOption: true,
            CAOptionStatus: GeneralEnums.textInputStatus.success,
        })
    },

    CAOptionNoSelected() {
        ClinicalActivityLogicStep1.context.setState({
            CAOption: false,
            CAOptionStatus: GeneralEnums.textInputStatus.error,
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (ClinicalActivityLogicStep1.context.state.CAOption) {
                ClinicalActivityLogicStep1.UpdateClinicalActivity().then((response) => {
                    if (response) {
                        if (
                            (ClinicalActivityLogicStep1.context.state.PDIR ||
                                ClinicalActivityLogicStep1.context.state.PreviousCA) &&
                            ClinicalActivityLogicStep1.context.state.re_ProgramType !=
                                GeneralEnums.program_type.MOCA2
                        ) {
                            resolve(true)
                        } else {
                            clinicalActivityContext.setState({
                                CurrentComponentStep: ClinicalActivityStep2,
                                ClinicalActivityID: response,
                                ContactId:
                                    ClinicalActivityLogicStep1.context.state.CAResponseData.ContactId,
                            })
                            resolve(false)
                        }
                    } else {
                        resolve(false)
                    }
                })
            } else {
                ClinicalActivityLogicStep1.context.setState({
                    CAOptionStatus: GeneralEnums.textInputStatus.error,
                })
                resolve(false)
            }
        })
    },

    Previous() {
        return new Promise((resolve) => {
            const {PreviousComponentStep} = clinicalActivityContext.state

            if (PreviousComponentStep) {
                clinicalActivityContext.setState({
                    CurrentComponentStep: PreviousComponentStep,
                })
                resolve(false)
            }
            resolve(true)
        })
    },
}

export default ClinicalActivityLogicStep1
