import {Platform} from 'react-native'

import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import SubstanceAbuseStep1 from '../presentation/substanceAbuseStep1'
import SubstanceAbuseStep7 from '../presentation/substanceAbuseStep7'

const SubstanceAbuseLogicStep8 = {
    context: null,

    GoToComponentStep(componentStep) {
        substanceAbuseContext.setState({CurrentComponentStep: componentStep}, () => {
            Platform.OS === 'web' && window.scrollTo(0, 0)
        })
    },

    UpdateSubstanceAbuseStep8() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep8`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            SubstanceAbuseLogicStep8.UpdateSubstanceAbuseStep8().then((response) => {
                this.context.props.SetPreviousVisibility(true)
                response ? resolve(true) : resolve(false)
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            if (substanceAbuseContext.state.RuleStatus === 1) {
                let goToStep = SubstanceAbuseStep7

                substanceAbuseContext.setState({
                    CurrentComponentStep: goToStep,
                })
                resolve(false)
            } else {
                resolve(true)
            }
        })
    },
}

export default SubstanceAbuseLogicStep8
