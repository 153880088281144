//#region Imports
import React, {Component} from 'react'
import {BackHandler, Linking, Platform, StatusBar, View, ActivityIndicator, Text} from 'react-native'
import {connect} from 'react-redux'
import InactivityAlert from './components/presentation/InactivityAlert'
import configuration from './configuration'
import ABAPortalAPI, {setHeaderABAID, setHeaderAuthToken} from './helpers/AxiosInstance'
import {ColorEnums, GeneralEnums} from './helpers/generalEnums'
import NavigationHelper from './helpers/NavigationHelper'
import {setABAID, setAuthToken, setUserData} from './redux/ActionCreators'
import {Redirect, Route, Switch} from './router'
import styleFunctions from './styles/styleFunctions'
import LoginLogic from './screens/logic/loginLogic'
import AddEditCME from './screens/presentation/addEditCME'
import AddOnDetails from './screens/presentation/addOnDetails'
import AddOnInvoice from './screens/presentation/addOnInvoice'
import AddOnServices from './screens/presentation/addOnServices'
import Authenticate from './screens/presentation/authenticate'
import BackdoorAccess from './screens/presentation/backdoorAccess'
import CertificationInformation from './screens/presentation/certificateInformation'
import ChangePasswordSetPassword from './screens/presentation/changePasswordSetPassword'
import CMEActivities from './screens/presentation/cmeActivities'
import CMEActivitiesTable from './screens/presentation/cmeActivitiesTable'
import CMEExplorerContainer from './screens/presentation/cmeExplorerContainer'
import CovidVaccinationCardSubmission from './screens/presentation/covidVaccinationCardSubmission'
import CreateAccountSetPassword from './screens/presentation/createAccountSetPassword'
import CreateAccountValidation from './screens/presentation/createAccountValidation'
import CreateAccountVerification from './screens/presentation/createAccountVerification'
import Dashboard from './screens/presentation/dashboard'
import EditMedicalLicense from './screens/presentation/editMedicalLicense'
import ExaminerAvailability from './screens/presentation/examinerAvailability'
import ExaminerConflicts from './screens/presentation/examinerConflicts'
import ExamInfoAnesthesiology from './screens/presentation/examInfoAnesthesiology'
import ExamInfoSubspecialty from './screens/presentation/examInfoSubspecialty'
import ForgotPasswordSetPassword from './screens/presentation/forgotPasswordSetPassword'
import ForgotPasswordValidation from './screens/presentation/forgotPasswordValidation'
import ForgotPasswordVerification from './screens/presentation/forgotPasswordVerification'
import KnowledgeAssessmentReport from './screens/presentation/knowledgeAssessmentReport'
import Loading from './screens/presentation/loading'
import Login from './screens/presentation/login'
import MedicalLicense from './screens/presentation/medicalLicense'
import MocaMinute from './screens/presentation/mocaMinute'
import MocaMinuteFeedbackContainer from './screens/presentation/mocaMinuteFeedbackContainer'
import MocaMinutePerformanceChart from './screens/presentation/mocaMinutePerformanceChart'
import Notifications from './screens/presentation/notifications'
import PaymentForm from './screens/presentation/paymentForm'
import PearsonVueRedirect from './screens/presentation/PearsonVueRedirect'
import PersonalInfo from './screens/presentation/personalInfo'
import PracticeProfile from './screens/presentation/practiceProfile'
import ProgressReport from './screens/presentation/progressReport'
import QIActivities from './screens/presentation/qiActivities'
import QIAttestation from './screens/presentation/qiAttestation'
import QuestionHistory from './screens/presentation/questionHistory'
import ReceiptDetails from './screens/presentation/receiptDetails'
import Receipts from './screens/presentation/receipts'
import RedirectErrors from './screens/presentation/RedirectErrors'
import RegistrationStepsDisplay from './screens/presentation/registrationStepsDisplay'
import ReportQIActivities from './screens/presentation/reportQiActivities'
import SchedulingDetails from './screens/presentation/schedulingDetails'
import StatusLetter from './screens/presentation/statusLetter'
import StyleGuide from './screens/presentation/styleGuide'
import SubmitNSERequest from './screens/presentation/submitNseRequest'
import ViewCME from './screens/presentation/viewCME'
import ViewCompletedSubstanceAbuseForms from './screens/presentation/viewCompletedSubstanceAbuseForms'
import ViewExamResults from './screens/presentation/viewExamResults'
import ViewNSERequests from './screens/presentation/viewNseRequests'
import ViewPostedDocuments from './screens/presentation/viewPostedDocuments'
import ViewRegistrationDetails from './screens/presentation/viewRegistrationDetails'
import VolunteerCensus from './screens/presentation/volunteerCensus'
import VolunteerTraining from './screens/presentation/volunteerTraining'
//#endregion

const mapStateToProps = (state) => ({
    settings: state.settings,
    hasBackDoorAccess: state.userData.hasBackDoorAccess,
})

const mapDispatchToProps = {setAuthToken, setABAID, setUserData, getUserData: LoginLogic.getUserData}
const codePush = Platform.OS === 'web' ? null : require('react-native-code-push')
class App extends Component {
    constructor(props) {
        super(props)
        this.handleNotification = this.handleNotification.bind(this)
        const isAuthenticate = window?.location?.pathname?.includes('authenticate')
        if (!isAuthenticate) {
            LoginLogic.authenticateToken(props)
        }
        if (props.settings.ABAID) {
            setHeaderABAID(props.settings.ABAID)
        }
    }

    handleNotification(notification) {
        const AsyncStorage = require('@react-native-community/async-storage').default
        const {notificationId} = notification.notification
        AsyncStorage.getItem('lastNotificationId').then((lastNotificationId) => {
            if (lastNotificationId === notificationId) return
            AsyncStorage.setItem('lastNotificationId', notificationId).catch((e) => console.log(e))
            const {route, webUrl} = notification.notification.data || {route: null, webUrl: null}
            webUrl &&
                Linking.canOpenURL(notification.notification.data.webUrl) &&
                Linking.openURL(notification.notification.data.webUrl)
            route &&
                this.props.settings.authToken &&
                NavigationHelper.GoToPage(GeneralEnums.navigationType.push, route)
        })
    }

    handleBackPress() {
        // navigationContext here comes from login.js
        // The entire reason that this works is because login.js is always loaded as the first step of the app.
        // If that ever changes, this will break! In fact, all of navigation will break!! Wear your hard hat and work on this with extreme caution.
        // You may even want to just walk away slowly and not breathe on it too hard for fear of knocking the straw house down.
        navigationContext.props.history.goBack()
        return true
    }

    async componentDidMount() {
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress)
        if (Platform.OS !== 'web') {
            StatusBar.setBarStyle('dark-content')
            const messaging = require('@react-native-firebase/messaging').default
            messaging().onNotificationOpenedApp(this.handleNotification)
        }
        Platform.OS === 'android' && StatusBar.setBackgroundColor(ColorEnums.backgroundGray)

        Linking.addEventListener('url', ({url}) => {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
        })
    }

    render() {
        if (this.props.settings.authToken) {
            setHeaderAuthToken(this.props.settings.authToken)
        }

        if (this.props.settings.ABAID) {
            setHeaderABAID(this.props.settings.ABAID)
        }

        const PrivateRoute = ({component: Component, ...rest}) => {
            var abaId =
                rest.computedMatch.params.abaid ||
                this.props.settings.ABAID ||
                ABAPortalAPI.defaults.headers.common.ABAID

            if (abaId && this.props.settings.ABAID !== abaId) {
                this.props.setABAID(abaId)
            }
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        authToken = ABAPortalAPI.defaults.headers.common.AuthenticationToken

                        if (props.location.pathname !== '/backdoor') {
                            this.props.getUserData(authToken)
                        }
                        if (
                            !abaId &&
                            authToken &&
                            this.props.hasBackDoorAccess &&
                            props.location.pathname !== '/backdoor'
                        ) {
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/backdoor',
                                    }}
                                />
                            )
                        }
                        if (!authToken) {
                            origPath = props.location.pathname ? props.location.pathname : '/dashboard'
                            var destination = rest.standAlone ? origPath : '/dashboard'
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        origPath: destination,
                                        error: rest.location.state?.error,
                                    }}
                                />
                            )
                        }

                        return (
                            <Component
                                {...props}
                                authToken={this.props.settings.authToken}
                                setAuthToken={(token) => this.props.setAuthToken(token)}
                                setABAID={this.props.setABAID}
                            />
                        )
                    }}
                />
            )
        }
        return (
            <View style={{flex: 1}}>
                <View style={{flex: 1}}>
                    <Switch>
                        {/* Please keep all routes in alphabetical order.  That will help with being able to find them quickly :) */}
                        <PrivateRoute exact path="/" component={Dashboard} standAlone />
                        <Route
                            exact
                            path="/login"
                            render={(props) => (
                                <Login
                                    {...props}
                                    authToken={this.props.settings.authToken}
                                    setAuthToken={(token) => this.props.setAuthToken(token)}
                                />
                            )}
                        />
                        <PrivateRoute path="/addEditCME" component={AddEditCME} />
                        <PrivateRoute path="/addOnDetails" component={AddOnDetails} />
                        <PrivateRoute path="/addOnServices" component={AddOnServices} />
                        <PrivateRoute path="/addOnInvoice" component={AddOnInvoice} />
                        <Route
                            path="/authenticate"
                            render={(props) => (
                                <Authenticate
                                    {...props}
                                    authToken={this.props.settings.authToken}
                                    setABAID={this.props.setABAID}
                                    setAuthToken={this.props.setAuthToken}
                                />
                            )}
                        />
                        <Route
                            path="/routeToPearsonVue/:token?/:eventId?/:platform?/:abaId?"
                            component={PearsonVueRedirect}
                        />
                        <Route path="/RedirectErrors/:token?/:eventId?" component={RedirectErrors} />
                        <PrivateRoute path="/backdoor" component={BackdoorAccess} standAlone />
                        <PrivateRoute
                            path="/certificateInformation"
                            component={CertificationInformation}
                        />
                        <PrivateRoute path="/changePassword" component={ChangePasswordSetPassword} />
                        <PrivateRoute path="/cmeActivities" component={CMEActivities} />
                        <Route
                            path="/cmeActivitiesTable/:selectedYear/:authToken"
                            component={CMEActivitiesTable}
                        />
                        <PrivateRoute path="/cmeExplorer" component={CMEExplorerContainer} />
                        <PrivateRoute
                            path="/covidVaccinationCardSubmission"
                            component={CovidVaccinationCardSubmission}
                        />
                        <Route path="/createAccountValidate" component={CreateAccountValidation} />
                        <Route path="/createAccountVerify" component={CreateAccountVerification} />
                        <Route path="/createAccountSetPassword" component={CreateAccountSetPassword} />
                        <PrivateRoute path="/dashboard/:abaid?" component={Dashboard} standAlone />
                        <PrivateRoute path="/editMedicalLicense" component={EditMedicalLicense} />
                        <PrivateRoute path="/EditQI" component={ReportQIActivities} />
                        <PrivateRoute path="/examinerAvailability" component={ExaminerAvailability} />
                        <PrivateRoute path="/examinerConflicts" component={ExaminerConflicts} />
                        <PrivateRoute
                            path="/examInfoAnesthesiology"
                            component={ExamInfoAnesthesiology}
                        />
                        <PrivateRoute path="/examInfoSubspecialty" component={ExamInfoSubspecialty} />
                        <Route path="/forgotPasswordValidate" component={ForgotPasswordValidation} />
                        <Route path="/forgotPasswordVerify" component={ForgotPasswordVerification} />
                        <Route path="/forgotPasswordSetPassword" component={ForgotPasswordSetPassword} />
                        <PrivateRoute
                            path="/knowledgeAssessmentReport"
                            component={KnowledgeAssessmentReport}
                        />
                        <Route path="/loading" component={Loading} />
                        <PrivateRoute path="/medicalLicense" component={MedicalLicense} />
                        <PrivateRoute
                            path="/mocaMinuteFeedback"
                            component={MocaMinuteFeedbackContainer}
                        />
                        <PrivateRoute path="/mocaMinuteLanding" component={MocaMinute} />
                        <PrivateRoute
                            path="/mocaMinutePerformance"
                            component={MocaMinutePerformanceChart}
                        />
                        <PrivateRoute
                            path="/myProgressReport/:abaid?"
                            component={ProgressReport}
                            standAlone
                        />
                        <PrivateRoute path="/myQiActivities" component={QIActivities} />
                        <PrivateRoute path="/notifications" component={Notifications} />
                        <PrivateRoute path="/paymentForm" component={PaymentForm} />
                        <PrivateRoute path="/personalInfo" component={PersonalInfo} standAlone />
                        <PrivateRoute path="/practiceProfile" component={PracticeProfile} />
                        <PrivateRoute path="/qiAttestation" component={QIAttestation} />
                        <PrivateRoute path="/questionHistory" component={QuestionHistory} />
                        <PrivateRoute path="/receipts" component={Receipts} />
                        <PrivateRoute path="/receiptDetails" component={ReceiptDetails} />
                        <PrivateRoute
                            path="/registrationStepsDisplay"
                            component={RegistrationStepsDisplay}
                        />
                        <PrivateRoute path="/reportNewCME" component={AddEditCME} />
                        <PrivateRoute path="/reportQiActivities" component={ReportQIActivities} />
                        <Route path="/statusLetter/:authToken" component={StatusLetter} />
                        {configuration.ENVIRONMENT === 'DEV' && (
                            <Route path="/styleGuide" component={StyleGuide} />
                        )}
                        <Route
                            path="/schedulingDetails/:event_id/:error?"
                            component={SchedulingDetails}
                        />
                        <PrivateRoute path="/submitNseRequest" component={SubmitNSERequest} />
                        <PrivateRoute path="/viewCME" component={ViewCME} />
                        <PrivateRoute
                            path="/viewCompletedSubstanceAbuseForms"
                            component={ViewCompletedSubstanceAbuseForms}
                        />
                        <PrivateRoute path="/viewExamResults" component={ViewExamResults} />
                        <PrivateRoute path="/viewNseRequests" component={ViewNSERequests} />
                        <PrivateRoute path="/viewPostedDocuments" component={ViewPostedDocuments} />
                        <PrivateRoute
                            path="/viewRegistrationDetails"
                            component={ViewRegistrationDetails}
                        />
                        <PrivateRoute path="/volunteerCensus" component={VolunteerCensus} />
                        <PrivateRoute path="/volunteerTraining" component={VolunteerTraining} />
                    </Switch>
                </View>
                {this.props.hasBackDoorAccess === false && <InactivityAlert />}
            </View>
        )
    }
}

let app
if (Platform.OS === 'web') {
    app = connect(mapStateToProps, mapDispatchToProps)(App)
} else {
    const codePushOptions = {
        deploymentKey: configuration.CodePushKey,
        checkFrequency: codePush.CheckFrequency.ON_APP_RESUME,
    }
    app = connect(mapStateToProps, mapDispatchToProps)(codePush(codePushOptions)(App))
}

export default app
