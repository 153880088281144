import React, {Component} from 'react'
import {MobilePay} from 'react-native-aba-module'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import {Image, Platform, Text, View} from 'react-native'
import {
    ColorEnums,
    PaymentTypes,
    AuthNetResponseCodes,
    IconEnums,
    GeneralEnums,
} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import {ABARadio, ABARadioGroup} from '../../components/ABACustom/presentation/ABARadio'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import {appConfiguration} from '../../configuration.json'
import NavigationHelper from '../../helpers/NavigationHelper'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import PaymentFormLogic from '../logic/paymentFormLogic'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const GooglePayMark = require('../../assets/images/MobilePay/GooglePayMark.png')
const ApplePayMark = require('../../assets/images/MobilePay/ApplePayMark.png')

const isProd = appConfiguration.Environment === 'PROD'
const ApplePayCert = isProd ? 'merchant.theaba.org.authorizenet' : 'merchant.org.theaba'
const googleMerchantId = isProd ? '1064596' : '380014'
const mobilePay = new MobilePay(
    'The ABA',
    Platform.OS === 'ios' ? ApplePayCert : 'authorizenet',
    googleMerchantId,
    !isProd,
)

const notesText = {
    fontFamily: 'Lato',
    color: ColorEnums.cloudBurst,
    fontSize: 14,
    paddingTop: 10,
}

const titleText = {
    fontFamily: 'Lato',
    color: ColorEnums.cloudBurst,
    fontSize: 14,
    fontWeight: 'bold',
}

const declinedMessage = {
    paddingTop: 15,
    fontSize: 20,
    color: ColorEnums.pomegranate,
    textAlign: 'center',
}

const lineItemContainer = {
    borderTopColor: ColorEnums.exLightGray,
    borderTopWidth: 1,
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 5,
    paddingTop: 5,
}

const tableContainer = {
    borderBottomColor: ColorEnums.cloudBurst,
    borderBottomWidth: 3,
    paddingBottom: 15,
    paddingTop: 10,
}

const InvoiceNotes = ({invoiceDetails, feeType}) => {
    let notesContent = `Click the "Continue" button to pay for your ${feeType} fee.`
    if (invoiceDetails.TotalAmount <= 0) {
        switch (feeType) {
            case 'Printed Certificate':
                notesContent = `Our records indicate that you have a one-time complimentary printed MOCA certificate credit on account, so no payment is required. Click the “Continue” button to submit your request.`
                break
            default:
                notesContent = `Our records indicate that you have a credit on account, so no payment is required. Click the “Continue” button to submit your request.`
                break
        }
    }
    return (
        <>
            <Text style={[titleText, {paddingBottom: 10, paddingTop: 30}]}>Notes</Text>
            <Text style={notesText}>{notesContent}</Text>
        </>
    )
}
class AddOnInvoice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invoiceDetails: null,
            lineItems: [],
            paymentType: PaymentTypes.CreditCard,
            declinedMessages: [],
            loading: 'Loading Invoice',
        }
    }

    async componentDidMount() {
        const isReady = await mobilePay.isReady()
        this.setState({isMobilePayReady: isReady})
        try {
            const {data} = await ABAPortalAPI.get(
                `fee/detail/${this.navigationData.FeeDetailID}/invoice`,
            )
            this.setState({
                invoiceDetails: data.InvoiceDetails,
                lineItems: data.LineItems,
                loading: null,
            })
        } catch (error) {
            HelperMethods.apiErrorHandler(error)
        }
    }

    getMobilePaymentOption() {
        if (Platform.OS === 'android')
            return (
                <ABARadio
                    value={PaymentTypes.MobilePay}
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                >
                    <Image
                        style={{width: 50, height: 50}}
                        source={GooglePayMark}
                        resizeMode={'contain'}
                    />
                </ABARadio>
            )
        if (Platform.OS === 'ios')
            return (
                <ABARadio
                    value={PaymentTypes.MobilePay}
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                >
                    <Image
                        style={{width: 50, height: 50}}
                        source={ApplePayMark}
                        resizeMode={'contain'}
                    />
                </ABARadio>
            )
        return <></>
    }

    async Pay() {
        if (this.state.paymentType === PaymentTypes.CreditCard) {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/paymentForm', {
                InvoiceID: this.state.invoiceDetails.InvoiceID,
            })
            return Promise.resolve(false)
        }

        try {
            this.setState({loading: 'Processing payment'})
            {
                const {data} = await ABAPortalAPI.get('payment/paymentStatusDashboard', {
                    params: {
                        timestamp: new Date().toUTCString(),
                    },
                })
                // A payment check failed
                if (data) {
                    const error = {
                        response: {
                            status: 400,
                        },
                    }
                    PaymentFormLogic.handlePaymentError(error)
                    return
                }
            }
            const transaction = await mobilePay.startTransaction(this.state.invoiceDetails.TotalAmount)
            const paymentData = JSON.parse(transaction)

            const transactionData = {
                invoiceID: this.state.invoiceDetails.InvoiceID,
                token: paymentData.token,
                billingAddress: paymentData.billingAddress,
            }
            const url = `payment/ProcessPayment/${Platform.select({
                ios: 'applepay',
                android: 'googlepay',
            })}`
            const result = await ABAPortalAPI.post(url, transactionData)

            var currentTimestamp = new Date()
            var formattedTimestamp = currentTimestamp.toLocaleString()
            const {data} = result
            if (data.transactionResponse.responseCode === AuthNetResponseCodes.SUCCESSFUL) {
                const paymentDetails = {
                    InvoiceID: this.state.invoiceDetails.InvoiceID,
                    LineItems: this.state.lineItems,
                    MOCA: false,
                    Description: this.state.invoiceDetails.PortalName,
                    InvoiceDetails: this.state.invoiceDetails,
                    TransactionID: data.transactionResponse.transId,
                    PayDate: formattedTimestamp,
                    TotalAmount: this.state.invoiceDetails.TotalAmount,
                    ApprovalCode: data.transactionResponse.authCode,
                    PayType: 'CREDIT',
                }

                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/receiptDetails', {
                    details: paymentDetails,
                })
            } else {
                this.setState({declinedMessages: data.transactionResponse.errors, loading: ''})
            }
        } catch (err) {
            if (err?.code === 'PAYMENT_RESULT_CANCELED') {
                return
            }
            PaymentFormLogic.handlePaymentError(err)
            console.error(err)
        } finally {
            this.setState({loading: ''})
        }
        return Promise.resolve(false)
    }

    render() {
        HelperMethods.setNavigationData(this)
        return (
            <NavigationShell context={this} print>
                <ABAKeyboardAwareScrollView
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        paddingBottom: 50,
                    }}
                    ref={(comp) => {
                        this.componentRef = comp
                    }}
                >
                    <ABAHeader heading="Add-On Services" icon={IconEnums['user-circle']} />

                    <ABAContainer
                        containerTitle="Add-On Service Invoice"
                        activityText={this.state.loading}
                        showActivityIndicator={!!this.state.loading}
                        containerViewStyle={{minHeight: 200}}
                    >
                        <View>
                            <Text style={[titleText, {paddingTop: 30}]}>Payment Overview</Text>
                            <Text style={[notesText, {paddingBottom: 10}]}>
                                *All fees are non-refundable
                            </Text>
                            <View style={{width: '100%', flexDirection: 'row', paddingTop: 5}}>
                                <Text
                                    style={[
                                        styleFunctions.columnHeader(),
                                        {width: '50%', fontFamily: 'Lato', fontSize: 14},
                                    ]}
                                >
                                    Item
                                </Text>
                                <Text
                                    style={[
                                        styleFunctions.columnHeader(),
                                        {
                                            width: '50%',
                                            textAlign: 'right',
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        },
                                    ]}
                                >
                                    Fee
                                </Text>
                            </View>
                            <View style={tableContainer}>
                                {this.state.lineItems.map((lineItem, index) => {
                                    return (
                                        <View key={index} style={lineItemContainer}>
                                            <View style={{width: '80%'}}>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {fontSize: 14},
                                                    ]}
                                                >
                                                    {lineItem.ItemDescription}
                                                </Text>
                                            </View>
                                            <View style={{width: '20%'}}>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {fontSize: 14, textAlign: 'right'},
                                                    ]}
                                                >
                                                    ${lineItem.Amount.toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            <View
                                style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {width: '50%', fontSize: 14, fontWeight: 'bold'},
                                    ]}
                                >
                                    Total:
                                </Text>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            width: '50%',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            textAlign: 'right',
                                        },
                                    ]}
                                >
                                    {this.state.invoiceDetails
                                        ? `$${this.state.invoiceDetails.TotalAmount.toFixed(2)}`
                                        : 'Calculating total...'}
                                </Text>
                            </View>
                            <View>
                                {this.state.declinedMessages?.map((error) => (
                                    <Text style={declinedMessage}>{error.errorText}</Text>
                                ))}
                            </View>
                            {this.state.isMobilePayReady && this.state.invoiceDetails?.TotalAmount > 0 && (
                                <View>
                                    <Text style={[titleText, {paddingTop: 30}]}>Payment Options</Text>

                                    <ABARadioGroup
                                        onChange={(paymentType) => this.setState({paymentType})}
                                        defaultValue={this.state.paymentType}
                                    >
                                        <ABARadio
                                            value={PaymentTypes.CreditCard}
                                            style={{paddingTop: 15, paddingBottom: 15}}
                                        >
                                            <Text>Credit/Debit</Text>
                                        </ABARadio>
                                        {this.getMobilePaymentOption()}
                                    </ABARadioGroup>
                                </View>
                            )}

                            {this.state.invoiceDetails && (
                                <InvoiceNotes
                                    invoiceDetails={this.state.invoiceDetails}
                                    feeType={this.navigationData.Name}
                                />
                            )}
                        </View>
                        <View
                            style={{
                                marginTop: 50,
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <View style={{width: '50%', alignItems: 'center'}}>
                                <ABATouchableHighlight
                                    onPressEvent={() => {
                                        NavigationHelper.navigateBack()
                                    }}
                                    buttonStyle={styleFunctions.buttonClickInverted()}
                                    textStyle={{
                                        color: ColorEnums.danube,
                                        fontFamily: 'Lato',
                                        fontSize: 16,
                                    }}
                                    text={'Back'}
                                />
                            </View>
                            <View style={{width: '50%', alignItems: 'center'}}>
                                <ABATouchableHighlight
                                    icon={'chevron-right'}
                                    onPressEvent={() => {
                                        this.Pay()
                                    }}
                                    text={'Continue'}
                                />
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
export default AddOnInvoice
