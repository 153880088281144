import React from 'react'
import {View, Text, StyleSheet, TouchableWithoutFeedback} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import styleFunctions from '../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums, UnicodeEnums} from '../../helpers/generalEnums'

const styles = StyleSheet.create({
    certificationComponentTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    certificationComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    certificationComponentLabel: {
        textAlign: 'left',
        paddingLeft: 10,
        paddingTop: 10,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
    },

    certificationComponentLabelIconRow: {
        textAlign: 'left',
        paddingLeft: 10,
        paddingTop: 10,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
    },

    certificationComponentData: {
        textAlign: 'right',
        fontFamily: 'Lato',
        paddingTop: 10,
        paddingRight: 10,
        fontSize: 15,
    },

    certificationItemView: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: ColorEnums.aquaHaze,
    },

    certificationMeetingMoca: {
        fontSize: 15,
        fontFamily: 'Lato',
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    certificationQuestionICON: {
        width: '10%',
        fontSize: 15,
        textAlign: 'right',
        paddingTop: 10,
        color: ColorEnums.danube,
        fontFamily: GeneralEnums.fontAwesomeFamilyName,
    },

    certificationComponentPopoverText: {
        width: '95%',
        fontSize: 15,
        textAlign: 'left',
        fontFamily: 'Lato',
        color: ColorEnums.cloudBurst,
    },
})

const CertificationsLogic = {
    context: null,

    styleCertificationPopover: function () {
        return {
            alignItems: 'center',
            backgroundColor: ColorEnums.aquaHaze,
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            overflow: 'hidden',
            borderRadius: 4,
            borderWidth: 1,
            width: '100%',
            zIndex: 10,
        }
    },

    GetComponentsDisplay: function () {
        return new Promise((resolve) => {
            CertificationsLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('certification/getCertificationComponentDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certifications')
                    resolve(null)
                })
                .finally(function () {
                    CertificationsLogic.context.setState({CallingApi: false})
                })
        })
    },

    getPopupContent: function () {
        let pimContent = `You are considered "Participating in MOC" if you answer 120 MOCA Minute${UnicodeEnums['registered-trademark']} questions annually and, by the end of the fifth year in your MOCA cycle, you have:`

        let pimBullets = [
            'Satisfactory medical license',
            'Completed half of your CME credits',
            'Completed 25 points worth of Quality Improvement activities',
        ]

        return (
            <View style={{width: '100%', alignItems: 'center'}}>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 12, width: '90%', fontWeight: 'bold', paddingBottom: 5},
                    ]}
                >
                    {pimContent}
                </Text>
                {pimBullets.map((bulletContent, i) => {
                    return (
                        <View key={i} style={{flexDirection: 'row', width: '90%'}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 12, paddingLeft: 5, paddingRight: 5},
                                ]}
                            >
                                {UnicodeEnums['bullet']}
                            </Text>
                            <Text style={[styleFunctions.paragraphText(), {fontSize: 12}]}>
                                {bulletContent}
                            </Text>
                        </View>
                    )
                })}
            </View>
        )
    },

    wrapInHalfView: function (component) {
        return <View style={{width: '50%'}}>{component}</View>
    },

    renderDateStyling: function (detailDate) {
        if (detailDate == null) return {color: ColorEnums.pomegranate}
        if (detailDate != null && detailDate != '01/01/1900') return {color: ColorEnums.surfGreen}
    },

    renderDetailText: function (detailDate) {
        if (detailDate == '01/01/1900') return 'N/A'
        if (detailDate == null) return 'Not Completed'
        return detailDate
    },

    displayCertificationDetails: function (Cert) {
        return (
            <View style={{paddingTop: 10, paddingLeft: 50}}>
                <ABAAccordion
                    key={Cert.SpecialtyName + 'Component'}
                    title={Cert.SpecialtyName + ' Details'}
                    accordionTitleStyle={styles.certificationComponentTitle}
                    accordionContent={
                        <View style={{paddingTop: 0, paddingBottom: 20}}>
                            <View style={styles.certificationItemView}>
                                {CertificationsLogic.wrapInHalfView(
                                    <Text style={styles.certificationComponentLabel}>
                                        Patient Safety CME
                                    </Text>,
                                )}
                                {CertificationsLogic.wrapInHalfView(
                                    <Text
                                        style={[
                                            styles.certificationComponentData,
                                            this.renderDateStyling(Cert.PatientSafetyCMEDate),
                                        ]}
                                    >
                                        {this.renderDetailText(Cert.PatientSafetyCMEDate)}
                                    </Text>,
                                )}
                            </View>
                            <View style={styles.certificationItemView}>
                                {CertificationsLogic.wrapInHalfView(
                                    <Text style={styles.certificationComponentLabel}>
                                        125 CME Credit
                                    </Text>,
                                )}
                                {CertificationsLogic.wrapInHalfView(
                                    <Text
                                        style={[
                                            styles.certificationComponentData,
                                            this.renderDateStyling(
                                                Cert.IsTenYear
                                                    ? Cert.CMEHalfCompleteDate
                                                    : Cert.CMEAllCompleteDate,
                                            ),
                                        ]}
                                    >
                                        {this.renderDetailText(
                                            Cert.IsTenYear
                                                ? Cert.CMEHalfCompleteDate
                                                : Cert.CMEAllCompleteDate,
                                        )}
                                    </Text>,
                                )}
                            </View>
                            {Cert.IsTenYear && (
                                <View style={styles.certificationItemView}>
                                    {CertificationsLogic.wrapInHalfView(
                                        <Text style={styles.certificationComponentLabel}>
                                            250 CME Credit
                                        </Text>,
                                    )}
                                    {CertificationsLogic.wrapInHalfView(
                                        <Text
                                            style={[
                                                styles.certificationComponentData,
                                                this.renderDateStyling(Cert.CMEAllCompleteDate),
                                            ]}
                                        >
                                            {this.renderDetailText(Cert.CMEAllCompleteDate)}
                                        </Text>,
                                    )}
                                </View>
                            )}

                            <View style={styles.certificationItemView}>
                                {CertificationsLogic.wrapInHalfView(
                                    <Text style={styles.certificationComponentLabel}>Attestation</Text>,
                                )}
                                {CertificationsLogic.wrapInHalfView(
                                    <Text
                                        style={[
                                            styles.certificationComponentData,
                                            this.renderDateStyling(Cert.AttestationDate),
                                        ]}
                                    >
                                        {this.renderDetailText(Cert.AttestationDate)}
                                    </Text>,
                                )}
                            </View>
                            <View style={styles.certificationItemView}>
                                {CertificationsLogic.wrapInHalfView(
                                    <Text style={styles.certificationComponentLabel}>QI-1</Text>,
                                )}
                                {CertificationsLogic.wrapInHalfView(
                                    <Text
                                        style={[
                                            styles.certificationComponentData,
                                            this.renderDateStyling(
                                                Cert.IsTenYear ? Cert.HalfIMPDate : Cert.AllIMPDate,
                                            ),
                                        ]}
                                    >
                                        {this.renderDetailText(
                                            Cert.IsTenYear ? Cert.HalfIMPDate : Cert.AllIMPDate,
                                        )}
                                    </Text>,
                                )}
                            </View>
                            {Cert.IsTenYear && (
                                <View style={styles.certificationItemView}>
                                    {CertificationsLogic.wrapInHalfView(
                                        <Text style={styles.certificationComponentLabel}>QI-2</Text>,
                                    )}
                                    {CertificationsLogic.wrapInHalfView(
                                        <Text
                                            style={[
                                                styles.certificationComponentData,
                                                this.renderDateStyling(Cert.AllIMPDate),
                                            ]}
                                        >
                                            {this.renderDetailText(Cert.AllIMPDate)}
                                        </Text>,
                                    )}
                                </View>
                            )}
                        </View>
                    }
                />
            </View>
        )
    },

    GetAccordionComponent: function (Cert, index) {
        if (this.context.props.location.pathname === '/myProgressReport' && Cert.IncludedInMOC !== 1) {
            return
        }
        let MeetingMoca = ''
        let MeetingMocaClass = {
            fontSize: 15,
            color: ColorEnums.lime,
            fontFamily: GeneralEnums.fontAwesomeFamilyName,
        }

        switch (Cert.Meetingmocrequirements) {
            case 1:
                MeetingMoca = IconEnums['check']
                break
            case 2:
            case 3:
                MeetingMoca = IconEnums['times']
                MeetingMocaClass = {
                    fontSize: 15,
                    color: ColorEnums.pomegranate,
                    fontFamily: GeneralEnums.fontAwesomeFamilyName,
                }
                break
            default:
                MeetingMoca = 'Not Required'
                MeetingMocaClass = styleFunctions.certificationMeetingMoca
        }

        return (
            <ABAAccordion
                key={Cert.SpecialtyName + 'Component'}
                title={Cert.SpecialtyName}
                title2={
                    Cert.ApprovedForCertification === 1 &&
                    this.context.props.location.pathname &&
                    this.context.props.location.pathname.includes('/myProgressReport') && (
                        <Text style={{color: ColorEnums.lime, fontWeight: 'bold', marginLeft: 22}}>
                            Approved for Certification
                        </Text>
                    )
                }
                accordionContent={
                    <View style={{paddingTop: 0, paddingBottom: 20}}>
                        <View style={styles.certificationItemView}>
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentLabel}>
                                    Initial Issue Date
                                </Text>,
                            )}
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentData}>{Cert.IssueDate}</Text>,
                            )}
                        </View>
                        <View style={styles.certificationItemView}>
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentLabel}>Expiration Date</Text>,
                            )}
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentData}>
                                    {Cert.ExpirationDate == null || Cert.ExpirationDate.trim() === ''
                                        ? 'N/A'
                                        : Cert.ExpirationDate}
                                </Text>,
                            )}
                        </View>
                        <View style={styles.certificationItemView}>
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentLabel}>Status</Text>,
                            )}
                            {CertificationsLogic.wrapInHalfView(
                                <Text style={styles.certificationComponentData}>
                                    {Cert.CertificationStatus}
                                </Text>,
                            )}
                        </View>
                        <View style={styles.certificationItemView}>
                            <View style={{flexDirection: 'row', width: '100%'}}>
                                <View style={{width: '50%', flexDirection: 'row'}}>
                                    <TouchableWithoutFeedback
                                        style={{width: '100%'}}
                                        onPress={() => {
                                            CertificationsLogic.context.PopupToggle(index)
                                        }}
                                        testID="MeetingMocaQuestion"
                                    >
                                        <View style={{width: '100%', flexDirection: 'row'}}>
                                            <Text style={styles.certificationComponentLabelIconRow}>
                                                Participating in MOC
                                            </Text>
                                            <Text style={styles.certificationQuestionICON}>
                                                {IconEnums['question-circle']}
                                            </Text>
                                        </View>
                                    </TouchableWithoutFeedback>
                                </View>
                                {CertificationsLogic.wrapInHalfView(
                                    <Text style={[styles.certificationComponentData, MeetingMocaClass]}>
                                        {MeetingMoca}
                                    </Text>,
                                )}
                            </View>
                        </View>
                        {!CertificationsLogic.context.state.popupToggle[index] && (
                            <TouchableWithoutFeedback
                                style={{width: '100%'}}
                                onPress={() => {
                                    CertificationsLogic.context.PopupToggle(index)
                                }}
                                testID="MeetingMocaPopup"
                            >
                                <View style={CertificationsLogic.styleCertificationPopover()}>
                                    <Text
                                        style={{
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            position: 'absolute',
                                            right: 5,
                                            top: 0,
                                        }}
                                    >
                                        {IconEnums['times']}
                                    </Text>
                                    {CertificationsLogic.getPopupContent()}
                                </View>
                            </TouchableWithoutFeedback>
                        )}

                        {this.displayCertificationDetails(Cert)}
                    </View>
                }
                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                accordionTitleStyle={styles.certificationComponentTitle}
                accordionButtonStyle={styles.certificationComponentTitlebutton}
                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                accordionExpanded={CertificationsLogic.context.props.Expanded}
                accordionUnderlayColor={ColorEnums.white}
                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
            />
        )
    },

    GetCertificationDisplay: function () {
        return new Promise((resolve) => {
            CertificationsLogic.GetComponentsDisplay().then(function (displayComponents) {
                let PopupToggleArray = []
                if (displayComponents && displayComponents.length > 0) {
                    for (let index = 0; index < displayComponents.length; index++) {
                        PopupToggleArray[index] = true
                    }
                    CertificationsLogic.context.setState({popupToggle: PopupToggleArray})
                }
                resolve(displayComponents)
            })
        })
    },

    GetCertificationDisplayComponent: function (DisplayContent) {
        let DisplayComponents = []
        if (DisplayContent && DisplayContent.length > 0) {
            for (let index = 0; index < DisplayContent.length; index++) {
                DisplayComponents.push(
                    CertificationsLogic.GetAccordionComponent(DisplayContent[index], index),
                )
            }
        } else {
            DisplayComponents.push(
                <View key={'NoData'} style={{flexDirection: 'row', padding: 5}}>
                    <Text>No Data</Text>
                </View>,
            )
        }
        return DisplayComponents
    },
}

export default CertificationsLogic
