import React, {Component} from 'react'
import {View, Text} from 'react-native'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import ViewPostedDocumentsLogic from '../logic/viewPostedDocumentsLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'

export default class ViewPostedDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {documentList: [], isLoading: false}
    }
    componentDidMount() {
        this.setState({isLoading: true})
        ViewPostedDocumentsLogic.getPostedDocuments(this.navigationData.AuthenticationToken)
            .then((responsedata) => {
                this.setState({documentList: responsedata})
            })
            .finally(() => {
                this.setState({isLoading: false})
            })
    }

    render() {
        HelperMethods.setNavigationData(this)
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.PostedDocuments} />
                    <ABAContainer
                        activityText={'Loading Posted Documents'}
                        containerTitle={'RELATED TO MY CERTIFICATION'}
                        showActivityIndicator={this.state.isLoading}
                    >
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 10,
                                paddingTop: 30,
                                borderBottomWidth: 2,
                                borderBottomColor: ColorEnums.lightGray,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '25%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Date
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '47%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Description
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '28%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                View Document
                            </Text>
                        </View>
                        {this.state.documentList.map((item, index) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        width: '100%',
                                        flexDirection: 'row',
                                        alignSelf: 'center',
                                        paddingBottom: 10,
                                        paddingTop: 10,
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                    }}
                                >
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {width: '25%', fontSize: 14},
                                        ]}
                                    >
                                        {item.DisplayDate}
                                    </Text>
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {width: '47%', fontSize: 14},
                                        ]}
                                    >
                                        {item.DisplayName}
                                    </Text>
                                    <Text
                                        style={[
                                            styleFunctions.actionText(),
                                            {width: '28%', fontFamily: 'Lato', fontSize: 14},
                                        ]}
                                        onPress={() =>
                                            ViewPostedDocumentsLogic.openDocument(
                                                item,
                                                this.navigationData.AuthenticationToken,
                                            )
                                        }
                                    >
                                        <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>
                                            {IconEnums['file-alt']}
                                        </Text>{' '}
                                        Download
                                    </Text>
                                </View>
                            )
                        })}
                        <View
                            style={{
                                borderTopWidth: 2,
                                borderTopColor: ColorEnums.cloudBurst,
                                width: '100%',
                                alignSelf: 'center',
                                maxWidth: 700,
                            }}
                        ></View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
