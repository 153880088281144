import React, {Component} from 'react'
import {View, Text, Platform} from 'react-native'
import {connect} from 'react-redux'
import HelperMethods from '../../helpers/HelperMethods'
import Header from './header'
import SideNavigation from './sidenavigation'
import BottomNavigation from './bottomnavigation'
import {ColorEnums} from '../../helpers/generalEnums'

const mapStateToProps = (state) => ({
    settings: state.settings,
    hasBackDoorAccess: state.userData.hasBackDoorAccess,
})
class NavigationShell extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showNav: true,
            displayPrintInfo: 'none',
        }
    }

    render() {
        navigationShellContext = this.props.context
        navigationContext = this.props.context

        if (Platform.OS === 'web') {
            return (
                <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <View
                        style={{flexDirection: 'row', flex: 1}}
                        onLayout={(event) => {
                            HelperMethods.handleResize(this, event)
                        }}
                    >
                        {this.state.showNav && (
                            <div className="no-print" style={{display: 'flex', minWidth: 275}}>
                                <SideNavigation showNav={this.props.showNav} />
                            </div>
                        )}

                        <View
                            style={{
                                display: 'flex',
                                flex: 1,
                            }}
                        >
                            <Header
                                afterPrint={this.props.afterPrint}
                                beforePrint={this.props.beforePrint}
                                print={this.props.print}
                                shellContext={this}
                                showNav={this.state.showNav}
                                navEnabled={this.props.showNav}
                            />
                            {this.props.children}
                        </View>
                    </View>
                </div>
            )
        }

        return (
            <View style={{flex: 1}}>
                {this.props.children}
                {this.props.showNav && (
                    <BottomNavigation url={navigationShellContext.props.location.pathname} />
                )}
            </View>
        )
    }
}

NavigationShell.defaultProps = {
    showNav: true,
}

export default connect(mapStateToProps)(NavigationShell)
