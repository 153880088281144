import {Platform} from 'react-native'

import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep4 from '../presentation/nonStandardExamStep4'
import NonStandardExamStep6 from '../presentation/nonStandardExamStep6'

const NonStandardExamLogicStep5 = {
    context: null,

    GetNSEData: function () {
        return new Promise((resolve) => {
            const {NonStandardExamID} = nonStandardExamContext.state

            let getParams = {
                params: {
                    NonStandardExamID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`nserequestsinfo/getNSEData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing NSE Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateStateVariables: function (NSEData) {
        if (NSEData) {
            this.context.setState({
                exam_residencyOrFellowAccommodations:
                    NSEData.exam_residencyOrFellowAccommodations === null
                        ? null
                        : NSEData.exam_residencyOrFellowAccommodations
                        ? true
                        : false,
                exam_residencyOrFellowAccomodationsDesc:
                    NSEData.exam_residencyOrFellowAccomodationsDesc || '',
                exam_clinicalPracticeAccommodations:
                    NSEData.exam_clinicalPracticeAccommodations === null
                        ? null
                        : NSEData.exam_clinicalPracticeAccommodations
                        ? true
                        : false,
                exam_clinicalPracticeAccommodationsDescription:
                    NSEData.exam_clinicalPracticeAccommodationsDescription || '',
                exam_specificExamAccommodationRequested:
                    NSEData.exam_specificExamAccommodationRequested || '',
                exam_wheelchairAccessRequired:
                    NSEData.exam_wheelchairAccessRequired === null
                        ? null
                        : NSEData.exam_wheelchairAccessRequired
                        ? true
                        : false,
                exam_accommodationsForCollegeExams:
                    NSEData.exam_accommodationsForCollegeExams === null
                        ? null
                        : NSEData.exam_accommodationsForCollegeExams
                        ? true
                        : false,
                exam_accommodationsForCollegeExamsDescription:
                    NSEData.exam_accommodationsForCollegeExamsDescription || '',
                exam_accommodationsForMedicalSchoolExams:
                    NSEData.exam_accommodationsForMedicalSchoolExams === null
                        ? null
                        : NSEData.exam_accommodationsForMedicalSchoolExams
                        ? true
                        : false,
                exam_accommodationsForResidencyExams:
                    NSEData.exam_accommodationsForResidencyExams === null
                        ? null
                        : NSEData.exam_accommodationsForResidencyExams
                        ? true
                        : false,
                exam_specialAccommodation:
                    NSEData.exam_specialAccommodation === null
                        ? null
                        : NSEData.exam_specialAccommodation
                        ? true
                        : false,
                exam_iteAccommodation: NSEData.exam_iteAccommodation ? true : false,
                exam_iteYears: NSEData.exam_iteYears || '',
                exam_iteAccommodationFile: NSEData.exam_iteAccommodationFile || null,
                exam_usmle1Accommodation: NSEData.exam_usmle1Accommodation ? true : false,
                exam_usmle1Years: NSEData.exam_usmle1Years || '',
                exam_usmle1AccommodationFile: NSEData.exam_usmle1AccommodationFile || null,
                exam_usmle2Accommodation: NSEData.exam_usmle2Accommodation ? true : false,
                exam_usmle2Years: NSEData.exam_usmle2Years || '',
                exam_usmle2AccommodationFile: NSEData.exam_usmle2AccommodationFile || null,
                exam_usmle3Accommodation: NSEData.exam_usmle3Accommodation ? true : false,
                exam_usmle3Years: NSEData.exam_usmle3Years || '',
                exam_usmle3AccommodationFile: NSEData.exam_usmle3AccommodationFile || null,
                exam_accommodationsForMedicalSchoolExamsFile:
                    NSEData.exam_accommodationsForMedicalSchoolExamsFile || null,
                exam_accommodationsForResidencyExamsFile:
                    NSEData.exam_accommodationsForResidencyExamsFile || null,
                otherStandardizedExamsFile: NSEData.otherStandardizedExamsFile || null,
                otherStandardizedExams: NSEData.exam_otherExamAccommodationsAndYears ? true : false,
                exam_otherExamAccommodationsAndYears: NSEData.exam_otherExamAccommodationsAndYears || '',
            })
        }
    },

    ResidencyOrFellowAccommodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({
            exam_residencyOrFellowAccommodations: false,
            exam_residencyOrFellowAccomodationsDesc: '',
        })
    },

    ResidencyOrFellowAccommodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_residencyOrFellowAccommodations: true})
    },

    ClinicalPracticeAccommodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({
            exam_clinicalPracticeAccommodations: false,
            exam_clinicalPracticeAccommodationsDescription: '',
        })
    },

    ClinicalPracticeAccommodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_clinicalPracticeAccommodations: true})
    },

    WheelchairAccessNoSelected() {
        NonStandardExamLogicStep5.context.setState({exam_wheelchairAccessRequired: false})
    },

    WheelchairAccessYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_wheelchairAccessRequired: true})
    },

    CollegeExamAccomodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({
            exam_accommodationsForCollegeExams: false,
            exam_accommodationsForCollegeExamsDescription: '',
        })
    },

    CollegeExamAccomodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_accommodationsForCollegeExams: true})
    },

    MedicalSchoolAccommodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({exam_accommodationsForMedicalSchoolExams: false})
    },

    MedicalSchoolAccommodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_accommodationsForMedicalSchoolExams: true})
    },

    ResidencyExamsAccommodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({exam_accommodationsForResidencyExams: false})
    },

    ResidencyExamsAccommodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_accommodationsForResidencyExams: true})
    },

    SpecialAccommodationsNoSelected() {
        NonStandardExamLogicStep5.context.setState({exam_specialAccommodation: false})
    },

    SpecialAccommodationsYesSelected() {
        NonStandardExamLogicStep5.context.setState({exam_specialAccommodation: true})
    },

    SetOtherStandardizedExams() {
        const {otherStandardizedExams} = NonStandardExamLogicStep5.context.state
        if (otherStandardizedExams === null || !otherStandardizedExams) {
            NonStandardExamLogicStep5.context.setState({
                otherStandardizedExams: true,
            })
        } else {
            NonStandardExamLogicStep5.context.setState({
                otherStandardizedExams: false,
                exam_otherExamAccommodationsAndYears: '',
            })
        }
    },

    UpdateNSEStep5: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {
                exam_residencyOrFellowAccommodations,
                exam_residencyOrFellowAccomodationsDesc,
                exam_clinicalPracticeAccommodations,
                exam_clinicalPracticeAccommodationsDescription,
                exam_specificExamAccommodationRequested,
                exam_wheelchairAccessRequired,
                exam_accommodationsForCollegeExams,
                exam_accommodationsForCollegeExamsDescription,
                exam_accommodationsForMedicalSchoolExams,
                exam_accommodationsForResidencyExams,
                exam_specialAccommodation,
                exam_iteAccommodation,
                exam_iteYears,
                exam_usmle1Accommodation,
                exam_usmle1Years,
                exam_usmle2Accommodation,
                exam_usmle2Years,
                exam_usmle3Accommodation,
                exam_usmle3Years,
                exam_otherExamAccommodationsAndYears,
            } = this.context.state

            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep5`, {
                NSEID: nonStandardExamContext.state.NonStandardExamID,
                exam_residencyOrFellowAccommodations,
                exam_residencyOrFellowAccomodationsDesc,
                exam_clinicalPracticeAccommodations,
                exam_clinicalPracticeAccommodationsDescription,
                exam_specificExamAccommodationRequested,
                exam_wheelchairAccessRequired,
                exam_accommodationsForCollegeExams,
                exam_accommodationsForCollegeExamsDescription,
                exam_accommodationsForMedicalSchoolExams,
                exam_accommodationsForResidencyExams,
                exam_specialAccommodation,
                exam_iteAccommodation,
                exam_iteYears,
                exam_usmle1Accommodation,
                exam_usmle1Years,
                exam_usmle2Accommodation,
                exam_usmle2Years,
                exam_usmle3Accommodation,
                exam_usmle3Years,
                exam_otherExamAccommodationsAndYears,
                rs_statuscode: nonStandardExamContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }
            this.UpdateNSEStep5().then((response) => {
                if (response) {
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep6,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            this.UpdateNSEStep5().then((response) => {
                if (response) {
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep4,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },
    ValidatePage: function () {
        const {
            exam_residencyOrFellowAccommodations,
            exam_clinicalPracticeAccommodations,
            exam_accommodationsForCollegeExams,
            InputStatus,
        } = this.context.state
        const {error, success} = GeneralEnums.textInputStatus

        var affirmationRadios = [
            'exam_residencyOrFellowAccommodations',
            'exam_clinicalPracticeAccommodations',
            'exam_wheelchairAccessRequired',
            'exam_accommodationsForCollegeExams',
            'exam_accommodationsForMedicalSchoolExams',
            'exam_accommodationsForResidencyExams',
            'exam_specialAccommodation',
        ]

        var textFields = {
            exam_residencyOrFellowAccomodationsDesc: exam_residencyOrFellowAccommodations === true,
            exam_clinicalPracticeAccommodationsDescription: exam_clinicalPracticeAccommodations === true,
            exam_specificExamAccommodationRequested: true,
            exam_accommodationsForCollegeExamsDescription: exam_accommodationsForCollegeExams === true,
        }

        var specialExaminationFields = {
            exam_iteYears:
                this.context.state.exam_specialAccommodation === true &&
                this.context.state.exam_iteAccommodation,
            exam_usmle1Years:
                this.context.state.exam_specialAccommodation === true &&
                this.context.state.exam_usmle1Accommodation,
            exam_usmle2Years:
                this.context.state.exam_specialAccommodation === true &&
                this.context.state.exam_usmle2Accommodation,
            exam_usmle3Years:
                this.context.state.exam_specialAccommodation === true &&
                this.context.state.exam_usmle3Accommodation,
            exam_otherExamAccommodationsAndYears:
                this.context.state.exam_specialAccommodation === true &&
                this.context.state.otherStandardizedExams,
        }

        var checkBoxes = {
            exam_iteAccommodation: this.context.state.exam_iteAccommodation,
            exam_usmle1Accommodation: this.context.state.exam_usmle1Accommodation,
            exam_usmle2Accommodation: this.context.state.exam_usmle2Accommodation,
            exam_usmle3Accommodation: this.context.state.exam_usmle3Accommodation,
            otherStandardizedExams: this.context.state.otherStandardizedExams,
        }

        affirmationRadios.forEach(
            (radio) => (InputStatus[radio] = this.context.state[radio] !== null ? success : error),
        )

        Object.keys(textFields).forEach((field) => {
            if (textFields[field] === false) {
                InputStatus[field] = GeneralEnums.textInputStatus.default
                return
            }
            if (this.context.state[field].length !== 0) {
                InputStatus[field] = success
            } else {
                InputStatus[field] = error
            }
        })

        //Loops through specialExaminationFields properties checking to see if field should be validated based on if the associated checkbox is checked. If so, Validate text fields.
        Object.keys(specialExaminationFields).forEach((field) => {
            if (specialExaminationFields[field] === false) {
                InputStatus[field] = GeneralEnums.textInputStatus.default
                return
            }
            if (this.context.state[field].length !== 0) {
                InputStatus[field] = success
            } else {
                InputStatus[field] = error
            }
        })

        var checkedFieldsHaveNoEmptyTextBoxes = !Object.keys(specialExaminationFields)
            .filter((field) => specialExaminationFields[field] === true)
            .some((field) => this.context.state[field].length === 0)
        var atLeastOneCheckboxIsChecked = Object.keys(checkBoxes).some(
            (check) => checkBoxes[check] === true,
        )
        console.log(atLeastOneCheckboxIsChecked, checkedFieldsHaveNoEmptyTextBoxes)

        if (
            this.context.state.exam_specialAccommodation === true &&
            atLeastOneCheckboxIsChecked &&
            checkedFieldsHaveNoEmptyTextBoxes
        ) {
            InputStatus.exam_specialAccommodationChecks = success
        } else {
            InputStatus.exam_specialAccommodationChecks =
                this.context.state.exam_specialAccommodation === false
                    ? GeneralEnums.textInputStatus.default
                    : error
        }

        var Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        this.context.setState({
            InputStatus: InputStatus,
        })
        return Validated
    },
}

export default NonStandardExamLogicStep5
