import React, {createContext, useReducer} from 'react'
import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    CurrentProgressTab: 'mm',
}

export const pageSettings = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetCurrentProgressTab:
            return {...state, CurrentProgressTab: action.payload}
        case ActionTypes.ClearUserData:
                return {...state, ...initialState}
        default:
            return state
    }
}

export default pageSettings
