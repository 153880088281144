import React, {Component} from 'react'
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native'
import ABAAffirmationRadio from '../../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABACheckbox from '../../../../components/ABACustom/presentation/ABACheckbox'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import ABATextInput from '../../../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods'
import styleFunctions from '../../../../styles/styleFunctions'
import {ABAFileUpload} from '../../../ABACustom/presentation/ABAFileUpload'
import NonStandardExamLogicStep5 from '../logic/nonStandardExamStep5Logic'

export default class NonStandardExamStep5 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        exam_residencyOrFellowAccommodations: null,
        exam_residencyOrFellowAccomodationsDesc: '',
        exam_clinicalPracticeAccommodations: null,
        exam_clinicalPracticeAccommodationsDescription: '',
        exam_specificExamAccommodationRequested: '',
        exam_wheelchairAccessRequired: null,
        exam_accommodationsForCollegeExams: null,
        exam_accommodationsForCollegeExamsDescription: '',
        exam_accommodationsForMedicalSchoolExams: null,
        exam_accommodationsForResidencyExams: null,
        exam_specialAccommodation: null,
        exam_iteAccommodation: null,
        exam_iteYears: '',
        exam_iteAccommodationFile: null,
        exam_usmle1Accommodation: null,
        exam_usmle1Years: '',
        exam_usmle2Accommodation: null,
        exam_usmle2Years: '',
        exam_usmle3Accommodation: null,
        exam_usmle3Years: '',
        exam_usmle1AccommodationFile: null,
        exam_usmle2AccommodationFile: null,
        exam_usmle3AccommodationFile: null,
        exam_otherExamAccommodationsAndYears: '',
        otherStandardizedExams: false,
        InputStatus: {
            exam_residencyOrFellowAccommodations: GeneralEnums.textInputStatus.default,
            exam_residencyOrFellowAccomodationsDesc: GeneralEnums.textInputStatus.default,
            exam_clinicalPracticeAccommodations: GeneralEnums.textInputStatus.default,
            exam_clinicalPracticeAccommodationsDescription: GeneralEnums.textInputStatus.default,
            exam_specificExamAccommodationRequested: GeneralEnums.textInputStatus.default,
            exam_wheelchairAccessRequired: GeneralEnums.textInputStatus.default,
            exam_accommodationsForCollegeExams: GeneralEnums.textInputStatus.default,
            exam_accommodationsForCollegeExamsDescription: GeneralEnums.textInputStatus.default,
            exam_accommodationsForMedicalSchoolExams: GeneralEnums.textInputStatus.default,
            exam_accommodationsForResidencyExams: GeneralEnums.textInputStatus.default,
            exam_specialAccommodation: GeneralEnums.textInputStatus.default,
            exam_specialAccommodationChecks: GeneralEnums.textInputStatus.default,
            exam_iteAccommodation: GeneralEnums.textInputStatus.default,
            exam_iteYears: GeneralEnums.textInputStatus.default,
            exam_usmle1Accommodation: GeneralEnums.textInputStatus.default,
            exam_usmle1Years: GeneralEnums.textInputStatus.default,
            exam_usmle2Accommodation: GeneralEnums.textInputStatus.default,
            exam_usmle2Years: GeneralEnums.textInputStatus.default,
            exam_usmle3Accommodation: GeneralEnums.textInputStatus.default,
            exam_usmle3Years: GeneralEnums.textInputStatus.default,
            exam_otherExamAccommodationsAndYears: GeneralEnums.textInputStatus.default,
            otherStandardizedExams: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        NonStandardExamLogicStep5.GetNSEData().then((NSEData) => {
            NonStandardExamLogicStep5.PopulateStateVariables(NSEData)
        })
    }

    Save() {
        return NonStandardExamLogicStep5.SaveLogic()
    }

    Previous() {
        return NonStandardExamLogicStep5.Previous()
    }

    render() {
        NonStandardExamLogicStep5.context = this
        const {InputStatus, ComponentWidth} = this.state

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 3 of 5</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.6),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.4),
                            ]}
                        />
                    </View>
                )}

                <View>
                    <View style={{marginBottom: 15}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    textDecorationLine: 'underline',
                                    fontSize: 14,
                                    paddingTop: 15,
                                    paddingBottom: 10,
                                    fontWeight: 'bold',
                                    width: '100%',
                                },
                            ]}
                        >
                            Accommodation in Clinical Training / Clinical Practice
                        </Text>
                    </View>
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: ColorEnums.cloudBurst,
                            paddingBottom: 10,
                            marginBottom: 15,
                        }}
                    >
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                    ]}
                                >
                                    Did you have accommodation for your impairment in your bedside
                                    clinical activities as an anesthesiology resident or fellow?
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                                <ABAAffirmationRadio
                                    leftOptionContent="Yes"
                                    leftOptionOnPress={
                                        NonStandardExamLogicStep5.ResidencyOrFellowAccommodationsYesSelected
                                    }
                                    rightOptionContent="No"
                                    rightOptionOnPress={
                                        NonStandardExamLogicStep5.ResidencyOrFellowAccommodationsNoSelected
                                    }
                                    optionValues={{leftValue: true, rightValue: false}}
                                    selectedValue={this.state.exam_residencyOrFellowAccommodations}
                                    validationStatus={InputStatus.exam_residencyOrFellowAccommodations}
                                />
                            </View>
                            {this.state.exam_residencyOrFellowAccommodations && (
                                <View>
                                    <View>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 14,
                                                    paddingTop: 15,
                                                    width: '100%',
                                                    marginBottom: 4,
                                                },
                                            ]}
                                        >
                                            Please describe each accommodation and the reason for it. (
                                            <Text style={{fontStyle: 'italic'}}>
                                                Do <Text style={{fontWeight: 'bold'}}>not</Text> list
                                                exam accommodations.
                                            </Text>
                                            )
                                            <ABARequiredAsterisk />
                                        </Text>
                                    </View>
                                    <ABATextInputMultiline
                                        maxLength={2000}
                                        numberOfLines={8}
                                        onChangeText={(exam_residencyOrFellowAccomodationsDesc) =>
                                            this.setState({exam_residencyOrFellowAccomodationsDesc})
                                        }
                                        value={this.state.exam_residencyOrFellowAccomodationsDesc}
                                        textInputStatus={
                                            InputStatus.exam_residencyOrFellowAccomodationsDesc
                                        }
                                    />
                                </View>
                            )}
                        </View>
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                    ]}
                                >
                                    Do you have accommodation for your impairment in your clinical
                                    practice?
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                                <ABAAffirmationRadio
                                    leftOptionContent="Yes"
                                    leftOptionOnPress={
                                        NonStandardExamLogicStep5.ClinicalPracticeAccommodationsYesSelected
                                    }
                                    rightOptionContent="No"
                                    rightOptionOnPress={
                                        NonStandardExamLogicStep5.ClinicalPracticeAccommodationsNoSelected
                                    }
                                    optionValues={{leftValue: true, rightValue: false}}
                                    selectedValue={this.state.exam_clinicalPracticeAccommodations}
                                    validationStatus={InputStatus.exam_clinicalPracticeAccommodations}
                                />
                            </View>
                            {this.state.exam_clinicalPracticeAccommodations && (
                                <View>
                                    <View>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 14,
                                                    paddingTop: 15,
                                                    width: '100%',
                                                    marginBottom: 4,
                                                },
                                            ]}
                                        >
                                            Please describe each accommodation and the reason for it. (
                                            <Text style={{fontStyle: 'italic'}}>
                                                Do <Text style={{fontWeight: 'bold'}}>not</Text> list
                                                exam accommodations.
                                            </Text>
                                            )
                                            <ABARequiredAsterisk />
                                        </Text>
                                    </View>
                                    <ABATextInputMultiline
                                        maxLength={2000}
                                        numberOfLines={8}
                                        onChangeText={(exam_clinicalPracticeAccommodationsDescription) =>
                                            this.setState({
                                                exam_clinicalPracticeAccommodationsDescription,
                                            })
                                        }
                                        value={this.state.exam_clinicalPracticeAccommodationsDescription}
                                        textInputStatus={
                                            InputStatus.exam_clinicalPracticeAccommodationsDescription
                                        }
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                        marginBottom: 15,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                textDecorationLine: 'underline',
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '100%',
                            },
                        ]}
                    >
                        Test Accommodation Requested
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '100%',
                            },
                        ]}
                    >
                        Request only accommodations necessary and appropriate to accommodate the effect
                        of the impairment on your ability to take the exam under standard conditions.
                    </Text>
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, width: '100%', marginBottom: 4},
                                ]}
                            >
                                What specific exam accommodation are you requesting? For each, explain
                                why it is an appropriate and necessary accommodation, given the
                                limitations on your ability to take the exam under standard conditions
                                due to your impairment.
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <ABATextInputMultiline
                            maxLength={2000}
                            numberOfLines={8}
                            onChangeText={(exam_specificExamAccommodationRequested) =>
                                this.setState({exam_specificExamAccommodationRequested})
                            }
                            value={this.state.exam_specificExamAccommodationRequested}
                            textInputStatus={InputStatus.exam_specificExamAccommodationRequested}
                        />
                    </View>
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Do you require wheelchair access at the exam facility?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={NonStandardExamLogicStep5.WheelchairAccessYesSelected}
                            rightOptionContent="No"
                            rightOptionOnPress={NonStandardExamLogicStep5.WheelchairAccessNoSelected}
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_wheelchairAccessRequired}
                            validationStatus={InputStatus.exam_wheelchairAccessRequired}
                        />
                    </View>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                        marginBottom: 15,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                textDecorationLine: 'underline',
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '100%',
                            },
                        ]}
                    >
                        Prior Test Accommodation
                    </Text>
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Did you have special accommodation for college exams?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={
                                NonStandardExamLogicStep5.CollegeExamAccomodationsYesSelected
                            }
                            rightOptionContent="No"
                            rightOptionOnPress={
                                NonStandardExamLogicStep5.CollegeExamAccomodationsNoSelected
                            }
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_accommodationsForCollegeExams}
                            validationStatus={InputStatus.exam_accommodationsForCollegeExams}
                        />
                    </View>
                    {this.state.exam_accommodationsForCollegeExams && (
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, paddingTop: 15, width: '100%', marginBottom: 4},
                                    ]}
                                >
                                    What were the exam accommodations?
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <ABATextInputMultiline
                                maxLength={2000}
                                numberOfLines={8}
                                onChangeText={(exam_accommodationsForCollegeExamsDescription) =>
                                    this.setState({exam_accommodationsForCollegeExamsDescription})
                                }
                                value={this.state.exam_accommodationsForCollegeExamsDescription}
                                textInputStatus={
                                    InputStatus.exam_accommodationsForCollegeExamsDescription
                                }
                            />
                        </View>
                    )}
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Did you have special accommodation for medical school exams?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={
                                NonStandardExamLogicStep5.MedicalSchoolAccommodationsYesSelected
                            }
                            rightOptionContent="No"
                            rightOptionOnPress={
                                NonStandardExamLogicStep5.MedicalSchoolAccommodationsNoSelected
                            }
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_accommodationsForMedicalSchoolExams}
                            validationStatus={InputStatus.exam_accommodationsForMedicalSchoolExams}
                        />
                    </View>
                    {this.state.exam_accommodationsForMedicalSchoolExams && (
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontSize: 14, paddingTop: 15, width: '100%', marginBottom: 4},
                                    ]}
                                >
                                    Please submit a statement from the medical school that describes the
                                    test accommodation.
                                </Text>
                            </View>
                            <ABAFileUpload
                                filename={this.state.exam_accommodationsForMedicalSchoolExamsFile}
                                CRMField={'exam_accommodationsForMedicalSchoolExams'}
                                NSEID={nonStandardExamContext.state.NonStandardExamID}
                                deleteurl={`nserequestsinfo/removefile`}
                                uploadurl={`nserequestsinfo/uploadfile`}
                            />
                        </View>
                    )}
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Did you have special accommodation for exams given by the anesthesiology
                            residency program?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={
                                NonStandardExamLogicStep5.ResidencyExamsAccommodationsYesSelected
                            }
                            rightOptionContent="No"
                            rightOptionOnPress={
                                NonStandardExamLogicStep5.ResidencyExamsAccommodationsNoSelected
                            }
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_accommodationsForResidencyExams}
                            validationStatus={InputStatus.exam_accommodationsForResidencyExams}
                        />
                    </View>
                    {this.state.exam_accommodationsForResidencyExams && (
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText('right'),
                                        {fontSize: 14, paddingTop: 15, width: '100%', marginBottom: 4},
                                    ]}
                                >
                                    Please submit a statement from your anesthesiology residency program
                                    that describes the exam accommodation.
                                </Text>
                            </View>
                            <ABAFileUpload
                                filename={this.state.exam_accommodationsForResidencyExamsFile}
                                CRMField={'exam_accommodationsForResidencyExams'}
                                NSEID={nonStandardExamContext.state.NonStandardExamID}
                                deleteurl={`nserequestsinfo/removefile`}
                                uploadurl={`nserequestsinfo/uploadfile`}
                            />
                        </View>
                    )}
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Did you have special accommodation for an anesthesiology in-training, medical
                            licensure or other standardized exam?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25, paddingBottom: 15}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={
                                NonStandardExamLogicStep5.SpecialAccommodationsYesSelected
                            }
                            rightOptionContent="No"
                            rightOptionOnPress={
                                NonStandardExamLogicStep5.SpecialAccommodationsNoSelected
                            }
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_specialAccommodation}
                            validationStatus={InputStatus.exam_specialAccommodation}
                        />
                    </View>
                    {this.state.exam_specialAccommodation && (
                        <View
                            style={
                                validationStyles[this.state.InputStatus.exam_specialAccommodationChecks]
                            }
                        >
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        width: '100%',
                                        marginBottom: 4,
                                        paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                    },
                                ]}
                            >
                                Please add the Type of Exam, Exam Year(s), and submit a statement from
                                the testing agency that describes the exam accommodation.
                                <ABARequiredAsterisk />
                            </Text>

                            <View style={{padding: 5}}>
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '60%' : '100%',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ABACheckbox
                                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                            isChecked={this.state.exam_iteAccommodation}
                                            label={'ABA/ASA In-Training Exam'}
                                            onChangeEvent={() => {
                                                this.setState({
                                                    exam_iteAccommodation:
                                                        !this.state.exam_iteAccommodation,
                                                })
                                            }}
                                            validationStatus={InputStatus.exam_iteAccommodation}
                                            width={ComponentWidth > 600 ? '90%' : '100%'}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '40%' : '100%',
                                            justifyContent: 'center',
                                            paddingBottom: ComponentWidth > 600 ? 0 : 10,
                                        }}
                                    >
                                        <ABATextInput
                                            placeholder="ITE Exam Year(s)"
                                            returnKeyType="next"
                                            textInputStatus={InputStatus.exam_iteYears}
                                            onChangeText={(exam_iteYears) =>
                                                this.setState({exam_iteYears})
                                            }
                                            value={this.state.exam_iteYears}
                                            width="100%"
                                            maxLength={100}
                                        />
                                    </View>
                                </View>
                                <ABAFileUpload
                                    filename={this.state.exam_iteAccommodationFile}
                                    CRMField={'exam_iteAccommodation'}
                                    NSEID={nonStandardExamContext.state.NonStandardExamID}
                                    deleteurl={`nserequestsinfo/removefile`}
                                    uploadurl={`nserequestsinfo/uploadfile`}
                                />
                            </View>
                            <View
                                style={{
                                    padding: 5,
                                    borderTopWidth: 1,
                                    borderColor: ColorEnums.cloudBurst,
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '60%' : '100%',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ABACheckbox
                                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                            isChecked={this.state.exam_usmle1Accommodation}
                                            label={'USMLE Step 1'}
                                            onChangeEvent={() => {
                                                this.setState({
                                                    exam_usmle1Accommodation:
                                                        !this.state.exam_usmle1Accommodation,
                                                })
                                            }}
                                            validationStatus={InputStatus.exam_usmle1Accommodation}
                                            width={ComponentWidth > 600 ? '90%' : '100%'}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '40%' : '100%',
                                            justifyContent: 'center',
                                            paddingBottom: ComponentWidth > 600 ? 0 : 10,
                                        }}
                                    >
                                        <ABATextInput
                                            placeholder="USMLE Step 1 Year(s)"
                                            returnKeyType="next"
                                            textInputStatus={InputStatus.exam_usmle1Years}
                                            onChangeText={(exam_usmle1Years) =>
                                                this.setState({exam_usmle1Years})
                                            }
                                            value={this.state.exam_usmle1Years}
                                            width="100%"
                                            maxLength={100}
                                        />
                                    </View>
                                </View>
                                <ABAFileUpload
                                    filename={this.state.exam_usmle1AccommodationFile}
                                    CRMField={'exam_usmle1Accommodation'}
                                    NSEID={nonStandardExamContext.state.NonStandardExamID}
                                    deleteurl={`nserequestsinfo/removefile`}
                                    uploadurl={`nserequestsinfo/uploadfile`}
                                />
                            </View>
                            <View
                                style={{
                                    padding: 5,
                                    borderTopWidth: 1,
                                    borderColor: ColorEnums.cloudBurst,
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '60%' : '100%',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ABACheckbox
                                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                            isChecked={this.state.exam_usmle2Accommodation}
                                            label={'USMLE Step 2'}
                                            onChangeEvent={() => {
                                                this.setState({
                                                    exam_usmle2Accommodation:
                                                        !this.state.exam_usmle2Accommodation,
                                                })
                                            }}
                                            validationStatus={InputStatus.exam_usmle2Accommodation}
                                            width={ComponentWidth > 600 ? '90%' : '100%'}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '40%' : '100%',
                                            justifyContent: 'center',
                                            paddingBottom: ComponentWidth > 600 ? 0 : 10,
                                        }}
                                    >
                                        <ABATextInput
                                            placeholder="USMLE Step 2 Year(s)"
                                            returnKeyType="next"
                                            textInputStatus={InputStatus.exam_usmle2Years}
                                            onChangeText={(exam_usmle2Years) =>
                                                this.setState({exam_usmle2Years})
                                            }
                                            value={this.state.exam_usmle2Years}
                                            width="100%"
                                            maxLength={100}
                                        />
                                    </View>
                                </View>
                                <ABAFileUpload
                                    filename={this.state.exam_usmle2AccommodationFile}
                                    CRMField={'exam_usmle2Accommodation'}
                                    NSEID={nonStandardExamContext.state.NonStandardExamID}
                                    deleteurl={`nserequestsinfo/removefile`}
                                    uploadurl={`nserequestsinfo/uploadfile`}
                                />
                            </View>
                            <View
                                style={{
                                    padding: 5,
                                    borderTopWidth: 1,
                                    borderColor: ColorEnums.cloudBurst,
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '60%' : '100%',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ABACheckbox
                                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                            isChecked={this.state.exam_usmle3Accommodation}
                                            label={'USMLE Step 3'}
                                            onChangeEvent={() => {
                                                this.setState({
                                                    exam_usmle3Accommodation:
                                                        !this.state.exam_usmle3Accommodation,
                                                })
                                            }}
                                            validationStatus={InputStatus.exam_usmle3Accommodation}
                                            width={ComponentWidth > 600 ? '90%' : '100%'}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '40%' : '100%',
                                            justifyContent: 'center',
                                            paddingBottom: ComponentWidth > 600 ? 0 : 10,
                                        }}
                                    >
                                        <ABATextInput
                                            placeholder="USMLE Step 3 Year(s)"
                                            returnKeyType="next"
                                            textInputStatus={InputStatus.exam_usmle3Years}
                                            onChangeText={(exam_usmle3Years) =>
                                                this.setState({exam_usmle3Years})
                                            }
                                            value={this.state.exam_usmle3Years}
                                            width="100%"
                                            maxLength={100}
                                        />
                                    </View>
                                </View>
                                <ABAFileUpload
                                    filename={this.state.exam_usmle3AccommodationFile}
                                    CRMField={'exam_usmle3Accommodation'}
                                    NSEID={nonStandardExamContext.state.NonStandardExamID}
                                    deleteurl={`nserequestsinfo/removefile`}
                                    uploadurl={`nserequestsinfo/uploadfile`}
                                />
                            </View>
                            <View
                                style={{
                                    padding: 5,
                                    borderTopWidth: 1,
                                    borderColor: ColorEnums.cloudBurst,
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? '60%' : '100%',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ABACheckbox
                                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                                            isChecked={
                                                this.state.otherStandardizedExams ||
                                                this.state.exam_otherExamAccommodationsAndYears
                                                    ? true
                                                    : false
                                            }
                                            label={'Other Standardized Exams'}
                                            onChangeEvent={() =>
                                                NonStandardExamLogicStep5.SetOtherStandardizedExams()
                                            }
                                            validationStatus={InputStatus.otherStandardizedExams}
                                            width="100%"
                                        />
                                    </View>
                                </View>
                                {this.state.otherStandardizedExams && (
                                    <View>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 14,
                                                    paddingTop: 15,
                                                    width: '100%',
                                                    marginBottom: 4,
                                                },
                                            ]}
                                        >
                                            Please add the Type of Exam, Exam Year(s), and submit a
                                            statement from the testing agency that describes the exam
                                            accommodation.
                                            <ABARequiredAsterisk />
                                        </Text>
                                        <ABATextInputMultiline
                                            maxLength={2000}
                                            numberOfLines={8}
                                            onChangeText={(exam_otherExamAccommodationsAndYears) =>
                                                this.setState({exam_otherExamAccommodationsAndYears})
                                            }
                                            value={this.state.exam_otherExamAccommodationsAndYears}
                                            textInputStatus={
                                                InputStatus.exam_otherExamAccommodationsAndYears
                                            }
                                        />
                                        <ABAFileUpload
                                            filename={this.state.otherStandardizedExamsFile}
                                            CRMField={'exam_otherexamaccommodationsandyears'}
                                            NSEID={nonStandardExamContext.state.NonStandardExamID}
                                            deleteurl={`nserequestsinfo/removefile`}
                                            uploadurl={`nserequestsinfo/uploadfile`}
                                        />
                                    </View>
                                )}
                                {this.state.InputStatus.exam_specialAccommodationChecks ===
                                    GeneralEnums.textInputStatus.error && (
                                    <View>
                                        <Text style={{color: ColorEnums.pomegranate}}>
                                            Please select at least 1 Type of Exam.
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Non-Standard Exam Page 3</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

const validationStyles = StyleSheet.create({
    [GeneralEnums.textInputStatus.error]: {
        borderRadius: 5,
        borderColor: ColorEnums.pomegranate,
        borderWidth: 1,
        margin: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
    [GeneralEnums.textInputStatus.success]: {
        borderRadius: 5,
        borderColor: ColorEnums.surfGreen,
        borderWidth: 1,
        margin: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
})
