import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ReceiptDetailsLogic = {
    context: null,

    getReceiptDetails: function (Data) {
        return new Promise((resolve) => {
            ReceiptDetailsLogic.context.setState({CallingApi: true})
            const InvoiceId =
                ReceiptDetailsLogic.context.props.InvoiceId ||
                ReceiptDetailsLogic.context.navigationData.InvoiceID
            const Source =
                ReceiptDetailsLogic.context.props.Source ||
                ReceiptDetailsLogic.context.navigationData.Source

            ABAPortalAPI.post('receipts/getReceiptDetails', {
                InvoiceId,
                Source,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
                .finally(function () {
                    ReceiptDetailsLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default ReceiptDetailsLogic
