import React, {Component} from 'react'
import {ActivityIndicator, Text, View, ScrollView, StyleSheet} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import styleFunctions from '../../styles/styleFunctions'
import ViewRegistrationDetailsLogic from '../logic/viewRegistrationDetailsLogic'
import {ColorEnums} from '../../helpers/generalEnums'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import Acknowledgement from '../../components/registrationsteps/presentation/acknowledgement'
import GeneralIPR from '../../components/registrationsteps/presentation/generalIPR'
import CMERelease from '../../components/registrationsteps/presentation/cmeReleaseForm'
import PracticeArea from '../../components/registrationsteps/presentation/practiceAreaAndSetting'
import MOCACopyRight from '../../components/registrationsteps/presentation/mocaCopyrightRelease'
import Release from '../../components/registrationsteps/presentation/release'
import Confidentiality from '../../components/registrationsteps/presentation/confidentialityPolicy'
import ClinicalActivity from '../../components/registrationsteps/presentation/clinicalActivity'
import SubstanceAbuse from '../../components/registrationsteps/presentation/substanceAbuse'
import MOCAAnnualPayment from '../../components/registrationsteps/presentation/mocaAnnualPayment'
import MedLicenseRelease from '../../components/registrationsteps/presentation/medicalLicenseRelease'
import PaymentandRegistration from '../../components/registrationsteps/presentation/paymentAndRegistration'
import NSE from '../../components/registrationsteps/presentation/nonStandardExam'
import CertificationInformation from '../../components/registrationsteps/presentation/certificateinformation'
import ViewReceiptDetails from '../presentation/viewReceiptDetails'
import CMECreditRelease from '../../components/registrationsteps/presentation/cmeCreditRelease'
import PrintedFooter from '../../components/presentation/printedFooter'

export default class ViewRegistrationDetails extends Component {
    state = {
        CallingApi: false,
        registrationSteps: [],
        completionDate: null,
    }

    componentsListing = {
        'MOCA Independent Practice Requirement': GeneralIPR,
        Acknowledgement: Acknowledgement,
        'General Independent Practice Requirement': GeneralIPR,
        'Medical License Release': MedLicenseRelease,
        'MOCA Acknowledgement and Release Form': Acknowledgement,
        'CME Release Form': CMERelease,
        'Practice Area & Setting': PracticeArea,
        'MOCA Copyright Release': MOCACopyRight,
        Release: Release,
        'Confidentiality Policy': Confidentiality,
        'Clinical Activity': ClinicalActivity,
        'Prerequisite Check': PaymentandRegistration,
        'Substance Abuse': SubstanceAbuse,
        'MOCA Annual Payment': MOCAAnnualPayment,
        'Certificate Information': CertificationInformation,
        'Payment and Registration': PaymentandRegistration,
        NSE: NSE,
        'CME Credit Release': CMECreditRelease,
    }

    styles = StyleSheet.create({
        accordionTitlebutton: {
            flex: 1,
            backgroundColor: ColorEnums.white,
        },

        accordionComponentTitle: {
            textAlign: 'left',
            paddingLeft: 10,
            fontSize: 15,
            color: ColorEnums.cloudBurst,
            fontWeight: 'bold',
        },
    })

    componentDidMount() {
        this.setState({CallingApi: true}, () => {
            ViewRegistrationDetailsLogic.GetAllRegistrationStepsDisplayData().then((responseData) => {
                responseData = responseData.map((data) => {
                    data.isExpanded = false
                    return data
                })

                // we need the invoice ID in order to get the date that payment/registration was completed
                let invoiceId = null
                for (let i = 0; i < responseData.length; i++) {
                    if (responseData[i].ActivityTypeName.toLowerCase().includes('payment')) {
                        invoiceId = responseData[i].InvoiceId
                        break
                    }
                }

                if (!invoiceId) {
                    this.setState({CallingApi: false, registrationSteps: responseData})
                } else {
                    ViewRegistrationDetailsLogic.getReceiptDetails(invoiceId, 'CRM').then(
                        (receiptDetails) => {
                            this.setState({
                                completionDate: receiptDetails.PayDate,
                                registrationSteps: responseData,
                                CallingApi: false,
                            })
                        },
                    )
                }
            })
        })
    }

    expandAllComponents() {
        let registrationSteps = this.state.registrationSteps.map((steps) => {
            steps.isExpanded = true
            return steps
        })

        this.setState({registrationSteps})
    }

    collapseAllComponents() {
        let registrationSteps = this.state.registrationSteps.map((steps) => {
            steps.isExpanded = false
            return steps
        })

        this.setState({registrationSteps})
    }

    renderRegistrationStep(registrationStep, index) {
        const {ActivityTypeName, isExpanded} = registrationStep
        let MyComp
        if (ActivityTypeName.toLowerCase().indexOf('payment') === -1) {
            MyComp = this.componentsListing[ActivityTypeName]
        } else {
            MyComp = ViewReceiptDetails
        }

        return (
            <ABAAccordion
                key={index}
                title={ActivityTypeName}
                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                accordionTitleStyle={this.styles.accordionComponentTitle}
                accordionButtonStyle={this.styles.accordionTitlebutton}
                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                accordionExpanded={isExpanded}
                accordionUnderlayColor={ColorEnums.white}
                accordionIconLeft={{
                    collapsed: 'chevron-down',
                    expanded: 'chevron-up',
                }}
                onPress={() => {
                    let registrationSteps = [...viewRegistrationDetailsContext.state.registrationSteps]
                    registrationSteps[index].isExpanded = !isExpanded
                    this.setState({registrationSteps})
                }}
                accordionContent={
                    <View>
                        <MyComp
                            Order={registrationStep.re_Order}
                            CR2ID={viewRegistrationDetailsContext.navigationData.re_rulesetstatusId}
                            DoNotRenderNav={true}
                            IsViewRegistration={true}
                            InvoiceId={registrationStep.InvoiceId}
                            Source={'CRM'}
                            ProgramType={registrationStep.re_ProgramType}
                            SetPreviousVisibility={() => null}
                        />
                        {ActivityTypeName.toLowerCase() !== 'nse' &&
                            ActivityTypeName.toLowerCase() !== 'substance abuse' &&
                            ActivityTypeName.toLowerCase() !== 'clinical activity' && (
                                <View style={styleFunctions.confirmationOverlay()} />
                            )}
                    </View>
                }
            />
        )
    }

    renderExpandOrCollapseAll() {
        if (this.state.CallingApi) {
            return
        }

        let allComponentsExpanded = this.state.registrationSteps.map(
            (registrationStep) => registrationStep.isExpanded,
        )

        if (allComponentsExpanded.includes(false)) {
            return (
                <Text
                    onPress={() => {
                        this.expandAllComponents()
                    }}
                    style={{color: ColorEnums.danube, paddingTop: 20, paddingBottom: 20}}
                    className="no-print"
                >
                    Expand All
                </Text>
            )
        }
        return (
            <Text
                onPress={() => {
                    this.collapseAllComponents()
                }}
                style={{
                    color: ColorEnums.danube,
                    paddingTop: 20,
                    paddingBottom: 20,
                }}
                className="no-print"
            >
                Collapse All
            </Text>
        )
    }

    render() {
        viewRegistrationDetailsContext = this
        navigationContext = this
        ViewRegistrationDetailsLogic.context = viewRegistrationDetailsContext
        HelperMethods.setNavigationData(viewRegistrationDetailsContext)
        let paymentFound = false
        const registrationComponents = this.state.registrationSteps.map((registrationStep, index) => {
            if (paymentFound) {
                return
            }
            if (registrationStep.ActivityTypeName === 'Payment and Registration') {
                paymentFound = true
            }
            return this.renderRegistrationStep(registrationStep, index)
        })

        return (
            <NavigationShell context={viewRegistrationDetailsContext} print={true}>
                <ScrollView
                    style={{
                        flex: 1,
                        backgroundColor: ColorEnums.backgroundGray,
                    }}
                    ref="RegStepScrollView"
                >
                    {
                        <View
                            style={{
                                backgroundColor: ColorEnums.backgroundGray,
                                flex: 1,
                            }}
                        >
                            <View style={{width: '95%', alignSelf: 'center'}}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        color: ColorEnums.danube,
                                        fontSize: 24,
                                        fontFamily: 'Lato',
                                        paddingTop: 25,
                                        paddingBottom: 25,
                                    }}
                                />
                            </View>
                            <View
                                style={{
                                    width: '95%',
                                    alignSelf: 'center',
                                    backgroundColor: ColorEnums.white,
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: ColorEnums.indigo,
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: ColorEnums.white,
                                            width: '70%',
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            padding: 5,
                                            paddingLeft: 10,
                                            fontFamily: 'Lato',
                                        }}
                                    >
                                        REVIEW REGISTRATION
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        width: '95%',
                                        alignSelf: 'center',
                                        maxWidth: 700,
                                    }}
                                    onLayout={(event) =>
                                        HelperMethods.setComponentWidth(
                                            ViewRegistrationDetailsLogic.context,
                                            event,
                                        )
                                    }
                                >
                                    {this.state.completionDate && (
                                        <View>
                                            <Text
                                                style={{
                                                    color: ColorEnums.cloudBurst,
                                                    paddingTop: 20,
                                                    paddingBottom: 10,
                                                    fontFamily: 'Lato',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Registration completed on {this.state.completionDate}
                                            </Text>
                                        </View>
                                    )}
                                    {this.renderExpandOrCollapseAll()}
                                    {registrationComponents}
                                </View>
                            </View>
                        </View>
                    }
                    <PrintedFooter />
                </ScrollView>
                {viewRegistrationDetailsContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>{'Loading Registration Steps'}</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </NavigationShell>
        )
    }
}
