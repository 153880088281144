import React, {Component} from 'react'

import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import MedicalLicenseLogic from '../logic/medicalLicenseLogic'
import MedicalLicenseRelease from '../../components/registrationsteps/presentation/medicalLicenseRelease'
import NavigationShell from '../../components/presentation/navigationShell'
import PrintedFooter from '../../components/presentation/printedFooter'

import HelperMethods from '../../helpers/HelperMethods'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'

export default class MedicalLicense extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        MedicalLicenseLogic.context = this
        HelperMethods.setNavigationData(this)

        return (
            <NavigationShell context={this} print={true}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer containerTitle="All Medical Licenses">
                        <MedicalLicenseRelease showLinks={true} />
                    </ABAContainer>
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
