import React, {useEffect, useState} from 'react'
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native'
import {ColorEnums, GeneralEnums, UnicodeEnums, PaymentTypes} from '../../../helpers/generalEnums'

export const ABARadio = ({children, value, selectedValue, setSelectedValue, onPress, style}) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(selectedValue === value)
    }, [selectedValue, value])
    const onPressAction = () => {
        if (setSelectedValue) setSelectedValue(value)
        if (onPress) onPress()
    }
    return (
        <TouchableOpacity onPress={onPressAction} testID={`ABARadioOption${value}`}>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <View style={styles.radioOutside}>
                    <View style={isSelected && styles.radioInside} />
                </View>
                <View style={{...style}}>{children}</View>
            </View>
        </TouchableOpacity>
    )
}

export const ABARadioGroup = ({children, style, defaultValue, onChange, itemsUnderlined = true}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    useEffect(() => {
        onChange(selectedValue)
    }, [selectedValue])
    return (
        <View>
            {React.Children.map(children, (child) => {
                if (defaultValue === child.props.value && defaultValue === null)
                    setSelectedValue(child.props.value)
                return (
                    <View style={{...style}}>
                        {React.cloneElement(child, {selectedValue, setSelectedValue})}
                        {itemsUnderlined && (
                            <View
                                style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    borderBottomColor: ColorEnums.lightGray,
                                    borderBottomWidth: 2,
                                }}
                            />
                        )}
                    </View>
                )
            })}
        </View>
    )
}
const styles = StyleSheet.create({
    radioInside: {
        width: 10,
        height: 10,
        borderRadius: 50,
        backgroundColor: ColorEnums.danube,
    },
    radioOutside: {
        width: 18,
        height: 18,
        borderRadius: 50,
        paddingTop: 2,
        paddingLeft: 2,
        borderWidth: 2,
        marginRight: 10,
        borderColor: ColorEnums.blueGray,
    },
})
