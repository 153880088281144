import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import HelperMethods from '../../../helpers/HelperMethods'
import CertificationInformationLogic from '../logic/certificateinformationLogic'
import PersonalInfoLogic from '../../../screens/logic/personalInfoLogic'
import styleFunctions from '../../../styles/styleFunctions'
import {GeneralEnums, ColorEnums} from '../../../helpers/generalEnums'
import ABAModal from '../../ABACustom/presentation/ABAModal'
import ABACheckbox from '../../ABACustom/presentation/ABACheckbox'
import ABADropdown from '../../ABACustom/presentation/ABADropdown'
import ABATextInput from '../../ABACustom/presentation/ABATextInput'
import StrikeIronForm from '../../presentation/strikeIronForm'

export default class CertificationInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ShowStrikeIronScreen: false,
            si_AddressLine1: '',
            si_AddressLine2: '',
            si_City: '',
            si_ZipCode: '',
            si_StateSelected: 'null',
            orig_CountrySelected: 'null',
            orig_AddressLine1: '',
            orig_AddressLine2: '',
            orig_City: '',
            orig_ZipCode: '',
            orig_StateSelected: 'null',
            orig_StateOrProvince: '',
            CallingApi: false,
            CallingCreateApi: false,
            ComponentWidth: 1,
            StateList: [],
            CountryList: [],
            AddressTypeList: [],
            NumberofCerts: '',
            ABMS: false,
            NameOnCertificate: '',
            AddressType: 'null',
            Organization: '',
            Department: '',
            CountrySelected: 'null',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            ZipCode: '',
            StateSelected: 'null',
            StateOrProvince: '',
            AddressTypeStatus: GeneralEnums.textInputStatus.default,
            OrganizationStatus: GeneralEnums.textInputStatus.default,
            DepartmentStatus: GeneralEnums.textInputStatus.default,
            NameOnCertificateStatus: GeneralEnums.textInputStatus.default,
            CountrySelectedStatus: GeneralEnums.textInputStatus.default,
            AddressLine1Status: GeneralEnums.textInputStatus.default,
            CityStatus: GeneralEnums.textInputStatus.default,
            ZipCodeStatus: GeneralEnums.textInputStatus.default,
            StateSelectedStatus: GeneralEnums.textInputStatus.default,
            StateOrProvinceStatus: GeneralEnums.textInputStatus.default,
            UpdatePrimary: false,
        }
    }

    componentDidMount() {
        CertificationInformationLogic.GetCountryListData().then((responseDataCountry) => {
            PersonalInfoLogic.getAddressTypes().then((addressTypes) => {
                CertificationInformationLogic.GetCertificationInformationDisplayData().then(
                    (responseData) => {
                        CertificationInformationLogic.GetStateListData(responseData.aba_countryid).then(
                            (responseDataStates) => {
                                CertificationInformationContext.setState(
                                    {
                                        StateList: responseDataStates,
                                        CountryList: responseDataCountry,
                                        AddressTypeList: addressTypes,
                                    },
                                    () => {
                                        CertificationInformationLogic.populateStateVaribles(responseData)
                                    },
                                )
                            },
                        )
                    },
                )
            })
        })
    }

    Save() {
        return CertificationInformationLogic.SaveLogic()
    }

    renderStateAndPostal() {
        return (
            <View
                style={{
                    flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                    width: '100%',
                }}
            >
                <View
                    style={{
                        width: this.state.ComponentWidth > 600 ? '50%' : '100%',
                    }}
                >
                    {this.state.StateList.length > 0 ? (
                        <View style={{marginBottom: 30}}>
                            <ABADropdown
                                options={this.state.StateList}
                                label={`State${this.state.StateList.length === 0 ? '' : ' *'}`}
                                placeholder={`State${this.state.StateList.length === 0 ? '' : ' *'}`}
                                onChange={(value) =>
                                    CertificationInformationLogic.CheckRequiredDropdown(
                                        'StateSelected',
                                        value,
                                    )
                                }
                                selectedValue={this.state.StateSelected}
                                validationStatus={this.state.StateSelectedStatus}
                                width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                            />
                        </View>
                    ) : (
                        <ABATextInput
                            onChangeText={(text) =>
                                CertificationInformationLogic.CheckRequiredText('StateOrProvince', text)
                            }
                            placeholder="State or Province *"
                            textInputStatus={this.state.StateOrProvinceStatus}
                            returnKeyType="next"
                            width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                            marginLeft={0}
                            value={this.state.StateOrProvince}
                            onBlur={() =>
                                this.setState({
                                    StateOrProvince: HelperMethods.properCase(
                                        this.state.StateOrProvince,
                                    ),
                                })
                            }
                        />
                    )}
                </View>
                <View
                    style={{
                        width: this.state.ComponentWidth > 600 ? '50%' : '100%',
                    }}
                >
                    <ABATextInput
                        onChangeText={(text) =>
                            CertificationInformationLogic.CheckRequiredText('ZipCode', text)
                        }
                        placeholder="Postal Code *"
                        textInputStatus={this.state.ZipCodeStatus}
                        returnKeyType="next"
                        value={this.state.ZipCode}
                        width="100%"
                        maxLength={20}
                    />
                </View>
            </View>
        )
    }

    render() {
        let StateName = ''
        let CountryName = ''
        CertificationInformationContext = this
        CertificationInformationLogic.context = CertificationInformationContext
        let myStates = CertificationInformationContext.state.StateList
        if (myStates.length > 0 && StateName.length === 0) {
            for (var i = 0; i < myStates.length; i++) {
                if (myStates[i].value === CertificationInformationContext.state.StateSelected) {
                    StateName = myStates[i].label
                }
            }
        }
        let myCountries = CertificationInformationContext.state.CountryList
        if (myCountries.length > 0 && CountryName.length === 0) {
            for (var i = 0; i < myCountries.length; i++) {
                if (myCountries[i].value === CertificationInformationContext.state.CountrySelected) {
                    CountryName = myCountries[i].label
                }
            }
        }
        return (
            <View
                style={{width: '95%'}}
                onLayout={(event) =>
                    HelperMethods.setComponentWidth(CertificationInformationContext, event)
                }
            >
                <View style={{padding: 10, width: '100%'}}>
                    <View style={{marginTop: 20}}>
                        <Text style={[styleFunctions.containerText()]}>
                            If you are awarded certification, your certificate will be printed with your
                            name as it appears below and will be mailed to you at the address below.
                            Please review the information entered and make any necessary updates. Then
                            click "Continue." If everything is already correct as displayed click
                            "Continue." 
                        </Text>
                        <Text style={[styleFunctions.containerTextBold(), {marginTop: 20}]}>
                            NAME ON CERTIFICATE
                        </Text>
                        <View>
                            <ABATextInput
                                style={styleFunctions.paragraphText()}
                                placeholder="Name on Certificate *"
                                onChangeText={(text) =>
                                    CertificationInformationLogic.CheckRequiredText(
                                        'NameOnCertificate',
                                        text,
                                    )
                                }
                                width="100%"
                                value={this.state.NameOnCertificate}
                                textInputStatus={this.state.NameOnCertificateStatus}
                                maxLength={100}
                            />
                        </View>
                        <Text style={[styleFunctions.containerText(), {marginTop: 20}]}>
                            Your academic/medical degree(s) will not be printed on your certificate, so
                            please do not add your academic/medical degree(s) above. 
                        </Text>
                        <Text style={[styleFunctions.containerTextBold(), {marginTop: 20}]}>
                            CERTIFICATE MAILING ADDRESS
                        </Text>
                        <View
                            style={{
                                width: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: 30,
                                marginTop: 30,
                            }}
                        >
                            <ABADropdown
                                style={{marginBottom: 1}}
                                label="Address Type *"
                                placeholder="Address Type *"
                                options={this.state.AddressTypeList}
                                width="100%"
                                selectedValue={this.state.AddressType}
                                validationStatus={this.state.AddressTypeStatus}
                                onChange={(value) => {
                                    let status = this.state.AddressTypeStatus

                                    if (
                                        this.state.AddressTypeStatus ===
                                            GeneralEnums.textInputStatus.error &&
                                        value &&
                                        value != 'null'
                                    ) {
                                        status = GeneralEnums.textInputStatus.default
                                    }
                                    this.setState({
                                        AddressType: value,
                                        Organization: null,
                                        Department: null,
                                        AddressTypeStatus: status,
                                        OrganizationStatus: GeneralEnums.textInputStatus.default,
                                        DepartmentStatus: GeneralEnums.textInputStatus.default,
                                    })
                                }}
                            />
                        </View>
                        {/* if address type is set to professional then we show Organization and Department and make them required */}
                        {this.state.AddressType == 2 && (
                            <>
                                <ABATextInput
                                    style={styleFunctions.paragraphText()}
                                    placeholder="Organization *"
                                    onChangeText={(text) => this.setState({Organization: text})}
                                    width="100%"
                                    value={this.state.Organization}
                                    maxLength={100}
                                    textInputStatus={this.state.OrganizationStatus}
                                    onBlur={() =>
                                        this.setState({
                                            Organization: HelperMethods.properCase(
                                                this.state.Organization,
                                            ),
                                        })
                                    }
                                />
                                <ABATextInput
                                    style={styleFunctions.paragraphText()}
                                    placeholder="Department *"
                                    onChangeText={(text) => this.setState({Department: text})}
                                    width="100%"
                                    value={this.state.Department}
                                    maxLength={100}
                                    textInputStatus={this.state.DepartmentStatus}
                                    onBlur={() =>
                                        this.setState({
                                            Department: HelperMethods.properCase(this.state.Department),
                                        })
                                    }
                                />
                            </>
                        )}

                        <ABATextInput
                            style={styleFunctions.paragraphText()}
                            placeholder="Address Line 1 *"
                            onChangeText={(text) =>
                                CertificationInformationLogic.CheckRequiredText('AddressLine1', text)
                            }
                            width="100%"
                            value={this.state.AddressLine1}
                            textInputStatus={this.state.AddressLine1Status}
                            maxLength={200}
                            onBlur={() =>
                                this.setState({
                                    AddressLine1: HelperMethods.properCase(this.state.AddressLine1),
                                })
                            }
                        />

                        <ABATextInput
                            style={styleFunctions.paragraphText()}
                            placeholder="Address Line 2"
                            onChangeText={(text) => this.setState({AddressLine2: text})}
                            width="100%"
                            value={this.state.AddressLine2}
                            maxLength={200}
                            onBlur={() =>
                                this.setState({
                                    AddressLine2: HelperMethods.properCase(this.state.AddressLine2),
                                })
                            }
                        />
                        <View
                            style={{
                                width: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: 30,
                            }}
                        >
                            <ABADropdown
                                style={{marginBottom: 1}}
                                label="Country *"
                                placeholder="Country *"
                                options={this.state.CountryList || []}
                                width="100%"
                                selectedValue={this.state.CountrySelected}
                                validationStatus={this.state.CountrySelectedStatus}
                                onChange={(value) =>
                                    CertificationInformationLogic.countryChange('CountrySelected', value)
                                }
                            />
                        </View>
                        <ABATextInput
                            style={styleFunctions.paragraphText()}
                            placeholder="City *"
                            onChangeText={(text) =>
                                CertificationInformationLogic.CheckRequiredText('City', text)
                            }
                            width="100%"
                            value={this.state.City}
                            textInputStatus={this.state.CityStatus}
                            maxLength={50}
                            onBlur={() =>
                                this.setState({
                                    City: HelperMethods.properCase(this.state.City),
                                })
                            }
                        />
                        {this.renderStateAndPostal()}
                        <Text style={[styleFunctions.containerTextBold(), {marginTop: 20}]}>
                            ABMS RESTRICTION
                        </Text>
                        <Text
                            style={[styleFunctions.containerText(), {marginTop: 20, marginBottom: 20}]}
                        >
                            The ABA provides the American Board of Medical Specialties (ABMS) with lists
                            of all of its diplomates, including their name, city, state, type of
                            certification and date of certification. ABMS publishes this information in
                            its directory of diplomates from all 24 member boards. If you would like ABMS
                            to restrict publishing your city and state, please check the box below and
                            click "Continue."
                        </Text>
                        <View style={{alignItems: 'flex-start'}}>
                            <ABACheckbox
                                ref="ABMS"
                                labelStyle={styleFunctions.containerTextBold('left')}
                                isChecked={this.state.ABMS}
                                label="I request that the ABMS NOT publish any address information for me."
                                onChangeEvent={() => {
                                    this.setState({
                                        ABMS: !this.state.ABMS,
                                    })
                                }}
                            />
                        </View>
                        <Text style={[styleFunctions.containerTextBold(), {marginTop: 20}]}>
                            UPDATE PRIMARY ADDRESS
                        </Text>
                        <Text
                            style={[styleFunctions.containerText(), {marginTop: 20, marginBottom: 20}]}
                        >
                            Your certificate address, listed above, is where we will mail any
                            certificate, including requested duplicates, awarded to you by the ABA. Your
                            certificate address and primary address may not be the same. If you need to
                            change your primary address, you can do so by checking this box.
                        </Text>
                        <View style={{alignItems: 'flex-start'}}>
                            <ABACheckbox
                                labelStyle={styleFunctions.containerTextBold('left')}
                                isChecked={this.state.UpdatePrimary}
                                label="I would like to update my primary mailing address to match my certificate address"
                                onChangeEvent={() => {
                                    this.setState({
                                        UpdatePrimary: !this.state.UpdatePrimary,
                                    })
                                }}
                            />
                        </View>
                    </View>
                </View>
                {CertificationInformationContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Certificate Information</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
                {CertificationInformationContext.state.CallingCreateApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Creating Certificate Information Record</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
                {CertificationInformationContext.state.ShowStrikeIronScreen && (
                    <ABAModal
                        isVisible={this.state.ShowStrikeIronScreen}
                        onDismiss={() => this.setState({ShowStrikeIronScreen: false})}
                        onBackdropPress={() => this.setState({ShowStrikeIronScreen: false})}
                    >
                        <StrikeIronForm
                            NameOnCertificate={CertificationInformationContext.state.NameOnCertificate}
                            AddressLine1={CertificationInformationContext.state.AddressLine1}
                            AddressLine2={CertificationInformationContext.state.AddressLine2}
                            City={CertificationInformationContext.state.City}
                            StateSelected={CertificationInformationContext.state.StateSelected}
                            ZipCode={CertificationInformationContext.state.ZipCode}
                            onPressEvent={() =>
                                CertificationInformationContext.setState({
                                    ShowStrikeIronScreen: false,
                                })
                            }
                            CountryName={CountryName}
                            CountrySelected={CertificationInformationContext.state.CountrySelected}
                            StateName={StateName}
                            saveAsFound={CertificationInformationLogic.saveAsFound}
                            saveAsEntered={CertificationInformationLogic.saveAsEntered}
                        />
                    </ABAModal>
                )}
            </View>
        )
    }
}
