import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const NotificationsLogic = {
    context: null,

    getBellNotifications: function () {
        return new Promise((resolve) => {
            NotificationsLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('notifications/getBellNotifications')
                .then(function (response) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Bell Notifications')
                    resolve(null)
                })
        })
    },

    setBellNotificationToRead: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.post('notifications/setBellNotificationToRead', {})
                .then(function (response) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Reading Bell Notifications')
                    resolve(null)
                })
        })
    },

    deleteBellNotification: function (NotificationId) {
        return new Promise((resolve) => {
            ABAPortalAPI.post('notifications/deleteBellNotification', {NotificationId})
                .then(function (response) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    NotificationsLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Deleting Bell Notifications')
                    resolve(null)
                })
        })
    },
}

export default NotificationsLogic
