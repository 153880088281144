import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import practiceAreaAndSettingLogic from '../logic/practiceAreaAndSettingLogic'
import styleFunctions from '../../../styles/styleFunctions'
import {TextInputMask} from 'react-native-masked-text'
import ABADropdown from '../../ABACustom/presentation/ABADropdown'
import ABATextInput from '../../ABACustom/presentation/ABATextInput'

export default class PracticeAreaAndSetting extends Component {
    state = {
        CallingApi: false,
        Ambulatory: 0,
        CardiacAnesthesia: 0,
        CriticalCareMedicine: 0,
        GeneralOperativeAnesthesia: 0,
        NeuroAnesthesia: 0,
        ObstetricAnesthesia: 0,
        PainMedicine: 0,
        PediatricAnesthesia: 0,
        RegionalAnesthesia: 0,
        ThoracicAnesthesia: 0,
        Trauma: 0,
        PracticeLocation: 'null',
        PracticeLocationValidationStatus: GeneralEnums.textInputStatus.default,
        PracticeLocationList: [],
        OtherLocation: null,
        PracticeProfileData: null,
        InputStatus: {
            OtherLocation: GeneralEnums.textInputStatus.default,
            PracticeLocation: GeneralEnums.textInputStatus.default,
        },
        SubmittedTotalNotValid: false,
    }

    styles = StyleSheet.create({
        practiceAreaRow: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: ColorEnums.backgroundGray,
        },
        practiceAreaTextContainer: {
            width: '80%',
            justifyContent: 'center',
        },
        practiceAreaText: {
            fontSize: 14,
            paddingTop: 10,
            paddingBottom: 10,
        },
        practiceAreaPercentage: {
            width: '20%',
            paddingTop: 10,
            marginLeft: 'auto',
        },
        percentageInput: {
            width: '100%',
            fontFamily: 'Lato',
            fontSize: 14,
            color: ColorEnums.cloudBurst,
            textAlign: 'right',
            marginLeft: 'auto',
            textAlignVertical: 'top',
        },
    })

    componentDidMount() {
        practiceAreaAndSettingLogic.GetPracticeAreaProfileLocations().then((locations) => {
            let otherLocation = locations.find((data) => data.label === 'Other')

            practiceAreaAndSettingLogic.GetPracticeAreaProfile().then((responseData) => {
                practiceAreaAndSettingContext.setState(
                    {
                        PracticeProfileData: responseData,
                        PracticeLocationList: locations,
                        OtherLocationValue: otherLocation.value,
                    },
                    () => {
                        practiceAreaAndSettingLogic.PopulateStateVaribles(responseData)
                    },
                )
            })
        })
    }

    //used to make sure that user's dont leave an empty input field, which would cause our total to be NaN
    valueValidation(text) {
        if (!text) {
            return 0
        }

        return text
    }

    Save() {
        return practiceAreaAndSettingLogic.SaveLogic()
    }

    render() {
        practiceAreaAndSettingContext = this
        practiceAreaAndSettingLogic.context = practiceAreaAndSettingContext

        let calculatedTotalColor = ColorEnums.cloudBurst
        let calculatedTotal =
            parseInt(practiceAreaAndSettingContext.state.Ambulatory) +
            parseInt(practiceAreaAndSettingContext.state.CardiacAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.CriticalCareMedicine) +
            parseInt(practiceAreaAndSettingContext.state.GeneralOperativeAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.NeuroAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.ObstetricAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.PainMedicine) +
            parseInt(practiceAreaAndSettingContext.state.PediatricAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.RegionalAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.ThoracicAnesthesia) +
            parseInt(practiceAreaAndSettingContext.state.Trauma)
        let calculatedTotalLabel = 'Calculated Total'

        if (this.state.SubmittedTotalNotValid && calculatedTotal !== 100) {
            calculatedTotalLabel = 'Total must equal 100%'
            calculatedTotalColor = ColorEnums.pomegranate
        } else if (!this.state.SubmittedTotalNotValid && calculatedTotal > 100) {
            calculatedTotalLabel = 'Total exceeds 100%'
            calculatedTotalColor = ColorEnums.pomegranate
        }
        const {fromPracticeProfile} = this.props
        return (
            <View>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, fontWeight: 'bold', paddingTop: 20, paddingBottom: 25},
                    ]}
                >
                    Modify Practice Areas
                </Text>
                <Text style={[styleFunctions.paragraphText(), {fontSize: 14}]}>
                    Below are the practice areas for which MOCA Minute® questions are currently
                    available. Define a percentage for each of your practice areas from the list below
                    (must total 100%). Your selection(s) will guide the MOCA Minute questions you receive
                    and on which you will be assessed.
                    <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                </Text>
                <View style={this.styles.practiceAreaRow}>
                    <Text
                        style={[
                            styleFunctions.columnHeader(),
                            {
                                fontSize: 12,
                                paddingTop: 25,
                                paddingBottom: 10,
                                width: '80%',
                                fontFamily: 'Lato',
                            },
                        ]}
                    >
                        Practice Area
                    </Text>
                    <Text
                        style={[
                            styleFunctions.columnHeader(),
                            {
                                fontSize: 12,
                                paddingTop: 25,
                                paddingBottom: 10,
                                width: '20%',
                                fontFamily: 'Lato',
                                textAlign: 'right',
                            },
                        ]}
                    >
                        Percentage
                    </Text>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Ambulatory / Outpatient
                        </Text>
                    </View>

                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        Ambulatory: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={{
                                    width: '100%',
                                    fontFamily: 'Lato',
                                    fontSize: 14,
                                    color: ColorEnums.cloudBurst,
                                    textAlign: 'right',
                                    marginLeft: 'auto',
                                    textAlignVertical: 'top',
                                }}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.Ambulatory.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Cardiac Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        CardiacAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.CardiacAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Critical Care Medicine
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        CriticalCareMedicine: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.CriticalCareMedicine.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            General Operative Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        GeneralOperativeAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.GeneralOperativeAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Neuro Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        NeuroAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.NeuroAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Obstetric Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        ObstetricAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.ObstetricAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Pain Medicine
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        PainMedicine: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.PainMedicine.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Pediatric Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        PediatricAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.PediatricAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Regional Anesthesia / Acute Pain
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        RegionalAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.RegionalAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Thoracic Anesthesia
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        ThoracicAnesthesia: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.ThoracicAnesthesia.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={this.styles.practiceAreaRow}>
                    <View style={this.styles.practiceAreaTextContainer}>
                        <Text style={[styleFunctions.paragraphText(), this.styles.practiceAreaText]}>
                            Trauma
                        </Text>
                    </View>
                    <View style={this.styles.practiceAreaPercentage}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <TextInputMask
                                underlineColorAndroid="transparent"
                                keyboardType="number-pad"
                                maxLength={3}
                                onChangeText={(text) => {
                                    practiceAreaAndSettingContext.setState({
                                        Trauma: this.valueValidation(text),
                                    })
                                }}
                                selectTextOnFocus={true}
                                options={{mask: '999'}}
                                placeholder="0"
                                returnKeyType="next"
                                style={this.styles.percentageInput}
                                type={'custom'}
                                value={practiceAreaAndSettingContext.state.Trauma.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}} />
                <View style={{flexDirection: 'row'}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                fontWeight: 'bold',
                                paddingTop: 10,
                                width: '80%',
                                color: calculatedTotalColor,
                            },
                        ]}
                    >
                        {calculatedTotalLabel}
                    </Text>
                    <View style={{width: '20%'}}>
                        <View style={{alignSelf: 'center', width: '50%', marginLeft: 'auto'}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 10,
                                        textAlign: 'right',
                                        color: calculatedTotalColor,
                                    },
                                ]}
                            >
                                {calculatedTotal}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{paddingTop: 15}}>
                    <ABADropdown
                        style={{marginBottom: 1}}
                        label="Select Primary Practice Location *"
                        placeholder="Select Primary Practice Location *"
                        options={practiceAreaAndSettingContext.state.PracticeLocationList}
                        width="100%"
                        selectedValue={practiceAreaAndSettingContext.state.PracticeLocation}
                        onChange={(value) => {
                            if (
                                this.state.InputStatus.PracticeLocation ===
                                    GeneralEnums.textInputStatus.error &&
                                value != 'null'
                            ) {
                                practiceAreaAndSettingContext.setState({
                                    PracticeLocation: value,
                                    InputStatus: {
                                        PracticeLocation: GeneralEnums.textInputStatus.default,
                                    },
                                })
                            } else {
                                practiceAreaAndSettingContext.setState({PracticeLocation: value})
                            }
                        }}
                        validationStatus={
                            practiceAreaAndSettingContext.state.InputStatus.PracticeLocation
                        }
                    />
                    {fromPracticeProfile && (
                        <Text style={{color: ColorEnums.pomegranate, paddingTop: 10}}>
                            * Required field
                        </Text>
                    )}
                </View>
                {/* If "Other" is the selected option from our Practice Location dropdown, we want to show a text input */}
                {this.state.PracticeLocation == this.state.OtherLocationValue && (
                    <View style={{paddingTop: 15}}>
                        <ABATextInput
                            onChangeText={(text) => {
                                if (
                                    this.state.InputStatus.OtherLocation ===
                                    GeneralEnums.textInputStatus.error
                                ) {
                                    this.setState({
                                        OtherLocation: text,
                                        InputStatus: {
                                            OtherLocation: GeneralEnums.textInputStatus.default,
                                        },
                                    })
                                } else {
                                    this.setState({OtherLocation: text})
                                }
                            }}
                            placeholder="Primary Practice Location *"
                            textInputStatus={this.state.InputStatus.OtherLocation}
                            maxLength={100}
                            returnKeyType="next"
                            value={this.state.OtherLocation || ''}
                            width={'100%'}
                        />
                    </View>
                )}
                {practiceAreaAndSettingContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Practice Area And Setting</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
