import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const MocaMinutePerformanceChartLogic = {
    context: null,

    GetMocaMinutePerformance: function (yearSelected) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    mocaYear: parseInt(yearSelected),
                },
            }

            MocaMinutePerformanceChartLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('mocaMinute/getMocaMinutePerformanceValues', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Moca Minute MDT Performance Data',
                    )
                    resolve(null)
                })
                .finally(function () {
                    MocaMinutePerformanceChartLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetMocaMinuteYears: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('mocaMinute/getMocaMinuteYears')
                .then(function (response) {
                    var newResponse = response.data.map((d) => {
                        return {label: d.label.toString(), value: d.value}
                    })
                    resolve(newResponse)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Years')
                    resolve(null)
                })
        })
    },

    GetMocaMinuteDashboardInfo: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('mocaMinute/getMocaMinuteDashboardInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Dashboard Data')
                    resolve(null)
                })
        })
    },
}
export default MocaMinutePerformanceChartLogic
