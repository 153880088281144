import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

const EditMedicalLicenseLogic = {
    context: null,

    getStateProvinceList() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/GetStates')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Medical License States and Provinces',
                    )
                    resolve(null)
                })
        })
    },

    getLicenseRestrictionTypesList() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/GetLicenseRestrictionTypes')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Medical License Restriction Types',
                    )
                    resolve(null)
                })
        })
    },

    getLicenseRestrictionReasonTypesList() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/GetLicenseRestrictionReasonTypes')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Medical License Restriction Reason Types',
                    )
                    resolve(null)
                })
        })
    },

    getMedicalLicense() {
        return new Promise((resolve) => {
            EditMedicalLicenseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(
                'medicallicense/getMedicalLicenseById?licenseId=' +
                    EditMedicalLicenseLogic.context.navigationData.medicalLicenseId,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Medical License Information')
                    resolve(null)
                })
                .finally(function () {
                    EditMedicalLicenseLogic.context.setState({CallingApi: false})
                })
        })
    },

    saveMedicalLicense() {
        return new Promise((resolve) => {
            EditMedicalLicenseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('medicallicense/saveMedicalLicense', {
                CheckInactive: EditMedicalLicenseLogic.context.state.CheckInactive,
                ExpirationDate: EditMedicalLicenseLogic.context.state.ExpirationDate,
                InactiveDateAsOf: EditMedicalLicenseLogic.context.state.InactiveDateAsOf,
                IssueDate: EditMedicalLicenseLogic.context.state.IssueDate,
                IssuingStateId: EditMedicalLicenseLogic.context.state.IssuingStateId,
                LicenseId: EditMedicalLicenseLogic.context.state.LicenseId,
                LicenseNumber: EditMedicalLicenseLogic.context.state.LicenseNumber,
                LicenseStatus: EditMedicalLicenseLogic.context.state.LicenseStatus,
                RevokedReason: EditMedicalLicenseLogic.context.state.RevokedReason,
                RevokedReasonNotes: EditMedicalLicenseLogic.context.state.RevokedReasonNotes,
                RestrictionDateFrom: EditMedicalLicenseLogic.context.state.RestrictionDateFrom,
                RestrictionDateTo: EditMedicalLicenseLogic.context.state.RestrictionDateTo,
                RestrictionDetails: EditMedicalLicenseLogic.context.state.RestrictionDetails,
                RestrictionType: EditMedicalLicenseLogic.context.state.RestrictionType,
                RestrictionNotes: EditMedicalLicenseLogic.context.state.RestrictionNotes,
                RevokedEffectiveDate: EditMedicalLicenseLogic.context.state.RevokedEffectiveDate,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Medical License Information')
                    resolve(false)
                })
                .finally(function () {
                    EditMedicalLicenseLogic.context.setState({CallingApi: false})
                    NavigationHelper.navigateBack()
                })
        })
    },

    cancelLicenseUpdate() {
        NavigationHelper.navigateBack()
    },

    populateVariables(responseData) {
        const {InputStatus} = EditMedicalLicenseLogic.context.state
        if (responseData.IssuingStateId) {
            InputStatus.IssuingStateIdStatus = GeneralEnums.textInputStatus.disabled
        }
        if (responseData.IssueDate) {
            InputStatus.IssueDateStatus = GeneralEnums.textInputStatus.disabled
        }

        EditMedicalLicenseLogic.context.setState({
            InputStatus,
            CheckAttest: responseData.CheckAttest || false,
            CheckHasConditions: responseData.CheckHasConditions || false,
            CheckInactive: responseData.CheckInactive || false,
            CheckNoRestrictions: responseData.CheckNoRestrictions || false,
            CheckRevoked: responseData.CheckRevoked || false,
            ExpirationDate: responseData.ExpirationDate || '',
            InactiveDateAsOf: responseData.InactiveDateAsOf || '',
            IssueDate: responseData.IssueDate || '',
            IssuingStateId: responseData.IssuingStateId || 'null',
            LicenseId: responseData.LicenseId || '',
            LicenseNumber: responseData.LicenseNumber || '',
            LicenseStatus: responseData.LicenseStatus || '',
            RestrictionType: responseData.RestrictionType || 'null',
            RestrictionNotes: responseData.RestrictionNotes || '',
            RestrictionDateFrom: responseData.RestrictionDateFrom || '',
            RestrictionDateTo: responseData.RestrictionDateTo || '',
            RevokedReason: responseData.RevokedReason || 'null',
            RevokedReasonNotes: responseData.RevokedReasonNotes || '',
            RevokedEffectiveDate: responseData.RevokedEffectiveDate || '',
            Verified: responseData.Verified || false,
        })
    },

    validateFormData() {
        const {
            InputStatus,
            LicenseNumber,
            StateProvinceList,
            ExpirationDate,
            IssueDate,
            IssuingStateId,
            CheckInactive,
            InactiveDateAsOf,
            CheckHasConditions,
            RestrictionDateFrom,
            RestrictionDateTo,
            RestrictionType,
            RestrictionNotes,
            CheckRevoked,
            RevokedEffectiveDate,
            RevokedReason,
            RevokedReasonNotes,
            CheckAttest,
            CheckNoRestrictions,
        } = EditMedicalLicenseLogic.context.state

        const error = GeneralEnums.textInputStatus.error
        let errorFound = false

        // We need to change back any text inputs that had an error to default.  This is because  on re-submittal,
        // some required fields could have been filled out but others were missed.  We need those former errors
        // set back to default.  This is essentially giving us a clean pallete to work with each submittal.
        for (let key in InputStatus) {
            if (InputStatus[key] !== GeneralEnums.textInputStatus.disabled) {
                InputStatus[key] = GeneralEnums.textInputStatus.default
            }
        }

        if (!LicenseNumber) {
            InputStatus.LicenseNumberStatus = error
            errorFound = true
        }

        if (
            IssueDate &&
            !ValidationHelper.isDateFormatValid(IssueDate, GeneralEnums.DateCheckType.TodayAndPastOnly)
        ) {
            InputStatus.IssueDateStatus = error
            errorFound = true
        }

        if (!ValidationHelper.isDateFormatValid(ExpirationDate)) {
            InputStatus.ExpirationDateStatus = error
            errorFound = true
        }

        if (StateProvinceList.length > 0 && (!IssuingStateId || IssuingStateId === 'null')) {
            InputStatus.IssuingStateIdStatus = error
            errorFound = true
        }

        if (
            CheckInactive &&
            (!InactiveDateAsOf || !ValidationHelper.isDateFormatValid(InactiveDateAsOf))
        ) {
            InputStatus.InactiveDateAsOfStatus = error
            errorFound = true
        }

        if (CheckHasConditions && !ValidationHelper.isDateFormatValid(RestrictionDateFrom)) {
            InputStatus.RestrictionDateFromStatus = error
            errorFound = true
        }

        if (CheckHasConditions && (!RestrictionType || RestrictionType === 'null')) {
            InputStatus.RestrictionTypeStatus = error
            errorFound = true
        }

        if (CheckHasConditions && !RestrictionNotes) {
            InputStatus.RestrictionNotesStatus = error
            errorFound = true
        }

        if (CheckRevoked && !ValidationHelper.isDateFormatValid(RevokedEffectiveDate)) {
            InputStatus.RevokedEffectiveDateStatus = error
            errorFound = true
        }

        if (CheckRevoked && (!RevokedReason || RevokedReason === 'null')) {
            InputStatus.RevokedReasonStatus = error
            errorFound = true
        }

        if (CheckRevoked && !RevokedReasonNotes) {
            InputStatus.RevokedReasonNotesStatus = error
            errorFound = true
        }

        if (!(CheckNoRestrictions || CheckHasConditions || CheckRevoked)) {
            InputStatus.LicenseStatusStatus = error
            errorFound = true
        }

        if (!CheckAttest) {
            InputStatus.CheckAttestStatus = error
            errorFound = true
        }

        if (RestrictionDateTo && !ValidationHelper.isDateFormatValid(RestrictionDateTo)) {
            InputStatus.RestrictionDateToStatus = error
            errorFound = true
        }

        // If our inputStatuses contain an error, then we will return false which will alert the user of form errors.
        if (errorFound) {
            EditMedicalLicenseLogic.context.setState({InputStatus})
            EditMedicalLicenseLogic.context.parentScrollRef.scrollTo({
                x: 0,
                y: 0,
                animated: false,
            })
            return true
        }
        return false
    },

    saveLogic() {
        return new Promise((resolve) => {
            const errorMsg = EditMedicalLicenseLogic.validateFormData()
            if (errorMsg) {
                resolve(false)
            } else {
                EditMedicalLicenseLogic.saveMedicalLicense().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default EditMedicalLicenseLogic
