import React, {Component} from 'react'
import {Text, View, Platform} from 'react-native'
import styleFunctions from '../../styles/styleFunctions'
import {appConfiguration} from '../../configuration.json'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

const {Environment, BuildNumber, API_Version, CodePushKey} = appConfiguration

export default class LoginFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showFooter: true,
        }
    }

    render() {
        return (
            <View ref="myRef" style={[styleFunctions.loginBackground()]}>
                <Text style={styleFunctions.loginFooter()}>
                    The American Board of Anesthesiology is a member of the American Board of Medical
                    Specialties.
                </Text>
                <Text style={styleFunctions.loginFooter()}>
                    Copyright 2016© The American Board of Anesthesiology - All Rights Reserved.
                </Text>
                {Environment !== 'PROD' && (
                    <>
                        <Text style={styleFunctions.loginFooter()}>
                            Current Environment: {Environment}
                        </Text>
                        <Text style={styleFunctions.loginFooter()}>CodePush Key: {CodePushKey}</Text>
                    </>
                )}
                {Environment === 'DEV' && (
                    <Text
                        style={styleFunctions.loginFooter()}
                        onPress={() => {
                            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/styleGuide')
                        }}
                    >
                        Style Guide
                    </Text>
                )}

                <Text style={styleFunctions.loginFooter()}>
                    Build ID: {BuildNumber}-{API_Version}
                </Text>
                {Environment === 'DEV' && (
                    <View>
                        <Text
                            style={styleFunctions.loginFooter()}
                            onPress={() => {
                                throw new Error('Sentry Works')
                            }}
                        >
                            Test Sentry
                        </Text>
                    </View>
                )}
            </View>
        )
    }
}
