import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const StatusLetterLogic = {
    context: null,

    getStatusLetter: function () {
        return new Promise((resolve) => {
            StatusLetterLogic.context.setState({CallingApi: true})
            let apiConfig = {
                headers: {
                    Accept: 'application/json',
                    AuthenticationToken: StatusLetterLogic.context.props.match.params.authToken,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }

            ABAPortalAPI.get('statusLetter/getStatusLetter', apiConfig)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        window.alert(
                            'This URL link has expired.  Please try accessing it again from your dashboard.',
                        )
                        window.close()
                    }
                    resolve(null)
                })
                .finally(function () {
                    StatusLetterLogic.context.setState({CallingApi: false})
                })
        })
    },

    getDoctorInfo: function () {
        return new Promise((resolve) => {
            let apiConfig = {
                headers: {
                    Accept: 'application/json',
                    AuthenticationToken: StatusLetterLogic.context.props.match.params.authToken,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
            ABAPortalAPI.get('doctorInformation/getDoctorHeaderInfo', apiConfig)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Doctor Information')
                    resolve(null)
                })
        })
    },
}

export default StatusLetterLogic
