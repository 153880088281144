import {Platform} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ViewPostedDocumentsLogic = {
    openDocument: function (document, AuthenticationToken) {
        const headers = {
            Accept: '*/*',
        }
        let invalidFileCharacters = /[^a-zA-Z0-9-.]/g
        const path = `postedDocument/postedDocument/${document.DocumentID}`
        const filename = `${document.DisplayName}.${document.FileExtension}`.replace(
            invalidFileCharacters,
            '_',
        )
        if (Platform.OS === 'web') {
            HelperMethods.downloadPDFWeb(filename, headers, path)
        } else {
            HelperMethods.downloadPDFMobile(filename, headers, path)
        }
    },

    getPostedDocuments: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('postedDocument/postedDocument')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Documents')
                    resolve(null)
                })
        })
    },
}

export default ViewPostedDocumentsLogic
