import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Loading from './loading'
import ABAPortalAPI, {setHeaderABAID, setHeaderAuthToken} from '../../helpers/AxiosInstance'

export const PearsonVueRedirect = (props) => {
    const [apiData, setApiData] = useState({})
    const [isRedirected, setIsRedirected] = useState(false)
    const {token: authToken, eventId, platform, abaId} = props.match.params

    useEffect(() => {
        const getPearsonVueData = async () => {
            let getParams = {
                params: {
                    eventId,
                    platform,
                },
            }
            setHeaderABAID(abaId)
            setHeaderAuthToken(authToken)
            try {
                const pearsonVueData = await ABAPortalAPI.get(
                    'registration/getRegistrationDetails',
                    getParams,
                )
                setApiData(pearsonVueData.data)
            } catch (error) {
                props.history.push(`/RedirectErrors/${authToken}/${eventId}?s=BAD_AUTH`)
            }
        }
        if ((authToken, eventId)) {
            getPearsonVueData()
        }
    }, [authToken, eventId, platform])

    useEffect(() => {
        if (apiData.formData && !isRedirected) {
            pvForm = document.getElementById('pvForm')
            setIsRedirected(true)
            pvForm.submit()
        }
    }, [apiData])

    const renderForm = () => {
        if (!apiData?.formData) {
            return null
        }
        return (
            <>
                <noscript>
                    <input type="submit" name="submit" value="Continue" />
                </noscript>
                <Loading />
                {apiData?.formData.map((field) => (
                    <input type="hidden" style={styles.formField} name={field.Key} value={field.Value} />
                ))}
            </>
        )
    }

    return (
        <>
            <form id="pvForm" action={apiData.PVSSOUrl} method="post">
                {renderForm()}
            </form>
        </>
    )
}
const styles = {
    formField: {
        display: 'none',
    },
}

export default PearsonVueRedirect
