import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import trainingPageLogic from '../logic/trainingPageLogic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABAAffirmationRadio from '../../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABATextInput from '../../../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'
import HelperMethods from '../../../../helpers/HelperMethods'
import NCCPathwayPage from '../presentation/nccPathwayPage'

export default class TrainingPage extends Component {
    state = {
        CallingApi: false,
        CAOption: null,
        re_ProgramType: null,
        cert_enrollment_date: '',
        cert_graduation_date: '',
        cert_program: '',
        cert_program_city_and_state: '',
        cert_hospiceandpalliativemedicinepath: null,
        cert_sleepmedicinepath: null,
        cert_clinicalactivityid: null,
        CAResponseData: null,
        graduation_error_text: '',
        InputStatus: {
            cert_enrollment_date: GeneralEnums.textInputStatus.default,
            cert_graduation_date: GeneralEnums.textInputStatus.default,
            cert_program: GeneralEnums.textInputStatus.default,
            cert_program_city_and_state: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        trainingPageLogic.GetClinicalActivityData().then((CAData) => {
            trainingPageLogic.PopulateVariables(CAData)
        })

        const {ProgramType} = this.props
        const {Sleep_Medicine, Hospice_and_Palliative_Medicine} = GeneralEnums.program_type

        if (ProgramType === Sleep_Medicine) {
            trainingPageLogic.context.setState({cert_sleepmedicinepath: 1})
        }

        if (ProgramType === Hospice_and_Palliative_Medicine) {
            trainingPageLogic.context.setState({cert_hospiceandpalliativemedicinepath: 1})
        }

        if (clinicalActivityContext.state.showNCCPathway) {
            clinicalActivityContext.setState({PreviousComponentStep: NCCPathwayPage})
        }
    }

    Save() {
        return trainingPageLogic.SaveLogic()
    }

    Previous() {
        return trainingPageLogic.Previous()
    }

    determineProgramlabel(programType) {
        const {Sleep_Medicine, Hospice_and_Palliative_Medicine, Neurocritical_Care_Exam} =
            GeneralEnums.program_type
        let programLabel

        switch (programType) {
            case Sleep_Medicine:
                programLabel = 'Sleep Medicine'
                break
            case Hospice_and_Palliative_Medicine:
                programLabel = 'Hospice and Palliative Medicine'
                break
            case Neurocritical_Care_Exam:
                programLabel = 'Neurocritical Care'
                break
            default:
                programLabel = ''
        }

        return programLabel
    }

    render() {
        trainingPageLogic.context = this
        const {InputStatus, graduation_error_text} = this.state

        const programLabel = this.determineProgramlabel(this.props.ProgramType)

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                        ]}
                    >
                        Practice Requirements
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, width: '100%'},
                        ]}
                    >
                        Have you completed 12 months of satisfactory training OR are you currently
                        enrolled in a {programLabel} program and will graduate by December 31?
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 20,
                    }}
                >
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            rightOptionContent="No"
                            leftOptionOnPress={() => {}}
                            rightOptionOnPress={() => {}}
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={true}
                            validationStatus={GeneralEnums.textInputStatus.disabled}
                        />
                    </View>
                </View>

                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                        ]}
                    >
                        Please enter your Training Information
                    </Text>
                    <ABATextInput
                        maxLength={160}
                        onChangeText={(cert_program) => this.setState({cert_program})}
                        placeholder="Program Name *"
                        returnKeyType="next"
                        textInputStatus={InputStatus.cert_program}
                        value={this.state.cert_program}
                        width="100%"
                    />

                    <View style={{marginBottom: 25}}>
                        <ABATextInputMultiline
                            label="Program Address *"
                            labelStyle={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, fontWeight: 'bold', paddingTop: 15},
                            ]}
                            maxLength={200}
                            numberOfLines={7}
                            onChangeText={(cert_program_city_and_state) =>
                                this.setState({cert_program_city_and_state})
                            }
                            style={{marginBottom: 25}}
                            placeholder="Program Address"
                            textInputStatus={InputStatus.cert_program_city_and_state}
                            value={this.state.cert_program_city_and_state}
                        />
                    </View>

                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingBottom: 10,
                        }}
                    >
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(cert_enrollment_date) =>
                                    this.setState({cert_enrollment_date})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="Enrollment Date *"
                                returnKeyType="next"
                                textInputStatus={InputStatus.cert_enrollment_date}
                                value={this.state.cert_enrollment_date}
                                width="100%"
                            />
                        </View>
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        width: '100%',
                                        paddingLeft: this.state.ComponentWidth > 600 ? 15 : 0,
                                    },
                                ]}
                            >
                                ex: MM/DD/YYYY
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 10,
                        }}
                    >
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(cert_graduation_date) =>
                                    this.setState({cert_graduation_date})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="Graduation Date *"
                                returnKeyType="next"
                                textInputStatus={InputStatus.cert_graduation_date}
                                value={this.state.cert_graduation_date}
                                width="100%"
                            />
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        paddingTop:
                                            InputStatus.cert_graduation_date ===
                                            GeneralEnums.textInputStatus.error
                                                ? 20
                                                : 0,
                                        marginTop: -20,
                                        fontStyle: 'italic',
                                        width: '90%',
                                    },
                                ]}
                            >
                                Actual or Expected
                            </Text>
                        </View>
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        width: '100%',
                                        paddingLeft: this.state.ComponentWidth > 600 ? 15 : 0,
                                    },
                                ]}
                            >
                                ex: MM/DD/YYYY
                            </Text>
                        </View>
                    </View>
                    {InputStatus.cert_graduation_date === GeneralEnums.textInputStatus.error && (
                        <Text style={{color: ColorEnums.pomegranate, marginTop: '2%'}}>
                            {graduation_error_text}
                        </Text>
                    )}
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Training Page</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
