import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../helpers/generalEnums'

const CMECreditReleaseLogic = {
    context: null,

    GetText(Data) {
        return new Promise((resolve) => {
            CMECreditReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: Data.Crt_ReleaseType,
                ProgramType: CMECreditReleaseLogic.context.props.ProgramType,
                re_AdditionalSpecification: Data.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing CME Credit Release')
                })
                .finally(function () {
                    CMECreditReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetReleaseData() {
        return new Promise((resolve) => {
            CMECreditReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: CMECreditReleaseLogic.context.props.Order,
                CR2ID: CMECreditReleaseLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing CME Credit Release')
                })
                .finally(function () {
                    CMECreditReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease(data) {
        var releaseOptionYes
        var releaseOptionNo
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOptionYes = true
            releaseOptionNo = false
        } else if (data.Crt_RecordStatus == 2) {
            releaseOptionYes = false
            releaseOptionNo = true
        }
        CMECreditReleaseLogic.context.setState({
            ReleaseOptionYes: releaseOptionYes,
            ReleaseOptionNo: releaseOptionNo,
            NoteText: data.Crt_Note || '',
            ReleaseData: data,
        })
    },

    CreateRelease() {
        return new Promise((resolve) => {
            const {CR2ID, Order} = CMECreditReleaseLogic.context.props
            const {ReleaseOptionYes, NoteText} = CMECreditReleaseLogic.context.state
            const {
                ContactId,
                Crt_ReleaseType,
                Crt_ProgramType,
                Crt_ReleaseId,
                LastName,
                FirstName,
                rs_statuscode,
            } = CMECreditReleaseLogic.context.state.ReleaseData
            CMECreditReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID,
                Order,
                Crt_RecordStatus: ReleaseOptionYes ? 1 : 2,
                Crt_Note: NoteText,
                ContactId,
                Crt_ReleaseType,
                Crt_ProgramType,
                Crt_ReleaseId,
                LastName,
                FirstName,
                rs_statuscode,
            })
                .then(function () {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    CMECreditReleaseLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    ReleaseOptionYesSelected() {
        CMECreditReleaseLogic.context.setState({
            ReleaseOptionYes: true,
            ReleaseOptionNo: false,
            cmeCreditReleaseConfirmationError: GeneralEnums.textInputStatus.default,
        })
    },

    ReleaseOptionNoSelected() {
        CMECreditReleaseLogic.context.setState({
            ReleaseOptionNo: true,
            ReleaseOptionYes: false,
            cmeCreditReleaseConfirmationError: GeneralEnums.textInputStatus.default,
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!CMECreditReleaseLogic.ValidatePage()) {
                resolve(false)
                return
            }

            CMECreditReleaseLogic.CreateRelease().then((response) => {
                if (response) {
                    resolve(true)
                }
                resolve(false)
            })
        })
    },

    ValidatePage() {
        const {ReleaseOptionYes, ReleaseOptionNo} = CMECreditReleaseLogic.context.state
        if (ReleaseOptionYes === undefined && ReleaseOptionNo === undefined) {
            CMECreditReleaseLogic.context.setState({
                cmeCreditReleaseConfirmationError: GeneralEnums.textInputStatus.error,
            })
            return false
        }
        return true
    },
}

export default CMECreditReleaseLogic
