import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import NonStandardExamStep1 from '../presentation/nonStandardExamStep1'
import NonStandardExamStep3 from '../presentation/nonStandardExamStep3'

const NonStandardExamStep2Logic = {
    context: null,

    GetNSEData() {
        return new Promise((resolve) => {
            const {NonStandardExamID} = nonStandardExamContext.state

            let getParams = {
                params: {
                    NonStandardExamID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`nserequestsinfo/getNSEData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing NSE Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetExamTypes() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getExamTypes')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Exam Types')
                    resolve(null)
                })
        })
    },

    CreateNSE: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {ProductTypeCode, ProductId, ContactId, rs_statuscode, statuscode, exam_nserequestId} =
                this.context.state.NSEReleaseData
            ABAPortalAPI.post('nserequestsinfo/CreateNSEBegin', {
                CR2ID: this.context.props.CR2ID,
                Order: this.context.props.Order,
                exam_response: 1,
                ProductTypeCode,
                ProductId,
                ContactId,
                rs_statuscode,
                statuscode,
                exam_nserequestId,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving NSE Information')
                    resolve(false)
                })
        })
    },

    SetExamInformation(productID, examList) {
        const exam = examList.find((exam) => exam.value === productID)
        if (exam) {
            this.context.setState({
                productID,
                ExamLabel: exam.label,
            })
        }
    },

    UpdateNSEStep2() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {productID, exam_examYearRequested} = this.context.state
            let exam_requestType = null

            if (
                productID === GeneralEnums.product.SOE ||
                productID === GeneralEnums.product.OSCE ||
                productID === GeneralEnums.product.Part2
            ) {
                exam_requestType = GeneralEnums.examRequestType.OralExamination
            } else {
                exam_requestType = GeneralEnums.examRequestType.ComputerBasedExam
            }

            if (this.context.props.IsFromDashboard) {
                this.checkForExistingNSERequest(productID, exam_examYearRequested).then((response) => {
                    if (!response) {
                        this.CreateNSE().then((nseResponse) => {
                            if (!nseResponse) {
                                resolve(false)
                                return
                            }

                            nonStandardExamContext.setState({
                                NonStandardExamID: nseResponse,
                            })

                            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep2`, {
                                NSEID: nseResponse,
                                productID,
                                exam_examYearRequested,
                                rs_statuscode: nonStandardExamContext.state.RuleStatus,
                                exam_requestType,
                            })
                                .then(function (updateNSEResponse) {
                                    resolve(updateNSEResponse.data)
                                })
                                .catch(function (error) {
                                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                                    resolve(false)
                                })
                                .finally(() => {
                                    this.context.setState({CallingApi: false})
                                })
                        })
                    } else {
                        if (response.statuscode === GeneralEnums.NSEStatusCodes.Draft) {
                            nonStandardExamContext.setState({
                                NonStandardExamID: response.NSEID,
                            })

                            ABAPortalAPI.post(`nserequestsinfo/updateNSEStep2`, {
                                NSEID: response.NSEID,
                                productID,
                                exam_examYearRequested,
                                rs_statuscode: nonStandardExamContext.state.RuleStatus,
                                exam_requestType,
                            })
                                .then(function (updateNSEResponse) {
                                    resolve(updateNSEResponse.data)
                                })
                                .catch(function (error) {
                                    HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                                    resolve(false)
                                })
                                .finally(() => {
                                    this.context.setState({CallingApi: false})
                                })
                        } else {
                            this.context.setState({CallingApi: false})
                            nonStandardExamContext.setState({
                                showExistingNSERequestModal: true,
                            })
                        }
                    }
                })
            } else {
                ABAPortalAPI.post(`nserequestsinfo/updateNSEStep2`, {
                    NSEID: nonStandardExamContext.state.NonStandardExamID,
                    productID,
                    exam_examYearRequested,
                    rs_statuscode: nonStandardExamContext.state.RuleStatus,
                    exam_requestType,
                })
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        HelperMethods.apiErrorHandler(error, 'Error Updating NSE Record')
                        resolve(false)
                    })
                    .finally(() => {
                        this.context.setState({CallingApi: false})
                    })
            }
        })
    },

    PopulateStateVariables(examTypeList, NSEData, NursingMotherData) {
        this.context.setState(
            {
                examTypeList: examTypeList || [],
            },
            () => {
                if (NSEData) {
                    this.context.setState({
                        productID: NSEData.productID || '',
                        NSEReleaseData: NSEData,
                        exam_examYearRequested: NSEData.exam_examYearRequested || '',
                        nursingMotherData: NursingMotherData,
                    })
                }
            },
        )
    },

    validatePage() {
        const {InputStatus, exam_examYearRequested, productID} = this.context.state
        const {success, error} = GeneralEnums.textInputStatus
        const currentYear = new Date().getFullYear()
        InputStatus.exam_examYearRequested =
            exam_examYearRequested.toString().length === 4 &&
            Number(exam_examYearRequested) >= Number(currentYear)
                ? success
                : error
        InputStatus.productID = productID === '' ? error : success

        const validated = !Object.keys(InputStatus).some((input) => InputStatus[input] === error)
        this.context.setState({InputStatus})
        return validated
    },

    checkForExistingNSERequest(ExamId, ExamYear) {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    ExamId,
                    ExamYear,
                },
            }

            ABAPortalAPI.get('nserequestsinfo/checkfornserequest', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Request Information')
                    resolve(null)
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!NonStandardExamStep2Logic.validatePage()) {
                resolve(false)
                return
            }

            this.validateNursingMotherRequest().then((results) => {
                const {ShowMultipleEventMessage, ShowNMMessage, ShowNSEMessage} = results
                if (!ShowMultipleEventMessage && !ShowNMMessage && !ShowNSEMessage) {
                    this.UpdateNSEStep2().then((response) => {
                        if (response) {
                            const {ExamLabel} = NonStandardExamStep2Logic.context.state
                            nonStandardExamContext.setState({
                                CurrentComponentStep: NonStandardExamStep3,
                                ExamLabel,
                            })
                            resolve(false)
                        } else {
                            resolve(false)
                        }
                    })
                }
            })
        })
    },

    SaveLogicOnModalContinue() {
        return new Promise((resolve) => {
            this.UpdateNSEStep2().then((response) => {
                if (response) {
                    const {ExamLabel} = NonStandardExamStep2Logic.context.state
                    nonStandardExamContext.setState({
                        CurrentComponentStep: NonStandardExamStep3,
                        ExamLabel,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            nonStandardExamContext.setState({
                CurrentComponentStep: NonStandardExamStep1,
            })
            submitNseRequestContext.setState({
                SubmittedSteps: 0,
            })
            resolve(false)
        })
    },

    GetNSENursingMotherInfo(productId, isNMRequest) {
        return new Promise((resolve) => {
            ABAPortalAPI.get(`nserequestsinfo/getNSENursingMotherInfo/${productId}/${isNMRequest}`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Nursing Mother Info')
                    resolve(null)
                })
        })
    },

    validateNursingMotherRequest() {
        const {productID} = this.context.state
        const {NursingMotherResponse} = nonStandardExamContext.state
        const isNMRequest = NursingMotherResponse == 1 ? true : false

        return this.GetNSENursingMotherInfo(productID, isNMRequest).then((data) => {
            if (data != null) {
                const {ShowMultipleEventMessage, ShowNMMessage, ShowNSEMessage} = data
                const showNursingMotherModal =
                    ShowMultipleEventMessage || ShowNMMessage || ShowNSEMessage ? true : false

                this.context.setState({
                    showNursingMotherModal: showNursingMotherModal,
                    nursingMotherResults: data,
                })

                return data
            }
        })
    },
}

export default NonStandardExamStep2Logic
