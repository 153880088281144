import React, {Component} from 'react'
import {Text, View} from 'react-native'

import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import ABAContainer from '../ABACustom/presentation/ABAContainer'
import ABASegmentedProgressBar from '../ABACustom/presentation/ABASegmentedProgressBar'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import QIInformation from './qiInformation'
import QILogic from '../logic/qi_impLogic'
import StyleFunctions from '../../styles/styleFunctions'
import HelperMethods from '../../helpers/HelperMethods'
import MyProgressLinks from '../presentation/myProgressLinks'

import moment from 'moment'

export default class QI_IMP extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            qi1: {},
            qi2: {},
            qi3: {},
        }
    }

    componentDidMount() {
        QILogic.GetDiplomateQIInformation().then((apiResponse) => {
            QILogic.GetQIEntries(apiResponse)
        })
    }

    changeLayoutBasedOnWidth(componentWidth) {
        if (componentWidth <= 1100) {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: null,
                    borderTopWidth: 2,
                    borderLeftColor: null,
                    borderTopColor: ColorEnums.aquaHaze,
                },
                columnWidthDynamic: '100%',
                flexDirectionDynamic: 'column',
            })
        } else {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: 2,
                    borderTopWidth: null,
                    borderLeftColor: ColorEnums.aquaHaze,
                    borderTopColor: null,
                },
                columnWidthDynamic: '50%',
                flexDirectionDynamic: 'row',
            })
        }
    }

    changeLayoutPR(componentWidth) {
        if (componentWidth <= 550) {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: null,
                    borderTopWidth: null,
                    borderLeftColor: null,
                    borderTopColor: null,
                },
                columnWidthDynamic: '100%',
                flexDirectionDynamic: 'column',
            })
        } else {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: null,
                    borderTopWidth: null,
                    borderLeftColor: null,
                    borderTopColor: null,
                },
                columnWidthDynamic: '50%',
                flexDirectionDynamic: 'row',
            })
        }
    }

    setAttestButtonStatus(qiName) {
        const currentDate = moment(Date.now()).format('MM/DD/YYYY')
        const currentYear = moment(Date.now()).year()
        let qi1DateAvailable = null
        let qi1DateCompletedYear = null
        const {qi1, qi2} = this.state
        const {Satisfactory, Not_Submitted} = GeneralEnums.ruleStatus_statusCodes

        if (qi1 && qi1.DateCompleted) {
            qi1DateCompletedYear = qi1.DateCompleted !== null ? moment(qi1.DateCompleted).year() : null
        }

        if (qiName === 'qi1') {
            if (qi1) {
                if (qi1.DateAvailable) {
                    qi1DateAvailable = qi1.DateAvailable
                        ? moment(qi1.DateAvailable).format('MM/DD/YYYY')
                        : null
                }
                if (
                    qi1.RuleStatusStatusCode === Not_Submitted &&
                    qi1DateAvailable &&
                    moment(currentDate).isSameOrAfter(qi1DateAvailable)
                ) {
                    return true
                }
            }
        } else if (qiName === 'qi2') {
            if (qi2) {
                const qi2DateAvailable = moment(qi2.DateAvailable).format('MM/DD/YYYY')
                if (
                    qi2.RuleStatusStatusCode === Not_Submitted &&
                    (qi1DateCompletedYear || !qi1 || qi1.RuleStatusStatusCode === Satisfactory) &&
                    qi2.DateAvailable &&
                    (moment(currentDate).isSameOrAfter(qi1DateCompletedYear) ||
                        (!qi1 && moment(currentDate).isSameOrAfter(qi2DateAvailable))) // this line allows button to show for 5-year certs
                ) {
                    return true
                }
            }
        } else {
            return false
        }
    }

    render() {
        QILogic.context = this
        const {qi1, qi2, qi3} = this.state
        const fiveYearCenter = qi1 == null ? {alignSelf: 'center'} : {}
        const approvedActivityList = {
            Text: 'Approved Activity List',
            Link: 'https://www.theaba.org/wp-content/uploads/2023/01/MOCA_QI_Activities.pdf',
            Enabled: true,
        }

        let qi3HeaderLabel =
            qi1 == null && qi2 == null
                ? 'QI-1'
                : (qi1 != null && qi2 == null) || (qi1 == null && qi2 != null)
                ? 'QI-2'
                : 'QI-3'

        const {ShowActivitiesLink} = this.props
        const qiBorder = {
            borderLeftWidth: ShowActivitiesLink === true ? 2 : null,
            borderLeftColor: ShowActivitiesLink === true ? ColorEnums.aquaHaze : null,
        }

        let showQI1AttestButton = false
        let showQI2AttestButton = false

        showQI1AttestButton = this.setAttestButtonStatus('qi1')
        showQI2AttestButton = this.setAttestButtonStatus('qi2')

        let attestButtonHeight = showQI1AttestButton || showQI2AttestButton ? 40 : 0

        return (
            <View
                style={{flexDirection: this.state.flexDirectionDynamic}}
                onLayout={(event) => {
                    this.props.dashboard
                        ? this.changeLayoutBasedOnWidth(event.nativeEvent.layout.width)
                        : this.changeLayoutPR(event.nativeEvent.layout.width)
                }}
            >
                {this.props.dashboard && (
                    <View style={{width: this.state.columnWidthDynamic}}>
                        <QIInformation />
                    </View>
                )}

                <View
                    style={[
                        {width: this.props.dashboard ? this.state.columnWidthDynamic : '100%'},
                        this.state.borderDynamic,
                    ]}
                >
                    <ABAContainer
                        activityText={'Loading QI Data'}
                        showActivityIndicator={this.state.CallingApi}
                        showContainerTitleBar={false}
                        useMaxWidth={!this.props.dashboard}
                        overrideWidth={!this.props.dashboard}
                    >
                        <View>
                            {qi1 == null && qi2 == null && qi3 == null && (
                                <View>
                                    <Text style={StyleFunctions.containerHeader('center')}>
                                        You are exempt from fulfilling all Quality Improvement
                                        requirements.
                                    </Text>
                                </View>
                            )}
                            <View
                                style={{
                                    flexDirection: qi1 == null ? 'column' : this.state.flexDirectionDynamic,
                                    paddingBottom: 20,                                   
                                }}
                            >
                                 {qi1 != null && (
                                <View
                                    style={{                                        
                                        width: this.state.columnWidthDynamic,
                                        justifyContent: 'center',
                                        alignItems: 'center',                                  
                                        paddingBottom:
                                            this.state.flexDirectionDynamic === 'row' ? 0 : 10,
                                    }}
                                >                                   
                                        <View style={{width: '90%'}}>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',                                               
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerTextBold(),
                                                        {fontSize: 14},
                                                    ]}
                                                >
                                                    QI-1{' '}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingBottom: 5,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerText(),
                                                        {fontSize: 14},
                                                    ]}
                                                >
                                                    {HelperMethods.formatDate(qi1.DateAvailable)} -{' '}
                                                    {HelperMethods.formatDate(qi1.DateDue)}
                                                </Text>
                                            </View>
                                            <View style={{paddingBottom: 5}}>
                                                <ABASegmentedProgressBar
                                                    numberOfSegments={
                                                        qi1.TotalCreditsRequired
                                                            ? qi1.TotalCreditsRequired
                                                            : 0
                                                    }
                                                    numberOfSegmentsFilled={
                                                        qi1.TotalCredits ? qi1.TotalCredits : 0
                                                    }
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerTextBold(),
                                                        {fontSize: 12},
                                                    ]}
                                                >
                                                    {qi1.TotalCredits} points{' '}
                                                </Text>
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerText(),
                                                        {fontSize: 12},
                                                    ]}
                                                >
                                                    completed out of {qi1.TotalCreditsRequired}
                                                </Text>
                                            </View>
                                            <View style={{marginTop: 15, height: attestButtonHeight}}>
                                                {showQI1AttestButton && (
                                                    <ABATouchableHighlight
                                                        icon={'angle-right'}
                                                        onPressEvent={() => {
                                                            NavigationHelper.GoToPage(
                                                                GeneralEnums.navigationType.push,
                                                                '/qiAttestation',
                                                            )
                                                        }}
                                                        text={'Attest'}
                                                    />
                                                )}
                                            </View>
                                        </View>                                    
                                </View>
                                )}

                                <View
                                    style={[{
                                        width: this.state.columnWidthDynamic,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }, fiveYearCenter]}
                                >
                                    {qi2 != null && (
                                        <View style={{width: '90%'}}>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',                                                    
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerTextBold(),
                                                        {fontSize: 14},
                                                    ]}
                                                >
                                                    {qi1 == null ? 'QI-1 ' : 'QI-2 '}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingBottom: 5,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerText(),
                                                        {fontSize: 14},
                                                    ]}
                                                >
                                                    {HelperMethods.formatDate(qi2.DateAvailable)} -{' '}
                                                    {HelperMethods.formatDate(qi2.DateDue)}
                                                </Text>
                                            </View>
                                            <View style={{paddingBottom: 5}}>
                                                <ABASegmentedProgressBar
                                                    numberOfSegments={
                                                        qi2.TotalCreditsRequired
                                                            ? qi2.TotalCreditsRequired
                                                            : 0
                                                    }
                                                    numberOfSegmentsFilled={
                                                        qi2.TotalCredits ? qi2.TotalCredits : 0
                                                    }
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerTextBold(),
                                                        {fontSize: 12},
                                                    ]}
                                                >
                                                    {qi2.TotalCredits} points{' '}
                                                </Text>
                                                <Text
                                                    style={[
                                                        StyleFunctions.containerText(),
                                                        {fontSize: 12},
                                                    ]}
                                                >
                                                    completed out of {qi2.TotalCreditsRequired}
                                                </Text>
                                            </View>
                                        </View>
                                    )}
                                    <View style={{marginTop: 15, height: attestButtonHeight}}>
                                        {showQI2AttestButton && (
                                            <ABATouchableHighlight
                                                icon={'angle-right'}
                                                onPressEvent={() => {
                                                    NavigationHelper.GoToPage(
                                                        GeneralEnums.navigationType.push,
                                                        '/qiAttestation',
                                                    )
                                                }}
                                                text={'Attest'}
                                            />
                                        )}
                                    </View>
                                </View>
                            </View>

                            {qi3 != null && (
                                <View
                                    style={{
                                        alignItems: 'center',
                                        borderBottomColor: ColorEnums.aquaHaze,
                                        borderBottomWidth: 2,
                                        borderTopColor: ColorEnums.aquaHaze,
                                        borderTopWidth: 2,
                                        justifyContent: 'center',
                                        paddingBottom: 10,
                                        paddingTop: 10,
                                    }}
                                >
                                    <View style={{flexDirection: 'row'}}>
                                        <Text
                                            style={[
                                                StyleFunctions.containerTextBold(),
                                                {fontSize: 14, paddingRight: 5},
                                            ]}
                                        >
                                            {qi3HeaderLabel}
                                        </Text>
                                        <Text
                                            style={{
                                                color: ColorEnums.citrus,
                                                fontSize: 14,
                                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            }}
                                        >
                                            {' '}
                                            {IconEnums['clock']}
                                        </Text>
                                    </View>
                                    <Text style={[StyleFunctions.containerText(), {fontSize: 14}]}>
                                        {qi3.DisplayLabel}
                                    </Text>
                                </View>
                            )}
                            <View style={{paddingTop: 20}}>
                                {(qi1 != null || qi2 != null || qi3 != null) &&
                                    this.props.location.pathname !== '/myProgressReport' && (
                                        <MyProgressLinks
                                            LeftLink={
                                                ShowActivitiesLink
                                                    ? {
                                                          Icon: 'chart-line',
                                                          Text: 'My QI Activities',
                                                          Link: '/myQiActivities',
                                                          Enabled: true,
                                                      }
                                                    : approvedActivityList
                                            }
                                            RightLink={
                                                ShowActivitiesLink ? approvedActivityList : undefined
                                            }
                                            CenteredLink={ShowActivitiesLink ? false : true}
                                        />
                                    )}
                            </View>
                        </View>
                    </ABAContainer>
                </View>
            </View>
        )
    }
}

QI_IMP.defaultProps = {
    dashboard: true,
    location: {
        pathname: null,
    },
    ShowActivitiesLink: true,
}
