import React, {Component} from 'react'
import {ActivityIndicator, Platform, Text, TouchableHighlight, View} from 'react-native'

import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABALogo from '../../components/ABACustom/presentation/ABALogo'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import BackdoorAccessLogic from '../logic/backdoorAccessLogic'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import LoginFooter from '../../components/presentation/loginFooter'
import LoginHeader from '../../components/presentation/loginHeader'
import LoginReturnLink from '../../components/presentation/loginReturnLink'
import styleFunctions from '../../styles/styleFunctions'
import {connect} from 'react-redux'
import {clearUserData} from '../../redux/ActionCreators'

const mapDispatchToProps = {
    clearUserData,
}
class BackdoorAccess extends Component {
    state = {
        ABAId: null,
        CallingApi: false,
        InputStatus: {
            ABAId: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        this.props.clearUserData()
    }

    render() {
        backdoorAccessContext = this
        navigationContext = this
        BackdoorAccessLogic.context = backdoorAccessContext

        HelperMethods.setNavigationData(backdoorAccessContext)

        return (
            <ABAKeyboardAwareScrollView
                contentContainerStyle={[
                    {flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between'},
                    styleFunctions.loginBackground(),
                ]}
            >
                <View>
                    {Platform.OS === 'web' && <LoginHeader />}
                    <ABALogo />

                    <View style={styleFunctions.loginContainer()}>
                        <Text style={styleFunctions.loginHeader()}>Impersonate Doctor</Text>
                        <Text
                            style={[
                                styleFunctions.containerText('center'),
                                {fontSize: 14, paddingBottom: 20},
                            ]}
                        >
                            Enter a doctor's ABA ID to impersonate that doctor.
                        </Text>

                        <ABATextInputMask
                            keyboardType="number-pad"
                            maxLength={9}
                            onChangeText={(text) => backdoorAccessContext.setState({ABAId: text})}
                            onSubmitEditing={() =>
                                BackdoorAccessLogic.ImpersonateABAEmployeeAsDoctor(
                                    backdoorAccessContext.state.ABAId,
                                )
                            }
                            options={{mask: '9999-9999'}}
                            placeholder="ABA ID"
                            refInput={(input) => {
                                backdoorAccessContext.abaIdInputField = input
                            }}
                            style={styleFunctions.textInput()}
                            textInputStatus={backdoorAccessContext.state.InputStatus.ABAId}
                            type={'custom'}
                            value={backdoorAccessContext.state.ABAId}
                        />

                        <ABATouchableHighlight
                            buttonStyle={styleFunctions.buttonClick()}
                            icon={'angle-right'}
                            onPressEvent={() =>
                                BackdoorAccessLogic.ImpersonateABAEmployeeAsDoctor(
                                    backdoorAccessContext.state.ABAId,
                                )
                            }
                            underlayColor={'#66ccff'}
                            textStyle={{color: 'white', fontFamily: 'Lato'}}
                            text={'Log in as Doctor'}
                        />
                    </View>
                    <LoginReturnLink />

                    {backdoorAccessContext.state.CallingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Impersonating As Doctor...</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </View>

                <LoginFooter />
            </ABAKeyboardAwareScrollView>
        )
    }
}

export default connect(null, mapDispatchToProps)(BackdoorAccess)
