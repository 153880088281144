import React, {Component} from 'react'
import styleFunctions from '../../../../styles/styleFunctions'
import acaPathwayLogic from '../logic/acaPathwayPageLogic'
import {View, Text, ActivityIndicator, Platform} from 'react-native'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import {ColorEnums} from '../../../../helpers/generalEnums'

export default class ACAPathwayPage extends Component {
    state = {
        acaError: null,
        acaPathway: null,
        callingApi: false,
        clinicalActivityId: null,
        nbeCert: null,
        originalACAPathway: null, // if user reloads the page, this will be the option that was originally saved to CRM
        pathwayOptionList: [],
        postTraining: null,
    }

    Save() {
        return acaPathwayLogic.SaveLogic()
    }

    Previous() {
        return new Promise((resolve) => {
            resolve(true)
        })
    }

    componentDidMount() {
        const {GetClinicalActivityData, GetACAPathwayOptions} = acaPathwayLogic

        Promise.all([GetClinicalActivityData(), GetACAPathwayOptions()]).then((acaPathwayData) => {
            const clinicalActivityData = acaPathwayData[0]
            const options = acaPathwayData[1]

            this.setState({
                pathwayOptionList: options,
                clinicalActivityId: clinicalActivityData.cert_clinicalactivityid,
                acaPathway: clinicalActivityData.cert_acapath,
                originalACAPathway: clinicalActivityData.cert_acapath,
                nbeCert: clinicalActivityData.cert_acanbecert,
                postTraining: clinicalActivityData.cert_acaposttraining,
            })
        })
    }

    renderPathwayOptions() {
        const {pathwayOptionList, acaPathway} = this.state

        return pathwayOptionList.map((option, index) => {
            const {value, label} = option
            return (
                <View
                    style={{
                        marginBottom: 15,
                        marginRight: Platform.OS !== 'web' && index !== 1 ? -20 : 0,
                        maxWidth: '99%', // weird cutoff issue on mobile so setting to 99 here
                    }}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={acaPathway === value}
                        label={label}
                        onChangeEvent={() => {
                            this.setState({
                                acaPathway: acaPathway === value ? null : value,
                                acaError: null,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    render() {
        acaPathwayLogic.context = this
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 20,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        Adult Cardiac Anesthesiology Pathway
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 16,
                                paddingTop: 15,
                                width: '100%',
                                marginBottom: 15,
                            },
                        ]}
                    >
                        Please select your Adult Cardiac Anesthesiology entrance pathway:
                        <ABARequiredAsterisk />
                    </Text>
                    {this.state.acaError && (
                        <Text style={{color: ColorEnums.pomegranate}}>Please select a pathway</Text>
                    )}
                    <View>{this.renderPathwayOptions()}</View>
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
