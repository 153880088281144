import React, {Component} from 'react'
import {View} from 'react-native'

import CME from './cme'
import CMEDiplomateInMOCA from './cmeDiplomateInMOCA'
import CMEInformation from './cmeInformation'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'

export default class MyProgressCME extends Component {
    constructor(props) {
        super(props)

        this.state = {
            borderDynamic: {
                borderLeftWidth: 2,
                borderTopWidth: null,
                borderLeftColor: ColorEnums.aquaHaze,
                borderTopColor: null,
            },
            columnWidthDynamic: '50%',
            flexDirectionDynamic: 'row',
        }
    }

    changeLayoutBasedOnWidth(componentWidth) {
        if (componentWidth <= 1100) {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: null,
                    borderTopWidth: 2,
                    borderLeftColor: null,
                    borderTopColor: ColorEnums.aquaHaze,
                },
                columnWidthDynamic: '100%',
                flexDirectionDynamic: 'column',
            })
        } else {
            this.setState({
                borderDynamic: {
                    borderLeftWidth: 2,
                    borderTopWidth: null,
                    borderLeftColor: ColorEnums.aquaHaze,
                    borderTopColor: null,
                },
                columnWidthDynamic: '50%',
                flexDirectionDynamic: 'row',
            })
        }
    }

    render() {
        return (
            <View
                style={{flexDirection: this.state.flexDirectionDynamic}}
                onLayout={(event) => {
                    this.changeLayoutBasedOnWidth(event.nativeEvent.layout.width)
                }}
            >
                <View style={{width: this.state.columnWidthDynamic, paddingBottom: 20}}>
                    <CMEInformation />
                </View>
                <View style={[{width: this.state.columnWidthDynamic}, this.state.borderDynamic]}>
                    {this.props.RoleId === GeneralEnums.roleEnums.dilomateInMOCA && (
                        <CMEDiplomateInMOCA DashboardDisplay={true} />
                    )}
                    {this.props.RoleId === GeneralEnums.roleEnums.diplomateNotInMOCA && (
                        <CME DashboardDisplay={true} />
                    )}
                </View>
            </View>
        )
    }
}
