import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'
import {GeneralEnums} from '../../../helpers/generalEnums'

const MOCACopyrightReleaseLogic = {
    context: null,

    GetText: function (Data) {
        return new Promise((resolve) => {
            MOCACopyrightReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: Data.Crt_ReleaseType,
                ProgramType: MOCACopyrightReleaseLogic.context.props.ProgramType,
                re_AdditionalSpecification: Data.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing MOCA Copyright Release')
                })
                .finally(function () {
                    MOCACopyrightReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetReleaseData: function () {
        return new Promise((resolve) => {
            MOCACopyrightReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: MOCACopyrightReleaseLogic.context.props.Order,
                CR2ID: MOCACopyrightReleaseLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing MOCA Copyright Release')
                })
                .finally(function () {
                    MOCACopyrightReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease: function (data) {
        var releaseOption
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOption = true
        } else if (data.Crt_RecordStatus == 2) {
            releaseOption = false
        }
        MOCACopyrightReleaseLogic.context.setState({
            ReleaseOption: releaseOption || false,
            NoteText: data.Crt_Note || '',
            ReleaseData: data,
        })
    },

    CreateRelease: function () {
        return new Promise((resolve) => {
            MOCACopyrightReleaseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: MOCACopyrightReleaseLogic.context.props.CR2ID,
                Order: MOCACopyrightReleaseLogic.context.props.Order,
                Crt_RecordStatus: MOCACopyrightReleaseLogic.context.state.ReleaseOption ? 1 : 2,
                Crt_Note: MOCACopyrightReleaseLogic.context.state.NoteText,
                ContactId: MOCACopyrightReleaseLogic.context.state.ReleaseData.ContactId,
                Crt_ReleaseType: MOCACopyrightReleaseLogic.context.state.ReleaseData.Crt_ReleaseType,
                Crt_ProgramType: MOCACopyrightReleaseLogic.context.state.ReleaseData.Crt_ProgramType,
                Crt_ReleaseId: MOCACopyrightReleaseLogic.context.state.ReleaseData.Crt_ReleaseId,
                LastName: MOCACopyrightReleaseLogic.context.state.ReleaseData.LastName,
                FirstName: MOCACopyrightReleaseLogic.context.state.ReleaseData.FirstName,
                rs_statuscode: MOCACopyrightReleaseLogic.context.state.ReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving MOCA Copyright Information')
                    resolve(false)
                })
                .finally(function () {
                    MOCACopyrightReleaseLogic.context.setState({CallingApi: false})
                })
        })
    },

    ReleaseOptionSelected: function () {
        MOCACopyrightReleaseLogic.context.setState({
            ReleaseOption: !MOCACopyrightReleaseLogic.context.state.ReleaseOption,
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!MOCACopyrightReleaseLogic.context.state.ReleaseOption) {
                MOCACopyrightReleaseLogic.context.setState({
                    InputStatus: {ReleaseOption: GeneralEnums.textInputStatus.error},
                })
                resolve(false)
            } else {
                MOCACopyrightReleaseLogic.context.setState({
                    OverlayText: 'Saving Copyright Release',
                    CallingApi: true,
                })
                MOCACopyrightReleaseLogic.CreateRelease().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default MOCACopyrightReleaseLogic
