import React, { Component } from "react";
import {
    Platform,
    Text,
    View,
} from "react-native";

import ABAKeyboardAwareScrollView from "../../components/ABACustom/presentation/ABAKeyboardAwareScrollView";
import ABALogo from '../../components/ABACustom/presentation/ABALogo';
import ABATextInput from "../../components/ABACustom/presentation/ABATextInput";
import ABATouchableHighlight from "../../components/ABACustom/presentation/ABATouchableHighlight";
import ForgotPasswordLogic from "../logic/forgotPasswordLogic";
import { GeneralEnums } from "../../helpers/generalEnums";
import HelperMethods from "../../helpers/HelperMethods";
import LoginHeader from "../../components/presentation/loginHeader";
import LoginFooter from "../../components/presentation/loginFooter"
import LoginReturnLink from "../../components/presentation/loginReturnLink";
import styleFunctions from "../../styles/styleFunctions";

export default class ForgotPasswordVerification extends Component {
    state = {
        LastNameConfirmationEntry: null,
        InputStatus: {
            LastName: GeneralEnums.textInputStatus.default,
        }
    }

    render() {
        forgotPasswordVerificationContext = this;
        navigationContext = this;
        ForgotPasswordLogic.context = forgotPasswordVerificationContext;

        HelperMethods.setNavigationData(forgotPasswordVerificationContext);

        return (
            <ABAKeyboardAwareScrollView contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between' }, styleFunctions.loginBackground()]}>
                <View>
                    {Platform.OS === "web" &&
                        <LoginHeader />
                    }
                    <ABALogo />

                    <View style={styleFunctions.loginContainer()}>
                        <Text style={styleFunctions.loginHeader()}>
                            Verify Identity
                        </Text>
                        <Text style={[styleFunctions.containerText(), {fontSize: 14, fontWeight: 'bold', paddingLeft: 50, paddingRight: 50, textAlign: 'center'}]}>
                            Are you {forgotPasswordVerificationContext.navigationData.ContactFullName}?
                        </Text>

                        <Text style={[styleFunctions.containerText(), {fontSize: 14, paddingLeft: 50, paddingRight: 50, paddingTop: 10, textAlign: 'center'}]}>
                            Please confirm this by typing your last name and pressing Continue.
                        </Text>

                        <View style={styleFunctions.loginFormContainer()}>
                            <ABATextInput
                                maxLength={50}
                                onChangeText={(text) => { forgotPasswordVerificationContext.setState({ LastNameConfirmationEntry: text }); }}
                                onSubmitEditing={() => { ForgotPasswordLogic.goToSetPassword(forgotPasswordVerificationContext.navigationData.ContactId); }}
                                placeholder={"Last Name"}
                                reference={(input) => { forgotPasswordVerificationContext.confirmLastNameInputField = input }}
                                textInputStatus={forgotPasswordVerificationContext.state.InputStatus.LastName}
                            />

                            <ABATouchableHighlight
                                buttonStyle={styleFunctions.buttonClick()}
                                icon={"angle-right"}
                                onPressEvent={() => { ForgotPasswordLogic.goToSetPassword(forgotPasswordVerificationContext.navigationData.ContactId); }}
                                text={"Continue"}
                                textStyle={{ color: 'white', fontFamily: 'Lato', fontSize: 18 }}
                                underlayColor="#66ccff"
                            />
                        </View>
                    </View>
                    <LoginReturnLink />
                </View>

                <LoginFooter />
            </ABAKeyboardAwareScrollView>
        );
    }
}