import React, {Component} from 'react'
import {ActivityIndicator, Image, Platform, StyleSheet, Text, View} from 'react-native'
import {MobilePay} from 'react-native-aba-module'
import {appConfiguration} from '../../../../configuration.json'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import {
    AuthNetResponseCodes,
    ColorEnums,
    GeneralEnums,
    PaymentTypes,
    UnicodeEnums,
} from '../../../../helpers/generalEnums'
import NavigationHelper from '../../../../helpers/NavigationHelper'
import PaymentFormLogic from '../../../../screens/logic/paymentFormLogic'
import styleFunctions from '../../../../styles/styleFunctions'
import ABACountdownCircle from '../../../ABACustom/presentation/ABACountdownCircle'
import {ABARadio, ABARadioGroup} from '../../../ABACustom/presentation/ABARadio'
import InvoiceConfirmationLogic from '../logic/invoiceConfirmationLogic'
import EventSelection from './eventSelection'
import RankedChoiceSelection from './rankedChoiceSelection'

const GooglePayMark = require('../../../../assets/images/MobilePay/GooglePayMark.png')
const ApplePayMark = require('../../../../assets/images/MobilePay/ApplePayMark.png')

const isProd = appConfiguration.Environment === 'PROD'
const ApplePayCert = isProd ? 'merchant.theaba.org.authorizenet' : 'merchant.org.theaba'
const googleMerchantId = isProd ? '1064596' : '380014'
mobilePay = new MobilePay(
    'The ABA',
    Platform.OS === 'ios' ? ApplePayCert : 'authorizenet',
    googleMerchantId,
    !isProd,
)

export default class InvoiceConfirmation extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            notesText: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 14,
                paddingTop: 10,
            },

            titleText: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 14,
                fontWeight: 'bold',
            },
            declinedMessage: {
                paddingTop: 15,
                fontSize: 20,
                color: ColorEnums.pomegranate,
                textAlign: 'center',
            },
        })
        this.state = {
            CallingApi: false,
            InvoiceID: undefined,
            InvoiceDetails: null,
            LineItems: null,
            CertsIncludedInMoca: null,
            Moca2Prices: null,
            paymentType: PaymentTypes.CreditCard,
            declinedMessages: [],
        }
    }

    async componentDidMount() {
        if (this.props.MOCA) {
            InvoiceConfirmationLogic.getMocaInvoiceInfo().then((ResponseData) => {
                this.setState(ResponseData)
            })
        } else {
            InvoiceConfirmationLogic.createAndGetInvoice().then(
                ({TotalAmount, InvoiceID, AppliedTimerSeconds}) => {
                    this.setState({
                        InvoiceDetails: {
                            TotalAmount,
                            InvoiceID,
                            AppliedTimerSeconds,
                            AppliedTimerExpiration: !AppliedTimerSeconds
                                ? null
                                : new Date(new Date().getTime() + AppliedTimerSeconds * 1000),
                        },
                    })
                },
            )
        }
        const isReady = await mobilePay.isReady()
        this.setState({isMobilePayReady: isReady})
    }

    //Only called for exam registration (non moca)
    Previous() {
        return new Promise((resolve) => {
            if (this.props.isRankedChoice) {
                this.props.PaymentAndRegistrationContext.setState({
                    CurrentComponentStep: RankedChoiceSelection,
                })
                resolve(false)
                return
            }
            this.props.PaymentAndRegistrationContext.setState({
                CurrentComponentStep: EventSelection,
                EventID: '',
                SessionName: '',
                SessionDates: '',
            })
            resolve(false)
        })
    }

    async Save() {
        if (this.state.paymentType === PaymentTypes.CreditCard) {
            const {AppliedTimerExpiration, InvoiceID} = this.state.InvoiceDetails
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/paymentForm', {
                InvoiceID,
                AppliedTimerExpiration,
                returnTo: '/registrationStepsDisplay',
                returnData: this.props.NavigationData,
                containerTitle: this.props.ContainerTitle,
            })
            return Promise.resolve(false)
        }

        try {
            this.setState({CallingApi: true})
            {
                const {data} = await ABAPortalAPI.get('payment/paymentStatusDashboard', {
                    params: {
                        timestamp: new Date().toUTCString(),
                    },
                })
                // A payment check failed
                if (data) {
                    const error = {
                        response: {
                            status: 400,
                        },
                    }
                    PaymentFormLogic.handlePaymentError(error)
                    return
                }
            }
            const transaction = await mobilePay.startTransaction(this.state.InvoiceDetails.TotalAmount)
            const paymentData = JSON.parse(transaction)

            const transactionData = {
                invoiceID: this.state.InvoiceDetails.InvoiceID,
                token: paymentData.token,
                billingAddress: paymentData.billingAddress,
            }
            const url = `payment/ProcessPayment/${Platform.select({
                ios: 'applepay',
                android: 'googlepay',
            })}`

            const result = await ABAPortalAPI.post(url, transactionData)

            var currentTimestamp = new Date()
            var formattedTimestamp = currentTimestamp.toLocaleString()
            const {data} = result
            if (data.transactionResponse.responseCode === AuthNetResponseCodes.SUCCESSFUL) {
                const paymentDetails = {
                    InvoiceID: this.state.InvoiceDetails.InvoiceID,
                    LineItems: this.state.LineItems,
                    MOCA: this.props.MOCA,
                    Description:
                        this.state.InvoiceDetails.PortalName ||
                        `${this.props.SessionName}: ${this.props.SessionDates}`,
                    SessionDates: this.props.SessionDates,
                    SessionName: this.props.SessionName,
                    InvoiceDetails: this.state.InvoiceDetails,
                    TransactionID: data.transactionResponse.transId,
                    PayDate: formattedTimestamp,
                    TotalAmount: this.state.InvoiceDetails.TotalAmount,
                    ApprovalCode: data.transactionResponse.authCode,
                    PayType: 'CREDIT',
                }

                NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/receiptDetails', {
                    details: paymentDetails,
                })
            } else {
                this.setState({declinedMessages: data.transactionResponse.errors})
                this.props.PaymentAndRegistrationContext.props.RegistrationContext.context.setState({
                    disableButton: false,
                })
            }
        } catch (err) {
            if (err?.code === 'PAYMENT_RESULT_CANCELED') {
                // User canceled payment
                this.props.PaymentAndRegistrationContext.props.RegistrationContext.context.setState({
                    disableButton: false,
                })
                return
            }
            PaymentFormLogic.handlePaymentError(err)
            console.error(err)
        } finally {
            this.setState({CallingApi: false})
        }
        return Promise.resolve(false)
    }

    renderLineItems() {
        return this.props.MOCA && this.state.LineItems ? (
            <View
                style={{
                    borderBottomColor: ColorEnums.cloudBurst,
                    borderBottomWidth: 3,
                    paddingBottom: 15,
                    paddingTop: 10,
                }}
            >
                {this.state.LineItems.filter((lineItem) => !!lineItem.SumByProd).map(
                    (lineItem, index) => {
                        return (
                            <View
                                key={index}
                                style={{
                                    borderTopColor: ColorEnums.exLightGray,
                                    borderTopWidth: 1,
                                    width: '100%',
                                    flexDirection: 'row',
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                }}
                            >
                                <View style={{width: '80%'}}>
                                    <Text style={[styleFunctions.paragraphText(), {fontSize: 14}]}>
                                        {lineItem.ItemDescription}
                                    </Text>
                                </View>
                                <View style={{width: '20%'}}>
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {fontSize: 14, textAlign: 'right'},
                                        ]}
                                    >
                                        ${lineItem.Amount.toFixed(2)}
                                    </Text>
                                </View>
                            </View>
                        )
                    },
                )}
            </View>
        ) : (
            <View
                style={{
                    flex: 1,
                    width: '100%',
                    flexDirection: 'row',
                    paddingBottom: 25,
                    paddingTop: 10,
                    borderTopColor: ColorEnums.exLightGray,
                    borderTopWidth: 1,
                    borderBottomColor: ColorEnums.cloudBurst,
                    borderBottomWidth: 3,
                }}
            >
                <Text style={[styleFunctions.paragraphText(), {width: '80%', fontSize: 14}]}>
                    <Text style={{fontWeight: 'bold'}}>{this.props.SessionName}: </Text>
                    {this.props.SessionDates}
                </Text>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {width: '20%', fontSize: 14, textAlign: 'right'},
                    ]}
                >
                    {this.state.InvoiceDetails
                        ? `$${this.state.InvoiceDetails.TotalAmount.toFixed(2)}`
                        : 'Fetching price...'}
                </Text>
            </View>
        )
    }

    renderNotesExam() {
        return (
            <View
                style={{
                    paddingTop: 20,
                    borderBottomColor: ColorEnums.lightGray,
                    borderBottomWidth: 2,
                    paddingBottom: 20,
                }}
            >
                <Text style={[this.styles.titleText, {paddingBottom: 10}]}>Notes</Text>
                <View style={{flexDirection: 'row', paddingLeft: 10}}>
                    <Text style={[this.styles.notesText, {paddingLeft: 5, paddingRight: 5, width: 20}]}>
                        {UnicodeEnums['bullet']}
                    </Text>
                    <Text style={this.styles.notesText}>
                        You have chosen to register for the{' '}
                        {`${this.props.SessionName} - ${this.props.SessionDates}`} exam.
                    </Text>
                </View>
                <View style={{flexDirection: 'row', paddingLeft: 10}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingLeft: 5, paddingRight: 5, width: 20, paddingTop: 5},
                        ]}
                    >
                        {UnicodeEnums['bullet']}
                    </Text>
                    <Text style={this.styles.notesText}>
                        You must complete payment in order to be registered for the exam.
                    </Text>
                </View>
                <Text style={this.styles.notesText}>
                    We expect a candidate who accepts an exam to keep the exam appointment. Candidates
                    who inform us that they are cancelling their exam appointment will be charged a
                    cancellation fee.
                </Text>
                <Text style={this.styles.notesText}>
                    Notice of cancellation must be in writing and must include a check in the amount of
                    the cancellation fee.
                </Text>
                {this.state.InvoiceDetails && this.state.InvoiceDetails.TotalAmount <= 0 && (
                    <Text style={this.styles.notesText}>
                        Our records indicate that you do not owe a payment to the ABA at this time for
                        the {this.props.SessionName} Exam. You MUST press the “Continue” button to
                        finish.
                    </Text>
                )}
            </View>
        )
    }
    getMobilePaymentOption() {
        if (Platform.OS === 'android')
            return (
                <ABARadio
                    value={PaymentTypes.MobilePay}
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                >
                    <Image
                        style={{width: 50, height: 50}}
                        source={GooglePayMark}
                        resizeMode={'contain'}
                    />
                </ABARadio>
            )
        if (Platform.OS === 'ios')
            return (
                <ABARadio
                    value={PaymentTypes.MobilePay}
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                >
                    <Image
                        style={{width: 50, height: 50}}
                        source={ApplePayMark}
                        resizeMode={'contain'}
                    />
                </ABARadio>
            )
        return <></>
    }
    renderNotesMoca() {
        return (
            <View
                style={{
                    paddingTop: 20,
                    borderBottomColor: ColorEnums.lightGray,
                    borderBottomWidth: 2,
                    paddingBottom: 20,
                }}
            >
                <Text style={[this.styles.titleText, {paddingBottom: 10}]}>Notes</Text>
                <Text style={this.styles.notesText}>You are maintaining certificate(s) in:</Text>
                {this.state.CertsIncludedInMoca && (
                    <View>
                        {this.state.CertsIncludedInMoca.map((cert, index) => {
                            return (
                                <View key={index} style={{flexDirection: 'row', paddingLeft: 10}}>
                                    <Text
                                        style={[
                                            this.styles.notesText,
                                            {paddingLeft: 5, paddingRight: 5, width: 20},
                                        ]}
                                    >
                                        {UnicodeEnums['bullet']}
                                    </Text>
                                    <Text style={this.styles.notesText}>{cert}</Text>
                                </View>
                            )
                        })}
                    </View>
                )}
                {this.state.Moca2Prices && (
                    <Text style={this.styles.notesText}>
                        MOCA 2.0 fee: You'll pay ${this.state.Moca2Prices.FirstMOCACertificatePrice} each
                        year for the first certificate you maintain. For each additional, different
                        certificate you'll pay an annual $
                        {this.state.Moca2Prices.AdditionalMOCACertificatePrice + ' '} program fee.
                    </Text>
                )}
                {this.state.InvoiceDetails && this.state.InvoiceDetails.TotalAmount <= 0 ? (
                    <Text style={this.styles.notesText}>
                        Our records indicate that you have a credit on account, so no payment is
                        required. Click the “Continue” button to finish your registration.
                    </Text>
                ) : (
                    <Text style={this.styles.notesText}>
                        Click the “Continue” button to pay your annual MOCA 2.0 fee. If you have a credit
                        on your account, it will be reflected above.
                    </Text>
                )}
            </View>
        )
    }
    render() {
        invoiceConfirmationContext = this
        InvoiceConfirmationLogic.context = invoiceConfirmationContext

        return (
            <View>
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap-reverse',
                        paddingTop: 15,
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    {this.props.MOCA ? (
                        <View style={{paddingTop: 15}}>
                            <Text style={this.styles.titleText}>
                                MOCA 2.0{UnicodeEnums['registered-trademark']} Payment Overview
                            </Text>
                            <Text style={[this.styles.notesText, {paddingBottom: 10}]}>
                                *All fees are non-refundable
                            </Text>
                        </View>
                    ) : (
                        <>
                            <View style={{paddingTop: 15, alignSelf: 'flex-end'}}>
                                <Text style={this.styles.titleText}>Examination Payment Overview</Text>
                                <Text style={[this.styles.notesText, {paddingBottom: 10}]}>
                                    *All fees are non-refundable
                                </Text>
                            </View>
                            {this.state.InvoiceDetails?.AppliedTimerSeconds && (
                                <View style={{alignSelf: 'center'}}>
                                    <ABACountdownCircle
                                        seconds={this.state.InvoiceDetails.AppliedTimerSeconds}
                                        renderMinutes
                                        textStyle={[styleFunctions.containerText(), {fontSize: 12}]}
                                        expirationEvent={this.Previous.bind(this)}
                                    />
                                </View>
                            )}
                        </>
                    )}
                </View>
                <View style={{width: '100%', flexDirection: 'row', paddingTop: 5}}>
                    <Text
                        style={[
                            styleFunctions.columnHeader(),
                            {width: '50%', fontFamily: 'Lato', fontSize: 14},
                        ]}
                    >
                        Item
                    </Text>
                    <Text
                        style={[
                            styleFunctions.columnHeader(),
                            {width: '50%', textAlign: 'right', fontFamily: 'Lato', fontSize: 14},
                        ]}
                    >
                        Fee
                    </Text>
                </View>
                {this.renderLineItems()}
                <View
                    style={{
                        width: '100%',
                        flexDirection: 'row',
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {width: '50%', fontSize: 14, fontWeight: 'bold'},
                        ]}
                    >
                        Total:
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {width: '50%', fontSize: 14, fontWeight: 'bold', textAlign: 'right'},
                        ]}
                    >
                        {this.state.InvoiceDetails
                            ? `$${this.state.InvoiceDetails.TotalAmount.toFixed(2)}`
                            : 'Calculating total...'}
                    </Text>
                </View>
                <View>
                    {this.state.declinedMessages?.map((error) => (
                        <Text style={this.styles.declinedMessage}>{error.errorText}</Text>
                    ))}
                </View>
                {this.state.isMobilePayReady && this.state.InvoiceDetails?.TotalAmount > 0 && (
                    <View>
                        <Text style={[this.styles.titleText, {paddingTop: 30}]}>Payment Options</Text>

                        <ABARadioGroup
                            onChange={(paymentType) => this.setState({paymentType})}
                            defaultValue={this.state.paymentType}
                        >
                            <ABARadio
                                value={PaymentTypes.CreditCard}
                                style={{paddingTop: 15, paddingBottom: 15}}
                            >
                                <Text>Credit/Debit</Text>
                            </ABARadio>
                            {this.getMobilePaymentOption()}
                        </ABARadioGroup>
                    </View>
                )}

                {this.props.MOCA ? this.renderNotesMoca() : this.renderNotesExam()}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Invoice Confirmation</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
