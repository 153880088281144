import React, {Component} from 'react'
import {View, Platform} from 'react-native'
import WebView from 'react-native-webview'
import PaymentFormLogic from '../logic/paymentFormLogic'
import NavigationHelper from '../../helpers/NavigationHelper'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import HelperMethods from '../../helpers/HelperMethods'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import configurationProps from '../../configuration'
import NavigationShell from '../../components/presentation/navigationShell'
import ABACountdownCircle from '../../components/ABACustom/presentation/ABACountdownCircle'
import styleFunctions from '../../styles/styleFunctions'
import ABAPortalAPI from '../../helpers/AxiosInstance'

authNetURL =
    configurationProps.ENVIRONMENT === 'PROD'
        ? 'https://accept.authorize.net/payment/payment'
        : 'https://test.authorize.net/payment/payment'

export default class PaymentForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            PaymentToken: undefined,
            RefId: undefined,
            WebHeight: 100,
            TimerSecondsRemaining: null,
            completed: false,
            isMounted: true,
            stopProcessingOn: null,
        }
        this.frameRef = React.createRef()
        this.paymentTimeout = React.createRef()
        this.handleMessage = this.handleMessage.bind(this)
    }

    componentDidMount() {
        PaymentFormLogic.getPaymentToken()
            .then(({Token, RefID}) => {
                if (Token === 'completed') {
                    //Invoice total was 0, no payment required
                    this.setState({completed: true}, () => {
                        NavigationHelper.GoToPage(
                            GeneralEnums.navigationType.replace,
                            '/receiptDetails',
                            {
                                InvoiceID: this.navigationData.InvoiceID,
                                Source: 'CRM',
                            },
                        )
                    })
                } else {
                    if (this.navigationData.AppliedTimerExpiration) {
                        const remainingTime =
                            new Date(this.navigationData.AppliedTimerExpiration).getTime() -
                            new Date().getTime()
                        this.setState({TimerSecondsRemaining: Math.floor(remainingTime / 1000)})
                    }
                    this.setState({PaymentToken: Token, RefId: RefID})
                }
            })
            .finally(() => this.setState({CallingApi: false}))

        if (Platform.OS === 'web') {
            window.addEventListener('message', this.handleMessage)
        }
    }

    componentWillUnmount() {
        this.setState({isMounted: false})
        clearTimeout(this.paymentTimeout?.current)
        if (Platform.OS === 'web') {
            window.removeEventListener('message', this.handleMessage)
        }
        if (this.state.completed) return
        PaymentFormLogic.expireUnusedLogs()
    }

    getPaymentPage() {
        let authForm = document.createElement('form')
        authForm.method = 'post'
        authForm.action = authNetURL
        document.body.appendChild(authForm)

        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'token'
        input.value = this.state.PaymentToken

        authForm.appendChild(input)
        authForm.submit()
    }

    goBack() {
        const {returnTo, returnData} = this.navigationData
        NavigationHelper.GoToPage(
            GeneralEnums.navigationType.replace,
            returnTo || '/dashboard',
            returnTo && returnData ? returnData : undefined,
        )
    }

    handlePostPaymentRedirect(path) {
        if (path?.indexOf('/cancelled') > -1) {
            this.goBack()
        } else if (path?.indexOf('/paid') > -1) {
            this.countdownCircle?.stop?.()
            const stopProcessingOn = new Date().getTime() + 1000 * 60 // 1 minute
            this.setState({completed: true, stopProcessingOn}, () => {
                this.startPaymentProccessingLoop()
            })
            var newRefId = String(this.state.RefId)
            ABAPortalAPI.get('payment/lockPaymentRequest', {
                params: {refId: newRefId}
            })
        }
    }

    startPaymentProccessingLoop() {
        const paymentTimeout = setTimeout(async () => {
            const {data} = await this.getPaymentStatus()
            if (!data) {
                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/receiptDetails', {
                    InvoiceID: this.navigationData.InvoiceID,
                    Source: 'CRM',
                })
            } else if (new Date().getTime() > this.state.stopProcessingOn) {
                NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/dashboard')
            } else if (this.state.isMounted) {
                this.startPaymentProccessingLoop()
            }
        }, 2000)
        this.paymentTimeout.current = paymentTimeout
    }

    async getPaymentStatus() {
        try {
            return await ABAPortalAPI.get('payment/paymentStatusDashboard', {
                params: {
                    timestamp: new Date().toUTCString(),
                },
            })
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error Acccessing Payment Status')
            return {data: true} // A little counter-intuitive but if we can't get the status assume it's still processing
        }
    }

    handleMessage(event) {        
        if (event.data) {
            event = event.data
        }
        if (event.source && (event.source === 'react-devtools-bridge' || event.source === 'react-devtools-content-script' || event.source === 'react-devtools-backend-manager'))
        {
            return;
        }
        try {
            const eventObject = JSON.parse(event)

            switch (eventObject.action) {
                case 'resizeWindow':
                    this.setState({WebHeight: parseInt(eventObject.height)})
                    break
                case 'cancel':
                    this.handlePostPaymentRedirect('/cancelled')
                    break
                case 'transactResponse':
                    this.handlePostPaymentRedirect('/paid')
                    break
            }
        } catch (error) {
            console.error('error parsing message: ', event, error)
        }
    }

    render() {
        paymentFormContext = this
        PaymentFormLogic.context = paymentFormContext
        HelperMethods.setNavigationData(paymentFormContext)
        navigationContext = this
        return (
            <NavigationShell context={this} showNav={false}>
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray, paddingVertical: 20}}
                >
                    <ABAContainer
                        activityText={this.state.completed ? 'Payment Processing' : 'Loading Payment'}
                        useMaxWidth={false}
                        showActivityIndicator={this.state.CallingApi || this.state.completed}
                        containerTitle={this.navigationData.containerTitle || 'PAYMENT'}
                        overrideWidth={Platform.OS !== 'web'}
                        containerViewStyle={{minHeight: 300}}
                    >
                        {!this.state.completed && (
                            <View
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                {this.state.TimerSecondsRemaining && (
                                    <>
                                        <View style={{height: 10}} />
                                        <ABACountdownCircle
                                            ref={(countdownCircle) => {
                                                this.countdownCircle = countdownCircle
                                            }}
                                            seconds={this.state.TimerSecondsRemaining}
                                            renderMinutes
                                            textStyle={[styleFunctions.containerText(), {fontSize: 12}]}
                                            expirationEvent={this.goBack.bind(this)}
                                        />
                                    </>
                                )}
                                <View style={{height: 10}} />
                                {!this.state.PaymentToken && <View style={{height: '100%'}} />}
                                {this.state.PaymentToken && Platform.OS === 'web' && (
                                    <iframe
                                        src={`${configurationProps.BASE_URL}assets/payment.html?env=${
                                            configurationProps.ENVIRONMENT
                                        }&token=${encodeURIComponent(this.state.PaymentToken)}`}
                                        style={{
                                            width: '100%',
                                            height: this.state.WebHeight + 20,
                                            border: 'none',
                                            overflow: 'hidden',
                                        }}
                                        onLoad={(() => {
                                            const location =
                                                this.frameRef.current.contentWindow?.location
                                            this.handlePostPaymentRedirect(location?.href)
                                        }).bind(this)}
                                        ref={this.frameRef}
                                        scrolling="no"
                                    />
                                )}
                                {this.state.PaymentToken && Platform.OS !== 'web' && (
                                    <View
                                        style={{
                                            height: this.state.WebHeight,
                                        }}
                                    >
                                        <WebView
                                            ref={(ref) => {
                                                this.webview = ref
                                            }}
                                            scrollEnabled={false}
                                            source={{
                                                uri: `${
                                                    configurationProps.BASE_URL
                                                }assets/payment.html?env=${
                                                    configurationProps.ENVIRONMENT
                                                }&token=${encodeURIComponent(this.state.PaymentToken)}`,
                                            }}
                                            containerStyle={{
                                                backgroundColor: 'transparent',
                                                minHeight: 400,
                                                minWidth: '100%',
                                                width: '100%',
                                                maxWidth: '100%',
                                            }}
                                            javaScriptEnabled={true}
                                            onMessage={(event) => {
                                                this.handleMessage(event.nativeEvent.data)
                                            }}
                                            onNavigationStateChange={(target) => {
                                                this.handlePostPaymentRedirect(target.url)
                                            }}
                                            scalesPageToFit={false}
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
