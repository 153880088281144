import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../helpers/generalEnums'

const AcknowledgementLogic = {
    context: null,

    GetACKText: function (ACKData) {
        return new Promise((resolve) => {
            AcknowledgementLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: ACKData.Crt_ReleaseType,
                ProgramType: AcknowledgementLogic.context.props.ProgramType,
                re_AdditionalSpecification: ACKData.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Release')
                })
                .finally(function () {
                    AcknowledgementLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetACKReleaseData: function () {
        return new Promise((resolve) => {
            AcknowledgementLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: AcknowledgementLogic.context.props.Order,
                CR2ID: AcknowledgementLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Acknowledgement Release')
                })
                .finally(function () {
                    AcknowledgementLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease: function (data) {
        var releaseOption
        if (data.Crt_RecordStatus == 1 || data.Crt_RecordStatus == 4) {
            releaseOption = true
        } else if (data.Crt_RecordStatus == 2) {
            releaseOption = false
        }
        var LabelText
        if (AcknowledgementLogic.context.props.ProgramType == 500) {
            LabelText = 'I have read and agree to the MOCA Acknowledgement contract.'
        } else {
            LabelText =
                'I have read and understand the Registration Acknowledgement statement as it pertains to my registration for ABA exam.'
        }
        AcknowledgementLogic.context.setState({
            ReleaseOption: releaseOption,
            NoteText: data.Crt_Note || '',
            ACKReleaseData: data,
            LabelText: LabelText,
        })
    },

    CreateACKRelease: function () {
        return new Promise((resolve) => {
            AcknowledgementLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: AcknowledgementLogic.context.props.CR2ID,
                Order: AcknowledgementLogic.context.props.Order,
                Crt_RecordStatus: AcknowledgementLogic.context.state.ReleaseOption ? 1 : 2,
                Crt_Note: AcknowledgementLogic.context.state.NoteText,
                ContactId: AcknowledgementLogic.context.state.ACKReleaseData.ContactId,
                Crt_ReleaseType: AcknowledgementLogic.context.state.ACKReleaseData.Crt_ReleaseType,
                Crt_ProgramType: AcknowledgementLogic.context.state.ACKReleaseData.Crt_ProgramType,
                Crt_ReleaseId: AcknowledgementLogic.context.state.ACKReleaseData.Crt_ReleaseId,
                LastName: AcknowledgementLogic.context.state.ACKReleaseData.LastName,
                FirstName: AcknowledgementLogic.context.state.ACKReleaseData.FirstName,
                rs_statuscode: AcknowledgementLogic.context.state.ACKReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    AcknowledgementLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    ReleaseOptionSelected: function () {
        let stateObj = {
            ReleaseOption: !AcknowledgementLogic.context.state.ReleaseOption,
        }

        if (
            AcknowledgementLogic.context.state.InputStatus.ReleaseOption ===
            GeneralEnums.textInputStatus.error
        ) {
            stateObj['InputStatus'] = {
                ReleaseOption: GeneralEnums.textInputStatus.default,
            }
        }

        AcknowledgementLogic.context.setState(stateObj)
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!AcknowledgementLogic.context.state.ReleaseOption) {
                AcknowledgementLogic.context.setState({
                    InputStatus: {ReleaseOption: GeneralEnums.textInputStatus.error},
                })
                resolve(false)
            } else {
                AcknowledgementLogic.CreateACKRelease().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default AcknowledgementLogic
