import React, {Component} from 'react'
import {Text} from 'react-native'

import ABAContainer from '../ABACustom/presentation/ABAContainer'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import {GeneralEnums, UnicodeEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import styleFunctions from '../../styles/styleFunctions'

export default class QIInformation extends Component {
    render() {
        return (
            <ABAContainer showContainerTitleBar={false} useMaxWidth={false}>
                <Text style={[styleFunctions.containerHeader('center'), {paddingBottom: 20}]}>
                    Tell us how you're improving your practice
                </Text>
                <Text style={[styleFunctions.containerText('center'), {paddingBottom: 20}]}>
                    {' '}
                    Learn more about the requirements that need to be completed by Years 5 and 10 as part
                    of MOCA 2.0{UnicodeEnums['registered-trademark']}.
                </Text>

                <ABATouchableHighlight
                    icon={'angle-right'}
                    onPressEvent={() => {
                        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/myProgressReport')
                    }}
                    text={'Review Your Progress Report'}
                />
            </ABAContainer>
        )
    }
}
