import React, {useState, useEffect} from 'react'
import {View, Text} from 'react-native'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import ABAToolTip from './ABAToolTip'
import StyleFunctions from '../../../styles/styleFunctions'
import MocaMinutePerformanceChartLogic from '../../../screens/logic/mocaMinutePerformanceChartLogic'

function ABAPerformanceStandard() {
    const [showPSTooltip, setShowPSTooltip] = useState(false)
    const [tooltipLeft] = useState(0)
    const [formHeight] = useState(40)
    const [tooltipBottom] = useState(0)
    const [mocaMinuteData, setMocaMinuteData] = useState({})

    function setMDTSectionWidth(Q1Answered) {
        return Q1Answered > 0 ? '85%' : '100%'
    }

    function setMDTStatusText(MDTValue) {
        if (MDTValue >= 0.1) {
            return 'You are currently meeting the standard'
        } else {
            return 'You are not currently meeting the standard of ≥ 0.10'
        }
    }

    function setMDTIconColor(MDTValue) {
        if (MDTValue >= 0.1) {
            return ColorEnums.surfGreen
        } else if (MDTValue < 0.1) {
            return ColorEnums.pomegranate
        } else {
            return ColorEnums.gray
        }
    }

    useEffect(() => {
        const fetchMocaMinuteData = async () => {
            const mocaMinuteData = await MocaMinutePerformanceChartLogic.GetMocaMinuteDashboardInfo()
            setMocaMinuteData(mocaMinuteData)
        }

        fetchMocaMinuteData()
    }, [])

    const {Q1Answered, MDTValue} = mocaMinuteData
    if (!MDTValue && MDTValue !== 0) {
        return <></>
    }
    return (
        <View style={{width: '80%', alignSelf: 'center', alignItems: 'center'}}>
            <View
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                }}
            >
                <Text style={[StyleFunctions.containerTextBold(), {fontSize: 12}]}>
                    Performance Standard{' '}
                </Text>
                <View>
                    <Text
                        style={{
                            fontSize: 16,
                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                            color: ColorEnums.danube,
                            paddingTop: 7,
                        }}
                        onPress={() => setShowPSTooltip((showPSToolTip) => !showPSToolTip)}
                    >
                        {IconEnums['question-circle']}
                    </Text>
                </View>
                {showPSTooltip && (
                    <ABAToolTip
                        width="100%"
                        coordinates={{
                            left: tooltipLeft,
                            bottom: formHeight - tooltipBottom,
                        }}
                        onPress={() => setShowPSTooltip((showPSToolTip) => !showPSToolTip)}
                    >
                        <Text
                            style={{
                                fontFamily: 'lato',
                                fontSize: 14,
                                color: ColorEnums.cloudBurst,
                            }}
                        >
                            We use Measurement Decision Theory (MDT) to calculate your MOCA Minute​
                            Performance.
                            {'\n\n'}
                            &bull; MDT takes into account each question's difficulty and your pattern of
                            responses​
                            {'\n\n'}
                            &bull; MDT is not a percent correct or a percentile ranking
                        </Text>
                    </ABAToolTip>
                )}
            </View>
            <View
                style={{
                    flexDirection: 'row',
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderTopColor: ColorEnums.lightGray,
                    borderBottomColor: ColorEnums.lightGray,
                    width: '100%',
                }}
            >
                {Q1Answered > 0 && (
                    <View style={{width: '15%'}}>
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Text
                                style={[
                                    StyleFunctions.containerTextBold(),
                                    {fontSize: 12, width: '100%'},
                                ]}
                            >
                                {MDTValue} MDT
                            </Text>
                        </View>
                    </View>
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        width: setMDTSectionWidth(Q1Answered),
                    }}
                >
                    <Text
                        style={{
                            color: ColorEnums.blueGray,
                            fontSize: 12,
                            width: '90%',
                        }}
                    >
                        {setMDTStatusText(MDTValue)}
                    </Text>
                    <Text
                        style={{
                            color: setMDTIconColor(MDTValue),
                            fontSize: 13,
                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                            textAlign: 'right',
                        }}
                    >
                        {MDTValue >= 0.1 ? IconEnums['check'] : IconEnums['exclamation_triangle']}
                    </Text>
                </View>
            </View>
        </View>
    )
}

export default ABAPerformanceStandard
