import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'

const RankedChoiceSelectionLogic = {
    getRankedChoiceEventOptions: async (eventId) => {
        try {
            const response = await ABAPortalAPI.get(`registration/getRankedChoiceEventOptions`, {
                params: {
                    eventId,
                },
            })
            return response.data
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error fetching ranked choice exam options')
            return null
        }
    },
    saveRankedChoiceSelections: async (eventId, selections) => {
        try {
            await ABAPortalAPI.post(`registration/saveRankedChoiceEventOptions`, {
                ParentEventId: eventId,
                RankedChoiceEventIds: selections,
            })
            return true
        } catch (error) {
            HelperMethods.apiErrorHandler(error, 'Error saving ranked choice selections')
            return false
        }
    },
}

export default RankedChoiceSelectionLogic
