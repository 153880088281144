import React, {Component} from 'react'
import {ActivityIndicator, Platform, Text, View} from 'react-native'

import ABALogo from '../../components/ABACustom/presentation/ABALogo'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ForgotPasswordLogic from '../logic/forgotPasswordLogic'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import LoginHeader from '../../components/presentation/loginHeader'
import LoginFooter from '../../components/presentation/loginFooter'
import LoginReturnLink from '../../components/presentation/loginReturnLink'
import styleFunctions from '../../styles/styleFunctions'

const passwordRequirements = [
    'Be between 7 to 20 characters long',
    'Include at least one number',
    'Have at least one upper case letter',
    'Have at least one lower case letter or special character',
]

export default class ForgotPasswordSetPassword extends Component {
    state = {
        ContactId: '',
        newPassword: '',
        newPasswordConfirm: '',
        InputStatus: {
            NewPassword: GeneralEnums.textInputStatus.default,
            NewPasswordConfirm: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        forgotPasswordSetPasswordContext.setState({
            ContactId: forgotPasswordSetPasswordContext.navigationData.ContactId,
        })
    }

    render() {
        forgotPasswordSetPasswordContext = this
        navigationContext = this
        ForgotPasswordLogic.context = forgotPasswordSetPasswordContext

        HelperMethods.setNavigationData(forgotPasswordSetPasswordContext)

        return (
            <ABAKeyboardAwareScrollView
                contentContainerStyle={[
                    {flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between'},
                    styleFunctions.loginBackground(),
                ]}
            >
                <View>
                    {Platform.OS === 'web' && <LoginHeader />}
                    <ABALogo />

                    <View style={styleFunctions.loginContainer()}>
                        <Text style={styleFunctions.loginHeader()}>Create New Password</Text>

                        <Text
                            style={[
                                styleFunctions.containerText('center'),
                                {fontSize: 14, marginBottom: 20},
                            ]}
                        >
                            Create a password that includes the below criteria.
                        </Text>

                        <ABATextInput
                            onChangeText={(text) => {
                                forgotPasswordSetPasswordContext.setState({newPassword: text})
                            }}
                            onSubmitEditing={() => {
                                forgotPasswordSetPasswordContext.newPasswordConfirmInput.focus()
                            }}
                            placeholder="New Password"
                            reference={(newPasswordInput) => {
                                newPasswordInput &&
                                    newPasswordInput.setNativeProps({style: {fontFamily: 'Lato'}})
                                forgotPasswordSetPasswordContext.newPasswordInput = newPasswordInput
                            }}
                            returnKeyType="next"
                            secureTextEntry
                            textInputStatus={
                                forgotPasswordSetPasswordContext.state.InputStatus.NewPassword
                            }
                            value={this.state.newPassword}
                        />

                        <View
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '80%',
                                marginBottom: 30,
                                paddingLeft: 10,
                            }}
                        >
                            <Text
                                style={{fontFamily: 'Lato', fontSize: 12, color: ColorEnums.cloudBurst}}
                            >
                                Your password must:
                            </Text>
                            {passwordRequirements.map((requirement, i) => {
                                return (
                                    <Text
                                        key={i}
                                        style={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 12,
                                        }}
                                    >{`\u2022 ${requirement}`}</Text>
                                )
                            })}
                        </View>

                        <ABATextInput
                            onChangeText={(text) => {
                                forgotPasswordSetPasswordContext.setState({newPasswordConfirm: text})
                            }}
                            onSubmitEditing={() => {
                                ForgotPasswordLogic.setPassword(
                                    forgotPasswordSetPasswordContext.state.newPassword,
                                    forgotPasswordSetPasswordContext.state.newPasswordConfirm,
                                )
                            }}
                            placeholder="Confirm Password"
                            reference={(newPasswordConfirmInput) => {
                                newPasswordConfirmInput &&
                                    newPasswordConfirmInput.setNativeProps({style: {fontFamily: 'Lato'}})
                                forgotPasswordSetPasswordContext.newPasswordConfirmInput =
                                    newPasswordConfirmInput
                            }}
                            secureTextEntry
                            textInputStatus={
                                forgotPasswordSetPasswordContext.state.InputStatus.NewPasswordConfirm
                            }
                            value={this.state.newPasswordConfirm}
                        />

                        <ABATouchableHighlight
                            buttonStyle={styleFunctions.buttonClick()}
                            icon={'angle-right'}
                            onPressEvent={() => {
                                ForgotPasswordLogic.setPassword(
                                    forgotPasswordSetPasswordContext.state.newPassword,
                                    forgotPasswordSetPasswordContext.state.newPasswordConfirm,
                                )
                            }}
                            text={'Submit'}
                            textStyle={{color: 'white', fontSize: 16}}
                            underlayColor="#66ccff"
                        />
                    </View>
                    <LoginReturnLink />
                </View>
                <LoginFooter />
            </ABAKeyboardAwareScrollView>
        )
    }
}
