import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import SubstanceAbuseLogicStep2 from '../logic/substanceAbuseLogicStep2'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABATextInput from '../../../../components/ABACustom/presentation/ABATextInput'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import SubstanceAbuseSubstance from '../../../../components/presentation/substanceAbuseSubstance'

export default class SubstanceAbuseStep2 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        cert_lastepisode: '',
        cert_SubstanceBeganDate: '',
        cert_name: '',
        cert_LengthofTime: '',
        cert_AvenueofAdministration: '',
        gridIsShowing: true,
        substancesAbusedIDList: [],
        callingApiText: '',
        substancesMissing: false,
        substanceBeganDateError: false,
        InputStatus: {
            cert_lastepisode: GeneralEnums.textInputStatus.default,
            cert_SubstanceBeganDate: GeneralEnums.textInputStatus.default,
            cert_name: GeneralEnums.textInputStatus.default,
            cert_LengthofTime: GeneralEnums.textInputStatus.default,
            cert_AvenueofAdministration: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        Promise.all([
            SubstanceAbuseLogicStep2.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep2.GetSubstanceAbuseSubstancesData(),
        ]).then((SAData) => {
            SubstanceAbuseLogicStep2.PopulateVariables(SAData)
        })
    }

    renderAddSubstanceButton() {
        const {gridIsShowing, substancesAbusedIDList} = this.state
        return (
            <View
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ABATouchableHighlight
                    onPressEvent={
                        gridIsShowing
                            ? () => SubstanceAbuseLogicStep2.ShowAddSubstanceFields()
                            : () => SubstanceAbuseLogicStep2.AddSubstanceToGrid()
                    }
                    text={substancesAbusedIDList.length > 0 ? 'Add Another Substance' : 'Add Substance'}
                />
                {!gridIsShowing && (
                    <ABATouchableHighlight
                        buttonStyle={[styleFunctions.buttonClickInverted(), {marginLeft: 10}]}
                        onPressEvent={() => SubstanceAbuseLogicStep2.CancelAddSubstance()}
                        textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                        text={'Cancel'}
                    />
                )}
            </View>
        )
    }

    renderSubstance(substance) {
        const {cert_SAsubstanceId} = substance
        const {cert_lastepisode} = this.state

        return (
            <SubstanceAbuseSubstance
                key={cert_SAsubstanceId}
                SubstanceAbuseSubstanceID={cert_SAsubstanceId}
                UpdateGrid={SubstanceAbuseLogicStep2.UpdateGrid}
                LastEpisodeDate={cert_lastepisode}
            />
        )
    }

    Save() {
        return SubstanceAbuseLogicStep2.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep2.Previous()
    }

    render() {
        SubstanceAbuseLogicStep2.context = this
        const {
            ComponentWidth,
            InputStatus,
            gridIsShowing,
            substancesAbusedIDList,
            substanceBeganDateError,
        } = this.state
        const primaryColumnWidth = '70%'
        const secondaryColumnWidth = '30%'

        let SubstanceAbuseSubstances
        if (substancesAbusedIDList.length > 0) {
            SubstanceAbuseSubstances = substancesAbusedIDList.map((substance) => {
                return this.renderSubstance(substance)
            })
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 1 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0.15),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0.85),
                            ]}
                        />
                    </View>
                )}

                <Text
                    style={[styleFunctions.paragraphText(), {fontSize: 15, width: '100%', padding: 20}]}
                >
                    The ABA requires the following information so it can assess the specific
                    circumstances of your alcohol abuse and/or illegal use of drugs. You are reminded
                    that as part of your application, you agreed to disqualification from examination and
                    from issuance of a certificate if any of the statements you make are false.
                </Text>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? primaryColumnWidth : '100%'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 15,
                                    width: '100%',
                                    marginRight: 10,
                                    paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                },
                            ]}
                        >
                            The date of your last evidenced episode of substance abuse:
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: ComponentWidth > 600 ? secondaryColumnWidth : '100%'}}>
                        <ABATextInputMask
                            errorMessage="Please enter a valid date"
                            keyboardType="number-pad"
                            marginLeft={0}
                            marginRight={0}
                            maxLength={10}
                            onChangeText={(cert_lastepisode) => this.setState({cert_lastepisode})}
                            options={{mask: '99/99/9999'}}
                            placeholder="(mm/dd/yyyy)"
                            returnKeyType="next"
                            style={styleFunctions.textInput()}
                            textInputStatus={InputStatus.cert_lastepisode}
                            value={this.state.cert_lastepisode}
                            width="100%"
                        />
                    </View>
                </View>
                {gridIsShowing && (
                    <View>
                        {this.state.substancesMissing && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    fontSize: 16,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                At least one substance must be added
                            </Text>
                        )}
                        {this.renderAddSubstanceButton()}
                        <View style={{padding: 10}}>{SubstanceAbuseSubstances}</View>
                    </View>
                )}

                {!gridIsShowing && (
                    <View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                        },
                                    ]}
                                >
                                    Date Substance Abuse Began:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInputMask
                                    errorMessage={
                                        substanceBeganDateError
                                            ? 'The date substance abuse began must be before the last evidenced episode date'
                                            : 'Please enter a valid date'
                                    }
                                    keyboardType="number-pad"
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={10}
                                    onChangeText={(cert_SubstanceBeganDate) =>
                                        this.setState({cert_SubstanceBeganDate})
                                    }
                                    options={{mask: '99/99/9999'}}
                                    placeholder="(mm/dd/yyyy)"
                                    returnKeyType="next"
                                    style={styleFunctions.textInput()}
                                    textInputStatus={InputStatus.cert_SubstanceBeganDate}
                                    value={this.state.cert_SubstanceBeganDate.toString()}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                        },
                                    ]}
                                >
                                    Substance Abused:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInput
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={100}
                                    onChangeText={(cert_name) => this.setState({cert_name})}
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.cert_name}
                                    value={this.state.cert_name.toString()}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                        },
                                    ]}
                                >
                                    Length of Time Substance Abused Prior to Initial Treatment:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInput
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={100}
                                    onChangeText={(cert_LengthofTime) =>
                                        this.setState({cert_LengthofTime})
                                    }
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.cert_LengthofTime}
                                    value={this.state.cert_LengthofTime.toString()}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                }}
                            >
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 15,
                                        },
                                    ]}
                                >
                                    Avenue of Administration: <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInput
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={100}
                                    onChangeText={(cert_AvenueofAdministration) =>
                                        this.setState({cert_AvenueofAdministration})
                                    }
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.cert_AvenueofAdministration}
                                    value={this.state.cert_AvenueofAdministration.toString()}
                                    width="100%"
                                />
                            </View>
                        </View>
                        {this.renderAddSubstanceButton()}
                    </View>
                )}

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        {this.state.callingApiText ? (
                            <Text>{this.state.callingApiText}</Text>
                        ) : (
                            <Text>Loading Substance Abuse Page 1</Text>
                        )}
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
