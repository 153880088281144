import moment from 'moment'
import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'

const SubstanceAbuseSubstanceLogic = {
    context: null,

    GetSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            const {SubstanceAbuseSubstanceID} = this.context.props
            let getParams = {
                params: {
                    SubstanceAbuseSubstanceID,
                },
            }

            ABAPortalAPI.get(`substanceabuseinfo/getSubstanceAbuseSubstance`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Accessing Substance Abuse Substances Record',
                    )
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            const {
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
                cert_SAsubstanceId,
            } = this.context.state
            ABAPortalAPI.post('substanceabuseinfo/updateSubstanceAbuseSubstance', {
                cert_substanceabuseid: substanceAbuseContext.state.SubstanceAbuseID,
                cert_Type: GeneralEnums.SASubstanceTypes.Substance,
                cert_SAsubstanceId,
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Adding Substance Abused')
                    resolve(false)
                })
        })
    },

    DeleteSubstanceFromGrid() {
        return new Promise((resolve) => {
            const {SubstanceAbuseSubstanceID} = this.context.props
            let getParams = {
                params: {
                    SubstanceAbuseSubstanceID,
                },
            }

            ABAPortalAPI.post('substanceabuseinfo/deleteSubstanceAbuseSubstance', {}, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting Substance Abused')
                    resolve(false)
                })
        })
    },

    DeleteSubstance() {
        this.DeleteSubstanceFromGrid().then((response) => {
            if (response) {
                this.context.props.UpdateGrid()
            }
        })
    },

    UpdateSubstanceAbuseSubstance() {
        if (this.ValidateSubstance()) {
            this.AddSubstanceAbuseSubstance().then(() => {
                this.GetSubstanceAbuseSubstance().then((SASData) => {
                    this.PopulateVariables(SASData)
                    const {canEdit} = this.context.state
                    this.context.setState({
                        canEdit: !canEdit,
                    })
                })
            })
        }
    },

    CancelSubstanceAbuseSubstanceChanges() {
        const {cert_SubstanceBeganDate, cert_name, cert_LengthofTime, cert_AvenueofAdministration} =
            this.context.state.originalSubstanceData
        const substanceBeganDate =
            cert_SubstanceBeganDate && moment(new Date(cert_SubstanceBeganDate)).format('MM/DD/YYYY')

        this.context.setState({
            substanceBeganDate,
            cert_name,
            cert_LengthofTime,
            cert_AvenueofAdministration,
            canEdit: false,
        })
    },

    SetSubstanceFieldsToEditable() {
        const {InputStatus} = this.context.state
        for (const key of Object.keys(InputStatus)) {
            InputStatus[key] = GeneralEnums.textInputStatus.default
        }

        this.context.setState({
            canEdit: true,
            InputStatus,
        })
    },

    ValidateSubstance() {
        const {
            InputStatus,
            cert_SubstanceBeganDate,
            cert_name,
            cert_LengthofTime,
            cert_AvenueofAdministration,
        } = this.context.state
        const {LastEpisodeDate} = this.context.props
        const {error, success} = GeneralEnums.textInputStatus
        const cert_lastepisodeDate = moment(new Date(LastEpisodeDate)).format('MM/DD/YYYY')
        const validSubstanceAbuseDate = moment(cert_SubstanceBeganDate, 'MM/DD/YYYY').isSameOrBefore(
            cert_lastepisodeDate,
            'day',
        )

        if (cert_SubstanceBeganDate.length < 10) {
            InputStatus.cert_SubstanceBeganDate = error
            this.context.setState({substanceBeganDateError: false})
        } else if (!LastEpisodeDate || !validSubstanceAbuseDate) {
            InputStatus.cert_SubstanceBeganDate = error
            this.context.setState({substanceBeganDateError: true})
        } else {
            InputStatus.cert_SubstanceBeganDate = success
        }

        InputStatus.cert_name = cert_name ? success : error
        InputStatus.cert_LengthofTime = cert_LengthofTime ? success : error
        InputStatus.cert_AvenueofAdministration = cert_AvenueofAdministration ? success : error

        const validated = !Object.keys(InputStatus).some((input) => InputStatus[input] === 3)
        this.context.setState({InputStatus})
        return validated
    },

    PopulateVariables(SASData) {
        const substanceBeganDate = moment(new Date(SASData.cert_SubstanceBeganDate)).format('MM/DD/YYYY')
        this.context.setState({
            originalSubstanceData: SASData,
            cert_SubstanceBeganDate: substanceBeganDate,
            cert_name: SASData.cert_name,
            cert_LengthofTime: SASData.cert_LengthofTime,
            cert_AvenueofAdministration: SASData.cert_AvenueofAdministration,
            cert_SAsubstanceId: SASData.cert_SAsubstanceId,
        })
    },
}

export default SubstanceAbuseSubstanceLogic
