import React, {useState, useEffect} from 'react'
import {View, Text, Platform} from 'react-native'
import {ColorEnums} from '../../../helpers/generalEnums'
import PropTypes from 'prop-types'

ABADisplayCard = ({children, actionButtons, isExpanded, expandOnClick, title, style,titleActionButton}) => {
    const [expanded, setExpanded] = useState(isExpanded)
    useEffect(() => {
        setExpanded(isExpanded)
    }, [isExpanded])

    const toggle = () => {
        if (expandOnClick) {
            setExpanded(!expanded)
        }
    }

    const defaultStyle = {
        display: 'flex',
        flexDirection: 'column',
        shadowColor: ColorEnums.cloudBurst,
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 2,
        shadowRadius: 5,
        elevation: 2,
        borderRadius: 5,
        margin: 5,
        width: 275,
        ...Platform.select({
            ios: {
                borderWidth: 1,
                borderColor: ColorEnums.cloudBurst,
            },
        }),
    }

    return (
        <View style={[defaultStyle, style]}>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap:"wrap",
                    textAlign: 'center',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    backgroundColor: ColorEnums.cloudBurst,
                }}
            >
                <Text
                    onPress={() => toggle()}
                    style={{
                        textAlign: 'center',
                        color: 'white',
                        flexGrow:1,
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        padding: 10,
                        fontSize: 16,
                    }}
                >
                    {title}
                </Text>
                {titleActionButton}
            </View>
            {expanded && (
                <View
                    style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingBottom: 10,
                        paddingTop: 5,
                    }}
                >
                    {children}
                </View>
            )}
            {expanded && actionButtons && (
                <View
                    style={{
                        marginTop: 'auto',
                        borderColor: ColorEnums.cloudBurst,
                        borderTopWidth: 3,
                        padding: 5,
                    }}
                >
                    {actionButtons}
                </View>
            )}
        </View>
    )
}

ABADisplayCard.defaultProps = {
    isExpanded: true,
}

ABADisplayCard.propTypes = {
    isExpanded: PropTypes.bool,
}

export default ABADisplayCard
