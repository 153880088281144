import React, {Component} from 'react'
import {View, Text, ActivityIndicator, TouchableOpacity} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import ViewReceiptsSummaryLogic from '../logic/viewReceiptsSummaryLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import NavigationHelper from '../../helpers/NavigationHelper'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import PrintedFooter from '../../components/presentation/printedFooter'

export default class Receipt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            results: [],
            CallingApi: false,
            ShowNote: [],
        }
    }

    componentDidMount() {
        ViewReceiptsSummaryLogic.getReceiptsSummary().then((responsedata) => {
            receiptsSummaryContext.setState({results: responsedata})
        })
    }
    render() {
        receiptsSummaryContext = this
        navigationContext = this
        ViewReceiptsSummaryLogic.context = this
        HelperMethods.setNavigationData(receiptsSummaryContext)
        return (
            <NavigationShell context={receiptsSummaryContext} print={true}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer
                        activityText={'Loading Receipts Summary'}
                        containerTitle={'RECEIPT SUMMARY'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                paddingBottom: 10,
                                paddingTop: 30,
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.lightGray,
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '25%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Date
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '50%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Description
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {
                                        width: '25%',
                                        fontFamily: 'Lato',
                                        fontSize: 14,
                                        minWidth: 75,
                                        textAlign: 'center',
                                    },
                                ]}
                            >
                                Amount
                            </Text>
                        </View>
                        {receiptsSummaryContext.state.results.map((option, index) => {
                            return (
                                <View
                                    key={'item' + index}
                                    style={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.backgroundGray,
                                    }}
                                >
                                    <TouchableOpacity
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignSelf: 'center',
                                            paddingBottom: 15,
                                            paddingTop: 10,
                                        }}
                                        onPress={() => {
                                            NavigationHelper.GoToPage(
                                                GeneralEnums.navigationType.push,
                                                '/receiptDetails',
                                                {
                                                    ContactId: option.CustomerID,
                                                    InvoiceID: option.InvoiceID,
                                                    Source: option.Source,
                                                },
                                            )
                                        }}
                                        testID={`receipt${option.Description}`}
                                    >
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 14,
                                                    width: '25%',
                                                    paddingRight: 5,
                                                    minWidth: 90,
                                                },
                                            ]}
                                        >
                                            {option.PayDate}
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {fontSize: 14, width: '50%', paddingRight: 1},
                                            ]}
                                        >
                                            {option.Description}
                                        </Text>
                                        <View style={{flexDirection: 'row', width: '25%', minWidth: 85}}>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingRight: 2,
                                                        width: '80%',
                                                        textAlign: 'right',
                                                    },
                                                ]}
                                            >
                                                {option.TotalAmount}
                                            </Text>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        width: '20%',
                                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                        color: ColorEnums.danube,
                                                    },
                                                ]}
                                            >
                                                {IconEnums['chevron-right']}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            )
                        })}
                        <View style={{borderTopWidth: 3, borderTopColor: ColorEnums.cloudBurst}} />
                    </ABAContainer>
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
