import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import NonStandardExamLogicStep4 from '../logic/nonStandardExamStep4Logic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABAAffirmationRadio from '../../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABARequiredAsterisk from '../../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },
})

export default class NonStandardExamStep4 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        exam_assistiveDevicesAvailable: null,
        exam_currentlyUseAssistiveDevices: null,
        exam_currentyUseAssistveDevicesDescription: '',
        exam_howLongAssistiveDevicesUsed: '',
        exam_symptomsAndLimitationsBefore: '',
        exam_symptomsAndLimitationsAfter: '',
        exam_symptomsAndLimitationsUnaffected: '',
        exam_unaffectedExplanationForMajorLifeActivity: '',
        exam_mitigatingMeasuresOverTime: null,
        exam_mitigatingMeasuresOverTimeDesc: '',
        exam_changeMitigatingMeasures: '',
        exam_mitigatingMeasuresSideEffects: '',
        exam_explainMitigatingMeasuresEffectLimitation: '',
        exam_mitigatingMeasuresForExam: null,
        exam_mitigatingMeasuresForExamDesc: '',
        exam_mitigatingMeasureAtEval: null,
        exam_mitigatingMeasureAtEvalDesc: '',
        InputStatus: {
            exam_assistiveDevicesAvailable: GeneralEnums.textInputStatus.default,
            exam_currentlyUseAssistiveDevices: GeneralEnums.textInputStatus.default,
            exam_currentyUseAssistveDevicesDescription: GeneralEnums.textInputStatus.default,
            exam_howLongAssistiveDevicesUsed: GeneralEnums.textInputStatus.default,
            exam_symptomsAndLimitationsBefore: GeneralEnums.textInputStatus.default,
            exam_symptomsAndLimitationsAfter: GeneralEnums.textInputStatus.default,
            exam_symptomsAndLimitationsUnaffected: GeneralEnums.textInputStatus.default,
            exam_unaffectedExplanationForMajorLifeActivity: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasuresOverTime: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasuresOverTimeDesc: GeneralEnums.textInputStatus.default,
            exam_changeMitigatingMeasures: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasuresSideEffects: GeneralEnums.textInputStatus.default,
            exam_explainMitigatingMeasuresEffectLimitation: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasuresForExam: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasuresForExamDesc: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasureAtEval: GeneralEnums.textInputStatus.default,
            exam_mitigatingMeasureAtEvalDesc: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        NonStandardExamLogicStep4.GetNSEData().then((NSEData) => {
            NonStandardExamLogicStep4.PopulateStateVariables(NSEData)
        })
    }

    Save() {
        return NonStandardExamLogicStep4.SaveLogic()
    }

    Previous() {
        return NonStandardExamLogicStep4.Previous()
    }

    render() {
        NonStandardExamLogicStep4.context = this
        const {InputStatus, ComponentWidth} = this.state
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 2 of 5</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.4),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.6),
                            ]}
                        />
                    </View>
                )}
                <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, padding: 15, width: '90%', fontWeight: 'bold'},
                        ]}
                    >
                        The information below pertaining to your use of any available assistive devices
                        and mitigating measures will be used solely to determine the reasonableness of
                        the exam accommodation you have requested for the ABA exam. This information will
                        not be used in any respect to determine if you are an individual with a
                        disability protected under the Americans with Disabilities Act, as amended.
                    </Text>
                </View>
                <View>
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                            ]}
                        >
                            Are assistive devices or mitigating measure(s) (e.g. medications) available
                            that could control or correct the symptoms or limitations of your impairment?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={NonStandardExamLogicStep4.DevicesAvailableYesSelected}
                            rightOptionContent="No"
                            rightOptionOnPress={NonStandardExamLogicStep4.DevicesAvailableNoSelected}
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={this.state.exam_assistiveDevicesAvailable}
                            validationStatus={InputStatus.exam_assistiveDevicesAvailable}
                        />
                    </View>
                </View>
                {this.state.exam_assistiveDevicesAvailable && (
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                ]}
                            >
                                Do you currently use any mitigating measure(s) (including a medication,
                                assistive device, or prosthetic) or compensating behavior(s) in your
                                clinical practice to control or correct the symptoms or limitations of
                                your impairment?
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                            <ABAAffirmationRadio
                                leftOptionContent="Yes"
                                leftOptionOnPress={
                                    NonStandardExamLogicStep4.CurrentlyUsingDevicesYesSelected
                                }
                                rightOptionContent="No"
                                rightOptionOnPress={
                                    NonStandardExamLogicStep4.CurrentlyUsingDevicesNoSelected
                                }
                                optionValues={{leftValue: true, rightValue: false}}
                                selectedValue={this.state.exam_currentlyUseAssistiveDevices}
                                validationStatus={InputStatus.exam_currentlyUseAssistiveDevices}
                            />
                        </View>
                        {this.state.exam_currentlyUseAssistiveDevices && (
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                    ]}
                                >
                                    Please explain.
                                    <ABARequiredAsterisk />
                                </Text>
                                <ABATextInputMultiline
                                    maxLength={2000}
                                    numberOfLines={8}
                                    onChangeText={(exam_currentyUseAssistveDevicesDescription) =>
                                        this.setState({exam_currentyUseAssistveDevicesDescription})
                                    }
                                    value={this.state.exam_currentyUseAssistveDevicesDescription}
                                    textInputStatus={
                                        InputStatus.exam_currentyUseAssistveDevicesDescription
                                    }
                                />

                                {/* Accordion Questions */}
                                <ABAAccordion
                                    title="Mitigating Measures"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_howLongAssistiveDevicesUsed',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                How long have you used these mitigating measure(s) or
                                                compensating behavior(s)?
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_howLongAssistiveDevicesUsed) =>
                                                    this.setState({exam_howLongAssistiveDevicesUsed})
                                                }
                                                value={this.state.exam_howLongAssistiveDevicesUsed}
                                                textInputStatus={
                                                    InputStatus.exam_howLongAssistiveDevicesUsed
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Symptoms Before Measures"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_symptomsAndLimitationsBefore',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                What symptoms and limitations did you experience because
                                                of your impairment before using these mitigating
                                                measure(s) or compensating behavior(s)?
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_symptomsAndLimitationsBefore) =>
                                                    this.setState({exam_symptomsAndLimitationsBefore})
                                                }
                                                value={this.state.exam_symptomsAndLimitationsBefore}
                                                textInputStatus={
                                                    InputStatus.exam_symptomsAndLimitationsBefore
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Symptoms After Measures"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_symptomsAndLimitationsAfter',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                What effect does the current use of these mitigating
                                                measure(s) or compensating behavior(s) have on the
                                                symptoms and limitations of your impairment, i.e. how
                                                well does it control the symptoms and limitations of your
                                                impairment?
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_symptomsAndLimitationsAfter) =>
                                                    this.setState({exam_symptomsAndLimitationsAfter})
                                                }
                                                value={this.state.exam_symptomsAndLimitationsAfter}
                                                textInputStatus={
                                                    InputStatus.exam_symptomsAndLimitationsAfter
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Unaffected Symptoms"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_symptomsAndLimitationsUnaffected',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Identify what symptoms, limitations or complications from
                                                your impairment that are unaffected or uncontrolled by
                                                the mitigating measure(s) or compensating behavior(s).
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_symptomsAndLimitationsUnaffected) =>
                                                    this.setState({
                                                        exam_symptomsAndLimitationsUnaffected,
                                                    })
                                                }
                                                value={this.state.exam_symptomsAndLimitationsUnaffected}
                                                textInputStatus={
                                                    InputStatus.exam_symptomsAndLimitationsUnaffected
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Unaffected Symptoms Impact"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_unaffectedExplanationForMajorLifeActivity',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Please explain if any of these unaffected or uncontrolled
                                                symptoms, limitations or complications limit your ability
                                                to perform any major life activity.
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(
                                                    exam_unaffectedExplanationForMajorLifeActivity,
                                                ) =>
                                                    this.setState({
                                                        exam_unaffectedExplanationForMajorLifeActivity,
                                                    })
                                                }
                                                value={
                                                    this.state
                                                        .exam_unaffectedExplanationForMajorLifeActivity
                                                }
                                                textInputStatus={
                                                    InputStatus.exam_unaffectedExplanationForMajorLifeActivity
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Mitigating Measures Over Time"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_mitigatingMeasuresOverTime',
                                        'exam_mitigatingMeasuresOverTimeDesc',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <View>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {
                                                            fontSize: 14,
                                                            paddingTop: 15,
                                                            fontWeight: 'bold',
                                                            width: '90%',
                                                        },
                                                    ]}
                                                >
                                                    Do any of these mitigating measure(s) or compensating
                                                    behavior(s) become less effective over time or under
                                                    certain conditions?
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    width: '50%',
                                                    alignSelf: 'center',
                                                    paddingTop: 25,
                                                }}
                                            >
                                                <ABAAffirmationRadio
                                                    leftOptionContent="Yes"
                                                    leftOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresOverTimeYesSelected
                                                    }
                                                    rightOptionContent="No"
                                                    rightOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresOverTimeNoSelected
                                                    }
                                                    optionValues={{leftValue: true, rightValue: false}}
                                                    selectedValue={
                                                        this.state.exam_mitigatingMeasuresOverTime
                                                    }
                                                    validationStatus={
                                                        InputStatus.exam_mitigatingMeasuresOverTime
                                                    }
                                                />
                                            </View>
                                            {this.state.exam_mitigatingMeasuresOverTime && (
                                                <View>
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styleFunctions.paragraphText(),
                                                                {
                                                                    fontSize: 14,
                                                                    paddingTop: 15,
                                                                    fontWeight: 'bold',
                                                                    width: '90%',
                                                                },
                                                            ]}
                                                        >
                                                            Please explain.
                                                            <ABARequiredAsterisk />
                                                        </Text>
                                                    </View>
                                                    <ABATextInputMultiline
                                                        maxLength={2000}
                                                        numberOfLines={8}
                                                        onChangeText={(
                                                            exam_mitigatingMeasuresOverTimeDesc,
                                                        ) =>
                                                            this.setState({
                                                                exam_mitigatingMeasuresOverTimeDesc,
                                                            })
                                                        }
                                                        value={
                                                            this.state
                                                                .exam_mitigatingMeasuresOverTimeDesc
                                                        }
                                                        textInputStatus={
                                                            InputStatus.exam_mitigatingMeasuresOverTimeDesc
                                                        }
                                                    />
                                                </View>
                                            )}
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Changes to Mitigating Measures"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_changeMitigatingMeasures',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Please explain if you had to change mitigating measure(s)
                                                or compensating behavior(s) because previous ones became
                                                less effective.
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_changeMitigatingMeasures) =>
                                                    this.setState({exam_changeMitigatingMeasures})
                                                }
                                                value={this.state.exam_changeMitigatingMeasures}
                                                textInputStatus={
                                                    InputStatus.exam_changeMitigatingMeasures
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Side Effects"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_explainMitigatingMeasuresEffectLimitation',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Please explain if your use of any of these mitigating
                                                measure(s) or compensating behavior(s) result in any side
                                                effects or complications, either individually or in
                                                combination with the use of other mitigating measure(s)
                                                or compensating behavior(s)?
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(
                                                    exam_explainMitigatingMeasuresEffectLimitation,
                                                ) =>
                                                    this.setState({
                                                        exam_explainMitigatingMeasuresEffectLimitation,
                                                    })
                                                }
                                                value={
                                                    this.state
                                                        .exam_explainMitigatingMeasuresEffectLimitation
                                                }
                                                textInputStatus={
                                                    InputStatus.exam_explainMitigatingMeasuresEffectLimitation
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Side Effect Complications"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_mitigatingMeasuresSideEffects',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <Text
                                                style={[
                                                    styleFunctions.paragraphText(),
                                                    {
                                                        fontSize: 14,
                                                        paddingTop: 15,
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Please explain if any of these side effects or
                                                complications limit your ability to perform a major life
                                                activity.
                                            </Text>
                                            <ABATextInputMultiline
                                                maxLength={2000}
                                                numberOfLines={8}
                                                onChangeText={(exam_mitigatingMeasuresSideEffects) =>
                                                    this.setState({exam_mitigatingMeasuresSideEffects})
                                                }
                                                value={this.state.exam_mitigatingMeasuresSideEffects}
                                                textInputStatus={
                                                    InputStatus.exam_mitigatingMeasuresSideEffects
                                                }
                                            />
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Mitigating Measures For Exam"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_mitigatingMeasuresForExam',
                                        'exam_mitigatingMeasuresForExamDesc',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <View>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {
                                                            fontSize: 14,
                                                            paddingTop: 15,
                                                            fontWeight: 'bold',
                                                            width: '90%',
                                                        },
                                                    ]}
                                                >
                                                    Will you use any mitigating measure(s) or
                                                    compensating behavior(s) when you take the
                                                    examination for which you are requesting
                                                    accommodation?
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    width: '50%',
                                                    alignSelf: 'center',
                                                    paddingTop: 25,
                                                }}
                                            >
                                                <ABAAffirmationRadio
                                                    leftOptionContent="Yes"
                                                    leftOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresForExamYesSelected
                                                    }
                                                    rightOptionContent="No"
                                                    rightOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresForExamNoSelected
                                                    }
                                                    optionValues={{leftValue: true, rightValue: false}}
                                                    selectedValue={
                                                        this.state.exam_mitigatingMeasuresForExam
                                                    }
                                                    validationStatus={
                                                        InputStatus.exam_mitigatingMeasuresForExam
                                                    }
                                                />
                                            </View>
                                            {this.state.exam_mitigatingMeasuresForExam && (
                                                <View>
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styleFunctions.paragraphText(),
                                                                {
                                                                    fontSize: 14,
                                                                    paddingTop: 15,
                                                                    fontWeight: 'bold',
                                                                    width: '90%',
                                                                },
                                                            ]}
                                                        >
                                                            Please explain.
                                                            <ABARequiredAsterisk />
                                                        </Text>
                                                    </View>
                                                    <ABATextInputMultiline
                                                        maxLength={2000}
                                                        numberOfLines={8}
                                                        onChangeText={(
                                                            exam_mitigatingMeasuresForExamDesc,
                                                        ) =>
                                                            this.setState({
                                                                exam_mitigatingMeasuresForExamDesc,
                                                            })
                                                        }
                                                        value={
                                                            this.state.exam_mitigatingMeasuresForExamDesc
                                                        }
                                                        textInputStatus={
                                                            InputStatus.exam_mitigatingMeasuresForExamDesc
                                                        }
                                                    />
                                                </View>
                                            )}
                                        </View>
                                    }
                                />
                                <ABAAccordion
                                    title="Mitigating Measures At Evaluation"
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.accordionComponentTitle}
                                    accordionButtonStyle={styles.accordionTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={NonStandardExamLogicStep4.InputStateAccordianToggle(
                                        'exam_mitigatingMeasureAtEval',
                                        'exam_mitigatingMeasureAtEvalDesc',
                                    )}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    isRequired={true}
                                    accordionContent={
                                        <View>
                                            <View>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {
                                                            fontSize: 14,
                                                            paddingTop: 15,
                                                            fontWeight: 'bold',
                                                            width: '90%',
                                                        },
                                                    ]}
                                                >
                                                    Did you use any mitigating measure(s) or compensating
                                                    behavior(s) at the time you were tested to evaluate
                                                    the disability for which you are requesting
                                                    accommodation?
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    width: '50%',
                                                    alignSelf: 'center',
                                                    paddingTop: 25,
                                                }}
                                            >
                                                <ABAAffirmationRadio
                                                    leftOptionContent="Yes"
                                                    leftOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresAtEvalYesSelected
                                                    }
                                                    rightOptionContent="No"
                                                    rightOptionOnPress={
                                                        NonStandardExamLogicStep4.MeasuresAtEvalNoSelected
                                                    }
                                                    optionValues={{leftValue: true, rightValue: false}}
                                                    selectedValue={
                                                        this.state.exam_mitigatingMeasureAtEval
                                                    }
                                                    validationStatus={
                                                        InputStatus.exam_mitigatingMeasureAtEval
                                                    }
                                                />
                                            </View>
                                            {this.state.exam_mitigatingMeasureAtEval !== null && (
                                                <View>
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styleFunctions.paragraphText(),
                                                                {
                                                                    fontSize: 14,
                                                                    paddingTop: 15,
                                                                    fontWeight: 'bold',
                                                                    width: '90%',
                                                                },
                                                            ]}
                                                        >
                                                            {!this.state.exam_mitigatingMeasureAtEval &&
                                                                'Please explain why you did not use any mitigating measure or compensating behavior during your testing.'}
                                                            {this.state.exam_mitigatingMeasureAtEval &&
                                                                'Please specify each mitigating measure or compensating behavior you used.'}
                                                            <ABARequiredAsterisk />
                                                        </Text>
                                                    </View>
                                                    <ABATextInputMultiline
                                                        maxLength={2000}
                                                        numberOfLines={8}
                                                        onChangeText={(
                                                            exam_mitigatingMeasureAtEvalDesc,
                                                        ) =>
                                                            this.setState({
                                                                exam_mitigatingMeasureAtEvalDesc,
                                                            })
                                                        }
                                                        value={
                                                            this.state.exam_mitigatingMeasureAtEvalDesc
                                                        }
                                                        textInputStatus={
                                                            InputStatus.exam_mitigatingMeasureAtEvalDesc
                                                        }
                                                    />
                                                </View>
                                            )}
                                        </View>
                                    }
                                />
                            </View>
                        )}
                    </View>
                )}

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Non-Standard Exam Page 2</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
