import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep6 from '../logic/substanceAbuseLogicStep6'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABADropdown from '../../../../components/ABACustom/presentation/ABADropdown'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import Reference from '../../../presentation/reference'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },
})

export default class SubstanceAbuseStep6 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        afterCareStatuses: [],
        cert_aftercare: '-1',
        cert_aftercarecompleted: '',
        cert_aftercarecompleteddue: '',
        addNewFacilityReference: false,
        afterCareReferenceIDlist: [],
        collapsedReference: null,
        InputStatus: {
            cert_aftercare: GeneralEnums.textInputStatus.default,
            cert_aftercarecompleted: GeneralEnums.textInputStatus.default,
            cert_aftercarecompleteddue: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        this.setState({CallingApi: true})
        Promise.all([
            SubstanceAbuseLogicStep6.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep6.GetAfterCareStatuses(),
            SubstanceAbuseLogicStep6.GetAfterCareReferences(),
        ])
            .then((SAData) => {
                SubstanceAbuseLogicStep6.PopulateVariables(SAData)
            })
            .finally(() => {
                this.setState({CallingApi: false})
            })
    }

    renderReference(reference) {
        const {cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName} = reference
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`
        return (
            <View key={cert_referenceId}>
                <ABAAccordion
                    title={referenceName}
                    accordianID={cert_referenceId}
                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                    accordionTitleStyle={styles.accordionComponentTitle}
                    accordionButtonStyle={styles.accordionTitlebutton}
                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                    accordionExpanded={this.state.collapsedReference === cert_referenceId}
                    toggleCallback={(refId, expanded) =>
                        expanded && this.setState({collapsedReference: refId})
                    }
                    accordionUnderlayColor={ColorEnums.white}
                    accordionIconLeft={{
                        collapsed: 'chevron-down',
                        expanded: 'chevron-up',
                    }}
                    accordionContent={
                        <Reference
                            referenceID={cert_referenceId}
                            isConfirmationPage={this.props.isConfirmationPage}
                            ProgramType={this.props.ProgramType}
                            IsSubstanceAbuseRef={true}
                            RenderSaveReferenceButton={false}
                            RenderEditButtons={true}
                            saveCallback={SubstanceAbuseLogicStep6.UpdateGrid}
                            cancelCallback={SubstanceAbuseLogicStep6.CancelAddReference}
                        />
                    }
                />
            </View>
        )
    }

    renderDateInput() {
        if (this.state.cert_aftercare == '0') {
            return (
                <ABATextInputMask
                    errorMessage="Please enter a valid date"
                    keyboardType="number-pad"
                    marginLeft={0}
                    marginRight={0}
                    maxLength={10}
                    onChangeText={(cert_aftercarecompleted) => this.setState({cert_aftercarecompleted})}
                    options={{mask: '99/99/9999'}}
                    placeholder="mm/dd/yyyy"
                    returnKeyType="next"
                    style={styleFunctions.textInput()}
                    textInputStatus={this.state.InputStatus.cert_aftercarecompleted}
                    value={this.state.cert_aftercarecompleted}
                    width="40%"
                />
            )
        }

        if (this.state.cert_aftercare == '1') {
            return (
                <ABATextInputMask
                    errorMessage="Please enter a valid date"
                    keyboardType="number-pad"
                    marginLeft={0}
                    marginRight={0}
                    maxLength={10}
                    onChangeText={(cert_aftercarecompleteddue) =>
                        this.setState({cert_aftercarecompleteddue})
                    }
                    options={{mask: '99/99/9999'}}
                    placeholder="mm/dd/yyyy"
                    returnKeyType="next"
                    style={styleFunctions.textInput()}
                    textInputStatus={this.state.InputStatus.cert_aftercarecompleteddue}
                    value={this.state.cert_aftercarecompleteddue}
                    width="40%"
                />
            )
        }
    }

    Save() {
        return SubstanceAbuseLogicStep6.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep6.Previous()
    }

    hideContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: false})
    }

    showContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: true})
    }

    render() {
        SubstanceAbuseLogicStep6.context = this
        const {ComponentWidth, InputStatus, cert_aftercare, afterCareReferenceIDlist} = this.state
        let afterCareReferences = <View />
        const referenceRequired = cert_aftercare != '2' && cert_aftercare != '-1'
        if (afterCareReferenceIDlist.length > 0) {
            afterCareReferences = afterCareReferenceIDlist.map((reference) => {
                return this.renderReference(reference)
            })
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 5 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.72),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.28),
                            ]}
                        />
                    </View>
                )}
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {
                            fontSize: 14,
                            paddingTop: 15,
                            fontWeight: 'bold',
                            width: '90%',
                        },
                    ]}
                >
                    Are you currently participating in an aftercare or monitoring program?
                    <ABARequiredAsterisk />
                </Text>
                <ABADropdown
                    style={{marginBottom: 1}}
                    placeholder="Select an option"
                    placeholderItemValue={'-1'}
                    options={this.state.afterCareStatuses}
                    width="100%"
                    selectedValue={cert_aftercare}
                    onChange={(cert_aftercare) =>
                        SubstanceAbuseLogicStep6.SetAfterCareStatus(cert_aftercare)
                    }
                    validationStatus={InputStatus.cert_aftercare}
                />
                {cert_aftercare == '0' && (
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 14,
                                    paddingTop: 15,
                                    width: '90%',
                                },
                            ]}
                        >
                            You responded that you have completed your aftercare or monitoring program.
                            <Text style={{fontWeight: 'bold'}}>
                                {' '}
                                Please indicate the date when you completed your aftercare or monitoring
                                program.
                                <ABARequiredAsterisk />
                            </Text>
                        </Text>
                    </View>
                )}
                {cert_aftercare == '1' && (
                    <View>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 14,
                                    paddingTop: 15,
                                    width: '90%',
                                },
                            ]}
                        >
                            You responded that you are currently participating in an aftercare or
                            monitoring program.
                            <Text style={{fontWeight: 'bold'}}>
                                {' '}
                                Please indicate the date when you are due to complete your aftercare or
                                monitoring program.
                                <ABARequiredAsterisk />
                            </Text>
                        </Text>
                    </View>
                )}
                {referenceRequired && (
                    <View style={{paddingTop: 10, paddingBottom: 10}}>
                        {this.renderDateInput()}
                        {this.state.referencesMissing && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    fontSize: 16,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                At least one reference must be added
                            </Text>
                        )}
                        <View
                            style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    marginBottom: ComponentWidth > 600 ? 0 : 10,
                                }}
                            >
                                <ABATouchableHighlight
                                    onPressEvent={() => {
                                        this.hideContinue()
                                        this.setState({
                                            addNewFacilityReference: true,
                                            referencesMissing: false,
                                        })
                                    }}
                                    text="Add Aftercare or Monitoring Facility"
                                />
                            </View>
                            {this.state.addNewFacilityReference && (
                                <ABATouchableHighlight
                                    buttonStyle={[
                                        styleFunctions.buttonClickInverted(),
                                        {
                                            marginLeft: ComponentWidth > 600 ? 10 : 0,
                                        },
                                    ]}
                                    onPressEvent={() => {
                                        this.showContinue()
                                        this.setState({
                                            addNewFacilityReference: false,
                                        })
                                    }}
                                    textStyle={{
                                        color: ColorEnums.danube,
                                        fontFamily: 'Lato',
                                        fontSize: 14,
                                    }}
                                    text={'Cancel'}
                                />
                            )}
                        </View>
                    </View>
                )}

                {this.state.addNewFacilityReference && referenceRequired && (
                    <Reference
                        referenceID="-1"
                        NewReference={true}
                        isConfirmationPage={this.props.isConfirmationPage}
                        ProgramType={this.props.ProgramType}
                        ReferenceType={GeneralEnums.cert_referenceType.AfterCare_Location}
                        IsSubstanceAbuseRef={true}
                        RenderEditButtons={true}
                        saveCallback={SubstanceAbuseLogicStep6.UpdateGrid}
                    />
                )}
                {!this.state.addNewFacilityReference && referenceRequired && (
                    <View>{afterCareReferences}</View>
                )}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 5</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
