import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import mocaAnnualPaymentLogic from '../logic/mocaAnnualPaymentLogic'
import styleFunctions from '../../../styles/styleFunctions'
import InvoiceConfirmation from '../selectionpayment/presentation/invoiceConfirmation'

export default class MOCAAnnualPayment extends Component {
    state = {
        CallingApi: false,
    }

    componentDidMount() {
        //whatever logic needs to be done (if any) when this component loads
    }

    Save() {
        return this.invConfRef.Save()
    }

    Previous() {
        return new Promise((resolve) => {
            resolve(false)
        })
    }

    render() {
        mocaAnnualPaymentContext = this
        mocaAnnualPaymentLogic.context = mocaAnnualPaymentContext

        return (
            <InvoiceConfirmation
                ProgramType={this.props.ProgramType}
                Order={this.props.Order}
                CR2ID={this.props.CR2ID}
                SubmittedSteps={this.props.SubmittedSteps}
                PaymentAndRegistrationContext={this}
                SetContinueVisibility={this.props.SetContinueVisibility}
                MOCA={true}
                ref={(comp) => {
                    this.invConfRef = comp
                }}
                NavigationData={this.props.NavigationData}
                ContainerTitle={this.props.ContainerTitle}
            />
        )
    }
}
0
