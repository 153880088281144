import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import substanceAbuseLogicStep1 from '../logic/substanceAbuseLogicStep1'
import styleFunctions from '../../../../styles/styleFunctions'
import ABADropdown from '../../../ABACustom/presentation/ABADropdown'
import {GeneralEnums, ColorEnums} from '../../../../helpers/generalEnums'
import ABAHyperLink from '../../../ABACustom/presentation/ABAHyperLink'
import ABAAffirmationRadio from '../../../ABACustom/presentation/ABAAffirmationRadio'

export default class SubstanceAbuseStep1 extends Component {
    state = {
        CallingApi: false,
        AlcoholAbuse: 'null',
        DrugAbuse: 'null',
        SAReponseData: null,
        aba_substanceabuse1: null,
        PreviousSAExists: null,
        cert_lastepisode: null,
        cert_relapse1: null,
        InputStatus: {
            AlcoholAbuseStatus: GeneralEnums.textInputStatus.default,
            DrugAbuseStatus: GeneralEnums.textInputStatus.default,
            cert_relapse1: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        if (this.props.SubmittedSteps == 1) {
            this.props.SetPreviousVisibility(false)
        }
        substanceAbuseLogicStep1.GetSubstanceAbuseBeginData().then((responsedata) => {
            substanceAbuseLogicStep1.PopulateVariables(responsedata)
        })
    }

    Save() {
        return substanceAbuseLogicStep1.SaveLogic()
    }

    Previous() {
        return substanceAbuseLogicStep1.Previous()
    }

    render() {
        substanceAbuseContextStep1 = this
        substanceAbuseLogicStep1.context = substanceAbuseContextStep1
        const {aba_substanceabuse1, PreviousSAExists, DrugAbuse, AlcoholAbuse, InputStatus} = this.state
        return (
            <View>
                {!!aba_substanceabuse1 && (
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, color: ColorEnums.pomegranate, paddingTop: 20},
                        ]}
                    >
                        The ABA's records indicate that you have a previous history of substance abuse.
                        The ABA requires the following information so it can assess the specific
                        circumstances of your alcohol abuse and/or illegal use of drugs. You are reminded
                        that as part of your application, you agreed to disqualification from examination
                        and from issuance of a certificate if any of the statements you make are false.
                    </Text>
                )}
                <Text style={[styleFunctions.containerTextBold(), {paddingTop: 20, paddingBottom: 20}]}>
                    Alcohol and Drug Use
                </Text>
                <Text style={[styleFunctions.containerText(), {fontSize: 14}]}>
                    The ABA supports the intent of the Americans with Disabilities Act, which protects
                    individuals with a history of alcohol abuse who are rehabilitated, and protects
                    former drug users who currently do not use drugs illegally.  Please see our policy
                    regarding alcohol and substance abuse in the
                    <ABAHyperLink url="https://theaba.org/what%20we%20do.html" label=" Policy Book" />.
                </Text>
                <Text style={[styleFunctions.containerTextBold(), {paddingTop: 40, paddingBottom: 15}]}>
                    Please complete the following statements:
                </Text>

                <ABADropdown
                    style={{marginBottom: 1}}
                    label="Alcohol Abuse *"
                    placeholder="Alcohol Abuse *"
                    options={[
                        {label: 'No, I currently do not abuse Alcohol', value: '0'},
                        {label: 'Yes, I currently abuse Alcohol', value: '1'},
                    ]}
                    width="100%"
                    selectedValue={substanceAbuseContextStep1.state.AlcoholAbuse.toString()}
                    onChange={(value) =>
                        substanceAbuseLogicStep1.CheckRequiredDropdown('AlcoholAbuse', value)
                    }
                    validationStatus={InputStatus.AlcoholAbuseStatus}
                />
                <ABADropdown
                    style={{marginBottom: 1}}
                    label="Illegal Drug Use *"
                    placeholder="Illegal Drug Use *"
                    options={[
                        {label: 'No, I currently do not use Drugs illegally', value: '0'},
                        {label: 'Yes, I currently use Drugs illegally', value: '1'},
                    ]}
                    width="100%"
                    selectedValue={substanceAbuseContextStep1.state.DrugAbuse.toString()}
                    onChange={(value) =>
                        substanceAbuseLogicStep1.CheckRequiredDropdown('DrugAbuse', value)
                    }
                    validationStatus={InputStatus.DrugAbuseStatus}
                />
                {!!aba_substanceabuse1 &&
                    !!PreviousSAExists &&
                    DrugAbuse === '0' &&
                    AlcoholAbuse === '0' && (
                        <View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 14,
                                            paddingTop: 15,
                                            fontWeight: 'bold',
                                            width: '90%',
                                        },
                                    ]}
                                >
                                    <Text style={{color: ColorEnums.pomegranate}}>*</Text>
                                    {`Have you had a relapse or any additional substance use since ${this.state.cert_lastepisode}`}
                                </Text>
                            </View>
                            <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                                <ABAAffirmationRadio
                                    leftOptionContent="Yes"
                                    leftOptionOnPress={substanceAbuseLogicStep1.CertRelapse1YesSelected}
                                    rightOptionContent="No"
                                    rightOptionOnPress={substanceAbuseLogicStep1.CertRelapse1NoSelected}
                                    optionValues={{leftValue: '1', rightValue: '0'}}
                                    selectedValue={this.state.cert_relapse1}
                                    validationStatus={InputStatus.cert_relapse1}
                                />
                            </View>
                        </View>
                    )}
                {substanceAbuseContextStep1.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Information</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
