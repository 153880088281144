import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import generalIPRLogic from '../logic/generalIPRLogic'
import styleFunctions from '../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import ABAAffirmationRadio from '../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABATextInputMultiline from '../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABARequiredAsterisk from '../../ABACustom/presentation/ABARequiredAsterisk'

export default class GeneralIPR extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        NoteText: '',
        CRT_FullName: '',
        Crt_ProgramType: null,
        ReleaseOptionStatus: GeneralEnums.textInputStatus.default,
        NoteTextStatus: GeneralEnums.textInputStatus.default,
        IPRReleaseData: null,
        IPRTextDisplay: null,
    }

    componentDidMount() {
        generalIPRLogic.GetIPRReleaseData().then((IPRData) => {
            generalIPRLogic.PopulateRelease(IPRData)
            generalIPRLogic.GetIPRText(IPRData).then((IPRText) => {
                generalIPRLogic.context.setState({IPRTextDisplay: IPRText})
            })
        })
    }

    Save() {
        return generalIPRLogic.SaveLogic()
    }

    render() {
        generalIPRContext = this
        generalIPRLogic.context = generalIPRContext
        const practiceName =
            generalIPRContext.state.Crt_ProgramType == 500
                ? 'anesthesiology and/or any of its subspecialties'
                : generalIPRContext.state.CRT_FullName

        return (
            <View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 5,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, width: '90%'},
                        ]}
                    >
                        {generalIPRContext.state.IPRTextDisplay}
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingTop: 10,
                        paddingBottom: 25,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, fontWeight: 'bold', paddingTop: 10, width: '90%'},
                        ]}
                    >
                        Are you capable of performing independently in the practice of {practiceName}{' '}
                        without accommodation or with reasonable accommodation?
                        <ABARequiredAsterisk />
                    </Text>
                    <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={generalIPRLogic.ReleaseOptionYesSelected}
                            rightOptionContent="No"
                            rightOptionOnPress={generalIPRLogic.ReleaseOptionNoSelected}
                            optionValues={{leftValue: true, rightValue: false}}
                            selectedValue={generalIPRContext.state.ReleaseOption}
                            validationStatus={generalIPRContext.state.ReleaseOptionStatus}
                        />
                    </View>
                </View>
                {generalIPRContext.state.ReleaseOption == false && (
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, paddingBottom: 10},
                                ]}
                            >
                                You indicated that you can NOT practice independently in the practice of{' '}
                                {practiceName}.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, paddingBottom: 10},
                                ]}
                            >
                                If this is incorrect, please revise your response above.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                    },
                                ]}
                            >
                                Please describe below the reasons(s) why you are unable to practice
                                independently and the specific accommodations that are required for you
                                in the practice of {practiceName}.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 15,
                                        paddingBottom: 25,
                                    },
                                ]}
                            >
                                Details <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                            </Text>
                        </View>
                        <View>
                            <ABATextInputMultiline
                                maxLength={500}
                                numberOfLines={8}
                                onChangeText={(text) => generalIPRContext.setState({NoteText: text})}
                                value={generalIPRContext.state.NoteText}
                                textInputStatus={generalIPRContext.state.NoteTextStatus}
                            />
                        </View>
                    </View>
                )}
                {generalIPRContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading generalIPR</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
