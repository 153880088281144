import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    userRole: null,
    KARYear: new Date().getFullYear().toString(),
    inactivityAlert: false,
    hasBackDoorAccess: undefined,
}

export const userData = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetUserRole:
            return {...state, userRole: action.payload}
        case ActionTypes.SetKARYear:
            return {...state, KARYear: action.payload}
        case ActionTypes.ToggleActivityReminder:
            return {...state, inactivityAlert: action.payload}
        case ActionTypes.SetUserData:
            return {...state, ...action.payload}
        case ActionTypes.ClearUserData:
            if (state.hasBackDoorAccess) {
                var keepHasBackDoorAccess = state.hasBackDoorAccess
            }
            return {...state, ...initialState, hasBackDoorAccess: keepHasBackDoorAccess}
        default:
            return state
    }
}

export default userData
