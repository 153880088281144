import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {View, Text, TouchableOpacity} from 'react-native'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'

export default class ABAToolTip extends Component {
    state = {ViewHeight: 100}
    render() {
        const {top, bottom, left, right} = this.props.coordinates
        const isIE = HelperMethods.checkIsIE()
        return (
            <TouchableOpacity
                style={{
                    alignItems: 'center',
                    backgroundColor: ColorEnums.aquaHaze,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: this.props.width,
                    position: 'absolute',
                    top,
                    bottom,
                    left,
                    right,
                    zIndex: 10,
                    height: isIE ? this.state.ViewHeight : null,
                }}
                onPress={() => this.props.onPress()}
            >
                <View style={{width: '100%', height: '100%'}}>
                    <Text
                        style={{
                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                            fontSize: 14,
                            color: ColorEnums.cloudBurst,
                            position: 'absolute',
                            right: 6,
                            top: 4,
                        }}
                    >
                        {IconEnums['times']}
                    </Text>
                    <View
                        style={{padding: 25, flexGrow: 1}}
                        onLayout={e => {
                            isIE && this.setState({ViewHeight: e.nativeEvent.layout.height})
                        }}
                    >
                        {this.props.children}
                    </View>
                </View>
            </TouchableOpacity>
        )
    }
}

ABAToolTip.defaultProps = {
    width: 300,
    coordinates: {top: 0, left: 0},
}

ABAToolTip.propTypes = {
    width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    onPress: PropTypes.func,
    coordinates: PropTypes.exact({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
}
