import {Platform} from 'react-native'

const GeneralEnums = {
    fontAwesomeFamilyName: Platform.OS === 'ios' ? 'FontAwesome5FreeSolid' : 'FontAwesome',

    navigationType: {
        push: 1,
        replace: 2,
    },

    textInputStatus: {
        default: 1,
        disabled: 2,
        error: 3,
        success: 4,
    },

    ABAHeaderGroups: {
        AccountSettings: 1,
        Dashboard: 2,
        ExamInformation: 3,
        FAQ: 4,
        MOCA: 5,
        MOCAMinute: 6,
        QuestionHistory: 7,
        PracticeProfile: 8,
        ProgressReport: 9,
        Performance: 10,
        Conflicts: 11,
        ExaminerAvailability: 12,
        PaymentConfirmation: 13,
        PostedDocuments: 14,
    },

    roleEnums: {
        dilomateInMOCA: 1,
        nonDiplomate: 2,
        diplomateNotInMOCA: 3,
        retiredRevoked: 4,
    },

    MMStatusScenario: {
        EligibleToAnswer: 1,
        ReachedDailyLimit: 2,
        ReachedQuarterlyLimit: 3,
        ReachedAnnualLimit: 4,
    },

    MocaRegistrationStatus: {
        NotRegisteredForMOCA: 1,
        NotRegisteredNTL: 2,
        NotRegisteredFirstTime: 3,
        RegisteredForMOCA: 4,
    },

    DateCheckType: {
        NoCheck: 0,
        TodayAndFutureAllowed: 1,
        TodayOnly: 2,
        TodayAndPastOnly: 3,
        PastOnly: 4,
        FutureOnly: 5,
    },

    professionalStanding: {
        satisfactiory: 1,
        unsatisfactory: 2,
    },

    cert_referenceType: {
        AfterCare_Monitoring: 2,
        AfterCare_Location: 8,
        Clinical_Activity: 5,
        Clinical_Activity_Chief_of_Anesthesiology: 9,
        Clinical_Activity_Chief_of_Staff: 10,
        Clinical_Activity_Clinical_Competence_Committee_Chair: 11,
        Clinical_Activity_Credentials_Committee_Chair: 18,
        Clinical_Activity_Medical_Director: 16,
        Inpatient_Treating_Physicians: 7,
        Outpatient_Treating_Physicians: 6,
        Principal_Facility_Reference: 4,
        Program_Director: 15,
        Referring_Physician: 17,
        Relapse: 3,
        Relapse_Inpatient: 690480000,
        Relapse_Outpatient: 690480001,
    },

    program_type: {
        Adult_Cardiac_Anesthesiology: 551,
        Adult_Cardiothoracic_Anesthesiology: 524,
        AEP_DO_NOT_USE_Use_Pri_with_AEP_track: 526,
        Clinical_Informatics: 541,
        Critical_Care_Medicine: 516,
        Hospice_and_Palliative_Medicine: 525,
        MOCA2: 500,
        Obstetric_Anesthesiology: 539,
        Pain_Medicine: 515,
        Pediatric_Anesthesiology: 519,
        Primary: 513,
        Regional_Anesthesiology_and_Acute_Pain_Medicine: 540,
        Sleep_Medicine: 537,
        Neurocritical_Care_Exam: 548,
    },

    crt_eventtype: {
        ACE: 1,
        Not_Identified: 5,
        Other: 7,
        Patient_Safety: 2,
        Practice_Improvement_Activity: 3,
        SEE: 6,
        Simulation_Course: 4,
    },

    crt_category: {
        ACCME_AMA_PRA_Category_1: 1,
        Other_Documented: 2,
        Other_Undocumented: 3,
    },

    cert_CountryId: {
        USA: 'e94dbeb6-99d0-de11-8b2b-001f29d17639',
        Canada: '274dbeb6-99d0-de11-8b2b-001f29d17639',
    },

    SASubstanceTypes: {
        Substance: 690480000,
        Relapse: 690480001,
    },

    LegendPosition: {
        Top: 'top',
        Bottom: 'bottom',
        Left: 'left',
        Right: 'right',
        In: 'in',
        None: 'none',
    },

    PerformanceLogicQuestionResponseCodes: {
        Correct: 2,
        Incorrect: 3,
        FilledIn: 6,
    },

    AlertTypes: {
        ExpiredCredentials: 4011,
        ImproperUsernameAndPassword: 4012,
        ImproperUsername: 4013,
        ImproperPassword: 4014,
        InvalidUsername: 4015,
        AuthenticationError: 4016,
        SessiontTimeout: 401,
        AppOutOfDate: 409,
        DownCompletelyForMaintenance: 503,
    },

    NSEStatusCodes: {
        Submitted: 1,
        Completed: 2,
        NeedsAction: 970230002,
        UnderReviewByConsultant: 970230004,
        UnderReviewByCommittee: 970230005,
        Cancelled: 970230006,
        NoneRequested: 970230007,
        Pending: 970230008,
        Draft: 970230009,
    },

    ruleStatus_statusCodes: {
        Inactive: 2,
        NA: 134470004,
        Not_Submitted: 1,
        Pending_Decision: 134470005,
        Pending_Final_Approval: 134470003,
        Satisfactory: 134470000,
        Submitted_Under_Review: 134470001,
        Unsatisfactory: 134470002,
    },

    rulesetStatus_statusCodes: {
        Approved_for_Certification: 134470005,
        Cancelled_ABA: 134470010,
        Certified: 134470006,
        Complete: 2,
        Complete_Extended_Year: 690480000,
        Deferred_Certification: 134470011,
        Eligible: 134470004,
        Eligible_to_Enroll: 1,
        Hold: 134470001,
        In_Progress: 134470002,
        Incomplete: 134470014,
        Not_Paid: 134470013,
        Paid: 134470012,
        Rejected: 134470008,
        Validate: 134470003,
        Void: 134470007,
        Withdrawn: 134470009,
        xCompleted: 134470000,
    },

    raceEthnicity: {
        Other: '95847b87-38c9-eb11-80f1-005056a346e2',
    },

    product: {
        ACA: '864eb777-0c77-ed11-8112-005056a3d908',
        OSCE: '562b5ad6-3325-e411-a7d3-005056ab0010',
        Part2: '5c2b5ad6-3325-e411-a7d3-005056ab0010',
        SOE: '662b5ad6-3325-e411-a7d3-005056ab0010',
        APPLIED: '351191ff-4b86-e611-b01b-005056ab0010',
        NCC: 'b6c97a02-392a-eb11-80f7-005056a3d908',
        HPM: '4c2b5ad6-3325-e411-a7d3-005056ab0010',
        HPMRecert: '3fd835ab-f9da-e511-80d1-005056ab0010',
        SM: '642b5ad6-3325-e411-a7d3-005056ab0010',
        SMRecert: 'cb60e64b-e239-e911-80df-005056a3d908',
    },

    examRequestType: {
        ComputerBasedExam: 970230000,
        OralExamination: 970230001,
    },

    paymentSource: {
        web: 0,
        android: 1,
        ios: 2,
        googlePay: 3,
        applePay: 4,
    },
    nccTrainingPathway: {
        Currently_Training_In_Fellowship: 1,
        Completed_Fellowship_In_NCC: 2,
        Certified_By_UCNS: 3,
        Certified_By_CAST: 4,
        Currently_Practicing: 5,
    },
    nccPostTrainingPathway: {
        At_Least_7_Hours: 1,
        At_Least_10_Hours: 2,
        At_Least_13_Hours: 3,
        At_Least_20_Hours: 4,
        Academic_Program_Director: 5,
    },
}
export const BiometryTypes = {
    FaceIOS: 'FaceID',
    FingerprintIOS: 'TouchID',
    Biometrics: 'Biometrics',
    FaceRecognition: 2,
    FingerprintRecognition: 1,
}

export const PaymentTypes = {
    CreditCard: 0,
    MobilePay: 1,
}

export const RemoteExam = {
    yes: true,
    no: false,
}

const ColorEnums = {
    aquaHaze: '#F1F4F5',
    backgroundGray: '#F4F7F8',
    blueGray: '#8DABBC',
    citrus: '#FFC262',
    citrusDark: '#D88400',
    citrusLight: '#FFF0D8',
    cloudBurst: '#002F6C',
    danube: '#418FDE',
    darkGray: '#262626',
    exDarkGray: '#131313',
    exLightGray: '#DCE0E1',
    gray: '#999999',
    indigo: '#6A67CF',
    lightBlue: '#66CCFF',
    lightGray: '#BFC1C2',
    lime: '#3AC67E',
    mdDarkGray: '#5A5B5C',
    midnight: '#16223F',
    pomegranate: '#FB6C7A',
    pomegranateDark: '#B20516',
    pomegranateLight: '#FEE2E4',
    surfGreen: '#75D7A5',
    surfGreenDark: '#308A5C',
    surfGreenLight: '#DCF5E8',
    white: '#FFFFFF',
}

const ActionTypes = {
    SetAuthToken: 'SetAuthToken',
    SetABAID: 'SetABAID',
    SetKARYear: 'SetKARYear',
    SetUserRole: 'SetUserRole',
    SetUserData: 'SetUserData',
    ClearUserData: 'ClearUserData',
    ClearSettings: 'ClearSettings',
    TogglePendingPayment: 'TogglePendingPayment',
    SetCurrentProgressTab: 'SetCurrentProgressTab',
    SetBiometricsAvailable: 'SetBiometricsAvailable',
    SetBiometricsEnabled: 'SetBiometricsEnabled',
    SetBiometricsSeen: 'SetBiometricsSeen',
    SetLogoutNoticeTime: 'SetLogoutNoticeTime',
    SetNavigation: 'SetNavigation',
    SetOpenMenuGroups: 'SetOpenMenuGroups',
    SetPaymentOffline: 'SetPaymentOffline',
    RegistrationLoading: 'RegistrationLoading',
    ToggleActivityReminder: 'ToggleActivityReminder',
}

const BrowserTypes = {
    Safari: 'Safari',
    Chrome: 'Chrome',
    FireFox: 'FireFox',
    Safari_Mobile: 'Safari mobile',
}

const UnicodeEnums = {
    bullet: '\u2022',
    'registered-trademark': '\u00AE',
    'double-angle-left': '\u00AB',
    'double-angle-right': '\u00BB',
    ellipsis: '\u2026',
    nbsp: '\u00A0',
}

const IconEnums = {
    'angle-left': '\uf104',
    'angle-right': '\uf105',
    ban: '\uf05e',
    bell: '\uf0f3',
    'chart-line': '\uf201',
    check: '\uf00c',
    'chevron-down': '\uf078',
    'chevron-left': '\uf053',
    'chevron-right': '\uf054',
    'chevron-up': '\uf077',
    circle: '\uf111',
    'clipboard-list': '\uf46d',
    clock: '\uf017',
    'exclamation-circle': '\uf06a',
    exclamation_triangle: '\uf071',
    'external-link-alt': '\uf35d',
    'file-alt': '\uf15c',
    history: '\uf1da',
    'list-ul': '\uf0ca',
    lock: '\uf023',
    menu: '\uf0c9',
    minus: '\uf068',
    'minus-circle': '\uf056',
    pencil: '\uf040',
    'pencil-alt': '\uf303',
    'pencil-square': '\uf14b',
    plus: '\uf067',
    print: '\uf02f',
    search: '\uf002',
    'question-circle': '\uf059',
    'tachometer-alt': '\uf3fd',
    tasks: '\uf0ae',
    times: '\uf00d',
    'times-circle': '\uf057',
    trash: '\uf1f8',
    'user-circle': '\uf2bd',
    'redo-alt': '\uf2f9',
    'file-medical': '\uf477',
}

export const PearsonVueErrors = {
    ERROR_CANDIDATE_NOT_FOUND: 'ERROR_CANDIDATE_NOT_FOUND',
    ERROR_CLIENT_NOT_SUPPORTED: 'ERROR_CLIENT_NOT_SUPPORTED',
    ERROR_INVALID_PAYLOAD: 'ERROR_INVALID_PAYLOAD',
    ERROR_MISSING_REQUIRED_PARAMETERS: 'ERROR_MISSING_REQUIRED_PARAMETERS',
    ERROR_REGISTRATION_NOT_FOUND: 'ERROR_REGISTRATION_NOT_FOUND',
    ERROR_SESSION_TIMEOUT: 'ERROR_SESSION_TIMEOUT',
    ERROR_TEMPORARY: 'ERROR_TEMPORARY',
    ERROR_VUE_WIDE_BLOCK: 'ERROR_VUE_WIDE_BLOCK',
    ERROR: 'ERROR',
    BAD_AUTH: 'BAD_AUTH',
}

export const ExamProviders = {
    PEARSON_VUE: 1,
    ABA: 2,
    OTHER: 4,
}

export const AuthNetResponseCodes = {
    SUCCESSFUL: '1',
    DECLINED: '2',
}

export const PVTransCodes = {
    Notify_Eligibility: 4,
    Eligible_to_Schedule: 1,
    Add: 2,
    Change: 3,
    Delete: 9,
}

export const FullEnglishOrdinals = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth',
    'Tenth',
    'Eleventh',
    'Twelfth',
    'Thirteenth',
    'Fourteenth',
    'Fifteenth',
    'Sixteenth',
    'Seventeenth',
    'Eighteenth',
    'Nineteenth',
    'Twentieth',
]
export {ColorEnums, GeneralEnums, UnicodeEnums, IconEnums, ActionTypes, BrowserTypes}
