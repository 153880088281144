import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ViewRegistrationDetailsLogic = {
    context: null,

    GetAllRegistrationStepsDisplayData() {
        return new Promise((resolve) => {
            ABAPortalAPI.post('registration/GetAllRegistrationStepsDisplayInfo', {
                CR2ID: viewRegistrationDetailsContext.navigationData.re_rulesetstatusId,
                re_RulegroupId: viewRegistrationDetailsContext.navigationData.re_RulegroupId,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Registration Information')
                    resolve(null)
                })
        })
    },

    getReceiptDetails: function (invoiceId, source) {
        return new Promise((resolve) => {
            ABAPortalAPI.post('receipts/getReceiptDetails', {
                invoiceId,
                source,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Receipt Details')
                    resolve(null)
                })
        })
    },
}

export default ViewRegistrationDetailsLogic
