import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native'

import {ColorEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'

export default class ABAContainer extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            pageTitle: {
                color: ColorEnums.white,
                fontFamily: 'Lato',
                fontSize: 16,
                fontWeight: 'bold',
            },
            pageTitleView: {
                alignItems: 'center',
                backgroundColor: ColorEnums.indigo,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                justifyContent: 'center',
                minHeight: 30,
                paddingBottom: 5,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                width: '100%',
            },
            pageTitleStatusView: {
                alignSelf: 'center',
                backgroundColor: ColorEnums.indigo,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                justifyContent: 'center',
                minHeight: 30,
                paddingBottom: 5,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                fontWeight: 'bold',
                color: 'white',
            },
            containerView: {
                alignItems: 'center',
                backgroundColor: ColorEnums.white,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
            },
            containerContent: {
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: 30,
                paddingLeft: this.props.overrideWidth ? 0 : this.props.sidePadding,
                paddingRight: this.props.overrideWidth ? 0 : this.props.sidePadding,
                paddingTop: this.props.topPadding,
                width: '100%',
            },
        })

        this.state = {}
    }

    render() {
        return (
            <View
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: this.props.overrideWidth ? '100%' : '95%',
                }}
                onLayout={this.props.onLayout}
            >
                {this.props.showContainerTitleBar === true && (
                    <View
                        style={[
                            this.styles.pageTitleView,
                            {alignItems: this.props.containerTitleAlignment, zIndex: 2},
                        ]}
                    >
                        <Text style={this.styles.pageTitle}>{this.props.containerTitle}</Text>
                    </View>
                )}
                {this.props.status && (
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}
                    >
                        <View
                            style={{
                                borderTopRightRadius: 5,
                                width: 125,
                                shadowColor: ColorEnums.indigo,
                                shadowOffset: {width: 2, height: -15},
                                shadowRadius: 0,
                                shadowOpacity: 5,
                            }}
                        ></View>
                        <Text style={this.styles.pageTitleStatusView}>{this.props.status}</Text>
                        <View
                            style={{
                                borderTopLeftRadius: 5,
                                width: 125,
                                shadowColor: ColorEnums.indigo,
                                shadowOffset: {width: -2, height: -15},
                                shadowRadius: 0,
                                shadowOpacity: 5,
                            }}
                        ></View>
                    </View>
                )}
                <View
                    style={[
                        this.styles.containerView,
                        this.props.containerViewStyle,
                        this.props.showContainerTitleBar === false
                            ? {borderTopLeftRadius: 4, borderTopRightRadius: 4}
                            : {},
                    ]}
                >
                    <View
                        style={[
                            this.styles.containerContent,
                            this.props.useMaxWidth === true ? {maxWidth: 700} : {},
                        ]}
                    >
                        {this.props.children}
                    </View>
                </View>
                {this.props.showActivityIndicator && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>{this.props.activityText}</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

ABAContainer.defaultProps = {
    containerTitleAlignment: 'center',
    showContainerTitleBar: true,
    useMaxWidth: true,
    overrideWidth: false,
    sidePadding: 20,
    topPadding: 30,
    containerViewStyle: {},
}

ABAContainer.propTypes = {
    activityText: PropTypes.string,
    containerTitle: PropTypes.string,
    containerTitleAlignment: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    overrideWidth: PropTypes.bool,
    sidePadding: PropTypes.number,
    showActivityIndicator: PropTypes.bool,
    showContainerTitleBar: PropTypes.bool,
    topPadding: PropTypes.number,
    useMaxWidth: PropTypes.bool,
    containerViewStyle: PropTypes.object,
}
