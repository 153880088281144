import React, {Component} from 'react'
import {Text, View} from 'react-native'
import {connect} from 'react-redux'
import AddEditCMELogic from '../logic/addEditCMELogic'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import StyleFunctions from '../../styles/styleFunctions'

import ABACheckbox from '../../components/ABACustom/presentation/ABACheckbox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABAToolTip from '../../components/ABACustom/presentation/ABAToolTip'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import moment from 'moment'
import {setUserRole} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = {setUserRole}

class AddEditCME extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ScreenWidth: 700,
            ShowPSToolTip: false,
            Category: 'null',
            ContinuingEducationID: null,
            CreditsRequested: '',
            DateCompleted: '',
            EventType: 0,
            CMEActivityID: '',
            ActivitySponsor: '',
            EventDescription: '',
            IsPatientSafety: false,
            Action: 'create',
            DateCompletedError: 'Please enter a value.',
            CreditsRequestedError: 'Please enter a value.',
            CMEMaxPastDate: null,
            ControlStatus: {
                CMEActivityID: GeneralEnums.textInputStatus.disabled,
                DateCompleted: GeneralEnums.textInputStatus.default,
                ActivitySponsor: GeneralEnums.textInputStatus.default,
                EventDescription: GeneralEnums.textInputStatus.default,
                Category: GeneralEnums.textInputStatus.default,
                CreditsRequested: GeneralEnums.textInputStatus.default,
            },
            Role: null,
            ToolTipLeft: 0,
            ToolTipBottom: 0,
            FormHeight: 0,
        }
    }

    componentDidMount() {
        this.setState({CallingApi: true})
        HelperMethods.getUserRole(this.props.userData.userRole, this.props.setUserRole)
        this.dateCompletedInput.focus()
        Promise.all([
            AddEditCMELogic.GetCMEMaxPastDateSettingValue(),
            AddEditCMELogic.GetUserRoleId(),
        ]).then((values) => {
            let [CMEMaxPastDate, Role] = values
            this.setState({Role, CMEMaxPastDate, CallingApi: false})
        })

        if (this.navigationData.CMEID != null) {
            AddEditCMELogic.GetCMEActivity(this.navigationData.CMEID).then((response) => {
                this.setState({
                    Category: response.Category ? response.Category.toString() : 'null',
                    ContinuingEducationID: this.navigationData.CMEID,
                    CreditsRequested: response.CreditsRequested.toFixed(2).toString(),
                    DateCompleted: moment(new Date(response.DateCompleted)).format('MM/DD/YYYY'),
                    EventType: response.EventType,
                    CMEActivityID: response.CMEActivityID,
                    ActivitySponsor: response.ActivitySponsor,
                    EventDescription: response.EventDescription,
                    IsPatientSafety: response.IsPatientSafety,
                })
            })
        }
    }

    setCMETitle() {
        if (this.state.ContinuingEducationID == '' || this.state.ContinuingEducationID == null) {
            return 'Add CME Activity'
        } else {
            this.state.Action = 'update'
            return 'Edit CME Activity'
        }
    }

    render() {
        const fieldWidth = this.state.ScreenWidth >= 700 ? '50%' : '90%'
        addEditCMEContext = this
        AddEditCMELogic.context = addEditCMEContext
        HelperMethods.setNavigationData(addEditCMEContext)
        const userInMoca = this.props.userData.userRole === 1
        const bold = {fontWeight: 'bold'}
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    {this.state.Role === 1 ? (
                        <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                    ) : (
                        <View style={{height: 30}} />
                    )}
                    <ABAContainer
                        activityText={'Loading CME Activity Data'}
                        containerTitle={this.setCMETitle()}
                        showActivityIndicator={this.state.CallingApi}
                        onLayout={(event) => {
                            const {nativeEvent} = event
                            this.setState({
                                ScreenWidth: nativeEvent.layout.width,
                            })
                        }}
                    >
                        <View style={{justifyContent: 'center', paddingBottom: 20}}>
                            {userInMoca && (
                                <Text
                                    style={{
                                        fontFamily: 'lato',
                                        fontSize: 14,
                                    }}
                                >
                                    <Text style={bold}>Beginning in 2013</Text>, a maximum of
                                    <Text style={bold}> 60</Text> CME credits will be accepted per
                                    calendar year.
                                </Text>
                            )}

                            <View>
                                <Text
                                    style={{
                                        flexWrap: 'wrap',
                                        fontFamily: 'lato',
                                        fontSize: 14,
                                        paddingTop: 15,
                                    }}
                                >
                                    If you complete an activity through one of our{' '}
                                    <ABAHyperLink
                                        label="registered CME providers"
                                        url="https://theaba.org/pdfs/MOCA_CME_Providers.pdf"
                                        style={StyleFunctions.actionText()}
                                    />
                                    , please give them your ABA ID number and they will report your
                                    activities to us on your behalf. This includes{' '}
                                    <ABAHyperLink
                                        label="ABA-approved Patient Safety CME"
                                        url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                                        style={StyleFunctions.actionText()}
                                    />
                                    . These activities have the provider’s name in{' '}
                                    <Text style={{fontWeight: 'bold', color: ColorEnums.surfGreen}}>
                                        green
                                    </Text>{' '}
                                    font in your below activity listing.
                                </Text>
                            </View>
                        </View>

                        <View>
                            <View
                                style={{justifyContent: 'center', width: '100%'}}
                                onLayout={(event) =>
                                    this.setState({FormHeight: event.nativeEvent.layout.height})
                                }
                            >
                                {this.state.Action == 'update' && (
                                    <View>
                                        <ABATextInput
                                            maxLength={100}
                                            onChangeText={(text) => {
                                                addEditCMEContext.setState({CMEActivityID: text})
                                            }}
                                            placeholder="CME Activity ID"
                                            reference={(input) => {
                                                this.cmeActivityIDInput = input
                                            }}
                                            textInputStatus={this.state.ControlStatus.CMEActivityID}
                                            value={this.state.CMEActivityID}
                                            width={fieldWidth}
                                        />
                                    </View>
                                )}

                                <ABATextInputMask
                                    errorMessage={this.state.DateCompletedError}
                                    keyboardType="number-pad"
                                    maxLength={10}
                                    onChangeText={(text) => {
                                        addEditCMEContext.setState({DateCompleted: text})
                                    }}
                                    onSubmitEditing={() => {
                                        this.activitySponsorInput.focus()
                                    }}
                                    options={{mask: '99/99/9999'}}
                                    placeholder="Date Completed (mm/dd/yyyy) *"
                                    refInput={(input) => {
                                        this.dateCompletedInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={this.state.ControlStatus.DateCompleted}
                                    value={this.state.DateCompleted}
                                    width={fieldWidth}
                                />

                                <ABATextInput
                                    maxLength={150}
                                    onChangeText={(text) => {
                                        addEditCMEContext.setState({ActivitySponsor: text})
                                    }}
                                    onSubmitEditing={() => {
                                        this.eventDescriptionInput.focus()
                                    }}
                                    placeholder="Activity Sponsor *"
                                    reference={(input) => {
                                        this.activitySponsorInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={this.state.ControlStatus.ActivitySponsor}
                                    value={this.state.ActivitySponsor}
                                    width={fieldWidth}
                                />

                                <ABATextInput
                                    maxLength={200}
                                    onChangeText={(text) => {
                                        addEditCMEContext.setState({EventDescription: text})
                                    }}
                                    placeholder="Event Description *"
                                    reference={(input) => {
                                        this.eventDescriptionInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={this.state.ControlStatus.EventDescription}
                                    value={this.state.EventDescription}
                                    width={fieldWidth}
                                />

                                <View
                                    style={{
                                        paddingBottom: 20,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ABADropdown
                                        label="Event Category *"
                                        options={[
                                            {label: 'ACCME/AMA PRA Category 1', value: '1'},
                                            {label: 'Other: Documented', value: '2'},
                                            {label: 'Other: Undocumented', value: '3'},
                                        ]}
                                        onChange={(value) => {
                                            addEditCMEContext.setState({Category: value})
                                        }}
                                        placeholder="Event Category *"
                                        selectedValue={this.state.Category.toString()}
                                        validationStatus={this.state.ControlStatus.Category}
                                        width={fieldWidth}
                                    />
                                </View>
                                {this.state.Category === '3' && (
                                    <Text
                                        style={{
                                            fontWeight: 'bold',
                                            color: ColorEnums.citrusDark,
                                            paddingBottom: 10,
                                            alignSelf: 'center',
                                        }}
                                    >
                                        Maximum number of credits for this activity is 5.
                                    </Text>
                                )}
                                <ABATextInput
                                    errorMessage={this.state.CreditsRequestedError}
                                    onChangeText={(text) => {
                                        addEditCMEContext.setState({CreditsRequested: text})
                                    }}
                                    placeholder="Credits Requested *"
                                    reference={(input) => {
                                        this.creditsRequestedInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={this.state.ControlStatus.CreditsRequested}
                                    value={this.state.CreditsRequested}
                                    width={fieldWidth}
                                />
                                {this.state.ShowPSToolTip && (
                                    <ABAToolTip
                                        width={fieldWidth}
                                        coordinates={{
                                            left: this.state.ToolTipLeft,
                                            bottom: this.state.FormHeight - this.state.ToolTipBottom,
                                        }}
                                        onPress={() => this.setState({ShowPSToolTip: false})}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: 'lato',
                                                fontSize: 14,
                                                color: ColorEnums.cloudBurst,
                                            }}
                                        >
                                            This activity has a clear focus on patient safety and
                                            preventable harm and qualifies for the MOCA patient safety
                                            CME requirement.
                                        </Text>
                                    </ABAToolTip>
                                )}
                                <View
                                    style={{
                                        paddingBottom: 5,
                                        paddingTop: 10,
                                        flexDirection: 'row',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: fieldWidth,
                                    }}
                                    onLayout={(event) => {
                                        const {nativeEvent} = event
                                        this.setState({
                                            ToolTipLeft: nativeEvent.layout.x,
                                            ToolTipBottom: nativeEvent.layout.y,
                                        })
                                    }}
                                >
                                    <View style={{width: 160}}>
                                        <ABACheckbox
                                            isChecked={!!this.state.IsPatientSafety}
                                            label={'Patient Safety'}
                                            onChangeEvent={() => {
                                                AddEditCMELogic.SetCheckBoxState()
                                            }}
                                            useValidation={false}
                                            validationStatus={this.state.ControlStatus.IsPatientSafety}
                                        />
                                    </View>
                                    <Text
                                        style={{
                                            fontSize: 16,
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            color: ColorEnums.danube,
                                            paddingTop: 7,
                                        }}
                                        onPress={() =>
                                            this.setState({
                                                ShowPSToolTip: !this.state.ShowPSToolTip,
                                            })
                                        }
                                    >
                                        {IconEnums['question-circle']}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: fieldWidth,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text>
                                        Patient safety CME must come from this
                                        <ABAHyperLink
                                            label=" ABA-approved patient safety CME list"
                                            url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                                            style={StyleFunctions.actionText()}
                                        />
                                    </Text>
                                </View>

                                <View style={{paddingTop: 30}}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{width: '50%', alignSelf: 'flex-start'}}>
                                            <ABATouchableHighlight
                                                buttonStyle={StyleFunctions.buttonClickInverted()}
                                                onPressEvent={() => {
                                                    NavigationHelper.navigateBack()
                                                }}
                                                text={'Cancel'}
                                                textStyle={{
                                                    color: ColorEnums.danube,
                                                    fontSize: 16,
                                                }}
                                                underlayColor={ColorEnums.white}
                                            />
                                        </View>
                                        <View style={{width: '50%', alignSelf: 'flex-end'}}>
                                            <ABATouchableHighlight
                                                buttonStyle={StyleFunctions.buttonClick()}
                                                icon={'angle-right'}
                                                onPressEvent={() => {
                                                    AddEditCMELogic.SaveCMEActivity()
                                                }}
                                                text={'Submit'}
                                                textStyle={{
                                                    color: ColorEnums.white,
                                                    fontSize: 16,
                                                }}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCME)
