import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Text, TouchableOpacity, View} from 'react-native'

import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'

export default class ABAAffirmationRadio extends Component {
    sideEnum = {
        left: 1,
        right: 2,
    }

    constructor(props) {
        super(props)

        let borderStyle = this.getStyleForStatus(props.validationStatus)

        this.state = {
            borderColor: borderStyle.borderColor,
            borderWidth: borderStyle.borderWidth,
            leftOptionButtonStyle: this.props.unselectedButtonStyle,
            leftOptionTextStyle: this.props.unselectedTextStyle,
            rightOptionButtonStyle: this.props.unselectedButtonStyle,
            rightOptionTextStyle: this.props.unselectedTextStyle,
        }
    }

    componentDidMount() {
        this.selectOption()
    }

    componentDidUpdate(previousProps) {
        if (this.props.selectedValue !== previousProps.selectedValue) {
            this.selectOption()
        }
        if (this.props.validationStatus !== previousProps.validationStatus) {
            let borderStyle = this.getStyleForStatus(this.props.validationStatus)
            this.setState({
                borderColor: borderStyle.borderColor,
                borderWidth: borderStyle.borderWidth,
            })
        }
    }

    getStyleForStatus(status) {
        switch (status) {
            case GeneralEnums.textInputStatus.disabled:
                return {borderColor: ColorEnums.blueGray, borderWidth: 2}
            case GeneralEnums.textInputStatus.error:
                return {borderColor: ColorEnums.pomegranate, borderWidth: 2}
            case GeneralEnums.textInputStatus.success:
                return {borderColor: ColorEnums.surfGreen, borderWidth: 2}
            case GeneralEnums.textInputStatus.default:
            default:
                return {borderColor: null, borderWidth: 0}
        }
    }

    selectOption() {
        let selectedSide = null
        if (this.props.selectedValue === this.props.optionValues.leftValue) {
            selectedSide = this.sideEnum.left
        } else if (this.props.selectedValue === this.props.optionValues.rightValue) {
            selectedSide = this.sideEnum.right
        }
        this.updateSelectedOptionStyle(selectedSide)
    }

    updateSelectedOptionStyle(side) {
        if (side === this.sideEnum.left) {
            this.setState({
                leftOptionButtonStyle: this.props.selectedButtonStyle,
                leftOptionTextStyle: this.props.selectedTextStyle,
                rightOptionButtonStyle: this.props.unselectedButtonStyle,
                rightOptionTextStyle: this.props.unselectedTextStyle,
            })
        } else if (side === this.sideEnum.right) {
            this.setState({
                leftOptionButtonStyle: this.props.unselectedButtonStyle,
                leftOptionTextStyle: this.props.unselectedTextStyle,
                rightOptionButtonStyle: this.props.selectedButtonStyle,
                rightOptionTextStyle: this.props.selectedTextStyle,
            })
        } else {
            this.setState({
                leftOptionButtonStyle: this.props.unselectedButtonStyle,
                leftOptionTextStyle: this.props.unselectedTextStyle,
                rightOptionButtonStyle: this.props.unselectedButtonStyle,
                rightOptionTextStyle: this.props.unselectedTextStyle,
            })
        }
    }

    renderIcon() {
        let targetIcon = null

        switch (this.props.validationStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetIcon = IconEnums['lock']
                break
            case GeneralEnums.textInputStatus.error:
                targetIcon = IconEnums['times']
                break
            case GeneralEnums.textInputStatus.success:
                targetIcon = IconEnums['check']
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetIcon = null
                break
        }

        return (
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottomColor: this.state.borderBottomColor,
                    borderBottomWidth: this.state.borderBottomWidth,
                    width: '10%',
                }}
            >
                <Text
                    style={{
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        color: this.getStyleForStatus(this.props.validationStatus).borderColor,
                        fontSize: 16,
                    }}
                >
                    {targetIcon}
                </Text>
            </View>
        )
    }

    render() {
        return (
            <View style={{marginLeft: 'auto', marginRight: 'auto', width: this.props.width}}>
                <View style={{flexDirection: 'row'}}>
                    <TouchableOpacity
                        activeOpacity={1}
                        disabled={this.props.validationStatus === GeneralEnums.textInputStatus.disabled}
                        onPress={() => {
                            this.updateSelectedOptionStyle(this.sideEnum.left)
                            this.props.leftOptionOnPress()
                        }}
                        style={Object.assign(
                            {
                                borderBottomColor: this.state.borderColor,
                                borderBottomLeftRadius: 5,
                                borderBottomWidth: this.state.borderWidth,
                                borderLeftColor: this.state.borderColor,
                                borderLeftWidth: this.state.borderWidth,
                                borderTopColor: this.state.borderColor,
                                borderTopLeftRadius: 5,
                                borderTopWidth: this.state.borderWidth,
                                height: 50,
                                width: '44.5%',
                            },
                            this.state.leftOptionButtonStyle,
                        )}
                        testID={'leftOptionButton' + this.props.leftOptionContent}
                    >
                        <View
                            style={{
                                alignItems: 'center',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            {this.props.leftOptionIcon && this.props.leftOptionIcon.Side === 'left' && (
                                <Text
                                    style={Object.assign(
                                        {
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            paddingRight: 10,
                                        },
                                        this.state.leftOptionTextStyle,
                                    )}
                                >
                                    {this.props.leftOptionIcon.Icon}
                                </Text>
                            )}
                            <Text
                                style={Object.assign(
                                    {fontSize: 20, fontFamily: 'Lato'},
                                    this.state.leftOptionTextStyle,
                                )}
                            >
                                {this.props.leftOptionContent}
                            </Text>
                            {this.props.leftOptionIcon && this.props.leftOptionIcon.Side === 'right' && (
                                <Text
                                    style={Object.assign(
                                        {
                                            fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                            paddingLeft: 10,
                                        },
                                        this.state.leftOptionTextStyle,
                                    )}
                                >
                                    {this.props.leftOptionIcon.Icon}
                                </Text>
                            )}
                        </View>
                    </TouchableOpacity>

                    {/* Buffer space between the two options */}
                    <View
                        style={{
                            borderBottomColor: this.state.borderColor,
                            borderBottomWidth: this.state.borderWidth,
                            borderTopColor: this.state.borderColor,
                            borderTopWidth: this.state.borderWidth,
                            width: '1%',
                        }}
                    ></View>

                    <TouchableOpacity
                        activeOpacity={1}
                        disabled={this.props.validationStatus === GeneralEnums.textInputStatus.disabled}
                        onPress={() => {
                            this.updateSelectedOptionStyle(this.sideEnum.right)
                            this.props.rightOptionOnPress()
                        }}
                        style={Object.assign(
                            {
                                borderBottomColor: this.state.borderColor,
                                borderBottomRightRadius: 5,
                                borderBottomWidth: this.state.borderWidth,
                                borderRightColor: this.state.borderColor,
                                borderRightWidth: this.state.borderWidth,
                                borderTopColor: this.state.borderColor,
                                borderTopRightRadius: 5,
                                borderTopWidth: this.state.borderWidth,
                                height: 50,
                                width: '44.5%',
                            },
                            this.state.rightOptionButtonStyle,
                        )}
                        testID={'rightOptionButton' + this.props.rightOptionContent}
                    >
                        <View
                            style={{
                                alignItems: 'center',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            {this.props.rightOptionIcon &&
                                this.props.rightOptionIcon.Side === 'left' && (
                                    <Text
                                        style={Object.assign(
                                            {
                                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                paddingRight: 10,
                                            },
                                            this.state.rightOptionTextStyle,
                                        )}
                                    >
                                        {this.props.rightOptionIcon.Icon}
                                    </Text>
                                )}
                            <Text
                                style={Object.assign(
                                    {fontSize: 20, fontFamily: 'Lato'},
                                    this.state.rightOptionTextStyle,
                                )}
                            >
                                {this.props.rightOptionContent}
                            </Text>
                            {this.props.rightOptionIcon &&
                                this.props.rightOptionIcon.Side === 'right' && (
                                    <Text
                                        style={Object.assign(
                                            {
                                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                paddingLeft: 10,
                                            },
                                            this.state.rightOptionTextStyle,
                                        )}
                                    >
                                        {this.props.rightOptionIcon.Icon}
                                    </Text>
                                )}
                        </View>
                    </TouchableOpacity>
                    {this.renderIcon()}
                </View>
                <View>
                    {this.props.validationStatus === GeneralEnums.textInputStatus.error && (
                        <Text style={{color: ColorEnums.pomegranate}}>{this.props.errorMessage}</Text>
                    )}
                </View>
            </View>
        )
    }
}

ABAAffirmationRadio.defaultProps = {
    errorMessage: 'Please select an option.',
    selectedButtonStyle: {backgroundColor: ColorEnums.danube},
    selectedTextStyle: {color: ColorEnums.white},
    unselectedButtonStyle: {backgroundColor: ColorEnums.backgroundGray},
    unselectedTextStyle: {color: ColorEnums.danube},
    width: '100%',
}

ABAAffirmationRadio.propTypes = {
    errorMessage: PropTypes.string,
    leftOptionContent: PropTypes.string.isRequired,
    leftOptionIcon: PropTypes.object,
    leftOptionOnPress: PropTypes.func.isRequired,
    optionValues: PropTypes.object.isRequired,
    rightOptionContent: PropTypes.string.isRequired,
    rightOptionIcon: PropTypes.object,
    rightOptionOnPress: PropTypes.func.isRequired,
    selectedButtonStyle: PropTypes.object,
    selectedTextStyle: PropTypes.object,
    selectedValue: PropTypes.any, //not marked as isRequired because value passed in might be null, which is valid.
    unselectedButtonStyle: PropTypes.object,
    unselectedTextStyle: PropTypes.object,
    validationStatus: PropTypes.number,
    width: PropTypes.string,
}
