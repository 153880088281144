import ABAPortalAPI from '../../helpers/AxiosInstance'

const MocaMinuteLogic = {
    context: null,

    GetMocaMinuteDashboardInfo: function () {
        return new Promise((resolve) => {
            MocaMinuteLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('mocaMinute/getMocaMinuteDashboardInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Dashboard Data')
                    resolve(null)
                })
                .finally(function () {
                    MocaMinuteLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default MocaMinuteLogic
