import React, {Component} from 'react'
import {View, Text, ActivityIndicator} from 'react-native'
import styleFunctions from '../../../../styles/styleFunctions'
import EventSelection from './eventSelection'
import InvoiceConfirmation from './invoiceConfirmation'
import RankedChoiceSelectionLogic from '../logic/rankedChoiceSelectionLogic'
import {ColorEnums, FullEnglishOrdinals, GeneralEnums} from '../../../../helpers/generalEnums'
import ABADropdown from '../../../ABACustom/presentation/ABADropdown'

export default class RankedChoiceSelection extends Component {
    state = {
        CallingApi: false,
        options: null,
        dropdownOptions: [],
        selections: [],
        invalidSelections: [],
    }
    Previous() {
        return new Promise((resolve) => {
            this.props.PaymentAndRegistrationContext.setState({
                CurrentComponentStep: EventSelection,
                EventID: '',
                SessionName: '',
                SessionDates: '',
                isRankedChoice: false,
            })
            resolve(false)
        })
    }
    Save() {
        return new Promise((resolve) => {
            const {selections} = this.state
            invalidSelections = selections.map((selection) => {
                return !selection || selection === 'null'
            })
            this.setState({invalidSelections})
            if (invalidSelections.includes(true)) {
                resolve(false)
                return
            }
            this.setState({CallingApi: 'Saving Ranked Choice Selections'})
            RankedChoiceSelectionLogic.saveRankedChoiceSelections(this.props.EventID, selections)
                .then((saved) => {
                    if (!saved) return
                    this.props.PaymentAndRegistrationContext.setState({
                        CurrentComponentStep: InvoiceConfirmation,
                    })
                })
                .finally(() => {
                    this.setState({CallingApi: false})
                    resolve(false)
                })
        })
    }

    componentDidMount() {
        const getData = async () => {
            this.setState({CallingApi: 'Loading Ranked Choice Options'})
            let options = await RankedChoiceSelectionLogic.getRankedChoiceEventOptions(
                this.props.EventID,
            )
            this.setState({CallingApi: false})
            if (!options) return
            const selections = Array(options.length).fill(null)
            options.forEach((option, index) => {
                if (option.Rank) {
                    selections[option.Rank - 1] = option.EventId
                }
            })
            const invalidSelections = Array(options.length).fill(false)
            this.setState({options, invalidSelections}, () => this.populateDropdownOptions(selections))
        }
        getData()
    }

    populateDropdownOptions = (selections) => {
        const {options} = this.state
        if (!options) return
        const allOptionsLookup = options.map((option) => {
            return {label: option.DisplayName, value: option.EventId}
        })
        const unselectedOptions = allOptionsLookup.filter((option) => !selections.includes(option.value))
        const newDropdownOptions = []
        for (let i = 0; i < options.length; i++) {
            const availableOptions = [...unselectedOptions]
            if (selections[i] && selections[i] !== 'null') {
                var selectedOption = this.state.options.find(
                    (option) => option.EventId === selections[i],
                )
                availableOptions.push({label: selectedOption.DisplayName, value: selectedOption.EventId})
            }
            availableOptions = availableOptions.sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
            )
            newDropdownOptions.push(availableOptions)
        }
        this.setState({dropdownOptions: newDropdownOptions, selections})
    }

    handleSelectionChange = (index, value) => {
        const selections = [...this.state.selections]
        selections[index] = value

        this.populateDropdownOptions(selections)
    }

    render() {
        return (
            <View>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, paddingVertical: 30, fontWeight: 'bold'},
                    ]}
                >
                    Exam Rankings
                </Text>
                {!!this.state.options?.length &&
                    !!this.state.dropdownOptions?.length &&
                    this.state.options.map((option, index) => {
                        return (
                            <View key={option.DisplayName} style={{marginBottom: 20}}>
                                <ABADropdown
                                    options={this.state.dropdownOptions[index]}
                                    label={`${FullEnglishOrdinals[index]} Choice *`}
                                    placeholder={`${FullEnglishOrdinals[index]} Choice *`}
                                    onChange={(value) => this.handleSelectionChange(index, value)}
                                    selectedValue={this.state.selections[index] || 'null'}
                                    validationStatus={
                                        this.state.invalidSelections[index]
                                            ? GeneralEnums.textInputStatus.error
                                            : GeneralEnums.textInputStatus.default
                                    }
                                    keepValueOnOptionsChange
                                />
                            </View>
                        )
                    })}
                {this.state.CallingApi && (
                    <>
                        {!this.state.options && <View style={{height: 200}} />}
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>{this.state.CallingApi}</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    </>
                )}
            </View>
        )
    }
}
