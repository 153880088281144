import React, {Component} from 'react'
import {Text, View, StyleSheet} from 'react-native'
import PropTypes from 'prop-types'
import {ColorEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'
import ABATouchableHighlight from '../../ABACustom/presentation/ABATouchableHighlight'

export default class ABAConfirmationBox extends Component {
    constructor(props) {
        super(props)
        this.styles = StyleSheet.create({
            popUpStyle: {
                backgroundColor: ColorEnums.aquaHaze,
                shadowColor: '#dddddd',
                shadowOffset: {width: 4, height: 5},
                zIndex: 10,
                opacity: 1,
                borderWidth: 1,
                borderColor: ColorEnums.lightBlue,
                borderRadius: 10,
                width: '95%',
                maxWidth: 500,
            },
        })
    }

    renderButtons() {
        if (this.props.singleButtonFunction) {
            return (
                <View style={{alignSelf: 'center', width: '100%'}}>
                    <ABATouchableHighlight
                        text={this.props.singleButtonText}
                        onPressEvent={() => this.props.singleButtonFunction()}
                    />
                </View>
            )
        }

        return (
            <View
                style={{
                    flexDirection: 'row',
                    alignContent: 'center',
                    width: '100%',
                    alignSelf: 'center',
                }}
            >
                <View style={{alignSelf: 'center', width: '50%'}}>
                    <ABATouchableHighlight
                        text={this.props.buttonLeftText}
                        onPressEvent={() => this.props.leftButtonFunction()}
                    />
                </View>
                <View style={Object.assign({alignSelf: 'center', width: '50%'})}>
                    <ABATouchableHighlight
                        text={this.props.buttonRightText}
                        onPressEvent={() => this.props.rightButtonFunction()}
                    />
                </View>
            </View>
        )
    }

    render() {
        return (
            <View style={this.styles.popUpStyle}>
                <View>
                    <Text
                        style={[
                            styleFunctions.containerHeader('center'),
                            {marginTop: 20, marginLeft: 10, marginRight: 10},
                        ]}
                    >
                        {this.props.title}
                    </Text>
                    <Text
                        style={[
                            styleFunctions.containerTextBold('center'),
                            {marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10},
                        ]}
                    >
                        {this.props.messageText1}
                    </Text>
                    <Text
                        style={[
                            styleFunctions.containerText('center'),
                            {marginBottom: 20, marginLeft: 10, marginRight: 10},
                        ]}
                    >
                        {this.props.messageText2}
                    </Text>
                </View>
                <View style={{marginBottom: 20}}>{this.renderButtons()}</View>
            </View>
        )
    }
}
ABAConfirmationBox.defaultProps = {
    title: '',
    messageText1: '',
    messageText2: '',
    buttonLeftText: '',
    buttonRightText: '',
    singleButtonText: '',
    leftButtonFunction: null,
    rightButtonFunction: null,
    singleButtonFunction: null,
}

ABAConfirmationBox.propTypes = {
    title: PropTypes.string,
    messageText1: PropTypes.string,
    messageText2: PropTypes.string,
    buttonLeftText: PropTypes.string,
    buttonRightText: PropTypes.string,
    singleButtonText: PropTypes.string,
    leftButtonFunction: PropTypes.func,
    rightButtonFunction: PropTypes.func,
    singleButtonFunction: PropTypes.func,
}
