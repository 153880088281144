import React, {Component} from 'react'
import {View, Text, StyleSheet, TouchableWithoutFeedback} from 'react-native'
import ReceiptDetailsLogic from '../logic/receiptDetailsLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'

const styles = StyleSheet.create({
    amountColumn: {
        width: '25%',
        minWidth: 90,
        color: ColorEnums.cloudBurst,
        fontFamily: 'Lato',
        fontSize: 14,
        marginBottom: 5,
        marginTop: 5,
        fontWeight: 'bold',
    },

    descriptionColumn: {
        width: '75%',
        minWidth: 90,
        color: ColorEnums.mdDarkGray,
        fontFamily: 'Lato',
        fontSize: 14,
        marginBottom: 5,
        marginTop: 5,
        fontWeight: 'bold',
        paddingLeft: 10,
    },
})

export default class ViewReceiptDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            results: null,
            CallingApi: false,
            ShowNote: [],
        }
    }

    componentDidMount() {
        ReceiptDetailsLogic.getReceiptDetails().then(responseData => {
            viewReceiptDetailsSummaryContext.setState({results: responseData})
        })
    }

    render() {
        viewReceiptDetailsSummaryContext = this
        ReceiptDetailsLogic.context = viewReceiptDetailsSummaryContext
        const {IsViewRegistration} = this.props
        const Container = IsViewRegistration ? View : ABAContainer
        return (
            <ABAKeyboardAwareScrollView
                style={{backgroundColor: IsViewRegistration ? 'white' : ColorEnums.backgroundGray}}
            >
                {!IsViewRegistration && (
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                )}

                <Container
                    activityText={'Loading Receipts Summary'}
                    containerTitle={'RECEIPT DETAILS'}
                    showActivityIndicator={this.state.CallingApi}
                >
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            paddingBottom: 10,
                            paddingTop: 30,
                        }}
                    >
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 14,
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    color: ColorEnums.cloudBurst,
                                },
                            ]}
                        >
                            LINE ITEMS
                        </Text>
                    </View>
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'row',
                            paddingBottom: 10,
                            paddingTop: 30,
                            borderBottomWidth: 1,
                            borderBottomColor: ColorEnums.lightGray,
                        }}
                    >
                        <Text
                            style={[
                                styleFunctions.columnHeader(),
                                {width: '25%', fontFamily: 'Lato', fontSize: 14},
                            ]}
                        >
                            Amount
                        </Text>
                        <Text
                            style={[
                                styleFunctions.columnHeader(),
                                {width: '75%', fontFamily: 'Lato', fontSize: 14, paddingLeft: 13},
                            ]}
                        >
                            Description
                        </Text>
                    </View>

                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>
                                ${this.state.results.TotalAmount.toFixed(2)}
                            </Text>
                            <Text style={styles.descriptionColumn}>
                                {this.state.results.Description}
                            </Text>
                        </View>
                    )}
                    <View
                        style={{
                            borderTopWidth: 2,
                            borderTopColor: ColorEnums.cloudBurst,
                            paddingTop: 10,
                        }}
                    />
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            paddingBottom: 10,
                            paddingTop: 30,
                        }}
                    >
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {
                                    fontSize: 14,
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    color: ColorEnums.cloudBurst,
                                },
                            ]}
                        >
                            DETAILS
                        </Text>
                    </View>

                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>Payment ID</Text>
                            <Text style={styles.descriptionColumn}>{this.state.results.PaymentID}</Text>
                        </View>
                    )}

                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>Date</Text>
                            <Text style={styles.descriptionColumn}>{this.state.results.PayDate}</Text>
                        </View>
                    )}

                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>Description</Text>
                            <Text style={styles.descriptionColumn}>
                                {this.state.results.Description}
                            </Text>
                        </View>
                    )}
                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>Amount</Text>
                            <Text style={styles.descriptionColumn}>
                                ${this.state.results.TotalAmount.toFixed(2)}
                            </Text>
                        </View>
                    )}
                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                            }}
                        >
                            <Text style={styles.amountColumn}>Payment Type</Text>
                            <Text style={styles.descriptionColumn}>{this.state.results.PayType}</Text>
                        </View>
                    )}
                    {this.state.results && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.backgroundGray,
                                paddingTop: 10,
                                paddingBottom: 30,
                            }}
                        >
                            <Text style={styles.amountColumn}>Approval Code</Text>
                            <Text style={styles.descriptionColumn}>
                                {this.state.results.ApprovalCode}
                            </Text>
                        </View>
                    )}
                    <View style={{borderTopWidth: 2, borderTopColor: ColorEnums.cloudBurst}} />
                </Container>
            </ABAKeyboardAwareScrollView>
        )
    }
}
