import React, {Component, useEffect} from 'react'
import {ActivityIndicator, Platform, Text, View, Image} from 'react-native'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import HelperMethods from '../../helpers/HelperMethods'
import {Link} from '../../router'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAModal from '../../components/ABACustom/presentation/ABAModal'
import LoginLogic from '../logic/loginLogic'
import styleFunctions from '../../styles/styleFunctions'
import LoginFooter from '../../components/presentation/loginFooter'
import LoginHeader from '../../components/presentation/loginHeader'
import ReactNativeBiometrics from 'react-native-biometrics'
import ABALogo from '../../components/ABACustom/presentation/ABALogo'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import {
    setAuthToken,
    setABAID,
    setBiometricsAvailable,
    setBiometricsEnabled,
    setBiometricsSeen,
    setUserData,
} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    biometrics: state.biometrics,
})

const mapDispatchToProps = {
    setAuthToken,
    setABAID,
    setUserData,
    setBiometricsAvailable,
    setBiometricsEnabled,
    setBiometricsSeen,
}

const getBiometricIcon = (type) => {
    switch (type) {
        case ReactNativeBiometrics.Biometrics:
            return require('../../assets/images/biometrics/touchandroid.png')
        case ReactNativeBiometrics.FaceID:
            return require('../../assets/images/biometrics/faceios.png')
        case ReactNativeBiometrics.TouchID:
            return require('../../assets/images/biometrics/touchios.png')
    }
}

class Login extends Component {
    state = {
        username: '',
        password: '',
        CallingApi: false,
        IsSafari: false,
        AgreeSafari: false,
        UserAgentData: null,
        InputStatus: {
            UserName: GeneralEnums.textInputStatus.default,
            Password: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        LoginLogic.downForMaintenanceCheck(this.props.location.state)
        LoginLogic.versionCheck()

        if (Platform.OS === 'web') {
            LoginLogic.clientBrowser()
        }

        if (Platform.OS !== 'web') {
            LoginLogic.checkBiometrics()
        }

        if (Platform.OS !== 'ios') {
            return
        }

        const messaging = require('@react-native-firebase/messaging').default
        messaging()
            .hasPermission()
            .then((hasPermission) => {
                !hasPermission && messaging().requestPermission()
            })
    }

    componentDidUpdate(prevProps) {
        if (this.props.authToken === prevProps.authToken) return

        if (this.props.authToken && Platform.OS === 'web') {
            !this.state.CallingApi && LoginLogic.authenticateWithApi()
        }
    }

    render() {
        loginContext = this
        navigationContext = this
        LoginLogic.context = loginContext
        let linkStyle = {}
        if (Platform.OS === 'web') {
            // removing text decoration underline for our web links
            linkStyle = {textDecoration: 'none'}
        }
        return (
            <ABAKeyboardAwareScrollView
                contentContainerStyle={[
                    {flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between'},
                    styleFunctions.loginBackground(),
                ]}
            >
                <View>
                    {Platform.OS === 'web' && <LoginHeader />}
                    <ABALogo />

                    <View style={styleFunctions.loginContainer()}>
                        <Text style={styleFunctions.loginHeader()}>Login</Text>
                        <ViewError
                            error={this.props.location.state?.error}
                            message={
                                this.props.location.state?.message?.Message ||
                                this.props.location.state?.message
                            }
                        />
                        <View style={styleFunctions.loginFormContainer()}>
                            <ABATextInput
                                onChangeText={(username) => loginContext.setState({username})}
                                onSubmitEditing={() => loginContext.passwordInput.focus()}
                                placeholder={'ABA ID or Email'}
                                reference={(usernameInput) => {
                                    loginContext.usernameInput = usernameInput
                                }}
                                returnKeyType="next"
                                textInputStatus={loginContext.state.InputStatus.UserName}
                                value={this.state.username}
                            />

                            <ABATextInput
                                onChangeText={(password) => loginContext.setState({password})}
                                onSubmitEditing={LoginLogic.performLogin}
                                placeholder={'Password'}
                                reference={(passwordInput) => {
                                    passwordInput &&
                                        passwordInput.setNativeProps({
                                            style: {fontFamily: 'Lato'},
                                        })
                                    loginContext.passwordInput = passwordInput
                                }}
                                secureTextEntry={true}
                                textInputStatus={loginContext.state.InputStatus.Password}
                                value={this.state.password}
                            />

                            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                <ABATouchableHighlight
                                    buttonStyle={styleFunctions.buttonClick()}
                                    icon={'angle-right'}
                                    onPressEvent={LoginLogic.performLogin}
                                    textStyle={{color: 'white', fontFamily: 'Lato', fontSize: 18}}
                                    text={'Login'}
                                    isDisabled={this.state.CallingApi}
                                />
                                {this.props.biometrics.enabled && (
                                    <ABATouchableHighlight
                                        buttonStyle={{
                                            ...styleFunctions.buttonClick(),
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            marginLeft: 10,
                                        }}
                                        onPressEvent={() => LoginLogic.performFPLogin()}
                                    >
                                        <Image
                                            style={{width: 30, height: 30}}
                                            source={getBiometricIcon(this.props.biometrics.type)}
                                            resizeMode={'contain'}
                                        />
                                    </ABATouchableHighlight>
                                )}
                            </View>
                            {loginContext.state.CallingApi && (
                                <View style={styleFunctions.loadingOverlay()}>
                                    <Text>Logging In</Text>
                                    <ActivityIndicator color={ColorEnums.indigo} size="large" />
                                </View>
                            )}
                        </View>
                    </View>

                    <View
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            width: '100%',
                            maxWidth: 400,
                            paddingTop: 10,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: 50,
                        }}
                    >
                        <Link to="/forgotPasswordValidate" style={linkStyle}>
                            <Text style={{color: 'white'}}>Forgot Password?</Text>
                        </Link>
                        {Platform.OS !== 'android' && (
                            <Link to="/createAccountValidate" style={linkStyle}>
                                <Text style={{color: 'white'}}>Create Account</Text>
                            </Link>
                        )}
                    </View>

                    {this.state.IsSafari && (
                        <ABAModal
                            isVisible={this.state.IsSafari}
                            onDismiss={() => this.setState({IsSafari: false})}
                            onBackdropPress={() => null}
                        >
                            <View style={{margin: 20, justifyContent: 'center'}}>
                                <View
                                    style={{
                                        padding: 20,
                                        flexDirection: 'column',
                                        backgroundColor: ColorEnums.aquaHaze,
                                        shadowColor: '#bbbbbb',
                                        shadowOffset: {width: 4, height: 5},
                                    }}
                                >
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 24,
                                            fontWeight: 'bold',
                                            padding: 10,
                                        }}
                                    >
                                        Unsupported browser
                                    </Text>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            padding: 20,
                                        }}
                                    >
                                        You're using a browser that does not display all ABA Go content.
                                        Please use one of the following for the best experience:
                                    </Text>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                width: '40%',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ABATouchableHighlight
                                                buttonStyle={{
                                                    backgroundColor: ColorEnums.aquaHaze,
                                                    padding: 50,
                                                    height: 100,
                                                }}
                                                onPressEvent={() =>
                                                    HelperMethods.openURL(
                                                        'https://www.google.com/chrome/',
                                                    )
                                                }
                                            >
                                                <View
                                                    style={{
                                                        width: 120,
                                                        textAlign: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Image
                                                        style={{height: 80}}
                                                        source={{
                                                            uri: 'https://www.google.com/chrome/static/images/chrome-logo.svg',
                                                        }}
                                                        resizeMode={'contain'}
                                                    />
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Lato',
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: ColorEnums.danube,
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        Google Chrome
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Lato',
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Version 37+
                                                    </Text>
                                                </View>
                                            </ABATouchableHighlight>
                                        </View>
                                        <View
                                            style={{
                                                width: '40%',
                                            }}
                                        >
                                            <ABATouchableHighlight
                                                buttonStyle={{
                                                    backgroundColor: ColorEnums.aquaHaze,
                                                    padding: 50,
                                                    height: 100,
                                                }}
                                                onPressEvent={() =>
                                                    HelperMethods.openURL(
                                                        'https://apps.apple.com/us/app/aba-go/id1498508871',
                                                    )
                                                }
                                            >
                                                <View
                                                    style={{
                                                        width: 120,
                                                        textAlign: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Image
                                                        style={{height: 80}}
                                                        source={{
                                                            uri: ' https://qrcgcustomers.s3-eu-west-1.amazonaws.com/account12240131/12994976_1.png?0.14961700970435876',
                                                        }}
                                                        resizeMode={'contain'}
                                                    />
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Lato',
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: ColorEnums.danube,
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        ABA Go
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Lato',
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Mobile App
                                                    </Text>
                                                </View>
                                            </ABATouchableHighlight>
                                        </View>
                                    </View>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: 'Lato',
                                            color: ColorEnums.danube,
                                            textDecoration: 'underline',
                                            fontSize: 14,
                                            paddingTop: 35,
                                        }}
                                        onPress={() => {
                                            this.setState({IsSafari: false, AgreeSafari: true})
                                        }}
                                    >
                                        Continue using {this.state.UserAgentData.browser.name} with known
                                        issues
                                    </Text>
                                </View>
                            </View>
                        </ABAModal>
                    )}
                </View>
                <LoginFooter />
            </ABAKeyboardAwareScrollView>
        )
    }
}

const ViewError = ({error, message}) => {
    var displayMessage

    switch (error) {
        case GeneralEnums.AlertTypes.ExpiredCredentials:
            displayMessage = 'Your stored credentials have expired.\nPlease sign in again.'
            break
        case GeneralEnums.AlertTypes.ImproperPassword:
            displayMessage = 'Please enter your Password and try again.'
            break
        case GeneralEnums.AlertTypes.ImproperUsername:
            displayMessage = 'Please enter your User Name and try again.'
            break
        case GeneralEnums.AlertTypes.ImproperUsernameAndPassword:
            displayMessage = 'Please enter your User Name and Password and try again.'
            break
        case GeneralEnums.AlertTypes.InvalidUsername:
            displayMessage =
                'Please enter a valid username format using your ABA ID (nnnn-nnnn or nnnnnnnn) or E-Mail Address.'
            break
        case GeneralEnums.AlertTypes.AuthenticationError:
            displayMessage = message
            break
        case GeneralEnums.AlertTypes.SessiontTimeout:
            displayMessage =
                'You have been logged out because your current session expired.\n Please login again.'
            break
        case GeneralEnums.AlertTypes.DownCompletelyForMaintenance:
            displayMessage = message
                ? message
                : "Our sites are offline now for system maintenance. We'll be back online as soon as possible."
            break
        case GeneralEnums.AlertTypes.AppOutOfDate:
            displayMessage = `Your app is out of date. To continue using it, please update the ABA Go app at the ${
                Platform.OS === 'ios' ? 'App' : 'Google Play'
            } Store.`
            break
        default:
            return <View style={{padding: 20}} />
    }

    if (displayMessage?.length === 0 || !error) {
        return <View style={{padding: 20}} />
    }

    return (
        <View
            style={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'center',
                marginTop: 20,
                padding: 5,
                backgroundColor: ColorEnums.pomegranateLight,
                borderColor: ColorEnums.pomegranate,
                borderWidth: 1,
                borderRadius: 5,
                width: '85%',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text
                style={{
                    color: ColorEnums.pomegranateDark,
                    alignSelf: 'stretch',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center',
                }}
            >
                {displayMessage}
            </Text>
        </View>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
