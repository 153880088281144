import React from 'react'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const StrikeIronFormLogic = {
    context: null,
    ValidateAddress: function () {
        return new Promise((resolve) => {
            StrikeIronFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('addressValidation/verification', {
                AddressLine1: StrikeIronFormLogic.context.props.AddressLine1,
                AddressLine2: StrikeIronFormLogic.context.props.AddressLine2,
                City: StrikeIronFormLogic.context.props.City,
                StateIDName: StrikeIronFormLogic.context.props.StateName,
                CountryID: StrikeIronFormLogic.context.props.CountrySelected,
                ZipCode: StrikeIronFormLogic.context.props.ZipCode,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Address Information')
                    resolve(null)
                })
                .finally(function () {
                    StrikeIronFormLogic.context.setState({CallingApi: false})
                })
        })
    },
}
export default StrikeIronFormLogic
