import React, {Component} from 'react'
import {View, Text, Platform} from 'react-native'
import PropTypes from 'prop-types'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import NavigationHelper from '../../helpers/NavigationHelper'
import StyleFunctions from '../../styles/styleFunctions'
export default class CMEDashboardLinks extends Component {
    render() {
        return (
            <View
                style={{
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <View
                    style={{
                        width: '50%',
                        alignItems: 'flex-start',
                    }}
                >
                    <Text
                        style={{
                            color: ColorEnums.danube,
                            paddingRight: 0,
                            flexDirection: 'row',
                            fontSize: 16,
                            textAlign: 'left',
                        }}
                        onPress={() => {
                            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/cmeActivities')
                        }}
                    >
                        <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName, fontSize: 16}}>
                            {IconEnums['chart-line']}
                        </Text>{' '}
                        My CME Activities
                    </Text>

                    <View
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: 10,
                        }}
                    >
                        {this.props.RetiredRevoked !== GeneralEnums.roleEnums.retiredRevoked && (
                            <ABAHyperLink
                                label="How to Self-Report CME"
                                url="https://theaba.org/pdfs/MOCA_Report_CME_Instructions.pdf"
                                style={[
                                    StyleFunctions.actionText(),
                                    {
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        fontSize: 16,
                                        textAlign: 'left',
                                    },
                                ]}
                                icon="list-ul"
                            />
                        )}
                    </View>
                </View>
                <View
                    style={{
                        borderLeftWidth: 2,
                        borderLeftColor: ColorEnums.aquaHaze,
                        marginRight: 9,
                    }}
                />
                <View
                    style={{
                        width: '50%',
                    }}
                >
                    <View
                        style={{
                            marginLeft: 20,
                        }}
                    >
                        {
                            <Text
                                style={{
                                    color: ColorEnums.danube,
                                    flexDirection: 'row',
                                    fontSize: 16,
                                }}
                                onPress={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        '/reportNewCME',
                                    )
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                        fontSize: 16,
                                    }}
                                >
                                    {IconEnums['file-alt']}
                                </Text>{' '}
                                Report New CME
                            </Text>
                        }
                    </View>
                    <View
                        style={{
                            marginLeft: 20,
                            marginTop: 10,
                        }}
                    >
                        <ABAHyperLink
                            label="ABA-Approved Patient Safety CME"
                            url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                            style={[
                                StyleFunctions.actionText(),
                                {
                                    fontSize: 16,
                                    textAlign: 'left',
                                },
                            ]}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

CMEDashboardLinks.propTypes = {
    RetiredRevoked: PropTypes.bool.isRequired,
    SelectedYearForTable: PropTypes.string,
}

CMEDashboardLinks.defaultProps = {
    SelectedYearForTable: '1900',
}
