import moment from 'moment'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'

export default AddEditCMELogic = {
    context: null,

    GetCMEActivity: function () {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    continuingEducationID: AddEditCMELogic.context.navigationData.CMEID,
                },
            }

            AddEditCMELogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getCMEActivity', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activity Data')
                    resolve(null)
                })
                .finally(function () {
                    AddEditCMELogic.context.setState({CallingApi: false})
                })
        })
    },

    GetCMEEventCategories: function () {
        return new Promise((resolve) => {
            AddEditCMELogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getCMEEventCategories')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Event Categories')
                    resolve(null)
                })
                .finally(function () {
                    AddEditCMELogic.context.setState({CallingApi: false})
                })
        })
    },

    SaveCMEActivity: function () {
        AddEditCMELogic.context.setState({CallingApi: true})
        const {
            Category,
            ContinuingEducationID,
            CreditsRequested,
            DateCompleted,
            EventType,
            ActivitySponsor,
            EventDescription,
            IsPatientSafety,
        } = AddEditCMELogic.context.state

        if (!AddEditCMELogic.ValidateFormData()) {
            HelperMethods.alertAgnostic(
                'Please fill out all required fields correctly',
                'Form Validation Error',
                [{text: 'Ok'}],
            )
            AddEditCMELogic.context.setState({CallingApi: false})
            return
        }

        return new Promise((resolve) => {
            ABAPortalAPI.post('cme/saveCMEActivity', {
                Category,
                ContinuingEducationID,
                CreditsRequested,
                DateCompleted,
                EventType,
                ActivitySponsor,
                EventDescription,
                IsPatientSafety,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving CME Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    AddEditCMELogic.context.setState({CallingApi: false})
                    NavigationHelper.navigateBack()
                })
        })
    },

    GetCMEMaxPastDateSettingValue() {
        return new Promise((resolve) => {
            AddEditCMELogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/GetCMEMaxPastDate')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CMEMaxPastDate')
                    resolve(null)
                })
                .finally(function () {
                    AddEditCMELogic.context.setState({CallingApi: false})
                })
        })
    },

    SetCheckBoxState: function () {
        let {IsPatientSafety, EventType} = AddEditCMELogic.context.state
        IsPatientSafety = !IsPatientSafety
        if (IsPatientSafety) {
            EventType = GeneralEnums.crt_eventtype.Patient_Safety
            IsPatientSafety = true
        } else {
            EventType = GeneralEnums.crt_eventtype.Not_Identified
            IsPatientSafety = false
        }

        AddEditCMELogic.context.setState({IsPatientSafety, EventType})
    },

    GetUserRoleId: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('dashboard/getUserRoleId')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving User Role')
                    resolve(null)
                })
        })
    },

    ValidateFormData: function () {
        let controlStatus = AddEditCMELogic.context.state.ControlStatus
        let error = GeneralEnums.textInputStatus.error
        let defaultStatus = GeneralEnums.textInputStatus.default

        const {
            DateCompleted,
            ActivitySponsor,
            EventDescription,
            Category,
            CreditsRequested,
            CMEMaxPastDate,
        } = AddEditCMELogic.context.state

        // We need to change back any text inputs that had an error to default.  This is because  on re-submittal,
        // some required fields could have been filled out but others were missed.  We need those former errors
        // set back to default.  This is essentially giving us a clean pallete to work with each submittal.
        for (let key in controlStatus) {
            if (controlStatus[key] === error) {
                controlStatus[key] = defaultStatus
            }
        }

        //Let the field validation begin!
        let currentDate = moment(new Date(), 'MM/DD/YYYY', true)
        const dateCompleted = moment(DateCompleted, 'MM/DD/YYYY', true)

        let eligibleDateSpan = new Date()
        eligibleDateSpan.setFullYear(eligibleDateSpan.getFullYear() + -CMEMaxPastDate)
        eligibleDateSpan = moment(eligibleDateSpan).format('MM/DD/YYYY')

        if (!DateCompleted) {
            controlStatus.DateCompleted = error
            DateCompletedError = 'Please enter a value.'
            AddEditCMELogic.context.setState({DateCompletedError})
        } else if (!dateCompleted.isValid()) {
            controlStatus.DateCompleted = error
            DateCompletedError = 'Please enter a valid date.'
            AddEditCMELogic.context.setState({DateCompletedError})
        } else if (DateCompleted && DateCompleted.length < 10) {
            controlStatus.DateCompleted = error
            DateCompletedError = 'Invalid format.  Please enter a valid date.'
            AddEditCMELogic.context.setState({DateCompletedError})
        } else if (dateCompleted > currentDate) {
            controlStatus.DateCompleted = error
            DateCompletedError = 'Date cannot be in the future.'
            AddEditCMELogic.context.setState({DateCompletedError})
        } else if (dateCompleted.isBefore(eligibleDateSpan, 'day')) {
            controlStatus.DateCompleted = error
            DateCompletedError = `Date entered cannot be more than ${CMEMaxPastDate} years in the past.`
            AddEditCMELogic.context.setState({DateCompletedError})
        } else {
            controlStatus.DateCompleted = defaultStatus
            DateCompletedError = ''
            AddEditCMELogic.context.setState({DateCompletedError})
        }

        if (!ActivitySponsor) {
            controlStatus.ActivitySponsor = error
        }

        if (!EventDescription) {
            controlStatus.EventDescription = error
        }

        if (!Category || Category === 'null') {
            controlStatus.Category = error
        }

        if (!CreditsRequested) {
            controlStatus.CreditsRequested = error
            CreditsRequestedError = 'Please enter a value.'
            AddEditCMELogic.context.setState({CreditsRequestedError})
        }
        //checking for numeric input
        else if (isNaN(CreditsRequested)) {
            controlStatus.CreditsRequested = error
            CreditsRequestedError = 'Please enter a numeric value.'
            AddEditCMELogic.context.setState({CreditsRequestedError})
        }
        //[1] is the digits after the decimal point
        else if (CreditsRequested.split('.')[1] != null && CreditsRequested.split('.')[1].length > 2) {
            controlStatus.CreditsRequested = error
            CreditsRequestedError = 'Please enter only two digits after the decimal place.'
            AddEditCMELogic.context.setState({CreditsRequestedError})
        } else {
            controlStatus.CreditsRequested = defaultStatus
            CreditsRequestedError = ''
            AddEditCMELogic.context.setState({CreditsRequestedError})
        }

        if (
            Category == GeneralEnums.crt_category.Other_Undocumented &&
            parseFloat(CreditsRequested, 10) > 5.0
        ) {
            controlStatus.CreditsRequested = error
            CreditsRequestedError = 'The maximum credit for Other: Undocumented activity is 5.00.'
            AddEditCMELogic.context.setState({CreditsRequestedError})
        }

        //return false in the event our controlStatuses contains an error
        for (let key in controlStatus) {
            if (controlStatus[key] === error) {
                AddEditCMELogic.context.setState({ControlStatus: controlStatus})
                return false
            }
        }
        return true
    },
}
