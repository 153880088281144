import {Platform} from 'react-native'
import {Prompt} from 'react-router'
const isWeb = Platform.OS === 'web'
const Sentry = isWeb ? require('@sentry/react') : require('@sentry/react-native')

const RouterPackage = isWeb ? require('react-router-dom') : require('react-router-native')

const Link = RouterPackage.Link
const Redirect = RouterPackage.Redirect
const Route = isWeb ? Sentry.withSentryRouting(RouterPackage.Route) : RouterPackage.Route
const Router = isWeb ? RouterPackage.BrowserRouter : RouterPackage.NativeRouter
const withRouter = isWeb ? RouterPackage.withRouter : RouterPackage.withRouter
const Switch = RouterPackage.Switch

export {Link, Prompt, Redirect, Route, Router, Switch, withRouter}
export default RouterPackage
