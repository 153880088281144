import moment from 'moment'
import React from 'react'
import {Platform, Linking} from 'react-native'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import Alerts from '../../components/presentation/alerts'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import Certifications from '../../components/presentation/certifications'
import CME from '../../components/presentation/cme'
import HelperMethods from '../../helpers/HelperMethods'
import MedLicense from '../../components/presentation/medlicense'
import MyProgress from '../../components/presentation/myprogress'
import QuickLinks from '../../components/presentation/quicklinks'
import Registration from '../../components/presentation/registration'
import styleFunctions from '../../styles/styleFunctions'
import NavigationHelper from '../../helpers/NavigationHelper'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'

if (Platform.OS !== 'web') {
    var AsyncStorage = require('@react-native-community/async-storage').default
}

const componentsListing = {
    ALERTS: Alerts,
    CERTIFICATIONS: Certifications,
    CME: CME,
    'MY PROGRESS': MyProgress,
    'QUICK LINKS': QuickLinks,
    REGISTRATION: Registration,
    'MEDICAL LICENSE': MedLicense,
}

const DashboardLogic = {
    context: null,

    HandleNotification(notificationOpen) {
        if (!notificationOpen) return
        const route = notificationOpen.notification.data.route
        const webUrl = notificationOpen.notification.data.webUrl
        const notificationId = notificationOpen.notification.notificationId
        AsyncStorage.getItem('lastNotificationId').then((lastNotificationId) => {
            if (lastNotificationId === notificationId) return
            AsyncStorage.setItem('lastNotificationId', notificationId)
            route && NavigationHelper.GoToPage(GeneralEnums.navigationType.push, route)
            webUrl &&
                Linking.canOpenURL(webUrl).then((canOpen) => {
                    canOpen && Linking.openURL(webUrl)
                })
        })
    },

    GetComponentsDisplay() {
        return new Promise((resolve) => {
            DashboardLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('dashboard/getComponentsDisplay')
                .then(function (response) {
                    DashboardLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    DashboardLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Dashboard Components List')
                    resolve(null)
                })
        })
    },

    GetAccordionComponent(Title, Comp, dashboardAlerts) {
        return (
            <ABAAccordion
                key={Title + 'Component'}
                title={Title}
                accordionContent={
                    <Comp
                        Alerts={dashboardAlerts}
                        MocaCR2={DashboardLogic.context.state.mocaCR2}
                        DashboardDisplay
                    />
                }
                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                accordionTitleStyle={styleFunctions.Accordiontitle()}
                accordionButtonStyle={styleFunctions.containerTitle()}
                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                accordionExpanded={true}
                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                accordionIconStyle={{
                    color: 'white',
                    fontSize: 12,
                    marginRight: 'auto',
                    paddingTop: 3,
                    paddingRight: 10,
                }}
            />
        )
    },

    GetAlertDisplay(dashboardAlerts) {
        if (Platform.OS == 'web') {
            return (
                <div key={'PopAlert'} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    {this.GetAccordionComponent('ALERTS', Alerts, dashboardAlerts)}
                </div>
            )
        } else {
            return this.GetAccordionComponent('ALERTS', Alerts, dashboardAlerts)
        }
    },

    GetDashboardDisplay() {
        return new Promise((resolve) => {
            let DisplayComponents = []
            const {dashboardAlerts} = DashboardLogic.context.state
            if (dashboardAlerts.length > 0) {
                DisplayComponents.push(DashboardLogic.GetAlertDisplay(dashboardAlerts))
            }
            DashboardLogic.GetComponentsDisplay().then(function (displayComponents) {
                for (let index = 0; index < displayComponents.length; index++) {
                    let MyComp = componentsListing[displayComponents[index].ComponentName]
                    if (MyComp) {
                        let CssClass =
                            'col-' +
                            displayComponents[index].CssClassNumber +
                            ' col-sm-' +
                            displayComponents[index].CssClassNumberSM +
                            ' col-md-' +
                            displayComponents[index].CssClassNumberMD +
                            ' col-lg-' +
                            displayComponents[index].CssClassNumberLG +
                            ' col-xl-' +
                            displayComponents[index].CssClassNumberXL

                        DisplayComponents.push(
                            <div key={'ComponentContainer' + index} className={CssClass}>
                                {DashboardLogic.GetAccordionComponent(
                                    displayComponents[index].ComponentName,
                                    MyComp,
                                )}
                            </div>,
                        )
                    }
                }
                resolve(DisplayComponents)
            })
        })
    },

    GetDashboardDisplayApp() {
        return new Promise((resolve) => {
            let DisplayComponents = []
            const {dashboardAlerts} = DashboardLogic.context.state
            if (dashboardAlerts.length > 0) {
                DisplayComponents.push(DashboardLogic.GetAlertDisplay(dashboardAlerts))
            }
            DashboardLogic.GetComponentsDisplay().then(function (displayComponents) {
                for (let index = 0; index < displayComponents.length; index++) {
                    let MyComp = componentsListing[displayComponents[index].ComponentName]
                    if (MyComp) {
                        DisplayComponents.push(
                            DashboardLogic.GetAccordionComponent(
                                displayComponents[index].ComponentName,
                                MyComp,
                            ),
                        )
                    }
                }
                resolve(DisplayComponents)
            })
        })
    },

    GetDashboardAlertData(showLoading = true) {
        DashboardLogic.context.setState({CallingApi: showLoading})
        Promise.all([
            DashboardLogic.GetRegistrationAlerts(),
            DashboardLogic.GetExamResults(),
            DashboardLogic.GetShowCertInfoLink(),
            DashboardLogic.GetAvailOpen(),
            DashboardLogic.GetAvailClose(),
            DashboardLogic.GetExaminerInfo(),
            DashboardLogic.GetExaminerConflicts(),
            DashboardLogic.GetVolunteerInfo(),
            DashboardLogic.GetVolunteerTraining(),
        ])
            .then((values) => {
                DashboardLogic.PopulateAlerts(values)
                var returnedContent =
                    Platform.OS === 'web'
                        ? DashboardLogic.GetDashboardDisplay()
                        : DashboardLogic.GetDashboardDisplayApp()

                returnedContent.then((components) => {
                    DashboardLogic.context.setState({DisplayContent: components})
                })
            })
            .finally(() => {
                DashboardLogic.context.setState({CallingApi: false})
            })
    },

    GetRegistrationAlerts() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getRegistrationAlerts')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Registration Alerts')
                    resolve(null)
                })
        })
    },

    GetExamResults() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('examInformation/getExamResults')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Header Information')
                    resolve(null)
                })
        })
    },

    GetShowCertInfoLink() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getShowCertInfoLink')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Alert')
                    resolve(null)
                })
        })
    },

    GetAvailOpen() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getAvailOpenSetting')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving AvailOpen Setting Value')
                    resolve(null)
                })
        })
    },

    GetAvailClose() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getAvailCloseSetting')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving AvailClose Setting Value')
                    resolve(null)
                })
        })
    },

    GetExaminerInfo() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getExaminerInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Examiner Info')
                    resolve(null)
                })
        })
    },

    GetExaminerConflicts() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getExaminerConflicts')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Examiner Conflict Info')
                    resolve(null)
                })
        })
    },

    GetVolunteerInfo() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getVolunteerInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Volunteer Information')
                    resolve(null)
                })
        })
    },

    GetVolunteerTraining() {
        return new Promise((resolve) => {
            ABAPortalAPI.get('alerts/getVolunteerTraining')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Volunteer Training Information',
                    )
                    resolve(null)
                })
        })
    },

    PopulateAlerts(alertData) {
        const [
            registrationAlerts,
            examResults,
            showCertInfoLink,
            availOpen,
            availClose,
            examiner,
            examinerConflicts,
            volunteerInfo,
            volunteerTrainingList,
        ] = alertData
        const today = moment(new Date()).format('MM/DD/YYYY')

        const dashboardAlerts = []
        registrationAlerts.map((regData) => {
            const pendingExam = regData.statuscode.toLowerCase() === 'hold pending'
            const rankedEvent = regData.rankedEvent
            const alertText = pendingExam
                ? `We have received your registration for ${regData.evt_ProductIDName}.`
                : rankedEvent === 1 // 1 is yes
                ? `You are currently registered for ${regData.eventName}.`
                : `You are currently registered for ${regData.evt_ProductIDName}.`
            dashboardAlerts.push({
                alertText,
                route: `/schedulingDetails/${regData.evt_eventId}`,
                navigationData: regData,
                iconColor: ColorEnums.pomegranate,
            })
        })

        const hasAppliedResult = {}
        examResults.map((result) => {
            const {exam_firstview, exam_statuscode, EventName, productnumber} = result
            const isAppliedProduct =
                productnumber.toLowerCase() === 'exam-osce' || productnumber.toLowerCase() === 'exam-soe'
            const eventYear = EventName.substring(0, 4)
            if (isAppliedProduct && hasAppliedResult[eventYear]) return
            const shouldDisplayResult =
                exam_firstview === null && exam_statuscode && exam_statuscode !== -1

            shouldDisplayResult &&
                dashboardAlerts.push({
                    alertText: `You have exam results available for ${EventName}.`,
                    route: '/viewExamResults',
                    iconColor: ColorEnums.pomegranate,
                })
            if (isAppliedProduct) hasAppliedResult[eventYear] = true
        })

        showCertInfoLink &&
            dashboardAlerts.push({
                alertText:
                    'Click here to update your name and/or mailing address for your future certificates.',
                route: '/certificateInformation',
                iconColor: ColorEnums.danube,
            })

        const availOpenDate = moment(availOpen).format('MM/DD/YYYY')
        const availCloseDate = moment(availClose).format('MM/DD/YYYY')
        const isAvailabilityOpen = moment(availOpenDate).isSameOrBefore(today)
        const isAvailabilityNotClosed = moment(availCloseDate).isSameOrAfter(today)
        if (isAvailabilityOpen && isAvailabilityNotClosed && examiner) {
            dashboardAlerts.push({
                alertText: 'Please review Examiner Availability.',
                route: '/examinerAvailability',
                iconColor: ColorEnums.danube,
            })
        }

        examinerConflicts &&
            dashboardAlerts.push({
                alertText: 'Please review your Examiner Conflicts List.',
                route: '/examinerConflicts',
                navigationData: {ExamId: examinerConflicts},
                iconColor: ColorEnums.danube,
            })

        let censusOpenDate
        let censusCloseDate
        if (volunteerInfo.eligibleForCensus) {
            const censusOpen = volunteerInfo.censusOpen
            const censusClose = volunteerInfo.censusClose
            censusOpenDate = moment(censusOpen).format('MM/DD/YYYY')
            censusCloseDate = moment(censusClose).format('MM/DD/YYYY')
        }
        const isCensusOpen = censusOpenDate ? moment(censusOpenDate).isSameOrBefore(today) : null
        const isCensusNotClosed = censusCloseDate ? moment(censusCloseDate).isSameOrAfter(today) : null
        if (isCensusOpen && isCensusNotClosed) {
            dashboardAlerts.push({
                alertText: 'Click here to complete the Volunteer Census.',
                route: '/volunteerCensus',
                navigationData: {
                    isExaminer: examiner,
                },
                iconColor: ColorEnums.danube,
            })
        }

        volunteerTrainingList.map((volunteerTraining) => {
            dashboardAlerts.push({
                alertText: `Click here to complete the ${volunteerTraining.volunteerTrainingModuleName}.`,
                route: '/volunteerTraining',
                navigationData: {
                    volunteerTraining,
                },
                iconColor: ColorEnums.danube,
            })
        })

        DashboardLogic.context.setState({dashboardAlerts})
    },
}

export default DashboardLogic
