import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import NonStandardExamStep1 from '../NSESteps/presentation/nonStandardExamStep1'
import NonStandardExamStep7 from '../NSESteps/presentation/nonStandardExamStep7'

const NonStandardExamLogic = {
    context: null,

    GetNSEBeginData: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {Order, CR2ID} = this.context.props
            ABAPortalAPI.post('nserequestsinfo/GetNSEBegin', {
                Order,
                CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving NSE Information')
                    resolve(null)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables: function (NSEData) {
        if (NSEData) {
            const {rs_statuscode, statuscode, exam_requestresult1} = NSEData
            this.context.setState({
                CurrentComponentStep: null,
                NonStandardExamID: NSEData.exam_nserequestId || null,
                CurrentComponentStep:
                    (rs_statuscode && rs_statuscode === 1) || this.context.props.IsFromDashboard
                        ? NonStandardExamStep1
                        : NonStandardExamStep7,
                ExamResponse: NSEData.exam_Response,
                NSEStatus: statuscode,
                RuleStatus: rs_statuscode,
                ReviewResult: exam_requestresult1,
            })
        }
    },
}

export default NonStandardExamLogic
