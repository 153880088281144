import HelperMethods from '../../../helpers/HelperMethods'
import mocaMinuteLogic from '../../../screens/logic/mocaMinuteLogic'
import MocaMinuteLanding from '../presentation/mocaMinuteLanding'
import ABAPortalAPI from '../../../helpers/AxiosInstance'

const MocaMinuteFeedbackLogic = {
    context: null,

    getMocaMinuteReviewAnswer: function () {
        return new Promise((resolve) => {
            MocaMinuteFeedbackLogic.context.setState({callingApi: true})

            ABAPortalAPI.get(
                'mocaMinute/getMocaMinuteReviewAnswer?askId=' +
                    MocaMinuteFeedbackLogic.context.props.NavData.AskId,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Response')
                    resolve(null)
                })
                .finally(function () {
                    MocaMinuteFeedbackLogic.context.setState({callingApi: false})
                })
        })
    },

    updateMocaMinuteFeedback: function () {
        return new Promise((resolve) => {
            MocaMinuteFeedbackLogic.context.setState({
                activityIndicatorText: 'Saving MOCA Minute Feedback',
                callingApi: true,
            })

            ABAPortalAPI.post(
                `mocaMinute/updateMocaMinuteFeedback?askId=${MocaMinuteFeedbackLogic.context.props.NavData.AskId}`,
                {feedback: MocaMinuteFeedbackLogic.context.state.feedback},
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving MOCA Minute Feedback')
                    resolve(null)
                })
                .finally(function () {
                    mocaMinuteLogic.swapMocaComponent(MocaMinuteLanding)
                })
        })
    },
}

export default MocaMinuteFeedbackLogic
