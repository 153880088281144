import {Alert, Dimensions, Platform, Linking, PermissionsAndroid} from 'react-native'
import axios from 'axios'
import CryptoJs from 'crypto-js'
import ConfigurationProps from '../configuration'
import moment from 'moment'
import {GeneralEnums} from './generalEnums'
import NavigationHelper from './NavigationHelper'
import {debounce} from 'lodash'
const Sentry = Platform.OS === 'web' ? require('@sentry/react') : require('@sentry/react-native')
import {store} from '../redux/configureStore'
import ABAPortalAPI from './AxiosInstance'

const fjioewam = 'daPp@$wI!1YEYL!68$vWK5v#'
const googlePorperty = ConfigurationProps.ENVIRONMENT == 'PROD' ? 'UA-7743517-9' : 'UA-7743517-7'
const HelperMethods = {
    apiErrorHandler: debounce(function (error, title, callback = null) {
        if (!error || !error.response) {
            if (callback) {
                this.alertWithRetry(title, callback)
            } else {
                Sentry.captureException(error)
                this.alertAgnostic('Sorry! An error has occurred. Please refresh the page.', title, [
                    {text: 'Ok'},
                ]),
                    console.log('apiErrorHandler', error)
            }
            return
        }

        //system down for maintenance
        if (error.response.status === 503) {
            navigationContext.props.history.push('/login', {error: 503, message: error.response.data})
            return
        }

        if (error.response.status === 401) {
            if (navigationContext.props.location.pathname === '/forgotPasswordSetPassword') {
                navigationContext.props.history.push('/forgotPasswordValidate', {error: 401})
                return
            } else if (navigationContext.props.location.pathname === '/createAccountSetPassword') {
                navigationContext.props.history.push('/createAccountValidate', {error: 401})
                return
            }
            navigationContext.props.history.push('/login', {error: 401})
            return
        }

        if (error.response.status === 409) {
            navigationContext.props.history.push('/login', {error: 409})
            return
        }

        let isAuthentication = error.response.config.url.split('/').includes('authentication')

        let message =
            error.response.status === 500
                ? error.response.data.ExceptionMessage
                : error.response.data.Message
        if (isAuthentication) {
            navigationContext.props.history.push('/login', {
                error: GeneralEnums.AlertTypes.AuthenticationError,
                message,
            })
            return
        }
        console.log('apiErrorHandler', error)
        this.alertAgnostic(message, title, [{text: 'Ok'}])
    }, 200),

    alertAgnostic: function (message, title, buttons) {
        if (Platform.OS === 'web') {
            alert(message)
        } else {
            Alert.alert(title, message, buttons, {cancelable: true})
        }
    },

    alertWithRetry(title, callback) {
        HelperMethods.retryCounter++
        if (HelperMethods.retryCounter < 4) {
            HelperMethods.retryAPICall(title, callback)
        } else {
            HelperMethods.retryCounter = 0
            HelperMethods.redirectToLogin(title)
        }
    },

    retryAPICall(title, callback) {
        const confirmMessage =
            'There was an error attempting to submit. Click OK to Retry or Cancel to return to Login page. You can retry up to 3 times'
        if (Platform.OS === 'web') {
            if (confirm(confirmMessage)) {
                callback()
            } else {
                HelperMethods.retryCounter = 0
                HelperMethods.navigateToLogin()
            }
        } else {
            Alert.alert(
                title,
                confirmMessage,
                [
                    {
                        text: 'OK',
                        onPress: () => callback(),
                    },
                    {
                        text: 'Cancel',
                        onPress: () => {
                            HelperMethods.retryCounter = 0
                            HelperMethods.navigateToLogin()
                        },
                        style: 'cancel',
                    },
                ],
                {
                    cancelable: true,
                    onDismiss: () => HelperMethods.navigateToLogin(),
                },
            )
        }
    },

    redirectToLogin(title) {
        const redirectMessage = 'There is currently an issue submitting. Redirecting to login.'
        if (Platform.OS === 'web') {
            alert(redirectMessage)
            HelperMethods.navigateToLogin()
        } else {
            Alert.alert(
                title,
                redirectMessage,
                [
                    {
                        text: 'Ok',
                        onPress: () => HelperMethods.navigateToLogin(),
                    },
                ],
                {
                    cancelable: true,
                    onDismiss: () => HelperMethods.navigateToLogin(),
                },
            )
        }
    },

    retryCounter: 0,
    AddSpaceBefore: (fullText, word) => {
        if (fullText.indexOf(word) > -1) {
            return fullText.split(word).join(' ') + word
        }
        return fullText
    },
    navigateToLogin() {
        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/login')
    },

    decryptData: function (data) {
        return CryptoJs.AES.decrypt(data, fjioewam).toString(CryptoJs.enc.Utf8)
    },

    encryptData: function (data) {
        return CryptoJs.AES.encrypt(data, fjioewam)
    },

    formatDate(date) {
        return moment(new Date(date)).format('M/D/YYYY')
    },

    getStatusBarHeight() {
        let isIphoneXOrXs = () => {
            let screenSize = Dimensions.get('window')
            return screenSize.height === 812
        }
        let isIphoneXsMaxOrXr = () => {
            let screenSize = Dimensions.get('window')
            return screenSize.height === 896
        }

        switch (Platform.OS) {
            case 'ios':
                if (isIphoneXOrXs() || isIphoneXsMaxOrXr()) {
                    return 44
                }
                return 20
            case 'android':
            case 'web':
            default:
                return 0
        }
    },

    //this is watching our components width on web so that we can make our sidenavigation and header responsive
    handleResize(component, event) {
        if (Platform.OS !== 'web') {
            return
        }

        let screenSize = event.nativeEvent.layout.width

        if (screenSize < 800 && component.state.showNav === true) {
            component.setState({showNav: false})
        } else if (screenSize >= 800 && !component.state.showNav) {
            component.setState({showNav: true})
        }
    },

    setNavigationData: function (context) {
        try {
            if (
                context.NavigationData != null ||
                (Platform.OS === 'web' &&
                    sessionStorage.getItem(context.props.location.pathname + 'Data') == null)
            ) {
                return
            }

            if (Platform.OS === 'web') {
                return (context.navigationData = JSON.parse(
                    HelperMethods.decryptData(
                        sessionStorage.getItem(context.props.location.pathname + 'Data'),
                    ),
                ))
            } else {
                return (context.navigationData = context.props.location.state)
            }
        } catch (e) {
            // Future use of error data.
        }
    },

    setComponentWidth(component, event) {
        component.setState({ComponentWidth: event.nativeEvent.layout.width})
    },

    openURL(urlAddress) {
        if (Platform.OS === 'web') {
            window.open(urlAddress, '_blank')
        } else {
            Linking.openURL(urlAddress)
        }
    },

    replaceApostrophe(text) {
        let returnString = ''
        for (let counter = 0; counter < text.length; counter++) {
            if (text[counter].charCodeAt(0) == 8217) {
                returnString += String.fromCharCode(39)
            } else {
                returnString += text[counter]
            }
        }

        return returnString
    },

    getAndroidFilePermissions: function () {
        return new Promise(function (resolve) {
            if (Platform.OS === 'android') {
                resolve(
                    PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE),
                )
            } else {
                resolve(null)
            }
        })
    },

    downloadPDFMobile: function (filename, headers, path) {
        const authInjectedHeaders = {
            ...headers,
            AuthenticationToken: ABAPortalAPI.defaults.headers.common['AuthenticationToken'],
            abaId: ABAPortalAPI.defaults.headers.common['abaId']
                ? `${ABAPortalAPI.defaults.headers.common['abaId']}`
                : undefined,
        }
        HelperMethods.getAndroidFilePermissions()
            .then((result) => {
                if (result && result !== PermissionsAndroid.RESULTS.GRANTED) {
                    return
                }
                let RNFetchBlob = require('rn-fetch-blob').default
                let config = {}
                if (Platform.OS === 'android') {
                    config = {
                        addAndroidDownloads: {
                            useDownloadManager: true,
                            notification: true,
                            description: 'File downloaded by download manager.',
                            base64: true,
                            title: filename,
                            path: `${RNFetchBlob.fs.dirs.DownloadDir}/${filename}`,
                        },
                    }
                } else {
                    config = {
                        path: `${RNFetchBlob.fs.dirs.DocumentDir}/${filename}`,
                    }
                }
                RNFetchBlob.config(config)
                    .fetch('GET', `${ConfigurationProps.API_URL}${path}`, authInjectedHeaders)
                    .then((resp) => {
                        Platform.OS === 'ios' && RNFetchBlob.ios.openDocument(resp.path())
                    })
                    .catch((err) => {
                        HelperMethods.apiErrorHandler(err, 'Error Retrieving PDF')
                    })
            })
            .catch((error) => {
                HelperMethods.apiErrorHandler(error, 'Error Retrieving PDF')
            })
    },

    downloadPDFWeb: function (filename, headers, path) {
        const fileSaver = require('file-saver')
        ABAPortalAPI.get(path, {
            headers: headers,
            responseType: 'blob',
        })
            .then(function (response) {
                new Promise(function () {
                    fileSaver(response.data, filename)
                })
            })
            .catch(function (err) {
                HelperMethods.apiErrorHandler(err, 'Error Retrieving PDF')
            })
    },

    trackPageTransition: function (route) {
        const {userData} = store.getState()
        if (userData.hasBackDoorAccess) {
            return
        }

        const environment = {
            aba_environment: ConfigurationProps.ENVIRONMENT,
        }
        const page_title = route,
            page_location = route,
            screen_class = route,
            screen_name = route
        if (Platform.OS === 'web') {
            gtag('event', 'page_view', {
                page_title,
                page_location,
                ...environment,
            })
        } else {
            const analytics = require('@react-native-firebase/analytics').default
            analytics().logScreenView({
                screen_name,
                screen_class,
                ...environment,
            })
        }
    },

    objToRenderableString(obj) {
        var str = ''
        if (obj === null) return null
        objType = typeof obj
        if (Array.isArray(obj)) {
            return `[${obj.join(',')}]`
        }

        switch (objType) {
            case 'string':
                return `"${obj}"`
            case 'boolean':
            case 'number':
                return `${obj}`
            case 'undefined':
                return undefined
            case 'object':
                str = '{\n'
                for (var p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        var value = this.objToRenderableString(obj[p])
                        if (value != undefined && value !== null) {
                            str += `${p}:${value},\n`
                        }
                    }
                }
                return str + '}'
            default:
                return undefined
        }
    },

    calculateTime(time) {
        let now = Date.now()
        let timeDiff = moment(now).diff(time, 'days')

        // Hours & Minutes
        if (timeDiff <= 1) {
            let utc = moment.utc(time, 'YYYY-MM-DD HH:mm:ss')
            let local = utc.local()

            return `${local.fromNow()}`
        }

        // Days
        if (timeDiff > 1 && timeDiff < 7) {
            return `${moment(now).diff(time, 'days')} days ago`
        }

        // 1 Week
        if (timeDiff >= 7 && timeDiff < 14) {
            return `${moment(now).diff(time, 'weeks')} week ago`
        }

        // Weeks
        if (timeDiff >= 14 && timeDiff < 30) {
            return `${moment(now).diff(time, 'weeks')} weeks ago`
        }

        // 1 Month
        if (timeDiff >= 30 && timeDiff < 60) {
            return `${moment(now).diff(time, 'months')} month ago`
        }

        // Months
        if (timeDiff >= 30 && timeDiff < 365) {
            return `${moment(now).diff(time, 'months')} months ago`
        }

        // 1 Year
        if (timeDiff >= 365 && timeDiff < 730) {
            return `${moment(now).diff(time, 'years')} year ago`
        }

        // Years
        if (timeDiff >= 730) {
            return `${moment(now).diff(time, 'years')} years ago`
        }
    },

    checkIsIE() {
        return Platform.OS === 'web' && /*@cc_on!@*/ (false || !!document.documentMode)
    },

    clearValidation(InputStatus) {
        for (let key in InputStatus) {
            InputStatus[key] = GeneralEnums.textInputStatus.default
        }
    },

    getUserRole(userRole, setUserRole) {
        if (userRole !== null && userRole !== undefined) return

        ABAPortalAPI.get('dashboard/getUserRoleId')
            .then(function (response) {
                setUserRole(response.data)
            })
            .catch(function (error) {
                HelperMethods.apiErrorHandler(error, 'Error Retrieving Role Id')
                resolve(null)
            })
    },

    //https://www.freecodecamp.org/news/three-ways-to-title-case-a-sentence-in-javascript-676a9175eb27/
    properCase(str) {
        if (!str) {
            return str
        }
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ')
    },

    isCountryDomestic(country) {
        return (
            country === GeneralEnums.cert_CountryId.USA || country === GeneralEnums.cert_CountryId.Canada
        )
    },

    // Inspired by https://stackoverflow.com/a/26215431
    // Recursively converts javascript camelCase objects to C# PascalCase by raising the first character of every key
    toPascal: (camelObject) => {
        if (camelObject instanceof Array) {
            return camelObject.map((value) => {
                if (typeof value === 'object' || value instanceof Array) {
                    return HelperMethods.toPascal(value)
                }
                return value
            })
        }
        const pascalObject = {}
        Object.keys(camelObject).forEach((origKey) => {
            const newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString()
            let value = camelObject[origKey]
            if (value instanceof Array || (value !== null && value.constructor === Object)) {
                value = HelperMethods.toPascal(value)
            }
            pascalObject[newKey] = value
        })
        return pascalObject
    },

    //opposite of toPascal
    toCamel: (pascalObject) => {
        if (pascalObject instanceof Array) {
            return pascalObject.map((value) => {
                if (typeof value === 'object' || value instanceof Array) {
                    return HelperMethods.toCamel(value)
                }
                return value
            })
        }
        const camelObject = {}
        Object.keys(pascalObject).forEach((origKey) => {
            const newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
            let value = pascalObject[origKey]
            if (value instanceof Array || (value !== null && value.constructor === Object)) {
                value = HelperMethods.toCamel(value)
            }
            camelObject[newKey] = value
        })
        return camelObject
    },

    acaCheckboxPadding: (value) => {
        if (Platform.OS === 'web' && value != 1) {
            return 60
        } else if (Platform.OS !== 'web' && value != 1) {
            return 40
        } else {
            return 20
        }
    },
}

export default HelperMethods
