import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import clinicalActivityLogicStep3 from '../logic/clinicalActivityLogicStep3'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABAAffirmationRadio from '../../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABATextInput from '../../../ABACustom/presentation/ABATextInput'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import ABATextInputMask from '../../../ABACustom/presentation/ABATextInputMask'
import ABADropdown from '../../../ABACustom/presentation/ABADropdown'
import ABATextInputMultiline from '../../../ABACustom/presentation/ABATextInputMultiline'
import ABAConfirmationBox from '../../../ABACustom/presentation/ABAConfirmationBox'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABAModal from '../../../ABACustom/presentation/ABAModal'
import HelperMethods from '../../../../helpers/HelperMethods'

const styles = StyleSheet.create({
    confirmationBox: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
})

export default class ClinicalActivityStep3 extends Component {
    state = {
        CallingApi: false,
        ComponentWidth: null,
        stateList: [],
        countryList: [],
        cert_workprivileges: null,
        cert_startdate: '',
        cert_city: '',
        cert_stateid: 'null',
        cert_country: 'null',
        cert_phone: '',
        cert_fax: '',
        cert_haverestrictions: null,
        cert_restrictionsdescription: '',
        cert_locumtenensdetails: '',
        cert_organization: '',
        original_cert_workprivileges: null,
        referenceIDs: [],
        showDeleteConfirm: false,
        cert_startdateError: 'Please enter a valid date.',
        cert_phoneError: 'Please enter a value.',
        cert_stateorprovince: '',
        InputStatus: {
            cert_workprivileges: GeneralEnums.textInputStatus.default,
            practiceFacility: GeneralEnums.textInputStatus.default,
            practiceIndependent: GeneralEnums.textInputStatus.default,
            practiceLocum: GeneralEnums.textInputStatus.default,
            cert_haverestrictions: GeneralEnums.textInputStatus.default,
            cert_restrictionsdescription: GeneralEnums.textInputStatus.default,
            cert_locumtenensdetails: GeneralEnums.textInputStatus.default,
            cert_startdate: GeneralEnums.textInputStatus.default,
            cert_organization: GeneralEnums.textInputStatus.default,
            cert_city: GeneralEnums.textInputStatus.default,
            cert_stateid: GeneralEnums.textInputStatus.default,
            cert_country: GeneralEnums.textInputStatus.default,
            cert_phone: GeneralEnums.textInputStatus.default,
            cert_fax: GeneralEnums.textInputStatus.default,
            cert_stateorprovince: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        Promise.all([
            clinicalActivityLogicStep3.GetCountryListData(),
            clinicalActivityLogicStep3.GetClinicalActivityData(),
        ]).then(([DataCountry, CAData]) => {
            clinicalActivityLogicStep3
                .GetStateListData(CAData.cert_country || GeneralEnums.cert_CountryId.USA)
                .then((DataState) => {
                    clinicalActivityLogicStep3.GetReferenceIDs().then((referenceIDs) => {
                        clinicalActivityLogicStep3.PopulateVariables(
                            CAData,
                            DataCountry,
                            DataState,
                            referenceIDs,
                        )
                    })
                })
        })
    }

    Save() {
        return clinicalActivityLogicStep3.SaveLogic()
    }

    Previous() {
        return clinicalActivityLogicStep3.Previous()
    }

    countryChange(countryId) {
        if (!countryId || countryId === 'null') {
            return
        }

        const {InputStatus} = this.state
        InputStatus.cert_stateid = GeneralEnums.textInputStatus.default
        InputStatus.cert_stateorprovince = GeneralEnums.textInputStatus.default

        clinicalActivityLogicStep3.getStatesProvinces(countryId).then((stateList) => {
            this.setState({
                stateList,
                cert_stateorprovince: '',
                InputStatus,
                cert_stateid: 'null',
            })
        })
    }

    renderState() {
        return (
            <View>
                {this.state.stateList.length > 0 ? (
                    <View style={{marginBottom: 30}}>
                        <ABADropdown
                            options={this.state.stateList}
                            label="State *"
                            placeholder="State *"
                            onChange={(cert_stateid) => this.setState({cert_stateid})}
                            selectedValue={this.state.cert_stateid}
                            validationStatus={this.state.InputStatus.cert_stateid}
                            width="100%"
                        />
                    </View>
                ) : (
                    <ABATextInput
                        onChangeText={(cert_stateorprovince) => this.setState({cert_stateorprovince})}
                        placeholder="State *"
                        textInputStatus={this.state.InputStatus.cert_stateorprovince}
                        returnKeyType="next"
                        width="100%"
                        value={this.state.cert_stateorprovince}
                    />
                )}
            </View>
        )
    }

    render() {
        clinicalActivityLogicStep3.context = this
        let currentStep = 2
        let completionPercentage

        if (!this.props.isConfirmationPage) {
            completionPercentage = (1 / registrationStepsDisplayContext.state.CAstepCount) * currentStep
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text>
                            Page {currentStep} of {registrationStepsDisplayContext.state.CAstepCount}
                        </Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(
                                    this.state.ComponentWidth,
                                    completionPercentage,
                                ),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0.5),
                            ]}
                        />
                    </View>
                )}
                <View style={{borderBottomWidth: 1, borderColor: ColorEnums.cloudBurst}}>
                    <Text style={{fontWeight: 'bold', fontFamily: 'Lato', paddingBottom: 15}}>
                        PLEASE SELECT FROM ONE OF THE STATEMENTS BELOW
                        <ABARequiredAsterisk />
                    </Text>
                    <View
                        style={{
                            borderTopWidth: 1,
                            borderColor: ColorEnums.cloudBurst,
                            marginBottom: 20,
                            paddingTop: 15,
                        }}
                    >
                        <ABACheckbox
                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                            isChecked={this.state.cert_workprivileges == 1}
                            label={
                                'I currently practice or have practiced in a facility and/or I was enrolled in a training program during the past three years.'
                            }
                            onChangeEvent={() => {
                                clinicalActivityLogicStep3.SetWorkPrivilege(1)
                            }}
                            validationStatus={this.state.InputStatus.practiceFacility}
                            width="100%"
                        />
                    </View>
                    <View style={{marginBottom: 20}}>
                        <ABACheckbox
                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                            isChecked={this.state.cert_workprivileges == 3}
                            label={'I engage in independent office-based practice.'}
                            onChangeEvent={() => {
                                clinicalActivityLogicStep3.SetWorkPrivilege(3)
                            }}
                            validationStatus={this.state.InputStatus.practiceIndependent}
                            width="100%"
                        />
                    </View>
                    <View style={{marginBottom: 20}}>
                        <ABACheckbox
                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                            isChecked={this.state.cert_workprivileges == 2}
                            label={'I engage in locum tenens practice at more than one facility.'}
                            onChangeEvent={() => {
                                clinicalActivityLogicStep3.SetWorkPrivilege(2)
                            }}
                            validationStatus={this.state.InputStatus.practiceLocum}
                            width="100%"
                        />
                    </View>
                </View>
                {this.state.InputStatus.cert_workprivileges === GeneralEnums.textInputStatus.error && (
                    <View>
                        <Text style={[styleFunctions.paragraphText(), {color: ColorEnums.pomegranate}]}>
                            Please select one of the statements above.
                        </Text>
                    </View>
                )}
                {this.state.cert_workprivileges == 2 && (
                    <View>
                        <Text style={{paddingTop: 15}}>
                            You indicated that during this period of clinical activity you worked in a
                            locum tenens capacity. You must provide the facility/institution name, Chief
                            of Anesthesia, street address of the institution, city, state, and zip code
                            as well as the phone number and dates of employment for each facility at
                            which you did locum tenens work during this report period.
                        </Text>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                {fontSize: 14, fontWeight: 'bold', paddingTop: 15},
                            ]}
                        >
                            Details
                            <ABARequiredAsterisk />
                        </Text>
                        <ABATextInputMultiline
                            maxLength={5000}
                            numberOfLines={8}
                            onChangeText={(text) => this.setState({cert_locumtenensdetails: text})}
                            value={this.state.cert_locumtenensdetails}
                            textInputStatus={this.state.InputStatus.cert_locumtenensdetails}
                            placeholder="Please provide the details about your locum tenens practice"
                        />
                    </View>
                )}

                {this.state.cert_workprivileges !== null && this.state.cert_workprivileges !== 5 && (
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 25, fontWeight: 'bold', width: '90%'},
                                ]}
                            >
                                CLINICAL ACTIVITY DETAILS
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                ]}
                            >
                                Please fill out the information below about your current Clinical
                                Activity
                            </Text>
                            <View
                                style={{
                                    flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                                    width: '100%',
                                }}
                            >
                                <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                                    <ABATextInputMask
                                        keyboardType="number-pad"
                                        maxLength={10}
                                        onChangeText={(text) => {
                                            this.setState({cert_startdate: text})
                                        }}
                                        options={{mask: '99/99/9999'}}
                                        placeholder="From (mm/dd/yyyy) *"
                                        returnKeyType="next"
                                        style={styleFunctions.textInput()}
                                        textInputStatus={this.state.InputStatus.cert_startdate}
                                        value={this.state.cert_startdate}
                                        marginLeft={0}
                                        marginRight={0}
                                        width={this.state.ComponentWidth > 600 ? '95%' : '100%'}
                                        errorMessage={this.state.cert_startdateError}
                                    />
                                </View>
                                <View
                                    style={{
                                        width: this.state.ComponentWidth > 600 ? '50%' : '100%',
                                        justifyContent: 'center',
                                        paddingBottom: this.state.ComponentWidth > 600 ? 0 : 20,
                                    }}
                                >
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {fontSize: 14, fontWeight: 'bold', width: '90%'},
                                        ]}
                                    >
                                        To present
                                    </Text>
                                </View>
                            </View>
                            <View style={{width: '100%'}}>
                                <ABATextInput
                                    placeholder="Institution/Practice *"
                                    returnKeyType="next"
                                    textInputStatus={this.state.InputStatus.cert_organization}
                                    onChangeText={(text) => this.setState({cert_organization: text})}
                                    value={this.state.cert_organization}
                                    width="100%"
                                    maxLength={100}
                                />
                                <View style={{marginBottom: 30}}>
                                    <ABADropdown
                                        options={this.state.countryList}
                                        label="Country *"
                                        placeholder="Country *"
                                        onChange={(value) => {
                                            this.setState({cert_country: value})
                                            this.countryChange(value)
                                        }}
                                        selectedValue={this.state.cert_country}
                                        validationStatus={this.state.InputStatus.cert_country}
                                        width="100%"
                                    />
                                </View>
                                <ABATextInput
                                    onChangeText={(text) => this.setState({cert_city: text})}
                                    placeholder="City *"
                                    textInputStatus={this.state.InputStatus.cert_city}
                                    returnKeyType="next"
                                    value={this.state.cert_city}
                                    width="100%"
                                    maxLength={100}
                                />

                                {/* Render Correct State Field */}
                                {this.renderState()}

                                <View
                                    style={{
                                        flexDirection:
                                            this.state.ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}
                                    >
                                        <ABATextInputMask
                                            keyboardType="number-pad"
                                            maxLength={14}
                                            onChangeText={(text) => this.setState({cert_phone: text})}
                                            options={{mask: '(999) 999-9999'}}
                                            placeholder="Phone *"
                                            returnKeyType="next"
                                            textInputStatus={this.state.InputStatus.cert_phone}
                                            errorMessage={this.state.cert_phoneError}
                                            value={this.state.cert_phone}
                                            marginLeft={0}
                                            marginRight={0}
                                            width={this.state.ComponentWidth > 600 ? '95%' : '100%'}
                                        />
                                    </View>
                                    <View
                                        style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}
                                    >
                                        <ABATextInputMask
                                            keyboardType="number-pad"
                                            maxLength={14}
                                            onChangeText={(text) => this.setState({cert_fax: text})}
                                            options={{mask: '(999) 999-9999'}}
                                            placeholder="Fax"
                                            returnKeyType="next"
                                            textInputStatus={this.state.InputStatus.cert_fax}
                                            value={this.state.cert_fax}
                                            marginLeft={0}
                                            marginRight={0}
                                            width="100%"
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                ]}
                            >
                                RESTRICTIONS AND LIMITATIONS
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                ]}
                            >
                                Have your clinical privileges at this institution been relinquished,
                                limited, suspended, or revoked?
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View style={{paddingBottom: 20}}>
                            <View style={{width: '50%', alignSelf: 'center', paddingTop: 25}}>
                                <ABAAffirmationRadio
                                    leftOptionContent="Yes"
                                    leftOptionOnPress={
                                        clinicalActivityLogicStep3.RestrictionsYesSelected
                                    }
                                    rightOptionContent="No"
                                    rightOptionOnPress={
                                        clinicalActivityLogicStep3.RestrictionsNoSelected
                                    }
                                    optionValues={{leftValue: true, rightValue: false}}
                                    selectedValue={this.state.cert_haverestrictions}
                                    validationStatus={this.state.InputStatus.cert_haverestrictions}
                                />
                            </View>
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, fontWeight: 'bold', paddingTop: 15},
                                    ]}
                                >
                                    Note:
                                    <Text style={{fontWeight: 'normal', paddingLeft: 3}}>
                                        If you Respond "Yes", you are required to provide a personal
                                        statement explaining the details of any action(s) taken against
                                        your clinical privileges.
                                    </Text>
                                </Text>
                            </View>
                        </View>
                        {this.state.cert_haverestrictions && (
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, fontWeight: 'bold', paddingTop: 15},
                                    ]}
                                >
                                    Details <ABARequiredAsterisk />
                                </Text>
                                <ABATextInputMultiline
                                    maxLength={5000}
                                    numberOfLines={8}
                                    onChangeText={(text) =>
                                        this.setState({cert_restrictionsdescription: text})
                                    }
                                    value={this.state.cert_restrictionsdescription}
                                    textInputStatus={this.state.InputStatus.cert_restrictionsdescription}
                                    placeholder="Please provide the terms and conditions of the restrictions on your privileges"
                                />
                            </View>
                        )}
                    </View>
                )}
                {this.state.showDeleteConfirm && (
                    <ABAModal
                        isVisible={this.state.showDeleteConfirm}
                        onDismiss={() => this.setState({showDeleteConfirm: false})}
                        onBackdropPress={() => this.setState({showDeleteConfirm: false})}
                    >
                        <ABAConfirmationBox
                            messageText1="This will remove all of the data that has been entered past this point for Clinical Activity. Are you sure you want to continue?"
                            buttonLeftText="Ok"
                            buttonRightText="Cancel"
                            leftButtonFunction={() => clinicalActivityLogicStep3.DeleteReferenceData()}
                            rightButtonFunction={() => this.setState({showDeleteConfirm: false})}
                        />
                    </ABAModal>
                )}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Clinical Activity Page 2</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
