import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import TrainingPage from '../../clinicalactivity/presentation/trainingPage'
import ACAExamPath3 from '../presentation/acaExamPath3'

const ACAExamPath3Logic = {
    context: null,

    ValidatePage() {
        const {nbeCert, postTraining} = this.context.state

        if (!nbeCert) {
            this.context.setState({
                primaryError: true,
            })
            return false
        } else if (nbeCert == 2 && !postTraining) {
            this.context.setState({
                secondaryError: true,
            })
            return false
        }

        return true
    },

    UpdateACAExamPathPage() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {acaPathway, nbeCert, clinicalActivityId, postTraining} = this.context.state
            const {ProgramType: re_ProgramType} = this.context.props
            const {ContactId} = clinicalActivityContext.state

            ABAPortalAPI.post(`clinicalactivity/updateACAPathwayPage`, {
                re_ProgramType,
                ContactId,
                cert_clinicalactivityid: clinicalActivityId,
                cert_acapath: acaPathway,
                cert_acanbecert: nbeCert,
                cert_acaposttraining: postTraining,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateACAExamPathPage().then((response) => {
                if (response) {
                    clinicalActivityContext.setState({
                        CurrentComponentStep: TrainingPage,
                        PreviousComponentStep: ACAExamPath3,
                    })

                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },
}

export default ACAExamPath3Logic
