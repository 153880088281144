import React, {Component} from 'react'
import {Text, View, StyleSheet} from 'react-native'

import moment from 'moment'
import PropTypes from 'prop-types'
import ABATextInput from '../ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../ABACustom/presentation/ABATextInputMask'
import ABAAccordion from '../ABACustom/presentation/ABAAccordion'
import ABAConfirmationBox from '../ABACustom/presentation/ABAConfirmationBox'
import SubstanceAbuseSubstanceLogic from '../logic/substanceAbuseSubstanceLogic'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },

    confirmationBox: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
})

export default class SubstanceAbuseSubstance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ComponentWidth: null,
            originalSubstanceData: null,
            cert_SubstanceBeganDate: '',
            cert_name: '',
            cert_LengthofTime: '',
            cert_AvenueofAdministration: '',
            cert_SAsubstanceId: '',
            canEdit: false,
            CallingApi: true,
            showDeleteConfirm: false,
            substanceBeganDateError: false,
            InputStatus: {
                cert_SubstanceBeganDate: GeneralEnums.textInputStatus.default,
                cert_name: GeneralEnums.textInputStatus.default,
                cert_LengthofTime: GeneralEnums.textInputStatus.default,
                cert_AvenueofAdministration: GeneralEnums.textInputStatus.default,
            },
        }

        this.SubstanceAbuseSubstanceLogic = Object.assign({}, SubstanceAbuseSubstanceLogic)
        this.SubstanceAbuseSubstanceLogic.context = this
    }

    componentDidMount() {
        this.SubstanceAbuseSubstanceLogic.GetSubstanceAbuseSubstance().then((SASData) => {
            this.SubstanceAbuseSubstanceLogic.PopulateVariables(SASData)
        })
    }

    render() {
        const {
            cert_SubstanceBeganDate,
            cert_name,
            cert_LengthofTime,
            cert_AvenueofAdministration,
            ComponentWidth,
            CallingApi,
            originalSubstanceData,
            canEdit,
            InputStatus,
            substanceBeganDateError,
        } = this.state

        const primaryColumnWidth = '70%'
        const secondaryColumnWidth = '30%'

        return (
            <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                {!CallingApi && (
                    <ABAAccordion
                        title={originalSubstanceData.cert_name.toUpperCase()}
                        accordionContainerStyle={styleFunctions.Accordioncontainer()}
                        accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                        accordionTitleStyle={styles.accordionComponentTitle}
                        accordionButtonStyle={styles.accordionTitlebutton}
                        accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                        accordionExpanded={false}
                        accordionUnderlayColor={ColorEnums.white}
                        accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                        accordionContent={
                            <View>
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 15,
                                                    width: '100%',
                                                    marginRight: 10,
                                                    paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                                            Date Substance Abuse Began:
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <ABATextInputMask
                                            errorMessage={
                                                substanceBeganDateError
                                                    ? 'The date substance abuse began must be before the last evidenced episode date'
                                                    : 'Please enter a valid date'
                                            }
                                            keyboardType="number-pad"
                                            marginLeft={0}
                                            marginRight={0}
                                            maxLength={10}
                                            onChangeText={(cert_SubstanceBeganDate) =>
                                                this.setState({cert_SubstanceBeganDate})
                                            }
                                            options={{mask: '99/99/9999'}}
                                            placeholder="(mm/dd/yyyy)"
                                            returnKeyType="next"
                                            style={styleFunctions.textInput()}
                                            textInputStatus={
                                                canEdit
                                                    ? InputStatus.cert_SubstanceBeganDate
                                                    : GeneralEnums.textInputStatus.disabled
                                            }
                                            value={cert_SubstanceBeganDate}
                                            width="100%"
                                        />
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 15,
                                                    width: '100%',
                                                    marginRight: 10,
                                                    paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                                            Substance Abused:
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <ABATextInput
                                            marginLeft={0}
                                            marginRight={0}
                                            maxLength={100}
                                            onChangeText={(cert_name) => this.setState({cert_name})}
                                            returnKeyType="next"
                                            textInputStatus={
                                                canEdit
                                                    ? InputStatus.cert_name
                                                    : GeneralEnums.textInputStatus.disabled
                                            }
                                            value={cert_name && cert_name.toString()}
                                            width="100%"
                                        />
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 15,
                                                    width: '100%',
                                                    marginRight: 10,
                                                    paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                                            Length of Time Substance Abused Prior to Initial Treatment:
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <ABATextInput
                                            marginLeft={0}
                                            marginRight={0}
                                            maxLength={100}
                                            onChangeText={(cert_LengthofTime) =>
                                                this.setState({cert_LengthofTime})
                                            }
                                            returnKeyType="next"
                                            textInputStatus={
                                                canEdit
                                                    ? InputStatus.cert_LengthofTime
                                                    : GeneralEnums.textInputStatus.disabled
                                            }
                                            value={cert_LengthofTime && cert_LengthofTime.toString()}
                                            width="100%"
                                        />
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? primaryColumnWidth : '100%',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                {
                                                    fontSize: 15,
                                                    width: '100%',
                                                    marginRight: 10,
                                                    paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <Text style={{color: ColorEnums.pomegranate}}> *</Text>
                                            Avenue of Administration:
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: ComponentWidth > 600 ? secondaryColumnWidth : '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <ABATextInput
                                            marginLeft={0}
                                            marginRight={0}
                                            maxLength={100}
                                            onChangeText={(cert_AvenueofAdministration) =>
                                                this.setState({cert_AvenueofAdministration})
                                            }
                                            returnKeyType="next"
                                            textInputStatus={
                                                canEdit
                                                    ? InputStatus.cert_AvenueofAdministration
                                                    : GeneralEnums.textInputStatus.disabled
                                            }
                                            value={
                                                cert_AvenueofAdministration &&
                                                cert_AvenueofAdministration.toString()
                                            }
                                            width="100%"
                                        />
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: ComponentWidth > 600 ? 'flex-end' : 'flex-start',
                                    }}
                                >
                                    <Text
                                        style={[styles.accordionTextLinkStyle, {marginLeft: 0}]}
                                        onPress={
                                            canEdit
                                                ? () =>
                                                      this.SubstanceAbuseSubstanceLogic.UpdateSubstanceAbuseSubstance()
                                                : () =>
                                                      this.SubstanceAbuseSubstanceLogic.SetSubstanceFieldsToEditable()
                                        }
                                    >
                                        {canEdit ? 'SAVE' : 'EDIT'}
                                    </Text>
                                    <Text
                                        style={styles.accordionTextLinkStyle}
                                        onPress={() =>
                                            this.SubstanceAbuseSubstanceLogic.CancelSubstanceAbuseSubstanceChanges()
                                        }
                                    >
                                        CANCEL
                                    </Text>
                                    <Text
                                        style={styles.accordionTextLinkStyle}
                                        onPress={() => {
                                            this.setState({showDeleteConfirm: true})
                                        }}
                                    >
                                        DELETE
                                    </Text>
                                </View>
                            </View>
                        }
                    />
                )}
                {this.state.showDeleteConfirm && (
                    <View style={styles.confirmationBox}>
                        <ABAConfirmationBox
                            messageText1="Are you sure you want to delete this substance?"
                            messageText2={this.state.cert_name}
                            buttonLeftText="Yes"
                            buttonRightText="No"
                            leftButtonFunction={() =>
                                this.SubstanceAbuseSubstanceLogic.DeleteSubstance()
                            }
                            rightButtonFunction={() => this.setState({showDeleteConfirm: false})}
                        />
                    </View>
                )}
            </View>
        )
    }
}

SubstanceAbuseSubstance.propTypes = {
    SubstanceAbuseSubstanceID: PropTypes.string.isRequired,
}
