import {GeneralEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import SubstanceAbuseStep4 from '../presentation/substanceAbuseStep4'
import SubstanceAbuseStep6 from '../presentation/substanceAbuseStep6'

const SubstanceAbuseLogicStep5 = {
    context: null,

    GetSubstanceAbuseData() {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('substanceabuseinfo/getSAData', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetSAReferences() {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('reference/getReferenceIDs', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetTreatmentReferences() {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        const {AfterCare_Monitoring} = GeneralEnums.cert_referenceType
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    Type: AfterCare_Monitoring,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get('reference/getReferenceIDs', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Treatment Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddExistingReference(referenceType, referenceID, context) {
        SubstanceAbuseLogicStep5.context.setState({
            showReferenceList: false,
            addNewTreatmentReference: true,
            addNewReferenceID: referenceID,
        })
    },

    AddNewReference() {
        SubstanceAbuseLogicStep5.context.setState(
            {
                showReferenceList: false,
                addNewTreatmentReference: true,
                addNewReferenceID: '-1',
            },
            () => {
                registrationStepsDisplayContext.setState({ShowContinue: false})
            },
        )
    },
    CancelAddReference() {
        SubstanceAbuseLogicStep5.context.setState({
            collapsedReference: null,
        })
    },
    PopulateVariables(SAData) {
        const [SubstanceAbuseData, referenceIDList, treatmentReferenceIDList] = SAData
        if (SubstanceAbuseData) {
            this.context.setState({
                ReferenceIDList: referenceIDList || [],
                TreatmentReferenceIDList: treatmentReferenceIDList || [],
            })
        }
    },

    UpdateGrid(index, validated = true) {
        if (!validated) {
            return
        }
        SubstanceAbuseLogicStep5.GetTreatmentReferences().then((TreatmentReferenceIDList) => {
            SubstanceAbuseLogicStep5.GetSAReferences().then((ReferenceIDList) => {
                SubstanceAbuseLogicStep5.context.setState(
                    {
                        TreatmentReferenceIDList,
                        ReferenceIDList,
                        addNewTreatmentReference: false,
                        collapsedReference: null,
                    },
                    () => {
                        registrationStepsDisplayContext.setState({ShowContinue: true})
                    },
                )
            })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep6,
            })
            resolve(false)
        })
    },

    Previous() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep4,
            })
            resolve(false)
        })
    },
}

export default SubstanceAbuseLogicStep5
