import React, {Component} from 'react'
import {View, Text, ActivityIndicator, Platform} from 'react-native'
import HelperMethods from '../../../../helpers/HelperMethods'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import styleFunctions from '../../../../styles/styleFunctions'
import ACAExamPath1Logic from '../logic/acaExamPath1Logic'
import acaPathwayLogic from '../../clinicalactivity/logic/acaPathwayPageLogic'
import ACAPathwayPage from '../../clinicalactivity/presentation/acaPathwayPage'
import {ColorEnums} from '../../../../helpers/generalEnums'

export default class ACAExamPath1 extends Component {
    state = {
        CallingApi: false,
        nbeCertOptions: [],
        nbeCert: null,
        postTrainingOptions: [],
        postTraining: null,
        primaryError: null,
        secondaryError: null,
        acaPathway: null,
    }

    componentDidMount() {
        const {GetACANBECerts, GetACAPostTrainingOptions} = ACAExamPath1Logic
        const {GetClinicalActivityData} = acaPathwayLogic

        Promise.all([GetACANBECerts(), GetACAPostTrainingOptions(), GetClinicalActivityData()]).then(
            (data) => {
                const nbeCertOptions = data[0]
                const postTrainingOptions = data[1]
                const clinicalActivity = data[2]

                this.setState({
                    nbeCertOptions,
                    postTrainingOptions,
                    acaPathway: clinicalActivity.cert_acapath,
                    nbeCert: clinicalActivity.cert_acanbecert,
                    postTraining: clinicalActivity.cert_acaposttraining,
                    clinicalActivityId: clinicalActivity.cert_clinicalactivityid,
                })
            },
        )
    }

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({
                CurrentComponentStep: ACAPathwayPage,
            })
            resolve(false)
        })
    }

    Save() {
        return ACAExamPath1Logic.SaveLogic()
    }

    renderNBECertOptions() {
        const {nbeCert} = this.state

        const renderMargin = (i) => {
            if (Platform.OS === 'android') {
                if (i === 0) {
                    return 25
                }
                if (i === 2) {
                    return 20
                }
            }
        }

        return this.state.nbeCertOptions.map((option, index) => {
            const {value, label} = option
            if (value == 2) {
                return
            }
            return (
                <View
                    style={{
                        marginBottom: 15,
                        marginLeft: Platform.OS === 'ios' && index === 0 ? -5 : 0,
                        marginRight: renderMargin(index),
                    }}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={nbeCert === value}
                        label={label}
                        labelStyle={{
                            border: `1px solid ${ColorEnums.exLightGray}`,
                            padding: 10,
                            color: ColorEnums.cloudBurst,
                        }}
                        onChangeEvent={() => {
                            this.setState({
                                nbeCert: nbeCert === value ? null : value,
                                postTraining: null,
                                primaryError: null,
                                secondaryError: null,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    renderPostTrainingOptions() {
        const {postTraining} = this.state
        if (this.state.nbeCert != 4) {
            return
        }

        return this.state.postTrainingOptions.map((option) => {
            const {value, label} = option

            return (
                <View
                    style={{
                        marginBottom: 15,
                        paddingLeft: HelperMethods.acaCheckboxPadding(value),
                    }}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: Platform.OS === 'web' ? 10 : 0,
                        }}
                        isChecked={postTraining === value}
                        label={label}
                        labelStyle={{
                            fontSize: 14,
                            color: ColorEnums.cloudBurst,
                        }}
                        onChangeEvent={() => {
                            this.setState({
                                postTraining: postTraining === value ? null : value,
                                primaryError: null,
                                secondaryError: null,
                            })
                        }}
                        width="100%"
                    />
                    {value == 1 && (
                        <Text
                            style={{
                                paddingLeft: Platform.OS === 'web' ? 70 : 50,
                                fontSize: 14,
                                color: ColorEnums.cloudBurst,
                                paddingTop: 20,
                            }}
                        >
                            I have spent the following amount of clinical time dedicated to the
                            anesthetic management of patients undergoing cardiac procedures, and at least
                            20 cases per year required cardiopulmonary bypass or other mechanical
                            circulatory support:
                        </Text>
                    )}
                </View>
            )
        })
    }

    render() {
        ACAExamPath1Logic.context = this
        acaPathwayLogic.context = this

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 20,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        Adult Cardiac Anesthesiology Pathway
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 16,
                                paddingTop: 15,
                                width: '100%',
                                marginBottom: 15,
                            },
                        ]}
                    >
                        Please select your Adult Cardiac Anesthesiology entrance pathway:
                        <ABARequiredAsterisk />
                    </Text>
                    {this.state.primaryError && (
                        <Text style={{color: ColorEnums.pomegranate, paddingBottom: 5}}>
                            Please select a pathway
                        </Text>
                    )}
                    <View>{this.renderNBECertOptions()}</View>
                    <View>
                        {this.state.secondaryError && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    paddingBottom: 5,
                                    paddingLeft: 120,
                                }}
                            >
                                Please select one of the options below
                            </Text>
                        )}
                        {this.renderPostTrainingOptions()}
                    </View>
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
