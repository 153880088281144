import React, {Component} from 'react'
import {View} from 'react-native'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'

import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import NavigationShell from '../../components/presentation/navigationShell'
import PracticeProfileLogic from '../logic/practiceProfileLogic'
import PracticeAreaAndSetting from '../../components/registrationsteps/presentation/practiceAreaAndSetting'
import styleFunctions from '../../styles/styleFunctions'

export default class PracticeProfile extends Component {
    render() {
        practiceProfileContext = this
        navigationContext = this
        PracticeProfileLogic.context = practiceProfileContext

        HelperMethods.setNavigationData(practiceProfileContext)

        return (
            <NavigationShell context={practiceProfileContext}>
                <ABAKeyboardAwareScrollView
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        paddingBottom: 50,
                    }}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.PracticeProfile} />
                    <ABAContainer containerTitle={'PRACTICE AREA & LOCATION'}>
                        <View
                            style={{width: '95%', alignSelf: 'center', maxWidth: 700}}
                            onLayout={(event) =>
                                HelperMethods.setComponentWidth(PracticeProfileLogic.context, event)
                            }
                        >
                            {
                                <PracticeAreaAndSetting
                                    ref={(child) => {
                                        PracticeProfileLogic.context.child = child
                                    }}
                                    fromPracticeProfile={true}
                                />
                            }
                        </View>
                        <View
                            style={{
                                width: '90%',
                                alignSelf: 'center',
                                paddingTop: 25,
                                paddingBottom: 20,
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '50%'}}>
                                    <ABATouchableHighlight
                                        buttonStyle={styleFunctions.buttonClickInverted()}
                                        onPressEvent={PracticeProfileLogic.GoBack}
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 16,
                                        }}
                                        text={'Back'}
                                    />
                                </View>
                                <View style={{width: '50%'}}>
                                    <ABATouchableHighlight
                                        icon={'angle-right'}
                                        onPressEvent={PracticeProfileLogic.Submit}
                                        text={'Save'}
                                    />
                                </View>
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
