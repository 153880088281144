import React, {Component} from 'react'
import {
    View,
    Text,
    StyleSheet,
    ActivityIndicator,
    Platform,
    TouchableWithoutFeedback,
    TouchableOpacity,
} from 'react-native'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import PropTypes from 'prop-types'

import ABAHyperLink from '../../../components/ABACustom/presentation/ABAHyperLink'
import MedicalLicenseLogic from '../logic/medicalLicenseReleaseLogic'
import NavigationHelper from '../../../helpers/NavigationHelper'
import styleFunctions from '../../../styles/styleFunctions'
import ABAAffirmationRadio from '../../../components/ABACustom/presentation/ABAAffirmationRadio'
import ABATextInputMultiline from '../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABARequiredAsterisk from '../../../components/ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../helpers/HelperMethods'

const styles = StyleSheet.create({
    horizontalRule: {
        borderBottomColor: ColorEnums.cloudBurst,
        borderBottomWidth: 3,
        marginBottom: 20,
        marginTop: 10,
    },

    hyperLink: {
        color: ColorEnums.danube,
        fontFamily: 'Lato',
        fontSize: 18,
        marginTop: 10,
        marginBottom: 20,
    },

    iconSymbol: {
        textAlign: 'left',
        fontFamily: GeneralEnums.fontAwesomeFamilyName,
        color: ColorEnums.danube,
        fontSize: 18,
    },

    linkRow: {
        borderBottomColor: ColorEnums.backgroundGray,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
    },

    mainTitle: {
        backgroundColor: ColorEnums.cloudburst,
        color: ColorEnums.white,
        fontFamily: 'Lato',
        fontSize: 18,
        fontWeight: 'bold',
        padding: 10,
        textAlign: 'center',
        textAlignVertical: 'center',
    },

    pageHeading: {
        color: ColorEnums.danube,
        flexDirection: 'row',
        fontFamily: 'Lato',
        fontSize: 24,
        marginBottom: 10,
        marginTop: 20,
        padding: 30,
        textAlign: 'center',
    },

    medicalLicenseView: {
        borderBottomWidth: 1,
        borderBottomColor: ColorEnums.blueGray,
        flexDirection: 'row',
        height: 40,
    },

    medicalLicenseTextHeader: {
        color: ColorEnums.blueGray,
        fontFamily: 'Lato',
        fontSize: 14,
        marginTop: 10,
        paddingBottom: 2,
    },

    medicalLicenseTextBody: {
        color: ColorEnums.cloudBurst,
        flexDirection: 'row',
        fontFamily: 'Lato',
        fontSize: 14,
        textAlignVertical: 'center',
    },

    iconSymbol: {
        color: ColorEnums.cloudBurst,
        fontFamily: GeneralEnums.fontAwesomeFamilyName,
        fontSize: 18,
        textAlign: 'left',
        textAlignVertical: 'center',
    },
})

export default class MedicalLicense extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ABAAffirmationRadioError: null,
            CallingApi: false,
            ComponentWidth: null,
            medicalBoardLinks: null,
            medicalLicenseComponents: [],
            MLReleaseData: null,
            NoteError: null,
            NoteText: '',
            ReleaseOption: null,
            thinScreen: false,
        }
    }

    componentDidMount() {
        const loadData = async () => {
            try {
                const components = await MedicalLicenseLogic.GetMedicalLicenseDetailDisplayData()
                let medicalBoardLinks = null
                if (this.props.showLinks && components && components.length > 0) {
                    let states = components.map((component) => component.StateAbbreviation).join()

                    medicalBoardLinks = await MedicalLicenseLogic.getMedicalLicenseURL(states)
                }
                let releaseData = {}
                if (medicalLicenseContext.props.DoNotRenderNav) {
                    releaseData = await MedicalLicenseLogic.GetMedicalLicenseReleaseData()
                }

                this.setState({
                    medicalBoardLinks,
                    medicalLicenseComponents: components,
                    ...releaseData,
                })
            } catch (error) {
                HelperMethods.apiErrorHandler(error, 'Error Accessing Medical License Information')
            } finally {
                this.setState({CallingApi: false})
            }
        }
        this.setState({CallingApi: true}, loadData)
    }

    Save() {
        return MedicalLicenseLogic.SaveLogic()
    }

    PopulateMedicalLicenseGrid(medicalLicenseComponents) {
        let MedicalLicenseScreenComponents = []
        MedicalLicenseScreenComponents.push(
            <View key={'MedicalLicenseHeader'} style={styles.medicalLicenseView}>
                <View style={{flex: 1}}>
                    <View style={{flexDirection: 'row', flex: 1}}>
                        <View style={{width: this.state.thinScreen ? 40 : 100}}>
                            <Text style={styles.medicalLicenseTextHeader}>State</Text>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <Text style={[styles.medicalLicenseTextHeader, {width: '39%'}]}>
                                License #
                            </Text>
                            <Text style={[styles.medicalLicenseTextHeader, {width: '35%'}]}>Status</Text>
                            <Text style={[styles.medicalLicenseTextHeader, {width: '26%'}]}>
                                Exp Date
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{width: 40, alignItems: 'center'}}>
                    <Text style={styles.medicalLicenseTextHeader}>Edit</Text>
                </View>
            </View>,
        )

        if (!medicalLicenseComponents || medicalLicenseComponents.length <= 0) {
            return MedicalLicenseScreenComponents
        }

        medicalLicenseComponents.forEach((medicalLicense, index) => {
            var expirationDateShort = `${medicalLicense.ExpirationDateFormatted.slice(
                0,
                6,
            )}${medicalLicense.ExpirationDateFormatted.slice(8)}`
            MedicalLicenseScreenComponents.push(
                <View
                    key={'Row' + index}
                    style={{
                        flexDirection: 'row',
                        marginTop: 10,
                        paddingBottom: 10,
                        borderBottomColor: ColorEnums.aquaHaze,
                        borderBottomWidth: 1,
                        width: '100%',
                    }}
                >
                    <View style={{flex: 1, height: '100%'}}>
                        <View style={{flexDirection: 'row', flex: 1, height: '100%'}}>
                            <View style={{width: this.state.thinScreen ? 40 : 100, height: '100%'}}>
                                <Text style={[styles.medicalLicenseTextBody, {fontWeight: 'bold'}]}>
                                    {medicalLicense.StateAbbreviation}
                                </Text>
                            </View>
                            <View style={{flex: 1, flexDirection: 'row', height: '100%'}}>
                                <Text
                                    style={[
                                        styles.medicalLicenseTextBody,
                                        {width: '34%', paddingRight: 10},
                                    ]}
                                >
                                    {medicalLicense.LicenseNumber}
                                </Text>
                                <Text style={[styles.medicalLicenseTextBody, {width: '35%'}]}>
                                    {medicalLicense.DisplayName}
                                </Text>
                                <Text style={[styles.medicalLicenseTextBody, {width: '31%'}]}>
                                    {this.state.thinScreen
                                        ? expirationDateShort
                                        : medicalLicense.ExpirationDateFormatted}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{width: 40, alignItems: 'center', height: '100%'}}>
                        <TouchableOpacity
                            style={{width: '100%', alignItems: 'center'}}
                            onPress={() => {
                                NavigationHelper.GoToPage(
                                    GeneralEnums.navigationType.push,
                                    '/editMedicalLicense',
                                    {
                                        medicalLicenseId: medicalLicense.MedicalLicenseId,
                                    },
                                )
                            }}
                            testID="editMedicalLicenseButton"
                        >
                            <Text
                                style={[
                                    styles.iconSymbol,
                                    {
                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                        color: ColorEnums.danube,
                                    },
                                ]}
                            >
                                {IconEnums['pencil-square']}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>,
            )
        })
        return MedicalLicenseScreenComponents
    }

    render() {
        medicalLicenseContext = this
        MedicalLicenseLogic.context = medicalLicenseContext
        return (
            <View
                onLayout={(event) => this.setState({thinScreen: event.nativeEvent.layout.width < 500})}
            >
                <View>
                    {Platform.OS === 'web' && !this.props.showLinks && (
                        <Text style={styles.pageHeading}>Medical License</Text>
                    )}
                    <Text style={[styleFunctions.containerText(), {marginBottom: 20, marginTop: 20}]}>
                        Candidates for initial certification and diplomates must report the
                        state/province, license number, issue date and expiration date for
                        <Text style={{fontWeight: 'bold'}}> every </Text>
                        U.S. or Canadian license held.
                    </Text>
                    <Text style={[styleFunctions.containerText(), {marginBottom: 10}]}>
                        They have the affirmative obligation to advise us of all restrictions placed on
                        their medical licenses and to provide complete information about restrictions
                        within 60 days of their imposition.
                    </Text>
                    <Text style={[styleFunctions.containerText(), {marginBottom: 20}]}>
                        DO NOT report training licenses.
                    </Text>
                </View>
                <View>
                    {this.PopulateMedicalLicenseGrid(
                        medicalLicenseContext.state.medicalLicenseComponents,
                    )}
                    {medicalLicenseContext.state.CallingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Loading Medical Licenses</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </View>
                <View style={styles.horizontalRule} />
                <TouchableOpacity
                    style={{width: 'auto', alignSelf: 'flex-start'}}
                    onPress={() => {
                        NavigationHelper.GoToPage(
                            GeneralEnums.navigationType.push,
                            '/editMedicalLicense',
                        )
                    }}
                    testID="addNewLicenseButton"
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignSelf: 'flex-start',
                            width: 'auto',
                            height: 30,
                        }}
                    >
                        <Text
                            style={[
                                styles.iconSymbol,
                                {textAlignVertical: 'center', height: 20, marginTop: 10},
                            ]}
                        >
                            {IconEnums['plus']}
                        </Text>
                        <Text style={[styles.hyperLink, {textAlignVertical: 'center', height: 20}]}>
                            {'   Add New License'}
                        </Text>
                    </View>
                </TouchableOpacity>
                {medicalLicenseContext.props.DoNotRenderNav && (
                    <View style={{borderTopWidth: 1, borderTopColor: ColorEnums.lightGray}}>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        paddingTop: 15,
                                        paddingBottom: 25,
                                    },
                                ]}
                            >
                                Please Answer the Following Statement
                            </Text>
                        </View>
                        <View>
                            <Text style={[styleFunctions.paragraphText(), {fontSize: 14}]}>
                                Do you have, or have you ever had, a restriction, condition, reprimand,
                                limitation, probation, suspension or revocation on a license to practice
                                medicine in any state of the United States or province of Canada{' '}
                                <Text style={{fontWeight: 'bold', textDecorationLine: 'underline'}}>
                                    that was not reported to the ABA on your current registration
                                </Text>{' '}
                                or as an amendment to it?
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View style={{width: '50%', alignSelf: 'center', marginTop: 20}}>
                            <ABAAffirmationRadio
                                leftOptionContent="Yes"
                                leftOptionOnPress={MedicalLicenseLogic.ReleaseOptionYesSelected}
                                rightOptionContent="No"
                                rightOptionOnPress={MedicalLicenseLogic.ReleaseOptionNoSelected}
                                optionValues={{leftValue: true, rightValue: false}}
                                selectedValue={medicalLicenseContext.state.ReleaseOption}
                            />
                        </View>
                        {medicalLicenseContext.state.ABAAffirmationRadioError && (
                            <View style={{alignSelf: 'center'}}>
                                <Text style={{color: 'red'}}>Please select Yes or No.</Text>
                            </View>
                        )}
                    </View>
                )}
                {medicalLicenseContext.state.ReleaseOption && (
                    <View>
                        <View>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, paddingBottom: 10},
                                ]}
                            >
                                You indicated that you have had actions taken against your medical
                                license that have not been reported to the ABA.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, paddingBottom: 10},
                                ]}
                            >
                                If this is incorrect, please revise your response above.
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {fontSize: 14, paddingTop: 15, paddingBottom: 10},
                                ]}
                            >
                                Please explain the actions taken against your medical license(s).
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View>
                            <ABATextInputMultiline
                                maxLength={500}
                                numberOfLines={8}
                                onChangeText={(text) => medicalLicenseContext.setState({NoteText: text})}
                                value={medicalLicenseContext.state.NoteText}
                                textInputStatus={medicalLicenseContext.state.NoteError}
                            />
                        </View>
                        {medicalLicenseContext.state.NoteError === 3 && (
                            <View>
                                <Text style={{color: 'red'}}>Please provide an explanation.</Text>
                            </View>
                        )}
                    </View>
                )}
                {this.props.showLinks && (
                    <View
                        style={{
                            marginTop: 20,
                            borderBottomWidth: 3,
                            borderBottomColor: ColorEnums.cloudBurst,
                        }}
                    >
                        <Text
                            style={[
                                styleFunctions.containerText(),
                                {
                                    fontFamily: 'Lato',
                                    color: ColorEnums.cloudBurst,
                                    fontWeight: 'bold',
                                    paddingBottom: 15,
                                },
                            ]}
                        >
                            LINKS TO STATE MEDICAL BOARDS
                        </Text>
                        <Text style={[styleFunctions.paragraphText()]}>
                            Make sure you're maintaining the good standing of each of your medical
                            licenses by visiting the issuing state's medical board
                        </Text>
                        <View style={{paddingTop: 15}}>
                            {this.state.medicalBoardLinks &&
                                this.state.medicalBoardLinks.map((link, i) => {
                                    if (link.StateMDURL && link.StateDOURL) {
                                        return (
                                            <View key={i}>
                                                <View style={styles.linkRow}>
                                                    <ABAHyperLink
                                                        label={link.StateName + ' (MD)'}
                                                        url={link.StateMDURL}
                                                        style={styleFunctions.actionText()}
                                                    />
                                                    <ABAHyperLink
                                                        label={IconEnums['external-link-alt']}
                                                        url={link.StateMDURL}
                                                        style={[
                                                            styleFunctions.actionText(),
                                                            {
                                                                fontFamily:
                                                                    GeneralEnums.fontAwesomeFamilyName,
                                                                paddingLeft: 10,
                                                            },
                                                        ]}
                                                    />
                                                </View>
                                                <View style={styles.linkRow}>
                                                    <ABAHyperLink
                                                        label={link.StateName + ' (DO)'}
                                                        url={link.StateDOURL}
                                                        style={styleFunctions.actionText()}
                                                    />
                                                    <ABAHyperLink
                                                        label={IconEnums['external-link-alt']}
                                                        url={link.StateDOURL}
                                                        style={[
                                                            styleFunctions.actionText(),
                                                            {
                                                                fontFamily:
                                                                    GeneralEnums.fontAwesomeFamilyName,
                                                                paddingLeft: 10,
                                                            },
                                                        ]}
                                                    />
                                                </View>
                                            </View>
                                        )
                                    }

                                    if (!link.StateMDURL) {
                                        return (
                                            <View style={styles.linkRow} key={i}>
                                                <Text
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        color: ColorEnums.danube,
                                                    }}
                                                >
                                                    {link.StateName} (No link available)
                                                </Text>
                                            </View>
                                        )
                                    }

                                    return (
                                        <View style={styles.linkRow} key={i}>
                                            <ABAHyperLink
                                                label={link.StateName}
                                                url={link.StateMDURL}
                                                style={styleFunctions.actionText()}
                                            />
                                            <ABAHyperLink
                                                label={IconEnums['external-link-alt']}
                                                url={link.StateMDURL}
                                                style={[
                                                    styleFunctions.actionText(),
                                                    {
                                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                        paddingLeft: 10,
                                                    },
                                                ]}
                                            />
                                        </View>
                                    )
                                })}
                        </View>
                    </View>
                )}
            </View>
        )
    }
}

MedicalLicense.defaultProps = {
    showLinks: false,
}

MedicalLicense.propTypes = {
    showLinks: PropTypes.bool,
}
