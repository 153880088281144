import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import SubstanceAbuseStep2 from '../presentation/substanceAbuseStep2'
import SubstanceAbuseStep4 from '../presentation/substanceAbuseStep4'
import ValidationHelper from '../../../../helpers/ValidationHelper'

const SubstanceAbuseLogicStep3 = {
    context: null,

    GetSubstanceAbuseData() {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`substanceabuseinfo/getSAData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    updateInitialTreatmentType(aba_initialtreatmenttype) {
        this.context.setState({aba_initialtreatmenttype})
        Object.keys(this.context.state.InputStatus).forEach(
            (field) => (this.context.state.InputStatus[field] = GeneralEnums.textInputStatus.default),
        )
    },

    GetSAInitialTreatTypes() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getSAInitialTreatTypes`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Treatment Types')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetInpatientReferences() {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        const {Inpatient_Treating_Physicians} = GeneralEnums.cert_referenceType
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    Type: Inpatient_Treating_Physicians,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Inpatient Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetOutpatientReferences() {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        const {Outpatient_Treating_Physicians} = GeneralEnums.cert_referenceType
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    Type: Outpatient_Treating_Physicians,
                },
            }

            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Outpatient Reference IDs')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    CancelAddReference() {
        SubstanceAbuseLogicStep3.context.setState({
            collapsedReference: null,
        })
    },

    PopulateVariables(SAData) {
        const [SubstanceAbuseData, TreatmentTypes, InpatientReferenceIDList, OutpatientReferenceIDList] =
            SAData
        if (
            SubstanceAbuseData &&
            TreatmentTypes &&
            InpatientReferenceIDList &&
            OutpatientReferenceIDList
        ) {
            const {
                aba_inpatienttreatbegin,
                aba_inpatienttreatend,
                aba_outpatienttreatbegin,
                aba_outpatienttreatend,
            } = SubstanceAbuseData
            const aba_inpatienttreatbeginDate =
                aba_inpatienttreatbegin && moment(new Date(aba_inpatienttreatbegin)).format('MM/DD/YYYY')
            const aba_inpatienttreatendDate =
                aba_inpatienttreatend && moment(new Date(aba_inpatienttreatend)).format('MM/DD/YYYY')
            const aba_outpatienttreatbeginDate =
                aba_outpatienttreatbegin &&
                moment(new Date(aba_outpatienttreatbegin)).format('MM/DD/YYYY')
            const aba_outpatienttreatendDate =
                aba_outpatienttreatend && moment(new Date(aba_outpatienttreatend)).format('MM/DD/YYYY')

            this.context.setState(
                {
                    treatmentTypes: TreatmentTypes || [],
                    InpatientReferenceIDList: InpatientReferenceIDList || [],
                    OutpatientReferenceIDList: OutpatientReferenceIDList || [],
                    showAddInpatientReferenceButton: InpatientReferenceIDList.length === 0,
                    showAddOutpatientReferenceButton: OutpatientReferenceIDList.length === 0,
                },
                () => {
                    this.context.setState({
                        aba_initialtreatmenttype: SubstanceAbuseData.aba_initialtreatmenttype || '',
                        aba_inpatienttreatbegin: aba_inpatienttreatbeginDate || '',
                        aba_inpatienttreatend: aba_inpatienttreatendDate || '',
                        aba_outpatienttreatbegin: aba_outpatienttreatbeginDate || '',
                        aba_outpatienttreatend: aba_outpatienttreatendDate || '',
                    })
                },
            )
        }
    },

    UpdateInpatientGrid(index, validated = true) {
        if (!validated) {
            return
        }

        registrationStepsDisplayContext.setState({
            ShowContinue: true,
        })

        SubstanceAbuseLogicStep3.GetInpatientReferences().then((InpatientReferenceIDList) => {
            SubstanceAbuseLogicStep3.context.setState({
                InpatientReferenceIDList,
                addInpatientReference: false,
                showAddInpatientReferenceButton: !InpatientReferenceIDList.length > 0,
                collapsedReference: null,
            })
        })
    },

    UpdateOutpatientGrid(index, validated = true) {
        if (!validated) {
            return
        }

        registrationStepsDisplayContext.setState({
            ShowContinue: true,
        })

        SubstanceAbuseLogicStep3.GetOutpatientReferences().then((OutpatientReferenceIDList) => {
            SubstanceAbuseLogicStep3.context.setState({
                OutpatientReferenceIDList,
                addOutpatientReference: false,
                showAddOutpatientReferenceButton: !OutpatientReferenceIDList.length > 0,
                collapsedReference: null,
            })
        })
    },

    AddExistingReference(referenceType, referenceID) {
        const {Outpatient_Treating_Physicians, Inpatient_Treating_Physicians} =
            GeneralEnums.cert_referenceType

        if (referenceType === Inpatient_Treating_Physicians) {
            SubstanceAbuseLogicStep3.context.setState({
                addOutpatientReference: true,
            })
        }

        if (referenceType === Outpatient_Treating_Physicians) {
            SubstanceAbuseLogicStep3.context.setState({
                addInpatientReference: true,
            })
        }

        SubstanceAbuseLogicStep3.context.setState({
            showReferenceList: false,
            addNewReferenceID: referenceID,
        })
    },

    AddNewReference() {
        SubstanceAbuseLogicStep3.context.setState({
            showReferenceList: false,
            addNewReferenceID: '-1',
        })
    },

    GetInitialTreatmentBeginDate(inpatientDate, outpatientDate) {
        if (inpatientDate && outpatientDate) {
            if (inpatientDate <= outpatientDate) {
                return inpatientDate
            }

            return outpatientDate
        }

        if (inpatientDate && !outpatientDate) {
            return inpatientDate
        }

        if (!inpatientDate && outpatientDate) {
            return outpatientDate
        }

        return null
    },

    GetInitialTreatmentEndDate(inpatientDate, outpatientDate) {
        if (inpatientDate && outpatientDate) {
            if (inpatientDate >= outpatientDate) {
                return inpatientDate
            }

            return outpatientDate
        }

        if (inpatientDate && !outpatientDate) {
            return inpatientDate
        }

        if (!inpatientDate && outpatientDate) {
            return outpatientDate
        }

        return null
    },

    UpdateSubstanceAbuseStep3() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {
                aba_initialtreatmenttype,
                aba_inpatienttreatbegin,
                aba_inpatienttreatend,
                aba_outpatienttreatbegin,
                aba_outpatienttreatend,
            } = this.context.state

            const aba_initialtreatmentbegin = this.GetInitialTreatmentBeginDate(
                aba_inpatienttreatbegin,
                aba_outpatienttreatbegin,
            )
            const aba_initialtreatmentend = this.GetInitialTreatmentEndDate(
                aba_inpatienttreatend,
                aba_outpatienttreatend,
            )

            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep3`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
                aba_initialtreatmenttype,
                aba_inpatienttreatbegin,
                aba_inpatienttreatend,
                aba_outpatienttreatbegin,
                aba_outpatienttreatend,
                aba_initialtreatmentbegin,
                aba_initialtreatmentend,
                rs_statuscode: substanceAbuseContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }
            this.UpdateSubstanceAbuseStep3().then(() => {
                substanceAbuseContext.setState({
                    CurrentComponentStep: SubstanceAbuseStep4,
                })
                resolve(false)
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep2,
            })
            resolve(false)
        })
    },

    ValidatePage() {
        const {
            aba_initialtreatmenttype,
            aba_inpatienttreatbegin,
            aba_inpatienttreatend,
            aba_outpatienttreatbegin,
            aba_outpatienttreatend,
            InpatientReferenceIDList,
            OutpatientReferenceIDList,
            InputStatus,
            ErrorMessages,
        } = SubstanceAbuseLogicStep3.context.state
        const {error, success} = GeneralEnums.textInputStatus
        const validateOutpatient = () => {
            InputStatus.aba_initialtreatmenttype = success
            InputStatus.aba_outpatienttreatend = GeneralEnums.textInputStatus.default

            if (
                ValidationHelper.isDateFormatValid(
                    aba_outpatienttreatbegin,
                    GeneralEnums.DateCheckType.TodayAndPastOnly,
                )
            ) {
                InputStatus.aba_outpatienttreatbegin = success
            } else {
                InputStatus.aba_outpatienttreatbegin = error
                return
            }

            if (!ValidationHelper.isDateRangeValid(aba_outpatienttreatbegin, aba_outpatienttreatend)) {
                ErrorMessages.aba_outpatienttreatend = `Please enter a date that is on or after ${aba_outpatienttreatbegin}.`
                InputStatus.aba_outpatienttreatend = error
            } else {
                InputStatus.aba_outpatienttreatend = success
            }
            if (OutpatientReferenceIDList.length === 0) {
                InputStatus.outpatientReference = error
            } else {
                InputStatus.outpatientReference = success
            }
        }

        const validateInpatient = () => {
            InputStatus.aba_initialtreatmenttype = success
            InputStatus.aba_inpatienttreatend = GeneralEnums.textInputStatus.default

            if (
                ValidationHelper.isDateFormatValid(
                    aba_inpatienttreatbegin,
                    GeneralEnums.DateCheckType.TodayAndPastOnly,
                )
            ) {
                InputStatus.aba_inpatienttreatbegin = success
            } else {
                InputStatus.aba_inpatienttreatbegin = error
                return
            }

            if (!ValidationHelper.isDateRangeValid(aba_inpatienttreatbegin, aba_inpatienttreatend)) {
                ErrorMessages.aba_inpatienttreatend = `Please enter a date that is on or after ${aba_inpatienttreatbegin}.`
                InputStatus.aba_inpatienttreatend = error
            } else {
                InputStatus.aba_inpatienttreatend = success
            }
            if (InpatientReferenceIDList.length === 0) {
                InputStatus.inpatientReference = error
            } else {
                InputStatus.inpatientReference = success
            }
        }

        switch (parseInt(aba_initialtreatmenttype)) {
            case 1:
                validateInpatient()
                break
            case 2:
                validateOutpatient()
                break
            case 3:
                validateInpatient()
                validateOutpatient()
                break
            default:
                InputStatus.aba_initialtreatmenttype = error
        }

        var Validated = !Object.keys(InputStatus).some((item) => InputStatus[item] === 3)
        this.context.setState({
            InputStatus: InputStatus,
            ErrorMessages: ErrorMessages,
        })
        return Validated
    },
}

export default SubstanceAbuseLogicStep3
