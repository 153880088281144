import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import {Platform} from 'react-native'
import HelperMethods from '../../../../helpers/HelperMethods'
import ClinicalActivityStep2 from '../presentation/clinicalActivityStep2'
import ClinicalActivityStep4 from '../presentation/clinicalActivityStep4'
import ClinicalActivityStep5 from '../presentation/clinicalActivityStep5'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import ValidationHelper from '../../../../helpers/ValidationHelper'

const ClinicalActivityLogicStep3 = {
    context: null,

    GetCountryListData: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getCountries')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    resolve(null)
                })
        })
    },

    GetStateListData: function (country) {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getStates?countryId=' + country)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    resolve(null)
                })
        })
    },

    GetClinicalActivityData: function () {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep3.context.setState({CallingApi: true})
            ABAPortalAPI.get(
                'clinicalactivity/getClinicalActivity?ClinicalActivityID=' +
                    clinicalActivityContext.state.ClinicalActivityID,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    ClinicalActivityLogicStep3.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables: function (CAData, DataCountry, DataState, ReferenceIDs) {
        const cert_startdate =
            CAData.cert_startdate && moment(new Date(CAData.cert_startdate)).format('MM/DD/YYYY')
        ClinicalActivityLogicStep3.context.setState(
            {
                stateList: DataState || [],
                countryList: DataCountry || [],
            },
            () => {
                ClinicalActivityLogicStep3.context.setState({
                    cert_workprivileges: CAData.cert_workprivileges || null,
                    cert_startdate: cert_startdate || '',
                    cert_city: CAData.cert_city || '',
                    cert_stateid: CAData.cert_stateid || 'null',
                    cert_stateorprovince: CAData.cert_stateorprovince || '',
                    cert_country: CAData.cert_country || GeneralEnums.cert_CountryId.USA,
                    cert_phone: CAData.cert_phone || '',
                    cert_fax: CAData.cert_fax || '',
                    cert_haverestrictions:
                        CAData.cert_haverestrictions == null
                            ? null
                            : CAData.cert_haverestrictions
                            ? true
                            : false,
                    cert_restrictionsdescription: CAData.cert_restrictionsdescription || '',
                    cert_locumtenensdetails: CAData.cert_locumtenensdetails || '',
                    cert_organization: CAData.cert_organization || '',
                    original_cert_workprivileges: CAData.cert_workprivileges || null,
                    referenceIDs: ReferenceIDs,
                })
            },
        )
    },

    RestrictionsYesSelected: function () {
        ClinicalActivityLogicStep3.context.setState({cert_haverestrictions: true})
    },

    RestrictionsNoSelected: function () {
        ClinicalActivityLogicStep3.context.setState({
            cert_haverestrictions: false,
            cert_restrictionsdescription: '',
        })
    },

    SetWorkPrivilege: function (cert_workprivileges) {
        const {InputStatus} = ClinicalActivityLogicStep3.context.state
        InputStatus.cert_workprivileges = GeneralEnums.textInputStatus.default

        ClinicalActivityLogicStep3.context.setState({
            cert_workprivileges,
            InputStatus,
        })

        if (cert_workprivileges !== 2) {
            ClinicalActivityLogicStep3.context.setState({
                cert_locumtenensdetails: '',
            })
        }
    },

    UpdateClinicalActivityStep3: function () {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep3.context.setState({CallingApi: true})
            const {
                cert_workprivileges,
                cert_locumtenensdetails,
                cert_startdate,
                cert_organization,
                cert_city,
                cert_stateorprovince,
                cert_stateid,
                cert_country,
                cert_phone,
                cert_fax,
                cert_haverestrictions,
                cert_restrictionsdescription,
            } = ClinicalActivityLogicStep3.context.state

            ABAPortalAPI.post('clinicalactivity/UpdateClinicalActivityStep3', {
                cert_clinicalactivityid: clinicalActivityContext.state.ClinicalActivityID,
                cert_topresent: 1,
                cert_workprivileges,
                cert_locumtenensdetails,
                cert_startdate,
                cert_organization,
                cert_city,
                cert_stateid,
                cert_country,
                cert_phone,
                cert_fax,
                cert_haverestrictions,
                cert_restrictionsdescription,
                cert_stateorprovince,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    ClinicalActivityLogicStep3.context.setState({CallingApi: false})
                })
        })
    },

    getStatesProvinces: function (country) {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getStates?countryId=' + country)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving States')
                    resolve(null)
                })
        })
    },

    GetReferenceIDs() {
        return new Promise((resolve) => {
            const {ClinicalActivityID} = clinicalActivityContext.state
            let getParams = {
                params: {
                    ClinicalActivityID,
                },
            }
            ClinicalActivityLogicStep3.context.setState({CallingApi: true})
            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Reference IDs')
                })
                .finally(function () {
                    ClinicalActivityLogicStep3.context.setState({CallingApi: false})
                })
        })
    },

    ValidatePage() {
        let Validated = true

        const {
            cert_workprivileges,
            cert_locumtenensdetails,
            cert_startdate,
            cert_organization,
            cert_city,
            cert_stateid,
            cert_country,
            cert_phone,
            cert_haverestrictions,
            cert_restrictionsdescription,
            InputStatus,
            stateList,
            cert_stateorprovince,
        } = ClinicalActivityLogicStep3.context.state

        const {error, success} = GeneralEnums.textInputStatus
        let cert_phoneError = 'Please enter a value.'
        let cert_startdateError = 'Please enter a valid date.'

        if (cert_workprivileges === 5) {
            Validated = false
            InputStatus.cert_workprivileges = GeneralEnums.textInputStatus.error
        } else {
            InputStatus.cert_workprivileges = GeneralEnums.textInputStatus.default
            switch (cert_workprivileges) {
                case 1:
                    InputStatus.practiceFacility = success
                    InputStatus.practiceLocum = GeneralEnums.textInputStatus.default
                    InputStatus.practiceIndependent = GeneralEnums.textInputStatus.default
                    break
                case 2:
                    InputStatus.practiceLocum = success
                    InputStatus.practiceFacility = GeneralEnums.textInputStatus.default
                    InputStatus.practiceIndependent = GeneralEnums.textInputStatus.default
                    break
                case 3:
                    InputStatus.practiceIndependent = success
                    InputStatus.practiceFacility = GeneralEnums.textInputStatus.default
                    InputStatus.practiceLocum = GeneralEnums.textInputStatus.default
                    break
            }
        }

        if (cert_workprivileges !== 5) {
            if (cert_workprivileges == 2 && !cert_locumtenensdetails) {
                Validated = false
                InputStatus.cert_locumtenensdetails = error
            } else {
                InputStatus.cert_locumtenensdetails = success
            }

            if (!cert_startdate || cert_startdate.length !== 10) {
                Validated = false
                InputStatus.cert_startdate = error
            } else if (
                !ValidationHelper.isDateFormatValid(
                    cert_startdate,
                    GeneralEnums.DateCheckType.TodayAndPastOnly,
                )
            ) {
                Validated = false
                InputStatus.cert_startdate = error
                cert_startdateError = 'Date cannot be in the future'
            } else {
                InputStatus.cert_startdate = success
            }

            if (!cert_organization) {
                Validated = false
                InputStatus.cert_organization = error
            } else {
                InputStatus.cert_organization = success
            }

            if (!cert_city) {
                Validated = false
                InputStatus.cert_city = error
            } else {
                InputStatus.cert_city = success
            }

            if (stateList.length > 0 && (!cert_stateid || cert_stateid === 'null')) {
                Validated = false
                InputStatus.cert_stateid = error
            } else {
                InputStatus.cert_stateid = success
            }

            InputStatus.cert_stateorprovince = success

            if (!cert_country || cert_country === 'null') {
                Validated = false
                InputStatus.cert_country = error
            } else {
                InputStatus.cert_country = success
            }

            if (!cert_phone) {
                Validated = false
                InputStatus.cert_phone = error
            } else if (cert_phone.replace(/\D/g, '').length != 10) {
                Validated = false
                InputStatus.cert_phone = error
                cert_phoneError = 'Please enter a valid 10 digit phone number.'
            } else {
                InputStatus.cert_phone = success
            }

            if (cert_haverestrictions === null) {
                Validated = false
                InputStatus.cert_haverestrictions = error
            } else {
                InputStatus.cert_haverestrictions = success
            }

            if (cert_haverestrictions && !cert_restrictionsdescription) {
                Validated = false
                InputStatus.cert_restrictionsdescription = error
            } else if (!cert_haverestrictions) {
                InputStatus.cert_restrictionsdescription = GeneralEnums.textInputStatus.default
            } else {
                InputStatus.cert_restrictionsdescription = success
            }
        }

        if (!Validated) {
            ClinicalActivityLogicStep3.context.setState(
                {
                    InputStatus: InputStatus,
                    cert_phoneError,
                    cert_startdateError,
                },
                () => {
                    registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                        x: 0,
                        y: 0,
                        animated: false,
                    })
                },
            )
        }
        return Validated
    },

    DeleteReferences() {
        return new Promise((resolve) => {
            const {referenceIDs} = ClinicalActivityLogicStep3.context.state
            ClinicalActivityLogicStep3.context.setState({CallingApi: true})
            ABAPortalAPI.post(`reference/deleteAllReferences`, referenceIDs)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting References')
                })
        })
    },

    DeleteReferenceData() {
        ClinicalActivityLogicStep3.DeleteReferences().then(() => {
            ClinicalActivityLogicStep3.SaveLogic(true)
        })
    },

    determineNextStep() {
        let shouldGoToNextStep = false
        const {ProgramType} = ClinicalActivityLogicStep3.context.props
        const {cert_acapath, graduationDate} = clinicalActivityContext.state
        const {Adult_Cardiac_Anesthesiology} = GeneralEnums.program_type
        const meetsACACriteria =
            ProgramType === Adult_Cardiac_Anesthesiology && (cert_acapath == 1 || cert_acapath == 3)

        const gradDate = new Date(graduationDate)
        let sevenYearsAgo = new Date()
        sevenYearsAgo.setFullYear(sevenYearsAgo.getFullYear() - 7)

        if (meetsACACriteria && gradDate > sevenYearsAgo) {
            shouldGoToNextStep = true
        }

        return shouldGoToNextStep
    },

    SaveLogic(confirmed) {
        const {CAstepCount: stepCount} = registrationStepsDisplayContext.state
        const shouldGoToNextRegistrationStep = this.determineNextStep()
        let nextStep =
            stepCount === 3 || shouldGoToNextRegistrationStep
                ? ClinicalActivityStep5
                : ClinicalActivityStep4

        return new Promise((resolve) => {
            if (!ClinicalActivityLogicStep3.ValidatePage()) {
                resolve(false)
                return
            }

            const {cert_workprivileges, original_cert_workprivileges, referenceIDs} =
                ClinicalActivityLogicStep3.context.state
            if (
                original_cert_workprivileges &&
                original_cert_workprivileges !== cert_workprivileges &&
                referenceIDs.length > 0 &&
                !confirmed
            ) {
                ClinicalActivityLogicStep3.context.setState({showDeleteConfirm: true})
                return
            }

            ClinicalActivityLogicStep3.UpdateClinicalActivityStep3().then((response) => {
                if (response) {
                    // If an ACA exam registration has an aca_cert of 1 or 3 and their grad date is within 7 years, then this is the last step in clinical activity
                    clinicalActivityContext.setState(
                        {
                            CurrentComponentStep: nextStep,
                            ClinicalActivityWorkPrivilege:
                                ClinicalActivityLogicStep3.context.state.cert_workprivileges,
                        },
                        () => {
                            registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                                x: 0,
                                y: 0,
                                animated: false,
                            })
                        },
                    )
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({
                CurrentComponentStep: ClinicalActivityStep2,
            })
            resolve(false)
        })
    },
}

export default ClinicalActivityLogicStep3
