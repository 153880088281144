import React, {createContext, useReducer} from 'react'
import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    pendingPayment: false,
    paymentOffline: false,
    isLoading: true,
}

export const registration = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TogglePendingPayment:
            return {...state, pendingPayment: action.payload}
        case ActionTypes.ClearUserData:
            return {...state, ...initialState}
        case ActionTypes.RegistrationLoading:
            return {...state, isLoading: action.payload}
        case ActionTypes.SetPaymentOffline:
            return {...state, paymentOffline: action.payload}
        default:
            return state
    }
}

export default registration
