import React, {Component} from 'react'
import {View, Text, StyleSheet} from 'react-native'
import {ColorEnums} from '../../helpers/generalEnums'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ExamInfoLinksLogic from '../logic/examInfoLinksLogic'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'

const styles = StyleSheet.create({
    pageHeading: {
        color: ColorEnums.danube,
        flexDirection: 'row',
        fontFamily: 'Lato',
        fontSize: 36,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'center',
    },

    examInfoContainer: {
        backgroundColor: ColorEnums.white,
        marginLeft: 25,
        marginRight: 25,
    },

    mainTitle: {
        backgroundColor: ColorEnums.cloudburst,
        color: ColorEnums.white,
        fontFamily: 'Lato',
        fontSize: 18,
        fontWeight: 'bold',
        padding: 5,
        textAlign: 'center',
        textAlignVertical: 'center',
    },

    linkComponents: {
        flexDirection: 'column',
        alignSelf: 'center',
        marginBottom: 20,
    },
})

export default class ExamInfoAnesthesiology extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        examInfoAnesthesiologyContext = this
        ExamInfoLinksLogic.context = examInfoAnesthesiologyContext
        navigationContext = this
        HelperMethods.setNavigationData(examInfoAnesthesiologyContext)

        let ExamInfoLinksArray = [
            [
                'BASIC',
                'Learn more about the BASIC Exam:',
                'https://www.theaba.org/certification-exam-type/basic-exam/',
            ],
            [
                'ADVANCED',
                'Learn more about the ADVANCED Exam:',
                'https://theaba.org/staged%20exams.html',
            ],
            [
                'APPLIED',
                'Learn more about the APPLIED Exam:',
                'https://www.theaba.org/certification-exam-type/applied-exam/',
            ],

            [
                'Traditional Part 2',
                'Learn more about the traditional Part 2 Exam:',
                'https://www.theaba.org/get-certified/',
            ],
            [
                'ASPEX',
                'Learn more about the Anesthesiology Special Purpose Exam (ASPEX):',
                'https://www.theaba.org/recertification-exam-type/aspex/',
            ],
        ]
        let examInfoLinkComponents = ExamInfoLinksLogic.PopulateExamInfoLinks(ExamInfoLinksArray)

        return (
            <NavigationShell context={examInfoAnesthesiologyContext}>
                <View style={{flex: 1, backgroundColor: ColorEnums.backgroundGray}}>
                    <Text style={styles.pageHeading}>Exam Information Page</Text>
                    <View
                        style={[
                            (style = styles.examInfoContainer),
                            {backgroundColor: ColorEnums.indigo},
                        ]}
                    >
                        <Text style={styles.mainTitle}>ANESTHESIOLOGY EXAM INFORMATION</Text>
                    </View>
                    <ABAKeyboardAwareScrollView style={styles.examInfoContainer}>
                        <View style={styles.linkComponents}>{examInfoLinkComponents}</View>
                    </ABAKeyboardAwareScrollView>
                </View>
            </NavigationShell>
        )
    }
}
