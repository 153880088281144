import React, {Component} from 'react'
import {Platform, Text, View} from 'react-native'
import {Checkbox} from 'react-native-paper'
import PropTypes from 'prop-types'

import styleFunctions from '../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import ABARequiredAsterisk from './ABARequiredAsterisk'

export default class ABACheckbox extends Component {
    state = {
        labelWidth: this.props.useValidation ? '90%' : '100%',
    }

    renderIcon() {
        let targetColor,
            targetIcon = null

        switch (this.props.validationStatus) {
            case GeneralEnums.textInputStatus.disabled:
                targetColor = ColorEnums.blueGray
                targetIcon = IconEnums['lock']
                break
            case GeneralEnums.textInputStatus.error:
                targetColor = ColorEnums.pomegranate
                targetIcon = IconEnums['times']
                break
            case GeneralEnums.textInputStatus.success:
                targetColor = ColorEnums.surfGreen
                targetIcon = IconEnums['check']
                break
            case GeneralEnums.textInputStatus.default:
            default:
                targetColor = ColorEnums.blueGray
                targetIcon = null
                break
        }

        return (
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '10%',
                }}
            >
                <Text
                    style={{
                        color: targetColor,
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        fontSize: 14,
                    }}
                >
                    {targetIcon}
                </Text>
            </View>
        )
    }

    render() {
        const {
            isRequired,
            label,
            isChecked,
            onChangeEvent,
            labelStyle,
            isDisabled,
            validationStatus,
            useValidation,
            width,
            errorMessage,
        } = this.props
        const {disabled} = GeneralEnums.textInputStatus
        const checkboxLabel = isRequired ? (
            <View style={[labelStyle, {flexDirection: 'column'}]}>
                <Text style={labelStyle}>
                    {label}
                    <ABARequiredAsterisk />
                </Text>
            </View>
        ) : (
            <Text style={labelStyle}>{label}</Text>
        )

        if (Platform.OS === 'web') {
            let checkboxTag = (
                <input
                    style={this.props.checkboxStyle}
                    type="checkbox"
                    name={`ABACheckbox${label}`}
                    onChange={onChangeEvent}
                    checked={isChecked}
                    disabled={isDisabled || validationStatus === disabled}
                />
            )

            return (
                <View style={{width: this.props.width}}>
                    <View style={{flexDirection: 'row'}}>
                        {useValidation && this.renderIcon()}
                        <label
                            style={Object.assign(
                                {marginBottom: 0, width: this.state.labelWidth},
                                labelStyle,
                            )}
                        >
                            {checkboxTag}
                            {label}
                            {isRequired && <ABARequiredAsterisk />}
                        </label>
                    </View>
                    {validationStatus === GeneralEnums.textInputStatus.error && (
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '10%'}} />
                            <Text style={{color: ColorEnums.pomegranate}}>{errorMessage}</Text>
                        </View>
                    )}
                </View>
            )
        }

        return (
            <View style={{width}}>
                <View style={{flexDirection: 'row'}}>
                    {useValidation && this.renderIcon()}
                    <View
                        style={{
                            width: this.state.labelWidth,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: this.props.justifyContent,
                        }}
                    >
                        <Checkbox.Android
                            disabled={isDisabled || validationStatus === disabled}
                            status={isChecked ? 'checked' : 'unchecked'}
                            onPress={onChangeEvent}
                            color={ColorEnums.danube}
                        />
                        <View>{checkboxLabel}</View>
                    </View>
                </View>
                {validationStatus === GeneralEnums.textInputStatus.error && (
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: '10%'}} />
                        <Text style={{color: ColorEnums.pomegranate}}>{errorMessage}</Text>
                    </View>
                )}
            </View>
        )
    }
}

ABACheckbox.defaultProps = {
    checkboxStyle: {width: 15, height: 15, marginRight: 10},
    errorMessage: 'Please check the box above.',
    isDisabled: false,
    labelStyle: styleFunctions.containerText(),
    useValidation: true,
    width: '100%',
    isRequired: false,
    justifyContent: 'center',
}

ABACheckbox.propTypes = {
    checkboxStyle: PropTypes.object,
    errorMessage: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    onChangeEvent: PropTypes.func.isRequired,
    useValidation: PropTypes.bool,
    validationStatus: PropTypes.number,
    width: PropTypes.string,
    isRequired: PropTypes.bool,
    justifyContent: PropTypes.string,
}
