import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import TrainingPage from '../../clinicalactivity/presentation/trainingPage'
import ACAExamPath1 from '../presentation/acaExamPath1'

const ACAExamPath1Logic = {
    context: null,
    GetACANBECerts() {
        return new Promise((resolve) => {
            ACAExamPath1Logic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getACANBECerts`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving ACA NBE Certification Options',
                    )
                })
                .finally(() => {
                    ACAExamPath1Logic.context.setState({CallingApi: false})
                })
        })
    },

    GetACAPostTrainingOptions() {
        return new Promise((resolve) => {
            ACAExamPath1Logic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/acaPostTraining`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving ACA Post Training Options')
                })
                .finally(() => {
                    ACAExamPath1Logic.context.setState({CallingApi: false})
                })
        })
    },

    ValidatePage() {
        const {nbeCert, postTraining} = this.context.state

        if (!nbeCert) {
            this.context.setState({
                primaryError: true,
                secondaryError: null,
            })
            return false
        }

        if (nbeCert == 4 && !postTraining) {
            this.context.setState({
                primaryError: null,
                secondaryError: true,
            })
            return false
        }

        return true
    },

    UpdateACAExamPathPage() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {acaPathway, nbeCert, postTraining, clinicalActivityId} = this.context.state
            const {ProgramType: re_ProgramType} = this.context.props
            const {ContactId} = clinicalActivityContext.state

            ABAPortalAPI.post(`clinicalactivity/updateACAPathwayPage`, {
                re_ProgramType,
                ContactId,
                cert_clinicalactivityid: clinicalActivityId,
                cert_acapath: acaPathway,
                cert_acanbecert: nbeCert,
                cert_acaposttraining: postTraining,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateACAExamPathPage().then((response) => {
                if (response) {
                    clinicalActivityContext.setState({
                        CurrentComponentStep: TrainingPage,
                        PreviousComponentStep: ACAExamPath1,
                    })

                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },
}

export default ACAExamPath1Logic
