import React, {Component} from 'react'
import {Platform, Text, View} from 'react-native'

import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import EditMedicalLicenseLogic from '../logic/editMedicalLicenseLogic'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import ABACheckbox from '../../components/ABACustom/presentation/ABACheckbox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABATextInputMultiline from '../../components/ABACustom/presentation/ABATextInputMultiline'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import NavigationShell from '../../components/presentation/navigationShell'
import ABARequiredAsterisk from '../../components/ABACustom/presentation/ABARequiredAsterisk'

const chkState = {
    NO_RESTRICTIONS: 1,
    HAS_CONDITIONS: 2,
    REVOKED: 3,
    INACTIVE: 4,
}

export default class EditMedicalLicense extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            CheckAttest: false,
            CheckHasConditions: false,
            CheckInactive: false,
            CheckNoRestrictions: false,
            CheckRevoked: false,
            ComponentWidth: null,

            // Medical License state variables
            ExpirationDate: '',
            InactiveDateAsOf: '',
            IssueDate: '',
            IssueDatePreEdit: '',
            IssuingStateId: 'null',
            LicenseId: '',
            LicenseNumber: '',
            LicenseStatus: '',
            Locked: false,
            RestrictionDateFrom: '',
            RestrictionDateTo: '',
            RestrictionNotes: '',
            RestrictionType: 'null',
            RevokedEffectiveDate: '',
            RevokedReason: 'null',
            RevokedReasonNotes: '',
            Verified: false,

            // Arrays to populate dropdown boxes
            StateProvinceList: [],
            LicenseRestrictionTypeList: [],
            LicenseRevokedReasonTypeList: [],

            // Form validation system variables
            InputStatus: {
                CheckAttestStatus: GeneralEnums.textInputStatus.default,
                CheckHasConditionsStatus: GeneralEnums.textInputStatus.default,
                CheckInactiveStatus: GeneralEnums.textInputStatus.default,
                CheckNoRestrictionsStatus: GeneralEnums.textInputStatus.default,
                CheckRevokedStatus: GeneralEnums.textInputStatus.default,
                ExpirationDateStatus: GeneralEnums.textInputStatus.default,
                InactiveDateAsOfStatus: GeneralEnums.textInputStatus.default,
                IssueDateStatus: GeneralEnums.textInputStatus.default,
                IssuingStateIdStatus: GeneralEnums.textInputStatus.default,
                LicenseNumberStatus: GeneralEnums.textInputStatus.default,
                LicenseStatusStatus: GeneralEnums.textInputStatus.default,
                RestrictionDateFromStatus: GeneralEnums.textInputStatus.default,
                RestrictionDetailsStatus: GeneralEnums.textInputStatus.default,
                RestrictionReasonTypeStatus: GeneralEnums.textInputStatus.default,
                RestrictionTypeStatus: GeneralEnums.textInputStatus.default,
                RevokedReasonNotesStatus: GeneralEnums.textInputStatus.default,
                RevokedReasonStatus: GeneralEnums.textInputStatus.default,
            },
        }
    }

    componentDidMount() {
        EditMedicalLicenseLogic.context.setState({CallingApi: true})
        EditMedicalLicenseLogic.getStateProvinceList().then(responseDataState => {
            editMedicalLicenseContext.setState({StateProvinceList: responseDataState})
            EditMedicalLicenseLogic.getLicenseRestrictionTypesList().then(responseDataRestrict => {
                editMedicalLicenseContext.setState({LicenseRestrictionTypeList: responseDataRestrict})
                EditMedicalLicenseLogic.getLicenseRestrictionReasonTypesList().then(
                    responseDataReason => {
                        editMedicalLicenseContext.setState({
                            LicenseRevokedReasonTypeList: responseDataReason,
                        })
                        EditMedicalLicenseLogic.getMedicalLicense().then(responseData => {
                            if (responseData) {
                                EditMedicalLicenseLogic.populateVariables(responseData)
                            }
                            EditMedicalLicenseLogic.context.setState({
                                Locked:
                                    this.state.LicenseStatus == chkState.HAS_CONDITIONS ||
                                    this.state.LicenseStatus == chkState.REVOKED,
                            })
                            EditMedicalLicenseLogic.context.setState({CallingApi: false})
                        })
                    },
                )
            })
        })
    }

    styleLicenseStatusDynamicBorder() {
        if (this.state.InputStatus.LicenseStatusStatus == GeneralEnums.textInputStatus.error) {
            return {
                borderWidth: 2,
                borderColor: ColorEnums.pomegranate,
            }
        }
        return {
            borderWidth: 0,
            borderColor: ColorEnums.white,
        }
    }

    setCheckboxState(chkNum) {
        switch (chkNum) {
            case chkState.NO_RESTRICTIONS:
                editMedicalLicenseContext.setState({
                    CheckHasConditions: false,
                    CheckInactive: false,
                    CheckNoRestrictions: !editMedicalLicenseContext.state.CheckNoRestrictions,
                    CheckRevoked: false,
                    InactiveDateAsOf: null,
                    LicenseStatus: 1,
                    RestrictionDateFrom: null,
                    RestrictionDateTo: null,
                    RestrictionDetails: null,
                    RestrictionEffectiveDate: null,
                })
                break

            case chkState.INACTIVE:
                editMedicalLicenseContext.setState({
                    CheckHasConditions: false,
                    CheckInactive: !editMedicalLicenseContext.state.CheckInactive,
                    CheckNoRestrictions: !editMedicalLicenseContext.state.CheckInactive,
                    CheckRevoked: false,
                    InactiveDateAsOf: null,
                    LicenseStatus: 1,
                    RestrictionDateFrom: null,
                    RestrictionDateTo: null,
                    RestrictionDetails: '',
                    RestrictionEffectiveDate: null,
                })
                break

            case chkState.HAS_CONDITIONS:
                editMedicalLicenseContext.setState({
                    CheckHasConditions: !editMedicalLicenseContext.state.CheckHasConditions,
                    CheckInactive: false,
                    CheckNoRestrictions: false,
                    CheckRevoked: false,
                    InactiveDateAsOf: null,
                    LicenseStatus: 2,
                    RestrictionDateFrom: null,
                    RestrictionDateTo: null,
                    RestrictionDetails: '',
                    RestrictionEffectiveDate: null,
                })
                break

            case chkState.REVOKED:
                editMedicalLicenseContext.setState({
                    CheckHasConditions: false,
                    CheckInactive: false,
                    CheckNoRestrictions: false,
                    CheckRevoked: !editMedicalLicenseContext.state.CheckRevoked,
                    InactiveDateAsOf: null,
                    LicenseStatus: 3,
                    RestrictionDateFrom: null,
                    RestrictionDateTo: null,
                    RestrictionDetails: '',
                    RestrictionEffectiveDate: null,
                })
                break
        }
    }

    render() {
        editMedicalLicenseContext = this
        EditMedicalLicenseLogic.context = editMedicalLicenseContext
        HelperMethods.setNavigationData(editMedicalLicenseContext)
        const {InputStatus, Locked, ComponentWidth} = editMedicalLicenseContext.state
        const isMobileView = ComponentWidth > 600
        const inputWidth = isMobileView ? '65%' : '100%'
        const dateValidationErrorMessage = 'Please enter a valid date.'

        return (
            <NavigationShell context={editMedicalLicenseContext} print={true}>
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray}}
                    ref={comp => {
                        this.parentScrollRef = comp
                    }}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.AccountSettings} />
                    <ABAContainer
                        activityText={'Loading Medical License'}
                        containerTitle={
                            editMedicalLicenseContext.navigationData.medicalLicenseId
                                ? 'EDIT MEDICAL LICENSE'
                                : 'ADD NEW LICENSE'
                        }
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View
                            onLayout={event => {
                                HelperMethods.setComponentWidth(editMedicalLicenseContext, event)
                            }}
                        >
                            <Text
                                style={[
                                    styleFunctions.containerText(),
                                    {paddingTop: 20, paddingBottom: 20},
                                ]}
                            >
                                Candidates for initial certification and diplomates must report the
                                state/province, license number, issue date and expiration date for
                                <Text style={styleFunctions.containerTextBold()}> every </Text>
                                U.S. or Canadian license held.
                            </Text>
                            <Text style={[styleFunctions.containerText(), {paddingBottom: 20}]}>
                                They have the affirmative obligation to advise us of all restrictions
                                placed on their medical licenses and to provide complete information
                                about restrictions within 60 days of their imposition.
                            </Text>
                            <Text style={[styleFunctions.containerText(), {paddingBottom: 30}]}>
                                DO NOT report training licenses.
                            </Text>
                            <ABATextInput
                                maxLength={45}
                                onChangeText={LicenseNumber => {
                                    editMedicalLicenseContext.setState({LicenseNumber})
                                }}
                                placeholder="License Number *"
                                returnKeyType="next"
                                textInputStatus={
                                    editMedicalLicenseContext.state.Verified
                                        ? GeneralEnums.textInputStatus.disabled
                                        : InputStatus.LicenseNumberStatus
                                }
                                value={editMedicalLicenseContext.state.LicenseNumber}
                                width={inputWidth}
                                marginLeft={0}
                            />
                            <View style={{paddingBottom: 20, width: inputWidth}}>
                                <ABADropdown
                                    label="License State or Province *"
                                    onChange={value => {
                                        editMedicalLicenseContext.setState({
                                            IssuingStateId: value,
                                        })
                                    }}
                                    options={editMedicalLicenseContext.state.StateProvinceList}
                                    placeholder="License State or Province*"
                                    selectedValue={editMedicalLicenseContext.state.IssuingStateId}
                                    validationStatus={InputStatus.IssuingStateIdStatus}
                                />
                            </View>
                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={10}
                                onChangeText={IssueDate => {
                                    editMedicalLicenseContext.setState({IssueDate})
                                }}
                                options={{mask: '99/99/9999'}}
                                placeholder="Date Issued (mm/dd/yyyy)"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.IssueDateStatus}
                                value={editMedicalLicenseContext.state.IssueDate}
                                width={inputWidth}
                                marginLeft={0}
                                errorMessage="Please enter a valid date"
                            />
                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={10}
                                onChangeText={ExpirationDate => {
                                    editMedicalLicenseContext.setState({ExpirationDate})
                                }}
                                options={{mask: '99/99/9999'}}
                                placeholder="Expiration Date (mm/dd/yyyy) *"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.ExpirationDateStatus}
                                value={editMedicalLicenseContext.state.ExpirationDate}
                                width={inputWidth}
                                marginLeft={0}
                                errorMessage="Please enter a valid date"
                            />
                            <View style={editMedicalLicenseContext.styleLicenseStatusDynamicBorder()}>
                                <Text
                                    style={[
                                        styleFunctions.containerTextBold(),
                                        {paddingBottom: 20, marginTop: 20},
                                    ]}
                                >
                                    LICENSE STATUS <ABARequiredAsterisk />
                                </Text>
                                <Text
                                    style={[
                                        styleFunctions.containerTextBold(),
                                        {paddingBottom: 10, marginRight: 10},
                                    ]}
                                >
                                    Please indicate the current status of your license below. If your
                                    license is in good standing but it is inactive, indicate the date
                                    when the license became inactive, and select the status.
                                </Text>
                                <Text style={[styleFunctions.containerText(), {paddingBottom: 10}]}>
                                    Please note that you cannot select 'Inactive' if the license has
                                    conditions or is revoked, suspended, or surrendered.
                                </Text>
                                <ABACheckbox
                                    isChecked={editMedicalLicenseContext.state.CheckNoRestrictions}
                                    label="Not revoked, suspended, surrendered, or restricted"
                                    labelStyle={styleFunctions.containerText()}
                                    onChangeEvent={() =>
                                        editMedicalLicenseContext.setCheckboxState(
                                            chkState.NO_RESTRICTIONS,
                                        )
                                    }
                                    validationStatus={
                                        Locked
                                            ? GeneralEnums.textInputStatus.disabled
                                            : InputStatus.CheckNoRestrictionsStatus
                                    }
                                />
                                <View style={{marginLeft: 35}}>
                                    <ABACheckbox
                                        isChecked={editMedicalLicenseContext.state.CheckInactive}
                                        label="Inactive (without disciplinary actions)"
                                        labelStyle={styleFunctions.containerText()}
                                        onChangeEvent={() =>
                                            editMedicalLicenseContext.setCheckboxState(chkState.INACTIVE)
                                        }
                                        validationStatus={
                                            Locked
                                                ? GeneralEnums.textInputStatus.disabled
                                                : InputStatus.CheckInactiveStatus
                                        }
                                    />
                                    {editMedicalLicenseContext.state.CheckInactive && (
                                        <ABATextInputMask
                                            keyboardType="number-pad"
                                            maxLength={10}
                                            onChangeText={text => {
                                                editMedicalLicenseContext.setState({
                                                    InactiveDateAsOf: text,
                                                })
                                            }}
                                            options={{mask: '99/99/9999'}}
                                            placeholder="Inactive Date (mm/dd/yyyy) *"
                                            returnKeyType="next"
                                            style={[styleFunctions.textInput()]}
                                            textInputStatus={
                                                this.state.InputStatus.InactiveDateAsOfStatus
                                            }
                                            value={editMedicalLicenseContext.state.InactiveDateAsOf}
                                            width="100%"
                                            errorMessage={dateValidationErrorMessage}
                                        />
                                    )}
                                </View>
                                <ABACheckbox
                                    isChecked={editMedicalLicenseContext.state.CheckHasConditions}
                                    label="Has conditions, but I am able to practice medicine"
                                    labelStyle={styleFunctions.containerText()}
                                    onChangeEvent={() =>
                                        editMedicalLicenseContext.setCheckboxState(
                                            chkState.HAS_CONDITIONS,
                                        )
                                    }
                                    validationStatus={
                                        Locked
                                            ? GeneralEnums.textInputStatus.disabled
                                            : InputStatus.CheckHasConditionsStatus
                                    }
                                />
                                <ABACheckbox
                                    isChecked={editMedicalLicenseContext.state.CheckRevoked}
                                    label="Revoked, suspended, or surrendered"
                                    labelStyle={styleFunctions.containerText()}
                                    onChangeEvent={() =>
                                        editMedicalLicenseContext.setCheckboxState(chkState.REVOKED)
                                    }
                                    validationStatus={
                                        Locked
                                            ? GeneralEnums.textInputStatus.disabled
                                            : InputStatus.CheckRevokedStatus
                                    }
                                />
                            </View>
                            {this.state.InputStatus.LicenseStatusStatus ==
                                GeneralEnums.textInputStatus.error && (
                                <Text
                                    style={[
                                        styleFunctions.containerText(),
                                        {color: ColorEnums.pomegranate},
                                        {paddingTop: 10},
                                        {fontSize: 14},
                                    ]}
                                >
                                    Please indicate the current status of your medical license by
                                    checking the appropriate check box above.
                                </Text>
                            )}
                            {(editMedicalLicenseContext.state.CheckHasConditions === true ||
                                editMedicalLicenseContext.state.CheckRevoked === true) && (
                                <Text
                                    style={[
                                        styleFunctions.containerTextBold(),
                                        {marginBottom: 20, marginTop: 20},
                                    ]}
                                >
                                    RESTRICTION DETAILS
                                </Text>
                            )}
                            {editMedicalLicenseContext.state.CheckHasConditions === true && (
                                <View>
                                    <View
                                        style={{
                                            flexDirection: isMobileView ? 'row' : 'column',
                                            width: '100%',
                                        }}
                                    >
                                        <View
                                            style={{
                                                width: isMobileView ? '50%' : '100%',
                                            }}
                                        >
                                            <ABATextInputMask
                                                keyboardType="number-pad"
                                                maxLength={10}
                                                onChangeText={text => {
                                                    editMedicalLicenseContext.setState({
                                                        RestrictionDateFrom: text,
                                                    })
                                                }}
                                                options={{mask: '99/99/9999'}}
                                                placeholder="From (mm/dd/yyyy) *"
                                                returnKeyType="next"
                                                style={styleFunctions.textInput()}
                                                textInputStatus={
                                                    Locked
                                                        ? GeneralEnums.textInputStatus.disabled
                                                        : InputStatus.RestrictionDateFromStatus
                                                }
                                                value={
                                                    editMedicalLicenseContext.state.RestrictionDateFrom
                                                }
                                                width={isMobileView ? '95%' : '100%'}
                                                errorMessage={dateValidationErrorMessage}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                width: isMobileView ? '50%' : '100%',
                                            }}
                                        >
                                            <ABATextInputMask
                                                keyboardType="number-pad"
                                                maxLength={10}
                                                onChangeText={text => {
                                                    editMedicalLicenseContext.setState({
                                                        RestrictionDateTo: text,
                                                    })
                                                }}
                                                options={{mask: '99/99/9999'}}
                                                placeholder="To (mm/dd/yyyy)"
                                                returnKeyType="next"
                                                style={styleFunctions.textInput()}
                                                textInputStatus={
                                                    Locked
                                                        ? GeneralEnums.textInputStatus.disabled
                                                        : InputStatus.RestrictionDateToStatus
                                                }
                                                value={editMedicalLicenseContext.state.RestrictionDateTo}
                                                width={isMobileView ? '95%' : '100%'}
                                                errorMessage={dateValidationErrorMessage}
                                            />
                                        </View>
                                    </View>
                                    <View style={{marginBottom: 30}}>
                                        <ABADropdown
                                            label="Restriction Type *"
                                            onChange={value => {
                                                editMedicalLicenseContext.setState({
                                                    RestrictionType: value,
                                                })
                                            }}
                                            options={
                                                editMedicalLicenseContext.state
                                                    .LicenseRestrictionTypeList
                                            }
                                            placeholder="Restriction Type *"
                                            selectedValue={
                                                editMedicalLicenseContext.state.RestrictionType
                                            }
                                            validationStatus={
                                                Locked
                                                    ? GeneralEnums.textInputStatus.disabled
                                                    : InputStatus.RestrictionTypeStatus
                                            }
                                            width="100%"
                                        />
                                    </View>
                                    <View>
                                        <ABATextInputMultiline
                                            label="Details *"
                                            maxLength={2500}
                                            numberOfLines={6}
                                            onChangeText={text => {
                                                editMedicalLicenseContext.setState({
                                                    RestrictionNotes: text,
                                                })
                                            }}
                                            placeholder={
                                                'Please provide the terms and conditions of the restriction on your license'
                                            }
                                            style={styleFunctions.containerText()}
                                            textInputStatus={
                                                Locked
                                                    ? GeneralEnums.textInputStatus.disabled
                                                    : InputStatus.RestrictionNotesStatus
                                            }
                                            value={editMedicalLicenseContext.state.RestrictionNotes}
                                        />
                                    </View>
                                </View>
                            )}
                            {editMedicalLicenseContext.state.CheckRevoked === true && (
                                <View>
                                    <ABATextInputMask
                                        keyboardType="number-pad"
                                        maxLength={10}
                                        onChangeText={text => {
                                            editMedicalLicenseContext.setState({
                                                RevokedEffectiveDate: text,
                                            })
                                        }}
                                        options={{mask: '99/99/9999'}}
                                        placeholder="Effective Date (mm/dd/yyyy) *"
                                        returnKeyType="next"
                                        style={styleFunctions.textInput()}
                                        textInputStatus={
                                            Locked
                                                ? GeneralEnums.textInputStatus.disabled
                                                : InputStatus.RevokedEffectiveDateStatus
                                        }
                                        value={editMedicalLicenseContext.state.RevokedEffectiveDate}
                                        width="100%"
                                        errorMessage={dateValidationErrorMessage}
                                    />
                                    <View style={{marginBottom: 30}}>
                                        <ABADropdown
                                            label="Reason *"
                                            onChange={value => {
                                                editMedicalLicenseContext.setState({
                                                    RevokedReason: value,
                                                })
                                            }}
                                            options={
                                                editMedicalLicenseContext.state
                                                    .LicenseRevokedReasonTypeList
                                            }
                                            placeholder="Reason *"
                                            selectedValue={editMedicalLicenseContext.state.RevokedReason}
                                            validationStatus={
                                                Locked
                                                    ? GeneralEnums.textInputStatus.disabled
                                                    : InputStatus.RevokedReasonStatus
                                            }
                                            width="100%"
                                        />
                                    </View>
                                    <View>
                                        <ABATextInputMultiline
                                            label="Details *"
                                            maxLength={2500}
                                            numberOfLines={6}
                                            onChangeText={text =>
                                                editMedicalLicenseContext.setState({
                                                    RevokedReasonNotes: text,
                                                })
                                            }
                                            placeholder={
                                                'Please provide the terms and conditions of the restriction on your license'
                                            }
                                            style={styleFunctions.containerText()}
                                            textInputStatus={
                                                Locked
                                                    ? GeneralEnums.textInputStatus.disabled
                                                    : this.state.InputStatus.RevokedReasonNotesStatus
                                            }
                                            value={editMedicalLicenseContext.state.RevokedReasonNotes}
                                        />
                                    </View>
                                </View>
                            )}
                            <View style={{paddingTop: 30, paddingBottom: 30}}>
                                <ABACheckbox
                                    isChecked={editMedicalLicenseContext.state.CheckAttest}
                                    label="I attest that the Medical License information listed above is correct and accurate."
                                    labelStyle={styleFunctions.containerText()}
                                    onChangeEvent={() =>
                                        editMedicalLicenseContext.setState({
                                            CheckAttest: !editMedicalLicenseContext.state.CheckAttest,
                                        })
                                    }
                                    validationStatus={this.state.InputStatus.CheckAttestStatus}
                                    isRequired={true}
                                />
                            </View>
                            <Text style={{color: ColorEnums.pomegranate}}>
                                <ABARequiredAsterisk /> Required Field
                            </Text>
                            <View style={{paddingBottom: 20}}>
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{width: '50%', alignSelf: 'flex-start'}}>
                                        <ABATouchableHighlight
                                            buttonStyle={styleFunctions.buttonClickInverted()}
                                            onPressEvent={() => {
                                                EditMedicalLicenseLogic.cancelLicenseUpdate()
                                            }}
                                            text={'Cancel'}
                                            textStyle={{color: ColorEnums.danube, fontSize: 16}}
                                            underlayColor={ColorEnums.white}
                                        />
                                    </View>
                                    <View style={{width: '50%', alignSelf: 'flex-end'}}>
                                        <ABATouchableHighlight
                                            buttonStyle={styleFunctions.buttonClick()}
                                            icon={'angle-right'}
                                            onPressEvent={() => {
                                                EditMedicalLicenseLogic.saveLogic()
                                            }}
                                            text={'Save Changes'}
                                            textStyle={{color: ColorEnums.white, fontSize: 16}}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
