import React from 'react'
import {Platform, Text, View} from 'react-native'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import StyleFunctions from '../../styles/styleFunctions'
import {ColorEnums, UnicodeEnums} from '../../helpers/generalEnums'
import ABAContainer from '../ABACustom/presentation/ABAContainer'

const QuickLinks = () => {
    return (
        <ABAContainer showContainerTitleBar={false} useMaxWidth={false}>
            <View style={{alignItems: 'center'}}>
                <Text
                    style={[StyleFunctions.containerHeader('center'), {paddingBottom: 20, width: '90%'}]}
                >
                    Need more information?
                </Text>
                <Text
                    style={[StyleFunctions.containerText('center'), {paddingBottom: 20, width: '90%'}]}
                >
                    Here are some useful links for new Physician's Portal users
                </Text>

                <View
                    style={{
                        borderTopColor: ColorEnums.aquaHaze,
                        borderTopWidth: 2,
                        flexDirection: 'row',
                        paddingTop: 10,
                        width: Platform.OS === 'web' ? '50%' : '100%',
                    }}
                >
                    <View style={{width: '50%'}}>
                        <View style={{paddingBottom: 10}}>
                            <ABAHyperLink
                                label="BASIC Exam Info"
                                url="https://theaba.org/staged%20exams.html"
                                style={StyleFunctions.actionText()}
                            />
                        </View>
                        <View style={{paddingBottom: 10}}>
                            <ABAHyperLink
                                label="ADVANCED Exam Info"
                                url="https://theaba.org/staged%20exams.html"
                                style={StyleFunctions.actionText()}
                            />
                        </View>
                        <View style={{paddingBottom: 10}}>
                            <ABAHyperLink
                                label="APPLIED Exam Info"
                                url="https://theaba.org/staged%20exams.html"
                                style={StyleFunctions.actionText()}
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            borderLeftWidth: 2,
                            borderLeftColor: ColorEnums.aquaHaze,
                            paddingRight: 10,
                        }}
                    />
                    <View style={{width: '50%'}}>
                        <View style={{paddingBottom: 10}}>
                            <ABAHyperLink
                                label={`About MOCA 2.0${UnicodeEnums['registered-trademark']}`}
                                url="https://theaba.org/about%20moca%202.0.html"
                                style={StyleFunctions.actionText()}
                            />
                        </View>
                        <View style={{paddingBottom: 10}}>
                            <ABAHyperLink
                                label="Contact Us"
                                url="https://theaba.org/contact.html"
                                style={StyleFunctions.actionText()}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </ABAContainer>
    )
}

export default QuickLinks
