import React, {Component} from 'react'
import nccPathwayPageLogic from '../logic/nccPathwayPageLogic'
import {View, Text, ActivityIndicator} from 'react-native'
import styleFunctions from '../../../../styles/styleFunctions'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import HelperMethods from '../../../../helpers/HelperMethods'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'

export default class NCCPathwayPage extends Component {
    state = {
        CallingApi: false,
        pathwayOptionList: [],
        cert_nccpath: null,
        cert_nccposttraining: null,
        cert_nccpath_error: null,
        cert_nccposttraining_error: null,
        saving: false,
        cert_clinicalactivityid: null,
    }

    componentDidMount() {
        const {GetClinicalActivityData, GetNCCPathwayOptions, PopulateVariables} = nccPathwayPageLogic

        Promise.all([GetClinicalActivityData(), GetNCCPathwayOptions()]).then((nccPathwayData) => {
            PopulateVariables(nccPathwayData)
        })
    }

    Save() {
        return nccPathwayPageLogic.SaveLogic()
    }

    Previous() {
        return nccPathwayPageLogic.Previous()
    }

    renderPathwayOptions() {
        const {pathwayOptionList, cert_nccpath: pathwayOption} = this.state
        const {Currently_Practicing} = GeneralEnums.nccTrainingPathway
        return pathwayOptionList.slice(0, 5).map((option) => {
            const {value, label} = option

            return (
                <View style={{marginBottom: 15}} key={value}>
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={pathwayOption === value}
                        label={label}
                        onChangeEvent={() => {
                            if (value !== Currently_Practicing) {
                                this.setState({
                                    cert_nccposttraining: null,
                                })
                            }
                            this.setState({
                                cert_nccpath: value,
                                cert_nccpath_error: null,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    renderPostTrainingOptions() {
        const {pathwayOptionList, cert_nccposttraining: postTrainingOption} = this.state
        return pathwayOptionList.slice(5).map((option) => {
            const {value, label} = option
            return (
                <View style={{marginBottom: 15}} key={value}>
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={postTrainingOption === value}
                        label={label}
                        onChangeEvent={() => {
                            this.setState({
                                cert_nccposttraining: value,
                                cert_nccposttraining_error: null,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    render() {
        nccPathwayPageLogic.context = this
        const {
            cert_nccpath: pathwayOption,
            cert_nccpath_error: pathwayError,
            cert_nccposttraining_error: postTrainingError,
            saving,
        } = this.state
        const {Currently_Practicing} = GeneralEnums.nccTrainingPathway
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 20,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        Neurocritical Care Pathway
                    </Text>

                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 16,
                                paddingTop: 15,
                                width: '100%',
                                marginBottom: 15,
                            },
                        ]}
                    >
                        Please select your Neurocritical Care entrance pathway:
                        <ABARequiredAsterisk />
                    </Text>
                    {pathwayError && (
                        <Text style={{color: ColorEnums.pomegranate}}>Please select a pathway</Text>
                    )}

                    <View>{this.renderPathwayOptions()}</View>
                    {pathwayOption === Currently_Practicing && (
                        <View style={{marginLeft: 30}}>
                            {postTrainingError && (
                                <Text
                                    style={{
                                        color: ColorEnums.pomegranate,
                                        textAlign: 'center',
                                    }}
                                >
                                    Please select a practice
                                </Text>
                            )}
                            {this.renderPostTrainingOptions()}
                        </View>
                    )}
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>{`${saving ? 'Saving' : 'Loading'} NCC Pathway Page`}</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
