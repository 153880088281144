import React, {useState, useEffect} from 'react'
import {View, Text, TouchableHighlight} from 'react-native'
import {ColorEnums, IconEnums, GeneralEnums} from '../../../helpers/generalEnums'

export const ABAPagination = ({
    displayedArrayLength,
    previousPage,
    nextPage,
    style,
    totalRecordCount,
    currentIndex,
}) => {
    const [nextDisabled, setNextDisabled] = useState(false)
    const [previousDisabled, setPreviousDisabled] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setNextDisabled(currentIndex + displayedArrayLength >= totalRecordCount)
            setPreviousDisabled(currentIndex === 0)
        }, 40)
    }, [currentIndex, displayedArrayLength, totalRecordCount])
    return (
        <View
            style={[
                {
                    display: 'flex',
                    flexDirection: 'row',
                },
                style,
            ]}
        >
            <TouchableHighlight
                style={{alignSelf: 'center'}}
                onPress={!previousDisabled && previousPage}
                testID="ABAPrevPage"
            >
                <Text
                    style={{
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        color: previousDisabled === true ? ColorEnums.blueGray : ColorEnums.danube,
                    }}
                >
                    {IconEnums['chevron-left']}
                </Text>
            </TouchableHighlight>
            <View
                style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {totalRecordCount !== 0 && (
                    <>
                        <Text style={{color: ColorEnums.cloudBurst, fontWeight: 'bold'}}>{`${
                            currentIndex + 1
                        } to ${displayedArrayLength + currentIndex}`}</Text>
                        <Text
                            style={{
                                color: ColorEnums.cloudBurst,
                                fontWeight: 'bold',
                            }}
                        >{` of ${totalRecordCount}`}</Text>
                    </>
                )}
                {totalRecordCount === 0 && (
                    <>
                        <Text style={{color: ColorEnums.cloudBurst, fontWeight: 'bold'}}>
                            No Records
                        </Text>
                    </>
                )}
            </View>
            <TouchableHighlight
                style={{alignSelf: 'center'}}
                onPress={!nextDisabled && nextPage}
                testID="ABANextpage"
            >
                <Text
                    style={{
                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                        color: nextDisabled === true ? ColorEnums.blueGray : ColorEnums.danube,
                    }}
                >
                    {IconEnums['chevron-right']}
                </Text>
            </TouchableHighlight>
        </View>
    )
}

export default ABAPagination
