import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import CMECreditReleaseLogic from '../logic/cmeCreditReleaseLogic'
import styleFunctions from '../../../styles/styleFunctions'
import ABACheckbox from '../../../components/ABACustom/presentation/ABACheckbox'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import ABAValidationView from '../../../components/ABACustom/presentation/ABAValidationView'

export default class CMECreditRelease extends Component {
    state = {
        CallingApi: false,
        ReleaseOptionYes: false,
        ReleaseOptionNo: false,
        NoteText: '',
        ReleaseData: null,
        TextDisplay: null,
        LabelText: '',
        cmeCreditReleaseConfirmationError: GeneralEnums.textInputStatus.default,
    }

    componentDidMount() {
        CMECreditReleaseLogic.GetReleaseData().then((Data) => {
            CMECreditReleaseLogic.PopulateRelease(Data)
            CMECreditReleaseLogic.GetText(Data).then((TextDisplay) => {
                this.setState({TextDisplay})
            })
        })
    }

    Save() {
        return CMECreditReleaseLogic.SaveLogic()
    }

    render() {
        CMECreditReleaseLogic.context = this

        return (
            <View>
                <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 15, width: '90%'},
                        ]}
                    >
                        {this.state.TextDisplay}
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingTop: 10,
                        paddingBottom: 25,
                    }}
                >
                    <View style={{width: '90%', alignSelf: 'center', paddingTop: 25}}>
                        <ABACheckbox
                            isChecked={this.state.ReleaseOptionYes}
                            onChangeEvent={CMECreditReleaseLogic.ReleaseOptionYesSelected}
                            label="I Agree - by selecting “I Agree,” I acknowledge this as my legally binding “electronic signature” for this authorization."
                            labelStyle={{
                                fontFamily: 'Lato',
                                color: ColorEnums.cloudBurst,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        />
                    </View>
                    <View style={{width: '90%', alignSelf: 'center', paddingTop: 25}}>
                        <ABACheckbox
                            isChecked={this.state.ReleaseOptionNo}
                            onChangeEvent={CMECreditReleaseLogic.ReleaseOptionNoSelected}
                            label="I Do Not Agree - selecting “I Do Not Agree” does not affect my participation in MOCA."
                            labelStyle={{
                                fontFamily: 'Lato',
                                color: ColorEnums.cloudBurst,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        />
                    </View>
                    <ABAValidationView
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        inputStatus={this.state.cmeCreditReleaseConfirmationError}
                        errorMessage="Please select on option above."
                    />
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Release Information</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
