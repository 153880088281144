import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import styleFunctions from '../../styles/styleFunctions'
import CertificationsLogic from '../logic/certificationsLogic'
import {ColorEnums} from '../../helpers/generalEnums'

export default class Certifications extends Component {
    state = {
        CallingApi: false,
        DisplayContent: [],
        popupHeight: [],
        popupToggle: [],
    }

    componentDidMount() {
        CertificationsLogic.GetCertificationDisplay().then((components) => {
            certificationsContext.setState({DisplayContent: components})
        })
    }

    PopupToggle(index) {
        let PopupToggleArray = certificationsContext.state.popupToggle
        PopupToggleArray[index] = !PopupToggleArray[index]
        certificationsContext.setState({popupToggle: PopupToggleArray})
    }

    render() {
        certificationsContext = this
        CertificationsLogic.context = certificationsContext
        return (
            <View>
                {CertificationsLogic.GetCertificationDisplayComponent(
                    certificationsContext.state.DisplayContent,
                )}
                {certificationsContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Certifications.</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

Certifications.defaultProps = {
    Expanded: false,
    location: {
        pathname: null,
    },
}
