import React, {Component} from 'react'
import {Text} from 'react-native'

import ABAContainer from '../ABACustom/presentation/ABAContainer'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import styleFunctions from '../../styles/styleFunctions'

export default class CMEInformation extends Component {
    render() {
        return (
            <ABAContainer showContainerTitleBar={false} useMaxWidth={false}>
                <Text style={[styleFunctions.containerHeader('center'), {paddingBottom: 20}]}>
                    Discover continuing medical education activities just for you
                </Text>
                <Text style={[styleFunctions.containerText('center'), {paddingBottom: 20}]}>
                    Search and find Category 1 activities that correlate to your MOCA Minute knowledge
                    gaps and count for MOCA 2.0 credit.
                </Text>

                <ABATouchableHighlight
                    icon={'angle-right'}
                    onPressEvent={() => {
                        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/cmeExplorer')
                    }}
                    text={'Use the CME Explorer'}
                />
            </ABAContainer>
        )
    }
}
