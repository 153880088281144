import React, {Component} from 'react'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import MocaMinuteFeedback from '../../components/mocaMinute/presentation/mocaMinuteFeedback'

export default class MocaMinuteFeedbackContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        HelperMethods.setNavigationData(this)
        let NavData = {
            HideFeedback: true,
            AskId: this.navigationData.AskId,
            KnowledgeAssessmentReport: this.navigationData.KnowledgeAssessmentReport,
        }

        return (
            <NavigationShell context={this} print={true}>
                <MocaMinuteFeedback NavData={NavData} />
            </NavigationShell>
        )
    }
}
