import {GeneralEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'

const GeneralIPRLogic = {
    context: null,

    GetIPRText: function (IPRData) {
        return new Promise((resolve) => {
            GeneralIPRLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetPortalText', {
                ReleaseType: IPRData.Crt_ReleaseType,
                ProgramType: GeneralIPRLogic.context.props.ProgramType,
                re_AdditionalSpecification: IPRData.re_AdditionalSpecification,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing IPR Release')
                })
                .finally(function () {
                    GeneralIPRLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetIPRReleaseData: function () {
        return new Promise((resolve) => {
            GeneralIPRLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/GetRelease', {
                Order: GeneralIPRLogic.context.props.Order,
                CR2ID: GeneralIPRLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Medical License Release')
                })
                .finally(function () {
                    GeneralIPRLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateRelease: function (data) {
        GeneralIPRLogic.context.setState({
            ReleaseOption: data.ReleaseOption,
            NoteText: data.Crt_Note || '',
            CRT_FullName: data.SpecialtyName || '',
            Crt_ProgramType: data.Crt_ProgramType,
            IPRReleaseData: data,
        })
    },

    CreateIPRRelease: function () {
        return new Promise((resolve) => {
            GeneralIPRLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/ReleaseUpdateInsert', {
                CR2ID: GeneralIPRLogic.context.props.CR2ID,
                Order: GeneralIPRLogic.context.props.Order,
                Crt_RecordStatus: GeneralIPRLogic.context.state.ReleaseOption ? 1 : 2,
                Crt_Note: GeneralIPRLogic.context.state.NoteText,
                ContactId: GeneralIPRLogic.context.state.IPRReleaseData.ContactId,
                Crt_ReleaseType: GeneralIPRLogic.context.state.IPRReleaseData.Crt_ReleaseType,
                Crt_ProgramType: GeneralIPRLogic.context.state.IPRReleaseData.Crt_ProgramType,
                Crt_ReleaseId: GeneralIPRLogic.context.state.IPRReleaseData.Crt_ReleaseId,
                LastName: GeneralIPRLogic.context.state.IPRReleaseData.LastName,
                FirstName: GeneralIPRLogic.context.state.IPRReleaseData.FirstName,
                rs_statuscode: GeneralIPRLogic.context.state.IPRReleaseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    GeneralIPRLogic.context.setState({CallingApi: false})
                })
        })
    },

    ReleaseOptionYesSelected: function () {
        GeneralIPRLogic.context.setState({
            ReleaseOption: true,
            NoteTextStatus: GeneralEnums.textInputStatus.default,
            NoteText: '',
        })
    },

    ReleaseOptionNoSelected: function () {
        GeneralIPRLogic.context.setState({ReleaseOption: false})
    },

    ValidateValues: function () {
        var NoErrors = true
        var NoteTextStatus = GeneralEnums.textInputStatus.default
        var ReleaseOptionStatus = GeneralEnums.textInputStatus.default
        if (GeneralIPRLogic.context.state.ReleaseOption == null) {
            ReleaseOptionStatus = GeneralEnums.textInputStatus.error
            NoErrors = false
        } else if (
            !GeneralIPRLogic.context.state.ReleaseOption &&
            GeneralIPRLogic.context.state.NoteText == ''
        ) {
            NoteTextStatus = GeneralEnums.textInputStatus.error
            ReleaseOptionStatus = GeneralEnums.textInputStatus.success
            NoErrors = false
        }
        GeneralIPRLogic.context.setState({
            NoteTextStatus: NoteTextStatus,
            ReleaseOptionStatus: ReleaseOptionStatus,
        })
        return NoErrors
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!GeneralIPRLogic.ValidateValues()) resolve(false)
            else {
                GeneralIPRLogic.CreateIPRRelease().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default GeneralIPRLogic
