import React, {Component} from 'react'
import {ScrollView, Text, View, StyleSheet} from 'react-native'

import ABAContainer from '../../../components/ABACustom/presentation/ABAContainer'
import ABACountdownCircle from '../../../components/ABACustom/presentation/ABACountdownCircle'
import ABAHeader from '../../../components/ABACustom/presentation/ABAHeader'
import ABATouchableHighlight from '../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAPerformanceStandard from '../../../components/ABACustom/presentation/ABAPerformanceStandard'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import MocaMinuteLandingLogic from '../logic/mocaMinuteLandingLogic'
import NavigationHelper from '../../../helpers/NavigationHelper'
import styleFunctions from '../../../styles/styleFunctions'
import mocaMinuteLogic from '../../../screens/logic/mocaMinuteLogic'
import MocaMinuteQuestion from './mocaMinuteQuestion'
import PrintedFooter from '../../../components/presentation/printedFooter'
import HelperMethods from '../../../helpers/HelperMethods'

const styles = StyleSheet.create({
    containerTextStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: '100%',
    },
})

export default class MocaMinuteLanding extends Component {
    state = {
        ButtonText: '',
        CallingApi: false,
        HeaderText: null,
        MMStatusScenario: null,
        SubheaderText: null,
        RegistrationIsEligible: false,
        ComponentWidth: null,
        MDTValue: null,
    }

    componentDidMount() {
        Promise.all([
            MocaMinuteLandingLogic.getMMStatus(),
            MocaMinuteLandingLogic.GetMocaMinuteDashboardInfo(),
        ]).then((values) => {
            const MMStatus = values[0]
            const MMDashboardInfo = values[1]
            const {MocaRegistrationStatus, MDTValue} = MMDashboardInfo
            //Check below subject to change. See mocaMinuteInformation.js for info
            if (
                MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredForMOCA ||
                MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredNTL ||
                MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredFirstTime
            ) {
                HelperMethods.alertAgnostic(
                    'Our records indicate that you are not eligible to answer a question at this time. Returning to Dashboard.',
                    'Error',
                    [{text: 'Ok'}],
                )
                NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
            } else {
                this.setState({RegistrationIsEligible: true, MDTValue}, () => {
                    this.updatePageForScenario(MMStatus)
                })
            }
        })
    }

    navigateAppropriately() {
        if (!this.state.RegistrationIsEligible) {
            return
        }
        if (this.state.MMStatusScenario === GeneralEnums.MMStatusScenario.EligibleToAnswer) {
            mocaMinuteLogic.swapMocaComponent(MocaMinuteQuestion, {}, false)
        } else {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/knowledgeAssessmentReport')
        }
    }

    determineEligibleSubheaderText(quarterlyQuestionsRemaining) {
        const {MDTValue} = this.state
        const notMeetingStandardText = (
            <View style={{width: '100%'}}>
                <Text
                    style={[
                        styleFunctions.containerText('center'),
                        styles.containerTextStyle,
                        {paddingBottom: 20, fontWeight: 'bold'},
                    ]}
                >
                    You’re not currently meeting the performance standard.
                </Text>
                <Text style={[styleFunctions.containerText('center')]}>
                    Your MDT value is {MDTValue} (needs to be &ge; 0.10). You have{' '}
                    {quarterlyQuestionsRemaining} questions left this quarter. Answer 120 questions by
                    11:59 p.m. EST on Dec. 31​. At the end of the year, any unanswered questions will be
                    counted as incorrect​.
                </Text>
            </View>
        )
        if (MDTValue == null) {
            return <></>
        } else if (MDTValue >= 0.1) {
            return (
                <Text style={[styleFunctions.containerHeader('center'), styles.containerTextStyle]}>
                    You're doing great! Only {quarterlyQuestionsRemaining} questions left to answer this
                    quarter.
                </Text>
            )
        } else {
            return notMeetingStandardText
        }
    }

    updatePageForScenario(apiResponse) {
        const {MMStatusScenario, AnnualMaxQuestions, QuarterlyQuestionsRemaining} = apiResponse
        const eligibleSubheaderText = this.determineEligibleSubheaderText(QuarterlyQuestionsRemaining)
        switch (MMStatusScenario) {
            case GeneralEnums.MMStatusScenario.ReachedDailyLimit:
                this.setState({
                    HeaderText: "You've reached your daily MOCA Minute question limit.",
                    SubheaderText:
                        'Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.ReachedQuarterlyLimit:
                this.setState({
                    HeaderText: "You've reached your quarterly MOCA Minute question limit.",
                    SubheaderText:
                        'Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.ReachedAnnualLimit:
                this.setState({
                    HeaderText: "You've reached your annual MOCA Minute question limit.",
                    SubheaderText:
                        'You’ve answered ' +
                        AnnualMaxQuestions +
                        ' MOCA Minute questions and reached your yearly limit. Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.EligibleToAnswer:
            default:
                this.setState({
                    HeaderText: 'Are you ready to answer a question?',
                    SubheaderText: eligibleSubheaderText,
                    ButtonText: 'Answer a Question',
                    MMStatusScenario,
                })
                break
        }
    }

    render() {
        MocaMinuteLandingLogic.context = this
        const {ComponentWidth, CallingApi, HeaderText, SubheaderText, ButtonText, MMStatusScenario} =
            this.state
        const isMobile = ComponentWidth < 600
        const eligibleToAnswer = MMStatusScenario === GeneralEnums.MMStatusScenario.EligibleToAnswer

        const mocaMinuteSubheaderText = eligibleToAnswer ? (
            <View
                style={[
                    styleFunctions.containerHeader('center'),
                    styles.containerTextStyle,
                    {paddingBottom: 20},
                ]}
            >
                {SubheaderText}
            </View>
        ) : (
            <Text
                style={[
                    styleFunctions.containerHeader('center'),
                    styles.containerTextStyle,
                    {paddingBottom: 20},
                ]}
            >
                {SubheaderText}
            </Text>
        )

        return (
            <ScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCAMinute} />
                <ABAContainer
                    activityText={'Loading MOCA Minute Status'}
                    containerTitle={'START MOCA'}
                    showActivityIndicator={CallingApi}
                >
                    <View
                        style={{alignItems: 'center', width: '100%'}}
                        onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                    >
                        <ABACountdownCircle
                            doCountdown={false}
                            expirationEvent={() => {}}
                            seconds={60}
                        />

                        <Text
                            style={[
                                styleFunctions.containerHeader('center'),
                                styles.containerTextStyle,
                                {paddingBottom: 20},
                            ]}
                        >
                            {HeaderText}
                        </Text>

                        {mocaMinuteSubheaderText}

                        <ABAPerformanceStandard />
                        <View
                            style={{
                                flexDirection: isMobile ? 'column-reverse' : 'row',
                                width: '80%',
                                justifyContent: eligibleToAnswer ? 'space-between' : 'center',
                                marginTop: 30,
                            }}
                        >
                            {eligibleToAnswer && (
                                <ABATouchableHighlight
                                    buttonStyle={styleFunctions.buttonClickInverted()}
                                    onPressEvent={() =>
                                        NavigationHelper.GoToPage(
                                            GeneralEnums.navigationType.push,
                                            '/dashboard',
                                        )
                                    }
                                    text="Return to Dashboard"
                                    textStyle={{
                                        color: ColorEnums.danube,
                                        fontFamily: 'Lato',
                                        fontSize: 16,
                                    }}
                                />
                            )}
                            <View style={{marginBottom: isMobile ? 10 : 0}}>
                                <ABATouchableHighlight
                                    buttonStyle={styleFunctions.buttonClick()}
                                    onPressEvent={() => {
                                        this.navigateAppropriately()
                                    }}
                                    text={ButtonText}
                                    icon={'angle-right'}
                                />
                            </View>
                        </View>

                        {eligibleToAnswer && (
                            <Text
                                style={[
                                    styleFunctions.placeholderText('center'),
                                    styles.containerTextStyle,
                                    {paddingTop: 20},
                                ]}
                            >
                                The MOCA Minute® question timer will begin counting down as soon as you
                                click “Answer a Question."
                            </Text>
                        )}
                    </View>
                </ABAContainer>
                <PrintedFooter />
            </ScrollView>
        )
    }
}
