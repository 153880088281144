import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const CMEActivitiesLogic = {
    context: null,

    getCMEActivities: function () {
        return new Promise((resolve) => {
            CMEActivitiesLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('cme/getcmeactivities')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving CME Activities Information')
                    resolve(null)
                })
                .finally(function () {
                    CMEActivitiesLogic.context.setState({CallingApi: false})
                })
        })
    },

    deleteActivity: function () {
        return new Promise((resolve) => {
            CMEActivitiesLogic.context.setState({CallingDeleteApi: true})
            ABAPortalAPI.get('cme/deleteCME?CMEID=' + CMEActivitiesLogic.context.state.recordToDelete)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Deleting CME Record')
                    resolve(null)
                })
                .finally(function () {
                    CMEActivitiesLogic.context.setState({CallingDeleteApi: false, recordToDelete: ''})
                })
        })
    },
}
export default CMEActivitiesLogic
