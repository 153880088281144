import React, {Component} from 'react'
import {View} from 'react-native'
import ABAPortalAPI, {setHeaderABAID, setHeaderAuthToken} from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

export default class Authenticate extends Component {
    componentDidMount() {
        navigationContext = this
        if (new URLSearchParams(this.props.location.search).get('token') == null) {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/')
        }
        const body = {AuthenticationToken: new URLSearchParams(this.props.location.search).get('token')}
        const ABAID = new URLSearchParams(this.props.location.search).get('ABAID')
        setHeaderABAID(ABAID)

        ABAPortalAPI.post('authentication/reauthenticate', body).then((response) => {
            setHeaderAuthToken(response.data.AuthenticationToken)
            this.props.setAuthToken(response.data.AuthenticationToken)
            NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/dashboard')
        })
    }
    render() {
        return <View />
    }
}
