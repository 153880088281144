import React, {Component} from 'react'
import {View, Text, StyleSheet, TouchableWithoutFeedback, Platform} from 'react-native'
import {Link} from 'react-router-dom'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import NavigationHelper from '../../helpers/NavigationHelper'
import {GeneralEnums} from '../../helpers/generalEnums'

const styles = StyleSheet.create({
    sublinkText: {
        color: 'white',
        paddingTop: 5,
        paddingBottom: 5,
        fontFamily: 'Lato',
    },
})

export default class SidebarSublinks extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.navigateToPage = this.navigateToPage.bind(this)
    }

    navigateToPage(linkUrl) {
        //The following if statements were made because if the app is on the same url as the link, the link will not do anything.  The
        // if statements were made to make sure that link has some kind of action.

        //If our mobile app is on the same page as the current link, then we will just close our menu and avoid doing a hard reload.
        //Avoiding a hard reload because that seems to send the app back to the dashboard, regardless of the current page.
        if (Platform.OS !== 'web' && bottomNavContext.props.url === linkUrl) {
            return bottomNavContext.setState({showMenu: false})
        }

        if (Platform.OS === 'web' && location.hash === '#' + linkUrl) {
            return location.reload()
        }

        return NavigationHelper.GoToPage(GeneralEnums.navigationType.push, linkUrl)
    }

    render() {
        return (
            <View style={{paddingLeft: 30}}>
                {this.props.links.map((link, index) => {
                    if (link.MenuItemText === 'Status Letter') {
                        if (Platform.OS !== 'web') {
                            return
                        }
                        return (
                            <ABAHyperLink
                                key={index}
                                label={link.MenuItemText}
                                url={link.MenuItemUrl}
                                style={styles.sublinkText}
                                addAuth={true}
                            />
                        )
                    }

                    return link.MenuItemUrl.startsWith('/') ? (
                        // Internal links
                        <TouchableWithoutFeedback
                            onPress={() => {
                                this.navigateToPage(link.MenuItemUrl)
                            }}
                            key={index}
                            testID={'sidebarSublink' + link.MenuItemText}
                        >
                            <View>
                                <Text style={styles.sublinkText}>{link.MenuItemText}</Text>
                            </View>
                        </TouchableWithoutFeedback>
                    ) : (
                        // External Links that will open in a new tab
                        <ABAHyperLink
                            key={index}
                            label={link.MenuItemText}
                            url={link.MenuItemUrl}
                            style={styles.sublinkText}
                        />
                    )
                })}
            </View>
        )
    }
}
