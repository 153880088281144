import React, {Component} from 'react'

import PropTypes from 'prop-types'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import NonStandardExamLogicStep7 from '../logic/nonStandardExamStep7Logic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import HelperMethods from '../../../../helpers/HelperMethods'
import NonStandardExamStep1 from '../presentation/nonStandardExamStep1'
import NonStandardExamStep2 from '../presentation/nonStandardExamStep2'
import NonStandardExamStep3 from '../presentation/nonStandardExamStep3'
import NonStandardExamStep4 from '../presentation/nonStandardExamStep4'
import NonStandardExamStep5 from '../presentation/nonStandardExamStep5'
import NonStandardExamStep6 from '../presentation/nonStandardExamStep6'

const styles = StyleSheet.create({
    certificationComponentTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    certificationComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
        flex: 1,
    },

    editButtonStyle: {
        alignItems: 'center',
        backgroundColor: ColorEnums.white,
        borderColor: ColorEnums.danube,
        borderRadius: 50,
        borderWidth: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        minHeight: 40,
    },
    ConfirmationTextTitleStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
})

export default class nonStandardExamStep7 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
    }

    Save() {
        return NonStandardExamLogicStep7.SaveLogic()
    }

    Previous() {
        return NonStandardExamLogicStep7.Previous()
    }

    confirmationSteps(IsFromDashboard) {
        var response = [
            {ConfirmationComponent: NonStandardExamStep1, title: 'Instructions'},
            {ConfirmationComponent: NonStandardExamStep3, title: 'Impairment'},
            {ConfirmationComponent: NonStandardExamStep4, title: 'Devices & Measures'},
            {ConfirmationComponent: NonStandardExamStep5, title: 'Accommodation'},
            {ConfirmationComponent: NonStandardExamStep6, title: 'Attestations'},
        ]

        if (nonStandardExamContext.state.ExamResponse !== 1) {
            return [{ConfirmationComponent: NonStandardExamStep1, title: 'Instructions'}]
        }

        if (IsFromDashboard) {
            let step2 = {ConfirmationComponent: NonStandardExamStep2, title: 'Online Form Instructions'}
            response.splice(2, 0, step2)
        }

        return response
    }

    render() {
        nonStandardExamStep7Context = this
        NonStandardExamLogicStep7.context = nonStandardExamStep7Context
        const {ReviewResult} = nonStandardExamContext.state
        const approvedResult = 970230001
        isEditable = !this.props.IsViewRegistration && ReviewResult !== approvedResult

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.IsViewRegistration && (
                    <View>
                        <Text> Page 5 of 5</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0),
                            ]}
                        />
                    </View>
                )}
                <View>
                    {ReviewResult === approvedResult && (
                        <View
                            style={{
                                backgroundColor: ColorEnums.pomegranateLight,
                                borderRadius: 4,
                                minHeight: 50,
                                padding: 20,
                                alignItems: 'center',
                            }}
                        >
                            <Text style={{color: ColorEnums.pomegranateDark, fontSize: 20}}>
                                Request has been approved by the ABA. No further edits are allowed.
                            </Text>
                        </View>
                    )}
                    {this.confirmationSteps(this.props.IsFromDashboard).map((element, key) => {
                        return (
                            <View key={key} style={{paddingTop: 20, justifyContent: 'center'}}>
                                {isEditable && (
                                    <View
                                        style={{
                                            paddingTop: 20,
                                            zIndex: 100,
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                        }}
                                    >
                                        <ABATouchableHighlight
                                            buttonStyle={styles.editButtonStyle}
                                            onPressEvent={() =>
                                                NonStandardExamLogicStep7.GoToComponentStep(
                                                    element.ConfirmationComponent,
                                                )
                                            }
                                            textStyle={{
                                                color: ColorEnums.danube,
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                            }}
                                            text={'Edit'}
                                        />
                                    </View>
                                )}
                                <ABAAccordion
                                    title={element.title}
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                    accordionTitleStyle={styles.certificationComponentTitle}
                                    accordionButtonStyle={styles.certificationComponentTitlebutton}
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionExpanded={true}
                                    accordionUnderlayColor={ColorEnums.white}
                                    accordionIconLeft={{
                                        collapsed: 'chevron-down',
                                        expanded: 'chevron-up',
                                    }}
                                    accordionIconStyle={styleFunctions.AccordionIconStyle()}
                                    accordionContent={
                                        <View>
                                            <element.ConfirmationComponent
                                                CR2ID={nonStandardExamStep7Context.props.CR2ID}
                                                Order={nonStandardExamStep7Context.props.Order}
                                                ProgramType={
                                                    nonStandardExamStep7Context.props.ProgramType
                                                }
                                                isConfirmationPage={true}
                                            />
                                            <View style={styleFunctions.confirmationOverlay()} />
                                        </View>
                                    }
                                />
                            </View>
                        )
                    })}
                </View>

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Non-Standard Exam Info</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

nonStandardExamStep7.propTypes = {
    IsFromDashboard: PropTypes.bool,
}
