import React, {Component} from 'react'
import {StyleSheet, Text, View, Image, TouchableHighlight, Animated} from 'react-native'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import ABARequiredAsterisk from './ABARequiredAsterisk'
import PropTypes from 'prop-types'

export default class ABAAccordion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: this.props.accordionExpanded ? true : false,
            iconClass:
                this.props.accordionIconRight || this.props.accordionIconLeft
                    ? [{fontFamily: GeneralEnums.fontAwesomeFamilyName}, this.props.accordionIconStyle]
                    : [{height: 0, width: 0}],
            iconLeft: !this.props.accordionIconLeft
                ? null
                : this.props.accordionIconLeft && this.props.accordionExpanded
                ? IconEnums[this.props.accordionIconLeft.expanded]
                : IconEnums[this.props.accordionIconLeft.collapsed],
            iconRight: !this.props.accordionIconRight
                ? null
                : this.props.accordionIconRight && this.props.accordionExpanded
                ? IconEnums[this.props.accordionIconRight.expanded]
                : IconEnums[this.props.accordionIconRight.collapsed],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.accordionExpanded != this.props.accordionExpanded) {
            this.setState({expanded: this.props.accordionExpanded}, () => {
                if (this.state.expanded) {
                    if (this.props.accordionIconRight) {
                        this.setState({
                            iconRight: IconEnums[this.props.accordionIconRight.expanded],
                        })
                    } else {
                        this.setState({
                            iconLeft: IconEnums[this.props.accordionIconLeft.expanded],
                        })
                    }
                } else {
                    if (this.props.accordionIconRight) {
                        this.setState({
                            iconRight: IconEnums[this.props.accordionIconRight.collapsed],
                        })
                    } else {
                        this.setState({
                            iconLeft: IconEnums[this.props.accordionIconLeft.collapsed],
                        })
                    }
                }
            })
        }
    }

    showBottomView() {
        if (this.state.expanded) {
            return this.props.accordionBottomViewStyle
        } else {
            return this.props.accordionBottomViewStyle, {height: 0, opacity: 0}
        }
    }

    toggle(callback = true) {
        this.setState(
            {
                expanded: !this.state.expanded,
            },
            () => {
                if (
                    this.props.accordionIconRight === undefined &&
                    this.props.accordionIconLeft === undefined
                )
                    return

                if (this.state.expanded) {
                    if (this.props.accordionIconRight) {
                        this.setState({
                            iconRight: IconEnums[this.props.accordionIconRight.expanded],
                        })
                    } else {
                        this.setState({
                            iconLeft: IconEnums[this.props.accordionIconLeft.expanded],
                        })
                    }
                } else {
                    if (this.props.accordionIconRight) {
                        this.setState({
                            iconRight: IconEnums[this.props.accordionIconRight.collapsed],
                        })
                    } else {
                        this.setState({
                            iconLeft: IconEnums[this.props.accordionIconLeft.collapsed],
                        })
                    }
                }

                this.props.scrollOnToggle &&
                    this.props.scrollviewRef.scrollTo({x: 0, y: 0, animated: false})
                callback &&
                    this.props.toggleCallback &&
                    this.props.toggleCallback(this.props.accordianID, this.state.expanded)
            },
        )
    }

    _setMaxHeight = () => {
        this.refs.bottom.measure((ox, oy, width, height) => {
            this.setState({maxHeight: height})
        })
    }

    _setMinHeight(event) {
        this.setState({
            minHeight: event.nativeEvent.layout.height,
        })
    }

    render() {
        return (
            <Animated.View style={[this.props.accordionContainerStyle]}>
                <View
                    style={this.props.accordionTitleContainerStyle}
                    onLayout={(event) => {
                        this._setMinHeight(event)
                    }}
                >
                    <TouchableHighlight
                        underlayColor={
                            this.props.accordionUnderlayColor
                                ? this.props.accordionUnderlayColor
                                : ColorEnums.blueGray
                        }
                        style={this.props.accordionButtonStyle}
                        onPress={() => {
                            this.toggle()
                            if (this.props.onPress) {
                                this.props.onPress()
                            }
                        }}
                        testID={'ABAAccordion' + this.props.title}
                    >
                        {this.props.accordionIconRight ? (
                            <View style={{flexDirection: 'row'}}>
                                <Text style={this.props.accordionTitleStyle}>{this.props.title}</Text>
                                {this.props.isRequired && <ABARequiredAsterisk />}
                                <Text style={this.state.iconClass}>{this.state.iconRight}</Text>
                            </View>
                        ) : this.props.accordionIconLeft ? (
                            <View>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={this.state.iconClass}>{this.state.iconLeft}</Text>
                                    <Text style={this.props.accordionTitleStyle}>
                                        {this.props.title}
                                    </Text>
                                    {this.props.isRequired && <ABARequiredAsterisk />}
                                </View>
                                <View>{this.props.title2}</View>
                            </View>
                        ) : (
                            <View style={{flexDirection: 'row'}}>
                                <Text style={this.props.accordionTitleStyle}>{this.props.title}</Text>
                                {this.props.isRequired && <ABARequiredAsterisk />}
                            </View>
                        )}
                    </TouchableHighlight>
                </View>
                <View
                    ref="bottom"
                    style={this.showBottomView()}
                    onLayout={(event) => {
                        this._setMaxHeight()
                    }}
                >
                    {this.props.accordionContent}
                </View>
            </Animated.View>
        )
    }
}

ABAAccordion.defaultProps = {
    accordionIconLeft: {collapsed: 'chevron-down', expanded: 'chevron-up'},
    onPress: null,
}

ABAAccordion.propTypes = {
    onPress: PropTypes.func,
}
