import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

export default KnowledgeAssessmentReportLogic = {
    context: null,

    getKnowledgeAssessment: function () {
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    mocaYear: parseInt(KnowledgeAssessmentReportLogic.context.props.userData.KARYear),
                },
            }

            KnowledgeAssessmentReportLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('knowledgeAssessment/getKnowledgeAssessment', getParams)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Knowledge Assessment Report')
                    resolve(null)
                })
                .finally(function () {
                    KnowledgeAssessmentReportLogic.context.setState({CallingApi: false})
                })
        })
    },

    getKnowledgeAssessmentApi: function () {
        this.getKnowledgeAssessment().then((karQuestions) => {
            let questionCategories = []

            karQuestions.data.map((question) => {
                //Removing any random p tags from Questions and Key Points
                if (question.KeyPointText.includes('<p')) {
                    question.KeyPointText = question.KeyPointText.replace(/<\/?p[^>]*>/g, '')
                }

                if (question.QuestionText.includes('<p')) {
                    question.QuestionText = question.QuestionText.replace(/<\/?p[^>]*>/g, '')
                }

                //if questionCategories already has this question's category in it, then we will add it to that object's 'question' array.
                //this will help us order each question into the correct categories.
                for (let i = 0; i < questionCategories.length; i++) {
                    if (questionCategories[i].categoryName === question.Level1Description) {
                        questionCategories[i].questions.push(question)
                        return
                    }
                }

                //if questionCategories did not have this specific question's category in it, then we need to create a new object with the Level1Description as the category name
                questionCategories.push({
                    categoryName: question.Level1Description,
                    questions: [question],
                    showCategory: false,
                })
            })

            this.context.setState({questionCategories})
        })
    },
}
