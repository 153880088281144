import React, { Component } from 'react';
import {
    View,
    Text
} from "react-native";
import NavigationShell from "../../components/presentation/navigationShell";

export default class CovidVaccinationCardSubmission extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <NavigationShell context={this}>
                <View style={{ flex: 1 }}>
                    <Text>COVID Vaccination Card Submission</Text>
                </View>
            </NavigationShell>
        )
    }
}