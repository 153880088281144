import React, {Component} from 'react'
import {StyleSheet, Text, View, Platform} from 'react-native'
import {connect} from 'react-redux'

import ABAContainer from '../ABACustom/presentation/ABAContainer'
import ABABarChart from '../ABACustom/presentation/ABABarChart'
import CMEActivitiesLinks from './cmeActivitiesLinks'
import CMEDashboardLinks from './cmeDashboardLinks'
import CMEDiplomateInMOCALogic from '../logic/cmeDiplomateInMOCALogic'
import MyProgressLinks from './myProgressLinks'

import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import StyleFunctions from '../../styles/styleFunctions'

import {setUserRole} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = {setUserRole}
class CMEDiplomateInMOCA extends Component {
    constructor(props) {
        super(props)

        this.dataColumnsWidth = 1

        this.styles = StyleSheet.create({
            rowContainer: {
                alignItems: 'center',
                flexDirection: 'row',
            },
            rowLabel: {
                fontWeight: 'bold',
                paddingBottom: 5,
                paddingTop: 10,
            },
            rowValuesContainer: {
                alignItems: 'flex-end',
                flex: 1,
            },
        })

        this.state = {
            CallingApi: true,
            ComponentWidth: 0,
            DiplomateInMOCAContent: null,
            showPatientSafety: true,
        }
    }

    componentDidMount() {
        CMEDiplomateInMOCALogic.GetDiplomateInMOCADisplay().then((apiResponse) => {
            this.setState({DiplomateInMOCAContent: apiResponse})
        })

        CMEDiplomateInMOCALogic.GetPatientSafetyRuleStatus().then((patientSafetyRuleStatus) => {
            this.setState({showPatientSafety: patientSafetyRuleStatus})
        })

        HelperMethods.getUserRole(this.props.userData.userRole, this.props.setUserRole)
    }

    roundToTwoDecimals(int) {
        if (isNaN(int)) int = 0
        int = Number(int.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])
        return int
    }

    showLinks() {
        if (this.props.DashboardDisplay) {
            return (
                <CMEDashboardLinks
                    RetiredRevoked={
                        this.props.userData.userRole === GeneralEnums.roleEnums.retiredRevoked
                    }
                />
            )
        }

        return <CMEActivitiesLinks SelectedYearForTable={this.props.SelectedYearForTable} />

    }

    render() {
        CMEDiplomateInMOCALogic.context = this

        if (this.state.DiplomateInMOCAContent === null) {
            return <View />
        }

        const {
            TotalCMEYrToDate,
            CMEMaxPerYr,
            Total5YrCMECredits,
            CMETargetYr5,
            Total10YrCMECredits,
            CMEMaxPer10Yr,
            TotalPSCMECredits,
            CMEPSMaxPer10Yr,
            MOCAYear,
            TotalCMERequired,
        } = this.state.DiplomateInMOCAContent

        let ytdRemainingWidth = 100 - this.roundToTwoDecimals((TotalCMEYrToDate / CMEMaxPerYr) * 100)
        let cmeRemainingWidth = 0
        let cmeMaxYearTotal = 0
        let cmeTotalCredits = 0
        let psRemainingWidth = 100 - (TotalPSCMECredits / TotalCMERequired) * 100
        let cmeTargetLabel = ''

        if (MOCAYear >= 6) {
            cmeTargetLabel = 'Year 1 - 10'
            cmeRemainingWidth = 100 - (Total10YrCMECredits / CMEMaxPer10Yr) * 100
            cmeTotalCredits = Total10YrCMECredits
            cmeMaxYearTotal = CMEMaxPer10Yr
        } else if (MOCAYear < 6 && Total5YrCMECredits >= CMETargetYr5) {
            cmeTargetLabel = 'Year 1 - 10'
            cmeRemainingWidth = 100 - (Total5YrCMECredits / CMEMaxPer10Yr) * 100
            cmeTotalCredits = Total5YrCMECredits
            cmeMaxYearTotal = CMEMaxPer10Yr
        } else {
            cmeTargetLabel = 'Year 1 - 5'
            cmeRemainingWidth = 100 - this.roundToTwoDecimals((Total5YrCMECredits / CMETargetYr5) * 100)
            cmeTotalCredits = Total5YrCMECredits
            cmeMaxYearTotal = CMETargetYr5
        }

        let ytdFilled = this.roundToTwoDecimals(
            (Math.min(TotalCMEYrToDate, CMEMaxPerYr) / CMEMaxPerYr) * 100,
        )
        let cmeFilled = this.roundToTwoDecimals(
            (Math.min(cmeTotalCredits, cmeMaxYearTotal) / cmeMaxYearTotal) * 100,
        )
        let patientSafetyFilled = this.roundToTwoDecimals(
            (Math.min(TotalPSCMECredits, TotalCMERequired) / TotalCMERequired) * 100,
        )

        const {ShowActivitiesLink} = this.props
        const cmeBorder = {
            borderLeftWidth: ShowActivitiesLink === true ? 2 : null,
            borderLeftColor: ShowActivitiesLink === true ? ColorEnums.aquaHaze : null,
        }
        const {showPatientSafety} = this.state

        return (
            <ABAContainer
                activityText={'Loading CME Data'}
                showActivityIndicator={this.state.CallingApi}
                showContainerTitleBar={false}
                useMaxWidth={this.props.useMaxWidth}
                overrideWidth={this.props.overrideWidth}
            >
                <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                    <Text style={{fontFamily: 'Lato', textAlign: 'center', color: ColorEnums.cloudBurst, marginBottom: 15, marginTop: 5}}>
                        A maximum of 60 CME credits will be accepted per calendar year.
                    </Text>
                    <View style={this.styles.rowContainer}>
                        <Text style={[StyleFunctions.paragraphText(), this.styles.rowLabel]}>
                            CME Credits Earned {cmeTargetLabel}
                        </Text>
                        <View style={this.styles.rowValuesContainer}>
                            <Text style={[StyleFunctions.paragraphText(), {fontWeight: 'bold'}]}>
                                {cmeTotalCredits.toFixed(2)} / {cmeMaxYearTotal}
                            </Text>
                        </View>
                    </View>
                    <View style={{paddingBottom: 20}}>
                        <ABABarChart
                            outerbarstyle={[StyleFunctions.chartoutercontainer(), {width: '100%'}]}
                            filledbarstyle={[
                                StyleFunctions.filledbarbgcolor(),
                                StyleFunctions.progressBarFill(),
                                {width: `${cmeFilled}%`},
                            ]}
                            emptybarstyle={[
                                StyleFunctions.emptybarbgcolor(),
                                StyleFunctions.progressBarEmpty(),
                                {width: `${cmeRemainingWidth}%`},
                            ]}
                        />
                    </View>

                    {showPatientSafety && (
                        <View>
                            <View style={this.styles.rowContainer}>
                                <Text style={[StyleFunctions.paragraphText(), this.styles.rowLabel]}>
                                    Patient Safety CME Credits
                                </Text>
                                <View style={this.styles.rowValuesContainer}>
                                    <Text style={[StyleFunctions.paragraphText(), {fontWeight: 'bold'}]}>
                                        {TotalPSCMECredits.toFixed(2)} / {TotalCMERequired}
                                    </Text>
                                </View>
                            </View>
                            <View style={{paddingBottom: 20}}>
                                <ABABarChart
                                    outerbarstyle={[
                                        StyleFunctions.chartoutercontainer(),
                                        {width: '100%'},
                                    ]}
                                    filledbarstyle={[
                                        StyleFunctions.filledbarbgcolor(),
                                        StyleFunctions.progressBarFill(),
                                        {width: `${patientSafetyFilled}%`},
                                    ]}
                                    emptybarstyle={[
                                        StyleFunctions.emptybarbgcolor(),
                                        StyleFunctions.progressBarEmpty(),
                                        {width: `${psRemainingWidth}%`},
                                    ]}
                                />
                            </View>
                        </View>
                    )}

                    {!this.props.overrideWidth && this.showLinks()}
                </View>
            </ABAContainer>
        )
    }
}

CMEDiplomateInMOCA.defaultProps = {
    useMaxWidth: true,
    overrideWidth: false,
    ShowActivitiesLink: true,
    SelectedYearForTable: '1900',
}

export default connect(mapStateToProps, mapDispatchToProps)(CMEDiplomateInMOCA)
