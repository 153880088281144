import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Image, Platform, Text, View} from 'react-native'

import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import MocaActive from '../../../assets/images/MOCA-Active.png'
import MocaMinuteLogo from '../../../assets/images/MOCA-Minute-Logo.png'

export default class ABAHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headingIcon: null,
            headingText: null,
        }
    }

    componentDidMount() {
        this.setIconAndHeadingText()
    }

    setIconAndHeadingText() {
        switch (this.props.group) {
            case GeneralEnums.ABAHeaderGroups.AccountSettings:
                this.setState({
                    headingIcon: IconEnums['user-circle'],
                    headingText: 'Account Settings',
                })
                break
            case GeneralEnums.ABAHeaderGroups.Dashboard:
                this.setState({
                    headingIcon: IconEnums['tachometer-alt'],
                    headingText: 'Dashboard',
                })
                break
            case GeneralEnums.ABAHeaderGroups.ExamInformation:
                this.setState({
                    headingIcon: IconEnums['clipboard-list'],
                    headingText: 'Exam Information',
                })
                break
            case GeneralEnums.ABAHeaderGroups.FAQ:
                this.setState({
                    headingIcon: IconEnums['question-circle'],
                    headingText: 'Frequently Asked Questions',
                })
                break
            case GeneralEnums.ABAHeaderGroups.MOCA:
                this.setState({
                    headingText: 'MOCA',
                })
                break
            case GeneralEnums.ABAHeaderGroups.MOCAMinute:
                this.setState({
                    headingText: 'MOCA Minute',
                })
                break
            case GeneralEnums.ABAHeaderGroups.Performance:
                this.setState({
                    headingIcon: IconEnums['chart-line'],
                    headingText: 'Performance',
                })
                break
            case GeneralEnums.ABAHeaderGroups.PracticeProfile:
                this.setState({
                    headingIcon: IconEnums['clipboard-list'],
                    headingText: 'Practice Profile',
                })
                break
            case GeneralEnums.ABAHeaderGroups.ProgressReport:
                this.setState({
                    headingIcon: IconEnums['clipboard-list'],
                    headingText: 'Progress Report',
                })
                break
            case GeneralEnums.ABAHeaderGroups.QuestionHistory:
                this.setState({
                    headingIcon: IconEnums['history'],
                    headingText: 'Question History',
                })
                break
            case GeneralEnums.ABAHeaderGroups.Conflicts:
                this.setState({
                    headingIcon: IconEnums['ban'],
                    headingText: 'Examiner Conflicts',
                })
                break
            case GeneralEnums.ABAHeaderGroups.ExaminerAvailability:
                this.setState({
                    headingIcon: IconEnums['user-circle'],
                    headingText: 'Availability',
                })
                break
            case GeneralEnums.ABAHeaderGroups.PostedDocuments:
                this.setState({
                    headingIcon: IconEnums['user-circle'],
                    headingText: 'Posted Documents',
                })
        }
    }

    render() {
        return (
            <View style={{backgroundColor: ColorEnums.backgroundGray}}>
                <View
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: 70,
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '95%',
                    }}
                >
                    {this.props.group !== GeneralEnums.ABAHeaderGroups.MOCAMinute &&
                        this.props.group !== GeneralEnums.ABAHeaderGroups.MOCA && (
                            <>
                                <Text
                                    style={{
                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                        color: ColorEnums.danube,
                                        fontSize:
                                            Platform.OS === 'web'
                                                ? this.props.fontSizeWeb
                                                : this.props.fontSizeMobile,
                                    }}
                                >
                                    {this.state.headingIcon || this.props.icon}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Lato',
                                        color: ColorEnums.danube,
                                        fontSize:
                                            Platform.OS === 'web'
                                                ? this.props.fontSizeWeb
                                                : this.props.fontSizeMobile,
                                    }}
                                >
                                    {' ' + (this.state.headingText || this.props.heading)}
                                </Text>
                            </>
                        )}
                    {(this.props.group === GeneralEnums.ABAHeaderGroups.MOCAMinute ||
                        this.props.group === GeneralEnums.ABAHeaderGroups.MOCA) && (
                        <Image
                            style={{
                                height: Platform.OS === 'web' ? 70 : 56,
                                width: Platform.OS === 'web' ? 1562 : 1250,
                            }}
                            source={MocaMinuteLogo}
                            resizeMode={'contain'}
                        />
                    )}
                </View>
            </View>
        )
    }
}

ABAHeader.propTypes = {
    fontSizeWeb: PropTypes.number,
    group: PropTypes.oneOf([
        GeneralEnums.ABAHeaderGroups.AccountSettings,
        GeneralEnums.ABAHeaderGroups.Dashboard,
        GeneralEnums.ABAHeaderGroups.ExamInformation,
        GeneralEnums.ABAHeaderGroups.FAQ,
        GeneralEnums.ABAHeaderGroups.MOCA,
        GeneralEnums.ABAHeaderGroups.MOCAMinute,
        GeneralEnums.ABAHeaderGroups.Performance,
        GeneralEnums.ABAHeaderGroups.PracticeProfile,
        GeneralEnums.ABAHeaderGroups.ProgressReport,
        GeneralEnums.ABAHeaderGroups.QuestionHistory,
        GeneralEnums.ABAHeaderGroups.PostedDocuments,
    ]),
    heading: PropTypes.string,
    icon: PropTypes.string,
}

ABAHeader.defaultProps = {
    fontSizeWeb: 36,
    fontSizeMobile: 24,
}
