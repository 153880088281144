import React, {Component, Fragment} from 'react'
import {findNodeHandle, Text, View, Platform, StyleSheet, UIManager} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import ProgressReportLogic from '../logic/progressReportLogic'
import NavigationShell from '../../components/presentation/navigationShell'
import StyleFunctions from '../../styles/styleFunctions'
import NavigationHelper from '../../helpers/NavigationHelper'
import Certifications from '../../components/presentation/certifications'
import {GeneralEnums, ColorEnums, IconEnums, UnicodeEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import MedLicense from '../../components/presentation/medlicense'
import CMEDiplomateInMOCA from '../../components/presentation/cmeDiplomateInMOCA'
import MyProgressMM from '../../components/presentation/myProgressMM'
import QI_IMP from '../../components/presentation/qi_imp'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import PrintedFooter from '../../components/presentation/printedFooter'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'

export default class ProgressReport extends Component {
    constructor(props) {
        super(props)
        let componentExpanded = []
        this.componentsToRender().forEach((element) => {
            componentExpanded.push(false)
        })
        this.componentRefs = Array(componentExpanded.length)

        this.state = {
            CallingApi: false,
            componentWidth: '100%',
            componentBeforePrint: [],
            componentExpanded,
            professionalStanding: 2,
            showProgressReport: false,
            showRoleError: false,
            MMDataContainer: {
                AnnualMaxQuestions: null,
                AnnualQuestionsRemaining: null,
                CurrentQuarter: null,
                DailyQuestionsRemaining: null,
                HasReachedAnnualLimit: false,
                HasReachedDailyLimit: false,
                HasReachedQuarteryLimit: false,
                IsEligibleCurrently: false,
                MDTValue: null,
                MMStatusScenario: null,
                MocaRegistrationStatus: null,
                QuarterlyQuestionsRemaining: null,
                Q1Answered: null,
                Q2Answered: null,
                Q3Answered: null,
                Q4Answered: null,
            },
        }
    }

    styles = StyleSheet.create({
        descriptionText: {
            textAlign: 'left',
            fontFamily: 'lato',
            fontSize: 16,
            paddingTop: 30,
            width: '90%',
            color: ColorEnums.cloudBurst,
        },
    })

    QIBulletPoint = `You must attest to completing 25 points of QI activity every five years. You will attest to a total of 50 QI points if you are on a 10-year certification cycle.  `

    renderBullets(bulletContent) {
        return bulletContent.map((content, index) => {
            return (
                <View key={index} style={{flexDirection: 'row'}}>
                    <Text
                        style={[
                            this.styles.descriptionText,
                            {paddingLeft: 5, paddingRight: 5, width: 20, paddingTop: 5},
                        ]}
                    >
                        {UnicodeEnums['bullet']}
                    </Text>
                    <Text style={[this.styles.descriptionText, {paddingTop: 5}]}>
                        {content}
                        {content === 'Choose activities from the approved​ ' && (
                            <Text
                                style={{color: ColorEnums.danube}}
                                onPress={() =>
                                    HelperMethods.openURL(
                                        'https://www.theaba.org/wp-content/uploads/2023/01/MOCA_QI_Activities.pdf',
                                    )
                                }
                            >
                                QI Activity list
                            </Text>
                        )}
                        {content === this.QIBulletPoint && (
                            <>
                                <ABAHyperLink
                                    label="Visit our website "
                                    url="https://www.theaba.org/maintain-certification/"
                                    style={StyleFunctions.actionText()}
                                />
                                to review the full MOCA requirements for five- and 10-year certification
                                cycles. Unsure what cycle you're on?{'  '}
                                <ABAHyperLink
                                    label="Click here "
                                    url="https://www.theaba.org/wp-content/uploads/2023/05/MOCA-Expiration-Date-Verification73.pdf"
                                    style={StyleFunctions.actionText()}
                                />
                                to learn how to check your cycle length.
                            </>
                        )}
                    </Text>
                </View>
            )
        })
    }

    componentsToRender() {
        return [
            {
                component: MedLicense,
                title: 'Medical License',
                previewText: (
                    <Text style={this.styles.descriptionText}>
                        You must maintain an active medical license and update your status
                        {UnicodeEnums['ellipsis'] + '  '}
                    </Text>
                ),
                displayText: (
                    <Text style={this.styles.descriptionText}>
                        You must maintain an active medical license and update your status annually to be
                        considered participating in the MOCA program. All diplomates’ medical license
                        should be in an active, unrestricted status to practice medicine in at least one
                        jurisdiction of the United States or Canada.{'  '}
                    </Text>
                ),
                quickLinks: [
                    {text: 'My Medical License', url: '/medicalLicense'},
                    {text: 'Add Medical License', url: '/editMedicalLicense'},
                ],
            },

            {
                component: CMEDiplomateInMOCA,
                title: 'Continuing Medical Education',
                previewText: (
                    <Text style={this.styles.descriptionText}>
                        Completion of Continued Medical Education (CME) activities allow you
                        {UnicodeEnums['ellipsis'] + '  '}
                    </Text>
                ),
                displayText: (
                    <View>
                        <Text style={this.styles.descriptionText}>
                            Completion of Continued Medical Education (CME) activities allow you to
                            continuously gain knowledge throughout your career and participate in
                            educational activities that support lifelong learning.
                            {'\n\n'}
                            <Text style={{fontWeight: 'bold'}}>
                                How Continued Medical Education Works​
                            </Text>
                            {'\n\n'}
                            You must earn 125 Category 1 CME credits, including 10 patient safety
                            credits, every five years. You will attest to a total of 250 CME credits,
                            including 20 patient safety credits, if you are on a 10-year certification
                            cycle.
                            {'\n\n'}
                            <ABAHyperLink
                                label="Visit our website"
                                url="https://www.theaba.org/maintain-certification/"
                                style={StyleFunctions.actionText()}
                            />{' '}
                            to review the full MOCA requirements for five- and 10-year certification
                            cycles. Unsure what cycle you're on?{'  '}
                            <ABAHyperLink
                                label="Click here"
                                url="https://www.theaba.org/wp-content/uploads/2023/05/MOCA-Expiration-Date-Verification73.pdf"
                                style={StyleFunctions.actionText()}
                            />{' '}
                            to learn how to check your cycle length.
                            {'\n\n'}
                            Acceptable Category 1 CMEs are:​{'\n\n'}
                        </Text>
                        {this.renderBullets([
                            'ACCME/AMA PRA-approved​​',
                            'American Osteopathic Association Category 1-A​',
                            'Accredited CPD credits issued by the Royal College of Physicians of Canada and the Association of Faculties of Medicine of Canada',
                        ])}
                        <Text style={[this.styles.descriptionText, {fontWeight: 'bold'}]}>
                            How to Meet the Requirement​{'\n\n'}
                        </Text>
                        {this.renderBullets([
                            'No more than 60 credits completed in or after 2013 will be applied per calendar year​',
                            'Some Quality Improvement (QI) activities can also count towards your CME requirements but you must report them separately​',
                        ])}
                    </View>
                ),
                quickLinks: [
                    {text: 'My CME Activities', url: '/cmeActivities'},
                    {text: 'Report New CME', url: '/reportNewCME'},   
                    {
                        text: 'ABA-Approved Patient Safety CME',
                        url: 'https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf',
                    },
                    {
                        text: 'How to Self-Report CME',
                        url: 'https://theaba.org/pdfs/MOCA_Report_CME_Instructions.pdf',
                    },
                    
                    {text: 'CME Explorer', url: '/cmeExplorer'},
                ],
            },

            {
                component: MyProgressMM,
                title: 'MOCA Minute',
                previewText: (
                    <Text style={this.styles.descriptionText}>
                        MOCA Minute{UnicodeEnums['registered-trademark']} is an interactive learning tool
                        that allows you to
                        {UnicodeEnums['ellipsis'] + '  '}
                    </Text>
                ),
                displayText: (
                    <View>
                        <Text style={this.styles.descriptionText}>
                            MOCA Minute{UnicodeEnums['registered-trademark']} is an interactive learning
                            tool that allows you to continuously assess and maintain your knowledge and
                            demonstrate your proficiency. We encourage you to answer MOCA Minute
                            questions throughout the year to promote continuous learning and knowledge
                            retention. You’ll receive similar questions over time on some of the same
                            topics to gauge whether you have retained your knowledge.{'\n\n'}
                            <Text style={[this.styles.descriptionText, {fontWeight: 'bold'}]}>
                                How MOCA Minute{UnicodeEnums['registered-trademark']} Works​
                                {'\n\n'}
                            </Text>
                        </Text>
                        {this.renderBullets([
                            'Access questions via weekly email reminders, portal account, or mobile app​​​​',
                            'Answer each question in 60 seconds​',
                            'We’ll give you 30 questions each calendar quarter​​',
                            'If you need to catch up, you can answer up to 30 questions per day​',
                            'Answer 120 questions each year by 11:59pm EST on Dec. 31​',
                            'At the end of the year, any unanswered questions will be counted as incorrect​',
                        ])}
                        <Text style={this.styles.descriptionText}>
                            <Text style={{fontWeight: 'bold'}}>How to Meet the Requirement​​</Text>
                            {'\n\n'}
                        </Text>
                        {this.renderBullets([
                            'Answer 120 questions each year by 11:59pm EST on Dec. 31 and maintain an MDT performance value of ≥ 0.10​​​​',
                            'Any questions that you do not answer will be counted as incorrect​',
                        ])}
                        <Text style={this.styles.descriptionText}>
                            <Text style={{fontWeight: 'bold'}}>About the Performance Standard​</Text>
                            {'\n\n'}
                            We use Measurement Decision Theory (MDT) to calculate your MOCA Minute​
                            Performance.
                            {'\n\n'}
                        </Text>
                        {this.renderBullets([
                            "MDT takes into account each question's difficulty and your pattern of responses​",
                            'MDT is not a percent correct or a percentile ranking',
                        ])}
                    </View>
                ),
                quickLinks: [
                    {text: 'Answer MOCA Minute Question', url: '/mocaMinuteLanding'},
                    {text: 'Knowledge Assessment Report', url: '/knowledgeAssessmentReport'},
                    {text: 'My Question History', url: '/questionHistory'},
                    {text: 'Modify Practice Profile', url: '/practiceProfile'},
                    {text: 'Performance Chart', url: '/mocaMinutePerformance'},
                ],
            },

            {
                component: QI_IMP,
                title: 'Quality Improvement',
                previewText: (
                    <Text style={this.styles.descriptionText}>
                        We require our diplomates to participate in quality improvement activities
                        {UnicodeEnums['ellipsis'] + '  '}
                    </Text>
                ),
                displayText: (
                    <View>
                        <Text style={this.styles.descriptionText}>
                            We require our diplomates to participate in quality improvement activities to
                            encourage continuous practice evaluation and improvement.
                            {'\n\n'}
                            <Text style={[this.styles.descriptionText, {fontWeight: 'bold'}]}>
                                How Quality Improvement (QI) Works​​{'\n\r'}
                            </Text>
                        </Text>
                        {this.renderBullets([
                            this.QIBulletPoint,
                            'Choose activities from the approved​ ',
                            'Attestations are subject to audit; we may request documentation during auditing',
                            'Complete a clinical activity attestation in the second-to-last year of your cycle during annual registration. If you are on a 10-year cycle, you will attest during year nine of your certification cycle; if you are on a five-year cycle, you will attest during year four of your certification cycle. You may need to complete more than one clinical activity attestation if you are maintaining multiple certificates.',
                        ])}
                    </View>
                ),
                quickLinks: [
                    {text: 'My QI Activites', url: '/myQiActivities'},
                    {
                        text: 'Approved Activity List',
                        url: 'https://www.theaba.org/wp-content/uploads/2023/01/MOCA_QI_Activities.pdf',
                    },
                ],
            },
        ]
    }

    componentDidMount() {
        this.setState({CallingApi: true})
        ProgressReportLogic.GetUserRoleId().then((userRole) => {
            const showProgressReport = userRole === GeneralEnums.roleEnums.dilomateInMOCA
            this.setState({
                showProgressReport,
                showRoleError: !showProgressReport,
            })
            if (showProgressReport) {
                Promise.all([
                    ProgressReportLogic.getProfessionalStanding(),
                    ProgressReportLogic.getMocaCycle(),
                    ProgressReportLogic.getMocaMinuteDashboardInfo(),
                ])
                    .then((values) => {
                        this.setState({
                            professionalStanding: values[0],
                            mocaStart: values[1].MocaCycleStartDate,
                            mocaEnd: values[1].MocaCycleEndDate,
                            MMDataContainer: values[2],
                        })
                    })
                    .finally(() => {
                        this.setState({CallingApi: false})
                    })
            } else {
                this.setState({CallingApi: false})
            }
        })
    }

    handleResize(event) {
        if (Platform.OS !== 'web') {
            return
        }

        let screenSize = event.nativeEvent.layout.width

        if (screenSize > 800 && this.state.componentWidth === '100%') {
            this.setState({componentWidth: '50%'})
        } else if (screenSize <= 800 && this.state.componentWidth === '50%') {
            this.setState({componentWidth: '100%'})
        }
    }

    expandTextForPrint() {
        componentBeforePrint = [...progressReportContext.state.componentExpanded]
        components = progressReportContext.state.componentExpanded.map(function (component) {
            return true
        })

        progressReportContext.setState({
            componentBeforePrint,
            componentExpanded: components,
        })
    }

    afterPrint() {
        progressReportContext.setState({
            componentExpanded: progressReportContext.state.componentBeforePrint,
        })
    }

    displayDescriptionContent(displayText, previewText, index) {
        let leftPadding = this.state.componentWidth === '50%' ? 60 : 0
        return (
            <View style={{width: this.state.componentWidth, paddingLeft: leftPadding}}>
                {this.state.componentExpanded[index] ? (
                    <View>
                        {displayText}
                        <Text
                            onPress={() => {
                                const headerHandle = findNodeHandle(this.componentRefs[index])
                                const parentHandle = findNodeHandle(this.parentScrollRef)
                                if (Platform.OS !== 'web') {
                                    UIManager.measureLayout(
                                        headerHandle,
                                        parentHandle,
                                        () => {},
                                        (ox, oy) => {
                                            this.parentScrollRef.scrollTo({
                                                x: ox,
                                                y: oy,
                                                animated: false,
                                            })
                                        },
                                    )
                                } else {
                                    headerHandle.scrollIntoView()
                                }
                                let tempExmapnsionArray = this.state.componentExpanded
                                tempExmapnsionArray[index] = !tempExmapnsionArray[index]
                                this.setState({componentExpanded: tempExmapnsionArray})
                            }}
                            style={[
                                this.styles.descriptionText,
                                {color: ColorEnums.danube, paddingTop: 5},
                            ]}
                        >
                            {UnicodeEnums['double-angle-left']}Read Less
                        </Text>
                    </View>
                ) : (
                    <View>
                        {previewText}
                        <Text
                            onPress={() => {
                                let tempExmapnsionArray = this.state.componentExpanded
                                tempExmapnsionArray[index] = !tempExmapnsionArray[index]
                                this.setState({componentExpanded: tempExmapnsionArray})
                            }}
                            style={[
                                this.styles.descriptionText,
                                {color: ColorEnums.danube, paddingTop: 5},
                            ]}
                        >
                            Read More{UnicodeEnums['double-angle-right']}
                        </Text>
                    </View>
                )}
            </View>
        )
    }

    displayQuickLinks(quickLinks) {
        //IE refuses to allocate space accordingly so here height is manually determined
        const qlRows = quickLinks.length / 2 + 1 //# of quick link rows
        const singleLinkHeight = 30 //Height of quick link
        const qlHeaderHeight = 30 //Height of quick link header
        const qlContainerHeight = singleLinkHeight * qlRows + qlHeaderHeight //total height
        return (
            <View style={{minHeight: qlContainerHeight}}>
                <Text
                    style={[
                        StyleFunctions.paragraphText(),
                        {
                            fontSize: 16,
                            color: ColorEnums.blueGray,
                            fontWeight: 'bold',
                            paddingTop: 5,
                            paddingBottom: 10,
                        },
                    ]}
                >
                    QUICK LINKS
                </Text>
                <View style={{flexDirection: 'row', paddingBottom: 15, flex: 1, flexWrap: 'wrap'}}>
                    {quickLinks.map((link, index) => {
                        let rightBorderWeight = index % 2 === 0 ? 2 : 0
                        return (
                            <View
                                key={index}
                                style={{
                                    width: '50%',
                                    borderRightColor: ColorEnums.exLightGray,
                                    borderRightWidth: rightBorderWeight,
                                    paddingLeft: rightBorderWeight === 0 ? 5 : 0,
                                    paddingBottom: 5
                                }}
                            >
                                <Text
                                    onPress={() => {
                                        if (link.url.startsWith('http')) {
                                            HelperMethods.openURL(link.url)
                                        } else {
                                            NavigationHelper.GoToPage(
                                                GeneralEnums.navigationType.push,
                                                link.url,
                                            )
                                        }
                                    }}
                                    style={[StyleFunctions.actionText(), {fontSize: 16}]}
                                >
                                    {link.text}
                                </Text>
                            </View>
                        )
                    })}
                </View>
            </View>
        )
    }
    wrapComponent(Comp, displayText, previewText, titleText, quickLinks, index) {
        const styles = StyleSheet.create({
            certificationComponentTitlebutton: {
                flex: 1,
                backgroundColor: ColorEnums.white,
                paddingVertical: 5,
            },

            certificationComponentTitle: {
                textAlign: 'left',
                paddingLeft: 10,
                fontSize: 15,
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
            },
        })
        return (
            <ABAAccordion
                key={index}
                title={titleText}
                accordionContent={
                    <View
                        style={[
                            this.state.componentWidth === '50%' ? {flexDirection: 'row'} : {},
                            {borderTopColor: ColorEnums.lightGray, borderTopWidth: 1, paddingBottom: 20},
                        ]}
                    >
                        <View style={{width: this.state.componentWidth}}>
                            <Comp
                                overrideWidth={true}
                                useMaxWidth={false}
                                dashboard={false}
                                MMData={titleText === 'MOCA Minute' ? this.state.MMDataContainer : null}
                                location={this.props.location}
                            />
                            {this.displayQuickLinks(quickLinks)}
                        </View>

                        {this.displayDescriptionContent(displayText, previewText, index)}
                    </View>
                }
                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                accordionTitleContainerStyle={[
                    styleFunctions.AccordiontitleContainer(),
                    {paddingBottom: 10},
                ]}
                accordionTitleStyle={styles.certificationComponentTitle}
                accordionButtonStyle={styles.certificationComponentTitlebutton}
                accordionBottomViewStyle={[
                    styleFunctions.AccordionBottomViewStyle(),
                    {paddingVertical: 5},
                ]}
                accordionExpanded={true}
                accordionUnderlayColor={ColorEnums.white}
                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                ref={(comp) => {
                    this.componentRefs[index] = comp
                }}
            />
        )
    }
    render() {
        progressReportContext = this
        navigationContext = this
        ProgressReportLogic.context = progressReportContext
        HelperMethods.setNavigationData(progressReportContext)

        return (
            <NavigationShell
                context={progressReportContext}
                print={true}
                beforePrint={progressReportContext.expandTextForPrint}
                afterPrint={progressReportContext.afterPrint}
            >
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray}}
                    onLayout={(event) => {
                        this.handleResize(event)
                    }}
                    ref={(comp) => {
                        this.parentScrollRef = comp
                    }}
                >
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.ProgressReport} />
                    {this.state.showRoleError && (
                        <Text
                            style={{
                                padding: 20,
                                fontFamily: 'lato',
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: ColorEnums.cloudBurst,
                                textAlign: 'center',
                            }}
                        >
                            Progress Report available for Diplomates in MOCA only.
                        </Text>
                    )}
                    {this.state.showProgressReport && (
                        <>
                            <View style={{alignSelf: 'center'}}>
                                <Text
                                    style={{
                                        color: ColorEnums.blueGray,
                                        fontFamily: 'Lato',
                                        fontSize: 16,
                                    }}
                                >
                                    Cycle runs:{'  '}
                                    {this.state.mocaStart
                                        ? HelperMethods.formatDate(this.state.mocaStart)
                                        : ''}{' '}
                                    -
                                    {this.state.mocaEnd
                                        ? HelperMethods.formatDate(this.state.mocaEnd)
                                        : ''}
                                </Text>
                            </View>
                            <ABAAccordion
                                title={'Certifications'}
                                accordionContent={
                                    <View style={{paddingHorizontal: 10}}>
                                        <Certifications Expanded={true} location={this.props.location} />
                                    </View>
                                }
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styleFunctions.Accordiontitle()}
                                accordionButtonStyle={styleFunctions.containerTitle()}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionIconStyle={{
                                    color: 'white',
                                    fontSize: 12,
                                    marginRight: 'auto',
                                    paddingTop: 3,
                                    paddingRight: 10,
                                }}
                            />
                            <ABAAccordion
                                title={'MOCA 2.0 Requirements'}
                                accordionContent={
                                    <View style={{paddingHorizontal: 10}}>
                                        {!this.state.CallingApi && (
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    right: 20,
                                                    zIndex: 100,
                                                    flexDirection: 'row',
                                                    paddingTop: 13,
                                                    alignItems: 'baseline',
                                                }}
                                            >
                                                {this.state.professionalStanding ===
                                                GeneralEnums.professionalStanding.satisfactiory ? (
                                                    <View style={{paddingLeft: 10, width: 180}}>
                                                        <Text
                                                            style={[
                                                                this.styles.descriptionText,
                                                                {paddingTop: 0, textAlign: 'right'},
                                                            ]}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        GeneralEnums.fontAwesomeFamilyName,
                                                                    color: ColorEnums.surfGreen,
                                                                }}
                                                            >
                                                                {IconEnums.check}{' '}
                                                            </Text>
                                                            Satisfactory
                                                        </Text>
                                                    </View>
                                                ) : (
                                                    <View style={{paddingLeft: 10, width: 180}}>
                                                        <Text
                                                            style={[
                                                                this.styles.descriptionText,
                                                                {paddingTop: 0, textAlign: 'right'},
                                                            ]}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        GeneralEnums.fontAwesomeFamilyName,
                                                                    color: ColorEnums.pomegranate,
                                                                }}
                                                            >
                                                                {IconEnums.exclamation_triangle}{' '}
                                                            </Text>
                                                            Unsatisfactory
                                                        </Text>
                                                    </View>
                                                )}
                                            </View>
                                        )}
                                        {this.componentsToRender().map((comp, index) => {
                                            return this.wrapComponent(
                                                comp.component,
                                                comp.displayText,
                                                comp.previewText,
                                                comp.title,
                                                comp.quickLinks,
                                                index,
                                            )
                                        })}
                                    </View>
                                }
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styleFunctions.Accordiontitle()}
                                accordionButtonStyle={styleFunctions.containerTitle()}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionIconStyle={{
                                    color: 'white',
                                    fontSize: 12,
                                    marginRight: 'auto',
                                    paddingTop: 3,
                                    paddingRight: 10,
                                }}
                            />
                        </>
                    )}
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
