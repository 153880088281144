import ABAPortalAPI from '../../../helpers/AxiosInstance'
import {GeneralEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'
import NavigationHelper from '../../../helpers/NavigationHelper'

const CertificationInformationLogic = {
    context: null,
    dataColumnsWidth: 0.6,

    GetCountryListData: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getCountries')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    resolve(null)
                })
        })
    },

    GetStateListData: function (country) {
        return new Promise((resolve) => {
            ABAPortalAPI.get('lookups/getStates?countryId=' + country)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    resolve(null)
                })
        })
    },

    GetCertificationInformationDisplayData: function () {
        return new Promise((resolve) => {
            CertificationInformationLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('registration/GetCertificationAddressDisplay')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Certificate Information')
                    resolve(null)
                })
                .finally(function (response) {
                    CertificationInformationLogic.context.setState({CallingApi: false})
                })
        })
    },

    CreateCertificationAddress: function () {
        return new Promise((resolve) => {
            CertificationInformationLogic.context.setState({CallingCreateApi: true})
            ABAPortalAPI.post('registration/CreateCertificationAddress', {
                ABA_AddressType: CertificationInformationContext.state.AddressType,
                aba_certificatename: CertificationInformationContext.state.NameOnCertificate,
                Organization: CertificationInformationContext.state.Organization,
                Department: CertificationInformationContext.state.Department,
                AddressLine1: CertificationInformationContext.state.AddressLine1,
                AddressLine2: CertificationInformationContext.state.AddressLine2,
                aba_city: CertificationInformationContext.state.City,
                aba_postalCode: CertificationInformationContext.state.ZipCode,
                aba_countryid: CertificationInformationContext.state.CountrySelected,
                aba_stateid: CertificationInformationContext.state.StateSelected,
                PreventABMSPub: CertificationInformationContext.state.ABMS,
                aba_stateOrProvince: CertificationInformationContext.state.StateOrProvince,
                CR2ID: CertificationInformationContext.props.CR2ID,
                UpdatePrimary: CertificationInformationContext.state.UpdatePrimary,
            })
                .then(function () {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Certificate Information')
                    resolve(false)
                })
                .finally(function () {
                    CertificationInformationLogic.context.setState({CallingCreateApi: false})
                })
        })
    },

    populateStateVaribles: function (data) {
        let AddressType = 1 // Personal
        if (data.Organization || data.Department) {
            AddressType = 2 // Professional
        }
        CertificationInformationContext.setState(
            {
                AddressType,
                NameOnCertificate: data.aba_certificatename || '',
                Organization: data.Organization || '',
                Department: data.Department || '',
                AddressLine1: data.AddressLine1 || '',
                AddressLine2: data.AddressLine2 || '',
                City: data.aba_city || '',
                ZipCode: data.aba_postalCode || '',
                CountrySelected: data.aba_countryid || 'e94dbeb6-99d0-de11-8b2b-001f29d17639',
                StateSelected: data.aba_stateid || 'null',
                ABMS: data.PreventABMSPub || false,
                StateOrProvince: data.aba_stateOrProvince || '',
                orig_CountrySelected: data.aba_countryid || 'e94dbeb6-99d0-de11-8b2b-001f29d17639',
                orig_AddressLine1: data.AddressLine1 || '',
                orig_AddressLine2: data.AddressLine2 || '',
                orig_City: data.aba_city || '',
                orig_ZipCode: data.aba_postalCode || '',
                orig_StateSelected: data.aba_stateid || 'null',
                orig_StateOrProvince: data.aba_stateOrProvince || '',
            },
            () => {
                CertificationInformationLogic.ValidationRequired()
            },
        )
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!CertificationInformationLogic.ValidationRequired()) resolve(false)
            else {
                if (
                    (CertificationInformationLogic.context.state.AddressLine1 !==
                        CertificationInformationLogic.context.state.orig_AddressLine1 ||
                        CertificationInformationLogic.context.state.AddressLine2 !==
                            CertificationInformationLogic.context.state.orig_AddressLine2 ||
                        CertificationInformationLogic.context.state.City !==
                            CertificationInformationLogic.context.state.orig_City ||
                        CertificationInformationLogic.context.state.StateSelected !==
                            CertificationInformationLogic.context.state.orig_StateSelected ||
                        CertificationInformationLogic.context.state.ZipCode !==
                            CertificationInformationLogic.context.state.orig_ZipCode) &&
                    (CertificationInformationLogic.context.state.CountrySelected ===
                        'e94dbeb6-99d0-de11-8b2b-001f29d17639' ||
                        CertificationInformationLogic.context.state.CountrySelected ===
                            '274dbeb6-99d0-de11-8b2b-001f29d17639')
                ) {
                    CertificationInformationLogic.context.setState({ShowStrikeIronScreen: true})
                    resolve(false)
                } else {
                    CertificationInformationLogic.context.setState({ShowStrikeIronScreen: false})
                    const {RegistrationContext} = CertificationInformationContext.props
                    CertificationInformationLogic.CreateCertificationAddress().then((response) => {
                        if (response) {
                            RegistrationContext
                                ? resolve(true)
                                : NavigationHelper.GoToPage(
                                      GeneralEnums.navigationType.push,
                                      '/dashboard',
                                  )
                        }
                        resolve(false)
                    })
                }
            }
        })
    },

    saveAsEntered: function (context) {
        CertificationInformationContext.setState(
            {
                orig_AddressLine1: CertificationInformationContext.state.AddressLine1,
                orig_AddressLine2: CertificationInformationContext.state.AddressLine2,
                orig_City: CertificationInformationContext.state.City,
                orig_ZipCode: CertificationInformationContext.state.ZipCode,
                orig_StateSelected: CertificationInformationContext.state.StateSelected,
                ShowStrikeIronScreen: false,
            },
            () => {
                const {RegistrationContext} = CertificationInformationContext.props
                if (RegistrationContext) {
                    RegistrationContext.GetNextStep()
                } else {
                    CertificationInformationLogic.CreateCertificationAddress().then((response) => {
                        if (response) {
                            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                        }
                        resolve(false)
                    })
                }
            },
        )
    },

    saveAsFound: function (context) {
        CertificationInformationContext.setState(
            {
                AddressLine1: context.state.si_AddressLine1,
                AddressLine2: context.state.si_AddressLine2,
                City: context.state.si_City,
                ZipCode: context.state.si_ZipCode,
                StateSelected: context.state.si_StateSelected,
                orig_AddressLine1: context.state.si_AddressLine1,
                orig_AddressLine2: context.state.si_AddressLine2,
                orig_City: context.state.si_City,
                orig_ZipCode: context.state.si_ZipCode,
                orig_StateSelected: context.state.si_StateSelected,
                ShowStrikeIronScreen: false,
            },
            () => {
                const {RegistrationContext} = CertificationInformationContext.props
                if (RegistrationContext) {
                    RegistrationContext.GetNextStep()
                } else {
                    CertificationInformationLogic.CreateCertificationAddress().then((response) => {
                        if (response) {
                            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
                        }
                        resolve(false)
                    })
                }
            },
        )
    },

    ValidationRequired() {
        const {error, success} = GeneralEnums.textInputStatus
        const {
            AddressLine1,
            AddressType,
            City,
            CountrySelected,
            Department,
            NameOnCertificate,
            Organization,
            StateList,
            StateSelected,
            StateOrProvince,
            ZipCode,
        } = CertificationInformationLogic.context.state
        let [
            AddressLine1Status,
            AddressTypeStatus,
            CityStatus,
            CountrySelectedStatus,
            DepartmentStatus,
            NameOnCertificateStatus,
            OrganizationStatus,
            StateOrProvinceStatus,
            StateSelectedStatus,
            ZipCodeStatus,
        ] = Array(10).fill(success) // if we ever need to edit this array, make sure the parameter inside of Array() is equal to the amount of items we have in it

        let fieldValidation = true
        if (NameOnCertificate == '') {
            NameOnCertificateStatus = error
            fieldValidation = false
        }
        if (CountrySelected == 'null') {
            CountrySelectedStatus = error
            fieldValidation = false
        }
        if (AddressLine1 == '') {
            AddressLine1Status = error
            fieldValidation = false
        }
        if (City == '') {
            CityStatus = error
            fieldValidation = false
        }
        if (ZipCode == '') {
            ZipCodeStatus = error
            fieldValidation = false
        }
        if (StateList.length > 0 && StateSelected == 'null') {
            StateSelectedStatus = error
            fieldValidation = false
        } else if (StateList.length == 0 && StateOrProvince == '') {
            StateOrProvinceStatus = error
            fieldValidation = false
        }
        if (!AddressType || AddressType == 'null') {
            AddressTypeStatus = error
            fieldValidation = false
        }
        // AddressType 2 is a Professional address
        if (AddressType == '2' && !Organization) {
            OrganizationStatus = error
            fieldValidation = false
        }
        if (AddressType == '2' && !Department) {
            DepartmentStatus = error
            fieldValidation = false
        }

        CertificationInformationContext.setState({
            AddressLine1Status,
            AddressTypeStatus,
            CityStatus,
            CountrySelectedStatus,
            DepartmentStatus,
            NameOnCertificateStatus,
            OrganizationStatus,
            StateOrProvinceStatus,
            StateSelectedStatus,
            ZipCodeStatus,
        })
        return fieldValidation
    },

    CheckRequiredText: function (field, value) {
        var status = GeneralEnums.textInputStatus.success
        if (value == '') status = GeneralEnums.textInputStatus.error
        CertificationInformationLogic.context.setState({
            [field + 'Status']: status,
            [field]: value,
        })
    },

    CheckRequiredDropdown: function (field, value) {
        var status = GeneralEnums.textInputStatus.success
        if (value == 'null') {
            status = GeneralEnums.textInputStatus.error
        }
        CertificationInformationLogic.context.setState({
            [field + 'Status']: status,
            [field]: value,
        })
    },

    countryChange: function (field, value) {
        CertificationInformationLogic.context.setState({StateSelected: 'null', StateOrProvince: ''})
        if (!value || value == 'null') {
            return
        }
        CertificationInformationLogic.CheckRequiredDropdown(field, value)
        CertificationInformationLogic.callGetStates(value)
    },

    callGetStates: function (country) {
        CertificationInformationLogic.GetStateListData(country).then((responseData) => {
            CertificationInformationContext.setState({
                StateList: responseData,
            })
            CertificationInformationLogic.ValidationRequired()
        })
    },
}
export default CertificationInformationLogic
