import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'
import {GeneralEnums} from '../../../helpers/generalEnums'

const PracticeAreaAndSettingLogic = {
    context: null,

    GetPracticeAreaProfileLocations: function () {
        return new Promise((resolve) => {
            PracticeAreaAndSettingLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('registration/GetPracticeAreaProfileLocations')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Practice Area Profile Location',
                    )
                    resolve(null)
                })
                .finally(function () {
                    PracticeAreaAndSettingLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetPracticeAreaProfile: function () {
        return new Promise((resolve) => {
            PracticeAreaAndSettingLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('registration/GetPracticeAreaProfile')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Practice Area Profile Information',
                    )
                    resolve(null)
                })
                .finally(function () {
                    PracticeAreaAndSettingLogic.context.setState({CallingApi: false})
                })
        })
    },

    CreatePracticeAreaProfile: function () {
        return new Promise((resolve) => {
            let otherLocation = PracticeAreaAndSettingLogic.context.state.OtherLocation

            //if we have text in otherLocation but the Practice Location is not 'Other', we are setting it to null.
            //scenarios this would happen are if a user selects 'Other' as their Location, fills out the 'Other' text field, but then changes the Location dropdown to something else.
            if (
                otherLocation &&
                PracticeAreaAndSettingLogic.context.state.PracticeLocation !=
                    PracticeAreaAndSettingLogic.context.state.OtherLocationValue
            ) {
                otherLocation = null
            }

            PracticeAreaAndSettingLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('registration/CreatePracticeAreaProfile', {
                AmbulatoryOutpatient: PracticeAreaAndSettingLogic.context.state.Ambulatory,
                Cardiac: PracticeAreaAndSettingLogic.context.state.CardiacAnesthesia,
                CriticalCareMedicine: PracticeAreaAndSettingLogic.context.state.CriticalCareMedicine,
                GeneralOperativeAnesthesia:
                    PracticeAreaAndSettingLogic.context.state.GeneralOperativeAnesthesia,
                Neuro: PracticeAreaAndSettingLogic.context.state.NeuroAnesthesia,
                Obstetric: PracticeAreaAndSettingLogic.context.state.ObstetricAnesthesia,
                Pain: PracticeAreaAndSettingLogic.context.state.PainMedicine,
                Pediatric: PracticeAreaAndSettingLogic.context.state.PediatricAnesthesia,
                RegionalAcute: PracticeAreaAndSettingLogic.context.state.RegionalAnesthesia,
                Thoracic: PracticeAreaAndSettingLogic.context.state.ThoracicAnesthesia,
                Trauma: PracticeAreaAndSettingLogic.context.state.Trauma,
                Location: PracticeAreaAndSettingLogic.context.state.PracticeLocation,
                OtherLocation: otherLocation,
                CR2ID: PracticeAreaAndSettingLogic.context.props.CR2ID,
                Order: PracticeAreaAndSettingLogic.context.props.Order || 0,
            })
                .then(function (response) {
                    resolve(true)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Practice Area Profile Information',
                    )
                    resolve(false)
                })
                .finally(function () {
                    PracticeAreaAndSettingLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateStateVaribles(data) {
        PracticeAreaAndSettingLogic.context.setState({
            Ambulatory: data.AmbulatoryOutpatient,
            CardiacAnesthesia: data.Cardiac,
            CriticalCareMedicine: data.CriticalCareMedicine,
            GeneralOperativeAnesthesia: data.GeneralOperativeAnesthesia,
            NeuroAnesthesia: data.Neuro,
            ObstetricAnesthesia: data.Obstetric,
            PainMedicine: data.Pain,
            PediatricAnesthesia: data.Pediatric,
            RegionalAnesthesia: data.RegionalAcute,
            ThoracicAnesthesia: data.Thoracic,
            Trauma: data.Trauma,
            PracticeLocation: data.Location,
            OtherLocation: data.OtherLocation,
        })
    },

    SaveLogic() {
        let errors = {}
        let calculatedTotal =
            parseInt(PracticeAreaAndSettingLogic.context.state.Ambulatory) +
            parseInt(PracticeAreaAndSettingLogic.context.state.CardiacAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.CriticalCareMedicine) +
            parseInt(PracticeAreaAndSettingLogic.context.state.GeneralOperativeAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.NeuroAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.ObstetricAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.PainMedicine) +
            parseInt(PracticeAreaAndSettingLogic.context.state.PediatricAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.RegionalAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.ThoracicAnesthesia) +
            parseInt(PracticeAreaAndSettingLogic.context.state.Trauma)
        let changeInputStatus = (statusState) => {
            //If we don't already have our InputStatus object in our errors object, we need to set it.
            //If not, we will just add our key and value to the already existing InputStatus.
            if (!errors.InputStatus) {
                errors.InputStatus = {}
            }

            errors.InputStatus[statusState] = GeneralEnums.textInputStatus.error
        }

        if (calculatedTotal !== 100) {
            errors.SubmittedTotalNotValid = true
        }

        //validating if our Practice Location dropdown has an option selected
        if (
            !PracticeAreaAndSettingLogic.context.state.PracticeLocation ||
            PracticeAreaAndSettingLogic.context.state.PracticeLocation === 'null'
        ) {
            changeInputStatus('PracticeLocation')
        }

        //validating if user has chosen 'Other' as a Practice Location and that they have filled out the 'Other' input field.
        if (
            PracticeAreaAndSettingLogic.context.state.PracticeLocation ==
                PracticeAreaAndSettingLogic.context.state.OtherLocationValue &&
            (!PracticeAreaAndSettingLogic.context.state.OtherLocation ||
                PracticeAreaAndSettingLogic.context.state.OtherLocation === 'null')
        ) {
            changeInputStatus('OtherLocation')
        }

        // if our errors object has anything in it, then we are going to set state and resolve the promise with false.  If not, then we will create our practice area profile.
        return new Promise((resolve) => {
            if (Object.keys(errors).length !== 0) {
                PracticeAreaAndSettingLogic.context.setState(errors)
                resolve(false)
            } else {
                PracticeAreaAndSettingLogic.CreatePracticeAreaProfile().then((response) => {
                    if (response) {
                        resolve(true)
                    }
                    resolve(false)
                })
            }
        })
    },
}

export default PracticeAreaAndSettingLogic
