import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    ABAID: null,
    authToken: null,
    inactivityTimer: false,
    allowUpdateToken: true,
    logoutTimer: 0,
    interceptor: null,
}

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetAuthToken:
            return {...state, authToken: action.payload}
        case ActionTypes.SetABAID:
            return {...state, ABAID: action.payload}
        case ActionTypes.ClearSettings:
            return {...state, ...initialState, logoutTimer: state.logoutTimer}
        default:
            return state
    }
}

export default settings
