import React from 'react'
import {Text, View, TouchableOpacity, StyleSheet, Platform} from 'react-native'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import HelperMethods from '../../helpers/HelperMethods'

const linkStyles = StyleSheet.create({
    enabled: {
        color: ColorEnums.danube,
        fontSize: 16,
        textAlign: 'center',
    },
    disabled: {
        color: ColorEnums.lightGray,
        fontSize: 16,
        textAlign: 'center',
    },
    divider: {
        borderLeftWidth: 2,
        borderLeftColor: ColorEnums.aquaHaze,
    },
})

const getLinkParameters = (link) => {
    const isVisible = !!link.Text

    if (!isVisible) {
        return [isVisible, null, null, null]
    }
    const {Enabled} = link
    const onPress =
        Enabled && !link.Link.includes('http')
            ? () => {
                  NavigationHelper.GoToPage(GeneralEnums.navigationType.push, link.Link)
              }
            : link.Link.includes('http')
            ? () => {
                  HelperMethods.openURL(link.Link)
              }
            : null

    const displayComponent = Enabled ? TouchableOpacity : View
    const textStyle = Enabled ? linkStyles.enabled : linkStyles.disabled

    return [isVisible, onPress, displayComponent, textStyle]
}

export default MyProgressLinks = ({
    LeftLink = {Icon: null, Text: null, Link: null, Enabled: true},
    RightLink = {Icon: null, Text: null, Link: null, Enabled: true},

    CenteredLink = false, // added prop to center a single link being displayed
}) => {
    const [leftIsVisible, leftOnPress, LeftDisplayConponent, leftTextStyle] = getLinkParameters(LeftLink)
    const [rightIsVisible, rightOnPress, RightDisplayConponent, rightTextStyle] =
        getLinkParameters(RightLink)
    const showDivider = leftIsVisible || rightIsVisible

    return (
        <View style={{flexDirection: 'row'}}>
            {leftIsVisible && (
                <View
                    style={[
                        {
                            width: CenteredLink ? '100%' : '50%',
                            justifyContent: 'center',
                        },
                    ]}
                >
                    <LeftDisplayConponent onPress={leftOnPress}>
                        <Text style={leftTextStyle}>
                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>
                                {IconEnums[LeftLink.Icon]}
                            </Text>{' '}
                            {LeftLink.Text}
                        </Text>
                    </LeftDisplayConponent>
                </View>
            )}
            {showDivider && <View style={linkStyles.divider} />}
            {rightIsVisible && (
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        paddingHorizontal: 4,
                    }}
                >
                    <RightDisplayConponent onPress={rightOnPress}>
                        <Text style={rightTextStyle}>
                            <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>
                                {IconEnums[RightLink.Icon]}
                            </Text>{' '}
                            {RightLink.Text}
                        </Text>
                    </RightDisplayConponent>
                </View>
            )}
        </View>
    )
}
