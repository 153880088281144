import NavigationHelper from "../../helpers/NavigationHelper";

const PracticeProfileLogic = {
    context: null,
    Submit: function() {
        PracticeProfileLogic.context.child.Save()
            .then(success => {
                if (success)  {   
                    NavigationHelper.navigateBack();
                }
            }) 
    },
    GoBack: function() {
        NavigationHelper.navigateBack();
    }
}

export default PracticeProfileLogic;