import React, {Component} from 'react'
import {View, Text, ActivityIndicator, Platform} from 'react-native'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import ABACheckbox from '../../../ABACustom/presentation/ABACheckbox'
import acaPathwayLogic from '../../clinicalactivity/logic/acaPathwayPageLogic'
import HelperMethods from '../../../../helpers/HelperMethods'
import {ColorEnums} from '../../../../helpers/generalEnums'
import styleFunctions from '../../../../styles/styleFunctions'
import ACAExamPath2Logic from '../logic/acaExamPath2Logic'
import ACAPathwayPage from '../../clinicalactivity/presentation/acaPathwayPage'

export default class ACAExamPath2 extends Component {
    state = {
        CallingApi: false,
        error: false,
        nbeCertOptions: [],
        nbeCert: null,
        clinicalActivityId: null,
        acaPathway: null,
    }
    componentDidMount() {
        const {GetACANBECerts, GetClinicalActivityData} = acaPathwayLogic
        Promise.all([GetACANBECerts(), GetClinicalActivityData()]).then((data) => {
            this.setState({
                nbeCertOptions: data[0],
                clinicalActivityId: data[1].cert_clinicalactivityid,
                acaPathway: data[1].cert_acapath,
                nbeCert: data[1].cert_acanbecert,
            })
        })
    }

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({
                CurrentComponentStep: ACAPathwayPage,
            })
            resolve(false)
        })
    }

    Save() {
        return ACAExamPath2Logic.SaveLogic()
    }

    renderNBECertOptions() {
        const {nbeCert} = this.state

        return this.state.nbeCertOptions.map((option, index) => {
            const {value, label} = option
            if (value != 1 && value != 2) {
                return
            }

            return (
                <View
                    style={{
                        marginBottom: 15,
                        marginRight: Platform.OS === 'android' && index === 0 ? 25 : 0,
                    }}
                    key={value}
                >
                    <ABACheckbox
                        checkboxStyle={{
                            width: 15,
                            height: 15,
                            marginRight: 10,
                        }}
                        isChecked={nbeCert === value}
                        label={label}
                        onChangeEvent={() => {
                            this.setState({
                                nbeCert: nbeCert === value ? null : value,
                                error: null,
                            })
                        }}
                        width="100%"
                    />
                </View>
            )
        })
    }

    render() {
        acaPathwayLogic.context = this
        ACAExamPath2Logic.context = this
        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 20,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                width: '90%',
                            },
                        ]}
                    >
                        Adult Cardiac Anesthesiology Pathway
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 16,
                                paddingTop: 15,
                                width: '100%',
                                marginBottom: 15,
                            },
                        ]}
                    >
                        Please select your Adult Cardiac Anesthesiology entrance pathway:
                        <ABARequiredAsterisk />
                    </Text>
                    {this.state.error && (
                        <Text style={{color: ColorEnums.pomegranate, paddingBottom: 5}}>
                            Please select a pathway
                        </Text>
                    )}
                    <View>{this.renderNBECertOptions()}</View>
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
