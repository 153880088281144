import React, {Component} from 'react'
import {View, Text, TouchableOpacity} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ViewExamResultsLogic from '../logic/viewExamResultsLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import styleFunctions from '../../styles/styleFunctions'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import PrintedFooter from '../../components/presentation/printedFooter'

export default class ViewExamResults extends Component {
    state = {
        results: [],
        CallingApi: false,
        ShowNote: [],
    }

    componentDidMount() {
        ViewExamResultsLogic.getExamResults().then((responsedata) => {
            examResultsContext.setState({results: responsedata})
            examResultsContext.setShowNote(responsedata)
        })

        ViewExamResultsLogic.getExamsViewed()
    }

    setShowNote(responsedata) {
        var showNotesArray = []
        for (let index = 0; index < responsedata.length; index++) {
            if (responsedata[index].ExamMessage == '' || responsedata[index].ExamMessage == null) {
                showNotesArray[index] = false
            } else {
                showNotesArray[index] = true
            }
        }
        examResultsContext.setState({ShowNote: showNotesArray})
    }

    toggle(index) {
        var showNotesArray = examResultsContext.state.ShowNote
        showNotesArray[index] = !showNotesArray[index]
        examResultsContext.setState({ShowNote: showNotesArray})
    }

    render() {
        examResultsContext = this
        ViewExamResultsLogic.context = this
        HelperMethods.setNavigationData(examResultsContext)

        return (
            <NavigationShell context={examResultsContext} print={true}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.ExamInformation} />
                    <ABAContainer
                        activityText={'Loading Exam Results'}
                        containerTitle={'EXAM RESULTS'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View style={{flexDirection: 'row', paddingBottom: 15, width: '100%'}}>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '60%', fontFamily: 'Lato', fontSize: 14},
                                ]}
                            >
                                Name
                            </Text>
                            <Text
                                style={[
                                    styleFunctions.columnHeader(),
                                    {width: '40%', fontFamily: 'Lato', fontSize: 14, minWidth: 100},
                                ]}
                            >
                                Results
                            </Text>
                        </View>
                        {examResultsContext.state.results.map((result, index) => {
                            var iconColor
                            switch (result.ExamIcon) {
                                case 'ban':
                                    iconColor = ColorEnums.citrus
                                    break
                                case 'check':
                                    iconColor = ColorEnums.lime
                                    break
                                case 'exclamation-circle':
                                    iconColor = ColorEnums.citrus
                                    break
                                case 'minus-circle':
                                    iconColor = ColorEnums.lightGray
                                    break
                                case 'times':
                                    iconColor = ColorEnums.pomegranate
                                    break
                                default:
                                    iconColor = ColorEnums.danube
                                    break
                            }

                            const showExamIcon = result.exam_DisplayResult
                            return (
                                <View
                                    key={'item' + index}
                                    style={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.backgroundGray,
                                        width: '100%',
                                    }}
                                >
                                    <TouchableOpacity
                                        style={{
                                            paddingBottom: 15,
                                            paddingTop: 10,
                                            width: '100%',
                                        }}
                                        onPress={() => {
                                            this.toggle(index)
                                        }}
                                        testID={'examResult' + result.SessionName}
                                    >
                                        <View style={{flexDirection: 'row'}}>
                                            <View style={{width: '60%'}}>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {
                                                            fontSize: 14,
                                                            paddingRight: 5,
                                                        },
                                                    ]}
                                                >
                                                    {result.SessionName}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    width: '40%',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                        color: iconColor,
                                                        fontSize: 14,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {showExamIcon && IconEnums[result.ExamIcon]}
                                                </Text>
                                                <Text
                                                    style={[
                                                        styleFunctions.paragraphText(),
                                                        {
                                                            fontSize: 14,
                                                            paddingLeft: 10,
                                                        },
                                                    ]}
                                                >
                                                    {result.ExamStatus
                                                        ? result.ExamStatus
                                                        : result.RegistrationStatus}
                                                </Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                    {examResultsContext.state.ShowNote[index] && (
                                        <View
                                            style={{
                                                paddingBottom: 15,
                                                paddingTop: 10,
                                                width: '100%',
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    styleFunctions.columnHeader(),
                                                    {
                                                        fontFamily: 'Lato',
                                                        fontSize: 14,
                                                        width: '100%',
                                                    },
                                                ]}
                                            >
                                                Note
                                            </Text>
                                            {ViewExamResultsLogic.fillInBoldText(
                                                result.ExamMessage,
                                                result,
                                            )}
                                        </View>
                                    )}
                                </View>
                            )
                        })}
                        <View style={{alignSelf: 'center', paddingTop: 15}}>
                            <ABATouchableHighlight
                                buttonStyle={styleFunctions.buttonClick()}
                                onPressEvent={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        '/dashboard',
                                        {AuthenticationToken: this.navigationData.AuthenticationToken},
                                    )
                                }}
                                text={'Return to Dashboard'}
                                textStyle={{color: ColorEnums.white, fontSize: 16}}
                                underlayColor={ColorEnums.white}
                            />
                        </View>
                    </ABAContainer>
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
