import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import HelperMethods from '../../../../helpers/HelperMethods'
import ValidationHelper from '../../../../helpers/ValidationHelper'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import SubstanceAbuseStep1 from '../presentation/substanceAbuseStep1'
import SubstanceAbuseStep3 from '../presentation/substanceAbuseStep3'

const SubstanceAbuseLogicStep2 = {
    context: null,

    GetSubstanceAbuseData() {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }
            this.context.setState({CallingApi: true})
            ABAPortalAPI.get(`substanceabuseinfo/getSAData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    GetSubstanceAbuseSubstancesData(saving) {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    SubType: GeneralEnums.SASubstanceTypes.Substance,
                },
            }
            SubstanceAbuseLogicStep2.context.setState({CallingApi: true})

            if (saving) {
                SubstanceAbuseLogicStep2.context.setState({callingApiText: 'Saving Substance'})
            }

            ABAPortalAPI.get(`substanceabuseinfo/getSubstanceAbuseSubstancesData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Accessing Substance Abuse Substances Record',
                    )
                })
                .finally(() => {
                    SubstanceAbuseLogicStep2.context.setState({
                        CallingApi: false,
                        callingApiText: '',
                    })
                })
        })
    },

    AddSubstanceAbuseSubstance() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
                cert_SAsubstanceId,
            } = this.context.state
            ABAPortalAPI.post('substanceabuseinfo/updateSubstanceAbuseSubstance', {
                cert_substanceabuseid: substanceAbuseContext.state.SubstanceAbuseID,
                cert_Type: GeneralEnums.SASubstanceTypes.Substance,
                cert_SAsubstanceId,
                cert_SubstanceBeganDate,
                cert_name,
                cert_LengthofTime,
                cert_AvenueofAdministration,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Adding Substance Abused')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    AddSubstanceToGrid() {
        if (this.ValidateSubstance()) {
            this.AddSubstanceAbuseSubstance().then(() => {
                this.GetSubstanceAbuseSubstancesData().then((substancesAbusedIDList) => {
                    const {gridIsShowing, InputStatus} = this.context.state
                    for (const key of Object.keys(InputStatus)) {
                        InputStatus[key] = GeneralEnums.textInputStatus.default
                    }

                    this.context.setState({
                        substancesAbusedIDList,
                        cert_SAsubstanceId: '',
                        cert_SubstanceBeganDate: '',
                        cert_name: '',
                        cert_LengthofTime: '',
                        cert_AvenueofAdministration: '',
                        gridIsShowing: !gridIsShowing,
                        substancesMissing: false,
                        InputStatus,
                    })
                    registrationStepsDisplayContext.setState({
                        ShowContinue: true,
                    })
                })
            })
        }
    },

    ShowAddSubstanceFields() {
        this.context.setState({
            gridIsShowing: false,
        })

        registrationStepsDisplayContext.setState({
            ShowContinue: false,
        })
    },

    UpdateGrid() {
        SubstanceAbuseLogicStep2.GetSubstanceAbuseSubstancesData(true).then((substancesAbusedIDList) => {
            SubstanceAbuseLogicStep2.context.setState({
                substancesAbusedIDList,
            })
        })
    },

    CancelAddSubstance() {
        this.context.setState({
            cert_SAsubstanceId: '',
            cert_SubstanceBeganDate: '',
            cert_name: '',
            cert_LengthofTime: '',
            cert_AvenueofAdministration: '',
            gridIsShowing: true,
        })
        registrationStepsDisplayContext.setState({
            ShowContinue: true,
        })
    },

    PopulateVariables(SAData) {
        const [SubstanceAbuseData, substancesAbusedIDList] = SAData
        if (SubstanceAbuseData && substancesAbusedIDList) {
            const cert_lastepisode =
                SubstanceAbuseData.cert_lastepisode &&
                moment(new Date(SubstanceAbuseData.cert_lastepisode)).format('MM/DD/YYYY')
            this.context.setState({
                cert_lastepisode: cert_lastepisode || '',
                substancesAbusedIDList: substancesAbusedIDList || [],
            })
        }
    },

    ValidatePage() {
        let validated = true
        const {substancesAbusedIDList, InputStatus, cert_lastepisode} = this.context.state
        const {error, success} = GeneralEnums.textInputStatus

        InputStatus.cert_lastepisode = ValidationHelper.isDateFormatValid(cert_lastepisode)
            ? success
            : error
        validated = InputStatus.cert_lastepisode !== error

        this.context.setState({InputStatus})

        if (substancesAbusedIDList.length < 1) {
            this.context.setState({
                substancesMissing: true,
            })
            validated = false
        }

        return validated
    },

    ValidateSubstance() {
        const {
            InputStatus,
            cert_lastepisode,
            cert_SubstanceBeganDate,
            cert_name,
            cert_LengthofTime,
            cert_AvenueofAdministration,
        } = this.context.state
        const {error, success} = GeneralEnums.textInputStatus
        const cert_lastepisodeDate = moment(new Date(cert_lastepisode)).format('MM/DD/YYYY')
        const validSubstanceAbuseDate = moment(cert_SubstanceBeganDate, 'MM/DD/YYYY').isSameOrBefore(
            cert_lastepisodeDate,
            'day',
        )
        const {isDateFormatValid} = ValidationHelper

        HelperMethods.clearValidation(InputStatus)

        if (!isDateFormatValid(cert_lastepisode)) {
            InputStatus.cert_lastepisode = error
        } else {
            InputStatus.cert_lastepisode = success
        }

        if (!isDateFormatValid(cert_SubstanceBeganDate)) {
            InputStatus.cert_SubstanceBeganDate = error
            this.context.setState({substanceBeganDateError: false})
        } else if (!cert_lastepisode || !validSubstanceAbuseDate) {
            InputStatus.cert_SubstanceBeganDate = error
            this.context.setState({substanceBeganDateError: true})
        } else {
            InputStatus.cert_SubstanceBeganDate = success
        }

        InputStatus.cert_name = cert_name ? success : error
        InputStatus.cert_LengthofTime = cert_LengthofTime ? success : error
        InputStatus.cert_AvenueofAdministration = cert_AvenueofAdministration ? success : error

        const validated = !Object.keys(InputStatus).some((input) => InputStatus[input] === 3)
        this.context.setState({InputStatus})
        return validated
    },

    UpdateSubstanceAbuseStep2() {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            const {cert_lastepisode} = this.context.state
            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep2`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
                cert_lastepisode,
                rs_statuscode: substanceAbuseContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateSubstanceAbuseStep2().then(() => {
                substanceAbuseContext.setState({
                    CurrentComponentStep: SubstanceAbuseStep3,
                })
            })
            resolve(false)
        })
    },

    Previous() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep1,
            })
            resolve(false)
        })
    },
}

export default SubstanceAbuseLogicStep2
