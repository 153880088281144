import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import ValidationHelper from '../../helpers/ValidationHelper'

const ForgotPasswordLogic = {
    context: null,

    goToConfirmIdentity() {
        let validationPassed = ForgotPasswordLogic.validateFieldData()

        if (!validationPassed) {
            return
        }

        ForgotPasswordLogic.getContactInfoForAccountValidation()
    },

    goToSetPassword(contactId) {
        if (!ForgotPasswordLogic.verifyLastNameMatches()) {
            return
        }

        NavigationHelper.GoToPage(GeneralEnums.navigationType.replace, '/forgotPasswordSetPassword', {
            ContactId: contactId,
        })
    },

    goToValidateIdentity() {
        navigationContext.props.history.replace('/forgotPasswordValidate')
    },

    getContactInfoForAccountValidation: function () {
        ForgotPasswordLogic.context.setState({CallingApi: true})

        ABAPortalAPI.post('authentication/getContactInfoForAccountValidation', {
            AbaId: ForgotPasswordLogic.context.state.abaId,
            DateOfBirth: ForgotPasswordLogic.context.state.dateOfBirth,
            LastName: ForgotPasswordLogic.context.state.lastName,
            Ssn: ForgotPasswordLogic.context.state.ssnLastFour,
        })
            .then(function (response) {
                ForgotPasswordLogic.context.setState({CallingApi: false})

                if (!response.data) {
                    HelperMethods.alertAgnostic(
                        'The information you provided does not identify you as an ABA Diplomate, Candidate or Applicant with a current ABA account, or an anesthesiology resident who may create an ABA account. Please re-enter your information.\n\nIf you have any questions, please contact the ABA Customer Service Center at (866) 999-7501.',
                        'Account Not Found',
                        [{text: 'Ok'}],
                    )
                } else if (response.data.LoginExists === false) {
                    HelperMethods.alertAgnostic(
                        'Your account has not been created. You will now be redirected to the Create Account page.',
                        'Account Does Not Exist',
                        [{text: 'Ok'}],
                    )
                    NavigationHelper.GoToPage(
                        GeneralEnums.navigationType.replace,
                        '/createAccountVerify',
                        {
                            ContactFullName: response.data.ContactFullName,
                            ContactLastName: response.data.ContactLastName,
                            ContactId: response.data.ContactId,
                        },
                    )
                } else {
                    NavigationHelper.GoToPage(
                        GeneralEnums.navigationType.replace,
                        '/forgotPasswordVerify',
                        {
                            ContactFullName: response.data.ContactFullName,
                            ContactLastName: response.data.ContactLastName,
                            ContactId: response.data.ContactId,
                        },
                    )
                }
            })
            .catch(function (error) {
                ForgotPasswordLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Error Verifying Information')
            })
    },

    setPassword: function (newPassword, newPasswordConfirm) {
        if (newPassword != newPasswordConfirm) {
            HelperMethods.alertAgnostic('Your passwords do not match.', 'Your passwords do not match.', [
                {text: 'Ok'},
            ])

            return
        }

        if (!ValidationHelper.isPasswordFormatValid(newPassword)) {
            HelperMethods.alertAgnostic(
                'Your password does not meet the specified requirements.',
                'Your password does not meet the specified requirements.',
                [{text: 'Ok'}],
            )

            return
        }

        ForgotPasswordLogic.context.setState({CallingApi: true})

        ABAPortalAPI.post('authentication/setPassword', {
            ContactId: ForgotPasswordLogic.context.state.ContactId,
            NewPassword: newPassword,
        })
            .then(function (response) {
                ForgotPasswordLogic.context.setState({CallingApi: false})

                HelperMethods.alertAgnostic(
                    'Your new password has been successfully set. Please log in to continue.',
                    'Password Set Successfully',
                    [{text: 'Ok'}],
                )

                navigationContext.props.history.replace('/')
            })
            .catch(function (error) {
                ForgotPasswordLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Error Setting Password')
            })
    },

    validateFieldData: function () {
        const FIELDS_REQUIRED_COUNT = 3

        //var names for our state variables
        let abaId = ForgotPasswordLogic.context.state.abaId
        let dateOfBirth = ForgotPasswordLogic.context.state.dateOfBirth
        let lastName = ForgotPasswordLogic.context.state.lastName
        let ssnLastFour = ForgotPasswordLogic.context.state.ssnLastFour

        //Since err messages for SSN and DOB can vary, we're just setting them to an empty string here
        let errMsgSsn = ''
        let errMsgDateOfBirth = ''

        //creating default input statuses before we check each of our inputs
        let inputStatus = {
            ssn: GeneralEnums.textInputStatus.default,
            abaId: GeneralEnums.textInputStatus.default,
            dateOfBirth: GeneralEnums.textInputStatus.default,
            lastName: GeneralEnums.textInputStatus.default,
        }

        let validationPassed = true
        let errorMessage = ''
        let allFieldsExceptSsn = [abaId, dateOfBirth, lastName]
        let populatedFieldsCount = 0

        if (ssnLastFour && ssnLastFour.length > 0) {
            populatedFieldsCount++
        }

        for (let counter = 0; counter < allFieldsExceptSsn.length; counter++) {
            if (allFieldsExceptSsn[counter] && allFieldsExceptSsn[counter].length > 0) {
                populatedFieldsCount++
            }
        }

        if (populatedFieldsCount < FIELDS_REQUIRED_COUNT) {
            errorMessage +=
                'Please enter the last four digits of your social security number and complete two additional fields.\n'
        }

        if (!ssnLastFour) {
            inputStatus.ssn = GeneralEnums.textInputStatus.error
            errMsgSsn = 'Last 4 of your social security number is required'
        }

        if (abaId && !ValidationHelper.isAbaIdFormatValid(abaId)) {
            inputStatus.abaId = GeneralEnums.textInputStatus.error
        }

        if (dateOfBirth && dateOfBirth.length < 10) {
            inputStatus.dateOfBirth = GeneralEnums.textInputStatus.error
            errMsgDateOfBirth = 'Invalid date format'
        } else if (dateOfBirth && !ValidationHelper.isDateOfBirthFormatValid(dateOfBirth)) {
            inputStatus.dateOfBirth = GeneralEnums.textInputStatus.error
            errMsgDateOfBirth = 'Invalid date'
        }

        if (ssnLastFour && !ValidationHelper.isSSNFormatValid(ssnLastFour)) {
            inputStatus.ssn = GeneralEnums.textInputStatus.error
            errMsgSsn = 'Invalid SSN format'
        }

        for (let key in inputStatus) {
            if (inputStatus[key] === GeneralEnums.textInputStatus.error) {
                validationPassed = false
                break
            }
        }

        if (errorMessage) {
            HelperMethods.alertAgnostic(errorMessage, 'Invalid Format', [{text: 'Ok'}])

            validationPassed = false
        }

        ForgotPasswordLogic.context.setState({
            errMsgSsn: errMsgSsn,
            errMsgDateOfBirth,
            errMsgDateOfBirth,
            inputStatusAbaId: inputStatus.abaId,
            inputStatusSsn: inputStatus.ssn,
            inputStatusDateOfBirth: inputStatus.dateOfBirth,
            inputStatusLastName: inputStatus.lastName,
        })

        return validationPassed
    },

    verifyLastNameMatches: function () {
        if (
            !ForgotPasswordLogic.context.state.LastNameConfirmationEntry ||
            !ForgotPasswordLogic.context.navigationData.ContactLastName ||
            ForgotPasswordLogic.context.state.LastNameConfirmationEntry.toLowerCase().trim() !==
                ForgotPasswordLogic.context.navigationData.ContactLastName.toLowerCase().trim()
        ) {
            HelperMethods.alertAgnostic(
                'The last name you entered does not match the last name displayed above.',
                'Last Name Mismatch',
                [{text: 'Ok'}],
            )
            return false
        }

        return true
    },
}

export default ForgotPasswordLogic
