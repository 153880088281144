import React, {Component} from 'react'
import {Text, View, ActivityIndicator} from 'react-native'

import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'
import StyleFunctions from '../../styles/styleFunctions'
import RegistrationLogic from '../../components/logic/registrationLogic'
import HelperMethods from '../../helpers/HelperMethods'
import {connect} from 'react-redux'

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
    }
}

class MocaMinuteInformation extends Component {
    state = {
        ButtonText: '',
        ButtonDisabled: false,
        CallingApi: false,
        HeaderText: null,
        MMStatusScenario: null,
        SubheaderText: null,
        NumberofCerts: null,
        MocaMinuteData: null,
    }

    componentDidMount() {
        this.setState({MocaMinuteData: this.props.MMData})
        this.displayDashboardInfo(this.props.MMData)

        RegistrationLogic.GetNumberOfCertifications().then((numberOfCerts) => {
            this.setState({NumberofCerts: numberOfCerts})
        })
    }

    componentDidUpdate(previousProps) {
        if (this.props.MMData != previousProps.MMData) {
            this.setState({MocaMinuteData: this.props.MMData})
            this.displayDashboardInfo(this.props.MMData)
        }
    }

    setNavigationUrl() {
        const {MMStatusScenario, MocaRegistrationStatus, MDTValue} = this.props.MMData
        const {MocaCR2: re_rulesetstatusId, PaymentOffline} = this.props
        const {NumberofCerts} = this.state
        const isNTL =
            MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredNTL &&
            NumberofCerts === 0
        const isNTLRegistrationStatus =
            MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredForMOCA ||
            MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredNTL ||
            MocaRegistrationStatus === GeneralEnums.MocaRegistrationStatus.NotRegisteredFirstTime

        if (isNTLRegistrationStatus) {
            if (PaymentOffline) {
                const paymentDownText = `Our payment processing service is offline now for system maintenance. We'll be back online as soon as possible.`
                HelperMethods.alertAgnostic(paymentDownText, 'Registration Error', [{text: 'Ok'}])
                return
            }
            this.setState({ButtonDisabled: true})
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/registrationStepsDisplay', {
                re_rulesetstatusId,
                isNTL,
            })
        } else if (MMStatusScenario === GeneralEnums.MMStatusScenario.EligibleToAnswer) {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/mocaMinuteLanding', {
                MDTValue,
            })
        } else {
            NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/knowledgeAssessmentReport')
        }
    }

    determineEligibleSubheaderText(quarterlyQuestionsRemaining) {
        const {MDTValue} = this.props.MMData
        const {SubheaderStyle} = this.props
        const notMeetingStandardText = (
            <View style={{width: '100%'}}>
                <Text style={SubheaderStyle}>
                    You’re not currently meeting the performance standard.
                </Text>
                <Text style={SubheaderStyle}>
                    Your MDT value is {MDTValue} (needs to be &ge; 0.10). You have{' '}
                    {quarterlyQuestionsRemaining} questions left this quarter. Answer 120 questions by
                    11:59 p.m. EST on Dec. 31​. At the end of the year, any unanswered questions will be
                    counted as incorrect​.
                </Text>
            </View>
        )

        if (MDTValue == null) {
            return <></>
        } else if (MDTValue >= 0.1) {
            return (
                <Text style={SubheaderStyle}>
                    You're doing great! Only {quarterlyQuestionsRemaining} questions left to answer this
                    quarter.
                </Text>
            )
        } else {
            return notMeetingStandardText
        }
    }

    displayDashboardInfo(mmData) {
        const {
            MMStatusScenario,
            QuarterlyQuestionsRemaining,
            AnnualMaxQuestions,
            MocaRegistrationStatus,
        } = mmData
        const eligibleSubheaderText = this.determineEligibleSubheaderText(QuarterlyQuestionsRemaining)

        switch (MMStatusScenario) {
            case GeneralEnums.MMStatusScenario.ReachedDailyLimit:
                this.setState({
                    HeaderText: "You've reached your daily MOCA Minute question limit.",
                    SubheaderText:
                        'Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.ReachedQuarterlyLimit:
                this.setState({
                    HeaderText: "You've reached your quarterly MOCA Minute question limit.",
                    SubheaderText:
                        'Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.ReachedAnnualLimit:
                this.setState({
                    HeaderText: "You've reached your annual MOCA Minute question limit.",
                    SubheaderText:
                        'You’ve answered ' +
                        AnnualMaxQuestions +
                        ' MOCA Minute questions and reached your yearly limit. Take a closer look at which questions you answered incorrectly, peer performance, and related CMEs in your Knowledge Assessment Report.',
                    ButtonText: 'Review Knowledge Assessment Report',
                    MMStatusScenario,
                })
                break
            case GeneralEnums.MMStatusScenario.EligibleToAnswer:
            default:
                this.setState({
                    HeaderText: 'Are you ready to answer a question?',
                    SubheaderText: eligibleSubheaderText,
                    ButtonText: 'Yes',
                    MMStatusScenario,
                })
                break
        }

        switch (MocaRegistrationStatus) {
            case GeneralEnums.MocaRegistrationStatus.NotRegisteredForMOCA:
                this.setState({
                    HeaderText: 'You’re not currently registered for MOCA 2.0®',
                    SubheaderText:
                        'Please register for MOCA 2.0 to answer MOCA Minute® questions and to continue participating.',
                    ButtonText: 'Register for MOCA',
                })
                break
            case GeneralEnums.MocaRegistrationStatus.NotRegisteredNTL:
                this.setState({
                    HeaderText: 'Register for MOCA 2.0®',
                    SubheaderText:
                        'You’re not required to participate in MOCA 2.0 but are welcome to register at any time.',
                    ButtonText: 'Register for MOCA',
                })
                break
            case GeneralEnums.MocaRegistrationStatus.NotRegisteredFirstTime:
                this.setState({
                    HeaderText: 'Begin your continuous learning journey by registering for MOCA®',
                    SubheaderText:
                        'Registering for MOCA will unlock your Physician’s Portal, allowing you to answer MOCA Minute® questions and participate in MOCA 2.0®.',
                    ButtonText: 'Register for MOCA',
                })
                break
            default:
                break
        }
    }

    render() {
        const {
            HeaderText,
            SubheaderText,
            width,
            ButtonText,
            MMStatusScenario,
            MocaMinuteData,
            ButtonDisabled,
        } = this.state
        const {HeaderStyle, SubheaderStyle, RenderProgressReportLink} = this.props
        const {EligibleToAnswer} = GeneralEnums.MMStatusScenario
        const {RegisteredForMOCA} = GeneralEnums.MocaRegistrationStatus

        const showMocaMinuteSubheaderView =
            MMStatusScenario === EligibleToAnswer &&
            MocaMinuteData.MocaRegistrationStatus === RegisteredForMOCA

        const mocaMinuteSubHeader = showMocaMinuteSubheaderView ? (
            <View>{SubheaderText}</View>
        ) : (
            <Text style={SubheaderStyle}>{SubheaderText}</Text>
        )
        return (
            <View
                style={{
                    minHeight: this.props.Stacked ? undefined : this.props.Height,
                    width: '95%',
                    margin: 'auto',
                    paddingTop: 30,
                    paddingBottom: 30,
                }}
                onLayout={(event) => {
                    this.setState({
                        width: event.nativeEvent.layout.width,
                    })
                }}
            >
                <Text style={HeaderStyle}>{HeaderText}</Text>
                {mocaMinuteSubHeader}
                <View
                    style={{
                        flexDirection: width > 500 ? 'row' : 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {RenderProgressReportLink && (
                        <View
                            style={{
                                width: this.state.width > 500 ? '50%' : '100%',
                                alignItems: 'center',
                                padding: 5,
                            }}
                        >
                            <ABATouchableHighlight
                                buttonStyle={StyleFunctions.buttonClickInverted()}
                                onPressEvent={() => {
                                    NavigationHelper.navigateBack()
                                }}
                                text={'Return to My Progress Report'}
                                textStyle={{
                                    color: ColorEnums.danube,
                                    fontFamily: 'Lato',
                                    fontSize: 16,
                                }}
                                underlayColor={ColorEnums.white}
                            />
                        </View>
                    )}
                    <View
                        style={{
                            width: width > 500 && RenderProgressReportLink ? '50%' : '100%',
                            alignItems: 'center',
                            padding: RenderProgressReportLink ? 5 : 0,
                        }}
                    >
                        {this.props.registration.isLoading ||
                        (ButtonText.indexOf('Register') > -1 &&
                            this.props.registration.pendingPayment) ? (
                            <View style={StyleFunctions.loadingOverlay()}>
                                <ActivityIndicator color={ColorEnums.indigo} size="large" />
                            </View>
                        ) : (
                            <ABATouchableHighlight
                                buttonStyle={StyleFunctions.buttonClick()}
                                icon={'angle-right'}
                                onPressEvent={() => this.setNavigationUrl()}
                                text={ButtonText}
                                isDisabled={ButtonDisabled}
                            />
                        )}
                    </View>
                </View>
                {MocaMinuteData?.ConsentToCME && (
                    <Text
                        style={[
                            SubheaderStyle,
                            {paddingTop: 10, bottom: 0, marginTop: 'auto', color: ColorEnums.danube},
                        ]}
                    >
                        You must answer 30 questions by the end of each quarter to earn up to a total of
                        10 CME credits for the year. These credits are through the ASA.
                    </Text>
                )}
            </View>
        )
    }
}

MocaMinuteInformation.defaultProps = {
    HeaderStyle: [
        StyleFunctions.containerHeader('center'),
        {paddingLeft: 10, paddingRight: 10, paddingBottom: 20},
    ],
    SubheaderStyle: [
        StyleFunctions.containerText('center'),
        {paddingLeft: 10, paddingRight: 10, paddingBottom: 20},
    ],
    RenderProgressReportLink: false,
}

export default connect(mapStateToProps)(MocaMinuteInformation)
