import {Platform} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const MyProgressLogic = {
    context: null,

    GetUserRoleId: function () {
        return new Promise((resolve) => {
            MyProgressLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('dashboard/getUserRoleId')
                .then(function (response) {
                    MyProgressLogic.context.setState({CallingApi: false})
                    resolve(response.data)
                })
                .catch(function (error) {
                    MyProgressLogic.context.setState({CallingApi: false})
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving My Progress Tabs')
                    resolve(null)
                })
        })
    },

    UpdateQiText: function () {
        if (MyProgressLogic.context.state.ComponentWidth < 800) {
            return 'QI'
        }

        return 'QUALITY IMPROVEMENT'
    },
}

export default MyProgressLogic
