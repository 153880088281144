import ClinicalActivityStep1 from '../presentation/clinicalActivityStep1'
import ClinicalActivityStep3 from '../presentation/clinicalActivityStep3'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'

const ClinicalActivityLogicStep2 = {
    context: null,

    GetClinicalActivityData: function () {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep2.context.setState({CallingApi: true})
            ABAPortalAPI.get(
                'clinicalactivity/getClinicalActivity?ClinicalActivityID=' +
                    clinicalActivityContext.state.ClinicalActivityID,
            )
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    ClinicalActivityLogicStep2.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables: function (CAData) {
        ClinicalActivityLogicStep2.context.setState(
            {
                cert_practicetype: +CAData.cert_practicetype || 'null',
                cert_anesthesiologydays:
                    CAData.cert_anesthesiologydays == 100 ? 0 : CAData.cert_anesthesiologydays,
                cert_painmedicinedays:
                    CAData.cert_painmedicinedays == 100 ? 0 : CAData.cert_painmedicinedays,
                cert_criticalcaremedicinedays:
                    CAData.cert_criticalcaremedicinedays == 100
                        ? 0
                        : CAData.cert_criticalcaremedicinedays,
                cert_PediatricAnesthesiologyDays:
                    CAData.cert_PediatricAnesthesiologyDays == 100
                        ? 0
                        : CAData.cert_PediatricAnesthesiologyDays,
                cert_HospiceandPalliativeMedicineDays:
                    CAData.cert_HospiceandPalliativeMedicineDays == 100
                        ? 0
                        : CAData.cert_HospiceandPalliativeMedicineDays,
                cert_sleepmedicinedays:
                    CAData.cert_sleepmedicinedays == 100 ? 0 : CAData.cert_sleepmedicinedays,
                cert_otheranesthesiology:
                    CAData.cert_otheranesthesiology == 100 ? 0 : CAData.cert_otheranesthesiology,
                cert_nccdays: CAData.cert_nccdays == 100 ? 0 : CAData.cert_nccdays,
                cert_acadays: CAData.cert_acadays == 100 ? 0 : CAData.cert_acadays,
                cert_otheractivitydescription: CAData.cert_otheractivitydescription || '',
                RequiredIncrementor: ClinicalActivityLogicStep2.SetRequiredIncrementor(
                    +CAData.cert_practicetype,
                ),
            },
            () => {
                ClinicalActivityLogicStep2.SetMaxValue()
            },
        )
    },

    GetLookupClinicalActivityPracticeTypes: function () {
        return new Promise((resolve) => {
            ClinicalActivityLogicStep2.context.setState({CallingApi: true})
            ABAPortalAPI.get('clinicalactivity/GetLookupClinicalActivityPracticeTypes')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Practice Area Profile Location',
                    )
                    resolve(null)
                })
                .finally(function () {
                    ClinicalActivityLogicStep2.context.setState({CallingApi: false})
                })
        })
    },

    UpdateClinicalActivityStep2: function () {
        return new Promise((resolve) => {
            const {
                cert_practicetype,
                cert_anesthesiologydays,
                cert_painmedicinedays,
                cert_criticalcaremedicinedays,
                cert_PediatricAnesthesiologyDays,
                cert_HospiceandPalliativeMedicineDays,
                cert_sleepmedicinedays,
                cert_otheranesthesiology,
                cert_otheractivitydescription,
                cert_nccdays,
                cert_acadays,
            } = this.context.state

            ClinicalActivityLogicStep2.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/UpdateClinicalActivityStep2', {
                cert_clinicalactivityid: clinicalActivityContext.state.ClinicalActivityID,
                cert_practicetype: cert_practicetype,
                cert_anesthesiologydays: cert_anesthesiologydays == 0 ? 100 : cert_anesthesiologydays,
                cert_painmedicinedays: cert_painmedicinedays == 0 ? 100 : cert_painmedicinedays,
                cert_criticalcaremedicinedays:
                    cert_criticalcaremedicinedays == 0 ? 100 : cert_criticalcaremedicinedays,
                cert_PediatricAnesthesiologyDays:
                    cert_PediatricAnesthesiologyDays == 0 ? 100 : cert_PediatricAnesthesiologyDays,
                cert_HospiceandPalliativeMedicineDays:
                    cert_HospiceandPalliativeMedicineDays == 0
                        ? 100
                        : cert_HospiceandPalliativeMedicineDays,
                cert_sleepmedicinedays: cert_sleepmedicinedays == 0 ? 100 : cert_sleepmedicinedays,
                cert_otheranesthesiology: cert_otheranesthesiology == 0 ? 100 : cert_otheranesthesiology,
                cert_otheractivitydescription: cert_otheractivitydescription,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
                cert_nccdays: cert_nccdays == 0 ? 100 : cert_nccdays,
                cert_acadays: cert_acadays == 0 ? 100 : cert_acadays,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    ClinicalActivityLogicStep2.context.setState({CallingApi: false})
                })
        })
    },

    PracticeDaysChange: function (type, change) {
        ClinicalActivityLogicStep2.context.setState(
            {
                [type]: change,
            },
            () => {
                ClinicalActivityLogicStep2.SetMaxValue()
            },
        )
    },

    OtherAnesthesiologyChange: function (type, change) {
        let {cert_otheractivitydescription} = ClinicalActivityLogicStep2.context.state
        if (change == 0) cert_otheractivitydescription = ''
        ClinicalActivityLogicStep2.context.setState(
            {
                [type]: change,
                cert_otheractivitydescription,
            },
            () => {
                ClinicalActivityLogicStep2.SetMaxValue()
            },
        )
    },

    TotalDays: function () {
        const {
            cert_anesthesiologydays,
            cert_painmedicinedays,
            cert_criticalcaremedicinedays,
            cert_PediatricAnesthesiologyDays,
            cert_HospiceandPalliativeMedicineDays,
            cert_sleepmedicinedays,
            cert_otheranesthesiology,
            cert_acadays,
            cert_nccdays,
        } = ClinicalActivityLogicStep2.context.state
        return (
            +cert_anesthesiologydays +
            +cert_painmedicinedays +
            +cert_criticalcaremedicinedays +
            +cert_PediatricAnesthesiologyDays +
            +cert_HospiceandPalliativeMedicineDays +
            +cert_sleepmedicinedays +
            +cert_otheranesthesiology +
            +cert_acadays +
            +cert_nccdays
        )
    },

    SetMaxValue: function () {
        var incrementDisabled = ClinicalActivityLogicStep2.context.state.incrementDisabled
        incrementDisabled = ClinicalActivityLogicStep2.TotalDays() >= 7
        ClinicalActivityLogicStep2.context.setState({
            incrementDisabled: incrementDisabled,
        })
    },

    practiceTypeChange: function (practiceType) {
        if (!practiceType || practiceType === 'null') {
            clinicalActivityStep2Context.setState({
                cert_practicetype: practiceType,
                RequiredIncrementor: '',
            })
            return
        }
        clinicalActivityStep2Context.setState({
            cert_practicetype: +practiceType,
            RequiredIncrementor: ClinicalActivityLogicStep2.SetRequiredIncrementor(+practiceType),
        })
    },

    SetRequiredIncrementor: function (practiceType) {
        switch (practiceType) {
            case 1:
                return 'cert_anesthesiologydays'
            case 2:
                return 'cert_painmedicinedays'
            case 3:
                return 'cert_criticalcaremedicinedays'
            case 10:
                return 'cert_PediatricAnesthesiologyDays'
            case 11:
                return 'cert_otheranesthesiology'
            case 12:
                return 'cert_HospiceandPalliativeMedicineDays'
            case 13:
                return 'cert_sleepmedicinedays'
            case 14:
                return 'cert_nccdays'
            case 15:
                return 'cert_acadays'
        }
    },

    ValidatePage() {
        const {ProgramType} = this.context.props

        const {
            Primary,
            Pain_Medicine,
            Critical_Care_Medicine,
            Pediatric_Anesthesiology,
            Hospice_and_Palliative_Medicine,
            Sleep_Medicine,
            Neurocritical_Care_Exam,
            Adult_Cardiac_Anesthesiology,
        } = GeneralEnums.program_type

        const requireAnesthesiologyPracticeTypes = [1, 7, 5, 6, 4, 8, 9] //values from dropdown

        const {
            cert_practicetype,
            cert_otheractivitydescription,
            cert_otheranesthesiology,
            cert_anesthesiologydays,
            cert_painmedicinedays,
            cert_criticalcaremedicinedays,
            cert_PediatricAnesthesiologyDays,
            cert_HospiceandPalliativeMedicineDays,
            cert_sleepmedicinedays,
            cert_nccdays,
            cert_acadays,
            InputStatus,
        } = this.context.state

        const {error, success} = GeneralEnums.textInputStatus

        let Validated = true
        if (cert_practicetype == 'null') {
            Validated = false
            InputStatus.cert_practicetype = error
        } else {
            InputStatus.cert_practicetype = success
        }

        if (cert_otheractivitydescription == '' && cert_otheranesthesiology > 0) {
            Validated = false
            InputStatus.cert_otheractivitydescription = error
        } else {
            InputStatus.cert_otheractivitydescription = success
        }

        if (
            cert_anesthesiologydays == 0 &&
            (requireAnesthesiologyPracticeTypes.includes(cert_practicetype) || ProgramType === Primary)
        ) {
            Validated = false
            InputStatus.cert_anesthesiologydays = error
        } else {
            InputStatus.cert_anesthesiologydays = success
        }

        if (cert_painmedicinedays == 0 && (cert_practicetype == '2' || ProgramType === Pain_Medicine)) {
            Validated = false
            InputStatus.cert_painmedicinedays = error
        } else {
            InputStatus.cert_painmedicinedays = success
        }

        if (
            cert_criticalcaremedicinedays == 0 &&
            (cert_practicetype == '3' || ProgramType === Critical_Care_Medicine)
        ) {
            Validated = false
            InputStatus.cert_criticalcaremedicinedays = error
        } else {
            InputStatus.cert_criticalcaremedicinedays = success
        }

        if (
            cert_PediatricAnesthesiologyDays == 0 &&
            (cert_practicetype == '10' || ProgramType === Pediatric_Anesthesiology)
        ) {
            Validated = false
            InputStatus.cert_PediatricAnesthesiologyDays = error
        } else {
            InputStatus.cert_PediatricAnesthesiologyDays = success
        }

        if (
            cert_HospiceandPalliativeMedicineDays == 0 &&
            (cert_practicetype == '12' || ProgramType === Hospice_and_Palliative_Medicine)
        ) {
            Validated = false
            InputStatus.cert_HospiceandPalliativeMedicineDays = error
        } else {
            InputStatus.cert_HospiceandPalliativeMedicineDays = success
        }

        if (
            cert_sleepmedicinedays == 0 &&
            (cert_practicetype == '13' || ProgramType === Sleep_Medicine)
        ) {
            Validated = false
            InputStatus.cert_sleepmedicinedays = error
        } else {
            InputStatus.cert_sleepmedicinedays = success
        }

        if (cert_otheranesthesiology == 0 && cert_practicetype == '11') {
            Validated = false
            InputStatus.cert_otheranesthesiology = error
        } else {
            InputStatus.cert_otheranesthesiology = success
        }

        if (
            cert_nccdays == 0 &&
            (cert_practicetype == '14' || ProgramType === Neurocritical_Care_Exam)
        ) {
            Validated = false
            InputStatus.cert_nccdays = error
        } else {
            InputStatus.cert_nccdays = success
        }

        if (
            cert_acadays == 0 &&
            (cert_practicetype == '15' || ProgramType === Adult_Cardiac_Anesthesiology)
        ) {
            Validated = false
            InputStatus.cert_acadays = error
        } else {
            InputStatus.cert_acadays = success
        }

        if (!Validated) {
            ClinicalActivityLogicStep2.context.setState({
                InputStatus,
            })
        }
        return Validated
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!ClinicalActivityLogicStep2.ValidatePage()) {
                ClinicalActivityLogicStep2.SetMaxValue()
                resolve(false)
                return
            }

            ClinicalActivityLogicStep2.UpdateClinicalActivityStep2().then((response) => {
                if (response) {
                    clinicalActivityContext.setState(
                        {
                            CurrentComponentStep: ClinicalActivityStep3,
                        },
                        () => {
                            registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
                                x: 0,
                                y: 0,
                                animated: false,
                            })
                        },
                    )
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            clinicalActivityContext.setState({
                CurrentComponentStep: ClinicalActivityStep1,
            })
            resolve(false)
        })
    },
}

export default ClinicalActivityLogicStep2
