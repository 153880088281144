import React, {Component} from 'react'
import {Text, View, StyleSheet} from 'react-native'
import HelperMethods from '../../helpers/HelperMethods'
import NotificationsLogic from '../logic/notificationsLogic'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import NavigationHelper from '../../helpers/NavigationHelper'

export default class Notifications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notifications: [],
        }

        this.styles = StyleSheet.create({
            container: {
                maxWidth: 700,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
            },
            text: {
                color: ColorEnums.cloudBurst,
                fontFamily: 'Lato',
            },
            link: {
                fontFamily: 'Lato',
                color: ColorEnums.danube,
                fontSize: 12,
                paddingTop: 3,
            },
        })
    }

    componentDidMount() {
        NotificationsLogic.getBellNotifications().then((data) => {
            this.setState({notifications: data}, () => {
                NotificationsLogic.setBellNotificationToRead()
            })
        })
    }

    deleteNotification(notificationId, notificationIndex) {
        NotificationsLogic.deleteBellNotification(notificationId).then(() => {
            let notifications = [...this.state.notifications]
            notifications.splice(notificationIndex, 1)
            this.setState({notifications})
        })
    }

    renderLinks(notification) {
        if (!notification.aba_url) {
            return
        }

        let urlName = notification.aba_urlname ? notification.aba_urlname : 'Click Here'

        if (notification.aba_isinternalurl) {
            return (
                <Text
                    style={this.styles.link}
                    onPress={() =>
                        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, notification.aba_url)
                    }
                >
                    {urlName}
                </Text>
            )
        }

        return <ABAHyperLink label={urlName} url={notification.aba_url} style={this.styles.link} />
    }

    render() {
        notificationsContext = this
        navigationContext = this
        NotificationsLogic.context = notificationsContext

        HelperMethods.setNavigationData(this)

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader heading="Notifications" />
                    {this.state.notifications.length < 1 ? (
                        <View style={this.styles.container}>
                            <Text>No notifications right now</Text>
                        </View>
                    ) : (
                        <View style={this.styles.container}>
                            {this.state.notifications.map((n, index) => {
                                let paddingColor = ColorEnums.danube
                                if (n.OpenedOn) {
                                    paddingColor = ColorEnums.exLightGray
                                }

                                return (
                                    <View
                                        style={{
                                            backgroundColor: 'white',
                                            marginTop: 2,
                                            marginBottom: 2,
                                            minHeight: 100,
                                            flexDirection: 'row',
                                        }}
                                        key={index}
                                    >
                                        <View style={{backgroundColor: paddingColor, width: 10}}></View>
                                        <View
                                            style={{
                                                justifyContent: 'space-between',
                                                flex: 1,
                                                padding: 5,
                                            }}
                                        >
                                            <View>
                                                <Text
                                                    style={[
                                                        this.styles.text,
                                                        {
                                                            fontSize: 16,
                                                            paddingBottom: 10,
                                                            fontWeight: 'bold',
                                                        },
                                                    ]}
                                                >
                                                    {n.aba_messagetitle}
                                                </Text>
                                                <Text style={[this.styles.text, {fontSize: 14}]}>
                                                    {n.aba_text}
                                                </Text>
                                                {this.renderLinks(n)}
                                            </View>
                                            <Text style={[this.styles.text, {fontSize: 12}]}>
                                                Received {HelperMethods.calculateTime(n.SentOn)}
                                            </Text>
                                        </View>
                                        <View style={{justifyContent: 'center', padding: 5}}>
                                            <Text
                                                style={{
                                                    fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                    color: ColorEnums.pomegranateDark,
                                                    paddingRight: 5,
                                                }}
                                                onPress={() => {
                                                    this.deleteNotification(n.NotificationId, index)
                                                }}
                                            >
                                                {IconEnums['trash']}
                                            </Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    )}
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
