import React, {Component} from 'react'
import {Text, View, ActivityIndicator, StyleSheet, Platform} from 'react-native'

import PropTypes from 'prop-types'
import ABACheckbox from '../ABACustom/presentation/ABACheckbox'
import ABADropdown from '../ABACustom/presentation/ABADropdown'
import ABATextInput from '../ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../ABACustom/presentation/ABATextInputMask'
import ABAModal from '../ABACustom/presentation/ABAModal'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import ABAConfirmationBox from '../ABACustom/presentation/ABAConfirmationBox'
import ABARequiredAsterisk from '../ABACustom/presentation/ABARequiredAsterisk'
import ReferenceLogic from '../logic/referenceLogic'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import StrikeIronForm from './strikeIronForm'

const styles = StyleSheet.create({
    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },

    confirmationBox: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
})

export default class Reference extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ComponentWidth: 700,
            aba_referenceclaim: 1,
            CallingApi: false,
            ShowStrikeIronScreen: false,
            cert_AddressLine1: '',
            cert_AddressLine2: '',
            cert_City: '',
            cert_CountryId: 'null',
            cert_DeptBox: '',
            cert_Email: '',
            cert_Fax: '',
            cert_FirstName: '',
            cert_InstitutionorOrganization: '',
            cert_LastName: '',
            cert_MedicalDegree: 'null',
            cert_MiddleName: '',
            cert_OfficePhone: '',
            cert_referenceId: '',
            cert_Salutation: 'null',
            cert_StateId: 'null',
            cert_Suffix: 'null',
            cert_referencepostype: 'null',
            referenceType: '',
            cert_ZipCode: '',
            countryList: [],
            medicalDegreeList: [],
            salutationList: [],
            stateList: [],
            suffixList: [],
            positionList: [],
            emailFormatInvalid: false,
            referenceTypeList: [],
            canEdit: false,
            showDeleteConfirm: false,
            hasErrors: false,
            saving: false,
            cert_StateorProvince: '',
            officePhoneFormatError: false,
            faxFormatError: false,
            InputStatus: {
                aba_referenceclaim: GeneralEnums.textInputStatus.default,
                cert_AddressLine1: GeneralEnums.textInputStatus.default,
                cert_AddressLine2: GeneralEnums.textInputStatus.default,
                cert_City: GeneralEnums.textInputStatus.default,
                cert_CountryId: GeneralEnums.textInputStatus.default,
                cert_DeptBox: GeneralEnums.textInputStatus.default,
                cert_Email: GeneralEnums.textInputStatus.default,
                cert_Fax: GeneralEnums.textInputStatus.default,
                cert_FirstName: GeneralEnums.textInputStatus.default,
                cert_InstitutionorOrganization: GeneralEnums.textInputStatus.default,
                cert_LastName: GeneralEnums.textInputStatus.default,
                cert_MedicalDegree: GeneralEnums.textInputStatus.default,
                cert_MiddleName: GeneralEnums.textInputStatus.default,
                cert_OfficePhone: GeneralEnums.textInputStatus.default,
                referenceType: GeneralEnums.textInputStatus.default,
                cert_Salutation: GeneralEnums.textInputStatus.default,
                cert_StateId: GeneralEnums.textInputStatus.default,
                cert_Suffix: GeneralEnums.textInputStatus.default,
                cert_referencepostype: GeneralEnums.textInputStatus.default,
                cert_ZipCode: GeneralEnums.textInputStatus.default,
                cert_StateorProvince: GeneralEnums.textInputStatus.default,
            },
        }

        this.referenceLogic = Object.assign({}, ReferenceLogic)
        this.referenceLogic.context = this
    }

    componentDidMount() {
        this.setState({CallingApi: true})

        this.props.referenceID !== '-1'
            ? this.referenceLogic
                  .GetReferenceDropdownData(this.props.ProgramType)
                  .then((dropdownData) => {
                      this.referenceLogic.GetReference(this.props.referenceID).then((referenceData) => {
                          this.referenceLogic.GetCountryListData().then((countryData) => {
                              this.referenceLogic
                                  .GetStateListData(referenceData.cert_CountryId)
                                  .then((stateData) => {
                                      this.referenceLogic.PopulateVariables(
                                          dropdownData,
                                          countryData,
                                          referenceData,
                                          stateData,
                                      )
                                  })
                              this.setState({CallingApi: false})
                          })
                      })
                  })
            : this.referenceLogic
                  .GetReferenceDropdownData(this.props.ProgramType)
                  .then((dropdownData) => {
                      this.referenceLogic.GetCountryListData().then((countryData) => {
                          this.referenceLogic.PopulateVariables(dropdownData, countryData)
                      })
                  })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.referenceID !== this.props.referenceID) {
            this.referenceLogic.GetReferenceDropdownData(this.props.ProgramType).then((dropdownData) => {
                this.referenceLogic.GetReference(this.props.referenceID).then((referenceData) => {
                    this.referenceLogic.GetCountryListData().then((countryData) => {
                        this.referenceLogic
                            .GetStateListData(referenceData.cert_CountryId)
                            .then((stateData) => {
                                this.referenceLogic.PopulateVariables(
                                    dropdownData,
                                    countryData,
                                    referenceData,
                                    stateData,
                                )
                            })
                    })
                })
            })
        }
    }

    renderStateAndPostal() {
        return (
            <View
                style={{
                    flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                    width: '100%',
                }}
            >
                <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                    {this.state.stateList.length > 0 ? (
                        <View style={{marginBottom: 30}}>
                            <ABADropdown
                                options={this.state.stateList}
                                label={`State${this.state.stateList.length === 0 ? '' : ' *'}`}
                                placeholder={`State${this.state.stateList.length === 0 ? '' : ' *'}`}
                                onChange={(cert_StateId) => this.setState({cert_StateId})}
                                selectedValue={this.state.cert_StateId}
                                validationStatus={this.state.InputStatus.cert_StateId}
                                width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                            />
                        </View>
                    ) : (
                        <ABATextInput
                            onChangeText={(cert_StateorProvince) =>
                                this.setState({cert_StateorProvince})
                            }
                            placeholder="State *"
                            textInputStatus={this.state.InputStatus.cert_StateorProvince}
                            returnKeyType="next"
                            width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                            marginLeft={0}
                            value={this.state.cert_StateorProvince}
                            onBlur={() =>
                                this.setState({
                                    cert_StateorProvince: HelperMethods.properCase(
                                        this.state.cert_StateorProvince,
                                    ),
                                })
                            }
                        />
                    )}
                </View>
                <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                    <ABATextInput
                        onChangeText={(text) => this.setState({cert_ZipCode: text})}
                        placeholder="Postal Code *"
                        textInputStatus={this.state.InputStatus.cert_ZipCode}
                        returnKeyType="next"
                        value={this.state.cert_ZipCode}
                        width="100%"
                        maxLength={20}
                    />
                </View>
            </View>
        )
    }

    updateStateList(countryId) {
        if (!countryId || countryId === 'null' || countryId === this.state.cert_CountryId) {
            return
        }

        const {InputStatus} = this.state
        InputStatus.cert_StateorProvince = GeneralEnums.textInputStatus.default
        InputStatus.cert_StateId = GeneralEnums.textInputStatus.default

        this.setState({CallingApi: true})
        this.referenceLogic.getStatesProvinces(countryId).then((stateList) => {
            this.setState({
                stateList,
                CallingApi: false,
                cert_StateorProvince: '',
                InputStatus,
                cert_StateId: 'null',
            })
        })
    }

    handleCountryChange(cert_CountryId) {
        const {referenceID} = this.props
        if (cert_CountryId === 'null' && referenceID !== '-1') return
        if (cert_CountryId === 'null' && referenceID === '-1') {
            const {USA} = GeneralEnums.cert_CountryId
            this.setState({cert_CountryId: USA}, this.updateStateList(USA))
        } else {
            this.setState({cert_CountryId}, this.updateStateList(cert_CountryId))
        }
    }

    render() {
        const {IsSubstanceAbuseRef, IsRelapseRef} = this.props
        const {
            cert_FirstName,
            cert_MiddleName,
            cert_LastName,
            ComponentWidth,
            officePhoneFormatError,
            faxFormatError,
        } = this.state
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`

        return (
            <View
                style={{paddingVertical: 15, borderWidth: 0}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!IsSubstanceAbuseRef &&
                    this.props.ReferenceType != GeneralEnums.cert_referenceType.Clinical_Activity && (
                        <ABACheckbox
                            checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                            isChecked={this.state.aba_referenceclaim == 2}
                            label={
                                <Text style={{fontSize: 15}}>
                                    <Text style={{fontWeight: 'bold', fontSize: 15}}>
                                        I serve in this position or there is no such position.
                                    </Text>
                                    {'\n'}(Please enter another reference)
                                </Text>
                            }
                            onChangeEvent={() => {
                                this.referenceLogic.SetAba_referenceclaim()
                            }}
                            validationStatus={this.state.InputStatus.aba_referenceclaim}
                            width="80%"
                        />
                    )}

                {IsRelapseRef && (
                    <View
                        style={{
                            flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                            paddingBottom: 15,
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        <View style={{width: ComponentWidth > 600 ? '70%' : '100%'}}>
                            <Text style={[styleFunctions.paragraphText(), {fontSize: 14, width: '90%'}]}>
                                Relapse Reference Type
                                <ABARequiredAsterisk />
                            </Text>
                        </View>
                        <View style={{width: ComponentWidth > 600 ? '30%' : '100%'}}>
                            <ABADropdown
                                options={this.state.referenceTypeList}
                                placeholder="Select Type"
                                onChange={(referenceType) => {
                                    this.setState({referenceType})
                                }}
                                selectedValue={this.state.referenceType}
                                validationStatus={this.state.InputStatus.referenceType}
                                width="100%"
                            />
                        </View>
                    </View>
                )}

                <View style={{width: '100%'}}>
                    {this.state.aba_referenceclaim === 2 && !IsSubstanceAbuseRef && (
                        <View style={{width: '100%', marginBottom: 8, marginTop: 5}}>
                            <ABADropdown
                                label="Reference Title *"
                                placeholder="Reference Title *"
                                options={this.state.positionList}
                                width="100%"
                                selectedValue={
                                    this.state.cert_referencepostype &&
                                    this.state.cert_referencepostype.toString()
                                }
                                validationStatus={this.state.InputStatus.cert_referencepostype}
                                onChange={(cert_referencepostype) => {
                                    this.setState({cert_referencepostype})
                                }}
                            />
                        </View>
                    )}
                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                width: this.state.ComponentWidth > 600 ? '20%' : '100%',
                                marginBottom: 30,
                            }}
                        >
                            <ABADropdown
                                label="Prefix"
                                placeholder="Prefix"
                                options={this.state.salutationList}
                                width={this.state.ComponentWidth > 600 ? '85%' : '100%'}
                                selectedValue={this.state.cert_Salutation}
                                validationStatus={this.state.InputStatus.cert_Salutation}
                                onChange={(cert_Salutation) => {
                                    this.setState({cert_Salutation})
                                }}
                            />
                        </View>
                        <View style={{width: this.state.ComponentWidth > 600 ? '80%' : '100%'}}>
                            <ABATextInput
                                placeholder="First Name *"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.cert_FirstName}
                                onChangeText={(text) => {
                                    this.setState({cert_FirstName: text})
                                }}
                                value={this.state.cert_FirstName}
                                width="100%"
                                maxLength={50}
                            />
                        </View>
                    </View>
                    <ABATextInput
                        placeholder="Middle Name"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_MiddleName}
                        onChangeText={(text) => {
                            this.setState({cert_MiddleName: text})
                        }}
                        value={this.state.cert_MiddleName}
                        width="100%"
                        maxLength={50}
                    />
                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                        }}
                    >
                        <View style={{width: this.state.ComponentWidth > 600 ? '80%' : '100%'}}>
                            <ABATextInput
                                placeholder="Last Name *"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.cert_LastName}
                                onChangeText={(text) => {
                                    this.setState({cert_LastName: text})
                                }}
                                value={this.state.cert_LastName}
                                width={this.state.ComponentWidth > 600 ? '95%' : '100%'}
                                marginLeft={0}
                                marginRight={0}
                                maxLength={50}
                            />
                        </View>
                        <View
                            style={{
                                width: this.state.ComponentWidth > 600 ? '20%' : '100%',
                                marginBottom: 30,
                            }}
                        >
                            <ABADropdown
                                label="Suffix"
                                placeholder="Suffix"
                                options={this.state.suffixList}
                                selectedValue={this.state.cert_Suffix}
                                validationStatus={this.state.InputStatus.cert_Suffix}
                                onChange={(value) => {
                                    this.setState({cert_Suffix: value})
                                }}
                                width="100%"
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                width: this.state.ComponentWidth > 600 ? '50%' : '100%',
                                marginBottom: 30,
                            }}
                        >
                            <ABADropdown
                                label="Medical Degree *"
                                placeholder="Medical Degree *"
                                options={this.state.medicalDegreeList}
                                selectedValue={this.state.cert_MedicalDegree}
                                validationStatus={this.state.InputStatus.cert_MedicalDegree}
                                onChange={(cert_MedicalDegree) => {
                                    this.setState({cert_MedicalDegree})
                                }}
                                width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                            />
                        </View>
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInput
                                placeholder="Email *"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.cert_Email}
                                errorMessage={
                                    this.state.emailFormatInvalid
                                        ? 'Please enter a valid email address.'
                                        : 'Please enter a value.'
                                }
                                onChangeText={(text) => {
                                    this.setState({cert_Email: text})
                                }}
                                value={this.state.cert_Email}
                                width="100%"
                                maxLength={100}
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: this.state.ComponentWidth > 600 ? 'row' : 'column',
                            width: '100%',
                        }}
                    >
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={14}
                                placeholder="Office Phone *"
                                returnKeyType="next"
                                options={{mask: '(999) 999-9999'}}
                                textInputStatus={this.state.InputStatus.cert_OfficePhone}
                                onChangeText={(text) => {
                                    this.setState({cert_OfficePhone: text})
                                }}
                                value={this.state.cert_OfficePhone}
                                width={this.state.ComponentWidth > 600 ? '90%' : '100%'}
                                marginLeft={0}
                                marginRight={0}
                                errorMessage={
                                    officePhoneFormatError
                                        ? 'Please enter a valid number'
                                        : 'Please enter a Value'
                                }
                            />
                        </View>
                        <View style={{width: this.state.ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                keyboardType="number-pad"
                                maxLength={14}
                                options={{mask: '(999) 999-9999'}}
                                placeholder="Fax*"
                                returnKeyType="next"
                                textInputStatus={this.state.InputStatus.cert_Fax}
                                onChangeText={(text) => {
                                    this.setState({cert_Fax: text})
                                }}
                                value={this.state.cert_Fax}
                                width="100%"
                                marginLeft={0}
                                marginRight={0}
                                errorMessage={
                                    faxFormatError
                                        ? 'Please enter a valid number'
                                        : 'Please enter a Value'
                                }
                            />
                        </View>
                    </View>
                </View>

                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 14, paddingTop: 25, fontWeight: 'bold', width: '90%'},
                        ]}
                    >
                        MAILING ADDRESS
                    </Text>
                    <ABATextInput
                        placeholder="Institution/Practice *"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_InstitutionorOrganization}
                        onChangeText={(text) => this.setState({cert_InstitutionorOrganization: text})}
                        value={this.state.cert_InstitutionorOrganization}
                        width="100%"
                        maxLength={250}
                        onBlur={() =>
                            this.setState({
                                cert_InstitutionorOrganization: HelperMethods.properCase(
                                    this.state.cert_InstitutionorOrganization,
                                ),
                            })
                        }
                    />
                    <ABATextInput
                        placeholder="Address 1 *"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_AddressLine1}
                        onChangeText={(text) => this.setState({cert_AddressLine1: text})}
                        value={this.state.cert_AddressLine1}
                        width="100%"
                        maxLength={250}
                        onBlur={() =>
                            this.setState({
                                cert_AddressLine1: HelperMethods.properCase(
                                    this.state.cert_AddressLine1,
                                ),
                            })
                        }
                    />
                    <ABATextInput
                        placeholder="Address 2"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_AddressLine2}
                        onChangeText={(text) => this.setState({cert_AddressLine2: text})}
                        value={this.state.cert_AddressLine2}
                        width="100%"
                        maxLength={250}
                        onBlur={() =>
                            this.setState({
                                cert_AddressLine2: HelperMethods.properCase(
                                    this.state.cert_AddressLine2,
                                ),
                            })
                        }
                    />
                    <ABATextInput
                        placeholder="Dept/Box"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_DeptBox}
                        onChangeText={(text) => this.setState({cert_DeptBox: text})}
                        value={this.state.cert_DeptBox}
                        width="100%"
                        maxLength={250}
                        onBlur={() =>
                            this.setState({
                                cert_DeptBox: HelperMethods.properCase(this.state.cert_DeptBox),
                            })
                        }
                    />
                    <View style={{marginBottom: 30}}>
                        <ABADropdown
                            options={this.state.countryList}
                            label="Country *"
                            placeholder="Country *"
                            onChange={(countryId) => {
                                this.handleCountryChange(countryId)
                            }}
                            selectedValue={this.state.cert_CountryId}
                            validationStatus={this.state.InputStatus.cert_CountryId}
                            width="100%"
                        />
                    </View>
                    <ABATextInput
                        placeholder="City *"
                        returnKeyType="next"
                        textInputStatus={this.state.InputStatus.cert_City}
                        onChangeText={(text) => this.setState({cert_City: text})}
                        value={this.state.cert_City}
                        width="100%"
                        maxLength={80}
                        onBlur={() =>
                            this.setState({
                                cert_City: HelperMethods.properCase(this.state.cert_City),
                            })
                        }
                    />

                    {this.renderStateAndPostal()}
                </View>

                {!this.props.isConfirmationPage && this.props.RenderSaveReferenceButton && (
                    <View
                        style={{
                            width: '100%',
                            alignSelf: 'center',
                            paddingTop: 25,
                            paddingBottom: 20,
                        }}
                    >
                        <ABATouchableHighlight
                            onPressEvent={() => this.referenceLogic.UpdateReference()}
                            text={'Save Reference'}
                        />
                    </View>
                )}
                {!this.props.isConfirmationPage && this.props.RenderEditButtons && (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: this.state.ComponentWidth > 600 ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <Text
                            style={[styles.accordionTextLinkStyle, {marginLeft: 0}]}
                            onPress={() => this.referenceLogic.UpdateReference()}
                        >
                            SAVE
                        </Text>
                        {!this.props.NewReference && (
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={styles.accordionTextLinkStyle}
                                    onPress={() => this.referenceLogic.CancelReferenceChanges()}
                                >
                                    CANCEL
                                </Text>
                                <Text
                                    style={styles.accordionTextLinkStyle}
                                    onPress={() => {
                                        this.setState({showDeleteConfirm: true})
                                    }}
                                >
                                    DELETE
                                </Text>
                            </View>
                        )}
                    </View>
                )}

                {this.state.showDeleteConfirm && (
                    <View style={styles.confirmationBox}>
                        <ABAConfirmationBox
                            messageText1="Are you sure you want to delete this reference?"
                            messageText2={referenceName}
                            buttonLeftText="Yes"
                            buttonRightText="No"
                            leftButtonFunction={() => this.referenceLogic.DeleteReference()}
                            rightButtonFunction={() => this.setState({showDeleteConfirm: false})}
                        />
                    </View>
                )}

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        {this.state.saving ? (
                            <Text>Saving Reference Data</Text>
                        ) : (
                            <Text>Loading Reference Data</Text>
                        )}
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}

                {this.state.ShowStrikeIronScreen && (
                    <ABAModal
                        isVisible={this.state.ShowStrikeIronScreen}
                        onDismiss={() => this.setState({ShowStrikeIronScreen: false})}
                        onBackdropPress={() => this.setState({ShowStrikeIronScreen: false})}
                    >
                        <StrikeIronForm
                            AddressLine1={this.state.cert_AddressLine1}
                            AddressLine2={this.state.cert_AddressLine2}
                            City={this.state.cert_City}
                            StateSelected={this.state.cert_StateId}
                            ZipCode={this.state.cert_ZipCode}
                            onPressEvent={() => this.setState({ShowStrikeIronScreen: false})}
                            CountryName={
                                this.state.countryList.find((option) => {
                                    return option.value === this.state.cert_CountryId
                                }).label
                            }
                            CountrySelected={this.state.cert_CountryId}
                            StateName={
                                this.state.stateList.find((option) => {
                                    return option.value === this.state.cert_StateId
                                }).label
                            }
                            saveAsFound={this.referenceLogic.saveAsFound.bind(this.referenceLogic)}
                            saveAsEntered={this.referenceLogic.saveAsEntered.bind(this.referenceLogic)}
                        />
                    </ABAModal>
                )}
            </View>
        )
    }
}

Reference.propTypes = {
    referenceID: PropTypes.string,
    isConfirmationPage: PropTypes.bool,
    ProgramType: PropTypes.number,
    ReferenceType: PropTypes.number,
    IsSubstanceAbuseRef: PropTypes.bool,
    IsRelapseRef: PropTypes.bool,
    RenderSaveReferenceButton: PropTypes.bool,
    RenderEditButtons: PropTypes.bool,
    NewReference: PropTypes.bool,
}
