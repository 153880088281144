import React, {Component} from 'react'
import {View, Text, Platform} from 'react-native'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABATextInputMask from '../../components/ABACustom/presentation/ABATextInputMask'
import ABATextInput from '../../components/ABACustom/presentation/ABATextInput'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import StyleFunctions from '../../styles/styleFunctions'
import ReportQIActivitiesLogic from '../logic/reportQiActivitiesLogic'

export default class ReportQIActivities extends Component {
    state = {
        CallingApi: false,
        crt_datecompleted: '',
        crt_HoursReported: '',
        crt_datecompletedError: 'Please enter a value.',
        reportingActivityTypes: [],
        reportingActivity: '',
        IMPID: null,
        crt_maxpoints: 0,
        crt_pointsperhour: 0,
        reportingActivityName: '',
        InputStatus: {
            crt_datecompleted: GeneralEnums.textInputStatus.default,
            reportingActivity: GeneralEnums.textInputStatus.default,
            crt_HoursReported: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        this.crt_datecompletedInput.focus()
        const {IMPID} = this.navigationData

        if (!IMPID) {
            ReportQIActivitiesLogic.GetReportingActivityTypesFromCrm().then((reportingActivityTypes) => {
                ReportQIActivitiesLogic.PopulateVariables(reportingActivityTypes, null)
            })
        }

        if (IMPID) {
            ReportQIActivitiesLogic.GetReportingActivityTypesFromCrm().then((reportingActivityTypes) => {
                ReportQIActivitiesLogic.GetIMP(IMPID).then((impData) => {
                    ReportQIActivitiesLogic.PopulateVariables(reportingActivityTypes, impData)
                })
            })
        }
    }

    setQITitle() {
        if (this.navigationData.IMPID) {
            return 'Edit QI Activity'
        }
        return 'Add QI Activity'
    }

    setInputWidth() {
        if (Platform.OS == 'web') {
            return '50%'
        } else {
            return '90%'
        }
    }

    calculatePointsToDisplay(reportingActivity) {
        let pointsToDisplay = 0
        if (reportingActivity) {
            const {crt_HoursReported, crt_pointsperhour, crt_maxpoints} = this.state
            calculatedPoints = crt_HoursReported * crt_pointsperhour
            pointsToDisplay = calculatedPoints > crt_maxpoints ? crt_maxpoints : calculatedPoints
        }

        return pointsToDisplay
    }

    render() {
        reportQiContext = this
        HelperMethods.setNavigationData(reportQiContext)
        ReportQIActivitiesLogic.context = reportQiContext
        const {InputStatus, reportingActivityTypes, reportingActivity} = this.state
        const pointsToDisplay = this.calculatePointsToDisplay(reportingActivity)

        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                    <ABAContainer
                        activityText={'Loading QI Activity Data'}
                        containerTitle={this.setQITitle()}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        <View style={{marginBottom: 20}}>
                            <Text style={{fontFamily: 'Lato'}}>
                                Earn <Text style={{fontWeight: 'bold'}}>25</Text> points per five-year
                                period for a total of <Text style={{fontWeight: 'bold'}}>50</Text> points
                                during your 10-year MOCA cycle. Choose activities from the
                                <ABAHyperLink
                                    url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_QI_Activities.pdf"
                                    label=" approved QI activity list "
                                />
                                to accumulate points.
                            </Text>
                        </View>
                        <Text style={{fontFamily: 'Lato', marginBottom: 20}}>
                            <Text>Activity submissions are subject to audit.</Text>
                        </Text>
                        <Text style={{paddingBottom: 15}}>
                            <Text style={{fontFamily: 'Lato', fontStyle: 'italic'}}>
                                An attestation is required, but will not provide points.
                            </Text>
                        </Text>
                        <View>
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <ABATextInputMask
                                    errorMessage={this.state.crt_datecompletedError}
                                    keyboardType="number-pad"
                                    maxLength={10}
                                    onChangeText={(crt_datecompleted) => {
                                        reportQiContext.setState({crt_datecompleted})
                                    }}
                                    options={{mask: '99/99/9999'}}
                                    placeholder="Date Completed (mm/dd/yyyy) *"
                                    refInput={(input) => {
                                        this.crt_datecompletedInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.crt_datecompleted}
                                    value={this.state.crt_datecompleted}
                                    width={this.setInputWidth()}
                                />
                                <View style={{marginBottom: 20, width: this.setInputWidth()}}>
                                    <ABADropdown
                                        label="Type *"
                                        placeholder="Select Type *"
                                        onChange={(reportingActivity) =>
                                            ReportQIActivitiesLogic.SetReportingActivityData(
                                                reportingActivity,
                                                reportingActivityTypes,
                                            )
                                        }
                                        options={reportingActivityTypes}
                                        selectedValue={reportingActivity}
                                        validationStatus={InputStatus.reportingActivity}
                                        width="100%"
                                    />
                                </View>

                                {this.state.crt_maxpoints > 0 && (
                                    <Text
                                        style={{
                                            fontWeight: 'bold',
                                            color: ColorEnums.citrusDark,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        Maximum number of points for this activity is{' '}
                                        {this.state.crt_maxpoints}.
                                    </Text>
                                )}

                                <ABATextInput
                                    maxLength={4}
                                    onChangeText={(crt_HoursReported) => {
                                        ReportQIActivitiesLogic.SetHoursReported(crt_HoursReported)
                                    }}
                                    placeholder="Hours spent completing the activity *"
                                    reference={(input) => {
                                        this.crt_HoursReportedInput = input
                                    }}
                                    returnKeyType="next"
                                    textInputStatus={InputStatus.crt_HoursReported}
                                    value={this.state.crt_HoursReported}
                                    width={this.setInputWidth()}
                                />

                                {this.state.crt_HoursReported.length > 0 && (
                                    <Text style={{fontFamily: 'Lato'}}>Points: {pointsToDisplay}</Text>
                                )}
                            </View>
                            <View style={{justifyContent: 'center', width: '100%'}}>
                                <View style={{paddingTop: 30}}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{width: '50%', alignSelf: 'flex-start'}}>
                                            <ABATouchableHighlight
                                                buttonStyle={StyleFunctions.buttonClickInverted()}
                                                onPressEvent={() => {
                                                    NavigationHelper.navigateBack()
                                                }}
                                                text={'Cancel'}
                                                textStyle={{
                                                    color: ColorEnums.danube,
                                                    fontSize: 16,
                                                }}
                                                underlayColor={ColorEnums.white}
                                            />
                                        </View>
                                        <View style={{width: '50%', alignSelf: 'flex-end'}}>
                                            <ABATouchableHighlight
                                                buttonStyle={StyleFunctions.buttonClick()}
                                                icon={'angle-right'}
                                                onPressEvent={() => {
                                                    ReportQIActivitiesLogic.SaveReportingActivity()
                                                }}
                                                text={'Submit'}
                                                textStyle={{
                                                    color: ColorEnums.white,
                                                    fontSize: 16,
                                                }}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ABAContainer>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
