import React, {Component} from 'react'
import {View} from 'react-native'
import mocaMinuteLogic from '../logic/mocaMinuteLogic'
import MocaMinuteLanding from '../../components/mocaMinute/presentation/mocaMinuteLanding'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import NavigationShell from '../../components/presentation/navigationShell'

export default class MocaMinute extends Component {
    state = {
        CallingApi: false,
        CheckPrevious: true,
        CurrentComponentStep: View,
        NavData: null,
        ShowNavShell: true,
    }

    componentDidMount() {
        const {AuthenticationToken, Q1Answered, MDTValue} = this.navigationData
        this.setState({
            CurrentComponentStep: MocaMinuteLanding,
            NavData: {AuthenticationToken, Q1Answered, MDTValue},
        })
    }

    Save() {
        return mocaMinuteContext.child.Save()
    }

    Previous() {
        return mocaMinuteContext.child.Previous()
    }

    render() {
        mocaMinuteContext = this
        mocaMinuteLogic.context = mocaMinuteContext

        HelperMethods.setNavigationData(mocaMinuteContext)

        return mocaMinuteContext.state.ShowNavShell ? (
            <NavigationShell context={mocaMinuteContext} print={true}>
                <mocaMinuteContext.state.CurrentComponentStep
                    ref={child => {
                        mocaMinuteContext.child = child
                    }}
                    NavData={this.state.NavData}
                />
            </NavigationShell>
        ) : (
            <mocaMinuteContext.state.CurrentComponentStep
                ref={child => {
                    mocaMinuteContext.child = child
                }}
                NavData={this.state.NavData}
            />
        )
    }
}
