import PropTypes from 'prop-types'
import React, {Component, useEffect, useRef, useState} from 'react'
import {ActivityIndicator, Platform, Text, View} from 'react-native'
import ABAPortalAPI from '../../../helpers/AxiosInstance'
import styleFunctions from '../../../styles/styleFunctions'
import ABATouchableHighlight from './ABATouchableHighlight'
import {ColorEnums} from '../../../helpers/generalEnums'
import HelperMethods from '../../../helpers/HelperMethods'

export class ABAFileUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploaded: false,
            selected: false,
            uploading: false,
            deleting: false,
            currentFileName: props.filename,
            formData: null,
        }
        this.inputRef = React.createRef()
    }
    componentDidUpdate(previousProps) {
        if (previousProps.filename != this.props.filename && this.props.filename !== null) {
            this.setState({
                currentFileName: this.props.filename,
                uploaded: true,
                selected: true,
            })
        }
    }

    OnFileChange = (file) => {
        let formData = new FormData()
        formData.append('file', file)
        Object.keys(this.props).forEach((field) => {
            formData.append(field, this.props[field])
        })
        this.setState(
            {
                currentFileName: file.name,
                formData,
            },
            () => {
                if (this.props.uploadOnChange) {
                    this.UploadFiles()
                } else {
                    this.setState({selected: true})
                }
            },
        )
    }

    UploadFiles = () => {
        if (!this.state.formData) {
            return
        }
        this.setState({
            uploading: true,
        })

        return ABAPortalAPI.post(this.props.uploadurl, this.state.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                this.setState({uploaded: true, selected: true})
                return response
            })
            .catch((error) => {
                HelperMethods.apiErrorHandler(error, 'Unable to upload file')
            })
            .finally(() => {
                this.setState({uploading: false})
            })
    }

    RemoveFiles = () => {
        if (!this.state.uploaded) {
            this.inputRef.current.value = null
            this.setState({selected: false, currentFileName: null})
            return
        }
        var formData = new FormData()
        Object.keys(this.props).forEach((field) => {
            formData.append(field, this.props[field])
        })
        this.setState({deleting: true})
        ABAPortalAPI.post(this.props.deleteurl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                this.setState({uploaded: false, selected: false, currentFileName: null})
                this.inputRef.current.value = null
            })
            .catch((error) => {
                HelperMethods.apiErrorHandler(error, 'Unable to delete file')
            })
            .finally(() => {
                this.setState({deleting: false})
            })
    }

    render() {
        const {uploaded, selected, uploading, deleting, currentFileName} = this.state
        if (Platform.OS === 'web') {
            return (
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    {(!selected || !this.props.noReplace) && (
                        <ABATouchableHighlight
                            text={`${selected ? 'Replace' : 'Upload'} File`}
                            onPressEvent={() => this.inputRef.current.click()}
                        />
                    )}

                    {selected && (
                        <span
                            class="d-inline-block"
                            tabindex="0"
                            data-toggle="tooltip"
                            title="Delete File"
                        >
                            <ABATouchableHighlight
                                buttonStyle={{backgroundColor: 'red'}}
                                icon={'trash'}
                                onPressEvent={() => {
                                    this.RemoveFiles()
                                }}
                            />
                        </span>
                    )}
                    <Text
                        style={{marginLeft: 15, borderColor: 'grey', borderBottomWidth: 1, width: '200'}}
                    >
                        {currentFileName
                            ? currentFileName
                            : `No file ${this.props.uploadOnChange ? 'uploaded' : 'selected'}.`}
                    </Text>
                    <input
                        ref={this.inputRef}
                        style={{opacity: 0, position: 'absolute', zIndex: -1}}
                        type="file"
                        accept={this.props.acceptedFileTypes}
                        onChange={(e) => {
                            if (e?.target?.files?.length) {
                                this.OnFileChange(e.target.files[0])
                            }
                        }}
                    />
                    {uploading && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Uploading File</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="small" />
                        </View>
                    )}
                    {deleting && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Deleting File</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="small" />
                        </View>
                    )}
                </View>
            )
        }

        return <Text>File upload option is only available from our website.</Text>
    }
}

ABAFileUpload.defaultProps = {
    filename: null,
    acceptedFileTypes:
        '.csv, .doc, .docx, .odp, .ods, .odt, .pdf, .pot, .potm, .potx, .pps, .ppsx, .ppsxm, .ppt, .pptm, .pptx, .rtf, .xls, .xlsx',
    uploadOnChange: true,
    noReplace: false,
}

ABAFileUpload.propTypes = {
    deleteurl: PropTypes.string,
    uploadurl: PropTypes.string,
}
