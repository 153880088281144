import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import clinicalActivityLogicStep5 from '../logic/clinicalActivityLogicStep5'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABATouchableHighlight from '../../../ABACustom/presentation/ABATouchableHighlight'
import ABAAccordion from '../../../ABACustom/presentation/ABAAccordion'
import HelperMethods from '../../../../helpers/HelperMethods'
import ClinicalActivityStep1 from '../presentation/clinicalActivityStep1'
import ClinicalActivityStep2 from '../presentation/clinicalActivityStep2'
import ClinicalActivityStep3 from '../presentation/clinicalActivityStep3'
import ClinicalActivityStep4 from '../presentation/clinicalActivityStep4'
import TrainingPage from '../presentation/trainingPage'
import NCCPathwaypage from '../presentation/nccPathwayPage'
import ACAPathwayPage from '../presentation/acaPathwayPage'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import ACAExamPath1 from '../../ACASteps/presentation/acaExamPath1'
import ACAExamPath2 from '../../ACASteps/presentation/acaExamPath2'
import acaPathwayLogic from './../logic/acaPathwayPageLogic'
import ACAExamPath3 from '../../ACASteps/presentation/acaExamPath3'
import ACAExamPath4 from '../../ACASteps/presentation/acaExamPath4'

const styles = StyleSheet.create({
    certificationComponentTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    certificationComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        paddingBottom: 20,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },
})

export default class ClinicalActivityStep5 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            ReleaseOption: null,
            ComponentWidth: null,
            clinicalActivityData: {},
            cert_acapath,
            referenceIDs: [],
        }

        this.confirmationComponents = []

        if (clinicalActivityContext.state.showNCCPathway) {
            this.confirmationComponents.push({
                ConfirmationComponent: NCCPathwaypage,
                title: 'Neurocritical Care Pathway',
            })
        }

        const {
            shouldCaptureGraduation,
            cert_nccpath: nccPathway,
            ClinicalActivityWorkPrivilege,
        } = clinicalActivityContext.state
        const {Currently_Training_In_Fellowship, Completed_Fellowship_In_NCC} =
            GeneralEnums.nccTrainingPathway
        const {IsRecertification} = this.props
        const {cert_acapath} = this.state.clinicalActivityData
        const showTrainingPage =
            (shouldCaptureGraduation && !IsRecertification) ||
            nccPathway === Currently_Training_In_Fellowship ||
            nccPathway === Completed_Fellowship_In_NCC

        if (showTrainingPage) {
            this.confirmationComponents.push({
                ConfirmationComponent: TrainingPage,
                title: 'Training Information',
            })
        }
        if (ClinicalActivityWorkPrivilege == 6 || ClinicalActivityWorkPrivilege == 5) {
            this.confirmationComponents.push({
                ConfirmationComponent: ClinicalActivityStep1,
                title: 'Practice Requirements',
            })
        }
    }

    componentDidMount() {
        Promise.all([
            clinicalActivityLogicStep5.GetClinicalActivityData(),
            clinicalActivityLogicStep5.GetReferenceIDs(),
        ]).then((values) => {
            clinicalActivityLogicStep5.PopulateVariables(values)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {clinicalActivityData, referenceIDs} = prevState
        const {clinicalActivityData: currentClinicalActivityData, referenceIDs: currentReferenceIDs} =
            this.state
        const {cert_acapath} = currentClinicalActivityData

        if (clinicalActivityData !== currentClinicalActivityData) {
            const {cert_practicetype} = currentClinicalActivityData
            if (cert_practicetype) {
                switch (cert_acapath) {
                    case 1:
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAPathwayPage,
                            title: ' Adult Cardiac Anesthesiology Pathway 1',
                        })
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAExamPath1,
                            title: ' Adult Cardiac Anesthesiology Pathway 2',
                        })
                        this.confirmationComponents.push({
                            ConfirmationComponent: TrainingPage,
                            title: 'Training Information',
                        })
                        break
                    case 3:
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAPathwayPage,
                            title: ' Adult Cardiac Anesthesiology Pathway 1',
                        })
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAExamPath3,
                            title: ' Adult Cardiac Anesthesiology Pathway 2',
                        })
                        this.confirmationComponents.push({
                            ConfirmationComponent: TrainingPage,
                            title: 'Training Information',
                        })
                        break
                    case 4:
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAPathwayPage,
                            title: ' Adult Cardiac Anesthesiology Pathway 1',
                        })
                        this.confirmationComponents.push({
                            ConfirmationComponent: ACAExamPath4,
                            title: ' Adult Cardiac Anesthesiology Pathway 2',
                        })
                        break
                }

                this.confirmationComponents.push({
                    ConfirmationComponent: ClinicalActivityStep1,
                    title: 'Practice Requirements',
                })
                this.confirmationComponents.push({
                    ConfirmationComponent: ClinicalActivityStep2,
                    title: 'Practice Type',
                })
                this.confirmationComponents.push({
                    ConfirmationComponent: ClinicalActivityStep3,
                    title: 'Privileges',
                })
            } else if (cert_acapath == 2) {
                this.confirmationComponents.push({
                    ConfirmationComponent: ACAPathwayPage,
                    title: ' Adult Cardiac Anesthesiology Pathway 1',
                })
                this.confirmationComponents.push({
                    ConfirmationComponent: ACAExamPath2,
                    title: ' Adult Cardiac Anesthesiology Pathway 2',
                })
                this.confirmationComponents.push({
                    ConfirmationComponent: TrainingPage,
                    title: 'Training Information',
                })
            }
            if (referenceIDs !== currentReferenceIDs) {
                if (currentReferenceIDs.length > 0) {
                    this.confirmationComponents.push({
                        ConfirmationComponent: ClinicalActivityStep4,
                        title: 'References',
                    })
                }
            }
        }
    }

    Save() {
        return clinicalActivityLogicStep5.SaveLogic()
    }

    Previous() {
        return clinicalActivityLogicStep5.Previous()
    }

    render() {
        clinicalActivityStep5Context = this
        clinicalActivityLogicStep5.context = clinicalActivityStep5Context
        const {ReviewResult} = clinicalActivityContext.state
        const {cert_acapath} = this.state.clinicalActivityData
        const {ProgramType} = clinicalActivityContext.props
        const approvedResult = 1
        let isEditable = !this.props.IsViewRegistration && ReviewResult !== approvedResult

        if (ProgramType === GeneralEnums.program_type.MOCA2) {
            isEditable = true
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) =>
                    HelperMethods.setComponentWidth(clinicalActivityStep5Context, event)
                }
            >
                {!this.props.IsViewRegistration && cert_acapath != 2 && (
                    <View>
                        <Text>
                            {' '}
                            Page {registrationStepsDisplayContext.state.CAstepCount} of{' '}
                            {registrationStepsDisplayContext.state.CAstepCount}
                        </Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep5Context.state.ComponentWidth,
                                    1,
                                ),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep5Context.state.ComponentWidth,
                                    1,
                                ),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(
                                    clinicalActivityStep5Context.state.ComponentWidth,
                                    0,
                                ),
                            ]}
                        />
                    </View>
                )}
                {!isEditable && ReviewResult === approvedResult && (
                    <View
                        style={{
                            backgroundColor: ColorEnums.pomegranateLight,
                            borderRadius: 4,
                            minHeight: 50,
                            padding: 20,
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{color: ColorEnums.pomegranateDark, fontSize: 20}}>
                            Request has been approved by the ABA. No further edits are allowed.
                        </Text>
                    </View>
                )}

                {this.confirmationComponents.map((componentInfo, accordionIndex) => {
                    return (
                        <View
                            key={`ConfirmationComponent${accordionIndex}`}
                            style={{paddingTop: 20, justifyContent: 'center'}}
                        >
                            {isEditable && (
                                <View
                                    style={{
                                        paddingTop: 20,
                                        zIndex: 100,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                    }}
                                >
                                    <ABATouchableHighlight
                                        buttonStyle={{
                                            alignItems: 'center',
                                            backgroundColor: ColorEnums.white,
                                            borderColor: ColorEnums.danube,
                                            borderRadius: 50,
                                            borderWidth: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            minHeight: 40,
                                        }}
                                        onPressEvent={() =>
                                            clinicalActivityLogicStep5.GoToComponentStep(
                                                componentInfo.ConfirmationComponent,
                                            )
                                        }
                                        textStyle={{
                                            color: ColorEnums.danube,
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                        }}
                                        text={'Edit'}
                                    />
                                </View>
                            )}
                            <ABAAccordion
                                title={componentInfo.title}
                                accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                                accordionTitleStyle={styles.certificationComponentTitle}
                                accordionButtonStyle={styles.certificationComponentTitlebutton}
                                accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                accordionExpanded={true}
                                accordionUnderlayColor={ColorEnums.white}
                                accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                                accordionContent={
                                    <View>
                                        <componentInfo.ConfirmationComponent
                                            CR2ID={clinicalActivityStep5Context.props.CR2ID}
                                            Order={clinicalActivityStep5Context.props.Order}
                                            ProgramType={clinicalActivityStep5Context.props.ProgramType}
                                            isConfirmationPage={true}
                                            IsViewRegistration={this.props.IsViewRegistration}
                                        />
                                        <View
                                            style={[styleFunctions.confirmationOverlay(), {bottom: 0}]}
                                        />
                                    </View>
                                }
                            />
                        </View>
                    )
                })}

                {clinicalActivityStep5Context.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Clinical Activity Page 4</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
