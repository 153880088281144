import React, {Component} from 'react'
import {Text, View, StyleSheet} from 'react-native'

import moment from 'moment'
import PropTypes from 'prop-types'
import ABATextInput from '../ABACustom/presentation/ABATextInput'
import ABATextInputMask from '../ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../ABACustom/presentation/ABATouchableHighlight'
import ABAConfirmationBox from '../ABACustom/presentation/ABAConfirmationBox'
import ABAAccordion from '../ABACustom/presentation/ABAAccordion'
import ABARequiredAsterisk from '../ABACustom/presentation/ABARequiredAsterisk'
import RelapseSubstanceAbuseSubstanceLogic from '../logic/relapseSubstanceAbuseSubstanceLogic'
import {GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import Reference from '../presentation/reference'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },

    confirmationBox: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5FCFF88',
        height: '100%',
        paddingBottom: 20,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 65,
    },
})

export default class RelapseSubstanceAbuseSubstance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ComponentWidth: null,
            originalSubstanceData: null,
            cert_SubstanceBeganDate: '',
            cert_name: '',
            cert_SAsubstanceId: '',
            canEdit: false,
            CallingApi: true,
            showDeleteConfirm: false,
            relapseReferenceIDList: [],
            addNewTreatmentFacility: false,
            addNewReferenceID: '-1',
            referenceMissing: false,
            collapsedReference: null,
            InputStatus: {
                cert_SubstanceBeganDate: GeneralEnums.textInputStatus.default,
                cert_name: GeneralEnums.textInputStatus.default,
            },
        }

        this.RelapseSubstanceAbuseSubstanceLogic = Object.assign({}, RelapseSubstanceAbuseSubstanceLogic)
        this.RelapseSubstanceAbuseSubstanceLogic.context = this
    }

    componentDidMount() {
        this.RelapseSubstanceAbuseSubstanceLogic.GetSubstanceAbuseSubstance().then((SASData) => {
            this.RelapseSubstanceAbuseSubstanceLogic.GetRelapseReferenceIDs().then(
                (relapseReferenceIDs) => {
                    this.RelapseSubstanceAbuseSubstanceLogic.PopulateVariables(
                        SASData,
                        relapseReferenceIDs,
                    )
                },
            )
        })
    }

    hideContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: false})
    }

    showContinue = () => {
        registrationStepsDisplayContext.setState({ShowContinue: true})
    }

    renderReference(reference) {
        const {cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName} = reference
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`

        return (
            <View key={cert_referenceId}>
                <ABAAccordion
                    title={referenceName}
                    accordianID={cert_referenceId}
                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                    accordionTitleStyle={styles.accordionComponentTitle}
                    accordionButtonStyle={styles.accordionTitlebutton}
                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                    accordionExpanded={this.state.collapsedReference === cert_referenceId}
                    toggleCallback={(refId, expanded) =>
                        expanded && this.setState({collapsedReference: refId})
                    }
                    accordionUnderlayColor={ColorEnums.white}
                    accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                    accordionContent={
                        <Reference
                            referenceID={cert_referenceId}
                            isConfirmationPage={this.props.isConfirmationPage}
                            ProgramType={this.props.ProgramType}
                            IsSubstanceAbuseRef={true}
                            IsRelapseRef={true}
                            RenderSaveReferenceButton={false}
                            RenderEditButtons={true}
                            saveCallback={(component, validated) =>
                                this.RelapseSubstanceAbuseSubstanceLogic.UpdateGrid(
                                    this.RelapseSubstanceAbuseSubstanceLogic,
                                    validated,
                                )
                            }
                            cancelCallback={() =>
                                this.RelapseSubstanceAbuseSubstanceLogic.CancelAddReference(
                                    this.RelapseSubstanceAbuseSubstanceLogic,
                                )
                            }
                        />
                    }
                />
            </View>
        )
    }

    renderAddRelapseTreatmentFacilityButton() {
        const {ReferenceIDList} = this.props
        const {addNewTreatmentFacility, ComponentWidth} = this.state
        const {context} = this.RelapseSubstanceAbuseSubstanceLogic
        const buttonStyle = {
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 5,
            paddingTop: 20,
        }
        let addFunction = () => {
            this.hideContinue()
            this.setState({addNewTreatmentFacility: true})
        }
        if (ReferenceIDList.length > 0) {
            addFunction = () => {
                this.hideContinue()
                this.props.ShowReferenceList(context)
            }
        }

        return (
            <View style={buttonStyle}>
                <View style={{marginBottom: ComponentWidth > 600 ? 0 : 10}}>
                    <ABATouchableHighlight
                        onPressEvent={addFunction}
                        text="Add Relapse Treatment Facility"
                    />
                </View>
                {addNewTreatmentFacility && (
                    <ABATouchableHighlight
                        buttonStyle={[styleFunctions.buttonClickInverted(), {marginLeft: 10}]}
                        onPressEvent={() => {
                            this.showContinue()
                            this.setState({addNewTreatmentFacility: false})
                        }}
                        textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                        text={'Cancel'}
                    />
                )}
            </View>
        )
    }

    render() {
        const {
            cert_SubstanceBeganDate,
            cert_name,
            ComponentWidth,
            CallingApi,
            relapseReferenceIDList,
            addNewTreatmentFacility,
            canEdit,
            InputStatus,
            addNewReferenceID,
        } = this.state

        let relapseReferences
        if (relapseReferenceIDList.length > 0) {
            relapseReferences = relapseReferenceIDList.map((reference) => {
                return this.renderReference(reference)
            })
        }

        return (
            <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                {!CallingApi && (
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: ColorEnums.cloudBurst,
                            borderTopWidth: 1,
                            borderTopColor: ColorEnums.cloudBurst,
                            padding: 5,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{width: ComponentWidth > 600 ? '70%' : '100%'}}>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                        },
                                    ]}
                                >
                                    Relapse Date:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? '30%' : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInputMask
                                    errorMessage="Please enter a valid date"
                                    keyboardType="number-pad"
                                    marginLeft={0}
                                    marginRight={0}
                                    maxLength={10}
                                    onChangeText={(cert_SubstanceBeganDate) =>
                                        this.setState({cert_SubstanceBeganDate})
                                    }
                                    options={{mask: '99/99/9999'}}
                                    placeholder="(mm/dd/yyyy)"
                                    returnKeyType="next"
                                    style={styleFunctions.textInput()}
                                    textInputStatus={
                                        canEdit
                                            ? InputStatus.cert_SubstanceBeganDate
                                            : GeneralEnums.textInputStatus.disabled
                                    }
                                    value={cert_SubstanceBeganDate}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{width: ComponentWidth > 600 ? '70%' : '100%'}}>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {
                                            fontSize: 15,
                                            width: '100%',
                                            marginRight: 10,
                                            paddingBottom: ComponentWidth > 600 ? 0 : 5,
                                        },
                                    ]}
                                >
                                    Substance Abused:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: ComponentWidth > 600 ? '30%' : '100%',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ABATextInput
                                    marginLeft={0}
                                    marginRight={0}
                                    onChangeText={(cert_name) => this.setState({cert_name})}
                                    returnKeyType="next"
                                    textInputStatus={
                                        canEdit
                                            ? InputStatus.cert_name
                                            : GeneralEnums.textInputStatus.disabled
                                    }
                                    value={cert_name && cert_name.toString()}
                                    width="100%"
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: ComponentWidth > 600 ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginLeft: 0}]}
                                onPress={
                                    canEdit
                                        ? () =>
                                              this.RelapseSubstanceAbuseSubstanceLogic.UpdateSubstanceAbuseSubstance()
                                        : () => {
                                              this.setState({canEdit: true})
                                          }
                                }
                            >
                                {canEdit ? 'SAVE' : 'EDIT'}
                            </Text>
                            <Text
                                style={styles.accordionTextLinkStyle}
                                onPress={() =>
                                    this.RelapseSubstanceAbuseSubstanceLogic.CancelSubstanceAbuseSubstanceChanges()
                                }
                            >
                                CANCEL
                            </Text>
                            <Text
                                style={styles.accordionTextLinkStyle}
                                onPress={() => {
                                    this.setState({showDeleteConfirm: true})
                                }}
                            >
                                DELETE
                            </Text>
                        </View>

                        {this.state.referenceMissing && (
                            <Text
                                style={{
                                    color: ColorEnums.pomegranate,
                                    fontSize: 16,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                At least one treatment facility must be added
                            </Text>
                        )}

                        {this.renderAddRelapseTreatmentFacilityButton()}

                        {!addNewTreatmentFacility && <View>{relapseReferences}</View>}

                        {addNewTreatmentFacility && (
                            <Reference
                                referenceID={addNewReferenceID}
                                isConfirmationPage={this.props.isConfirmationPage}
                                ProgramType={this.props.ProgramType}
                                NewReference={true}
                                IsSubstanceAbuseRef={true}
                                IsRelapseRef={true}
                                RenderEditButtons={true}
                                saveCallback={(component, validated) =>
                                    this.RelapseSubstanceAbuseSubstanceLogic.UpdateGrid(
                                        this.RelapseSubstanceAbuseSubstanceLogic,
                                        validated,
                                    )
                                }
                                SubstanceAbuseSubstanceID={this.props.SubstanceAbuseSubstanceID}
                            />
                        )}
                    </View>
                )}

                {this.state.showDeleteConfirm && (
                    <View style={styles.confirmationBox}>
                        <ABAConfirmationBox
                            messageText1="Are you sure you want to delete this substance?"
                            messageText2={this.state.cert_name}
                            buttonLeftText="Yes"
                            buttonRightText="No"
                            leftButtonFunction={() =>
                                this.RelapseSubstanceAbuseSubstanceLogic.DeleteSubstance()
                            }
                            rightButtonFunction={() => this.setState({showDeleteConfirm: false})}
                        />
                    </View>
                )}
            </View>
        )
    }
}

RelapseSubstanceAbuseSubstance.propTypes = {
    SubstanceAbuseSubstanceID: PropTypes.string.isRequired,
    UpdateGrid: PropTypes.func,
    ShowReferenceList: PropTypes.func,
    ReferenceIDList: PropTypes.array,
    ProgramType: PropTypes.number,
}
