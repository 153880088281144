import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Linking, Platform, View} from 'react-native'
import WebView from 'react-native-webview'
export default class ABAWebView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            height: 0,
        }
    }

    render() {
        const webViewScript = `
            setTimeout(function() { 
                var windowHeight = Math.max(
                    document.documentElement.clientHeight, 
                    document.documentElement.scrollHeight, 
                    document.body.clientHeight, 
                    document.body.scrollHeight
                );
                window.ReactNativeWebView.postMessage(windowHeight); 
            }, 500);
            true;
        `
        let headHtml = `<html id='htmlContent'>
             <head>
                <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1'>
                <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'>
                <style>
                    table {
                        width: 100% !important;
                        table-layout: auto !important;
                        border-collapse: collapse;
                    }
                    td, th {
                        word-wrap: break-word;
                        max-width: none !important;
                    }   
                    body.mmOptSelected {
                        background-color: #4A8BC9;
                    }
                    body.mmOptUnselected {
                       background-color: #F4F7F8;
                    }
                    div.mmOptSelected > p {
                        color: #ffffff;
                        margin-bottom: 0;
                    }
                    div.mmOptUnselected > p {
                        color: #4A8BC9;
                        margin-bottom: 0;
                    }
                    p {
                        font-family: 'Lato' !important;
                    }
                    p > span {
                        font-family: 'Lato' !important;
                    }
                </style>
             </head>`
        let htmlToRender = `${headHtml}<div style="overflow-x: auto">${this.props.htmlToRender}</div>`

        return (
            <View className="avoid-page-break">
                {Platform.OS === 'web' && (
                    <div
                        style={{minHeight: this.props.minHeight}}
                        dangerouslySetInnerHTML={{__html: htmlToRender}}
                    />
                )}
                {Platform.OS !== 'web' && (
                    <WebView
                        injectedJavaScript={webViewScript}
                        onMessage={(event) => this.setState({height: parseInt(event.nativeEvent.data)})}
                        onNavigationStateChange={(navState) => {
                            if (navState.url.startsWith('http')) {
                                this.webview.stopLoading()
                                Linking.openURL(navState.url)
                                return
                            }
                        }}
                        ref={(ref) => {
                            this.webview = ref
                        }}
                        scrollEnabled={false}
                        source={{html: htmlToRender}}
                        style={{
                            backgroundColor: 'transparent',
                            height: this.state.height,
                            minHeight: this.props.minHeight,
                        }}
                    />
                )}
            </View>
        )
    }
}

ABAWebView.defaultProps = {}

ABAWebView.propTypes = {
    htmlToRender: PropTypes.string.isRequired,
    minHeight: PropTypes.number,
}
