import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import moment from 'moment'
import HelperMethods from '../../../../helpers/HelperMethods'
import ClinicalActivityStep1 from '../presentation/clinicalActivityStep1'
import ClinicalActivityStep5 from '../presentation/clinicalActivityStep5'
import TrainingPage from '../presentation/trainingPage'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import ValidationHelper from '../../../../helpers/ValidationHelper'
import ACAExamPath1 from '../../ACASteps/presentation/acaExamPath1'
import ACAExamPath2 from '../../ACASteps/presentation/acaExamPath2'
import ACAExamPath3 from '../../ACASteps/presentation/acaExamPath3'
import ACAExamPath4 from '../../ACASteps/presentation/acaExamPath4'

const trainingPageLogic = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            trainingPageLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/GetClinicalActivityBegin', {
                Order: this.context.props.Order,
                CR2ID: this.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    trainingPageLogic.context.setState({CallingApi: false})
                })
        })
    },

    UpdateTrainingPage() {
        return new Promise((resolve) => {
            trainingPageLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/updateTrainingPage', {
                Order: trainingPageLogic.context.props.Order,
                CR2ID: trainingPageLogic.context.props.CR2ID,
                re_ProgramType: trainingPageLogic.context.state.re_ProgramType,
                cert_clinicalactivityid:
                    trainingPageLogic.context.state.CAResponseData.cert_clinicalactivityid,
                cert_enrollment_date: trainingPageLogic.context.state.cert_enrollment_date,
                cert_graduation_date: trainingPageLogic.context.state.cert_graduation_date,
                cert_program: trainingPageLogic.context.state.cert_program,
                cert_program_city_and_state: trainingPageLogic.context.state.cert_program_city_and_state,
                cert_hospiceandpalliativemedicinepath:
                    trainingPageLogic.context.state.cert_hospiceandpalliativemedicinepath,
                cert_sleepmedicinepath: trainingPageLogic.context.state.cert_sleepmedicinepath,
                rs_statuscode: trainingPageLogic.context.state.CAResponseData.rs_statuscode,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    trainingPageLogic.context.setState({CallingApi: false})
                })
        })
    },

    ValidatePage() {
        const {
            cert_enrollment_date,
            cert_graduation_date: graduationDate,
            cert_program,
            cert_program_city_and_state,
            InputStatus,
        } = trainingPageLogic.context.state
        const {ProgramType} = trainingPageLogic.context.props
        const {cert_acapath} = clinicalActivityContext.state
        const {Adult_Cardiac_Anesthesiology, Neurocritical_Care_Exam} = GeneralEnums.program_type
        const {error, success} = GeneralEnums.textInputStatus

        if (!cert_program) {
            InputStatus.cert_program = error
        } else {
            InputStatus.cert_program = success
        }

        if (!cert_program_city_and_state) {
            InputStatus.cert_program_city_and_state = error
        } else {
            InputStatus.cert_program_city_and_state = success
        }

        if (!cert_enrollment_date || !ValidationHelper.isDateFormatValid(cert_enrollment_date)) {
            Validated = false
            InputStatus.cert_enrollment_date = error
        } else {
            InputStatus.cert_enrollment_date = success
        }

        const today = moment(new Date(), 'MM/DD/YYYY', true)
        const trainingWindowExceeded = today.clone().subtract(7, 'years').format('MM/DD/YYYY')
        const momentGraduationDate = moment(new Date(graduationDate), 'MM/DD/YYYY')
        const graduationDateExceeded =
            graduationDate &&
            momentGraduationDate.isBefore(trainingWindowExceeded) &&
            ProgramType !== Adult_Cardiac_Anesthesiology &&
            ProgramType !== Neurocritical_Care_Exam
        const isGraduationInFuture =
            today.isSameOrBefore(graduationDate, 'year') && today.year() !== momentGraduationDate.year()

        if (!graduationDate || !ValidationHelper.isDateFormatValid(graduationDate)) {
            Validated = false
            InputStatus.cert_graduation_date = error
        } else if (graduationDateExceeded) {
            Validated = false
            InputStatus.cert_graduation_date = error
            const graduationDateExceededText =
                "Our records indicate that you've exceeded the seven-year window to become subspecialty certified and need to complete four additional months of fellowship training to reestablish your eligibility. If you've already completed your additional training, email our Credentialing team at credentialing@theaba.org to start the registration process."
            trainingPageLogic.context.setState({graduation_error_text: graduationDateExceededText})
        } else if (isGraduationInFuture) {
            Validated = false
            InputStatus.cert_graduation_date = error
            const graduationDateInFutureText =
                'Our records indicate that you have yet not met the training requirement necessary to register for this exam. If you have any questions, please email our Credentialing team at credentialing@theaba.org.'
            trainingPageLogic.context.setState({
                graduation_error_text: graduationDateInFutureText,
            })
        } else {
            InputStatus.cert_graduation_date = success
        }

        for (let key in InputStatus) {
            if (InputStatus[key] === error) {
                trainingPageLogic.context.setState({InputStatus})
                return false
            }
        }
        return true
    },

    PopulateVariables(CAData) {
        const cert_enrollment_date =
            CAData.cert_enrollment_date &&
            moment(new Date(CAData.cert_enrollment_date)).format('MM/DD/YYYY')
        const cert_graduation_date =
            CAData.cert_graduation_date &&
            moment(new Date(CAData.cert_graduation_date)).format('MM/DD/YYYY')
        this.context.setState({
            cert_enrollment_date: cert_enrollment_date || '',
            cert_graduation_date: cert_graduation_date || '',
            cert_program: CAData.cert_program || '',
            cert_program_city_and_state: CAData.cert_program_city_and_state || '',
            re_ProgramType: CAData.re_ProgramType,
            CAResponseData: CAData,
            cert_acapath: CAData.cert_acapath,
        })
    },

    determineNextStep(graduationDate) {
        let shouldGoToNextStep = false
        const {ProgramType} = trainingPageLogic.context.props
        const {cert_acapath} = clinicalActivityContext.state
        const {Neurocritical_Care_Exam, Adult_Cardiac_Anesthesiology} = GeneralEnums.program_type
        const isNCCRegistration = ProgramType === Neurocritical_Care_Exam
        const isACARegistrationPath2 = ProgramType === Adult_Cardiac_Anesthesiology && cert_acapath == 2

        if (isACARegistrationPath2) {
            shouldGoToNextStep = true
        } else if (isNCCRegistration) {
            const today = moment(new Date(), 'MM/DD/YYY', true)
            shouldGoToNextStep = today.isSameOrBefore(graduationDate, 'day')
        }

        return shouldGoToNextStep
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateTrainingPage().then((response) => {
                if (response) {
                    const {cert_graduation_date} = this.context.state
                    const shouldGoToNextRegistrationStep = this.determineNextStep(cert_graduation_date)

                    clinicalActivityContext.setState({
                        graduationDate: cert_graduation_date,
                    })

                    clinicalActivityContext.setState({
                        CurrentComponentStep: shouldGoToNextRegistrationStep
                            ? ClinicalActivityStep5
                            : ClinicalActivityStep1,
                        PreviousComponentStep: TrainingPage,
                        ClinicalActivityID: response,
                    })
                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            const {PreviousComponentStep, cert_acapath} = clinicalActivityContext.state

            if (cert_acapath) {
                let previousComponentStep

                switch (cert_acapath) {
                    case 1:
                        previousComponentStep = ACAExamPath1
                        break
                    case 2:
                        previousComponentStep = ACAExamPath2
                        break
                    case 3:
                        previousComponentStep = ACAExamPath3
                        break
                    case 4:
                        previousComponentStep = ACAExamPath4
                        break
                }

                clinicalActivityContext.setState({
                    CurrentComponentStep: previousComponentStep,
                })
                resolve(false)
            } else if (PreviousComponentStep) {
                clinicalActivityContext.setState({
                    CurrentComponentStep: PreviousComponentStep,
                })
                resolve(false)
            }
            resolve(true)
        })
    },
}

export default trainingPageLogic
