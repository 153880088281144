import HelperMethods from '../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../helpers/AxiosInstance'

const MocaMinuteLandingLogic = {
    context: null,

    GetMocaMinuteDashboardInfo() {
        return new Promise((resolve) => {
            MocaMinuteLandingLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('mocaMinute/getMocaMinuteDashboardInfo')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving MOCA Minute Dashboard Data')
                    resolve(null)
                })
                .finally(function () {
                    MocaMinuteLandingLogic.context.setState({CallingApi: false})
                })
        })
    },

    getMMStatus() {
        return new Promise((resolve) => {
            MocaMinuteLandingLogic.context.setState({CallingApi: true})

            ABAPortalAPI.get('mocaMinute/getMMStatus')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Medical School')
                    resolve(null)
                })
                .finally(function () {
                    MocaMinuteLandingLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default MocaMinuteLandingLogic
