import React, {createContext, useReducer} from 'react'
import {ActionTypes} from '../helpers/generalEnums'

const initialState = {
    enabled: false,
    type: null,
    available: false,
    seenMessage: false,
}

export const biometrics = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SetBiometricsAvailable:
            return {...state, ...action.payload}
        case ActionTypes.SetBiometricsEnabled:
            return {...state, enabled:action.payload}
        case ActionTypes.SetBiometricsSeen:
                    return {...state, seenMessage:action.payload}
        default:
            return state
    }
}

export default biometrics
