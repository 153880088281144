import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import mocaCopyrightReleaseLogic from '../logic/mocaCopyrightReleaseLogic'
import styleFunctions from '../../../styles/styleFunctions'
import ABACheckbox from '../../../components/ABACustom/presentation/ABACheckbox'
import {ColorEnums, GeneralEnums} from '../../../helpers/generalEnums'
import ABAScrollView from '../../../components/ABACustom/presentation/ABAScrollView'

export default class mocaCopyrightRelease extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: false,
        NoteText: '',
        ReleaseData: null,
        TextDisplay: null,
        OverlayText: 'Loading Copyright Release',
        InputStatus: {
            ReleaseOption: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        mocaCopyrightReleaseLogic.GetReleaseData().then((Data) => {
            mocaCopyrightReleaseLogic.PopulateRelease(Data)
            mocaCopyrightReleaseLogic.GetText(Data).then((Text) => {
                mocaCopyrightReleaseContext.setState({TextDisplay: Text})
            })
        })
    }

    Save() {
        return mocaCopyrightReleaseLogic.SaveLogic()
    }

    render() {
        mocaCopyrightReleaseContext = this
        mocaCopyrightReleaseLogic.context = mocaCopyrightReleaseContext

        return (
            <ABAScrollView style={{width: '100%'}}>
                <View>
                    <Text
                        style={[
                            styleFunctions.paragraphText('center'),
                            {
                                fontSize: 14,
                                paddingTop: 10,
                                paddingBottom: 20,
                                width: '90%',
                                fontWeight: 'bold',
                            },
                        ]}
                    >
                        Before proceeding to the next section, please read and acknowledge the following
                        statement:
                    </Text>
                    <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText('justify'),
                                {fontSize: 14, paddingTop: 15, width: '98%', paddingBottom: 20},
                            ]}
                        >
                            {mocaCopyrightReleaseContext.state.TextDisplay}
                        </Text>
                    </View>
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: ColorEnums.cloudBurst,
                            paddingTop: 10,
                            paddingBottom: 25,
                            alignItems: 'center',
                        }}
                    >
                        <View style={{width: '90%', paddingTop: 25}}>
                            <ABACheckbox
                                isChecked={this.state.ReleaseOption}
                                isRequired={true}
                                onChangeEvent={mocaCopyrightReleaseLogic.ReleaseOptionSelected}
                                label="I have read and understand all of the foregoing, including but not limited to the Copyright Policy, as it pertains to my registration for MOCA."
                                labelStyle={{
                                    fontFamily: 'Lato',
                                    color: ColorEnums.cloudBurst,
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                }}
                                validationStatus={
                                    mocaCopyrightReleaseContext.state.InputStatus.ReleaseOption
                                }
                            />
                        </View>
                        <Text
                            style={[
                                styleFunctions.paragraphText('center'),
                                {fontSize: 12, paddingTop: 15, width: '90%'},
                            ]}
                        >
                            By checking the above box, you are affixing your legal electronic signature
                            to this document.
                        </Text>
                    </View>
                    {mocaCopyrightReleaseContext.state.CallingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>{mocaCopyrightReleaseContext.state.OverlayText}</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </View>
            </ABAScrollView>
        )
    }
}
