import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep3 from '../logic/substanceAbuseLogicStep3'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABADropdown from '../../../../components/ABACustom/presentation/ABADropdown'
import ABATextInputMask from '../../../../components/ABACustom/presentation/ABATextInputMask'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABAValidationView from '../../../ABACustom/presentation/ABAValidationView'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import Reference from '../../../presentation/reference'
import ReferenceList from '../../../presentation/referenceList'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },

    accordionTextLinkStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        color: ColorEnums.danube,
        marginLeft: 10,
        textAlign: 'right',
    },
})

export default class SubstanceAbuseStep3 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        treatmentTypes: [],
        InpatientReferenceIDList: [],
        OutpatientReferenceIDList: [],
        aba_initialtreatmenttype: null,
        aba_inpatienttreatbegin: '',
        aba_inpatienttreatend: '',
        aba_outpatienttreatbegin: '',
        aba_outpatienttreatend: '',
        addInpatientReference: false,
        addOutpatientReference: false,
        showReferenceList: false,
        referenceList: [],
        addNewReferenceID: '-1',
        collapsedReference: null,
        InputStatus: {
            aba_initialtreatmenttype: GeneralEnums.textInputStatus.default,
            aba_inpatienttreatbegin: GeneralEnums.textInputStatus.default,
            aba_inpatienttreatend: GeneralEnums.textInputStatus.default,
            aba_outpatienttreatbegin: GeneralEnums.textInputStatus.default,
            aba_outpatienttreatend: GeneralEnums.textInputStatus.default,
            inpatientReference: GeneralEnums.textInputStatus.default,
            outpatientReference: GeneralEnums.textInputStatus.default,
        },
        ErrorMessages: {
            aba_inpatienttreatbegin: 'Please enter a valid date',
            aba_inpatienttreatend: 'Please enter a valid date',
            aba_outpatienttreatbegin: 'Please enter a valid date',
            aba_outpatienttreatend: 'Please enter a valid date',
        },
    }

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        Promise.all([
            SubstanceAbuseLogicStep3.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep3.GetSAInitialTreatTypes(),
            SubstanceAbuseLogicStep3.GetInpatientReferences(),
            SubstanceAbuseLogicStep3.GetOutpatientReferences(),
        ]).then((SAData) => {
            SubstanceAbuseLogicStep3.PopulateVariables(SAData)
        })
    }

    renderReference(reference, referenceType) {
        const {cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName} = reference
        const {Inpatient_Treating_Physicians} = GeneralEnums.cert_referenceType
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`
        const updateGridFunction =
            referenceType === Inpatient_Treating_Physicians
                ? SubstanceAbuseLogicStep3.UpdateInpatientGrid
                : SubstanceAbuseLogicStep3.UpdateOutpatientGrid

        return (
            <View key={cert_referenceId}>
                <ABAAccordion
                    title={referenceName}
                    accordianID={cert_referenceId}
                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                    accordionTitleStyle={styles.accordionComponentTitle}
                    accordionButtonStyle={styles.accordionTitlebutton}
                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                    accordionExpanded={this.state.collapsedReference === cert_referenceId}
                    toggleCallback={(refId, expanded) =>
                        expanded && this.setState({collapsedReference: refId})
                    }
                    accordionUnderlayColor={ColorEnums.white}
                    accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                    accordionContent={
                        <Reference
                            referenceID={cert_referenceId}
                            isConfirmationPage={this.props.isConfirmationPage}
                            ProgramType={this.props.ProgramType}
                            ReferenceType={referenceType}
                            IsSubstanceAbuseRef={true}
                            RenderSaveReferenceButton={false}
                            RenderEditButtons={true}
                            saveCallback={updateGridFunction}
                            cancelCallback={SubstanceAbuseLogicStep3.CancelAddReference}
                        />
                    }
                />
            </View>
        )
    }

    hideContinue = () => {
        registrationStepsDisplayContext.setState({
            ShowContinue: false,
        })
    }

    renderAddInpatientReferenceButton() {
        const {addInpatientReference, OutpatientReferenceIDList} = this.state
        let addFunction = () => {
            this.setState({addInpatientReference: true})
            this.hideContinue()
        }
        if (OutpatientReferenceIDList.length > 0) {
            addFunction = () => {
                this.hideContinue()
                this.setState({
                    showReferenceList: true,
                    addInpatientReference: true,
                    referenceList: OutpatientReferenceIDList,
                })
            }
        }

        if (!this.state.showAddInpatientReferenceButton) {
            return
        }

        return (
            <ABAValidationView
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                inputStatus={this.state.InputStatus.inpatientReference}
                errorMessage="You must add at least 1 Inpatient Reference"
            >
                <ABATouchableHighlight onPressEvent={addFunction} text="Add Inpatient Reference" />
                {addInpatientReference && (
                    <ABATouchableHighlight
                        buttonStyle={[styleFunctions.buttonClickInverted(), {marginLeft: 10}]}
                        onPressEvent={() => {
                            this.setState({addInpatientReference: false})
                            registrationStepsDisplayContext.setState({
                                ShowContinue: true,
                            })
                        }}
                        textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                        text={'Cancel'}
                    />
                )}
            </ABAValidationView>
        )
    }

    renderAddOutpatientReferenceButton() {
        const {addOutpatientReference, InpatientReferenceIDList} = this.state
        let addFunction = () => {
            this.hideContinue()
            this.setState({addOutpatientReference: true})
        }
        if (InpatientReferenceIDList.length > 0) {
            addFunction = () => {
                this.hideContinue()
                this.setState({
                    showReferenceList: true,
                    addOutpatientReference: true,
                    referenceList: InpatientReferenceIDList,
                })
            }
        }
        if (!this.state.showAddOutpatientReferenceButton) {
            return
        }
        return (
            <ABAValidationView
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                inputStatus={this.state.InputStatus.outpatientReference}
                errorMessage="You must add at least 1 Outpatient Reference"
            >
                <ABATouchableHighlight onPressEvent={addFunction} text="Add Outpatient Reference" />
                {addOutpatientReference && (
                    <ABATouchableHighlight
                        buttonStyle={[styleFunctions.buttonClickInverted(), {marginLeft: 10}]}
                        onPressEvent={() => {
                            this.setState({addOutpatientReference: false})
                            registrationStepsDisplayContext.setState({
                                ShowContinue: true,
                            })
                        }}
                        textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                        text={'Cancel'}
                    />
                )}
            </ABAValidationView>
        )
    }

    renderInpatientSection() {
        const {ComponentWidth, InputStatus, InpatientReferenceIDList, addNewReferenceID} = this.state
        const {Inpatient_Treating_Physicians} = GeneralEnums.cert_referenceType
        let inpatientReferences

        if (InpatientReferenceIDList.length > 0) {
            inpatientReferences = InpatientReferenceIDList.map((reference) => {
                return this.renderReference(reference, Inpatient_Treating_Physicians)
            })
        }

        return (
            <View style={{paddingTop: 20}}>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? '25%' : '100%'}}>
                        <Text>Inpatient Treatment</Text>
                    </View>
                    <View
                        style={{
                            flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                            width: ComponentWidth > 600 ? '75%' : '100%',
                        }}
                    >
                        <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                errorMessage={this.state.ErrorMessages.aba_inpatienttreatbegin}
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(aba_inpatienttreatbegin) =>
                                    this.setState({aba_inpatienttreatbegin})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="From (mm/dd/yyyy)"
                                returnKeyType="next"
                                style={styleFunctions.textInput()}
                                textInputStatus={InputStatus.aba_inpatienttreatbegin}
                                value={this.state.aba_inpatienttreatbegin}
                                width="60%"
                            />
                        </View>
                        <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                errorMessage={this.state.ErrorMessages.aba_inpatienttreatend}
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(aba_inpatienttreatend) =>
                                    this.setState({aba_inpatienttreatend})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="To (mm/dd/yyyy)"
                                returnKeyType="next"
                                style={styleFunctions.textInput()}
                                textInputStatus={InputStatus.aba_inpatienttreatend}
                                value={this.state.aba_inpatienttreatend}
                                width="60%"
                            />
                        </View>
                    </View>
                </View>

                <View style={{marginBottom: 10}}>
                    {this.renderAddInpatientReferenceButton()}
                    {this.state.addInpatientReference && (
                        <Reference
                            referenceID={addNewReferenceID}
                            NewReference={true}
                            isConfirmationPage={this.props.isConfirmationPage}
                            ProgramType={this.props.ProgramType}
                            ReferenceType={Inpatient_Treating_Physicians}
                            IsSubstanceAbuseRef={true}
                            RenderEditButtons={true}
                            saveCallback={SubstanceAbuseLogicStep3.UpdateInpatientGrid}
                        />
                    )}
                    {inpatientReferences}
                </View>
            </View>
        )
    }

    renderOutpatientSection() {
        const {ComponentWidth, InputStatus, OutpatientReferenceIDList, addNewReferenceID} = this.state
        const {Outpatient_Treating_Physicians} = GeneralEnums.cert_referenceType
        let outpatientReferences
        if (OutpatientReferenceIDList.length > 0) {
            outpatientReferences = OutpatientReferenceIDList.map((reference) => {
                return this.renderReference(reference, Outpatient_Treating_Physicians)
            })
        }

        return (
            <View style={{paddingTop: 20}}>
                <View
                    style={{
                        flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{width: ComponentWidth > 600 ? '25%' : '100%'}}>
                        <Text>Outpatient Treatment</Text>
                    </View>
                    <View
                        style={{
                            flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                            width: ComponentWidth > 600 ? '75%' : '100%',
                        }}
                    >
                        <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                errorMessage={this.state.ErrorMessages.aba_outpatienttreatbegin}
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(aba_outpatienttreatbegin) =>
                                    this.setState({aba_outpatienttreatbegin})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="From (mm/dd/yyyy)"
                                returnKeyType="next"
                                style={styleFunctions.textInput()}
                                textInputStatus={InputStatus.aba_outpatienttreatbegin}
                                value={this.state.aba_outpatienttreatbegin}
                                width="60%"
                            />
                        </View>
                        <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                            <ABATextInputMask
                                errorMessage={this.state.ErrorMessages.aba_outpatienttreatend}
                                keyboardType="number-pad"
                                marginLeft={0}
                                marginRight={0}
                                maxLength={10}
                                onChangeText={(aba_outpatienttreatend) =>
                                    this.setState({aba_outpatienttreatend})
                                }
                                options={{mask: '99/99/9999'}}
                                placeholder="To (mm/dd/yyyy)"
                                returnKeyType="next"
                                style={styleFunctions.textInput()}
                                textInputStatus={InputStatus.aba_outpatienttreatend}
                                value={this.state.aba_outpatienttreatend}
                                width="60%"
                            />
                        </View>
                    </View>
                </View>

                {this.renderAddOutpatientReferenceButton()}
                {this.state.addOutpatientReference && (
                    <Reference
                        referenceID={addNewReferenceID}
                        isConfirmationPage={this.props.isConfirmationPage}
                        ProgramType={this.props.ProgramType}
                        NewReference={true}
                        ReferenceType={Outpatient_Treating_Physicians}
                        IsSubstanceAbuseRef={true}
                        RenderEditButtons={true}
                        saveCallback={SubstanceAbuseLogicStep3.UpdateOutpatientGrid}
                    />
                )}

                {outpatientReferences}
            </View>
        )
    }

    Save() {
        return SubstanceAbuseLogicStep3.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep3.Previous()
    }

    render() {
        SubstanceAbuseLogicStep3.context = this
        const {ComponentWidth, InputStatus, aba_initialtreatmenttype, showReferenceList, referenceList} =
            this.state

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 2 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.3),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.7),
                            ]}
                        />
                    </View>
                )}

                {showReferenceList && (
                    <View>
                        <ReferenceList
                            ReferenceList={referenceList}
                            AddReference={SubstanceAbuseLogicStep3.AddExistingReference}
                        />
                        <View style={{flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={SubstanceAbuseLogicStep3.AddNewReference}
                            >
                                ADD NEW
                            </Text>
                            <Text
                                style={[styles.accordionTextLinkStyle, {marginTop: 10}]}
                                onPress={() => this.setState({showReferenceList: false})}
                            >
                                CANCEL
                            </Text>
                        </View>
                    </View>
                )}

                {!showReferenceList && (
                    <View>
                        <View
                            style={{
                                flexDirection: ComponentWidth > 600 ? 'row' : 'column',
                                width: '100%',
                            }}
                        >
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, paddingTop: 15, fontWeight: 'bold', width: '90%'},
                                    ]}
                                >
                                    Specify your Initial Treatment Type:
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                            <View style={{width: ComponentWidth > 600 ? '50%' : '100%'}}>
                                <ABADropdown
                                    options={this.state.treatmentTypes}
                                    label="Treatment Type"
                                    placeholder="Select Type"
                                    onChange={(aba_initialtreatmenttype) =>
                                        SubstanceAbuseLogicStep3.updateInitialTreatmentType(
                                            aba_initialtreatmenttype,
                                        )
                                    }
                                    selectedValue={aba_initialtreatmenttype}
                                    validationStatus={InputStatus.aba_initialtreatmenttype}
                                    width="100%"
                                />
                            </View>
                        </View>

                        {aba_initialtreatmenttype == '1' && this.renderInpatientSection()}

                        {aba_initialtreatmenttype == '2' && this.renderOutpatientSection()}

                        {aba_initialtreatmenttype == '3' && (
                            <View>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        {fontSize: 14, padding: 15, fontWeight: 'bold', width: '90%'},
                                    ]}
                                >
                                    You indicated that you were treated as both an inpatient and
                                    outpatient during your initial treatment for substance abuse. Please
                                    identify the specific dates of your inpatient treatment and the
                                    specific dates of your outpatient treatment in the space provided
                                    below.
                                </Text>
                                {this.renderInpatientSection()}
                                {this.renderOutpatientSection()}
                            </View>
                        )}
                    </View>
                )}

                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 2</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}

SubstanceAbuseStep3.propTypes = {
    SetPreviousVisibility: PropTypes.func,
    isConfirmationPage: PropTypes.bool,
    ProgramType: PropTypes.number,
}
