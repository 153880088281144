import {Platform} from 'react-native'

import moment from 'moment'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import SubstanceAbuseStep5 from '../presentation/substanceAbuseStep5'
import SubstanceAbuseStep7 from '../presentation/substanceAbuseStep7'
import ValidationHelper from '../../../../helpers/ValidationHelper'

const SubstanceAbuseLogicStep6 = {
    context: null,

    GetSubstanceAbuseData: function () {
        return new Promise((resolve) => {
            const {SubstanceAbuseID} = substanceAbuseContext.state
            let getParams = {
                params: {
                    SubstanceAbuseID,
                },
            }
            ABAPortalAPI.get(`substanceabuseinfo/getSAData`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Accessing Substance Abuse Record')
                })
        })
    },
    CancelAddReference() {
        SubstanceAbuseLogicStep6.context.setState({
            collapsedReference: null,
        })
    },
    GetAfterCareStatuses: function () {
        return new Promise((resolve) => {
            ABAPortalAPI.get(`lookups/getAfterCareStatuses`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving AfterCare Statuses')
                })
        })
    },

    GetAfterCareReferences: function () {
        const {SubstanceAbuseID} = substanceAbuseContext.state
        const {AfterCare_Location} = GeneralEnums.cert_referenceType
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    SubstanceAbuseID,
                    Type: AfterCare_Location,
                },
            }

            ABAPortalAPI.get(`reference/getReferenceIDs`, getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving AfterCare Reference IDs')
                })
        })
    },

    UpdateGrid: function (componentIdx = null, validated = true) {
        if (!validated) return

        SubstanceAbuseLogicStep6.context.setState({CallingApi: true})
        SubstanceAbuseLogicStep6.GetAfterCareReferences()
            .then((afterCareReferenceIDlist) => {
                SubstanceAbuseLogicStep6.context.setState({
                    afterCareReferenceIDlist,
                    addNewFacilityReference: false,
                    collapsedReference: null,
                })
                registrationStepsDisplayContext.setState({ShowContinue: true})
            })
            .finally(function () {
                SubstanceAbuseLogicStep6.context.setState({CallingApi: false})
            })
    },

    SetAfterCareStatus(cert_aftercare) {
        let ShowContinue
        if (cert_aftercare == -1 || cert_aftercare == 2) {
            ShowContinue = true
        } else {
            ShowContinue = !SubstanceAbuseLogicStep6.context.state.addNewFacilityReference
        }

        SubstanceAbuseLogicStep6.context.setState({cert_aftercare})
        if (cert_aftercare == 2) {
            SubstanceAbuseLogicStep6.context.setState({cert_aftercarecompleted: ''})
        }

        !this.context.props.IsViewRegistration &&
            registrationStepsDisplayContext.setState({ShowContinue})
    },

    PopulateVariables: function (SAData) {
        const [SubstanceAbuseData, afterCareStatuses, afterCareReferenceIDlist] = SAData
        if (SubstanceAbuseData && afterCareStatuses) {
            const {cert_aftercarecompleted, cert_aftercare} = SubstanceAbuseData
            const cert_aftercarecompletedDate =
                cert_aftercarecompleted && moment(new Date(cert_aftercarecompleted)).format('MM/DD/YYYY')

            this.context.setState(
                {
                    afterCareStatuses: afterCareStatuses || [],
                },
                () => {
                    this.context.setState({
                        cert_aftercare: cert_aftercare === null ? '-1' : cert_aftercare,
                        cert_aftercarecompleted: cert_aftercarecompletedDate || '',
                        afterCareReferenceIDlist,
                    })
                },
            )
        }
    },

    Validate() {
        const {error, success} = GeneralEnums.textInputStatus
        let {
            InputStatus,
            cert_aftercare,
            cert_aftercarecompleted,
            cert_aftercarecompleteddue,
            afterCareReferenceIDlist,
        } = SubstanceAbuseLogicStep6.context.state

        let validated = true
        for (let key in InputStatus) {
            InputStatus[key] = GeneralEnums.textInputStatus.default
        }

        if (cert_aftercare === '-1') {
            InputStatus.cert_aftercare = error
            validated = false
        } else {
            InputStatus.cert_aftercare = success
        }

        if (
            cert_aftercare === '0' &&
            (!cert_aftercarecompleted ||
                !ValidationHelper.isDateFormatValid(cert_aftercarecompleted) ||
                cert_aftercarecompleted.length < 10)
        ) {
            InputStatus.cert_aftercarecompleted = error
            validated = false
        } else {
            InputStatus.cert_aftercarecompleted = success
        }

        if (
            cert_aftercare === '1' &&
            (!cert_aftercarecompleteddue ||
                !ValidationHelper.isDateFormatValid(cert_aftercarecompleteddue) ||
                cert_aftercarecompleteddue.length < 10)
        ) {
            InputStatus.cert_aftercarecompleteddue = error
            validated = false
        } else {
            InputStatus.cert_aftercarecompleteddue = success
        }

        if (cert_aftercare != '2' && cert_aftercare !== '-1' && afterCareReferenceIDlist.length <= 0) {
            SubstanceAbuseLogicStep6.context.setState({referencesMissing: true})
            validated = false
        }

        SubstanceAbuseLogicStep6.context.setState({InputStatus})
        return validated
    },

    UpdateSubstanceAbuseStep6: function () {
        return new Promise((resolve) => {
            this.context.setState({CallingApi: true})
            let {cert_aftercare, cert_aftercarecompleted, cert_aftercarecompleteddue} =
                this.context.state

            if (cert_aftercare == 0) {
                cert_aftercarecompleteddue = null
            } else if (cert_aftercare == 1) {
                cert_aftercarecompleted = null
            } else {
                cert_aftercarecompleted = null
                cert_aftercarecompleteddue = null
            }

            ABAPortalAPI.post(`substanceabuseinfo/updateSubstanceAbuseStep6`, {
                cert_substanceabuseId: substanceAbuseContext.state.SubstanceAbuseID,
                cert_aftercare,
                cert_aftercarecompleted,
                cert_aftercarecompleteddue,
                rs_statuscode: substanceAbuseContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Updating Substance Abuse Record')
                    resolve(false)
                })
                .finally(() => {
                    this.context.setState({CallingApi: false})
                })
        })
    },

    SaveLogic() {
        if (!SubstanceAbuseLogicStep6.Validate()) {
            return new Promise((resolve) => {
                resolve(false)
            })
        }

        return new Promise((resolve) => {
            this.UpdateSubstanceAbuseStep6().then((data) => {
                if (!data) {
                    resolve(false)
                    return
                }
                substanceAbuseContext.setState({
                    CurrentComponentStep: SubstanceAbuseStep7,
                })
                resolve(false)
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            substanceAbuseContext.setState({
                CurrentComponentStep: SubstanceAbuseStep5,
            })
            resolve(false)
        })
    },
}

export default SubstanceAbuseLogicStep6
