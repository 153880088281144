import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'

import NonStandardExamLogicStep6 from '../logic/nonStandardExamStep6Logic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABACheckbox from '../../../../components/ABACustom/presentation/ABACheckbox'
import HelperMethods from '../../../../helpers/HelperMethods'

export default class nonStandardExamStep6 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        exam_authDate: '',
        exam_certDate: '',
        InputStatus: {
            exam_authDate: GeneralEnums.textInputStatus.default,
            exam_certDate: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        NonStandardExamLogicStep6.GetNSEData().then((NSEData) => {
            NonStandardExamLogicStep6.PopulateStateVariables(NSEData)
        })
    }

    Save() {
        return NonStandardExamLogicStep6.SaveLogic()
    }

    Previous() {
        return NonStandardExamLogicStep6.Previous()
    }

    render() {
        NonStandardExamLogicStep6.context = this
        const {InputStatus} = this.state

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 4 of 5</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0.8),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(this.state.ComponentWidth, 0.2),
                            ]}
                        />
                    </View>
                )}
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                        marginBottom: 15,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                paddingBottom: 10,
                                fontWeight: 'bold',
                                width: '95%',
                            },
                        ]}
                    >
                        Certification and Authorization
                    </Text>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 10,
                        marginBottom: 15,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 16, paddingTop: 15, paddingBottom: 10, width: '100%'},
                        ]}
                    >
                        I certify that the information entered is true and accurate. If exam
                        accommodations are granted to me include a deviation from the standard exam time
                        schedule, I agree that, from the time I begin the exam until I have completed it,
                        I will not communicate in any way with any other individuals taking the exam and
                        I will not communicate in any way with any such individuals about the content of
                        the exam.
                    </Text>
                    <ABACheckbox
                        checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                        isChecked={this.state.exam_certDate}
                        label={
                            'I have read and understand the Certification statement above as it pertains to my request for Nonstandard Accommodation for an ABA exam.'
                        }
                        onChangeEvent={() => {
                            NonStandardExamLogicStep6.SetCertificationDate()
                        }}
                        validationStatus={InputStatus.exam_certDate}
                        width="100%"
                        isRequired
                    />
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                paddingBottom: 10,
                                width: '100%',
                            },
                        ]}
                    >
                        By checking the above box, you are affixing your legal electronic signature to
                        this document.
                    </Text>
                </View>
                <View style={{paddingBottom: 10, marginBottom: 15}}>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 16, paddingTop: 15, paddingBottom: 10, width: '100%'},
                        ]}
                    >
                        If clarification or further information regarding the documentation provided is
                        needed, I authorize the ABA to contact the specialist(s) who diagnosed my
                        impairment and/or those entities, which have granted me exam accommodation. I
                        authorize such specialists and entities to communicate with the ABA in this
                        regard to provide ABA with such clarification and/or further information. I agree
                        to release and hold harmless each such specialist and entity from liability to me
                        arising out of the giving or release of Information to the ABA in connection with
                        this Request for Exam Accommodation. This release and agreement includes
                        liability for the inaccuracy or untruth of the Information, so long as such
                        Information is provided in good faith. I also release and agree to hold harmless
                        the ABA and its agents and employees, including its directors and officers and
                        examiners, from any liability to me as a result of making such contact with the
                        specialist(s) who diagnosed the impairment and/or with the entities which have
                        granted me exam accommodation, provided such contact was made or conducted in
                        good faith.
                        <Text />
                    </Text>
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 16, paddingTop: 15, paddingBottom: 10, width: '100%'},
                        ]}
                    >
                        <Text style={{fontWeight: 'bold'}}>
                            For Requests for Accommodation on the In-Training Exam:
                        </Text>{' '}
                        If my request for exam accommodation is approved, I authorize the ABA to contact
                        my residency training program director to inform him or her of the request,
                        including any approved accommodations to be arranged on my behalf.
                    </Text>
                    <ABACheckbox
                        checkboxStyle={{width: 15, height: 15, marginRight: 10}}
                        isChecked={this.state.exam_authDate}
                        label={
                            'I have read and understand the Authorization statement above as it pertains to my request for Nonstandard Accommodation for an ABA exam.'
                        }
                        onChangeEvent={() => {
                            NonStandardExamLogicStep6.SetAuthorizationDate()
                        }}
                        validationStatus={InputStatus.exam_authDate}
                        width="100%"
                        isRequired
                    />
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {
                                fontSize: 14,
                                paddingTop: 15,
                                fontWeight: 'bold',
                                paddingBottom: 10,
                                width: '100%',
                            },
                        ]}
                    >
                        By checking the above box, you are affixing your legal electronic signature to
                        this document.
                    </Text>
                </View>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Non-Standard Exam Page 4</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
