import {appConfiguration} from './configuration.json'

export default class configurationProps {
    static API_URL = appConfiguration.API_URL
    static BASE_URL = appConfiguration.BASE_URL
    static ENVIRONMENT = appConfiguration.Environment
    static APP_VERSION = appConfiguration.APP_Version
    static CodePushKey = appConfiguration.CodePushKey
    static SENTRY_DSN = appConfiguration.SENTRY_DSN
}
