import HelperMethods from '../../helpers/HelperMethods'
import ABAPortalAPI from '../../helpers/AxiosInstance'

const QuestionHistoryLogic = {
    context: null,

    getQuestionHistory: function () {
        return new Promise((resolve) => {
            QuestionHistoryLogic.context.setState({callingApi: true})

            ABAPortalAPI.get('mocaMinute/getQuestionHistory')
                .then(function (response) {
                    let organizedQuestions = []

                    QuestionHistoryLogic.context.state.questionCategories.map((category) => {
                        let questionsByCategory = QuestionHistoryLogic.filterQuestionsByCategory(
                            response.data,
                            category.categoryName,
                        )
                        let quarters = QuestionHistoryLogic.getQuestionQuarters(questionsByCategory)

                        organizedQuestions.push({
                            categoryName: category.categoryName,
                            showDetails: false,
                            quarters: quarters,
                            questions: questionsByCategory,
                        })
                    })

                    resolve(organizedQuestions)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Question History')
                    resolve(null)
                })
                .finally(function () {
                    QuestionHistoryLogic.context.setState({callingApi: false})
                })
        })
    },

    filterQuestionsByCategory(questions, topic) {
        if (!questions) {
            return
        }

        return questions.filter((question) => question.Category === topic)
    },

    getQuestionQuarters(questions) {
        if (!questions) {
            return
        }

        let quarters = questions.map((question) => question.Answered)
        quarters = [...new Set(quarters)]

        return quarters
    },
}

export default QuestionHistoryLogic
