import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'
import {ColorEnums} from '../../../helpers/generalEnums'
import styleFunctions from '../../../styles/styleFunctions'
import SubstanceAbuseStep8 from '../substanceabuse/presentation/substanceAbuseStep8'
import substanceAbuseLogic from '../logic/substanceAbuseLogic'
import ABAConfirmationBox from '../../ABACustom/presentation/ABAConfirmationBox'
import ABAModal from '../../ABACustom/presentation/ABAModal'
import SubstanceAbuseStep1 from '../substanceabuse/presentation/substanceAbuseStep1'

export default class SubstanceAbuse extends Component {
    modalRef = React.createRef()
    state = {
        CallingApi: false,
        CheckPrevious: true,
        CurrentComponentStep: null,
        ContactID: null,
        SubstanceAbuseID: null,
        Cert_AlcoholAbuse: null,
        Cert_DrugAbuse: null,
        showPreviousModal: false,
    }

    componentDidMount() {
        substanceAbuseLogic.GetSubstanceAbuseBeginData().then((SAData) => {
            substanceAbuseLogic.PopulateVariables(SAData)
        })
    }

    Save() {
        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({x: 0, y: 0, animated: false})
        this.setState({showPreviousModal: false})
        return substanceAbuseContext.child.Save()
    }

    goPrevious() {
        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({x: 0, y: 0, animated: false})
        registrationStepsDisplayContext.setState({ShowContinue: true})
        this.setState({showPreviousModal: false})
        return this.child.Previous()
    }

    cancelPrevious() {
        this.setState({showPreviousModal: false})
    }

    Previous() {
        skipModalWhenPreviousClicked = [SubstanceAbuseStep1, SubstanceAbuseStep8]
        if (skipModalWhenPreviousClicked.indexOf(this.state.CurrentComponentStep) == -1) {
            this.setState({showPreviousModal: true})
            return new Promise((resolve) => resolve(false))
        } else {
            return this.goPrevious()
        }
    }

    render() {
        substanceAbuseContext = this
        substanceAbuseLogic.context = substanceAbuseContext

        return (
            <View>
                {substanceAbuseContext.state.CurrentComponentStep && (
                    <substanceAbuseContext.state.CurrentComponentStep
                        ref={(child) => {
                            substanceAbuseContext.child = child
                        }}
                        ProgramType={substanceAbuseContext.props.ProgramType}
                        Order={substanceAbuseContext.props.Order}
                        CR2ID={substanceAbuseContext.props.CR2ID}
                        SubmittedSteps={substanceAbuseContext.props.SubmittedSteps}
                        SetPreviousVisibility={this.props.SetPreviousVisibility}
                        IsViewRegistration={substanceAbuseContext.props.IsViewRegistration}
                    />
                )}

                {substanceAbuseContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Statement</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
                {this.state.showPreviousModal && (
                    <ABAModal
                        isVisible={this.state.showPreviousModal}
                        onDismiss={() => this.cancelPrevious()}
                        onBackdropPress={() => this.cancelPrevious()}
                    >
                        <ABAConfirmationBox
                            messageText1="Warning:"
                            messageText2={`Any changes to data will NOT be saved.\n Do you want to continue?`}
                            buttonLeftText="Yes"
                            buttonRightText="No"
                            leftButtonFunction={() => this.goPrevious()}
                            rightButtonFunction={() => this.cancelPrevious()}
                        />
                    </ABAModal>
                )}
            </View>
        )
    }
}
