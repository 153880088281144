import React, {Component} from 'react'
import PropTypes from 'prop-types'

export default class ABAVideoPlayer extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <>
                <video autoPlay loop width={350} style={{textAlign: 'center', alignSelf: 'center'}}>
                    <source src={this.props.url} type="video/mp4" />
                </video>
            </>
        )
    }
}

ABAVideoPlayer.propTypes = {
    url: PropTypes.string.isRequired,
}
