import HelperMethods from '../../../../helpers/HelperMethods'
import ABAPortalAPI from '../../../../helpers/AxiosInstance'

const InvoiceConfirmationLogic = {
    context: null,

    createAndGetInvoice() {
        InvoiceConfirmationLogic.context.setState({CallingApi: true})
        return new Promise((resolve) => {
            let body = {
                EventID: InvoiceConfirmationLogic.context.props.EventID,
                SessionName: `${InvoiceConfirmationLogic.context.props.SessionName} ${InvoiceConfirmationLogic.context.props.SessionDates}`,
            }

            ABAPortalAPI.post('registration/createExamInvoice', body)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error creating or retrieving invoice')
                    resolve(null)
                })
                .finally(function () {
                    InvoiceConfirmationLogic.context.setState({CallingApi: false})
                })
        })
    },

    getMocaInvoiceInfo() {
        InvoiceConfirmationLogic.context.setState({CallingApi: true})
        return new Promise((resolve) => {
            ABAPortalAPI.get('registration/createMocaInvoice')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error creating or retrieving invoice')
                    resolve(null)
                })
                .finally(function () {
                    InvoiceConfirmationLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default InvoiceConfirmationLogic
