import React, { Component } from "react";
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';

import { ColorEnums } from "../../../helpers/generalEnums";

const styles = StyleSheet.create(
    {
        segment: {
            height: 20,
        }
    }
)

export default class ABASegmentedProgressBar extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    RenderFilledSegments() {
        let segments = []
        segmentWidth = 85 / this.props.numberOfSegments + "%";
        for (let counter = 0; counter < Math.floor(this.props.numberOfSegmentsFilled); counter++) {
            segments.push(
                <View key={counter} style={[styles.segment, { backgroundColor: this.props.filledSegmentColor, width: segmentWidth }]}></View>
            )
        }
        return segments;
    }

    RenderEmptySegments() {
        let segments = []
        segmentWidth = 85 / Math.floor(this.props.numberOfSegments) + "%";
        for (let counter = Math.floor(this.props.numberOfSegmentsFilled); counter < Math.floor(this.props.numberOfSegments); counter++) {
            segments.push(
                <View key={counter} style={[styles.segment, { backgroundColor: this.props.emptySegmentColor, width: segmentWidth }]}></View>
            )
        }
        return segments;
    }

    render() {
        return (
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                {this.RenderFilledSegments()}
                {this.RenderEmptySegments()}
            </View>
        )
    }
}

ABASegmentedProgressBar.defaultProps = {
    emptySegmentColor: ColorEnums.backgroundGray,
    filledSegmentColor: ColorEnums.surfGreen
};

ABASegmentedProgressBar.propTypes = {
    emptySegmentColor: PropTypes.string,
    filledSegmentColor: PropTypes.string,
    numberOfSegments: PropTypes.number.isRequired,
    numberOfSegmentsFilled: PropTypes.number.isRequired,
    width: PropTypes.string
};