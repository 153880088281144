import ABAPortalAPI from '../../../../helpers/AxiosInstance'
import HelperMethods from '../../../../helpers/HelperMethods'
import {GeneralEnums} from '../../../../helpers/generalEnums'
import ClinicalActivityStep1 from '../presentation/clinicalActivityStep1'
import NCCPathwayPage from '../presentation/nccPathwayPage'
import TrainingPage from '../presentation/trainingPage'

const nccPathwayPageLogic = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            nccPathwayPageLogic.context.setState({CallingApi: true})

            ABAPortalAPI.post('clinicalactivity/GetClinicalActivityBegin', {
                Order: nccPathwayPageLogic.context.props.Order,
                CR2ID: nccPathwayPageLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    nccPathwayPageLogic.context.setState({CallingApi: false})
                })
        })
    },

    GetNCCPathwayOptions() {
        return new Promise((resolve) => {
            nccPathwayPageLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get(`lookups/getNccPathwayOptions`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Treatment Types')
                })
                .finally(() => {
                    nccPathwayPageLogic.context.setState({CallingApi: false})
                })
        })
    },

    UpdateNCCPathwayPage() {
        return new Promise((resolve) => {
            nccPathwayPageLogic.context.setState({CallingApi: true, saving: true})
            const {cert_nccpath, cert_nccposttraining, cert_clinicalactivityid} = this.context.state
            const {ProgramType: re_ProgramType} = this.context.props
            const {ContactId} = clinicalActivityContext.state

            ABAPortalAPI.post(`clinicalactivity/updateNCCPathwayPage`, {
                re_ProgramType,
                ContactId,
                cert_clinicalactivityid,
                cert_nccpath,
                cert_nccposttraining,
                rs_statuscode: clinicalActivityContext.state.RuleStatus,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Saving Clinical Activity Record')
                    resolve(false)
                })
                .finally(function () {
                    this.context.setState({CallingApi: false, saving: false})
                })
        })
    },

    ValidatePage() {
        const {cert_nccpath, cert_nccposttraining} = this.context.state
        const {Currently_Practicing} = GeneralEnums.nccTrainingPathway
        if (!cert_nccpath) {
            this.context.setState({cert_nccpath_error: true})
            return false
        }

        if (cert_nccpath === Currently_Practicing && !cert_nccposttraining) {
            this.context.setState({cert_nccposttraining_error: true})
            return false
        }

        return true
    },

    PopulateVariables(nccPathwayData) {
        const [clinicalActivityData, pathwayOptionList] = nccPathwayData
        const {cert_nccpath, cert_nccposttraining, cert_clinicalactivityid} = clinicalActivityData
        nccPathwayPageLogic.context.setState({
            pathwayOptionList,
            cert_nccpath,
            cert_nccposttraining,
            cert_clinicalactivityid,
        })
    },

    SaveLogic() {
        return new Promise((resolve) => {
            if (!this.ValidatePage()) {
                resolve(false)
                return
            }

            this.UpdateNCCPathwayPage().then((response) => {
                if (response) {
                    const {cert_nccpath} = nccPathwayPageLogic.context.state
                    const {Currently_Training_In_Fellowship, Completed_Fellowship_In_NCC} =
                        GeneralEnums.nccTrainingPathway

                    const nextComponentStep =
                        cert_nccpath === Currently_Training_In_Fellowship ||
                        cert_nccpath === Completed_Fellowship_In_NCC
                            ? TrainingPage
                            : ClinicalActivityStep1

                    clinicalActivityContext.setState({
                        CurrentComponentStep: nextComponentStep,
                        PreviousComponentStep: NCCPathwayPage,
                        cert_nccpath,
                    })

                    resolve(false)
                } else {
                    resolve(false)
                }
            })
        })
    },

    Previous() {
        return new Promise((resolve) => {
            resolve(true)
        })
    },
}

export default nccPathwayPageLogic
