import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import {Platform} from 'react-native'
import {store} from '../../redux/configureStore'
import {GeneralEnums} from '../../helpers/generalEnums'

const PaymentFormLogic = {
    context: null,

    getPaymentToken() {
        PaymentFormLogic.context.setState({CallingApi: true})
        return new Promise((resolve) => {
            let getParams = {
                params: {
                    invoiceID: PaymentFormLogic.context.navigationData.InvoiceID,
                    source: GeneralEnums.paymentSource[Platform.OS],
                },
            }
            PaymentFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('payment/getPaymentTokenMoca3', getParams)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    PaymentFormLogic.handlePaymentError(error)
                    resolve(null)
                })
                .finally(function () {
                    PaymentFormLogic.context.setState({CallingApi: false})
                })
        })
    },

    handlePaymentError(error) {
        let message = `An error has occurred. We're redirecting you to your home page.`
        switch (error?.response?.status) {
            case 503:
                message =
                    "Our payment processing service is offline now for system maintenance. We'll be back online as soon as possible."
                break
            case 400:
                message = `It looks like you've already clicked "Continue" so we're redirecting you to your home page.`
                break
        }
        HelperMethods.alertAgnostic(message, 'Alert', [{text: 'Ok'}])
        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
    },

    expireUnusedLogs() {
        PaymentFormLogic.context.setState({CallingApi: true})
        return new Promise((resolve) => {
            PaymentFormLogic.context.setState({CallingApi: true})
            ABAPortalAPI.delete('payment/unusedLogs')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    PaymentFormLogic.handlePaymentError(error)
                    resolve(null)
                })
                .finally(function () {
                    PaymentFormLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default PaymentFormLogic
