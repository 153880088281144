import React, {Component} from 'react'
import {ActivityIndicator, StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'

import ABAContainer from '../ABACustom/presentation/ABAContainer'
import {ColorEnums, IconEnums, GeneralEnums} from '../../helpers/generalEnums'
import medlicenseLogic from '../logic/medlicenseLogic'
import styleFunctions from '../../styles/styleFunctions'
import NavigationHelper from '../../helpers/NavigationHelper'

export default class MedLicense extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            medicalLicenseTextHeader: {
                color: ColorEnums.blueGray,
                fontFamily: 'Lato',
                fontSize: 16,
                width: '50%',
            },
        })

        this.state = {
            CallingApi: false,
            medLicenseComponents: [],
        }
    }

    componentDidMount() {
        medlicenseLogic.GetMedicalLicenseDisplayData().then((components) => {
            medLicenseContext.setState({medLicenseComponents: components})
        })
    }

    render() {
        medLicenseContext = this
        medlicenseLogic.context = medLicenseContext

        return (
            <ABAContainer
                activityText={'Loading Medical Licenses'}
                showActivityIndicator={this.state.CallingApi}
                showContainerTitleBar={false}
                useMaxWidth={this.props.useMaxWidth}
                overrideWidth={this.props.overrideWidth}
            >
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.blueGray,
                        flexDirection: 'row',
                    }}
                >
                    <Text style={this.styles.medicalLicenseTextHeader}>State</Text>
                    <Text style={this.styles.medicalLicenseTextHeader}>Status</Text>
                </View>

                {this.state.medLicenseComponents.map((medLicense, index) => {
                    let iconColor
                    switch (medLicense.IconCharName) {
                        case 'check':
                            iconColor = ColorEnums.lime
                            break
                        case 'clock':
                            iconColor = ColorEnums.citrus
                            break
                        case 'exclamation_triangle':
                            iconColor = ColorEnums.citrus
                            break
                        case 'times':
                        default:
                            iconColor = ColorEnums.pomegranate
                            break
                    }

                    return (
                        <TouchableWithoutFeedback
                            key={'MedicalLicenseBody' + index}
                            onPress={() => {
                                NavigationHelper.GoToPage(
                                    GeneralEnums.navigationType.push,
                                    '/editMedicalLicense',
                                    {
                                        medicalLicenseId: medLicense.MedicalLicenseId,
                                    },
                                )
                            }}
                            testID={'medLicenseBody' + medLicense.MedicalLicenseId}
                        >
                            <View
                                style={{
                                    borderBottomColor: ColorEnums.aquaHaze,
                                    borderBottomWidth: 1,
                                    flexDirection: 'row',
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                }}
                            >
                                <View style={{width: '50%'}}>
                                    <Text style={styleFunctions.containerTextBold()}>
                                        {medLicense.StateName}
                                    </Text>
                                </View>
                                <View style={{flexDirection: 'row', width: '50%'}}>
                                    <Text style={styleFunctions.containerText()}>
                                        {medLicense.DisplayName}
                                    </Text>
                                    <View style={{alignItems: 'flex-end', flex: 1, paddingRight: 20}}>
                                        <Text
                                            style={{
                                                color: iconColor,
                                                fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                                fontSize: 18,
                                            }}
                                        >
                                            {IconEnums[medLicense.IconCharName]}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </TouchableWithoutFeedback>
                    )
                })}
                {this.props.location.pathname !== '/myProgressReport' && (
                    <TouchableWithoutFeedback
                        onPress={() => {
                            NavigationHelper.GoToPage(
                                GeneralEnums.navigationType.push,
                                '/medicalLicense',
                            )
                        }}
                        testID={'medLicenseFooter'}
                    >
                        <View>
                            <Text style={{color: ColorEnums.danube, fontSize: 14, paddingTop: 15}}>
                                <Text style={{fontFamily: GeneralEnums.fontAwesomeFamilyName}}>
                                    {IconEnums['file-medical']}
                                </Text>{' '}
                                My Medical Licenses
                            </Text>
                        </View>
                    </TouchableWithoutFeedback>
                )}
            </ABAContainer>
        )
    }
}

MedLicense.defaultProps = {
    useMaxWidth: true,
    overrideWidth: false,
    location: {
        pathname: undefined,
    },
}
