import React, {Component} from 'react'
import {View, ActivityIndicator, Text, StyleSheet} from 'react-native'
import NavigationHelper from '../../../helpers/NavigationHelper'
import {GeneralEnums, ColorEnums} from '../../../helpers/generalEnums'
import NonStandardExamLogic from '../logic/nonStandardExamLogic'
import NonStandardExamStep1 from '../NSESteps/presentation/nonStandardExamStep1'
import NonStandardExamStep7 from '../NSESteps/presentation/nonStandardExamStep7'
import styleFunctions from '../../../styles/styleFunctions'
import ABAConfirmationBox from '../../ABACustom/presentation/ABAConfirmationBox'
import ABAModal from '../../ABACustom/presentation/ABAModal'

export default class NonStandardExam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            CheckPrevious: true,
            CurrentComponentStep: null,
            NonStandardExamID: null,
            ExamLabel: null,
            showPreviousModal: false,
            showExistingNSERequestModal: false,
        }

        this.NonStandardExamLogic = Object.assign({}, NonStandardExamLogic)
        this.NonStandardExamLogic.context = this
    }

    componentDidMount() {
        this.getNSEData()
    }

    componentDidUpdate(previousProps) {
        if (this.props.Order !== previousProps.Order) {
            this.getNSEData()
        }
    }

    getNSEData() {
        this.NonStandardExamLogic.GetNSEBeginData().then((NSEData) => {
            this.NonStandardExamLogic.PopulateVariables(NSEData)
        })
    }

    Save() {
        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
            x: 0,
            y: 0,
            animated: false,
        })
        this.setState({showPreviousModal: false})
        return this.child.Save()
    }

    goPrevious() {
        registrationStepsDisplayContext.refs.RegStepScrollView.scrollTo({
            x: 0,
            y: 0,
            animated: false,
        })
        this.setState({showPreviousModal: false})
        return this.child.Previous()
    }

    Previous() {
        skipModalWhenPreviousClicked = [NonStandardExamStep1, NonStandardExamStep7]
        if (skipModalWhenPreviousClicked.indexOf(this.state.CurrentComponentStep) == -1) {
            this.setState({showPreviousModal: true})
            return new Promise((resolve) => resolve(false))
        } else {
            return this.goPrevious()
        }
    }

    cancelPrevious() {
        this.setState({showPreviousModal: false})
    }

    returnToDashboard() {
        this.setState({
            showExistingNSERequestModal: false,
        })
        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/dashboard')
    }

    render() {
        nonStandardExamContext = this
        return (
            <View>
                <View>
                    {this.state.CurrentComponentStep && (
                        <this.state.CurrentComponentStep
                            ref={(child) => {
                                this.child = child
                            }}
                            ProgramType={this.props.ProgramType}
                            Order={this.props.Order}
                            CR2ID={this.props.CR2ID}
                            SubmittedSteps={this.props.SubmittedSteps}
                            IsFromDashboard={this.props.IsFromDashboard}
                            IsViewRegistration={this.props.IsViewRegistration}
                        />
                    )}

                    {this.state.CallingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Loading NSE</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </View>
                {this.state.showPreviousModal && (
                    <ABAModal
                        isVisible={this.state.showPreviousModal}
                        onDismiss={() => this.cancelPrevious()}
                        onBackdropPress={() => this.cancelPrevious()}
                    >
                        <ABAConfirmationBox
                            messageText1="Warning:"
                            messageText2={`Any changes to data will NOT be saved.\n Do you want to continue?`}
                            buttonLeftText="Yes"
                            buttonRightText="No"
                            leftButtonFunction={() => this.goPrevious()}
                            rightButtonFunction={() => this.cancelPrevious()}
                        />
                    </ABAModal>
                )}
                {this.state.showExistingNSERequestModal && (
                    <ABAModal
                        isVisible={this.state.showExistingNSERequestModal}
                        onDismiss={() => this.returnToDashboard()}
                        onBackdropPress={() => this.returnToDashboard()}
                    >
                        <ABAConfirmationBox
                            messageText2={`You’ve already submitted an NSE request for this exam and year. Please contact us at accommodations@theaba.org for an update.`}
                            singleButtonText="OK"
                            singleButtonFunction={() => this.returnToDashboard()}
                        />
                    </ABAModal>
                )}
            </View>
        )
    }
}
