import React, {Component} from 'react'
import {ActivityIndicator, Text, View, TouchableWithoutFeedback, StyleSheet} from 'react-native'
import EventSelectionLogic from '../logic/eventSelectionLogic.js'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import HelperMethods from '../../../../helpers/HelperMethods.js'

export default class EventSelection extends Component {
    state = {
        CallingApi: false,
        AvailableSessions: [],
    }

    componentDidMount() {
        this.props.SetContinueVisibility && this.props.SetContinueVisibility(false)
        EventSelectionLogic.getAvailableSessions().then((sessions) => {
            this.setState({AvailableSessions: sessions})
        })
    }

    Previous() {
        this.props.SetContinueVisibility && this.props.SetContinueVisibility(true)
        return new Promise((resolve) => {
            resolve(true)
        })
    }

    handleSelectSession(session) {
        EventSelectionLogic.getAvailableSessions().then((sessions) => {
            let foundSession = undefined
            sessions.forEach((sessionYear) => {
                foundSession =
                    foundSession ||
                    sessionYear.find(
                        (sessionSearch) => sessionSearch.evt_eventid === session.evt_eventid,
                    )
            })
            if (!foundSession) {
                this.setState({AvailableSessions: sessions})
                HelperMethods.alertAgnostic(
                    'Sorry, that exam session is no longer available. Please select a different exam session.',
                    'Registration Error',
                    [{text: 'Ok'}],
                )
                return
            }

            this.props.SetContinueVisibility(true)
            if (session.IsRankedChoiceEvent) {
                this.props.PaymentAndRegistrationContext.goToRankedChoiceSelection(
                    session.evt_eventid,
                    session.SessionName,
                    `${session.EvtStartDate} - ${session.EvtEndDate}`,
                )
                return
            }
            this.props.PaymentAndRegistrationContext.goToInvoiceConfirmation(
                session.evt_eventid,
                session.SessionName,
                `${session.EvtStartDate} - ${session.EvtEndDate}`,
            )
        })
    }

    getSessionDisplay(session, i) {
        const columnWidths = StyleSheet.create({
            column1: {width: '33%'},
            column2: {width: '33%'},
            column3: {width: '34%'},
        })
        return (
            <View
                key={i}
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    paddingBottom: 10,
                    paddingTop: 15,
                    borderBottomWidth: 2,
                    borderBottomColor: ColorEnums.lightGray,
                }}
            >
                <View style={{flex: 1}}>
                    <View style={{width: '100%'}}>
                        <Text
                            style={[styleFunctions.paragraphText(), {fontSize: 14, fontWeight: 'bold'}]}
                        >
                            {session.SessionName}
                        </Text>
                        <Text style={[styleFunctions.paragraphText(), {fontSize: 14}]}>
                            {session.EvtStartDate} - {session.EvtEndDate}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            paddingBottom: 3,
                            paddingTop: 5,
                        }}
                    >
                        <Text
                            style={[
                                styleFunctions.columnHeader(),
                                columnWidths.column1,
                                {fontFamily: 'Lato', fontSize: 14},
                            ]}
                        >
                            Exam Fee
                        </Text>
                        <Text
                            style={[
                                styleFunctions.columnHeader(),
                                columnWidths.column2,
                                {fontFamily: 'Lato', fontSize: 14},
                            ]}
                        >
                            Late Fee
                        </Text>
                        <Text
                            style={[
                                styleFunctions.columnHeader(),
                                columnWidths.column3,
                                {fontFamily: 'Lato', fontSize: 14},
                            ]}
                        >
                            Total
                        </Text>
                    </View>
                    <View style={{width: '100%', flexDirection: 'row'}}>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                columnWidths.column1,
                                {fontSize: 14, alignSelf: 'center'},
                            ]}
                        >
                            ${session.EventAmount.toFixed(2)}
                        </Text>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                columnWidths.column2,
                                {fontSize: 14, alignSelf: 'center'},
                            ]}
                        >
                            ${session.LateFee.toFixed(2)}
                        </Text>
                        <Text
                            style={[
                                styleFunctions.paragraphText(),
                                columnWidths.column3,
                                {fontSize: 14, alignSelf: 'center'},
                            ]}
                        >
                            ${(session.EventAmount + session.LateFee).toFixed(2)}
                        </Text>
                    </View>
                </View>
                <TouchableWithoutFeedback
                    onPress={() => {
                        this.handleSelectSession(session)
                    }}
                    testID={`selectSession${i}`}
                >
                    <View style={{flexDirection: 'row'}}>
                        <Text
                            style={[styleFunctions.actionText(), {marginLeft: 20, fontWeight: 'bold'}]}
                        >
                            Select
                        </Text>
                        <Text
                            style={[
                                styleFunctions.actionText(),
                                {fontFamily: GeneralEnums.fontAwesomeFamilyName, paddingLeft: 12},
                            ]}
                        >
                            {IconEnums['chevron-right']}
                        </Text>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    render() {
        EventSelectionContext = this
        EventSelectionLogic.context = EventSelectionContext

        return (
            <View>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, paddingTop: 30, fontWeight: 'bold'},
                    ]}
                >
                    Registration
                </Text>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, paddingTop: 10, paddingBottom: 10},
                    ]}
                >
                    Below is a list of available exam sessions for which you can register, along with the
                    price of that exam. This price does not reflect any credits you may have on account.
                    Once you have selected an exam you will have 10 minutes to complete your
                    registration, including payment. Please have your payment method ready when you
                    select an exam.
                </Text>
                {EventSelectionContext.state.AvailableSessions.map((sessionYear, yearIdx) => {
                    if (sessionYear.length > 0) {
                        return (
                            <View style={{paddingTop: 15, paddingBottom: 25}} key={yearIdx}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'baseline',
                                        borderBottomWidth: 2,
                                        borderBottomColor: ColorEnums.lightGray,
                                    }}
                                >
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {fontSize: 20, fontWeight: 'bold'},
                                        ]}
                                    >
                                        {new Date().getFullYear() + yearIdx}
                                    </Text>
                                    <Text
                                        style={[
                                            styleFunctions.paragraphText(),
                                            {paddingLeft: 5, fontSize: 14, fontWeight: 'bold'},
                                        ]}
                                    >
                                        Available Exam Sessions
                                    </Text>
                                </View>
                                {sessionYear.map((session, i) => {
                                    return this.getSessionDisplay(session, i)
                                })}
                            </View>
                        )
                    }
                })}
                {EventSelectionContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Exam Sessions</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
