import React, {Component} from 'react'
import {StyleSheet, Text, View, Platform} from 'react-native'
import {connect} from 'react-redux'

import ABAContainer from '../ABACustom/presentation/ABAContainer'
import CMELogic from '../logic/cmeLogic'
import CMEActivitiesLinks from './cmeActivitiesLinks'
import CMEDashboardLinks from './cmeDashboardLinks'
import MyProgressLinks from './myProgressLinks'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import StyleFunctions from '../../styles/styleFunctions'

import {setUserRole} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = {setUserRole}
class CME extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            rowContainer: {
                alignItems: 'center',
                borderBottomColor: ColorEnums.aquaHaze,
                borderBottomWidth: 2,
                flexDirection: 'row',
                marginBottom: 20,
            },
            rowLabel: {
                fontWeight: 'bold',
                paddingBottom: 5,
                flex: 1,
            },
            rowValuesContainer: {
                alignItems: 'flex-end',
            },
        })

        this.state = {
            CallingApi: false,
            ComponentWidth: null,
            CMEContent: null,
            RoleId: null,
        }
    }

    componentDidMount() {
        CMELogic.GetCMEDisplayData().then((apiResponse) => {
            this.setState({CMEContent: apiResponse})
        })

        HelperMethods.getUserRole(this.props.userData.userRole, this.props.setUserRole)
    }

    render() {
        CMELogic.context = this
        if (this.state.CMEContent === null) {
            return (
                <ABAContainer
                    activityText={'Loading CME Data'}
                    showActivityIndicator={this.state.CallingApi}
                    showContainerTitleBar={false}
                    useMaxWidth={false}
                >
                    <Text>No Data</Text>
                </ABAContainer>
            )
        }

        return (
            <ABAContainer
                activityText={'Loading CME Data'}
                showActivityIndicator={this.state.CallingApi}
                showContainerTitleBar={false}
                useMaxWidth={false}
            >
                <View onLayout={(event) => HelperMethods.setComponentWidth(this, event)}>
                    <View style={this.styles.rowContainer}>
                        <Text style={[StyleFunctions.containerTextBold(), this.styles.rowLabel]}>
                            Category 1 CME Credits Earned
                        </Text>
                        <View style={this.styles.rowValuesContainer}>
                            <Text style={StyleFunctions.containerTextBold()}>
                                {this.state.CMEContent.TotalCMENonDiplomateCredits.toFixed(2)}
                            </Text>
                        </View>
                    </View>

                    <View style={this.styles.rowContainer}>
                        <Text style={[StyleFunctions.containerTextBold(), this.styles.rowLabel]}>
                            Patient Safety CME Credits
                        </Text>
                        <View style={this.styles.rowValuesContainer}>
                            <Text style={StyleFunctions.containerTextBold()}>
                                {this.state.CMEContent.TotalPSNonDiplomateCredits.toFixed(2)}
                            </Text>
                        </View>
                    </View>

                    {this.props.DashboardDisplay ? (
                        <CMEDashboardLinks
                            RetiredRevoked={
                                this.props.userData.userRole === GeneralEnums.roleEnums.retiredRevoked
                            }
                        />
                    ) : (
                        <CMEActivitiesLinks SelectedYearForTable={this.props.SelectedYearForTable} />
                    )}
                </View>
            </ABAContainer>
        )
    }
}

CME.defaultProps = {
    ShowActivitiesLink: true,
    SelectedYearForTable: '1900',
}

export default connect(mapStateToProps, mapDispatchToProps)(CME)
