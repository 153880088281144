import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'

import SubstanceAbuseLogicStep7 from '../logic/substanceAbuseLogicStep7'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../../helpers/generalEnums'
import ABABarChart from '../../../../components/ABACustom/presentation/ABABarChart'
import ABATextInputMultiline from '../../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABATouchableHighlight from '../../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAAccordion from '../../../../components/ABACustom/presentation/ABAAccordion'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'
import HelperMethods from '../../../../helpers/HelperMethods'
import Reference from '../../../presentation/reference'

const styles = StyleSheet.create({
    accordionTitlebutton: {
        flex: 1,
        backgroundColor: ColorEnums.white,
    },

    accordionComponentTitle: {
        textAlign: 'left',
        paddingLeft: 10,
        fontSize: 15,
        color: ColorEnums.cloudBurst,
        fontWeight: 'bold',
    },
})

export default class SubstanceAbuseStep7 extends Component {
    state = {
        CallingApi: false,
        ReleaseOption: null,
        ComponentWidth: null,
        cert_otherinfo: '',
        addNewPrincipalFacilityReference: false,
        principalFacilityReferenceIDList: [],
        referencesMissing: false,
        InputStatus: {
            cert_otherinfo: GeneralEnums.textInputStatus.default,
        },
    }

    componentDidMount() {
        this.props.SetPreviousVisibility(true)
        Promise.all([
            SubstanceAbuseLogicStep7.GetSubstanceAbuseData(),
            SubstanceAbuseLogicStep7.GetPrincipalFacilityReferences(),
        ]).then((SAData) => {
            SubstanceAbuseLogicStep7.PopulateVariables(SAData)
        })
    }

    renderReference(reference) {
        const {cert_referenceId, cert_FirstName, cert_MiddleName, cert_LastName} = reference
        const referenceName = cert_MiddleName
            ? `${cert_FirstName} ${cert_MiddleName} ${cert_LastName}`
            : `${cert_FirstName} ${cert_LastName}`
        return (
            <View key={cert_referenceId}>
                <ABAAccordion
                    title={referenceName}
                    accordianID={cert_referenceId}
                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                    accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                    accordionTitleStyle={styles.accordionComponentTitle}
                    accordionButtonStyle={styles.accordionTitlebutton}
                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                    accordionExpanded={this.state.collapsedReference === cert_referenceId}
                    toggleCallback={(refId, expanded) =>
                        expanded && this.setState({collapsedReference: refId})
                    }
                    accordionUnderlayColor={ColorEnums.white}
                    accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                    accordionContent={
                        <Reference
                            referenceID={cert_referenceId}
                            isConfirmationPage={this.props.isConfirmationPage}
                            ProgramType={this.props.ProgramType}
                            IsSubstanceAbuseRef={true}
                            RenderSaveReferenceButton={false}
                            RenderEditButtons={true}
                            saveCallback={SubstanceAbuseLogicStep7.UpdateGrid}
                            cancelCallback={SubstanceAbuseLogicStep7.CancelAddReference}
                        />
                    }
                />
            </View>
        )
    }

    Save() {
        return SubstanceAbuseLogicStep7.SaveLogic()
    }

    Previous() {
        return SubstanceAbuseLogicStep7.Previous()
    }

    render() {
        SubstanceAbuseLogicStep7.context = this
        const {ComponentWidth, principalFacilityReferenceIDList} = this.state
        let principalFacilityReferences
        if (principalFacilityReferenceIDList.length > 0) {
            principalFacilityReferences = principalFacilityReferenceIDList.map((reference) => {
                return this.renderReference(reference)
            })
        }

        return (
            <View
                style={{width: '100%'}}
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
            >
                {!this.props.isConfirmationPage && (
                    <View>
                        <Text> Page 6 of 7</Text>
                        <ABABarChart
                            outerbarstyle={[
                                styleFunctions.chartoutercontainer(),
                                styleFunctions.columnWidth(ComponentWidth, 1),
                            ]}
                            filledbarstyle={[
                                styleFunctions.filledbarbgcolor(),
                                styleFunctions.progressBarFill(),
                                styleFunctions.columnWidth(ComponentWidth, 0.85),
                            ]}
                            emptybarstyle={[
                                styleFunctions.emptybarbgcolor(),
                                styleFunctions.progressBarEmpty(),
                                styleFunctions.columnWidth(ComponentWidth, 0.15),
                            ]}
                        />
                    </View>
                )}

                <View
                    style={{
                        width: '100%',
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                    }}
                >
                    <Text
                        style={[
                            styleFunctions.paragraphText(),
                            {fontSize: 16, paddingTop: 15, fontWeight: 'bold', width: '100%'},
                        ]}
                    >
                        Current Practice of Anesthesiology
                    </Text>
                </View>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, paddingTop: 5, width: '100%'},
                    ]}
                >
                    Please provide the name of the Department Chairperson, Credentials Committee
                    Chairperson or Chief of Staff at the principal hospital or facility where you
                    currently practice anesthesiology, and the complete address and phone number of that
                    hospital or facility.
                    <ABARequiredAsterisk />
                </Text>
                {this.state.referencesMissing && (
                    <Text
                        style={{
                            color: ColorEnums.pomegranate,
                            fontSize: 16,
                            justifyContent: 'center',
                            alignSelf: 'center',
                            paddingBottom: 10,
                        }}
                    >
                        At least one reference must be added
                    </Text>
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{padding: 10}}>
                        <ABATouchableHighlight
                            onPressEvent={() => {
                                this.setState({
                                    addNewPrincipalFacilityReference: true,
                                    referencesMissing: false,
                                })
                                registrationStepsDisplayContext.setState({ShowContinue: false})
                            }}
                            text="Add Principal Facility Reference"
                        />
                    </View>
                    {this.state.addNewPrincipalFacilityReference && (
                        <View style={{padding: 10}}>
                            <ABATouchableHighlight
                                buttonStyle={[styleFunctions.buttonClickInverted()]}
                                onPressEvent={() => {
                                    registrationStepsDisplayContext.setState({ShowContinue: true})
                                    this.setState({addNewPrincipalFacilityReference: false})
                                }}
                                textStyle={{color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 14}}
                                text={'Cancel'}
                            />
                        </View>
                    )}
                </View>
                {!this.state.addNewPrincipalFacilityReference && (
                    <View>{principalFacilityReferences}</View>
                )}
                {this.state.addNewPrincipalFacilityReference && (
                    <Reference
                        referenceID="-1"
                        NewReference={true}
                        isConfirmationPage={this.props.isConfirmationPage}
                        ProgramType={this.props.ProgramType}
                        ReferenceType={GeneralEnums.cert_referenceType.Principal_Facility_Reference}
                        IsSubstanceAbuseRef={true}
                        RenderEditButtons={true}
                        saveCallback={SubstanceAbuseLogicStep7.UpdateGrid}
                    />
                )}
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, paddingTop: 5, width: '100%'},
                    ]}
                >
                    Please submit any{' '}
                    <Text style={{fontWeight: 'bold'}}>other pertinent information</Text> you would like
                    the ABA to consider when it assesses the specific circumstances of your alcohol abuse
                    and/or illegal use of drugs.
                </Text>
                <Text
                    style={[
                        styleFunctions.paragraphText(),
                        {fontSize: 14, fontWeight: 'bold', paddingTop: 15},
                    ]}
                >
                    Details
                </Text>
                <ABATextInputMultiline
                    maxLength={5000}
                    numberOfLines={8}
                    onChangeText={(cert_otherinfo) => this.setState({cert_otherinfo})}
                    value={this.state.cert_otherinfo}
                    textInputStatus={this.state.InputStatus.cert_otherinfo}
                />
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Substance Abuse Page 6</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
