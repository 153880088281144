import React, {Component} from 'react'
import {View, Text} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import NonStandardExam from '../../components/registrationsteps/presentation/nonStandardExam'
import submitNseRequestLogic from '../logic/submitNseRequestLogic'
import styleFunctions from '../../styles/styleFunctions'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight.js'
import {ColorEnums} from '../../helpers/generalEnums'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'

export default class SubmitNSERequest extends Component {
    state = {
        CallingApi: false,
        CurrentComponentStep: NonStandardExam,
        SubmittedSteps: 0,
    }

    render() {
        submitNseRequestContext = this
        submitNseRequestLogic.context = this
        navigationContext = this
        registrationStepsDisplayContext = this
        HelperMethods.setNavigationData(submitNseRequestContext)

        return (
            <NavigationShell context={submitNseRequestContext}>
                <ABAKeyboardAwareScrollView
                    ref="RegStepScrollView"
                    contentContainerStyle={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    style={{flex: 1}}
                >
                    <View
                        style={{
                            width: '95%',
                            maxWidth: 700,
                            padding: 20,
                            alignSelf: 'center',
                        }}
                    >
                        <submitNseRequestContext.state.CurrentComponentStep
                            ref={(child) => {
                                this.child = child
                            }}
                            IsFromDashboard={true}
                        />
                        <Text style={{color: ColorEnums.pomegranate, paddingTop: 10}}>
                            * Required field
                        </Text>
                        <View
                            style={{
                                width: '90%',
                                alignSelf: 'center',
                                paddingTop: 25,
                                paddingBottom: 20,
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '50%'}}>
                                    {this.state.SubmittedSteps > 0 && (
                                        <ABATouchableHighlight
                                            buttonStyle={styleFunctions.buttonClickInverted()}
                                            onPressEvent={submitNseRequestLogic.GetPreviousStep}
                                            textStyle={{
                                                color: ColorEnums.danube,
                                                fontFamily: 'Lato',
                                                fontSize: 16,
                                            }}
                                            text={'Previous'}
                                        />
                                    )}
                                </View>
                                <View style={{width: '50%'}}>
                                    <ABATouchableHighlight
                                        icon={'angle-right'}
                                        onPressEvent={submitNseRequestLogic.GetNextStep}
                                        text={'Continue'}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}
