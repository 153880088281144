import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import {connect} from 'react-redux'
import HelperMethods from '../../helpers/HelperMethods'
import RegistrationLogic from '../logic/registrationLogic'
import styleFunctions from '../../styles/styleFunctions'
import {
    togglePendingPayment,
    setPaymentOffline,
    setRegistrationLoading,
} from '../../redux/ActionCreators'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'

const mapStateToProps = (state) => ({
    registration: state.registration,
})

const mapDispatchToProps = {togglePendingPayment, setPaymentOffline, setRegistrationLoading}
class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            ComponentWidth: null,
            RegistrationContent: null,
            RegistrationScreenComponents: null,
            PaymentInfo: null,
            NumberofCerts: null,
            isMounted: undefined,
        }
    }

    componentDidMount() {
        this.setState({isMounted: true})
        RegistrationLogic.GetNumberOfCertifications().then((numberOfCerts) => {
            this.setState({NumberofCerts: numberOfCerts})
        })
        RegistrationLogic.GetRegistrationDisplay().then((components) => {
            this.setState({RegistrationContent: components})
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.registration.pendingPayment === this.props.registration.pendingPayment) return

        if (!this.props.registration.pendingPayment) {
            var components = await RegistrationLogic.GetRegistrationDisplay(false)
            this.setState({RegistrationContent: components})
            clearInterval(this.state.processingStatusLoop)
            const paymentStillProcessing = this.state.RegistrationScreenComponents.findIndex(
                (registration) =>
                    registration.PaymentExists && registration.CountFinished !== registration.CountTotal,
            )

            if (paymentStillProcessing > -1) {
                setTimeout(async () => {
                    components = await RegistrationLogic.GetRegistrationDisplay(false)
                    this.setState({RegistrationContent: components})
                }, 5000)
            }
        } else {
            this.startPaymentProccessingLoop()
        }
    }

    componentWillUnmount() {
        this.setState({isMounted: false})
        clearTimeout(this.state.processingStatusLoop)
    }

    startPaymentProccessingLoop() {
        var checkStatus = setTimeout(async () => {
            var [registrations, paymentProcessing] = await Promise.all([
                RegistrationLogic.GetRegistrationDisplayData(),
                RegistrationLogic.GetPaymentProcessingInfo(),
            ])
            const isProcessing =
                paymentProcessing ||
                registrations.filter(
                    (registration) =>
                        registration.CR2_statuscode !==
                            GeneralEnums.rulesetStatus_statusCodes.Eligible_to_Enroll &&
                        registration.CountFinished !== registration.CountTotal &&
                        registration.PaymentExists,
                ).length > 0

            if (isProcessing !== this.props.registration.pendingPayment)
                this.props.togglePendingPayment(isProcessing)
            if (isProcessing && this.state.isMounted) {
                this.startPaymentProccessingLoop()
            }
        }, 2000)
        this.setState({processingStatusLoop: checkStatus})
    }

    render() {
        RegistrationLogic.context = this

        return (
            <View
                onLayout={(event) => HelperMethods.setComponentWidth(this, event)}
                style={{paddingBottom: 30, paddingLeft: 20, paddingRight: 20, paddingTop: 30}}
            >
                {RegistrationLogic.PopulateRegistrationGrid(
                    this.state.RegistrationScreenComponents,
                    this.state.PaymentInfo,
                )}
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Registrations</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Registration)
