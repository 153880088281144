import React, { Component } from "react";
import {
    Text,
    View,
} from "react-native";

import ABAAffirmationRadio from "../../components/ABACustom/presentation/ABAAffirmationRadio";
import ABACheckbox from "../../components/ABACustom/presentation/ABACheckbox";
import ABAContainer from "../../components/ABACustom/presentation/ABAContainer";
import ABADropdown from "../../components/ABACustom/presentation/ABADropdown";
import ABAHeader from "../../components/ABACustom/presentation/ABAHeader";
import ABAIncrementDecrement from "../../components/ABACustom/presentation/ABAIncrementDecrement";
import ABATextInput from "../../components/ABACustom/presentation/ABATextInput";
import ABATextInputMask from "../../components/ABACustom/presentation/ABATextInputMask";
import ABATextInputMultiline from "../../components/ABACustom/presentation/ABATextInputMultiline";
import ABATouchableHighlight from "../../components/ABACustom/presentation/ABATouchableHighlight";
import { ColorEnums, GeneralEnums, IconEnums } from "../../helpers/generalEnums";
import HelperMethods from "../../helpers/HelperMethods";
import styleFunctions from "../../styles/styleFunctions";
import NavigationHelper from "../../helpers/NavigationHelper";
import ABAKeyboardAwareScrollView from "../../components/ABACustom/presentation/ABAKeyboardAwareScrollView";

export default class StyleGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ABAAffirmationSelectedValue: "left",
            ABACheckboxDisabled: false,
            ABACheckboxSelected: true,
            ABADropdownValue: "null",
            ABAIncrementDecrementValue: "0",
            ABATextInputValue: "",
            ABATextInputMaskValue: "",
            ABATextInputMultilineValue: "",

            ControlStatus: {
                ABAAffirmationRadio: GeneralEnums.textInputStatus.default,
                ABACheckbox: GeneralEnums.textInputStatus.default,
                ABADropdown: GeneralEnums.textInputStatus.default,
                ABAIncrementDecrement: GeneralEnums.textInputStatus.default,
                ABATextInput: GeneralEnums.textInputStatus.default,
                ABATextInputMask: GeneralEnums.textInputStatus.default,
                ABATextInputMultiline: GeneralEnums.textInputStatus.default,
            }
        }
    }

    cycleStatusValues() {
        let targetStatus = this.state.ControlStatus[Object.keys(this.state.ControlStatus)[0]] + 1;
        if (targetStatus > 4) {
            targetStatus = 1;
        }
        for (let statusProp in this.state.ControlStatus) {
            this.state.ControlStatus[statusProp] = targetStatus;
        }
        this.setState({ ControlStatus: this.state.ControlStatus });
    }

    render() {
        navigationContext = this;

        return (
            <ABAKeyboardAwareScrollView style={{ backgroundColor: ColorEnums.backgroundGray }}>
                <ABAHeader
                    group={GeneralEnums.ABAHeaderGroups.Dashboard}
                />

                <ABAContainer
                    activityText={"Activity Indicator Message"}
                    containerTitle={"STYLE GUIDE"}
                    // containerTitleAlignment={"center"}
                    showActivityIndicator={this.state.CallingApi}
                // showContainerTitleBar={false}
                // useMaxWidth={false}
                >
                    <View style={{ marginBottom: 30 }}>
                        <Text>ABAAffirmationRadio</Text>
                        <ABAAffirmationRadio
                            // errorMessage={"Error Message"}
                            leftOptionContent={"Left Option"}
                            // leftOptionIcon={IconEnums["clock"]}
                            leftOptionOnPress={() => { console.log("Pressed left option"); }}
                            optionValues={{ leftValue: "left", righgtValue: "right" }}
                            rightOptionContent={"Right Option"}
                            // rightOptionIcon={IconEnums["file-alt"]}
                            rightOptionOnPress={() => { console.log("Pressed right option"); }}
                            // selectedButtonStyle={{ backgroundColor: ColorEnums.surfGreen }}
                            // selectedTextStyle={{ color: ColorEnums.citrus }}
                            selectedValue={this.state.ABAAffirmationSelectedValue}
                            // unselectedButtonStyle={{ backgroundColor: ColorEnums.lightGray }}
                            // unselectedTextStyle={{ color: ColorEnums.danube }}
                            validationStatus={this.state.ControlStatus.ABAAffirmationRadio}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABACheckbox</Text>
                        <ABACheckbox
                            // checkboxStyle={{ width: 15, height: 15, marginRight: 10 }}
                            // errorMessage={"Error Message"}
                            isChecked={this.state.ABACheckboxSelected}
                            // isDisabled={this.state.ABACheckboxDisabled}
                            label={"Checkbox Text"}
                            // labelStyle={styleFunctions.containerText()}
                            onChangeEvent={() => { this.setState({ ABACheckboxSelected: !this.state.ABACheckboxSelected }); }}
                            validationStatus={this.state.ControlStatus.ABACheckbox}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABADropdown</Text>
                        <ABADropdown
                            // errorMessage={"Error Message"}
                            // label={"Dropdown Label"}
                            onChange={(value) => { this.setState({ ABADropdownValue: value }); }}
                            options={[{ value: "Value 1", label: "Label 1" }, { value: "Value 2", label: "Label 2" }, { value: "Value 3", label: "Label 3" }]}
                            placeholder={"Placeholder Text"}
                            // placeholderItemValue={"null"}
                            selectedValue={this.state.ABADropdownValue}
                            validationStatus={this.state.ControlStatus.ABADropdown}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABAIncrementDecrement</Text>
                        <ABAIncrementDecrement
                            // allowManualEntry={true}
                            // decrementDisabled={false}
                            // incrementDisabled={false}
                            inputValue={this.state.ABAIncrementDecrementValue}
                            // maxValue={10}
                            // minValue={-10}
                            onChangeText={(value) => { this.setState({ ABAIncrementDecrementValue: value.toString() }); }}
                            status={this.state.ControlStatus.ABAIncrementDecrement}
                        // stepValue={5}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABATextInput</Text>
                        <ABATextInput
                            // errorMessage={"Error Message"}
                            // keyboardType={"default"}
                            // maxLength={10}
                            onChangeText={(value) => { this.setState({ ABATextInputValue: value }); }}
                            // onKeyPress={() => { console.log("ABATextInput key pressed"); }}
                            // onSubmitEditing={() => { console.log("ABATextInput submitted"); }}
                            // placeholder={"Placeholder Text"}
                            // reference={(ABATextInput) => { this.ABATextInput = ABATextInput }}
                            // returnKeyType="next"
                            // secureTextEntry={false}
                            // style={styleFunctions.textInput(ColorEnums.blueGray, 1)} //not supported correctly due to onBlur/onFocus unable to set correct border bottom width
                            textInputStatus={this.state.ControlStatus.ABATextInput}
                            value={this.state.ABATextInputValue}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABATextInputMask</Text>
                        <ABATextInputMask
                            // errorMessage={"Error Message"}
                            // keyboardType={"default"}
                            // label={"Label"}
                            // maxLength={10}
                            onChangeText={(value) => { this.setState({ ABATextInputMaskValue: value }); }}
                            // onSubmitEditing={() => { console.log("ABATextInputMask submitted"); }}
                            options={{ mask: "9999-9999" }}
                            // placeholder={"Placeholder Text"}
                            // refInput={(ABATextInputMask) => { this.ABATextInputMask = ABATextInputMask }}
                            // returnKeyType="next"
                            textInputStatus={this.state.ControlStatus.ABATextInputMask}
                            value={this.state.ABATextInputMaskValue}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABATextInputMultiline</Text>
                        <ABATextInputMultiline
                            // errorMessage={"Error Message"}
                            // keyboardType={"default"}
                            // label={"Label"}
                            // maxHeight={100}
                            maxLength={500}
                            numberOfLines={10}
                            onChangeText={(value) => { this.setState({ ABATextInputMultilineValue: value }); }}
                            // onKeyPress={() => { console.log("ABATextInputMultiline key pressed"); }}
                            // onSubmitEditing={() => { console.log("ABATextInputMultiline submitted"); }}
                            // placeholder={"Placeholder Text"}
                            // reference={(ABATextInputMultiline) => { this.ABATextInputMultiline = ABATextInputMultiline }}
                            // returnKeyType="next"
                            // style={styleFunctions.textInput(ColorEnums.blueGray, 1)} //not supported correctly
                            textInputStatus={this.state.ControlStatus.ABATextInputMultiline}
                            value={this.state.ABATextInputMultilineValue}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <Text>ABATouchableHighlight</Text>
                        <ABATouchableHighlight
                            // buttonStyle={styleFunctions.buttonClick()}
                            buttonStyle={styleFunctions.buttonClickInverted()}
                            icon={"chevron-right"}
                            isDisabled={this.state.ControlStatus.ABAIncrementDecrement === GeneralEnums.textInputStatus.disabled}
                            onPressEvent={() => { console.log("ABATouchableHighlight pressed"); }}
                            text={"Button Text"}
                            textStyle={{ color: ColorEnums.danube, fontFamily: 'Lato', fontSize: 16 }}
                        // underlayColor={ColorEnums.lightBlue}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <ABATouchableHighlight
                            // buttonStyle={styleFunctions.buttonClick()}
                            // icon={"chevron-right"}
                            onPressEvent={() => { this.cycleStatusValues(); }}
                            text={"Change Status"}
                        // textStyle={{ color: 'white', fontFamily: 'Lato', fontSize: 16 }}
                        // underlayColor={ColorEnums.lightBlue}
                        // width={"100%"}
                        />
                    </View>

                    <View style={{ marginBottom: 30 }}>
                        <ABATouchableHighlight
                            onPressEvent={() => { NavigationHelper.goToHomePage() }}
                            text={"Back To Login"}
                        />
                    </View>
                </ABAContainer>
            </ABAKeyboardAwareScrollView>
        )
    }
}