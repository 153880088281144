import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Animated, Easing, Text, View} from 'react-native'

import styleFunctions from '../../../styles/styleFunctions'
import {ColorEnums} from '../../../helpers/generalEnums'

export default class ABACountdownCircle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            circleProgress: new Animated.Value(0),
            secondsRemaining: props.seconds,
            paused: false,
        }

        this.colorRemaining = ColorEnums.surfGreen
        this.radiusMinusBorder = this.props.radius - this.props.ringThickness
        this.decrementSeconds = this.decrementSeconds.bind(this)

        if (this.props.doCountdown === true) {
            this.intervalId = window.setInterval(this.decrementSeconds, 1000)
            this.animateCountdown()
        }
    }

    stop() {
        this.state.circleProgress.stopAnimation()
        this.setState({paused: true})
    }

    animateCountdown() {
        this.state.circleProgress.stopAnimation()
        Animated.timing(this.state.circleProgress, {
            duration: 1000 * this.props.seconds,
            easing: Easing.linear,
            toValue: this.props.seconds,
            useNativeDriver: false,
        }).start()
    }

    decrementSeconds() {
        if (this.state.paused) {
            return
        }
        //offset by 0.1 seconds to avoid rendering at second borders
        let secondsRemaining = Math.floor((this.endTime - new Date() + 100) / 1000)

        this.updateColorRemaining(secondsRemaining)
        this.setState({secondsRemaining: secondsRemaining})

        if (secondsRemaining <= 0) {
            clearInterval(this.intervalId)
            this.props.expirationEvent()
        }
    }

    drawLeftSemiCircle() {
        const rotation = this.state.circleProgress.interpolate({
            inputRange: [0, this.props.seconds / 2, this.props.seconds],
            outputRange: ['180deg', '180deg', '360deg'],
        })

        return (
            <Animated.View
                style={{
                    backgroundColor: this.colorRemaining,
                    borderBottomRightRadius: this.props.radius,
                    borderTopRightRadius: this.props.radius,
                    height: this.props.radius * 2,
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    transform: [
                        {translateX: this.props.radius / 2},
                        {rotate: rotation},
                        {translateX: this.props.radius / 2},
                    ],
                    width: this.props.radius,
                    zIndex: 2,
                }}
            />
        )
    }

    drawRightSemiCircle() {
        if (this.state.secondsRemaining <= this.props.seconds / 2) {
            return (
                <Animated.View
                    style={{
                        backgroundColor: this.props.colorElapsed,
                        borderBottomRightRadius: this.props.radius,
                        borderTopRightRadius: this.props.radius,
                        height: this.props.radius * 2,
                        left: this.props.radius,
                        position: 'absolute',
                        top: 0,
                        width: this.props.radius,
                        zIndex: 3,
                    }}
                />
            )
        }

        const rotation = this.state.circleProgress.interpolate({
            inputRange: [0, this.props.seconds / 2, this.props.seconds],
            outputRange: ['0deg', '180deg', '180deg'],
        })

        return (
            <Animated.View
                style={{
                    backgroundColor: this.colorRemaining,
                    borderBottomRightRadius: this.props.radius,
                    borderTopRightRadius: this.props.radius,
                    height: this.props.radius * 2,
                    overflow: 'hidden',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    transform: [
                        {translateX: this.props.radius / 2},
                        {rotate: rotation},
                        {translateX: this.props.radius / 2},
                    ],
                    width: this.props.radius,
                    zIndex: 1,
                }}
            />
        )
    }

    updateColorRemaining(secondsRemaining) {
        if (secondsRemaining <= this.props.seconds * 0.25) {
            this.colorRemaining = ColorEnums.pomegranate
        } else if (secondsRemaining <= this.props.seconds * 0.5) {
            this.colorRemaining = ColorEnums.citrus
        }
    }

    componentDidMount() {
        if (this.props.doCountdown === true) {
            this.endTime = new Date(new Date().setTime(new Date().getTime() + 1000 * this.props.seconds))
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    renderCaption = () => {
        if (!this.props.caption) {
            return (
                <>
                    <Text style={{...styleFunctions.containerText(), fontSize: 12}}>TIME</Text>
                    <Text style={{...styleFunctions.containerText(), fontSize: 12}}>REMAINING</Text>
                </>
            )
        }
        return (
            <>
                {this.props.caption.split(' ').map((word) => (
                    <Text key={word} style={{...styleFunctions.containerText(), fontSize: 12}}>
                        {word}
                    </Text>
                ))}
            </>
        )
    }

    render() {
        const minutes = Math.floor(this.state.secondsRemaining / 60)
        const seconds = String(this.state.secondsRemaining - minutes * 60).padStart(2, '0')
        return (
            <View style={{flexDirection: 'row'}}>
                {/*
                Render background circle first. This should be the elapsed color because this is always rendered.
                The progress will be semi-circles that spin to reveal more of this background circle.
                The right semi-circle will rotate clockwise until it overlaps 100% with the left semi-circle (progressed halfway) then switch over to background color and be on the right side.
                The left semi-circle will then start to rotate around to reveal more of the background circle on the left side. */}
                <View
                    style={{
                        alignItems: 'center',
                        backgroundColor: this.props.colorElapsed,
                        borderRadius: this.props.radius,
                        height: this.props.radius * 2,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: this.props.radius * 2,
                        zIndex: 0,
                    }}
                >
                    {this.drawRightSemiCircle()}
                    {this.drawLeftSemiCircle()}
                    <View
                        style={{
                            alignItems: 'center',
                            backgroundColor: this.props.colorInnerCircle,
                            borderRadius: this.radiusMinusBorder,
                            height: this.radiusMinusBorder * 2,
                            justifyContent: 'center',
                            overflow: 'hidden',
                            width: this.radiusMinusBorder * 2,
                            zIndex: 4,
                        }}
                    >
                        <Text style={this.props.textStyle}>
                            {this.props.renderMinutes
                                ? `${minutes}:${seconds}`
                                : this.state.secondsRemaining}
                        </Text>
                    </View>
                </View>
                <View style={{justifyContent: 'center', paddingLeft: 7}}>{this.renderCaption()}</View>
            </View>
        )
    }
}

ABACountdownCircle.defaultProps = {
    colorElapsed: ColorEnums.backgroundGray,
    colorInnerCircle: ColorEnums.white,
    doCountdown: true,
    radius: 30,
    renderMinutes: false,
    ringThickness: 10,
    textStyle: styleFunctions.containerText(),
}

ABACountdownCircle.propTypes = {
    colorElapsed: PropTypes.string,
    colorInnerCircle: PropTypes.string,
    doCountdown: PropTypes.bool,
    expirationEvent: PropTypes.func.isRequired,
    radius: PropTypes.number,
    renderMinutes: PropTypes.bool,
    ringThickness: PropTypes.number,
    seconds: PropTypes.number.isRequired,
    textStyle: PropTypes.object,
}
