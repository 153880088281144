import React, {Component} from 'react'
import {
    ActivityIndicator,
    View,
    StyleSheet,
    Text,
    TextInput,
    TouchableHighlight,
    TouchableOpacity,
    Platform,
} from 'react-native'
import moment from 'moment'

import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import ABATouchableHighlight from '../../components/ABACustom/presentation/ABATouchableHighlight'
import ABACheckbox from '../../components/ABACustom/presentation/ABACheckbox'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import CMEExplorerLogic from '../logic/cmeExplorerLogic'

import {IconEnums, GeneralEnums, ColorEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import styleFunctions from '../../styles/styleFunctions'

export default class CMEExplorer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            showResults: false,
            displayedResults: 0,
            generalSearchTabActive: this.props.navData.showKeywordSearchTab,
            searchbarText: '',
            searchResults: [],
            unfilteredSearchResults: [],
            showFilter: false,
            stackResultData: false,
            tier1: 'null',
            tier1_options: [],
            tier2: 'null',
            tier2_options: [],
            tier3: 'null',
            tier3_options: [],
            tier4: {
                testCheckbox: false,
                testCheckbox2: false,
            },
            feeFilters: [],
            feeOptions: [
                {label: 'Yes', value: 1, isChecked: false},
                {label: 'No', value: 0, isChecked: false},
                {label: 'Both', value: 2, isChecked: false},
            ],
            certTypeFilters: [],
            certTypeOptions: [],
            activityFormatFilters: [],
            activityFormatOptions: [],
            activityType: null,
        }

        this.styles = StyleSheet.create({
            grayText: {
                fontFamily: 'Lato',
                color: ColorEnums.blueGray,
            },
            cmeInfoTitle: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 12,
                fontWeight: 'bold',
                paddingRight: 5,
            },
            cmeInfoText: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 12,
                paddingRight: 5,
                alignSelf: 'center',
            },
            inactiveTab: {
                color: '#6D93A9',
                backgroundColor: ColorEnums.exLightGray,
            },
            webInput: {
                paddingLeft: 20,
                width: '75%',
                backgroundColor: ColorEnums.backgroundGray,
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
                paddingTop: 10,
                paddingBottom: 10,
            },
            mobileInput: {
                paddingLeft: 20,
                width: '75%',
                backgroundColor: ColorEnums.backgroundGray,
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
            },
            tabs: {
                width: '50%',
                height: 50,
                justifyContent: 'center',
            },
        })
    }

    componentDidMount() {
        CMEExplorerLogic.getContentOutline().then((content) => {
            let sortTier1 = (arr) => {
                return arr.sort(function (a, b) {
                    if (a.label < b.label) {
                        return -1
                    }
                    if (a.label > b.label) {
                        return 1
                    }

                    return 0 //default return value (no sorting)
                })
            }

            let sortOtherTiers = (arr) => {
                return arr.sort(function (a, b) {
                    // turning to uppercase so that upper and lower case don't matter when sorting
                    let DescriptionA = a.Description.toUpperCase()
                    let DescriptionB = b.Description.toUpperCase()

                    if (DescriptionA < DescriptionB) {
                        return -1
                    }
                    if (DescriptionA > DescriptionB) {
                        return 1
                    }

                    return 0 //default return value (no sorting)
                })
            }

            let tier1_options = sortTier1(content.tier1)
            let tier2_options_total = sortOtherTiers(content.tier2)
            let tier3_options_total = sortOtherTiers(content.tier3)
            let tier4_options_total = sortOtherTiers(content.tier4)

            //if this page is being navigated to from KAR and has tier3 props in the navData, then we need to set each tier's state for the dropdowns
            if (this.props.navData.tier3Id) {
                let tier3_info = tier3_options_total.find((option) => {
                    return option.ID == this.props.navData.tier3Id
                })

                let tier2_info = tier2_options_total.find((option) => {
                    return option.ID == tier3_info.Parent
                })

                let tier1 = this.props.navData.tier1Id
                let tier2 = tier2_info.ID
                let tier3 = this.props.navData.tier3Id

                let tier2_options = this.filterOptions(tier1, tier2_options_total)
                let tier3_options = this.filterOptions(tier2, tier3_options_total)

                // callbacks are being used after each setState in order for us to properly update each dropdown's needed state.
                // if the callbacks aren't being used, then the dropdowns will appear empty
                this.setState(
                    {
                        tier1_options,
                        tier2_options_total,
                        tier3_options_total,
                        tier4_options_total,
                    },
                    () => {
                        this.setState(
                            {
                                tier1,
                                tier2_options,
                            },
                            () => {
                                this.setState(
                                    {
                                        tier2,
                                        tier3_options,
                                    },
                                    () => {
                                        this.searchCMETier3(tier3)
                                    },
                                )
                            },
                        )
                    },
                )
            } else {
                this.setState({
                    tier1_options,
                    tier2_options_total,
                    tier3_options_total,
                    tier4_options_total,
                })
            }
        })

        Promise.all([
            CMEExplorerLogic.getCMECertificationTypes(),
            CMEExplorerLogic.getCMEActivityFormats(),
            CMEExplorerLogic.getEventTypes(),
        ]).then((values) => {
            let [certTypes, activityFormats] = values
            certTypes = certTypes.data.map((certType) => ({
                label: certType,
                value: certType,
                isChecked: false,
            }))
            activityFormats = activityFormats.data.map((activityFormat) => ({
                ...activityFormat,
                isChecked: false,
            }))
            this.setState({certTypeOptions: certTypes, activityFormatOptions: activityFormats})
        })

        if (Platform.OS === 'web' && this.state.generalSearchTabActive) {
            this.searchBar.focus()
        }
    }

    checkAll() {
        let tier4_options = this.state.tier4_options.map((x) => {
            return {label: x.label, isChecked: true, contentOutlineId: x.contentOutlineId}
        })

        this.setState({tier4_options}, this.searchCMETier4(tier4_options))
    }

    uncheckAll() {
        let tier4_options = this.state.tier4_options.map((x) => {
            return {label: x.label, isChecked: false, contentOutlineId: x.contentOutlineId}
        })

        //when unchecking every box, we go back to just having the results from tier3 show up
        this.setState({tier4_options}, this.searchCMETier3(this.state.tier3))
    }

    checkIndividual(index) {
        let tier4_options = [...this.state.tier4_options]
        tier4_options[index].isChecked = !tier4_options[index].isChecked

        this.setState({tier4_options}, this.searchCMETier4(tier4_options))
    }

    searchCME() {
        CMEExplorerLogic.getCMEActivities().then((activities) => {
            this.setState({
                showResults: true,
                searchResults: activities,
                unfilteredSearchResults: activities,
                displayedResults: activities?.length > 20 ? 20 : activities.length,
            })
        })
    }

    searchCMETier3(tier3value) {
        let tier4_options = this.state.tier4_options_total
            .filter((options) => options.Parent == tier3value)
            .map((options) => {
                return {label: options.Description, isChecked: false, contentOutlineId: options.ID}
            })

        if (tier3value !== 'null') {
            CMEExplorerLogic.getCMEActivitiesTier3(tier3value).then((activities) => {
                this.setState({
                    tier3: tier3value,
                    tier4_options,
                    showResults: true,
                    searchResults: activities,
                    displayedResults: activities.length > 20 ? 20 : activities.length,
                })
            })
        } else {
            this.setState({
                tier3: tier3value,
                tier4_options,
            })
        }
    }

    searchCMETier4(tier4_options) {
        let contentOutlineIds = []

        for (let i = 0; i < tier4_options.length; i++) {
            if (tier4_options[i].isChecked === true) {
                contentOutlineIds.push(tier4_options[i].contentOutlineId.toString())
            }
        }

        contentOutlineIds = contentOutlineIds.toString()

        // if our string contains a value, then we're searching tier 4 activities.  If not, then that means no tier 4 ids
        // are marked so we will revert back to showing just the tier 3 results.
        if (/\S/.test(contentOutlineIds)) {
            CMEExplorerLogic.getCMEActivitiesTier4(contentOutlineIds).then((activities) => {
                this.setState({
                    showResults: true,
                    searchResults: activities,
                    displayedResults: activities.length > 20 ? 20 : activities.length,
                })
            })
        } else {
            this.searchCMETier3(this.state.tier3)
        }
    }

    activityFormat(activity) {
        switch (activity) {
            case 1:
                return 'Course'
            case 2:
                return 'Enduring Material'
            case 3:
                return 'Internet Activity Enduring Material'
            case 4:
                return 'Internet Live Course'
            case 5:
                return 'Internet Search and Learning'
            case 6:
                return 'Journal-based CME'
            case 7:
                return 'Test Item Writing'
            case 8:
                return 'Committee Learning'
            case 9:
                return 'Regularly Scheduled Series'
            case 10:
                return 'Manuscript Review'
            case 11:
                return 'Performance Improvement'
            case 13:
                return 'Learning from Teaching'
            default:
                return 'N/A'
        }
    }

    showMoreResults() {
        displayedResults = this.state.displayedResults
        totalResults = this.state.searchResults.length

        if (displayedResults + 20 >= totalResults) {
            this.setState({displayedResults: totalResults})
        } else {
            this.setState({displayedResults: displayedResults + 20})
        }
    }

    resizeResultLayout(event) {
        let componentWidth = event.nativeEvent.layout.width

        //615 is referring to the scrollview's width, not the entire screen size
        if (componentWidth <= 615 && !this.state.stackResultData) {
            this.setState({stackResultData: true})
            return
        }

        if (componentWidth > 615 && this.state.stackResultData) {
            this.setState({stackResultData: false})
            return
        }
    }

    filterOptions(value, totalOptions) {
        let filteredOptions = totalOptions
            .filter((options) => options.Parent == value)
            .map((options) => {
                return {label: options.Description, value: options.ID}
            })

        return filteredOptions
    }

    filterCertType(searchResult) {
        const {certTypeFilters} = this.state

        if (searchResult.AmbulatoryOutpatient && certTypeFilters.includes('Ambulatory/Outpatient')) {
            return true
        }

        if (searchResult.CardiacAnesthesiology && certTypeFilters.includes('Cardiac Anesthesia')) {
            return true
        }

        if (searchResult.CriticalCareMedicine && certTypeFilters.includes('Critical Care Medicine')) {
            return true
        }

        if (
            searchResult.GeneralOperativeAnesthesia &&
            certTypeFilters.includes('General Operative Anesthesia')
        ) {
            return true
        }

        if (
            searchResult.HospiceAndPalliativeMedicine &&
            certTypeFilters.includes('Hospice and Palliative Medicine')
        ) {
            return true
        }

        if (searchResult.NeuroAnesthesia && certTypeFilters.includes('Neuro Anesthesia')) {
            return true
        }

        if (searchResult.NeurocriticalCare && certTypeFilters.includes('Neurocritical Care')) {
            return true
        }

        if (searchResult.ObstetricAnesthesia && certTypeFilters.includes('Obstetric Anesthesia')) {
            return true
        }

        if (searchResult.PainMedicine && certTypeFilters.includes('Pain Medicine')) {
            return true
        }

        if (searchResult.PediatricAnesthesia && certTypeFilters.includes('Pediatric Anesthesia')) {
            return true
        }

        if (
            searchResult.RegionalAnesthesiaAcutePain &&
            certTypeFilters.includes('Regional Anesthesia/Acute Pain')
        ) {
            return true
        }

        if (searchResult.SleepMedicine && certTypeFilters.includes('Sleep Medicine')) {
            return true
        }

        if (searchResult.ThoracicAnesthesia && certTypeFilters.includes('Thoracic Anesthesia')) {
            return true
        }

        if (searchResult.Trauma && certTypeFilters.includes('Trauma')) {
            return true
        }

        return false
    }

    render() {
        CMEExplorerLogic.context = this

        return (
            <ABAKeyboardAwareScrollView
                style={{backgroundColor: ColorEnums.backgroundGray}}
                ref="_scrollView"
                onLayout={(event) => this.resizeResultLayout(event)}
            >
                <ABAHeader heading="CME Explorer" icon={IconEnums['clipboard-list']} />

                <View style={{marginBottom: 15}}>
                    <ABAContainer
                        containerTitle={'Search for CMEs'}
                        sidePadding={0}
                        topPadding={0}
                        useMaxWidth={false}
                    >
                        <View style={{flexDirection: 'row', marginBottom: 30}}>
                            <TouchableHighlight
                                onPress={() =>
                                    !this.state.generalSearchTabActive &&
                                    this.setState({generalSearchTabActive: true})
                                }
                                style={[
                                    this.styles.tabs,
                                    !this.state.generalSearchTabActive && this.styles.inactiveTab,
                                ]}
                                underlayColor={ColorEnums.white}
                                testID="CMEActivityTypeTab"
                            >
                                <Text style={{fontFamily: 'Lato', textAlign: 'center'}}>
                                    CME Activity Type
                                </Text>
                            </TouchableHighlight>
                            <TouchableHighlight
                                onPress={() =>
                                    this.state.generalSearchTabActive &&
                                    this.setState({generalSearchTabActive: false})
                                }
                                style={[
                                    this.styles.tabs,
                                    this.state.generalSearchTabActive && this.styles.inactiveTab,
                                ]}
                                underlayColor={ColorEnums.white}
                                testID="ContentOutlineTab"
                            >
                                <Text style={{fontFamily: 'Lato', textAlign: 'center'}}>
                                    Content Outline Topic Search
                                </Text>
                            </TouchableHighlight>
                        </View>

                        <View
                            style={{
                                maxWidth: 700,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            {this.state.generalSearchTabActive
                                ? this.renderGeneralSearch()
                                : this.renderTopicSearch()}
                        </View>
                    </ABAContainer>
                    {this.state.CallingApi && (
                        // Ternary statements regarding this.state.searchResults are for showing the activity indicator.
                        // If we have a long page of results, this allows the indicator to be at the top instead of centered in our view.
                        <View
                            style={[
                                styleFunctions.loadingOverlay(),
                                this.state.searchResults.length > 0 && {justifyContent: 'flex-start'},
                            ]}
                        >
                            <View style={this.state.searchResults.length > 0 && {paddingTop: 100}}>
                                <Text style={{fontFamily: 'Lato'}}>Searching...</Text>
                                <ActivityIndicator color={ColorEnums.indigo} size="large" />
                            </View>
                        </View>
                    )}
                </View>
            </ABAKeyboardAwareScrollView>
        )
    }

    renderGeneralSearch() {
        return (
            <View style={{width: '100%', minHeight: 400, justifyContent: 'center'}}>
                <View style={{flexDirection: 'row'}}>
                    <View style={Platform.OS === 'web' ? this.styles.webInput : this.styles.mobileInput}>
                        <TextInput
                            onChangeText={(value) => this.setState({searchbarText: value})}
                            onSubmitEditing={() => this.searchCME()}
                            placeholder={'Search for CME Activities'}
                            placeholderTextColor={ColorEnums.blueGray}
                            ref={(ref) => (this.searchBar = ref)}
                            style={[
                                {fontFamily: 'Lato', color: ColorEnums.cloudBurst},
                                Platform.OS === 'ios' && {paddingTop: 10, paddingBottom: 10},
                            ]}
                            underlineColorAndroid="transparent"
                            value={this.state.searchbarText}
                        />
                    </View>
                    <TouchableHighlight
                        style={{
                            width: 90,
                            backgroundColor: ColorEnums.danube,
                            justifyContent: 'center',
                            width: '25%',
                            borderTopRightRadius: 50,
                            borderBottomRightRadius: 50,
                        }}
                        onPress={() => this.searchCME()}
                        testID="CMESearchButton"
                    >
                        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                            <Text style={{fontFamily: 'Lato', color: 'white', paddingRight: 5}}>
                                Search
                            </Text>
                            <Text
                                style={{fontFamily: GeneralEnums.fontAwesomeFamilyName, color: 'white'}}
                            >
                                {IconEnums['search']}
                            </Text>
                        </View>
                    </TouchableHighlight>
                </View>
                <Text
                    style={{
                        fontFamily: 'Lato',
                        color: ColorEnums.blueGray,
                        fontStyle: 'italic',
                        fontSize: 10,
                        textAlign: 'center',
                        paddingTop: 10,
                    }}
                >
                    The ABA does not produce or endorse any CME. We also do not financially benefit from
                    diplomate participation in CME.
                </Text>

                {this.state.generalSearchTabActive && (
                    <View
                        style={{flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15}}
                    >
                        <Text style={[this.styles.grayText, {fontSize: 12, fontWeight: 'bold'}]}>
                            {this.state.searchResults.length} Search Results
                        </Text>
                        <ABATouchableHighlight
                            text="Filters"
                            onPressEvent={() => this.setState({showFilter: !this.state.showFilter})}
                        />
                    </View>
                )}

                {this.state.showFilter && this.state.generalSearchTabActive && (
                    <ABAKeyboardAwareScrollView style={{backgroundColor: 'white'}}>
                        <ABADropdown
                            label="Activity Type"
                            onChange={(value) => {
                                this.setState({activityType: value})
                            }}
                            options={[
                                {
                                    label: 'ABA-approved Patient Safety Activity',
                                    value: 2,
                                },
                                {
                                    label: 'AMA PRA Category 1 Credit',
                                    value: 5,
                                },
                            ]}
                            placeholder={'Activity Type'}
                            selectedValue={this.state.activityType}
                            validationStatus={1}
                        />
                        <ABAAccordion
                            title="Fee"
                            accordionExpanded={true}
                            accordionContainerStyle={styleFunctions.Accordioncontainer()}
                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                            accordionTitleStyle={{
                                textAlign: 'left',
                                paddingLeft: 10,
                                fontSize: 15,
                                color: ColorEnums.cloudBurst,
                                fontWeight: 'bold',
                                marginBottom: 5,
                            }}
                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                            accordionContent={
                                <ABAKeyboardAwareScrollView
                                    style={{
                                        borderWidth: 1,
                                        borderColor: ColorEnums.blueGray,
                                        padding: 5,
                                    }}
                                >
                                    {this.state.feeOptions.map((fee, index) => {
                                        return (
                                            <ABACheckbox
                                                isChecked={fee.isChecked}
                                                onChangeEvent={() => {
                                                    const {feeFilters, feeOptions} = this.state
                                                    let feeArr = [...feeFilters]
                                                    let feeOptionsCopy = [...feeOptions]
                                                    const indexValue = feeArr.indexOf(fee.value)
                                                    if (indexValue !== -1) {
                                                        feeArr.splice(indexValue, 1)
                                                    } else {
                                                        feeArr.push(fee.value)
                                                    }

                                                    feeOptionsCopy[index].isChecked =
                                                        !feeOptions[index].isChecked

                                                    this.setState({
                                                        feeFilters: feeArr,
                                                        feeOptions: feeOptionsCopy,
                                                    })
                                                }}
                                                label={fee.label}
                                                labelStyle={{
                                                    fontFamily: 'Lato',
                                                    color: ColorEnums.cloudBurst,
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                }}
                                                useValidation={false}
                                            />
                                        )
                                    })}
                                </ABAKeyboardAwareScrollView>
                            }
                        />
                        <ABAAccordion
                            title="Certification Types"
                            accordionExpanded={true}
                            accordionContainerStyle={styleFunctions.Accordioncontainer()}
                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                            accordionTitleStyle={{
                                textAlign: 'left',
                                paddingLeft: 10,
                                fontSize: 15,
                                color: ColorEnums.cloudBurst,
                                fontWeight: 'bold',
                                marginBottom: 5,
                            }}
                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                            accordionContent={
                                <ABAKeyboardAwareScrollView
                                    style={{
                                        borderWidth: 1,
                                        borderColor: ColorEnums.blueGray,
                                        padding: 5,
                                    }}
                                >
                                    {this.state.certTypeOptions.map((certType, index) => {
                                        return (
                                            <ABACheckbox
                                                isChecked={certType.isChecked}
                                                onChangeEvent={() => {
                                                    const {certTypeFilters, certTypeOptions} = this.state
                                                    let certTypesArr = [...certTypeFilters]
                                                    let certTypeOptionsCopy = [...certTypeOptions]
                                                    const indexValue = certTypesArr.indexOf(
                                                        certType.value,
                                                    )
                                                    if (indexValue !== -1) {
                                                        certTypesArr.splice(indexValue, 1)
                                                    } else {
                                                        certTypesArr.push(certType.value)
                                                    }

                                                    certTypeOptionsCopy[index].isChecked =
                                                        !certTypeOptions[index].isChecked

                                                    this.setState({
                                                        certTypeFilters: certTypesArr,
                                                        certTypeOptions: certTypeOptionsCopy,
                                                    })
                                                }}
                                                label={certType.label}
                                                labelStyle={{
                                                    fontFamily: 'Lato',
                                                    color: ColorEnums.cloudBurst,
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                }}
                                                useValidation={false}
                                            />
                                        )
                                    })}
                                </ABAKeyboardAwareScrollView>
                            }
                        />
                        <ABAAccordion
                            title="Activity Formats"
                            accordionExpanded={true}
                            accordionContainerStyle={styleFunctions.Accordioncontainer()}
                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                            accordionTitleStyle={{
                                textAlign: 'left',
                                paddingLeft: 10,
                                fontSize: 15,
                                color: ColorEnums.cloudBurst,
                                fontWeight: 'bold',
                                marginBottom: 5,
                            }}
                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                            accordionContent={
                                <ABAKeyboardAwareScrollView
                                    style={{
                                        borderWidth: 1,
                                        borderColor: ColorEnums.blueGray,
                                        padding: 5,
                                    }}
                                >
                                    {this.state.activityFormatOptions.map((activity, index) => {
                                        return (
                                            <ABACheckbox
                                                isChecked={activity.isChecked}
                                                onChangeEvent={() => {
                                                    const {
                                                        activityFormatFilters,
                                                        activityFormatOptions,
                                                    } = this.state
                                                    let activitiesArr = [...activityFormatFilters]
                                                    let activityOptionsCopy = [...activityFormatOptions]
                                                    const indexValue = activitiesArr.indexOf(
                                                        activity.value,
                                                    )
                                                    if (indexValue !== -1) {
                                                        activitiesArr.splice(indexValue, 1)
                                                    } else {
                                                        activitiesArr.push(activity.value)
                                                    }

                                                    activityOptionsCopy[index].isChecked =
                                                        !activityFormatOptions[index].isChecked

                                                    this.setState({
                                                        activityFormatFilters: activitiesArr,
                                                        activityFormatOptions: activityOptionsCopy,
                                                    })
                                                }}
                                                label={activity.label}
                                                labelStyle={{
                                                    fontFamily: 'Lato',
                                                    color: ColorEnums.cloudBurst,
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                }}
                                                useValidation={false}
                                            />
                                        )
                                    })}
                                </ABAKeyboardAwareScrollView>
                            }
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingTop: 15,
                            }}
                        >
                            <Text style={[this.styles.grayText, {fontSize: 12, fontWeight: 'bold'}]}>
                                {this.state.searchResults.length} Search Results
                            </Text>
                            <ABATouchableHighlight
                                text="Apply Filter"
                                onPressEvent={() => {
                                    this.searchCME()
                                }}
                            />
                        </View>
                    </ABAKeyboardAwareScrollView>
                )}
                {this.state.showResults && this.renderSearchResults()}
            </View>
        )
    }

    renderTopicSearch() {
        return (
            <View style={{width: '100%', minHeight: 400}}>
                <View style={Platform.OS === 'web' && {marginBottom: 5}}>
                    <ABADropdown
                        label="Select a Topic from the Dropdown"
                        onChange={(value) => {
                            let tier2_options = this.filterOptions(value, this.state.tier2_options_total)

                            this.setState({
                                tier1: value,
                                tier2_options,
                            })
                        }}
                        options={this.state.tier1_options || []}
                        placeholder={'Select a Topic from the Dropdown'}
                        selectedValue={this.state.tier1}
                        validationStatus={1}
                    />
                </View>

                <View style={Platform.OS === 'web' && {marginBottom: 5}}>
                    <ABADropdown
                        label="Select a Section from the Dropdown"
                        onChange={(value) => {
                            let tier3_options = this.filterOptions(value, this.state.tier3_options_total)

                            this.setState({
                                tier2: value,
                                tier3_options,
                            })
                        }}
                        options={this.state.tier2_options || []}
                        placeholder={'Select a Section from the Dropdown'}
                        selectedValue={this.state.tier2}
                        validationStatus={1}
                    />
                </View>

                <View style={Platform.OS === 'web' && {marginBottom: 5}}>
                    <ABADropdown
                        label="Select a Sub-Section from the Dropdown"
                        onChange={(value) => {
                            this.searchCMETier3(value)
                        }}
                        options={this.state.tier3_options || []}
                        placeholder={'Select a Sub-Section from the Dropdown'}
                        selectedValue={this.state.tier3}
                        validationStatus={1}
                    />
                </View>

                {this.state.tier3 !== 'null' &&
                    this.state.tier4_options &&
                    this.state.tier4_options.length > 0 && (
                        <View style={{marginTop: 10}}>
                            <Text style={{fontFamily: 'Lato', color: ColorEnums.blueGray}}>
                                Select Optional Keywords
                            </Text>
                            <ABAKeyboardAwareScrollView
                                style={{borderWidth: 1, borderColor: ColorEnums.blueGray, padding: 5}}
                            >
                                {this.state.tier4_options.every(
                                    (option) => option.isChecked === true,
                                ) ? (
                                    <ABACheckbox
                                        isChecked={true}
                                        onChangeEvent={() => this.uncheckAll()}
                                        label="Check All"
                                        labelStyle={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                        useValidation={false}
                                    />
                                ) : (
                                    <ABACheckbox
                                        isChecked={false}
                                        onChangeEvent={() => this.checkAll()}
                                        label="Check All"
                                        labelStyle={{
                                            fontFamily: 'Lato',
                                            color: ColorEnums.cloudBurst,
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                        useValidation={false}
                                    />
                                )}

                                {this.state.tier4_options.map((option, index) => {
                                    return (
                                        <View key={index}>
                                            <ABACheckbox
                                                isChecked={option.isChecked}
                                                onChangeEvent={() => this.checkIndividual(index)}
                                                label={option.label}
                                                labelStyle={{
                                                    fontFamily: 'Lato',
                                                    color: ColorEnums.cloudBurst,
                                                    fontSize: 14,
                                                }}
                                                useValidation={false}
                                            />
                                        </View>
                                    )
                                })}
                            </ABAKeyboardAwareScrollView>
                        </View>
                    )}

                {this.state.showResults && this.state.tier3 !== 'null' && this.renderSearchResults()}
            </View>
        )
    }

    renderSearchResults() {
        return (
            <View>
                {this.state.searchResults.length < 1 && (
                    <View style={{paddingTop: 30}}>
                        <Text
                            style={{
                                textAlign: 'center',
                                fontFamily: 'Lato',
                                color: ColorEnums.cloudBurst,
                                fontSize: 20,
                            }}
                        >
                            No CMEs currently available.
                        </Text>
                    </View>
                )}

                {/* Container for all of our CME Results */}
                {this.state.searchResults.slice(0, this.state.displayedResults).map((result, i) => {
                    return (
                        <View style={{paddingTop: 40}} key={i}>
                            <View>
                                <ABAHyperLink
                                    label={result.ActivityTitle}
                                    url={result.ActivityUrl}
                                    style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold',
                                        color: ColorEnums.danube,
                                        fontSize: 16,
                                    }}
                                />
                                {/* ActivityType 2 is for Patient Safety */}
                                {result.ActivityType == 2 && (
                                    <View style={{flexDirection: 'row'}}>
                                        <Text
                                            style={[
                                                this.styles.cmeInfoTitle,
                                                {color: ColorEnums.pomegranate},
                                            ]}
                                        >
                                            Activity Type:{' '}
                                        </Text>
                                        <Text
                                            style={[
                                                this.styles.cmeInfoText,
                                                {color: ColorEnums.pomegranate},
                                            ]}
                                        >
                                            Patient Safety
                                        </Text>
                                    </View>
                                )}
                                {!this.state.stackResultData ? (
                                    <View>
                                        <View style={{flexDirection: 'row'}}>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <Text style={this.styles.cmeInfoTitle}>
                                                    Activity Format:{' '}
                                                </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {this.activityFormat(result.ActivityFormat)}
                                                </Text>
                                            </View>
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>
                                                    Provider Name:{' '}
                                                </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {result.ProviderName}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={{flexDirection: 'row'}}>
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>
                                                    Expiration Date:{' '}
                                                </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {moment(result.ActivityEndDate).format('MM/DD/YYYY')}
                                                </Text>
                                            </View>
                                            {result.TypicalLearningTime !== null &&
                                                result.TypicalLearningTime !== '0 hrs 0 min' && (
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text style={this.styles.cmeInfoTitle}>
                                                            Typical Learning Time:{' '}
                                                        </Text>
                                                        <Text style={this.styles.cmeInfoText}>
                                                            {result.TypicalLearningTime}
                                                        </Text>
                                                    </View>
                                                )}
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>
                                                    CME Credits:{' '}
                                                </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {result.CMECredits.toFixed(2)}
                                                </Text>
                                            </View>
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>Fee: </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {result.Fee === 1 ? 'Yes' : 'No'}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                ) : (
                                    <View>
                                        <View style={{flexDirection: 'row'}}>
                                            <Text style={this.styles.cmeInfoTitle}>
                                                Activity Format:{' '}
                                            </Text>
                                            <Text style={this.styles.cmeInfoText}>
                                                {this.activityFormat(result.ActivityFormat)}
                                            </Text>
                                        </View>
                                        <View style={{flexDirection: 'row'}}>
                                            <Text style={this.styles.cmeInfoTitle}>Provider Name: </Text>
                                            <Text style={this.styles.cmeInfoText}>
                                                {result.ProviderName}
                                            </Text>
                                        </View>
                                        <View style={{flexDirection: 'row'}}>
                                            <Text style={this.styles.cmeInfoTitle}>
                                                Expiration Date:{' '}
                                            </Text>
                                            <Text style={this.styles.cmeInfoText}>
                                                {moment(result.ActivityEndDate).format('MM/DD/YYYY')}
                                            </Text>
                                        </View>
                                        {result.TypicalLearningTime !== null &&
                                            result.TypicalLearningTime !== '0 hrs 0 min' && (
                                                <View style={{flexDirection: 'row'}}>
                                                    <Text style={this.styles.cmeInfoTitle}>
                                                        Typical Learning Time:{' '}
                                                    </Text>
                                                    <Text style={this.styles.cmeInfoText}>
                                                        {result.TypicalLearningTime}
                                                    </Text>
                                                </View>
                                            )}
                                        <View style={{flexDirection: 'row'}}>
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>
                                                    CME Credits:{' '}
                                                </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {result.CMECredits.toFixed(2)}
                                                </Text>
                                            </View>
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={this.styles.cmeInfoTitle}>Fee: </Text>
                                                <Text style={this.styles.cmeInfoText}>
                                                    {result.Fee === 1 ? 'Yes' : 'No'}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    )
                })}
                <View style={{paddingTop: 30, flexDirection: 'row'}}>
                    <View style={{width: '50%'}}>
                        {this.state.displayedResults < this.state.searchResults.length && (
                            <TouchableOpacity
                                onPress={() => this.showMoreResults()}
                                testID="ShowMoreResultsButton"
                            >
                                <Text style={{fontFamily: 'Lato', color: ColorEnums.danube}}>
                                    Show More...
                                </Text>
                            </TouchableOpacity>
                        )}
                    </View>
                    {this.state.searchResults.length > 1 && (
                        <View style={{width: '50%'}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.refs._scrollView.scrollTo({x: 0, animated: false})
                                }}
                                testID="ReturnToTopButton"
                            >
                                <Text
                                    style={{
                                        fontFamily: 'Lato',
                                        color: ColorEnums.danube,
                                        textAlign: 'right',
                                    }}
                                >
                                    Return to Top
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
                {this.props.navData.tier3Id && (
                    <View style={{marginTop: 20}}>
                        <ABATouchableHighlight
                            text="Return to Knowledge Assessment Report"
                            onPressEvent={() => {
                                let navData = {
                                    knowledgeAssessmentState: this.props.knowledgeAssessmentState,
                                }

                                NavigationHelper.GoToPage(
                                    GeneralEnums.navigationType.push,
                                    '/knowledgeAssessmentReport',
                                    navData,
                                )
                            }}
                        />
                    </View>
                )}
            </View>
        )
    }
}
