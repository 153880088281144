import ABAPortalAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'

const BackdoorAccessLogic = {
    context: null,

    ImpersonateABAEmployeeAsDoctor: function (abaId) {
        let regExAbaId = /^(\d{4}-\d{4})$|^(\d{8})$/
        if (!regExAbaId.test(abaId)) {
            HelperMethods.alertAgnostic('Please enter a valid ABA ID format.', 'Invalid ABA ID Format', [
                {
                    text: 'Ok',
                    onPress: () => {
                        BackdoorAccessLogic.context.abaIdInputField.clear()
                        BackdoorAccessLogic.context.abaIdInputField.focus()
                    },
                },
            ])
            return
        }

        BackdoorAccessLogic.context.setState({CallingApi: true})

        abaId = abaId.replace('-', '')

        ABAPortalAPI.patch('authentication/impersonateABAEmployeeAsDoctor/' + abaId)
            .then(function (response) {
                BackdoorAccessLogic.context.setState({CallingApi: false})
                BackdoorAccessLogic.context.props.setABAID(abaId)
                NavigationHelper.GoToPage(GeneralEnums.navigationType.push, `/dashboard`, {
                    abaid: abaId,
                })
            })
            .catch(function (error) {
                BackdoorAccessLogic.context.setState({CallingApi: false})
                HelperMethods.apiErrorHandler(error, 'Failed To Impersonate Doctor')
            })
    },
}

export default BackdoorAccessLogic
