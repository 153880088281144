import React, {Component} from 'react'
import {ActivityIndicator, Text, View, StyleSheet} from 'react-native'
import NonStandardExamStep1Logic from '../logic/nonStandardExamStep1Logic'
import styleFunctions from '../../../../styles/styleFunctions'
import {ColorEnums, GeneralEnums} from '../../../../helpers/generalEnums'
import ABAAffirmationRadio from '../../../ABACustom/presentation/ABAAffirmationRadio'
import ABAHyperLink from '../../../ABACustom/presentation/ABAHyperLink'
import ABARequiredAsterisk from '../../../ABACustom/presentation/ABARequiredAsterisk'

export default class NonStandardExamStep1 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            ReleaseOption: null,
            NSEReleaseData: {},
            ReleaseOptionError: GeneralEnums.textInputStatus.default,
            examTypeList: [],
            productID: '',
            ExamLabel: '',
            NursingMotherOption: null,
            NursingMotherOptionError: GeneralEnums.textInputStatus.default,
        }

        this.styles = StyleSheet.create({
            actionNeeded: {
                fontWeight: 'bold',
                color: 'red',
                textDecorationLine: 'underline',
                textDecorationColor: 'black',
            },
            examText: {
                fontSize: 14,
                padding: 15,
                width: '90%',
                marginLeft: 20,
            },
            containerText: {
                fontSize: 14,
                width: '90%',
            },
        })

        this.NonStandardExamStep1Logic = Object.assign({}, NonStandardExamStep1Logic)
        this.NonStandardExamStep1Logic.context = this
    }

    componentDidMount() {
        this.getNSEData()
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.Order !== previousProps.Order) {
            this.getNSEData()
        }

        if (previousState.productID !== this.state.productID) {
            const {productID, examTypeList} = this.state
            if (productID) {
                this.NonStandardExamStep1Logic.SetExamInformation(productID, examTypeList)
            }
        }
    }

    getNSEData() {
        this.NonStandardExamStep1Logic.GetExamTypes().then((examTypeList) => {
            this.NonStandardExamStep1Logic.GetNSEBeginData().then((NSEData) => {
                this.NonStandardExamStep1Logic.populateStateVariables(examTypeList, NSEData)
            })
        })
    }

    Save() {
        return this.NonStandardExamStep1Logic.SaveLogic()
    }

    Previous() {
        return this.NonStandardExamStep1Logic.Previous()
    }

    render() {
        nseContext = this
        const {NSEReleaseData} = nseContext.state
        const {re_ProgramType, ProductId} = NSEReleaseData
        const {MOCA2} = GeneralEnums.program_type
        const {APPLIED, OSCE, SOE, Part2} = GeneralEnums.product
        const {containerText, examText, actionNeeded} = this.styles
        const {IsFromDashboard} = this.props
        const showMedicalDeviceText = IsFromDashboard || (NSEReleaseData && re_ProgramType !== MOCA2)
        const showComputerizedExamText =
            IsFromDashboard ||
            (NSEReleaseData &&
                re_ProgramType !== MOCA2 &&
                ProductId !== OSCE &&
                ProductId !== Part2 &&
                ProductId !== SOE &&
                ProductId !== APPLIED)
        const showAppliedExamText =
            IsFromDashboard ||
            (NSEReleaseData &&
                re_ProgramType !== MOCA2 &&
                (ProductId === OSCE ||
                    ProductId === Part2 ||
                    ProductId === SOE ||
                    ProductId === APPLIED))
        return (
            <View>
                <View style={{borderBottomWidth: 1, borderBottomColor: ColorEnums.cloudBurst}}>
                    <View style={{padding: 15}}>
                        <Text style={[styleFunctions.paragraphText(), containerText]}>
                            We support the intent of the Americans with Disabilities Act (ADA) to
                            accommodate individuals with disabilities who demonstrate a need for
                            reasonable accommodation. Individuals will be required to submit
                            documentation of their need for a particular accommodation and the nature and
                            severity of their disability. All individuals requesting exams or MOCA Minute
                            under non-standard conditions should read our
                            <ABAHyperLink
                                url="https://theaba.org/pdfs/Nonstandard_Exam_Guidelines.pdf"
                                label=" Guidelines for Requesting Accommodations"
                                style={[styleFunctions.actionText(), {padding: 0}]}
                            />{' '}
                            and review our NSE deadlines for ADA Assessment Accommodation Requests
                            <ABAHyperLink
                                url="https://theaba.org/certifications%20and%20exms.html"
                                label=" here"
                                style={[styleFunctions.actionText(), {padding: 0}]}
                            />
                            .
                        </Text>
                    </View>
                </View>
                {showMedicalDeviceText && (
                    <View>
                        <View style={{padding: 15}}>
                            <Text style={[styleFunctions.paragraphText(), containerText]}>
                                <Text style={{fontWeight: 'bold'}}>
                                    If you are requesting use of Medical Devices, Medicine, or Mobility
                                    Devices{' '}
                                </Text>
                                (i.e., Insulin pump, Nitro Glycerin pills, Inhaler, Hearing Aids, Medical
                                Alert Bracelet, Canes, Wheelchairs, etc.):
                            </Text>
                        </View>
                        <Text style={[styleFunctions.paragraphText(), examText]}>
                            <Text style={actionNeeded}>ACTION NEEDED:</Text> Click "No" below and email
                            exams@theaba.org with the specific medical devices, medicine, or mobility
                            devices you will need during the exam at least two weeks prior to your exam
                            date.
                        </Text>
                    </View>
                )}
                {IsFromDashboard && (
                    <View>
                        <View style={{padding: 15}}>
                            <Text style={[styleFunctions.paragraphText(), containerText]}>
                                {IsFromDashboard && (
                                    <Text style={{fontWeight: 'bold'}}>In-Training Exams: {'\n'}</Text>
                                )}
                                If you are a nursing mother, you may request a 45-minute break for the
                                2022 or later In-Training Exams in Anesthesiology, Critical Care, Pain
                                Medicine or Pediatric Anesthesiology.
                            </Text>
                        </View>
                        <Text style={[styleFunctions.paragraphText(), examText]}>
                            <Text style={actionNeeded}>ACTION NEEDED:</Text> Click "Yes" below and
                            complete the additional break time request form between 60 and 120 days prior
                            to the exam date. Requests submitted outside this time frame will not be
                            considered.
                        </Text>
                    </View>
                )}
                {showComputerizedExamText && (
                    <View>
                        <View style={{padding: 15}}>
                            <Text style={[styleFunctions.paragraphText(), containerText]}>
                                {IsFromDashboard && (
                                    <Text style={{fontWeight: 'bold'}}>
                                        Other ABA-Administered Written Exams: {'\n'}
                                    </Text>
                                )}
                                If you are a nursing mother, you may request a 45-minute break in
                                addition to the standard 20-minute break for the following exams in 2022
                                or later: Adult Cardiac Anesthesiology Exam, ADVANCED Exam, BASIC Exam,
                                Critical Care Medicine Exam, Pain Medicine Exam and Pediatric
                                Anesthesiology Exam. Nursing mothers will also have access to a private
                                space to pump, if one is available at the Pearson VUE testing center
                                selected.
                            </Text>
                        </View>
                        <Text style={[styleFunctions.paragraphText(), examText]}>
                            <Text style={actionNeeded}>ACTION NEEDED:</Text> Click "Yes" below and
                            complete the additional break time request form between 60 and 120 days prior
                            to the exam date. Requests submitted outside this time frame will not be
                            considered.
                        </Text>
                    </View>
                )}
                {showComputerizedExamText && (
                    <View>
                        <View style={{padding: 15}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        width: '90%',
                                    },
                                    containerText,
                                ]}
                            >
                                <Text style={{fontWeight: 'bold'}}>
                                    Non-ABA Administered Written Exams: {'\n'}
                                </Text>
                                If you are a nursing mother or are requesting nonstandard accommodations
                                for a Non-ABA administered examination, please email
                                accommodations@theaba.org.
                            </Text>
                        </View>
                    </View>
                )}
                {showAppliedExamText && (
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: ColorEnums.cloudBurst,
                        }}
                    >
                        <View style={{padding: 15}}>
                            <Text
                                style={[
                                    styleFunctions.paragraphText(),
                                    {
                                        fontSize: 14,
                                        width: '90%',
                                    },
                                    containerText,
                                ]}
                            >
                                {IsFromDashboard && (
                                    <Text style={{fontWeight: 'bold'}}>APPLIED Exam: {'\n'}</Text>
                                )}
                                If you are a nursing mother, you may request an additional 45 minutes of
                                break time be added to the standard 15-minute break between components of
                                the APPLIED Exam. This policy applies only to candidates taking both
                                components of the APPLIED Exam.
                            </Text>
                        </View>
                        <Text style={[styleFunctions.paragraphText(), examText]}>
                            <Text style={actionNeeded}>ACTION NEEDED:</Text> Click "Yes" below and
                            complete the additional break time request form between 90 and 120 days prior
                            to the first day of your assigned exam week. Requests submitted outside this
                            time frame will not be considered.
                        </Text>
                    </View>
                )}
                <View>
                    <View style={{padding: 15}}>
                        <Text
                            style={[styleFunctions.paragraphText(), containerText, {fontWeight: 'bold'}]}
                        >
                            Are you requesting administration of an ABA exam or MOCA Minute under
                            nonstandard conditions to accommodate substantial limitations in your ability
                            to take the exam or answer computer-based questions under standard conditions
                            due to a physical or mental impairment?
                            <ABARequiredAsterisk />
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: ColorEnums.cloudBurst,
                        paddingBottom: 25,
                        paddingTop: 25,
                    }}
                >
                    <View style={{width: '50%', alignSelf: 'center'}}>
                        <ABAAffirmationRadio
                            leftOptionContent="Yes"
                            leftOptionOnPress={() =>
                                this.NonStandardExamStep1Logic.ReleaseOptionYesSelected()
                            }
                            rightOptionContent="No"
                            rightOptionOnPress={() =>
                                this.NonStandardExamStep1Logic.ReleaseOptionNoSelected()
                            }
                            optionValues={{leftValue: 1, rightValue: 2}}
                            selectedValue={nseContext.state.ReleaseOption}
                            validationStatus={nseContext.state.ReleaseOptionError}
                            errorMessage={
                                nseContext.props.IsFromDashboard
                                    ? 'You must select yes'
                                    : 'Please select an option.'
                            }
                        />
                    </View>
                </View>
                {IsFromDashboard && nseContext.state.ReleaseOption == 1 && (
                    <>
                        <View>
                            <View style={{padding: 15}}>
                                <Text
                                    style={[
                                        styleFunctions.paragraphText(),
                                        containerText,
                                        {fontWeight: 'bold'},
                                    ]}
                                >
                                    Are you a nursing mother requesting an additional 45-minute break for
                                    exams in the current year or beyond?
                                    <ABARequiredAsterisk />
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: ColorEnums.cloudBurst,
                                paddingBottom: 25,
                                paddingTop: 25,
                            }}
                        >
                            <View style={{width: '50%', alignSelf: 'center'}}>
                                <ABAAffirmationRadio
                                    leftOptionContent="Yes"
                                    leftOptionOnPress={() =>
                                        this.NonStandardExamStep1Logic.NursingMotherOptionYesSelected()
                                    }
                                    rightOptionContent="No"
                                    rightOptionOnPress={() =>
                                        this.NonStandardExamStep1Logic.NursingMotherOptionNoSelected()
                                    }
                                    optionValues={{leftValue: 1, rightValue: 2}}
                                    selectedValue={nseContext.state.NursingMotherOption}
                                    validationStatus={nseContext.state.NursingMotherOptionError}
                                    errorMessage={'Please select an option.'}
                                />
                            </View>
                        </View>
                    </>
                )}

                {nseContext.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading NSE</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
            </View>
        )
    }
}
