import React, {Component} from 'react'
import {
    ActivityIndicator,
    Platform,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
    View,
} from 'react-native'
import {connect} from 'react-redux'
import KnowledgeAssessmentReportLogic from '../logic/knowledgeAssessmentReportLogic'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import NavigationShell from '../../components/presentation/navigationShell'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAWebView from '../../components/ABACustom/presentation/ABAWebView'
import {IconEnums, ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationHelper from '../../helpers/NavigationHelper'
import styleFunctions from '../../styles/styleFunctions'
import {setKARYear} from '../../redux/ActionCreators'
import PrintedFooter from '../../components/presentation/printedFooter'

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = {setKARYear}

class KnowledgeAssessmentReport extends Component {
    constructor(props) {
        super(props)

        let currentYear = new Date().getFullYear()
        let yearDropdown = []
        let stackQuestions = Platform.OS !== 'web' ? true : false

        for (let i = 0; i < 4; i++) {
            yearDropdown.push({
                value: (currentYear - i).toString(),
                label: (currentYear - i).toString(),
            })
        }

        this.state = {
            CallingApi: false,
            questionCategories: [],
            stackQuestions,
            yearDropdown,
            yearSelected: null,
            webHeaderFont: 36,
        }

        this.styles = StyleSheet.create({
            boldFont: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
            },
            headerPadding: {
                paddingBottom: 8,
                paddingTop: 8,
            },
            standardFont: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontSize: 12,
            },
            link: {
                fontFamily: 'Lato',
                color: ColorEnums.danube,
                fontSize: 12,
            },
            webQuestionContainer: {
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            },
            mobileQuestionContainer: {
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            dropdown: {
                width: 100,
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                borderWidth: 0,
            },
        })
    }

    componentDidMount() {
        // if this.navigationData.knowledgeAssessmentState exists, then it means we're coming back from CME Explorer and want
        // to have the same state as when the user originally left the page for CME Explorer
        if (this.navigationData.knowledgeAssessmentState) {
            this.setState(this.navigationData.knowledgeAssessmentState)
        } else {
            KnowledgeAssessmentReportLogic.getKnowledgeAssessmentApi()
        }
    }

    componentDidUpdate() {
        if (this.state.yearSelected !== this.props.userData.KARYear) {
            KnowledgeAssessmentReportLogic.getKnowledgeAssessmentApi()
            this.setState({yearSelected: this.props.userData.KARYear})
        }
    }

    toggleQuestionTopic(categoryKey) {
        let questionCategories = [...this.state.questionCategories]
        questionCategories[categoryKey].showCategory = !questionCategories[categoryKey].showCategory

        this.setState({questionCategories})
    }

    changeYear(year) {
        if (year === 'null') {
            return
        }

        this.props.setKARYear(year)
    }

    handleResize() {
        if (Platform.OS !== 'web') {
            return
        }

        if (window.innerWidth < 800 && !this.state.stackQuestions) {
            this.setState({stackQuestions: true})
        } else if (window.innerWidth >= 800 && this.state.stackQuestions === true) {
            this.setState({stackQuestions: false})
        }

        if (window.innerWidth < 830 && this.state.webHeaderFont !== 24) {
            this.setState({webHeaderFont: 24})
        } else if (window.innerWidth >= 830 && this.state.webHeaderFont !== 36) {
            this.setState({webHeaderFont: 36})
        }
    }

    render() {
        KnowledgeAssessmentReportLogic.context = this
        HelperMethods.setNavigationData(this)

        return (
            <NavigationShell context={this} print={true}>
                <ABAKeyboardAwareScrollView
                    style={{backgroundColor: ColorEnums.backgroundGray, paddingBottom: 20}}
                    onLayout={() => {
                        this.handleResize()
                    }}
                >
                    <ABAHeader
                        heading="Knowledge Assessment Report"
                        icon={IconEnums['clipboard-list']}
                        fontSizeWeb={this.state.webHeaderFont}
                    />
                    <View
                        style={{
                            maxWidth: 500,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '100%',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: 'Lato',
                                color: ColorEnums.cloudBurst,
                                textAlign: 'center',
                            }}
                        >
                            This report provides details about the questions you answered incorrectly and
                            CMEs related to the question topics.
                        </Text>
                    </View>

                    <View
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 25,
                            justifyContent: 'center',
                        }}
                    >
                        <View>
                            <Text style={[this.styles.boldFont, {paddingRight: 5}]}>
                                View Performance for:{' '}
                            </Text>
                        </View>
                        <View
                            style={{
                                backgroundColor: ColorEnums.backgroundGray,
                                marginBottom: 10,
                            }}
                        >
                            <ABADropdown
                                options={this.state.yearDropdown}
                                label={`Please Select Year`}
                                placeholder=""
                                onChange={(value) => this.changeYear(value)}
                                selectedValue={this.props.userData.KARYear}
                                width={200}
                            />
                        </View>
                    </View>
                    {this.state.questionCategories &&
                        this.state.questionCategories.map((questionCategory, key) => {
                            return (
                                <View
                                    style={{
                                        maxWidth: 700,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '100%',
                                    }}
                                    key={key}
                                >
                                    <View
                                        style={{
                                            backgroundColor: 'white',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '95%',
                                            marginTop: 15,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <TouchableWithoutFeedback
                                            onPress={() => this.toggleQuestionTopic(key)}
                                            testID={`questionCategory${key}`}
                                        >
                                            <View style={{flexDirection: 'row', padding: 15}}>
                                                <View style={{justifyContent: 'center'}}>
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                GeneralEnums.fontAwesomeFamilyName,
                                                            color: ColorEnums.cloudBurst,
                                                            paddingRight: 10,
                                                        }}
                                                    >
                                                        {questionCategory.showCategory
                                                            ? IconEnums['chevron-up']
                                                            : IconEnums['chevron-down']}
                                                    </Text>
                                                </View>

                                                <View style={{justifyContent: 'center', flex: 1}}>
                                                    <View>
                                                        <Text
                                                            style={[
                                                                this.styles.boldFont,
                                                                {alignSelf: 'baseline'},
                                                            ]}
                                                        >
                                                            {questionCategory.categoryName}
                                                        </Text>
                                                    </View>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'Lato',
                                                                color: ColorEnums.cloudBurst,
                                                            }}
                                                        >
                                                            # Incorrect:{' '}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'Lato',
                                                                color: ColorEnums.cloudBurst,
                                                            }}
                                                        >
                                                            {questionCategory.questions.length.toString()}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </TouchableWithoutFeedback>

                                        {questionCategory.showCategory && (
                                            <View
                                                style={
                                                    this.state.stackQuestions
                                                        ? this.styles.mobileQuestionContainer
                                                        : this.styles.webQuestionContainer
                                                }
                                            >
                                                {questionCategory.questions.map((question, i) =>
                                                    this.renderQuestions(question, i),
                                                )}
                                            </View>
                                        )}
                                    </View>
                                </View>
                            )
                        })}
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
                {this.state.CallingApi && (
                    <View style={styleFunctions.loadingOverlay()}>
                        <Text>Loading Knowledge Assessment Report</Text>
                        <ActivityIndicator color={ColorEnums.indigo} size="large" />
                    </View>
                )}
                <Text
                    style={{
                        backgroundColor: ColorEnums.backgroundGray,
                        fontFamily: 'Lato',
                        color: ColorEnums.blueGray,
                        fontStyle: 'italic',
                        fontSize: 10,
                        textAlign: 'center',
                        paddingBottom: 10,
                    }}
                >
                    The ABA does not produce or endorse any CME. We also do not financially benefit from
                    diplomate participation in CME.
                </Text>
            </NavigationShell>
        )
    }

    renderQuestions(question, iteration) {
        return (
            <View
                key={iteration}
                style={{
                    width: this.state.stackQuestions ? '100%' : '48%',
                    borderBottomColor: ColorEnums.backgroundGray,
                    borderBottomWidth: 1,
                }}
            >
                <View>
                    <Text style={[this.styles.boldFont, this.styles.headerPadding]}>QUESTION</Text>
                    <ABAWebView
                        htmlToRender={`<p style="font-size:12;color:${ColorEnums.cloudBurst}">${question.QuestionText}</p>`}
                    />
                </View>
                <View>
                    <Text style={[this.styles.boldFont, this.styles.headerPadding]}>KEY POINT</Text>
                    <ABAWebView
                        htmlToRender={`<p style="font-size:12;color:${ColorEnums.cloudBurst}">${question.KeyPointText}</p>`}
                    />
                </View>
                <View>
                    <Text style={[this.styles.boldFont, this.styles.headerPadding]}>
                        PEER PERFORMANCE
                    </Text>
                    <Text style={this.styles.standardFont}>{question.PeerPerformance}%</Text>
                </View>
                <View style={{paddingBottom: 20}}>
                    <Text style={[this.styles.boldFont, this.styles.headerPadding]}>RESOURCES</Text>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: '50%'}}>
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        '/mocaMinuteFeedback',
                                        {
                                            AskId: question.AskID,
                                            KnowledgeAssessmentReport: true,
                                        },
                                    )
                                }}
                                testID={`questionDetails${question.AskID}`}
                            >
                                <View>
                                    <Text style={this.styles.link}>View Question Details</Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                        <View
                            style={{borderRightWidth: 1, borderRightColor: ColorEnums.backgroundGray}}
                        />
                        <View style={{width: '50%'}}>
                            {question.ActivitiesCount > 0 && (
                                <TouchableWithoutFeedback
                                    onPress={() => {
                                        let cmeProps = {
                                            showKeywordSearchTab: false,
                                            tier1Id: question.ContentOutlineLevel1ID,
                                            tier3Id: question.ContentOutlineID,
                                            knowledgeAssessmentState: this.state,
                                        }

                                        NavigationHelper.GoToPage(
                                            GeneralEnums.navigationType.push,
                                            '/cmeExplorer',
                                            cmeProps,
                                        )
                                    }}
                                    testID={`relatedCME${question.ContentOutlineID}`}
                                >
                                    <View>
                                        <Text
                                            style={[
                                                this.styles.link,
                                                {textAlign: 'right', paddingRight: 5},
                                            ]}
                                        >
                                            Related CME Activities
                                        </Text>
                                    </View>
                                </TouchableWithoutFeedback>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeAssessmentReport)
