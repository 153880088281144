import axios from 'axios'
import ConfigurationProps from '../configuration'

const ABAPortalAPI = axios.create({
    baseURL: ConfigurationProps.API_URL,
    Headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
    },
    withCredentials: true,
})

export default ABAPortalAPI

export const setHeaderABAID = (abaId) => {
    ABAPortalAPI.defaults.headers.common['abaId'] = abaId
}

export const setHeaderAuthToken = (token) => {
    ABAPortalAPI.defaults.headers.common['AuthenticationToken'] = token
}
