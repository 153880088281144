import React, {Component} from 'react'
import {View, Text, Image, StyleSheet} from 'react-native'
import moment from 'moment'
import HelperMethods from '../../helpers/HelperMethods'
import {ColorEnums, UnicodeEnums} from '../../helpers/generalEnums'
import StatusLetterLogic from '../logic/statusLetterLogic'
import BoardList from '../../assets/images/Letterhead-BOD.png'
import Signature from '../../assets/images/Letterhead-BOD-Signature-Oct2022.png'

export default class StatusLetter extends Component {
    constructor(props) {
        super(props)

        let isInternetExplorer = false
        if (/Trident/.test(navigator.userAgent)) {
            isInternetExplorer = true
        }

        this.state = {
            certData: null,
            certDataInProgress: null,
            currentDate: moment().format('MMMM D, YYYY'),
            lastName: '',
            fullName: '',
            participatingInMOC: null,
            notClinicallyActive: [],
            isInternetExplorer,
            CallingApi: false,
        }

        this.styles = StyleSheet.create({
            certHeader: {
                borderWidth: 1,
                borderLeftWidth: 0,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
            },
            certRowCol1: {
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                paddingTop: 10,
                paddingBottom: 10,
                paddingRight: 10,
                paddingLeft: 10,
                width: '35%',
                justifyContent: 'center',
                alignItems: 'center',
            },
            certRowFollowingCols: {
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                paddingTop: 10,
                paddingBottom: 10,
                paddingRight: 3,
                paddingLeft: 3,
                width: '15%',
                justifyContent: 'center',
                alignItems: 'center',
            },
            letterFontSize: {
                fontSize: 18,
            },
        })
    }

    componentDidMount() {
        StatusLetterLogic.getDoctorInfo().then((responseData) => {
            this.setState({
                lastName: responseData.LastName,
            })
        })

        StatusLetterLogic.getStatusLetter().then((responseData) => {
            let certData = []
            let certDataInProgress = []
            let notClinicallyActive = []
            let areasOfCert = []
            let participatingInMOC = []

            responseData.forEach((data) => {
                if (data.BoardStatus === 'Certified - Not Clinically Active') {
                    notClinicallyActive.push(data)
                }
                if (!data.IssueDate && !data.ExpirationDate) {
                    certDataInProgress.push(data)
                } else {
                    certData.push(data)
                    participatingInMOC.push(data)

                    //Getting rid of duplicates that may show up in the Participating in MOC table.
                    if (areasOfCert.includes(data.AreaofCert)) {
                        participatingInMOC.forEach((d, index) => {
                            // If user has an older cert with 'null' or 'certified indefinitely' expiration date,
                            // then the newer one will replace that in Participating in MOC
                            if (
                                d.AreaofCert === data.AreaofCert &&
                                ((!d.ExpirationDate && data.ExpirationDate) ||
                                    (!d.ExpirationDate && !data.ExpirationDate))
                            ) {
                                participatingInMOC.splice(index, 1)
                            }
                            // If user has a cert with a more recent expiration date, then that is what will show in the table.
                            else if (
                                d.AreaofCert === data.AreaofCert &&
                                moment(d.ExpirationDate).isBefore(moment(data.ExpirationDate))
                            ) {
                                participatingInMOC.splice(index, 1)
                            }
                        })
                    } else {
                        areasOfCert.push(data.AreaofCert)
                    }
                }
            })

            this.setState({
                fullName: responseData[0].FullName,
                certData,
                certDataInProgress,
                participatingInMOC,
                notClinicallyActive,
            })
        })
    }

    participatingInMOC(cert) {
        switch (cert.AreaofCert) {
            case 'Anesthesiology':
                return cert.MOCAStatus
            case 'Critical Care Medicine':
                return cert.MOCAStatusCCM
            case 'Hospice and Palliative Medicine':
                return cert.MOCAStatusHPM
            case 'Pain Medicine':
                return cert.MOCAStatusPM
            case 'Pediatric Anesthesiology':
                return cert.MOCAStatusPeds
            case 'Sleep Medicine':
                return cert.MOCAStatusSM
            case 'Neurocritical Care':
                return cert.MOCAStatusNCC
            case 'Adult Cardiac Anesthesiology':
                return cert.MOCAStatusACA
            default:
                return ''
        }
    }

    expirationDate(expDate) {
        if (expDate) {
            return moment(expDate).format('MMMM DD, YYYY')
        } else {
            return 'Certified Indefinitely'
        }
    }

    render() {
        StatusLetterLogic.context = this
        HelperMethods.setNavigationData(this)

        if (this.state.certData || this.state.certDataInProgress) {
            return (
                <View style={{backgroundColor: ColorEnums.backgroundGray}}>
                    {this.state.isInternetExplorer && (
                        <div
                            className="no-print"
                            style={{
                                fontFamily: 'Lato',
                                width: '100%',
                                maxWidth: 1189,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: 5,
                                marginBottom: 5,
                                backgroundColor: ColorEnums.pomegranateLight,
                                borderWidth: 1,
                                borderColor: ColorEnums.pomegranateDark,
                            }}
                        >
                            For the best results, please make sure ‘Print Background Colors and Images’
                            is checked in the Print Preview Page setup before printing this letter to a
                            PDF or use Google Chrome.
                        </div>
                    )}
                    <View
                        style={{
                            flex: 1,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: 'white',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <View
                            style={{flex: 1, width: 1189, marginTop: 30}}
                            className={this.state.isInternetExplorer ? 'ie-status-print' : ''}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 1, marginRight: 20, paddingLeft: 30}}>
                                    <View style={{marginTop: 30}}>
                                        <Text style={this.styles.letterFontSize}>
                                            {this.state.currentDate}
                                        </Text>
                                    </View>

                                    <Text
                                        style={[
                                            this.styles.letterFontSize,
                                            {fontWeight: 'bold', marginTop: 40},
                                        ]}
                                    >
                                        Certification Status for {this.state.fullName}
                                    </Text>

                                    {this.state.certData && this.state.certData.length > 0 && (
                                        <View>
                                            <Text style={[this.styles.letterFontSize, {marginTop: 15}]}>
                                                Dr. {this.state.lastName}'s current status relative to
                                                our examination and certification system is below.
                                            </Text>

                                            <View
                                                style={{
                                                    width: '100%',
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        borderLeftWidth: 1,
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <View
                                                        style={[this.styles.certHeader, {width: '35%'}]}
                                                    >
                                                        <Text
                                                            style={[
                                                                this.styles.letterFontSize,
                                                                {
                                                                    fontWeight: 'bold',
                                                                    maxWidth: '100%',
                                                                },
                                                            ]}
                                                        >
                                                            Area(s) of Certification
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={[this.styles.certHeader, {width: '15%'}]}
                                                    >
                                                        <Text
                                                            style={[
                                                                this.styles.letterFontSize,
                                                                {
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    maxWidth: '100%',
                                                                },
                                                            ]}
                                                        >
                                                            Certification Issuance Date
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={[this.styles.certHeader, {width: '15%'}]}
                                                    >
                                                        <Text
                                                            style={[
                                                                this.styles.letterFontSize,
                                                                {
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    maxWidth: '100%',
                                                                },
                                                            ]}
                                                        >
                                                            Certification Expiration Date
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={[this.styles.certHeader, {width: '35%'}]}
                                                    >
                                                        <Text
                                                            style={[
                                                                this.styles.letterFontSize,
                                                                {
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    maxWidth: '100%',
                                                                },
                                                            ]}
                                                        >
                                                            Name on Certification
                                                        </Text>
                                                    </View>
                                                </View>
                                                {this.state.certData &&
                                                    this.state.certData.map((cert, index) => {
                                                        return (
                                                            <View
                                                                style={{
                                                                    borderLeftWidth: 1,
                                                                    alignSelf: 'stretch',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                }}
                                                                key={index}
                                                            >
                                                                <View style={this.styles.certRowCol1}>
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                textAlign: 'center',
                                                                                maxWidth: '100%',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {cert.BoardStatus &&
                                                                        cert.BoardStatus.includes(
                                                                            'Revoked',
                                                                        )
                                                                            ? cert.AreaofCert
                                                                            : `${cert.AreaofCert} - ${cert.CertType}`}
                                                                    </Text>
                                                                </View>
                                                                <View
                                                                    style={
                                                                        this.styles.certRowFollowingCols
                                                                    }
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                textAlign: 'center',
                                                                                maxWidth: '100%',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {cert.BoardStatus &&
                                                                        cert.BoardStatus.includes(
                                                                            'Revoked',
                                                                        )
                                                                            ? cert.BoardStatus
                                                                            : moment(
                                                                                  cert.IssueDate,
                                                                              ).format('MMMM DD, YYYY')}
                                                                    </Text>
                                                                </View>
                                                                <View
                                                                    style={
                                                                        this.styles.certRowFollowingCols
                                                                    }
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                textAlign: 'center',
                                                                                maxWidth: '100%',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {cert.BoardStatus &&
                                                                        cert.BoardStatus.includes(
                                                                            'Revoked',
                                                                        )
                                                                            ? ''
                                                                            : this.expirationDate(
                                                                                  cert.ExpirationDate,
                                                                              )}
                                                                    </Text>
                                                                </View>
                                                                <View style={this.styles.certRowCol1}>
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                textAlign: 'center',
                                                                                maxWidth: '100%',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {cert.NameOnCert.trim()}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        )
                                                    })}
                                            </View>

                                            <View>
                                                <Text style={this.styles.letterFontSize}>
                                                    The following shows Dr. {this.state.lastName}'s
                                                    participation in the ABA's Maintenance of
                                                    Certification in Anesthesiology&reg; (MOCA&reg;)
                                                    program for all areas of certification.
                                                </Text>
                                            </View>

                                            <View style={{width: '50%', marginTop: 20}}>
                                                <View style={{borderLeftWidth: 1}}>
                                                    <View
                                                        style={{
                                                            borderWidth: 1,
                                                            borderLeftWidth: 0,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                this.styles.letterFontSize,
                                                                {
                                                                    fontWeight: 'bold',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                },
                                                            ]}
                                                        >
                                                            Participating in MOC
                                                        </Text>
                                                    </View>
                                                </View>
                                                {this.state.participatingInMOC &&
                                                    this.state.participatingInMOC.map((cert, index) => {
                                                        return (
                                                            <View
                                                                style={{
                                                                    borderLeftWidth: 1,
                                                                }}
                                                                key={index}
                                                            >
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        width: '100%',
                                                                        borderWidth: 1,
                                                                        borderLeftWidth: 0,
                                                                        borderTopWidth: 0,
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {cert.AreaofCert}
                                                                    </Text>
                                                                    <Text
                                                                        style={[
                                                                            this.styles.letterFontSize,
                                                                            {
                                                                                paddingLeft: 5,
                                                                                paddingRight: 5,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {this.participatingInMOC(cert)}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        )
                                                    })}
                                            </View>
                                        </View>
                                    )}

                                    {this.state.certDataInProgress &&
                                        this.state.certDataInProgress.length > 0 && (
                                            <View>
                                                <Text
                                                    style={[this.styles.letterFontSize, {marginTop: 15}]}
                                                >
                                                    Dr. {this.state.lastName} is currently a candidate in
                                                    the ABA examination system for:
                                                </Text>
                                                {this.state.certDataInProgress.map((data) => {
                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                marginTop: 5,
                                                                marginLeft: 50,
                                                            }}
                                                        >
                                                            <Text style={[this.styles.letterFontSize]}>
                                                                {UnicodeEnums.bullet}
                                                            </Text>
                                                            <Text style={[this.styles.letterFontSize]}>
                                                                {data.AreaofCert}
                                                            </Text>
                                                        </View>
                                                    )
                                                })}
                                            </View>
                                        )}

                                    {this.state.notClinicallyActive.length > 0 && (
                                        <View>
                                            <Text style={[this.styles.letterFontSize, {marginTop: 15}]}>
                                                Dr. {this.state.lastName} is not clinically active in:
                                            </Text>
                                            {this.state.notClinicallyActive.map((data) => {
                                                return (
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            marginTop: 5,
                                                            marginLeft: 50,
                                                        }}
                                                    >
                                                        <Text style={[this.styles.letterFontSize]}>
                                                            {UnicodeEnums.bullet}
                                                        </Text>
                                                        <Text style={[this.styles.letterFontSize]}>
                                                            {data.AreaofCert}
                                                        </Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    )}

                                    <Text style={[this.styles.letterFontSize, {marginTop: 60}]}>
                                        Sincerely,
                                    </Text>

                                    <img src={Signature} width="190" />

                                    <Text style={this.styles.letterFontSize}>
                                        Alex Macario, M.D., MBA
                                    </Text>
                                    <Text style={this.styles.letterFontSize}>Secretary</Text>
                                </View>

                                <View
                                    style={{
                                        marginLeft: 'auto',
                                        height: '100%',
                                        padding: 50,
                                        paddingRight: 20,
                                    }}
                                >
                                    <img src={BoardList} className="status-directors" width="250" />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            )
        } else if (this.state.CallingApi) {
            return (
                <View>
                    <Text style={{fontSize: 16, fontFamily: 'Lato'}}>Loading...</Text>
                </View>
            )
        } else {
            return (
                <View>
                    <Text style={{fontSize: 16, fontFamily: 'Lato'}}>
                        Dr. {this.state.lastName} does not currently have a status with the ABA.
                    </Text>
                </View>
            )
        }
    }
}
