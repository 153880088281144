import React, {Component} from 'react'
import {View, Text} from 'react-native'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
export default class ViewCME extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        HelperMethods.setNavigationData(this)
        return (
            <NavigationShell context={this}>
                <View style={{flex: 1}}>
                    <Text>View CME Placeholder</Text>
                </View>
            </NavigationShell>
        )
    }
}
