import {Platform} from 'react-native'

export default mocaMinuteLogic = {
    context: null,

    swapMocaComponent: function (component, navData, showNavShell = true) {
        mocaMinuteLogic.context.setState({
            CurrentComponentStep: component,
            NavData: navData,
            ShowNavShell: showNavShell,
        })
    },
}
