import React, {Component} from 'react'
import {TouchableHighlight, TouchableWithoutFeedback, StyleSheet, Text, View} from 'react-native'
import {connect} from 'react-redux'

import ABAAccordion from '../ABACustom/presentation/ABAAccordion'
import ABAHyperLink from '../ABACustom/presentation/ABAHyperLink'
import ABALogo from '../ABACustom/presentation/ABALogo'
import {ColorEnums, GeneralEnums, IconEnums} from '../../helpers/generalEnums'
import LoginLogic from '../../screens/logic/loginLogic'
import NavigationHelper from '../../helpers/NavigationHelper'
import SideNavigationLogic from '../logic/sidenavigationLogic'
import SidebarSublinks from '../presentation/sidebarSublinks'
import {
    setAuthToken,
    setCurrentProgressTab,
    clearUserData,
    setNavigation,
    setOpenMenuGroups,
} from '../../redux/ActionCreators'

const mapStateToProps = (state) => ({
    settings: state.settings,
    navigationMenu: state.navigationMenu,
})

const mapDispatchToProps = {
    setAuthToken,
    setCurrentProgressTab,
    clearUserData,
    setNavigation,
    setOpenMenuGroups,
    logout: LoginLogic.logout,
}

class SideNavigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CallingApi: false,
            links: this.props.navigationMenu.links,
        }

        this.styles = StyleSheet.create({
            navLinkText: {
                color: props.showNav ? 'white' : ColorEnums.blueGray,
                fontSize: 16,
                fontFamily: 'Lato',
                paddingLeft: 15,
            },
            navLinkContainer: {
                paddingTop: 5,
                paddingBottom: 5,
                backgroundColor: ColorEnums.cloudBurst,
            },
            headerInfoText: {
                fontFamily: 'lato',
                color: 'white',
                fontSize: 12,
            },
        })
    }

    componentDidMount() {
        SideNavigationLogic.getNavigationLinks().then((links) => {
            this.props.setNavigation(links)
            sideNavigationContext.setState({links})
        })
    }

    goToPersonalInfo() {
        NavigationHelper.GoToPage(GeneralEnums.navigationType.push, '/personalInfo')
    }

    render() {
        sideNavigationContext = this
        SideNavigationLogic.context = sideNavigationContext

        return (
            <View
                style={{
                    backgroundColor: ColorEnums.cloudBurst,
                    minHeight: '100vh',
                    overflow: 'auto',
                    width: 275,
                }}
            >
                <ABALogo />

                {this.props.headerInfo ? (
                    <View
                        style={{
                            flexDirection: 'row',
                            borderTopColor: ColorEnums.danube,
                            borderBottomColor: ColorEnums.danube,
                            borderBottomWidth: 1,
                            borderTopWidth: 1,
                            marginLeft: 15,
                            marginRight: 15,
                            marginTop: -15,
                            marginBottom: 10,
                            paddingTop: 8,
                            paddingBottom: 8,
                        }}
                    >
                        <View
                            style={{
                                marginRight: 8,
                                justifyContent: 'center',
                            }}
                        >
                            <Text
                                style={[
                                    {
                                        fontFamily: GeneralEnums.fontAwesomeFamilyName,
                                        fontSize: 32,
                                        color: 'white',
                                    },
                                ]}
                                onPress={this.props.showNav ? () => this.goToPersonalInfo() : undefined}
                            >
                                {IconEnums['user-circle']}
                            </Text>
                        </View>
                        <View>
                            <Text style={[this.styles.headerInfoText, {fontSize: 16, maxWidth: 200}]}>
                                Dr. {this.props.headerInfo.FirstName} {this.props.headerInfo.LastName}
                            </Text>
                            <View
                                style={{
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'white',
                                    flexDirection: 'row',
                                    paddingBottom: 3,
                                    paddingRight: 6,
                                }}
                            >
                                <Text style={this.styles.headerInfoText}>
                                    ABA ID: {this.props.headerInfo.ABAId}
                                </Text>
                                {this.props.headerInfo.PrincipalCertNumber && (
                                    <Text style={this.styles.headerInfoText}>
                                        {' '}
                                        | CERT #: {this.props.headerInfo.PrincipalCertNumber}
                                    </Text>
                                )}
                            </View>
                            <Text style={[this.styles.headerInfoText, {paddingTop: 3, maxWidth: 200}]}>
                                {this.props.headerInfo.Email}
                            </Text>
                        </View>
                    </View>
                ) : null}

                <View>
                    {this.state.links &&
                        this.state.links.map((link, index) => {
                            if (link.MenuItems) {
                                let expanded = this.props.navigationMenu.openMenuGroups.some(
                                    (menuItem) => menuItem === link.MenuGroupText,
                                )
                                return (
                                    <View key={index} style={{overflow: 'hidden'}}>
                                        <ABAAccordion
                                            title={link.MenuGroupText.toUpperCase()}
                                            accordionContent={<SidebarSublinks links={link.MenuItems} />}
                                            accordionContainerStyle={{
                                                backgroundColor: ColorEnums.cloudBurst,
                                            }}
                                            accordionExpanded={expanded}
                                            accordionTitleContainerStyle={this.styles.navLinkContainer}
                                            accordionTitleStyle={this.styles.navLinkText}
                                            accordionIconRight={{
                                                expanded: 'chevron-down',
                                                collapsed: 'chevron-right',
                                            }}
                                            accordionIconStyle={{
                                                color: this.props.showNav
                                                    ? 'white'
                                                    : ColorEnums.blueGray,
                                                fontSize: 12,
                                                marginLeft: 'auto',
                                                paddingTop: 3,
                                                paddingRight: 15,
                                            }}
                                            onPress={() => {
                                                let openMenuGroups =
                                                    this.props.navigationMenu.openMenuGroups
                                                if (openMenuGroups.includes(link.MenuGroupText)) {
                                                    openMenuGroups = openMenuGroups.filter(
                                                        (menuItem) => menuItem !== link.MenuGroupText,
                                                    )
                                                    this.props.setOpenMenuGroups(openMenuGroups)
                                                } else {
                                                    openMenuGroups.push(link.MenuGroupText)
                                                    this.props.setOpenMenuGroups(openMenuGroups)
                                                }
                                            }}
                                        />
                                    </View>
                                )
                            } else if (link.MenuGroupText.toUpperCase() === 'LOG OUT') {
                                return
                            } else if (
                                link.MenuGroupText.toUpperCase() === 'CONTACT US' ||
                                link.MenuGroupText.toUpperCase() === 'PUBLIC WEBSITE' ||
                                link.MenuGroupText.toUpperCase() === 'POLICY BOOK' ||
                                link.MenuGroupText.toUpperCase() === 'FAQ'
                            ) {
                                return (
                                    <View key={index} style={this.styles.navLinkContainer}>
                                        <ABAHyperLink
                                            key={index}
                                            label={link.MenuGroupText}
                                            url={link.MenuGroupUrl}
                                            style={this.styles.navLinkText}
                                        />
                                    </View>
                                )
                            } else {
                                return (
                                    <View key={index} style={this.styles.navLinkContainer}>
                                        <TouchableWithoutFeedback
                                            onPress={() => {
                                                NavigationHelper.GoToPage(
                                                    GeneralEnums.navigationType.push,
                                                    link.MenuGroupUrl,
                                                )
                                            }}
                                            testID={'sideNavLink' + link.MenuGroupText}
                                        >
                                            <View>
                                                <Text style={this.styles.navLinkText}>
                                                    {link.MenuGroupText.toUpperCase()}
                                                </Text>
                                            </View>
                                        </TouchableWithoutFeedback>
                                    </View>
                                )
                            }
                        })}
                    {!this.props.showNav && (
                        <View
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(00,47,108,.5)',
                            }}
                        />
                    )}
                </View>
                <View style={this.styles.navLinkContainer}>
                    <TouchableHighlight
                        onPress={() => {
                            this.props.logout()
                            LoginLogic.clearStoredCredentials()
                        }}
                        testID="sideNavLogout"
                    >
                        <Text style={[this.styles.navLinkText, {color: 'white'}]}>{'LOG OUT'}</Text>
                    </TouchableHighlight>
                </View>
            </View>
        )
    }
}

SideNavigation.defaultProps = {
    showNav: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation)
