import React, {Component} from 'react'

import EventSelection from '../selectionpayment/presentation/eventSelection'
import InvoiceConfirmation from '../selectionpayment/presentation/invoiceConfirmation'
import RankedChoiceSelection from '../selectionpayment/presentation/rankedChoiceSelection'
import PaymentAndRegistrationLogic from '../logic/paymentAndRegistrationLogic'

export default class PaymentAndRegistration extends Component {
    state = {
        CallingApi: false,
        CheckPrevious: true,
        CurrentComponentStep: EventSelection,
        EventID: '',
        SessionName: '',
        SessionDates: '',
        InvoiceID: '',
        isRankedChoice: false,
    }

    goToInvoiceConfirmation(EventID, SessionName, SessionDates) {
        PaymentAndRegistrationLogic.context.setState({
            CurrentComponentStep: InvoiceConfirmation,
            EventID,
            SessionName,
            SessionDates,
            isRankedChoice: false,
        })
    }

    goToRankedChoiceSelection(EventID, SessionName, SessionDates) {
        PaymentAndRegistrationLogic.context.setState({
            CurrentComponentStep: RankedChoiceSelection,
            EventID,
            SessionName,
            SessionDates,
            isRankedChoice: true,
        })
    }

    Previous() {
        return PaymentAndRegistrationLogic.context.child.Previous()
    }

    Save() {
        return this.child.Save()
    }

    render() {
        PaymentAndRegistrationLogic.context = this
        return (
            <this.state.CurrentComponentStep
                ref={(child) => {
                    this.child = child
                }}
                ProgramType={this.props.ProgramType}
                Order={this.props.Order}
                CR2ID={this.props.CR2ID}
                SubmittedSteps={this.props.SubmittedSteps}
                EventID={this.state.EventID}
                SessionName={this.state.SessionName}
                SessionDates={this.state.SessionDates}
                PaymentAndRegistrationContext={this}
                SetContinueVisibility={this.props.SetContinueVisibility}
                InvoiceID={this.state.InvoiceID}
                MOCA={false}
                NavigationData={this.props.NavigationData}
                ContainerTitle={this.props.ContainerTitle}
                isRankedChoice={this.state.isRankedChoice}
            />
        )
    }
}
