import React, {Component} from 'react'
import {View, Text, StyleSheet, ActivityIndicator} from 'react-native'
import {connect} from 'react-redux'
import NavigationShell from '../../components/presentation/navigationShell'
import HelperMethods from '../../helpers/HelperMethods'
import styleFunctions from '../../styles/styleFunctions'
import CMEActivitiesLogic from '../logic/cmeActivitiesLogic'
import ABAHeader from '../../components/ABACustom/presentation/ABAHeader'
import {ColorEnums, GeneralEnums} from '../../helpers/generalEnums'
import ABAContainer from '../../components/ABACustom/presentation/ABAContainer'
import ABAAccordion from '../../components/ABACustom/presentation/ABAAccordion'
import NavigationHelper from '../../helpers/NavigationHelper'
import CMEDiplomateInMOCA from '../../components/presentation/cmeDiplomateInMOCA'
import CME from '../../components/presentation/cme'
import ABAHyperLink from '../../components/ABACustom/presentation/ABAHyperLink'
import ABAConfirmationBox from '../../components/ABACustom/presentation/ABAConfirmationBox'
import ABADropdown from '../../components/ABACustom/presentation/ABADropdown'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import {setUserRole} from '../../redux/ActionCreators'
import PrintedFooter from '../../components/presentation/printedFooter'

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = {setUserRole}

class CMEActivities extends Component {
    constructor(props) {
        super(props)
        var currentTime = new Date()
        this.currentYear = currentTime.getFullYear()
        this.YearList = [
            {label: 'Current Year', value: this.currentYear.toString()},
            {label: 'Current and Last Year', value: (this.currentYear - 1).toString()},
            {label: 'Last Year', value: 'lastyear'},
            {label: 'Last 5 Years', value: (this.currentYear - 4).toString()},
            {label: 'Last 10 Years', value: (this.currentYear - 9).toString()},
            {label: 'Patient Safety', value: 'yes'},
            {label: 'Show All', value: '1900'},
        ]
        this.state = {
            CMEdata: [],
            CallingApi: false,
            CallingDeleteApi: false,
            Category1CME: [],
            OtherDocumentedActivities: [],
            OtherUndocumentedActivities: [],
            nrCat: [10, 10, 10],
            selectedYear: '1900', // 'Show All' value
            validationStatus: GeneralEnums.textInputStatus.default,
            showDeleteConfirm: false,
            recordToDelete: '',
            recordToDeleteName: '',
            recordToDeleteCompleted: '1/1/1900',
        }

        this.styles = StyleSheet.create({
            activityTypeTitle: {
                textAlign: 'center',
                paddingLeft: 10,
                fontSize: 15,
                color: ColorEnums.white,
                fontWeight: 'bold',
            },
            activityTitle: {
                textAlign: 'left',
                paddingLeft: 10,
                fontSize: 15,
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
                width: '90%',
            },
            boldFont: {
                fontFamily: 'Lato',
                color: ColorEnums.cloudBurst,
                fontWeight: 'bold',
            },
            confirmationBox: {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F5FCFF88',
                height: '100%',
                paddingBottom: 20,
                top: 0,
                left: 0,
                right: 0,
                position: 'absolute',
                zIndex: 65,
            },
            dataRowView: {
                width: '100%',
                flexDirection: 'row',
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 20,
                alignItems: 'flex-end',
            },
            dataLableStyle: {
                width: '50%',
                fontSize: 14,
                fontFamily: 'Lato',
            },
            dataRowStyle: {
                width: '50%',
                fontSize: 14,
                marginRight: 20,
                fontFamily: 'Lato',
                fontWeight: 'bold',
                textAlign: 'right',
                paddingRight: 5,
            },
            dropdown: {
                width: 175,
                color: ColorEnums.cloudBurst,
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: ColorEnums.blueGray,
                height: 26,
            },
            linkStyle: {
                fontSize: 14,
                fontFamily: 'Lato',
                color: ColorEnums.danube,
                marginLeft: 10,
                textAlign: 'right',
            },
            popupContainer: {
                alignSelf: 'center',
                alignItems: 'center',
                backgroundColor: '#F5FCFF88',
                paddingBottom: 20,
                width: '95%',
                zIndex: 5,
            },
            accordionIconStyle: {
                color: 'white',
                fontSize: 12,
                paddingTop: 3,
            },
            accordionButtonWidth: {
                width: '97%',
            },
        })
    }

    updateDropdownSelectedValue(selectedYear) {
        if (selectedYear === 'null') return
        this.setState({selectedYear})
        this.GetCMEByCategory(this.state.CMEdata, selectedYear)
    }

    GetCMEByCategory(cmeActivities, selectedYear) {
        let YearDateCompleted = this.currentYear
        const cmeActivityList = {
            Category1CME: [],
            OtherDocumentedActivities: [],
            OtherUndocumentedActivities: [],
        }

        if (typeof selectedYear === 'undefined') {
            selectedYear = this.currentYear
        }

        cmeActivities.forEach((cmeActivity) => {
            YearDateCompleted = cmeActivity.DateCompleted.substring(6, 11)
            if (selectedYear === 'lastyear') {
                if (YearDateCompleted === (this.currentYear - 1).toString()) {
                    this.addActivityToCategory(cmeActivity, cmeActivityList)
                }
            }
            if (YearDateCompleted >= selectedYear) {
                this.addActivityToCategory(cmeActivity, cmeActivityList)
            }
            if (selectedYear === 'yes') {
                if (cmeActivity.PatientSafety.toLowerCase() === 'yes') {
                    this.addActivityToCategory(cmeActivity, cmeActivityList)
                }
            }
        })

        const {Category1CME, OtherDocumentedActivities, OtherUndocumentedActivities} = cmeActivityList

        this.setState({
            Category1CME,
            OtherDocumentedActivities,
            OtherUndocumentedActivities,
        })
    }

    addActivityToCategory(cmeActivity, cmeActivityList) {
        const {Category1CME, OtherDocumentedActivities, OtherUndocumentedActivities} = cmeActivityList
        switch (cmeActivity.Category) {
            case 1:
                Category1CME.push(cmeActivity)
                break
            case 2:
                OtherDocumentedActivities.push(cmeActivity)
                break
            case 3:
                OtherUndocumentedActivities.push(cmeActivity)
                break
            default:
                null
        }
    }

    deleteCMERecord() {
        CMEContext.setState({showDeleteConfirm: false})
        CMEActivitiesLogic.deleteActivity().then((response) => {
            if (response) {
                CMEActivitiesLogic.getCMEActivities().then((responsedata) => {
                    CMEContext.setState({CMEdata: responsedata})
                    CMEContext.updateDropdownSelectedValue(CMEContext.state.selectedYear.toString())
                })
            }
        })
    }

    resetStateVars() {
        CMEContext.setState({showDeleteConfirm: false, recordToDelete: ''})
    }

    componentDidMount() {
        CMEActivitiesLogic.getCMEActivities().then((responsedata) => {
            this.setState({CMEdata: responsedata})
            this.GetCMEByCategory(responsedata, this.state.selectedYear)
        })
    }

    renderEditDeleteButtons(item) {
        return (
            <View style={[this.styles.dataRowView, {width: '50%', alignContent: 'flex-end'}]}>
                {item.ReportedBy === 1 && item.AuditStatusNr === 1 && (
                    <View style={{flex: 1, flexDirection: 'row-reverse', marginRight: 18}}>
                        <Text
                            onPress={() => {
                                this.setState({
                                    showDeleteConfirm: true,
                                    recordToDelete: item.ContinuingEducationID,
                                    recordToDeleteName: item.SessionDescription,
                                    recordToDeleteCompleted: item.DateCompleted,
                                })
                            }}
                            style={[styleFunctions.paragraphText('right'), this.styles.linkStyle]}
                        >
                            DELETE
                        </Text>

                        <Text
                            onPress={() => {
                                NavigationHelper.GoToPage(
                                    GeneralEnums.navigationType.push,
                                    '/addEditCME',
                                    {
                                        CMEID: item.ContinuingEducationID,
                                    },
                                )
                            }}
                            style={[styleFunctions.paragraphText('right'), this.styles.linkStyle]}
                        >
                            EDIT
                        </Text>
                    </View>
                )}
                {item.ReportedBy === 1 && item.AuditStatusNr === 3 && (
                    <View style={{flex: 1, flexDirection: 'row-reverse', marginRight: 18}}>
                        <Text
                            onPress={() => {
                                this.setState({
                                    showDeleteConfirm: true,
                                    recordToDelete: item.ContinuingEducationID,
                                    recordToDeleteName: item.SessionDescription,
                                    recordToDeleteCompleted: item.DateCompleted,
                                })
                            }}
                            style={[styleFunctions.paragraphText('right'), this.styles.linkStyle]}
                        >
                            DELETE
                        </Text>
                    </View>
                )}
            </View>
        )
    }
    accordionListContent(item, index, category, listTotal, userInMoca) {
        const activityDescription = item.SessionDescription
        const activityProvider = item.Provider === null ? 'Provider (Other)' : item.Provider
        const activityReportedByProvider = item.ReportedBy === 2
        const isPatientSafety = item.PatientSafety.toLowerCase() === 'yes'
        const accordionTitleHeader = (
            <Text>
                <Text style={{color: activityReportedByProvider && ColorEnums.surfGreen}}>
                    {activityProvider}
                </Text>{' '}
                {`\n${activityDescription}`}
            </Text>
        )

        return (
            index <= this.state.nrCat[category - 1] - 1 && (
                <View key={'' + item.ContinuingEducationID + category}>
                    <ABAAccordion
                        key={item.ContinuingEducationID}
                        title={accordionTitleHeader}
                        title2={
                            item.Category === category && (
                                <View style={{flexDirection: 'column', width: '100%'}}>
                                    <View style={{flexDirection: 'row'}}>
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                                marginLeft: 22,
                                            }}
                                        >
                                            Completed:
                                        </Text>
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                                marginRight: 7,
                                            }}
                                        >
                                            {' '}
                                            {item.DateCompleted}
                                        </Text>
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                            }}
                                        >
                                            Credits:
                                        </Text>
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                            }}
                                        >
                                            {' '}
                                            {parseFloat(
                                                Math.round(item.CreditsGranted * 100) / 100,
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                    {isPatientSafety && (
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Lato',
                                                fontSize: 14,
                                                fontStyle: 'italic',
                                                color: ColorEnums.danube,
                                                marginLeft: 18,
                                                marginTop: 3,
                                            }}
                                        >
                                            {' '}
                                            Patient Safety
                                        </Text>
                                    )}
                                </View>
                            )
                        }
                        accordionContent={
                            item.Category === category && (
                                <View
                                    style={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: ColorEnums.lightGray,
                                        width: '100%',
                                    }}
                                >
                                    <View style={this.styles.dataRowView}>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataLableStyle,
                                            ]}
                                        >
                                            Patient Safety
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataRowStyle,
                                            ]}
                                        >
                                            {item.PatientSafety}
                                        </Text>
                                    </View>
                                    <View style={this.styles.dataRowView}>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataLableStyle,
                                            ]}
                                        >
                                            Credits Requested
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataRowStyle,
                                            ]}
                                        >
                                            {parseFloat(
                                                Math.round(item.CreditsRequested * 100) / 100,
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                    <View style={this.styles.dataRowView}>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataLableStyle,
                                            ]}
                                        >
                                            Audit Status
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataRowStyle,
                                            ]}
                                        >
                                            {item.AuditStatus}
                                        </Text>
                                    </View>
                                    <View style={this.styles.dataRowView}>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataLableStyle,
                                            ]}
                                        >
                                            Audit Date
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataRowStyle,
                                            ]}
                                        >
                                            {item.AuditDate}
                                        </Text>
                                    </View>
                                    <View style={this.styles.dataRowView}>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataLableStyle,
                                            ]}
                                        >
                                            Audit Result
                                        </Text>
                                        <Text
                                            style={[
                                                styleFunctions.paragraphText(),
                                                this.styles.dataRowStyle,
                                            ]}
                                        >
                                            {item.AuditResult}
                                        </Text>
                                    </View>
                                    <View style={{flexDirection: 'row'}}>
                                        {item.ReportedBy === 1 &&
                                            (item.AuditStatusNr === 1 || item.AuditStatusNr === 3) && (
                                                <View style={[this.styles.dataRowView, {width: '50%'}]}>
                                                    <Text
                                                        style={[
                                                            styleFunctions.paragraphText(),
                                                            this.styles.dataLableStyle,
                                                        ]}
                                                    >
                                                        Action
                                                    </Text>
                                                </View>
                                            )}
                                        {!(
                                            this.state.showDeleteConfirm &&
                                            this.state.recordToDelete === item.ContinuingEducationID
                                        ) && this.renderEditDeleteButtons(item)}
                                    </View>
                                    {this.state.showDeleteConfirm &&
                                        this.state.recordToDelete === item.ContinuingEducationID && (
                                            <View style={this.styles.confirmationBox}>
                                                <ABAConfirmationBox
                                                    messageText1="Are you sure you want to delete the following record?"
                                                    messageText2={
                                                        `${CMEContext.state.recordToDeleteName}` +
                                                        ' completed on ' +
                                                        `${CMEContext.state.recordToDeleteCompleted}`
                                                    }
                                                    buttonLeftText="  Yes  "
                                                    buttonRightText="  No  "
                                                    leftButtonFunction={CMEContext.deleteCMERecord}
                                                    rightButtonFunction={CMEContext.resetStateVars}
                                                />
                                            </View>
                                        )}
                                    {this.state.showDeleteConfirm &&
                                        this.state.recordToDelete === item.ContinuingEducationID && (
                                            <View
                                                style={{
                                                    height: 50 + this.state.recordToDeleteName.length,
                                                }}
                                            />
                                        )}
                                </View>
                            )
                        }
                        accordionContainerStyle={[styleFunctions.Accordioncontainer(), {width: '97%'}]}
                        accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                        accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                        accordionTitleStyle={this.styles.activityTitle}
                        accordionExpanded={false}
                        accordionUnderlayColor={ColorEnums.white}
                        accordionIconLeft={{collapsed: 'chevron-down', expanded: 'chevron-up'}}
                        accordionButtonStyle={this.styles.accordionButtonWidth}
                    />
                    {index === Math.min(this.state.nrCat[category - 1] - 1, listTotal - 1) && (
                        <View style={{flexDirection: 'row', width: '100%'}}>
                            <View style={{width: '50%'}}>
                                {this.state.nrCat[category - 1] <= listTotal && (
                                    <Text
                                        onPress={() => {
                                            nrCatCopy = this.state.nrCat
                                            nrCatCopy[category - 1] += 10
                                            this.setState({nrCat: nrCatCopy})
                                        }}
                                        style={{
                                            paddingLeft: 25,
                                            marginTop: 20,
                                            fontFamily: 'Lato',
                                            fontWeight: 'bold',
                                            color: ColorEnums.danube,
                                            fontSize: 18,
                                        }}
                                    >
                                        Show More...
                                    </Text>
                                )}
                            </View>
                            {(this.state.nrCat[0] > 10 ||
                                this.state.nrCat[1] > 10 ||
                                this.state.nrCat[2] > 10) && (
                                <View style={{width: '50%'}}>
                                    <Text
                                        style={{
                                            paddingRight: 25,
                                            fontFamily: 'Lato',
                                            color: ColorEnums.danube,
                                            fontSize: 14,
                                            textAlign: 'right',
                                        }}
                                        onPress={() =>
                                            this.refs._scrollView.scrollTo({
                                                x: 0,
                                                y: 0,
                                                animated: true,
                                            })
                                        }
                                    >
                                        {' '}
                                        Return To Top
                                    </Text>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            )
        )
    }
    render() {
        navigationContext = this
        CMEContext = this
        CMEActivitiesLogic.context = this
        HelperMethods.setNavigationData(this)
        const userInMoca = this.props.userData.userRole === 1
        const bold = {fontWeight: 'bold'}
        return (
            <NavigationShell context={this} print={true}>
                <ABAKeyboardAwareScrollView
                    ref="_scrollView"
                    style={{backgroundColor: ColorEnums.backgroundGray}}
                >
                    {this.state.CallingDeleteApi && (
                        <View
                            style={[
                                styleFunctions.loadingOverlay(),
                                {
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 10,
                                },
                            ]}
                        >
                            <View style={{alignSelf: 'center', marginTop: 200}}>
                                <Text>Deleting CME Record</Text>
                                <ActivityIndicator color={ColorEnums.indigo} size="large" />
                            </View>
                        </View>
                    )}
                    {userInMoca ? (
                        <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCA} />
                    ) : (
                        <View style={{height: 30}} />
                    )}
                    <ABAContainer
                        activityText={'Loading CME Activities'}
                        containerTitle={'CME Activity Listing'}
                        showActivityIndicator={this.state.CallingApi}
                    >
                        {userInMoca && (
                            <Text
                                style={{
                                    fontFamily: 'lato',
                                    fontSize: 14,
                                }}
                            >
                                <Text style={bold}>Beginning in 2013</Text>, a maximum of
                                <Text style={bold}> 60</Text> CME credits will be accepted per calendar
                                year.
                            </Text>
                        )}
                        <View style={{paddingTop: 20, paddingBottom: 20}}>
                            <Text style={{paddingRight: 3}}>
                                If you complete an activity through one of our
                                <ABAHyperLink
                                    url="https://theaba.org/pdfs/MOCA_CME_Providers.pdf"
                                    label=" registered CME providers"
                                />
                                , please give them your ABA ID number and they will report your
                                activities to us on your behalf. This includes
                                <ABAHyperLink
                                    url="https://www.theaba.org/wp-content/uploads/2023/01/MOCA_Patient_Safety_CME.pdf"
                                    label=" ABA-approved Patient Safety CME"
                                />
                                . These activities have the provider’s name in{' '}
                                <Text style={{fontWeight: 'bold', color: ColorEnums.surfGreen}}>
                                    green
                                </Text>{' '}
                                font in your below activity listing.
                            </Text>
                        </View>
                        <View style={{paddingBottom: 15}}>
                            {userInMoca && (
                                <Text>
                                    Self-reported CME activities are subject to audit for a period of
                                    three years from the date you report them. Please retain your CME
                                    documentation for your self-reported activities. If you are scheduled
                                    for an audit, we will contact you about the activities for which you
                                    must submit documentation. Do not mail or fax documentation to us
                                    unless it's requested.
                                </Text>
                            )}
                        </View>
                        <ABAAccordion
                            accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                            accordionButtonStyle={this.styles.accordionButtonWidth}
                            accordionContainerStyle={[
                                styleFunctions.Accordioncontainer(),
                                {width: '97%'},
                            ]}
                            accordionContent={
                                userInMoca ? (
                                    <CMEDiplomateInMOCA
                                        ShowActivitiesLink={false}
                                        ShowPrintLink={true}
                                        SelectedYearForTable={this.state.selectedYear}
                                    />
                                ) : (
                                    <CME
                                        ShowActivitiesLink={false}
                                        ShowPrintLink={true}
                                        SelectedYearForTable={this.state.selectedYear}
                                    />
                                )
                            }
                            accordionExpanded={true}
                            accordionIconLeft={{
                                collapsed: 'chevron-down',
                                expanded: 'chevron-up',
                            }}
                            accordionTitleContainerStyle={styleFunctions.AccordiontitleContainer()}
                            accordionTitleStyle={this.styles.activityTitle}
                            accordionUnderlayColor={ColorEnums.white}
                            title="Category 1 CME Activity Progress"
                        />
                        <View
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 25,
                                justifyContent: 'center',
                            }}
                        >
                            <View>
                                <Text style={[this.styles.boldFont, {paddingRight: 5}]}>
                                    Display CMEs for:{' '}
                                </Text>
                            </View>
                            <View style={{backgroundColor: ColorEnums.white}}>
                                <View
                                    style={{
                                        backgroundColor: ColorEnums.backgroundGray,
                                        marginBottom: 10,
                                    }}
                                >
                                    <ABADropdown
                                        options={this.YearList}
                                        label={`Please Select Year`}
                                        placeholder=""
                                        onChange={(value) => this.updateDropdownSelectedValue(value)}
                                        selectedValue={this.state.selectedYear}
                                        width={200}
                                    />
                                </View>
                            </View>
                        </View>

                        {!this.state.CallingApi && (
                            <View>
                                <ABAAccordion
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionButtonStyle={this.styles.accordionButtonWidth}
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionContent={this.state.Category1CME.map((item, index) => {
                                        return this.accordionListContent(
                                            item,
                                            index,
                                            1,
                                            this.state.Category1CME.length,
                                            userInMoca,
                                        )
                                    })}
                                    accordionExpanded={true}
                                    accordionIconStyle={this.styles.accordionIconStyle}
                                    accordionTitleContainerStyle={[
                                        styleFunctions.AccordiontitleContainer(),
                                        {
                                            width: '100%',
                                            backgroundColor: ColorEnums.indigo,
                                            paddingLeft: 20,
                                        },
                                    ]}
                                    accordionTitleStyle={this.styles.activityTypeTitle}
                                    title="Category 1 Activities"
                                />
                                <ABAAccordion
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionButtonStyle={this.styles.accordionButtonWidth}
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionContent={this.state.OtherDocumentedActivities.map(
                                        (item, index) => {
                                            return this.accordionListContent(
                                                item,
                                                index,
                                                2,
                                                this.state.OtherDocumentedActivities.length,
                                                userInMoca,
                                            )
                                        },
                                    )}
                                    accordionExpanded={true}
                                    accordionIconStyle={this.styles.accordionIconStyle}
                                    accordionTitleContainerStyle={[
                                        styleFunctions.AccordiontitleContainer(),
                                        {
                                            width: '100%',
                                            backgroundColor: ColorEnums.indigo,
                                            paddingLeft: 20,
                                        },
                                    ]}
                                    accordionTitleStyle={this.styles.activityTypeTitle}
                                    title="Other: Documented Activities"
                                />
                                <ABAAccordion
                                    accordionBottomViewStyle={styleFunctions.AccordionBottomViewStyle()}
                                    accordionButtonStyle={this.styles.accordionButtonWidth}
                                    accordionContainerStyle={styleFunctions.Accordioncontainer()}
                                    accordionContent={this.state.OtherUndocumentedActivities.map(
                                        (item, index) => {
                                            return this.accordionListContent(
                                                item,
                                                index,
                                                3,
                                                this.state.OtherUndocumentedActivities.length,
                                                userInMoca,
                                            )
                                        },
                                    )}
                                    accordionExpanded={true}
                                    accordionIconStyle={this.styles.accordionIconStyle}
                                    accordionTitleContainerStyle={[
                                        styleFunctions.AccordiontitleContainer(),
                                        {
                                            width: '100%',
                                            backgroundColor: ColorEnums.indigo,
                                            paddingLeft: 20,
                                        },
                                    ]}
                                    accordionTitleStyle={this.styles.activityTypeTitle}
                                    title="Other: Undocumented Activities"
                                />
                            </View>
                        )}
                    </ABAContainer>
                    <PrintedFooter />
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CMEActivities)
