import {Platform} from 'react-native'
import ABAPortalAPI from '../../helpers/AxiosInstance'
import HelperMethods from '../../helpers/HelperMethods'

const ViewNSERequestsLogic = {
    context: null,

    openPDF: function (filename, NSEID) {
        const headers = {
            Accept: 'application/pdf',
        }
        const path = 'nserequestsinfo/getnseattachment?PDFID=' + NSEID

        if (Platform.OS === 'web') {
            HelperMethods.downloadPDFWeb(filename, headers, path)
        } else {
            HelperMethods.downloadPDFMobile(filename, headers, path)
        }
    },

    getCompletedNSERequests: function () {
        return new Promise((resolve) => {
            ViewNSERequestsLogic.context.setState({CallingApi: true})
            ABAPortalAPI.get('nserequestsinfo/getnserequests')
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(
                        error,
                        'Error Retrieving Completed NSE Request Forms Information',
                    )
                    resolve(null)
                })
                .finally(function () {
                    ViewNSERequestsLogic.context.setState({CallingApi: false})
                })
        })
    },
}

export default ViewNSERequestsLogic
