import {createStore, combineReducers, applyMiddleware} from 'redux'
import {Platform} from 'react-native'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import {persistStore, persistCombineReducers} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import {settings} from './settings'
import {biometrics} from './biometrics'
import {registration} from './registration'
import {pageSettings} from './pageSettings'
import {userData} from './userData'
import {navigationMenu} from './navigationMenu'
import {timeoutSettings} from './timeoutSettings'

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: ['registration'],
}

export const store = createStore(
    persistCombineReducers(persistConfig, {
        settings,
        biometrics,
        registration,
        pageSettings,
        userData,
        navigationMenu,
        timeoutSettings,
    }),
    //* Switch lines if you need to debug redux actions
    // applyMiddleware(thunk, logger),
    applyMiddleware(thunk),
)

export const ConfigureStore = () => {
    let persistor = persistStore(store)
    return {store, persistor}
}
