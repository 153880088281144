import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import SubstanceAbuseStep1 from '../substanceabuse/presentation/substanceAbuseStep1'
import SubstanceAbuseStep8 from '../substanceabuse/presentation/substanceAbuseStep8'

const SubstanceAbuseLogic = {
    context: null,

    GetSubstanceAbuseBeginData() {
        return new Promise((resolve) => {
            SubstanceAbuseLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('substanceabuseinfo/GetSABegin', {
                Order: SubstanceAbuseLogic.context.props.Order,
                CR2ID: SubstanceAbuseLogic.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Retrieving Substance Abuse Information')
                    resolve(null)
                })
                .finally(function () {
                    SubstanceAbuseLogic.context.setState({CallingApi: false})
                })
        })
    },

    PopulateVariables(data) {
        if (data) {
            substanceAbuseContext.setState({
                CurrentComponentStep:
                    data.rs_statuscode && data.rs_statuscode === 1
                        ? SubstanceAbuseStep1
                        : SubstanceAbuseStep8,
                SubstanceAbuseID: data.Cert_substanceabuseId,
                ContactID: data.ContactId,
                Cert_AlcoholAbuse: data.Cert_AlcoholAbuse,
                Cert_DrugAbuse: data.Cert_DrugAbuse,
                SubstanceAbuseStatus: data.Statuscode,
                RuleStatus: data.rs_statuscode,
                ReviewResult: data.cert_ReviewResult,
            })
        }
    },
}

export default SubstanceAbuseLogic
