import React, {Component} from 'react'
import {StyleSheet, Text, View, Platform} from 'react-native'

import ABAContainer from '../../../components/ABACustom/presentation/ABAContainer'
import ABAHeader from '../../../components/ABACustom/presentation/ABAHeader'
import ABAAlert from '../../../components/ABACustom/presentation/ABAAlert'
import ABAMMAnswerOptions from '../../../components/ABACustom/presentation/ABAMMAnswerOptions'
import ABATextInputMultiline from '../../../components/ABACustom/presentation/ABATextInputMultiline'
import ABATouchableHighlight from '../../../components/ABACustom/presentation/ABATouchableHighlight'
import ABAWebView from '../../../components/ABACustom/presentation/ABAWebView'
import ABAKeyboardAwareScrollView from '../../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import {ColorEnums, GeneralEnums, IconEnums} from '../../../helpers/generalEnums'
import MocaMinuteFeedbackLogic from '../logic/mocaMinuteFeedbackLogic'
import MocaMinuteImage from '../../presentation/mocaMinuteImage'
import NavigationHelper from '../../../helpers/NavigationHelper'
import styleFunctions from '../../../styles/styleFunctions'
import PrintedFooter from '../../../components/presentation/printedFooter'

export default class MocaMinuteFeedback extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            spacing: {
                marginBottom: 20,
            },
        })

        this.state = {
            activityIndicatorText: 'Loading MOCA Minute Response',
            answerId: null,
            answers: [],
            callingApi: false,
            correctAnswer: {},
            critique: null,
            critiqueImage: null,
            critiqueImageType: null,
            feedback: null,
            keyPoint: null,
            keyPointImage: null,
            keyPointImageType: null,
            questionText: null,
            questionImage: null,
            questionImageType: null,
            referenceImage: null,
            referenceImageType: null,
            resources: null,
            responseBoxBackgroundColor: null,
            responseBoxIcon: null,
            responseBoxColor: null,
            responseBoxText: null,
            responseStatus: null,
            selectedAnswer: {},
            minHeight: 50,
        }
    }

    getStyleForResponseTypeBox(responseStatus) {
        switch (responseStatus) {
            case 2:
                return {
                    color: 'green',
                    icon: IconEnums['check'],
                    text: 'Your answer was correct!',
                }
            case 3:
                return {
                    color: 'red',
                    icon: IconEnums['times-circle'],
                    text: 'Your answer was incorrect.',
                }
            case 4:
            default:
                return {
                    color: 'yellow',
                    icon: IconEnums['clock'],
                    text: 'You timed out.',
                }
        }
    }

    componentDidMount() {
        MocaMinuteFeedbackLogic.getMocaMinuteReviewAnswer().then((apiResponse) => {
            let answers = apiResponse.Answers
            let questionImage,
                questionImageType,
                referenceImage,
                referenceImageType,
                keyPointImage,
                keyPointImageType,
                critiqueImage,
                critiqueImageType = null
            answers.map((answer) => {
                return Object.assign(answer, {disabled: true})
            })

            let selectedAnswer = Object.assign(
                {},
                answers.find((answer) => answer.AnswerId == apiResponse.AnswerId),
            )
            let correctAnswer = Object.assign(
                {},
                answers.find((answer) => answer.AnswerId == apiResponse.CorrectAnswerId),
            )
            Object.assign(selectedAnswer, {isSelected: true})

            let responseBoxData = this.getStyleForResponseTypeBox(apiResponse.ResponseStatus)

            apiResponse.Images &&
                apiResponse.Images.map((image) => {
                    const {Section, FileName, FileType} = image
                    if (Section === 'Question') {
                        questionImage = FileName
                        questionImageType = FileType
                    } else if (Section === 'Reference') {
                        referenceImage = FileName
                        referenceImageType = FileType
                    } else if (Section === 'Key Point') {
                        keyPointImage = FileName
                        keyPointImageType = FileType
                    } else if (Section === 'Critique') {
                        critiqueImage = FileName
                        critiqueImageType = FileType
                    }
                })

            this.setState({
                answerId: apiResponse.AnswerId,
                answers: answers,
                correctAnswer: correctAnswer,
                critique: apiResponse.Critique,
                keyPoint: apiResponse.KeyPoint,
                questionText: apiResponse.QuestionText,
                resources: apiResponse.Resources,
                responseBoxIcon: responseBoxData.icon,
                responseBoxColor: responseBoxData.color,
                responseBoxText: responseBoxData.text,
                responseStatus: apiResponse.ResponseStatus,
                selectedAnswer: selectedAnswer,
                questionImage,
                questionImageType,
                critiqueImage,
                critiqueImageType,
                referenceImage,
                referenceImageType,
                keyPointImage,
                keyPointImageType,
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.answers !== this.state.answers) {
            const longestAnswerLength = Math.max(
                ...this.state.answers.map((answer) => answer.AnswerText.length - 7),
                0,
            )
            const minHeight = Math.max(50, 27 * Math.ceil(longestAnswerLength / 32))
            this.setState({minHeight})
        }
    }

    render() {
        MocaMinuteFeedbackLogic.context = this

        const {
            questionImage,
            questionImageType,
            critiqueImage,
            critiqueImageType,
            keyPointImage,
            keyPointImageType,
            referenceImage,
            referenceImageType,
        } = this.state

        return (
            <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                <ABAHeader group={GeneralEnums.ABAHeaderGroups.MOCAMinute} />
                <ABAContainer
                    activityText={this.state.activityIndicatorText}
                    containerTitle={'REVIEW ANSWER'}
                    showActivityIndicator={this.state.callingApi}
                >
                    <ABAAlert
                        color={this.state.responseBoxColor}
                        text={this.state.responseBoxText}
                        icon={this.state.responseBoxIcon}
                    />

                    <MocaMinuteImage image={questionImage} imageType={questionImageType} />
                    <ABAWebView htmlToRender={this.state.questionText || ''} />

                    <View style={this.styles.spacing}>
                        <ABAMMAnswerOptions
                            onPress={(option) => {}}
                            options={this.state.answers}
                            minHeight={this.state.minHeight}
                        />
                    </View>

                    {this.state.answerId !== null && (
                        <View style={this.styles.spacing}>
                            <Text style={styleFunctions.containerTextBold()}>You answered:</Text>
                            <ABAMMAnswerOptions
                                onPress={(option) => {}}
                                options={[this.state.selectedAnswer]}
                                minHeight={this.state.minHeight}
                            />
                        </View>
                    )}

                    {(this.state.responseStatus === 3 || this.state.responseStatus === 4) && (
                        <View style={this.styles.spacing}>
                            <Text style={styleFunctions.containerTextBold()}>
                                The correct answer is:
                            </Text>
                            <ABAMMAnswerOptions
                                onPress={(option) => {}}
                                options={[this.state.correctAnswer]}
                                minHeight={this.state.minHeight}
                            />
                        </View>
                    )}

                    <View style={this.styles.spacing}>
                        <Text style={styleFunctions.containerTextBold()}>Key Point</Text>
                        <MocaMinuteImage image={keyPointImage} imageType={keyPointImageType} />
                        <ABAWebView htmlToRender={this.state.keyPoint || ''} />
                    </View>

                    <View style={this.styles.spacing}>
                        <Text style={styleFunctions.containerTextBold()}>Reference</Text>
                        <MocaMinuteImage image={referenceImage} imageType={referenceImageType} />
                        <ABAWebView htmlToRender={this.state.resources || ''} />
                    </View>

                    <View style={this.styles.spacing}>
                        <Text style={styleFunctions.containerTextBold()}>Critique</Text>
                        <MocaMinuteImage image={critiqueImage} imageType={critiqueImageType} />
                        <ABAWebView htmlToRender={this.state.critique || ''} />
                    </View>

                    {this.props.NavData.HideFeedback !== true ? (
                        <View>
                            <View style={this.styles.spacing}>
                                <Text style={styleFunctions.containerTextBold()}>
                                    Please type your feedback below and click Finish.
                                </Text>
                                <ABATextInputMultiline
                                    maxLength={2500}
                                    numberOfLines={10}
                                    onChangeText={(value) => {
                                        this.setState({feedback: value})
                                    }}
                                    onSubmitEditing={() => {
                                        MocaMinuteFeedbackLogic.updateMocaMinuteFeedback()
                                    }}
                                    returnKeyType="done"
                                    value={this.state.feedback || ''}
                                />
                            </View>

                            <ABATouchableHighlight
                                icon={'chevron-right'}
                                onPressEvent={() => {
                                    MocaMinuteFeedbackLogic.updateMocaMinuteFeedback()
                                }}
                                text={'Finish'}
                            />
                        </View>
                    ) : this.props.NavData.KnowledgeAssessmentReport ? (
                        <View className={Platform.OS === 'web' ? 'printed-button-margin' : ''}>
                            <ABATouchableHighlight
                                onPressEvent={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        '/knowledgeAssessmentReport',
                                    )
                                }}
                                text={'Return to Knowledge Assessment Report'}
                            />
                        </View>
                    ) : (
                        <View className={Platform.OS === 'web' ? 'printed-button-margin' : ''}>
                            <ABATouchableHighlight
                                onPressEvent={() => {
                                    NavigationHelper.GoToPage(
                                        GeneralEnums.navigationType.push,
                                        '/questionHistory',
                                    )
                                }}
                                text={'Return to Question History'}
                            />
                        </View>
                    )}
                </ABAContainer>
                <PrintedFooter />
            </ABAKeyboardAwareScrollView>
        )
    }
}
