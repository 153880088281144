import React, {Component} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import {connect} from 'react-redux'

import {ColorEnums} from '../../helpers/generalEnums'
import DashboardLogic from '../logic/dashboardLogic'
import HelperMethods from '../../helpers/HelperMethods'
import NavigationShell from '../../components/presentation/navigationShell'
import ABAKeyboardAwareScrollView from '../../components/ABACustom/presentation/ABAKeyboardAwareScrollView'
import styleFunctions from '../../styles/styleFunctions.web'

const mapStateToProps = (state) => ({
    registration: state.registration,
})

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            callingApi: false,
            DisplayContent: null,
            dashboardAlerts: [],
            mocaCR2: null,
        }
    }

    componentDidMount() {
        DashboardLogic.GetDashboardAlertData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.mocaCR2 !== this.state.mocaCR2) {
            DashboardLogic.GetDashboardDisplay().then((components) => {
                dashboardContext.setState({DisplayContent: components})
            })
        }

        if (this.props.registration.pendingPayment != prevProps.registration.pendingPayment) {
            DashboardLogic.GetDashboardAlertData(false)
        }
    }

    render() {
        dashboardContext = this
        navigationContext = this
        DashboardLogic.context = dashboardContext
        HelperMethods.setNavigationData(dashboardContext)
        return (
            <NavigationShell context={this}>
                <ABAKeyboardAwareScrollView style={{backgroundColor: ColorEnums.backgroundGray}}>
                    <div className="row" style={{margin: 0}}>
                        {dashboardContext.state.DisplayContent}
                    </div>

                    {dashboardContext.state.callingApi && (
                        <View style={styleFunctions.loadingOverlay()}>
                            <Text>Logging In</Text>
                            <ActivityIndicator color={ColorEnums.indigo} size="large" />
                        </View>
                    )}
                </ABAKeyboardAwareScrollView>
            </NavigationShell>
        )
    }
}

export default connect(mapStateToProps)(Dashboard)
