import ABAPortalAPI from '../../../helpers/AxiosInstance'
import HelperMethods from '../../../helpers/HelperMethods'
import ClinicalActivityStep1 from '../clinicalactivity/presentation/clinicalActivityStep1'
import TrainingPage from '../clinicalactivity/presentation/trainingPage'
import ClinicalActivityStep5 from '../clinicalactivity/presentation/clinicalActivityStep5'
import NCCPathwayPage from '../clinicalactivity/presentation/nccPathwayPage'
import ACAPathwayPage from '../clinicalactivity/presentation/acaPathwayPage'
import {GeneralEnums} from '../../../helpers/generalEnums'

const ClinicalActivityLogic = {
    context: null,

    GetClinicalActivityData() {
        return new Promise((resolve) => {
            ClinicalActivityLogic.context.setState({CallingApi: true})
            ABAPortalAPI.post('clinicalactivity/GetClinicalActivityBegin', {
                Order: this.context.props.Order,
                CR2ID: this.context.props.CR2ID,
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    HelperMethods.apiErrorHandler(error, 'Error Acccessing Clinical Activity Record')
                })
                .finally(function () {
                    ClinicalActivityLogic.context.setState({CallingApi: false})
                })
        })
    },

    DetermineCurrentComponentStep(
        statusCode,
        captureGraduation,
        showNCCPathway,
        isRecertification,
        programType,
    ) {
        //Determines the first component to show
        switch (statusCode) {
            case 1: //Not Submitted Status code.
                if (showNCCPathway) {
                    return NCCPathwayPage
                } else if (programType === GeneralEnums.program_type.Adult_Cardiac_Anesthesiology) {
                    return ACAPathwayPage
                } else if (captureGraduation && !isRecertification) {
                    return TrainingPage
                }
                return ClinicalActivityStep1
            default:
                return ClinicalActivityStep5
        }
    },

    PopulateVariables(CAData) {
        if (CAData) {
            const {rs_statuscode, shouldCaptureGraduation, showNCCPathway, re_ProgramType} = CAData

            const CurrentComponentStep = ClinicalActivityLogic.DetermineCurrentComponentStep(
                rs_statuscode,
                shouldCaptureGraduation,
                showNCCPathway,
                ClinicalActivityLogic.context.props.IsRecertification,
                re_ProgramType,
            )

            ClinicalActivityLogic.context.setState({
                CurrentComponentStep,
                shouldCaptureGraduation,
                ClinicalActivityID: CAData.cert_clinicalactivityid,
                ContactId: CAData.ContactId,
                ClinicalActivityStatus: CAData.rs_statuscode,
                ClinicalActivityWorkPrivilege: CAData.cert_workprivileges,
                RuleStatus: CAData.rs_statuscode,
                ReviewResult: CAData.cert_reviewresult,
                showNCCPathway,
                cert_nccpath: CAData.cert_nccpath,
            })
        }
    },
}

export default ClinicalActivityLogic
